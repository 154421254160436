import {Button, Container, Grid, Snackbar, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import {LTLQuoteSelectService} from "../LTLQuoteList/LTLQuoteSelectService";
import {LTLQuoteNumber} from "../LTLQuoteList/LTLQuoteNumber";
import {LTLQuoteExpiredDate} from "../LTLQuoteList/LTLQuoteExpiredDate";
import {useState} from "react";
import {CarQuoteSelectCarrier} from "./CarQuoteSelectCarrier";
import {CarQuoteNumber} from "./CarQuoteNumber";
import {CarQuoteExpiredDate} from "./CarQuoteExpiredDate";
import {CarQuoteMake} from "./CarQuoteMake";
import {CarQuoteModel} from "./CarQuoteModel";
import {CarQuoteYear} from "./CarQuoteYear";
import {CarQuoteInsurance} from "./CarQuoteInsurance";
import {CarQuoteItemAllow} from "./CarQuoteItemAllow";
import {CarQuoteShippingTime} from "./CarQuoteShippingTime";
import {CarQuoteTo} from "./CarQuoteTo";
import {CarQuoteFrom} from "./CarQuoteFrom";
import {CarQuoteShippingMode} from "./CarQuoteShippingMode";
import {CarQuoteCost} from "./CarQuoteCost";
import LoadingButton from "@mui/lab/LoadingButton";
import {getMissingKeysShipFromInfo, getMissingKeysShipToInfo} from "../../utils/Helper";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {CarQuoteCategory} from "./CarQuoteCategory";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const CarQuoteAdd = () => {

    const history = useHistory();

    const [triggerAction, setTriggerAction] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [validation, setValidation] = useState(null);
    const [quoteNumber, setQuoteNumber] = useState();
    const [expiredDate, setExpiredDate] = useState();
    const [selectCarrier, setSelectCarrier] = useState();
    const [carMake, setCarMake] = useState();
    const [carModel, setCarModel] = useState();
    const [carYear, setCarYear] = useState();
    const [carSize, setCarSize] = useState([]);
    const [carInsurance, setCarInsurance] = useState();
    const [itemsAllowed, setItemsAllowed] = useState();
    const [shippingTime, setShippingTime] = useState();
    const [shippingFrom, setShippingFrom] = useState();
    const [shippingTo, setShippingTo] = useState();
    const [shippingMode, setShippingMode] = useState();
    const [shippingCost, setShippingCost] = useState();
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleEdit = () => {
        setIsConfirm(false);
    }

    const handleButtonClick = () => {
        // Set the state to trigger the action in the child component
        setTriggerAction(true);
    };

    const handleDataFromChildComponent = (data) => {
        const key = Object.keys(data);
        switch (key[0]) {
            case 'selectCarrier':
                setSelectCarrier(data?.selectCarrier);
                break
            case 'quoteNumber':
                setQuoteNumber(data?.quoteNumber);
                break
            case 'expiredDate':
                setExpiredDate(data?.expiredDate);
                break
            case 'carMake':
                setCarMake(data?.carMake);
                break
            case 'carModel':
                setCarModel(data?.carModel);
                break
            case 'shipFrom':
                setShippingFrom(data?.shipFrom);
                break
            case 'shipTo':
                setShippingTo(data?.shipTo);
                break
            case 'carYear':
                setCarYear(data?.carYear);
                break
            case 'carInsurance':
                setCarInsurance(data?.carInsurance);
                break
            case 'itemsAllowed':
                setItemsAllowed(data?.itemsAllowed);
                break
            case 'shippingTime':
                setShippingTime(data?.shippingTime);
                break
            case 'shippingMode':
                setShippingMode(data?.shippingMode);
                break
            case 'shippingCost':
                setShippingCost(data?.shippingCost);
                break
            case 'carSize':
                setCarSize(data?.carSize);
                break
            default:
        }
    }

    const getDataFromChildComponent = (data) => {
        console.log('child data', data);
        handleDataFromChildComponent(data);
        setTriggerAction(false);
        setIsConfirm(true);
    }

    const createQuote = async () => {
        setLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/movingCar/addMovingCarQuotes`;

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 120);
        const defaultExpiredDate = currentDate.toISOString().split('T')[0];

        const categoryName = 'CAR-SIZE';
        const categoryValue = carSize?.map(cat => `${categoryName}-${cat}`).join(', ');

        let data = {
            accountBase: selectCarrier,
            quoteNumber: quoteNumber,
            expiredTime: expiredDate || defaultExpiredDate,
            shipFromCity: shippingFrom?.city,
            shipFromProvince: shippingFrom?.province?.code,
            shipFromCountry: shippingFrom?.country?.code,
            shipToCity: shippingTo?.city,
            shipToProvince: shippingTo?.province?.code,
            shipToCountry: shippingTo?.country?.code,
            cateId: null,
            make: carMake,
            model: carModel,
            productionYear: carYear?.$y,
            shippingMode: shippingMode,
            currency: "CAD",
            includeInsurance: carInsurance?.includeInsurance,
            insuranceDescription: carInsurance?.description,
            itemsAllowedInsideCar: itemsAllowed,
            shippingDaysRange: shippingTime,
            tariffCharge: shippingCost?.tariffCharge || 0,
            fuelCharge: shippingCost?.fuelCharge || 0,
            taxes: shippingCost?.taxCharge || 0,
            crossBorderFee: shippingCost?.crossBorderCharge || 0,
            insuranceCharge: shippingCost?.insuranceCharge || 0,
            extraLocalPickupCharge: shippingCost?.extraLocalPickupCharge || 0,
            extraLocalDeliverCharge: shippingCost?.extraLocalDeliverCharge || 0,
            categoryName: categoryName,
            categoryValue: categoryValue,
            remarkNotes: ""

        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('[CarQuoteAdd] createQuote api - result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Create Quote');
            handleSuccessMessage();
            setTimeout(() => {
                history.push("/v2/car-quote-list");
            }, 2000);

        } catch (e) {
            console.log('[CarQuoteAdd] createQuote api - error', e?.response);
            setErrorMessage('Fail Create Quote');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysShipFromInfo({
            shipperCity: shippingFrom?.city,
            shipperProvince: shippingFrom?.province?.code,
            shipperCountry: shippingFrom?.country?.code
        });
        const shipToMissing = getMissingKeysShipToInfo({
            consigneeCity: shippingTo?.city,
            consigneeProvince: shippingTo?.province?.code,
            consigneeCountry: shippingTo?.country?.code
        });

        const validation = {
            shipFromMissing,
            shipToMissing,
            carrierMissing: selectCarrier === 'default' || !selectCarrier,
            tariffChargeError: shippingCost?.tariffCharge <= 0,
            // carMakeMissing: !carMake,
            // carModelMissing: !carModel,
            // carYearMissing: !carYear,
            shippingTimeMissing: !shippingTime,
            shippingModeMissing: !shippingMode,
            // carSize: carSize?.length === 0
        }

        console.log('validation', validation);
        setValidation(validation);

        // carSize?.length === 0 && setErrorMessage(prevState => prevState + 'Car Size is missing.');
        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Ship From information is missing one or more required fields.');
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Ship To information is missing one or more required fields.');
        (selectCarrier === 'default' || !selectCarrier) && setErrorMessage(prevState => prevState + 'Carrier is missing.');
        // (!carMake) && setErrorMessage(prevState => prevState + 'Make is missing.');
        // (!carModel) && setErrorMessage(prevState => prevState + 'Model is missing.');
        // (!carYear) && setErrorMessage(prevState => prevState + 'Year is missing.');
        (!shippingTime) && setErrorMessage(prevState => prevState + 'Shipping time is missing.');
        (!shippingMode) && setErrorMessage(prevState => prevState + 'Shipping mode is missing.');
        shippingCost?.tariffCharge <= 0 && setErrorMessage(prevState => prevState + 'Tariff Charge is smaller or equal to 0.');

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            selectCarrier !== 'default' &&
            selectCarrier &&
            shippingCost?.tariffCharge > 0
            // carSize?.length > 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreateOrder = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            createQuote();
        }
    }

    // console.log('[LTLQuoteAdd] selectCarrier', selectCarrier);
    // console.log('[LTLQuoteAdd] quoteNumber', quoteNumber);
    // console.log('[LTLQuoteAdd] expiredDate', expiredDate);
    // console.log('[LTLQuoteAdd] carMake', carMake);
    // console.log('[LTLQuoteAdd] carModel', carModel);
    // console.log('[LTLQuoteAdd] carYear', carYear?.$y);
    // console.log('[LTLQuoteAdd] shippingFrom', shippingFrom);
    // console.log('[LTLQuoteAdd] shippingTo', shippingTo);
    // console.log('[LTLQuoteAdd] shippingTime', shippingTime);
    // console.log('[LTLQuoteAdd] itemsAllowed', itemsAllowed);
    // console.log('[LTLQuoteAdd] shippingMode', shippingMode);
    // console.log('[LTLQuoteAdd] carInsurance', carInsurance);
    // console.log('[LTLQuoteAdd] shippingCost', shippingCost);
    console.log('[LTLQuoteAdd] validation', validation);
    console.log('[LTLQuoteAdd] validation', validation);
    console.log('[LTLQuoteAdd] validation', validation);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Create Car Quote
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <CarQuoteSelectCarrier
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CarQuoteNumber
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CarQuoteExpiredDate
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CarQuoteMake
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CarQuoteModel
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CarQuoteYear
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CarQuoteCategory
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CarQuoteFrom
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CarQuoteTo
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CarQuoteItemAllow
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CarQuoteShippingTime
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CarQuoteShippingMode
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <CarQuoteCost
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CarQuoteInsurance
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    variant='contained'
                                    onClick={() => history.push('/v2/car-quote-list')}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Back To Quote List
                                    </Typography>
                                </Button>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'center'
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={isConfirm ? handleEdit : handleButtonClick}
                                    >
                                        <Typography style={{
                                            textTransform: 'none'
                                        }}>
                                            {isConfirm ? 'Edit' : 'Confirm'}
                                        </Typography>
                                    </Button>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleCreateOrder}
                                        disabled={!isConfirm}
                                        loading={loading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none'
                                        }}>
                                            Create
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}