import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Snackbar,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { useSelector } from "react-redux";
import EtransferEmailListTable from "./EtransferEmailListTable";
import EtransferEmailDetails from "./EtransferEmailDetails";
import { SERVICE_DAO_URL, NODE_ROUTE_URI } from "../../utils/apiUrl";
import { Link } from "react-router-dom";
import BackArrow from "../BackArrow";

const style = {
  position: "absolute",
  top: "0%",
  left: "50%",
  transform: "translate(-50%, 0%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//             {children}
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     "aria-controls": `simple-tabpanel-${index}`,
//   };
// }

const EtransferEmailList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [emailList, setEmailList] = useState([]);
  const [emailFocus, setEmailFocus] = useState({});
  const [emailModalShowDetails, setEmailModalShowDetails] = useState(true);

  useEffect(() => {
    const getCompanies = async () => {
      const result = await axios.get(
        SERVICE_DAO_URL + "/processetransferemail/getInitialEmailList"
      );
      if (result.status === 200) {
        const emailList = await result.data;
        console.log(emailList);
        setEmailList(emailList);
      } else {
        throw new Error("Could not retrieve Company List");
      }
    };
    getCompanies();
  }, []);

  const reviewEmail = (index) => {
    setEmailFocus(emailList[index]);
    handleOpen();
  };

  const removeEmailById = (id) => {
    setEmailList((prev) => prev.filter((emailObj) => emailObj.id !== id));
    // setEmailFocus({})
  };

  return (
    <Box paddingBottom="3rem">
      <BackArrow link={"/"} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EtransferEmailDetails
            emailObj={emailFocus}
            removeEmailById={removeEmailById}
            handleClose={handleClose}
          />
        </Box>
      </Modal>

      <Box>
        <h1>Manually process end-user pay for orders</h1>
        {isLoading ? (
          <>
            <Box>
              <h1>Loading...</h1>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box>
                <Box mt={2}>
                  <EtransferEmailListTable
                    emailList={emailList}
                    reviewEmail={reviewEmail}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default EtransferEmailList;
