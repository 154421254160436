import {FormLabel, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ContainerShippingVessel = ({cbHandleVessel}) => {

    const [vessel, setVessel] = useState({
        vesselNumber: "",
        voyage: "",
        routeCode: "",
        shipCompany: ""
    });

    const handleVesselNumber = (event) => {
        setVessel(prevState => ({
            ...prevState,
            vesselNumber: event.target.value
        }))
    }

    const handleVoyage = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)$/.test(value)) {
            setVessel(prevState => ({
                ...prevState,
                voyage: value
            }));
        }
    }

    const handleRouteCode = (event) => {
        setVessel(prevState => ({
            ...prevState,
            routeCode: event.target.value
        }))
    }

    const handleShipCompany = (event) => {
        setVessel(prevState => ({
            ...prevState,
            shipCompany: event.target.value
        }))
    }

    useEffect(() => {
        cbHandleVessel(vessel);
    }, [vessel]);

    return (
        <Grid item xs={12}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Vessel and Voyage Information
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Vessel
                            </FormLabel>
                            <TextField
                                value={vessel.vesselNumber}
                                onInput={handleVesselNumber}
                                fullWidth
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Voyage
                            </FormLabel>
                            <TextField
                                value={vessel.voyage}
                                onInput={handleVoyage}
                                fullWidth
                                variant="outlined"
                                size='small'
                                type='number'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Route Code
                            </FormLabel>
                            <TextField
                                value={vessel.routeCode}
                                onInput={handleRouteCode}
                                fullWidth
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Ship Company
                            </FormLabel>
                            <TextField
                                value={vessel.shipCompany}
                                onInput={handleShipCompany}
                                fullWidth
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}