import React, { useEffect, useState } from "react";
import axios from "axios";
import HSSectionList from "./HSSectionList";
import {
  makeStyles,
  CircularProgress,
  Box,
  Button,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import {
  GET_HSCODE_SECTIONS,
  BACKEND_LOGIN_STATE,
  INIT_ELASTIC_SEARCH,
} from "../../utils/apiUrl";

import { addHSCodeList } from "../../redux/HSCode/HSCodeListSlice";

const useStyles = makeStyles((theme) => ({
  loadingWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    margin: "10px",
  },
  MaiPageWrapper: {
    width: "100%",
    margin: "5px auto",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
  },
  buttonGroup: {
    width: "300px",
    alignSelf: "end",
  },
  backDrop: {
    background: "#fff",
  },
  dialogContent: {},
}));

const HSCodeMainPage = ({ HSCodeState }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [authChecking, setAuthChecking] = useState(true);

  const [authorized, setAuthorized] = useState(false);

  const [errMsg, setErrMsg] = useState("");

  const [openBackDrop, setOpenBackDrop] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [startedInit, setStartedInit] = useState(false);

  const [initingData, setInitingData] = useState(false);

  const [initSuccess, setInitSuccess] = useState(false);

  const [initError, setInitError] = useState("");
  const checkAuth = () => {
    axios
      .get(BACKEND_LOGIN_STATE)
      .then((response) => {
        console.log("res", response);
        // response.data.data && dispatch(setOrders({orders: response.data.data}));
        if (response.data?.state === "authorized") {
          //fetch sections
          fetchSections();
          setAuthorized(true);
          setErrMsg("");
          setAuthChecking(false);
        } else {
          setErrMsg("Loading error");
          // setLoadingState(false);
          setAuthChecking(false);
        }
      })
      .catch((err) => {
        console.table("Error", err.response, err.status);
        if (err.response?.status === 401) {
          setErrMsg("Unauthorized, try to re-login.");
          setAuthorized(false);
          // fetchSections();
          // setErrMsg("");
          // setAuthorized(true);
        } else {
          setErrMsg("Unknown error, try to re-login.");
          setAuthorized(false);
        }
        setAuthChecking(false);
      });
  };

  useEffect(() => {
    //check auth
    checkAuth();
  }, []);

  const fetchSections = async () => {
    const sections = await axios.get(GET_HSCODE_SECTIONS);
    //updateredux state
    dispatch(
      addHSCodeList({
        indexToAdd: 0,
        type: "section",
        data: sections.data,
      })
    );
  };

  const handleInitClick = () => {
    setOpenBackDrop(true);
    setOpenDialog(true);
  };

  const handleConfirmInit = async () => {
    //API reset hscode data base
    try {
      setInitingData(true);
      const result = await axios.post(INIT_ELASTIC_SEARCH);
      if (result.status === 200) {
        setInitingData(false);
        setInitSuccess(true);
        setInitError("");
      }
    } catch (error) {
      setStartedInit(true);
      setInitingData(false);
      setInitSuccess(false);
      if (error.response?.data?.message) {
        const errorMsg = error.response.data.message;
        let returnMsg = "";
        for (const msg in errorMsg) {
          if (errorMsg[msg]) {
            returnMsg += errorMsg[msg] + ". ";
          }
        }
        setInitError(returnMsg);
      } else {
        setInitError(error.toString());
      }
    }
  };

  const handleCancel = () => {
    setOpenBackDrop(false);
    setInitError("");
    setInitSuccess(false);
    setStartedInit(false);
    setOpenDialog(false);
  };

  const handleConfirmSuccess = () => {
    handleCancel();
  };

  useEffect(() => {
    //if selected a HSCode, fetch its child

    //update redux
    if (HSCodeState.selectedHSCode.length === 0) return;
  }, [HSCodeState.selectedHSCode]);

  return authChecking ? (
    <div className={classes.loadingWrapper}>
      <CircularProgress />
    </div>
  ) : authorized && !errMsg ? (
    <div className={classes.MaiPageWrapper}>
      <div className={classes.buttonGroup}>
        <Button onClick={handleInitClick} variant="contained" color="primary">
          Initiate Data
        </Button>
        {<Backdrop open={openBackDrop} classes={{ root: classes.backDrop }} />}
        {
          <Dialog open={openDialog} scroll="body">
            {initingData ? (
              <>
                <DialogTitle>Initiating Data</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  Initiating data into Elastic Search, it might takes 10 - 15
                  minutes, please be patient.
                </DialogContent>
                <div className={classes.loadingWrapper}>
                  <CircularProgress />
                </div>
              </>
            ) : initSuccess ? (
              <>
                <DialogTitle>Initiate Data Success</DialogTitle>
                <DialogContent>
                  Successfully initiated data into Elastic Search.
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmSuccess}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </>
            ) : !startedInit ? (
              <>
                <DialogTitle>Attention</DialogTitle>
                <DialogContent>
                  Confirm to initiate all of the HS Code data into Elastic
                  Search?
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirmInit}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogTitle>Initiate Data Failed</DialogTitle>
                <DialogContent>
                  Failed to initiate data into Elastic Search.
                  <DialogContentText>{initError}</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCancel}
                  >
                    close
                  </Button>
                </DialogActions>
              </>
            )}
          </Dialog>
        }
      </div>
      {HSCodeState.fetchedHSCodeList.map((l, index) => {
        return <HSSectionList data={l} key={index} listIndex={index} />;
      })}
    </div>
  ) : (
    //go back
    <Box className={classes.root}>
      {errMsg}
      <Box mt={2}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://admin.uucargo.ca/"
        >
          Go back
        </a>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    HSCodeState: state.HSCodeList,
  };
};
export default connect(mapStateToProps)(HSCodeMainPage);
