import {Fragment, useEffect, useState} from "react";
import {FormHelperText, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardSelectCarrier = ({
                                                   getDataFromChildComponent,
                                                   triggerAction,
                                                   isConfirm,
                                                   validation,
                                                   orderData,
                                                   getOrderDetails
                                               }) => {

    const [availableService, setAvailableService] = useState([]);
    const [selectCarrier, setSelectCarrier] = useState('default');
    const [originalValue, setOriginalValue] = useState('default');
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getAllAvailableServiceNames = async () => {
        let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    all: true
                }
            })
            // console.log('result', result);
            setAvailableService(result?.data);
        } catch (e) {
            console.log('error', e);
        }
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setSelectCarrier(event.target.value);
    };

    useEffect(() => {
        getAllAvailableServiceNames();
    }, [])

    useEffect(() => {
        setSelectCarrier(orderData?.account_base_combine_name?.toLowerCase());
        setOriginalValue(orderData?.account_base_combine_name?.toLowerCase());
    }, [orderData])

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({selectCarrier});
        }
    }, [triggerAction])

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    serviceName: selectCarrier,
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalValue(orderStatus);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    console.log('[LTLOrderDashboardSelectCarrier] selectCarrier', selectCarrier);
    console.log('[LTLOrderDashboardSelectCarrier] originalValue', originalValue);


    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControl fullWidth>
                        <Select
                            value={selectCarrier}
                            size='small'
                            // placeholder='Select Partner'
                            onChange={handleChange}
                            disabled={isConfirm}
                            // disabled={true}
                            error={validation && validation?.carrierMissing}
                        >
                            <MenuItem disabled value='default'>
                                <em>Select Carrier</em>
                            </MenuItem>
                            {
                                availableService?.map((carrier, index) => (
                                    <MenuItem key={index} value={carrier?.account_base.toLowerCase()}>
                                        {carrier?.account_base}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText
                            sx={{
                                color: "error.main",
                            }}
                        >
                            {(validation && validation?.carrierMissing) && 'Required field'}
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            {
                selectCarrier !== originalValue &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}