import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import PaidInvoices from "./PaidInvoices";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const AdminDashboardPaidInvoices = ({classes}) => {

    const [invoices, setInvoices] = useState([]);

    let url = useParams()

    const getPaidInvoicesList = async () => {
        let requestURI = `${PARTNER_URI}/billingCenter/operator/getPaidInvoiceListFromCrmForThirdParty/${url.partnerId}`;
        try {
            const {data} = await axios({
                method: "get",
                url: requestURI,
            });
            setInvoices(data);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getPaidInvoicesList();
    }, []);

    return (
        <Grid item xs={6} sm={6} md={6}>
            <Card
                className={classes.paper}
                style={{minHeight: "55vh", boxShadow: "none"}}
            >
                <Typography variant="h6">{`Paid Invoices`}</Typography>
                <PaidInvoices invoices={invoices}/>
            </Card>
        </Grid>
    )
}