import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Box from "@mui/material/Box";
import {value} from "lodash/seq";

export const CustomTextFieldEdit = ({textValue, onChange, field}) => {

    const [isDisable, setIsDisable] = useState(true);
    const [text, setText] = useState('');

    const handleDisable = () => {
        setIsDisable(false);
    }

    const handleInput = (event) => {
        setText(event?.target?.value);
    }

    const handleConfirmChange = () => {
        onChange(field, text);
        setIsDisable(true);
    }

    const handleDiscardChange = () => {
        setText(textValue);
        setIsDisable(true);
    }

    useEffect(() => {
        setText(textValue);
    }, [textValue])

    return (
        <TextField
            value={text}
            variant='outlined'
            fullWidth
            size='small'
            disabled={isDisable}
            onInput={handleInput}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {isDisable ?
                            <IconButton onClick={handleDisable}>
                                <EditIcon/>
                            </IconButton> :
                            <Box sx={{display: 'flex', gap: '10px'}}>
                                <IconButton onClick={handleConfirmChange}>
                                    <CheckIcon/>
                                </IconButton>
                                <IconButton>
                                    <ClearIcon onClick={handleDiscardChange}/>
                                </IconButton>
                            </Box>
                        }
                    </InputAdornment>
                ),
            }}
        />
    )
}

export const CustomTextFieldNormal = ({
                                          defaultValue,
                                          field,
                                          onChange,
                                          isConfirm,
                                          error,
                                          multiline = false,
                                          row = 1
                                      }) => {
    const [text, setText] = useState('');

    const handleInput = (event) => {
        const textInput = event?.target?.value
        setText(textInput);
        onChange(field, textInput);
    }

    useEffect(() => {
        if (defaultValue) {
            setText(defaultValue);
        }
    }, [])

    return (
        <TextField
            value={text}
            variant='outlined'
            fullWidth
            size='small'
            onInput={handleInput}
            disabled={isConfirm}
            error={error}
            helperText={error && "Required field"}
            multiline={multiline}
            rows={row}
        />
    )
}

export const CustomTextFieldNumber = ({field, onChange, isConfirm, error}) => {
    const [text, setText] = useState(0);

    const handleInput = (event) => {
        const textInput = event?.target?.value
        setText(textInput);
        onChange(field, textInput);
    }

    return (
        <TextField
            value={text}
            type='number'
            variant='outlined'
            fullWidth
            size='small'
            onInput={handleInput}
            disabled={isConfirm}
            error={error}
            helperText={(error && text <= 0) && "Greater than zero"}
        />
    )
}

export const CustomTextFieldNumberForCost = ({field, onChange, isConfirm, error, placeHolder, errorMessage}) => {
    const [text, setText] = useState('');

    const handleInput = (event) => {
        const textInput = event?.target?.value
        setText(textInput);
        onChange(field, textInput);
    }

    return (
        <TextField
            value={text}
            type='number'
            variant='outlined'
            fullWidth
            size='small'
            onInput={handleInput}
            disabled={isConfirm}
            error={error}
            placeholder={placeHolder}
            helperText={(error && text <= 0) && (errorMessage || "Greater than or equal to zero")}
        />
    )
}

export const CustomTextFieldPallet = ({textValue, field, onChange, isConfirm, error, errorMessage}) => {
    const [text, setText] = useState('');

    const handleInput = (event) => {
        const textInput = event?.target?.value
        setText(textInput);
        onChange(field, textInput);
    }

    useEffect(() => {
        setText(textValue);
    }, [textValue]);

    return (
        <TextField
            value={text}
            variant='outlined'
            fullWidth
            size='small'
            onInput={handleInput}
            disabled={isConfirm}
            error={error}
            helperText={(error && field === 'crmInvoiceNumber') ? "Invalid field" : error && (errorMessage || "Required field")}
        />
    )
}

export const CustomTextFieldNormalWithUnit = ({textValue, field, onChange, unit, isConfirm, error}) => {
    const [text, setText] = useState('');

    const handleInput = (event) => {
        const textInput = event?.target?.value
        setText(textInput);
        onChange(field, textInput);
    }

    useEffect(() => {
        setText(textValue);
    }, [textValue]);

    return (
        <TextField
            value={text}
            variant='outlined'
            fullWidth
            disabled={isConfirm}
            size='small'
            onInput={handleInput}
            type='number'
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {unit}
                    </InputAdornment>
                )
            }}
            error={error}
            helperText={(error && text <= 0) && "Greater than zero"}
        />
    )
}

