import {Fragment, useEffect, useRef, useState} from "react";
import {Grid, Snackbar, Typography} from "@mui/material";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldPallet} from "../../utils/customComponent";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardReferenceOrderNumber = ({orderData, getOrderDetails}) => {

    const [referenceOrderNumber, setReferenceOrderNumber] = useState();
    const [originalReferenceOrderNumber, setOriginalReferenceOrderNumber] = useState();

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'referenceOrderNumber':
                setReferenceOrderNumber(value);
                break;
            default:
        }
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    refNumber: referenceOrderNumber,
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalShipDateAndTime(shipDateAndTime);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        setReferenceOrderNumber(orderData?.bolInfo?.ref_no);
        setOriginalReferenceOrderNumber(orderData?.bolInfo?.ref_no);
    }, [orderData])

    console.log('order data', orderData);
    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                sx={{width: "100%"}}
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <CustomTextFieldPallet
                        textValue={referenceOrderNumber}
                        field='referenceOrderNumber'
                        onChange={onChange}
                        // isConfirm={isConfirm}
                        // error={crmInvoiceNumber && !crmInvoiceId}
                    />
                </FormControl>
            </Grid>
            {
                referenceOrderNumber !== originalReferenceOrderNumber &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}