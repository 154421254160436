import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
  link: {
    textDecoration: "none",
  },
}));

const EtransferEmailListItem = ({
  id,
  index,
  senderEmail,
  senderName,
  subject,
  createdAt,
  reviewEmail,
}) => {
  const classes = useStyles();
  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          {/* <Link
              to={`/loose-item/3rd-party/shipping/order/${id}`}> */}
          {id}
          {/* </Link> */}
        </td>
        <td>{senderEmail}</td>
        <td>{senderName}</td>
        <td>{subject}</td>
        <td>{createdAt}</td>
        {/* <td>{adminId ? <Link to={`#${adminId}`}>{admin}</Link> : <>N/A</>}</td> */}
        {/* <td>{partnerId > 0 ? <>Partner</> : <>Not Partner</>}</td> */}
        {/* <td>{partnerStatus ? <>Partner</> : <>Not Partner</>}</td> */}
        {/* <td>{companyType}</td> */}
        {/* <td>{crmNumber ? crmNumber : <>N/A</>}</td> */}
        <td>
          {/* <Link className={classes.link} to={`/v2/etransferemailList#${id}`}> */}
            <Button
              className={classes.detailBtn}
              color="secondary"
              variant="outlined"
              onClick={() => reviewEmail(index)}
            >
              Review
            </Button>
          {/* </Link> */}
        </td>
      </tr>
    </>
  );
};

export default EtransferEmailListItem;


