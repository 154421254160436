import {useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingLTLQuickQuoteSelectRateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingLTLQuickQuoteSelectRateSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    ShippingLTLQuickQuoteAddressErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
    ShippingLTLQuickQuoteSelectRateText: {
        textAlign: 'left',
        fontSize: '14px'
    }
}

export const LTLQuickQuoteServices = ({cbHandleGetServices}) => {

    const [specialService, setSpecialService] = useState([]);

    const addOrRemoveSpecialService = (serviceName) => {
        const hasServiceCode = specialService.some(service => service.serviceCode === serviceName);

        if (hasServiceCode) {
            // Remove the element with serviceCode 'serviceName'
            const updatedServices = specialService.filter(service => service.serviceCode !== serviceName);
            setSpecialService(updatedServices);
        } else {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const addSpecialService = (serviceName) => {
        const hasServiceCode = specialService.some(service => service.serviceCode === serviceName);
        if (!hasServiceCode) {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const handleAppointmentDelivery = () => {
        addOrRemoveSpecialService('APTFGT');
    }

    const handleProtectedService = () => {
        addOrRemoveSpecialService('PROTEC');
    }

    const handleInsideDelivery = () => {
        addOrRemoveSpecialService('INSDLY');
    }

    const handleInsidePickup = () => {
        addOrRemoveSpecialService('INSPIC');
    }

    const handlePrivateResidenceDelivery = () => {
        addOrRemoveSpecialService('PRESDL');
        // addSpecialService('TLGDEL');
    }

    const handlePrivateResidencePickup = () => {
        addOrRemoveSpecialService('PRESPU');
        // addSpecialService('TLGPU');
    }

    const handleTailgateDelivery = () => {
        addOrRemoveSpecialService('TLGDEL');
    }

    const handleTailgatePickup = () => {
        addOrRemoveSpecialService('TLGPU');
    }

    useEffect(() => {

        if (specialService.some(service => service.serviceCode === 'PRESPU')) {
            addSpecialService('TLGPU');
        }

        if (specialService.some(service => service.serviceCode === 'PRESDL')) {
            addSpecialService('TLGDEL');
        }

        cbHandleGetServices(specialService);

    }, [specialService]);

    console.log('[LTLQuickQuoteServices] specialService', specialService);

    return (
        <>
            <Grid item xs={12}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Additional Services
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Typography sx={styles.ShippingLTLQuickQuoteSelectRateText}>
                                Additional Charges May Apply
                            </Typography>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "PRESPU")}
                                        onChange={handlePrivateResidencePickup}
                                    />}
                                    label={
                                        <Typography>
                                            Private Residence Pickup
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "TLGPU")}
                                        onChange={handleTailgatePickup}
                                        disabled={specialService?.some(service => service.serviceCode === "PRESPU")}
                                    />}
                                    label={
                                        <Typography>
                                            Tailgate Pickup
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "INSPIC")}
                                        onChange={handleInsidePickup}
                                    />}
                                    label={
                                        <Typography>
                                            Inside Pickup
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "PROTEC")}
                                        onChange={handleProtectedService}
                                    />}
                                    label={
                                        <Typography>
                                            Protected Service
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "PRESDL")}
                                        onChange={handlePrivateResidenceDelivery}
                                    />}
                                    label={
                                        <Typography>
                                            Private Residence Delivery
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "TLGDEL")}
                                        onChange={handleTailgateDelivery}
                                        disabled={specialService.some(service => service.serviceCode === "PRESDL")}
                                    />}
                                    label={
                                        <Typography>
                                            Tailgate Delivery
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "INSDLY")}
                                        onChange={handleInsideDelivery}
                                    />}
                                    label={
                                        <Typography>
                                            Inside Delivery
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Box sx={styles.ShippingLTLQuickQuoteSelectRateSection}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={specialService.some(service => service?.serviceCode === "APTFGT")}
                                        onChange={handleAppointmentDelivery}
                                    />}
                                    label={
                                        <Typography>
                                            Appointment Delivery
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}