import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
const getStatusText = (statusCode) => {
  if (statusCode === 0) {
    return "Initial"
  }
  return "Processed";
}


const VendorInvoiceLineEach = ({
                                 lineId,
                                 trackingNumber,
                                 shippingFeeExcludeTax,
                                 shippingFeeIncludeTax,
                                 status
                               }) => {

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Typography style={{fontSize: '14px'}}>
          {lineId}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{fontSize: '14px'}}>
          {trackingNumber}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{fontSize: '14px'}}>
          {shippingFeeExcludeTax}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{fontSize: '14px'}}>
          {shippingFeeIncludeTax}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{fontSize: '14px'}}>
          {getStatusText(status)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VendorInvoiceLineEach;
