import {useEffect, useState} from "react";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {extractFullAddress, getCountryCode, provinces_states} from "../../utils/Helper";
import {FormHelperText, FormLabel, Grid, InputAdornment, MenuItem, Select, Typography} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import CurrencyFormat from "react-currency-format";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ContainerShippingAddress = ({cbHandleAddress, heading, validationResultPlaceOrder}) => {

    const classes = useStyles();

    const [addressPredictions, setAddressPredictions] = useState([]);
    const [address, setAddress] = useState({
        streetAddress: "",
        apt: "",
        city: "",
        province: "",
        postalCode: "",
        country: heading === 'Shipper' ? 'CN' : 'CA',
        name: "",
        phone: ""
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country || 'ca'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    };

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    };

    const handleInputChangeAddress = async (event, value) => {
        setAddress(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address', address?.country);
        setAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setAddress(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setAddress(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                }
            }))
        }
    }

    const handleName = (event) => {
        setAddress(prevState => ({
            ...prevState,
            name: event.target.value
        }))
    }

    const handlePhone = (event) => {
        setAddress(prevState => ({
            ...prevState,
            phone: event.target.value
        }))
    }

    const handleApt = (event) => {
        setAddress(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleCity = (event) => {
        setAddress(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleProvince = (event) => {
        setAddress(prevState => ({
            ...prevState,
            province: ({
                ...prevState?.province,
                name: event.target.value,
                code: event.target.value
            })
        }))
    }

    const handleCountry = event => {
        setAddress(prevState => ({
            ...prevState,
            country: event.target.value
        }))
    }

    const handlePostalCode = (event) => {
        setAddress(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    // useEffect(() => {
    //     setAddress(prevState => ({
    //         ...prevState,
    //         country: heading === 'Shipper' ? 'CN' : 'CA'
    //     }))
    // },[heading])

    useEffect(() => {
        cbHandleAddress(address);
    }, [address]);

    return (
        <Grid item xs={6}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    {heading}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Name
                            </FormLabel>
                            <TextField
                                value={address.name}
                                onInput={handleName}
                                fullWidth
                                variant="outlined"
                                size='small'
                                // error={validationResultPlaceOrder?.missingKeys?.includes('name')}
                                // helperText={validationResultPlaceOrder?.missingKeys?.includes('name') && 'Required'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Phone
                            </FormLabel>
                            <TextField
                                value={address.phone}
                                onInput={handlePhone}
                                fullWidth
                                variant="outlined"
                                size='small'
                                // error={validationResultPlaceOrder?.missingKeys?.includes('phone') || validationResultPlaceOrder?.phoneError}
                                // helperText={validationResultPlaceOrder?.missingKeys?.includes('phone') ? 'Required' :
                                //     validationResultPlaceOrder?.phoneError && 'Invalid'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Address
                            </FormLabel>
                            <Autocomplete
                                freeSolo
                                value={address?.displayAddress}
                                fullWidth
                                options={addressPredictions}
                                filterOptions={(options, state) => options}
                                getOptionLabel={option => option?.text}
                                onInputChange={handleInputChangeAddress}
                                onChange={handleChangeAddress}
                                PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                renderInput={params => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    variant="outlined"
                                    // error={validationResult?.validateMovingFromMissing?.includes('streetAddress')}
                                    // helperText={validationResult?.validateMovingFromMissing?.includes('streetAddress') && 'Required'}
                                />}
                                renderOption={option => (
                                    <Typography style={{fontSize: '12px'}}>
                                        {option.description}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Apt / Suite
                            </FormLabel>
                            <TextField
                                value={address.apt}
                                onInput={handleApt}
                                fullWidth
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                City
                            </FormLabel>
                            <TextField
                                value={address.city}
                                onInput={handleCity}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    style: {padding: '2px 0'},
                                }}
                                size='small'
                                // error={validationResult?.validateMovingFromMissing?.includes('city')}
                                // helperText={validationResult?.validateMovingFromMissing?.includes('city') && 'Required'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Province
                            </FormLabel>
                            <TextField
                                value={address?.province?.code}
                                onInput={handleProvince}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    style: {padding: '2px 0'},
                                }}
                                size='small'
                                // error={validationResult?.validateMovingFromMissing?.includes('city')}
                                // helperText={validationResult?.validateMovingFromMissing?.includes('city') && 'Required'}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Country
                            </FormLabel>
                            <Select
                                value={address?.country}
                                size='small'
                                onChange={handleCountry}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            // width: 250,
                                        },
                                    },
                                }}
                                style={{textAlign: 'left'}}
                            >
                                {
                                    getCountryCode().map(v => {
                                        return <MenuItem value={v.code}>{v.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Postal Code
                            </FormLabel>
                            <TextField
                                value={address.postalCode}
                                onInput={handlePostalCode}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    style: {padding: '2px 0'},
                                }}
                                size='small'
                                // error={validationResult?.validateMovingFromMissing?.includes('postalCode') || validationResult?.movingFromPostalCodeError}
                                // helperText={validationResult?.validateMovingFromMissing?.includes('postalCode') ? 'Required' : validationResult?.movingToPostalCodeError && "Invalid"}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )

}