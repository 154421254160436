import {Container, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useState} from "react";
import {AccountEndUser} from "./AccountEndUser";
import {AccountPartner} from "./AccountPartner";
import {AccountJoinCompany} from "./AccountJoinCompany";
import {AccountCompany} from "./AccountCompany";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const Account = () => {

    const [accountType, setAccountType] = useState(2);

    const handleAccountType = (type) => {
        setAccountType(type);
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Create Account
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%'
                            }}>
                                <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                    Is this account for
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    checked={accountType === 1}
                                    onClick={() => handleAccountType(1)}
                                />
                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                    Individual
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    checked={accountType === 2}
                                    onClick={() => handleAccountType(2)}
                                />
                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                    Business
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    checked={accountType === 3}
                                    onClick={() => handleAccountType(3)}
                                />
                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                    Company
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    icon={<RadioButtonUncheckedIcon/>}
                                    checkedIcon={<CheckCircleIcon/>}
                                    color="success"
                                    checked={accountType === 4}
                                    onClick={() => handleAccountType(4)}
                                />
                                <Typography style={{color: '#545454', padding: '0 5px'}}>
                                    Company Member
                                </Typography>
                            </Box>
                        </Grid>
                        {
                            accountType === 1 ? <AccountEndUser/> :
                                accountType === 2 ? <AccountPartner/> :
                                    accountType === 3 ? <AccountCompany/> :
                                        <AccountJoinCompany/>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}