import {FormLabel, Grid, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldPallet} from "../../utils/customComponent";
import {Fragment, useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {extractFullAddress} from "../../utils/Helper";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLQuoteShipper = ({
                                    getDataFromChildComponent,
                                    triggerAction,
                                    isConfirm,
                                    validation,
                                }) => {

    const classes = useStyles();

    const [shipperCity, setShipperCity] = useState();
    const [shipperProvince, setShipperProvince] = useState();
    const [shipperCountry, setShipperCountry] = useState();
    const [shipFromCityPredictions, setShipFromCityPredictions] = useState([]);
    const [displayCity, setDisplayCity] = useState({
        description: null,
        placeId: null,
        text: null
    });

    const onChange = (field, value) => {
        switch (field) {
            case 'shipperCity':
                setShipperCity(value);
                break;
            case 'shipperProvince':
                setShipperProvince(value);
                break;
            case 'shipperCountry':
                setShipperCountry(value);
                break;
            default:
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeCity = async (event, value) => {
        setDisplayCity(prevState => ({
            ...prevState,
            description: '',
            placeId: '',
            text: value
        }));
        setShipperCity(value);
        const predictionsCity = await googleMapsPlaceAutocomplete(value, 'locality|sublocality|neighborhood|postal_code|administrative_area_level_2');
        setShipFromCityPredictions(predictionsCity);
    }

    const handleChangeCity = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setShipperCity(address?.sublocality || address?.city || address?.administrative_area_level_3 || address?.region);
            setShipperProvince(address?.state?.code);
            setShipperCountry(address?.country?.code);
        } else {
            setDisplayCity('');
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                shipperInfo: {
                    shipperCity,
                    shipperProvince,
                    shipperCountry,
                }
            });
        }
    }, [triggerAction]);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Ship From
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            City / Postal Code
                        </FormLabel>
                        <Autocomplete
                            freeSolo
                            value={displayCity}
                            fullWidth
                            disabled={isConfirm}
                            options={shipFromCityPredictions}
                            filterOptions={(options, state) => options}
                            getOptionLabel={option => option?.text}
                            onInputChange={handleInputChangeCity}
                            onChange={handleChangeCity}
                            PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                            renderInput={params => <TextField
                                {...params}
                                className={classes.smallInput}
                                variant="outlined"
                                error={validation?.shipFromMissing?.includes('shipperCity')}
                                helperText={
                                    (validation?.shipFromMissing?.includes('shipperCity')) && 'Required Field'
                                }
                            />}
                            renderOption={option => (
                                <Typography>
                                    {option?.description}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Province
                        </FormLabel>
                        <CustomTextFieldPallet
                            textValue={shipperProvince}
                            field='shipperProvince'
                            onChange={onChange}
                            isConfirm={isConfirm}
                            error={validation?.shipFromMissing?.includes('shipperProvince')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Country
                        </FormLabel>
                        <CustomTextFieldPallet
                            textValue={shipperCountry}
                            field='shipperCountry'
                            onChange={onChange}
                            isConfirm={isConfirm}
                            error={validation?.shipFromMissing?.includes('shipperCountry')}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    )
}