import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";

const headCells = [
    {
        id: "orderId",
        numeric: false,
        // disablePadding: true,
        label: "Order ID / Ref No.",
    },
    {
        id: "totalChargeIncludeTax",
        numeric: false,
        // disablePadding: true,
        label: "Total Charge Include Tax",
    },
    {
        id: "createdDate",
        numeric: true,
        disablePadding: false,
        label: "Created Date",
    },
];

function EnhancedTableHead(props) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort,
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        size="small"
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            size="small"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function NotBilledOrders({orders}) {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = orders.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

    return (
        <TableContainer style={{maxHeight: 500}}>
            <Table
                // sx={{Width: 400}}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
            >
                <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={orders.length}
                />
                <TableBody>
                    {
                        orders.map(row => {
                            return <TableRow
                                hover
                                tabIndex={-1}
                                key={row.name}
                                // selected={isItemSelected}
                            >
                                <TableCell component="th" scope="row">
                                    {/*{row.InvoiceNumber}*/}
                                    {row.order_id}
                                    / {row.ref_order_no}
                                </TableCell>
                                <TableCell component="th" align="center">
                                    <CurrencyFormat
                                        value={(Math.abs((row.total_amount_include_tax + row.service_fee_include_gst) || 0).toFixed(2))}
                                        displayType={'text'} thousandSeparator={true}
                                        prefix={((row.total_amount_include_tax + row.service_fee_include_gst) || 0) >= 0 ? `${row.currency_code} $` : `${row.currency_code} $-`}/>
                                </TableCell>
                                <TableCell component="th" align="right">
                                    {dayjs(row.created_at).format("YYYY-MM-DD HH:mm")}
                                </TableCell>
                            </TableRow>
                        })
                    }
                    {emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: (dense ? 33 : 53) * emptyRows,
                            }}
                        >
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
