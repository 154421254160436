import React, {useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Snackbar
} from "@material-ui/core";
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import dayjs from "dayjs";
import {Alert} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {PARTNER_URI} from "../../../utils/apiUrl";

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue"
        },
        lineHeight: "2rem",
        textAlign: "left"
    }
}));

export default function ShippingListTrackingTable({
                                                      tran_id,
                                                      order_id,
                                                      ref_order_no,
                                                      cargo_control_no,
                                                      ref_product_no,
                                                      ref_other_no,
                                                      account_base,
                                                      gross_price,
                                                      net_price,
                                                      zip_label_uri,
                                                      label_pdf_uri,
                                                      date,
                                                      send_to_name,
                                                      send_to_email,
                                                      send_to_tel,
                                                      send_to_addr,
                                                      send_to_city,
                                                      send_to_postal_code,
                                                      send_to_province,
                                                      send_to_country,
                                                      currency_code,
                                                      transactionNumbers,
                                                      tran_status,
                                                      shipping_id_no
                                                  }) {
    const classes = useStyles();

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const [cancelTrackingNumberText, setCancelTrackingNumberText] = useState("");
    const [cancelTrackingNumberErrorMessage, setCancelTrackingNumberErrorMessage] = useState("");
    const [cancelLoading, setCancelLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const cancelOrderByTransactionId = async () => {
        try {
            setCancelLoading(true);
            const {data} = await axios.delete(`${PARTNER_URI}/loose-item/3rd-party/internal/cancel-shipping`, {
                data: {
                    trackingNumber: shipping_id_no
                }
            });
            console.log(data);
            setCancelTrackingNumberErrorMessage("");
            setIsCancelled(true);
        } catch (e) {
            console.log(e);
            setCancelTrackingNumberErrorMessage(e?.response?.data?.errorMessage || "Error!");
        } finally {
            setCancelLoading(false);
        }
    }

    const confirmCancelOrder = async (e) => {
        e.preventDefault();
        await cancelOrderByTransactionId();
        handleToastClick();
        handleDialogClose();
    }

    // Cancel Tracking Order
    const onCancelOrder = () => {
        handleDialogOpen();
    }

    return (
        <>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order">
                {(() => {
                    if (cancelTrackingNumberErrorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                                Transaction can't be cancelled!
                                <hr/>
                                Error: {cancelTrackingNumberErrorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            Transaction has been cancelled!
                        </Alert>
                    )
                })()}
            </Snackbar>
            {/*Pop-up Toast*/}
            {/*Confirm Delete Tracking Dialog*/}
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Tracking Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will cancel ALL the trackings related to this
                        Transaction <b>{transactionNumbers}</b>. Are you sure to cancel?
                        <hr/>
                        Click <b>Confirm</b> to cancel this shipping.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <Button onClick={confirmCancelOrder} variant="outlined" type="button" disabled={cancelLoading}>
                        {cancelLoading && <CircularProgress size={14}/>}
                        {!cancelLoading && "Confirm"}
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Confirm Delete Tracking Dialog*/}
            <tr className={classes.listItemWrapper}>
                <td>
                    <Link
                        to={`#${tran_id}`}>
                        {tran_id}
                    </Link>
                </td>
                <td>{order_id}</td>
                <td>
                    Ref Order #: {ref_order_no}
                    <br/>
                    Ref Product #: {ref_product_no}
                    <br/>
                    Ref Other #: {ref_other_no}
                </td>
                {/*<td>{ref_order_no}</td>*/}
                {/*<td>{ref_product_no}</td>*/}
                {/*<td>{ref_other_no}</td>*/}
                {/*<td>{`${send_to_name ? send_to_name : "N/A"} / ${send_to_email ? send_to_email : "N/A"} / ${send_to_tel ? send_to_tel : "N/A"}`}</td>*/}
                {/*<td>{`${send_to_addr ? send_to_addr : "N/A"} / ${send_to_city ? send_to_city : "N/A"} / ${send_to_province ? send_to_province : "N/A"} / ${send_to_country ? send_to_country : "N/A"} / ${send_to_postal_code ? send_to_postal_code : "N/A"}`}</td>*/}
                <td>
                    {/*{`${send_to_name ? send_to_name : "N/A"} ${send_to_email ? send_to_email : "N/A"}, ${send_to_tel ? send_to_tel : "N/A"}`}*/}
                    {send_to_name ? send_to_name : "N/A"}
                    <br/>
                    {send_to_tel ? send_to_tel : "N/A"}, {send_to_email ? send_to_email : "N/A"}
                    <br/>
                    {send_to_addr}
                    <br/>
                    {send_to_city}, {send_to_province}, {send_to_country}, {send_to_postal_code}
                </td>
                <td>{account_base}</td>
                <td>
                    excl. tax: <CurrencyFormat value={gross_price} displayType={'text'} thousandSeparator={true}
                                               prefix={`${currency_code} $`}/>
                    <br/>
                    inc. tax: <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true}
                                              prefix={`${currency_code} $`}/>
                </td>
                {/*<td>*/}
                {/*  <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />*/}
                {/*</td>*/}
                {/* <td>{transactionNumbers}</td> */}
                <td>
                    <a href={getLabelURL(label_pdf_uri, zip_label_uri)} target="_blank" rel="noopener noreferrer">
                        {getLabelURL(label_pdf_uri, zip_label_uri) ? "Download" : ""}
                    </a>
                </td>
                {/*<td>*/}
                {/*  <a href={zip_label_uri} target="_blank" rel="noopener noreferrer">*/}
                {/*    {zip_label_uri ? "Download" : ""}*/}
                {/*  </a>*/}
                {/*</td>*/}
                {/*<td>*/}
                {/*  <a href={label_pdf_uri} target="_blank" rel="noopener noreferrer">*/}
                {/*    {label_pdf_uri ? "Download" : ""}*/}
                {/*  </a>*/}
                {/*</td>*/}
                <td>{dayjs(date).format("YYYY-MM-DD HH:mm")}</td>
                {
                    tran_status && <td>
                        {
                            !isCancelled ? (
                                <button onClick={() => {
                                    onCancelOrder();
                                }}>Cancel</button>
                            ) : "Cancelled"
                        }
                    </td>
                }
            </tr>
        </>
    );
}
