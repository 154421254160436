import PropTypes from "prop-types";
import MenuBar from "./MenuBar";
import { Box } from "@material-ui/core";
import SideBar from "./SideBar";

const MainContainer = ({ children }) => {

    return (
        <>
            <MenuBar/>
            {/*<SideBar/>*/}
            <Box sx={{ marginTop: "72px" }}>
            </Box>
        </>
    );
};

MainContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
        .isRequired,
};

export default MainContainer;
