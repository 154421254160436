import React, { useState } from "react";
import { Box, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { NODE_ROUTE_URI } from "../../utils/apiUrl";
const ReviceShippingOrderInvoices = () => {

  const [shippingId, setShippingId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("Revise Success!");

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  const submitReviseOrders = async ({ orderNumbers }) => {
    const { data } = await axios.post(`${NODE_ROUTE_URI}/reviseInvoice/reviseShippingPackageOrders`, {
      orderNumbers
    });
    console.log(data);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setErrorMessage("");
      const result = await submitReviseOrders({
        orderNumbers: shippingId
      });
      handleToastClick();
    } catch (e) {
      console.log(e);
      setErrorMessage(e?.response?.data?.error || "Error!");
      handleToastClick();
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <>
      <Box>
        {/*Pop-up Toast*/}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Submit Transaction"
        >
          {(() => {
            if (errorMessage !== "") {
              return (
                <Alert
                  onClose={handleToastClose}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Error!
                  <hr />
                  {errorMessage}
                </Alert>
              );
            }
            return (
              <Alert
                onClose={handleToastClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {successMessage}
              </Alert>
            );
          })()}
        </Snackbar>
        <Box sx={{
          display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
          alignItems: "center", alignContent: "center"
        }}>
          <h1>Revise Shipping Order Invoices</h1>
          <Box flex="45%" width="100%" sx={{
            p: 4,
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            backgroundColor: "white",
            borderRadius: 2,
          }}>
            <Typography>Enter Shipping Order Ids, one per line.</Typography>
            <TextField
              sx={{
                width: "100%",
                mb: 1,
              }}
              multiline
              value={shippingId}
              onChange={v => {
                setShippingId(v.target.value);
              }}
              rows={6}
              // placeholder="Enter your comma seperated tracking numbers here"
            />
          </Box>
          <Box display="flex" sx={{ gap: "3rem" }}>
            {/*<LoadingButton*/}
            {/*  variant="contained"*/}
            {/*  component="span"*/}
            {/*  color="primary"*/}
            {/*  style={{*/}
            {/*    textTransform: "unset",*/}
            {/*    fontSize: "1rem",*/}
            {/*    width: "7rem",*/}
            {/*  }}*/}
            {/*  loading={isLoading}*/}
            {/*  type="submit"*/}
            {/*  onClick={handleSubmit(onSubmit)}*/}
            {/*>*/}
            {/*  Revise*/}
            {/*</LoadingButton>*/}
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              onClick={handleSubmit}
              sx={{
                color: "white",
                backgroundColor: "primary.main",
                "&:hover": {
                  color: "primary.main",
                  backgroundColor: "white",
                  borderColor: "primary.main",
                  borderStyle: "solid",
                  borderWidth: 1,
                },
                borderRadius: 5,
                width: 100,
                height: 35,
                mb: 1,
              }}>Revise</LoadingButton>
          </Box>
        </Box>
      </Box>
    </>

  );
};

export default ReviceShippingOrderInvoices;
