import {
    Button,
    FormHelperText,
    FormLabel,
    Grid,
    InputAdornment,
    Link,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import HelpIcon from "@mui/icons-material/Help";
import {
    checkHSCodeInValid,
    generateRandomString,
    getCountryCode,
    HS_CODE_HELPER,
    HS_CODE_LOOKUP_URL,
    UOMList
} from "../../utils/Helper";
import SearchIcon from "@material-ui/icons/Search";
import Box from "@mui/material/Box";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";

export const CustomsBrokerageItem = ({
                                         item,
                                         index,
                                         metric,
                                         updateItemList,
                                         duplicateItem,
                                         removeItem,
                                         errorList,
                                         validation
                                     }) => {

    const [itemInfo, setItemInfo] = useState(item);

    const handleDescription = event => {
        setItemInfo(prevState => ({
            ...prevState,
            description: event.target.value
        }))
    }

    const handleSku = event => {
        setItemInfo(prevState => ({
            ...prevState,
            sku: event.target.value
        }))
    }

    const handleCountryCode = event => {
        setItemInfo(prevState => ({
            ...prevState,
            originCountryCode: event.target.value
        }))
    }

    const handleUOM = event => {
        setItemInfo(prevState => ({
            ...prevState,
            UOM: event.target.value
        }))
    }

    const handleHSCode = event => {
        const {value} = event.target;

        // Regular expression to allow only numbers and periods
        const regex = /^[0-9.]+$/;
        if (value === '' || regex.test(value)) {
            setItemInfo(prevState => ({
                ...prevState,
                hscode: event.target.value
            }))
        }
    }

    const handleWeight = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemInfo(prevState => ({
                ...prevState,
                weight: value
            }))
        }
    }

    const handleQuantity = event => {
        setItemInfo(prevState => ({
            ...prevState,
            quantity: event.target.value
        }))
    }

    const handlePrice = event => {
        const {value} = event.target;
        if (/^\d*\.?\d*$/.test(value)) {
            setItemInfo(prevState => ({
                ...prevState,
                priceEach: value
            }))
        }
    }

    const handleCurrency = event => {
        setItemInfo(prevState => ({
            ...prevState,
            currency: event.target.value
        }))
    }

    // const handleDuplicate = () => {
    //     const itemId = generateRandomString(8);
    //     const updatedItem = {...itemInfo};
    //     updatedItem.itemId = itemId;
    //
    // }

    useEffect(() => {
        setItemInfo(item)
    }, [item?.itemId]);

    useEffect(() => {
        updateItemList(itemInfo);
    }, [itemInfo]);

    useEffect(() => {
        setItemInfo(prevState => ({
            ...prevState,
            weightUnit: metric ? 'kg' : 'lb'
        }))
    }, [metric]);

    console.log('[CustomsBrokerageItem] item info', itemInfo);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography style={{textAlign: 'left', color: '#1D8B45'}}>
                    # {+index + 1}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Description
                    </FormLabel>
                    <TextField
                        value={itemInfo?.description}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        onInput={handleDescription}
                        error={!validation && !itemInfo?.description}
                        helperText={(!validation && !itemInfo?.description) && 'Required'}
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                        SKU
                    </FormLabel>
                    <TextField
                        value={itemInfo?.sku}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        onInput={handleSku}
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Unit Of Measure
                    </FormLabel>
                    <Select
                        value={itemInfo?.UOM}
                        size='small'
                        style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                        onChange={handleUOM}
                    >
                        {
                            UOMList?.map(v => {
                                return <MenuItem value={v}>{v}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Origin Country
                    </FormLabel>
                    <Select
                        value={itemInfo?.originCountryCode}
                        size='small'
                        style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                        onChange={handleCountryCode}
                    >
                        {
                            getCountryCode()?.map(v => {
                                return <MenuItem value={v.code}>{v.name}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        HS Code <span><Link underline="none" target="_blank"
                                            rel="noreferrer" href={HS_CODE_HELPER}><HelpIcon
                        sx={{color: '#1D8B45', fontSize: '14px'}}/></Link></span>
                    </FormLabel>
                    <TextField
                        value={itemInfo?.hscode}
                        size='small'
                        inputProps={{style: {fontSize: 14}}}
                        onInput={handleHSCode}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Link href={HS_CODE_LOOKUP_URL}
                                          target="_blank"
                                          rel="noopener"
                                          sx={{
                                              color: '#1D8B45',
                                              textDecoration: 'none', // Ensure no underline initially
                                              '&:hover': {
                                                  textDecoration: 'underline',
                                                  color: '#1D8B45', // Ensure no underline on hover
                                              },
                                          }}>
                                        <Box sx={{display: 'flex'}}>
                                            <SearchIcon sx={{color: '#454545'}}/>
                                            <Typography
                                                sx={{
                                                    textTransform: 'none',
                                                    color: '#1D8B45',
                                                    fontSize: '14px'
                                                }}>
                                                HS Code Lookup
                                            </Typography>
                                        </Box>
                                    </Link>
                                </InputAdornment>
                            )
                        }}
                        error={(!validation && !itemInfo?.hscode) || checkHSCodeInValid(errorList, itemInfo?.hscode)}
                        helperText={
                            (!validation && !itemInfo?.hscode) ? 'Required' :
                                checkHSCodeInValid(errorList, itemInfo?.hscode) && 'HS Code is invalid'
                        }
                    />
                    {
                        checkHSCodeInValid(errorList, itemInfo?.hscode) &&
                        <Link underline="none" target="_blank" rel="noreferrer" href={HS_CODE_HELPER}
                              sx={{
                                  textDecoration: 'none',
                                  '&:hover': {textDecoration: 'underline', color: '#1D8B45'}
                              }}>
                            <Typography sx={{
                                fontSize: '14px', color: '#1D8B45', textAlign: 'left'
                            }}>
                                Learn more about correctly inputting HS codes.
                            </Typography>
                        </Link>
                    }
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Quantity
                    </FormLabel>
                    <TextField
                        value={itemInfo?.quantity}
                        onInput={handleQuantity}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        placeholder='quantity'
                        type='number'
                        InputProps={{
                            style: {fontSize: 14}
                        }}
                        error={!validation && itemInfo?.quantity <= 0}
                        helperText={
                            (!validation && itemInfo?.quantity <= 0) && "Missing or less than 1"
                        }
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Unit Weight
                    </FormLabel>
                    <TextField
                        value={itemInfo?.weight}
                        onInput={handleWeight}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        type='number'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {itemInfo?.weightUnit}
                                </InputAdornment>
                            ),
                            style: {fontSize: 14}
                        }}
                        error={!validation && itemInfo?.weight <= 0}
                        helperText={
                            (!validation && itemInfo?.weight <= 0) && "Missing or Invalid"
                        }
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                        Total Weight
                    </FormLabel>
                    <TextField
                        value={itemInfo?.weight * itemInfo?.quantity}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        placeholder='total Weight'
                        type='number'
                        disabled={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {itemInfo?.weightUnit}
                                </InputAdornment>
                            ),
                            style: {fontSize: 14}
                        }}
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl required fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                        Currency
                    </FormLabel>
                    <Select
                        value={itemInfo?.currency}
                        size='small'
                        style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                        onChange={handleCurrency}
                    >
                        <MenuItem value='CAD'>CAD</MenuItem>
                        <MenuItem value='USD'>USD</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{fontSize: '14px', textAlign: 'left'}}>
                        Unit Price
                    </FormLabel>
                    <TextField
                        value={itemInfo?.priceEach}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        type='number'
                        onInput={handlePrice}
                        error={!validation && itemInfo?.priceEach <= 0}
                        helperText={
                            (!validation && itemInfo?.priceEach <= 0) && "Missing or Invalid"
                        }
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item md={2} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{fontSize: '14px', textAlign: 'left'}}>
                        Total Price
                    </FormLabel>
                    <TextField
                        value={itemInfo?.priceEach * itemInfo?.quantity}
                        size='small'
                        inputProps={{style: {fontSize: 14}}} // font size of input text
                        placeholder='total price'
                        type='number'
                        disabled={true}
                    >
                    </TextField>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end'
                }}>
                    <IconButton onClick={() => duplicateItem(itemInfo)}>
                        <ContentCopyIcon sx={{color: '#1D8B45'}}/>
                    </IconButton>
                    <IconButton onClick={() => removeItem(itemInfo)}>
                        <DeleteIcon sx={{color: '#DC3545'}}/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}