import Box from "@mui/material/Box";
import {
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Link,
    Snackbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {Alert} from "@mui/lab";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";

const style = {
    PackageOrderDashboardTransactionEachRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '5px',
        padding: '10px',
    }
}

export const PackageOrderTransactionEach = ({selectTransactionData}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 480px)');

    const [cancelLoading, setCancelLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cancelTrackingNumberErrorMessage, setCancelTrackingNumberErrorMessage,] = useState("");
    const [trackingPackageDetailList, setTrackingPackageDetailList] = useState([]);
    const [declaredValue, setDeclaredValue] = useState(0);
    const [batteryDetails, setBatteryDetails] = useState({});
    const [signatureOptions, setSignatureOptions] = useState(null);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const getValidTrackingListByTranId = async () => {

        const requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getValidTrackingListByTranId/${selectTransactionData?.tran_id}`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            console.log('[getValidTrackingListByTranId] result', result);
            const {data} = result;
            setTrackingPackageDetailList(data);
            const totalDeclaredValue = data?.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.declared_value
            }, 0);
            setDeclaredValue(totalDeclaredValue);
            setBatteryDetails(prevState => ({
                ...prevState,
                material: data[0]?.battery_material,
                packing: data[0]?.battery_packing
            }))
            setSignatureOptions(data[0]?.signature_option)
        } catch (e) {
            console.log('error', e);
        }
    }

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    };

    const totalOtherCharge = () => {
        let totalShippingCharge = +selectTransactionData?.freight_charge +
            +selectTransactionData?.fuel_surcharge +
            +selectTransactionData?.handling_charge +
            +selectTransactionData?.insurance_charge +
            +selectTransactionData?.over_size_charge +
            +selectTransactionData?.over_weight_charge +
            +selectTransactionData?.residential_charge +
            +selectTransactionData?.rural_charge +
            +selectTransactionData?.signature_charge +
            +selectTransactionData?.sur_charge
        totalShippingCharge = totalShippingCharge.toFixed(2);
        let otherCharge = selectTransactionData?.total_shipping_charge - +totalShippingCharge;
        // otherCharge = otherCharge?.toFixed(2);
        return otherCharge
    }

    // Cancel Tracking Order
    const onCancelOrder = () => {
        handleDialogOpen();
    };

    const cancelOrderByTransactionId = async () => {

        const requestURL = `${NODE_ROUTE_URI}/looseItem/admin/cancelShippingTransaction`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    tranId: selectTransactionData?.tran_id
                }
            })

            console.log('[cancelOrderByTransactionId] result', result);
            setCancelTrackingNumberErrorMessage("");
            setTimeout(() => {
                history.push("/v2/package-orders");
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setCancelTrackingNumberErrorMessage(
                e?.response?.data?.errorMessage || "Error!"
            );
        }
    }

    const confirmCancelOrder = async (e) => {
        e.preventDefault();
        await cancelOrderByTransactionId();
        handleToastClick();
        handleDialogClose();
    };

    useEffect(() => {
        if (selectTransactionData) {
            getValidTrackingListByTranId();
        }
    }, [selectTransactionData]);

    console.log('[PackageOrderTransactionEach] trackingPackageDetailList', trackingPackageDetailList);
    console.log('[PackageOrderTransactionEach] selectTransactionData', selectTransactionData);

    return (
        <Box style={style.PackageOrderDashboardTransactionEachRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (cancelTrackingNumberErrorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Transaction can't be cancelled!
                                <hr/>
                                Error: {cancelTrackingNumberErrorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Transaction has been cancelled!
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will cancel ALL the trackings related to this
                        Transaction {selectTransactionData?.tran_tracking_nos}
                        <hr/>
                        Click Confirm to cancel this shipping.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        No
                    </Button>
                    <Button
                        onClick={confirmCancelOrder}
                        variant="outlined"
                        type="button"
                        disabled={cancelLoading}
                    >
                        {cancelLoading && <CircularProgress size={14}/>}
                        {!cancelLoading && 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                Package Details
                            </Typography>
                        </Grid>
                        {!isMobile &&
                            <>
                                <Grid item xs={2}>
                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                        Tracking Number
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                        Dimension
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                        Weight
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                        Declared Value
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                        Status
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                            </>
                        }
                        {
                            trackingPackageDetailList?.map((element, index) => (
                                <Fragment key={index}>
                                    <Grid item md={2} xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {isMobile && `Description: `}{element?.item_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={3} xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {isMobile && `Tracking Number: `}<Link
                                            href={element?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${element?.label_uri}` : '#'}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="hover"
                                            sx={{
                                                color: '#1D8B45',
                                            }}
                                        >{element?.tracking_no}</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {isMobile && `Dimension: `}{element?.length}x{element?.width}x{element?.height} {element?.dimension_unit}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {isMobile && `Weight: `}{element?.weight} {element?.weight_unit}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {isMobile && `Declared: `}$ {element?.declared_value}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {isMobile && `Status: `}
                                            {
                                                (() => {
                                                    if (+element.tn_status === 1 || +element.tn_status === 0) {
                                                        return 'On The Way';
                                                    }
                                                    if (+element.tn_status === 2) {
                                                        return 'Delivered';
                                                    }
                                                    return 'Cancelled';
                                                })()
                                            }
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </Fragment>
                            ))
                        }
                    </Grid>
                </Grid>
                {
                    (declaredValue > 0 || batteryDetails?.material || signatureOptions) &&
                    <Grid item md={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                    Shipping Options
                                </Typography>
                            </Grid>
                            {
                                declaredValue > 0 &&
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Declared Value
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        $ {declaredValue.toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Insurance Charge
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        $ {(+selectTransactionData?.insurance_charge).toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                signatureOptions &&
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                Signature Options
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{textAlign: 'left', fontSize: '14px'}}>
                                                Signature Required
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {
                                batteryDetails?.material &&
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                style={{
                                                    fontWeight: '600',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                Battery
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Battery Material
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {batteryDetails?.material === 'LITHIUM_METAL' ? 'Lithium Metal' : 'Lithium Ion'}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Battery Packing
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT' ? 'Contained in equipment' : 'Packed with equipment'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                }
                <Grid item xl={9} md={7} xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                Cost Details
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'left'
                                }}>
                                Freight Charge
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'left',
                                fontSize: '14px'
                            }}>
                                $ {(+selectTransactionData?.freight_charge)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'left'
                                }}>
                                Fuel Surcharge
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'left',
                                fontSize: '14px'
                            }}>
                                $ {(+selectTransactionData?.fuel_surcharge)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        {(+selectTransactionData?.signature_charge) > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Signature Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.signature_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.dangerous_charge) > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Dangerous Good Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.dangerous_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.residential_charge) > 0 &&
                            <>
                                < Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Residential Surcharge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.residential_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.insurance_charge) > 0 &&
                            <>
                                < Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Insurance Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.insurance_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.over_size_charge) > 0 &&
                            <>
                                < Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Oversize Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.over_size_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.over_weight_charge) > 0 &&
                            <>
                                < Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Overweight Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.over_weight_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.sur_charge) > 0 &&
                            <>
                                < Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Surcharge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.sur_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {totalOtherCharge() > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Other Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography
                                        style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '14px'
                                        }}>
                                        $ {totalOtherCharge().toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'left'
                                }}>
                                Total Shipping Charge
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'left',
                                fontSize: '14px'
                            }}>
                                $ {(+selectTransactionData?.total_shipping_charge)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        {
                            (+selectTransactionData?.service_fee_exclude_tax) > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Service Fee
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.service_fee_exclude_tax)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.discount) > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Brokerage Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        ($ {(+selectTransactionData?.discount)?.toFixed(2)})
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={7}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'left'
                                }}>
                                Taxes
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'left',
                                fontSize: '14px'
                            }}>
                                $ {(+selectTransactionData?.total_tax)?.toFixed(2)}
                            </Typography>
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    <Divider/>*/}
                        {/*</Grid>*/}
                        {(+selectTransactionData?.total_brokerage_charge) > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Brokerage Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.total_brokerage_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {(+selectTransactionData?.total_duties_taxes_charge) > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Typography
                                        style={{
                                            fontSize: '14px',
                                            textAlign: 'left'
                                        }}>
                                        Duty Taxes Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'left',
                                        fontSize: '14px'
                                    }}>
                                        $ {(+selectTransactionData?.total_duties_taxes_charge)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography
                                style={{
                                    fontSize: '14px',
                                    textAlign: 'left'
                                }}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'left',
                                fontSize: '14px'
                            }}>
                                $ {(+selectTransactionData?.final_total)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={3} md={5} xs={12}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        height: '100%'
                    }}>
                        <Grid container spacing={1}>
                            {/*<Grid item xs={12}>*/}
                            {/*    <Button*/}
                            {/*        variant='outlined'*/}
                            {/*        sx={{*/}
                            {/*            border: '2px solid #1D8B45',*/}
                            {/*            "&:hover": {*/}
                            {/*                border: '2px solid #1D8B45',*/}
                            {/*                filter: 'brightness(0.9)'*/}
                            {/*            },*/}
                            {/*            display: 'flex',*/}
                            {/*            gap: '5px',*/}
                            {/*            width: '100%'*/}
                            {/*        }}*/}
                            {/*        onClick={handleTrackingInfo}*/}
                            {/*    >*/}
                            {/*        /!*<TrackLabelIcon width='20' height='20' fill='#1D8B45'/>*!/*/}
                            {/*        <Typography style={{*/}
                            {/*            textTransform: 'none',*/}
                            {/*            fontSize: '14px',*/}
                            {/*            color: '#1D8B45',*/}
                            {/*        }}>*/}
                            {/*            Tracking Information*/}
                            {/*        </Typography>*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <Button
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                        display: 'flex',
                                        gap: '5px',
                                        width: '100%',
                                    }}
                                >
                                    {/*<PrintLabelIcon width='20' height='20' fill='#1D8B45'/>*/}
                                    <Link
                                        href={getLabelURL(`${LABEL_DOWNLOAD_URL_PREFIX}${selectTransactionData?.label_pdf_uri}`, `${LABEL_DOWNLOAD_URL_PREFIX}${selectTransactionData?.label_zip_uri}`)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        underline="none"
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                            fontSize: '14px',
                                            color: '#1D8B45',
                                        }}>
                                            Print Label
                                        </Typography>
                                    </Link>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#7C7C7C',
                                        "&:hover": {
                                            backgroundColor: '#7C7C7C',
                                            filter: 'brightness(0.9)'
                                        },
                                        width: '100%'
                                    }}
                                    disabled={+selectTransactionData?.tran_status !== 1}
                                    onClick={onCancelOrder}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                        Cancel
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}