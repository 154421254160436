import {AdminDashboardSummary} from "./AdminDashboardSummary";
import {AdminDashboardNotBilledOrders} from "./AdminDashboardNotBilledOrders";
import {AdminDashboardPaidInvoices} from "./AdminDashboardPaidInvoices";
import {AdminDashboardUnpaidInvoices} from "./AdminDashboardUnpaidInvoices";
import {AdminDashboardTopUpInvoices} from "./AdminDashboardTopUpInvoices";
import AdminDashboardStyle from "./AdminDashboardStyle";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";

export const AdminDashboard = () => {

    let leftSpace = useSelector(state => state.menuBarReducer.leftSpace)

    const classes = AdminDashboardStyle();

    return (
        <div style={{marginLeft: `${leftSpace}px`}}>
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 12, sm: 12, md: 12}}>
                    <AdminDashboardSummary classes={classes}/>
                    <AdminDashboardNotBilledOrders classes={classes}/>
                    <AdminDashboardUnpaidInvoices classes={classes}/>
                    <AdminDashboardPaidInvoices classes={classes}/>
                    <AdminDashboardTopUpInvoices classes={classes}/>
                </Grid>
            </Box>
        </div>
    )
}