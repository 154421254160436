import {Divider, Grid, Typography} from "@mui/material";

export const CustomBrokerageRating = ({crossBorderFee}) => {

    console.log('[CustomBrokerageRating] crossBorderFee', crossBorderFee);
    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <Typography sx={{textAlign: 'right'}}>
                    Brokerage Fee
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {(+crossBorderFee?.totalBrokerageFees)?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{textAlign: 'right'}}>
                    Duty And Tax
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{textAlign: 'right'}}>
                    $ {(+crossBorderFee?.totalDutyandTax)?.toFixed(2)}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{textAlign: 'right', fontWeight: '600'}}>
                    Total
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography sx={{textAlign: 'right', fontWeight: '600'}}>
                    $ {(+crossBorderFee?.grandTotal)?.toFixed(2)}
                </Typography>
            </Grid>
        </Grid>
    )
}