import React, { useState } from "react";
import { makeStyles, Button, Dialog, List, ListItem } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
}));

export default function CsvShipTableItem({ data }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>{data.line}</td>
        <td>{data.productId}</td>
        <td>{data.totalPrice}</td>
        <td>
          <Button>
            {" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={data.labelUri || ""}
            >
              Download
            </a>
          </Button>
        </td>
        {/* <td>{data.trackingNum}</td> */}
        <td>
          {data.trackingNumList && (
            <Button onClick={() => setOpen(true)}>T List</Button>
          )}
        </td>
        <td>{data.error || ""}</td>
      </tr>
      <Dialog
        onClose={handleClose}
        aria-labelledby="tracing-numbers"
        open={open}
        scroll={"paper"}
      >
        <List>
          {data.trackingNumList &&
            data.trackingNumList
              .split(",")
              .map((item) => <ListItem key={item}>{item}</ListItem>)}
        </List>
      </Dialog>
    </>
  );
}
