import React, { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  Button,
  Snackbar,
  CircularProgress,
  // FormControlLabel,
  // FormControl,
  // Radio,
  // RadioGroup,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import produce from "immer";

import {
  BACKEND_LOGIN_STATE,
  UPLOAD_RATING_XLSX_FILE,
  UPLOAD_SHIPPING_XLSX_FILE,
} from "../../utils/apiUrl";
import { errHandling } from "../../utils/fetchUtils";
import CustomInput from "../../shared/customInput";
import CsvRateTableItem from "./csvRateTableItem";
import CsvShipTableItem from "./csvShipTableItem";
import AddressInputCombo from "./Components/addressInputCombo";

import axios from "axios";

const initForm = {
  name: "",
  address: "",
  addressLineTwo: "",
  zipCode: "",
  city: "",
  province: "",
  phone: "",
};

//Test mode
// const testMode = process.env.NODE_ENV === "production" ? false : true;

// const accountOptions = ["UPS: JLINK", "UPS: UUC-TRO",
//   "Fedex: fedexuucrcm", "Fedex: fedexuuctro", "Fedex: fedexuucmtl", "Fedex: fedexuuccalg"];

// const accountOptionsMap = new Map([
//   ["UPS: JLINK", "upsjlink"],
//   ["UPS: UUC-TRO", "upsuuctro"],
//   ["Fedex: UUC-Richmond", "fedexuucrcm"],
//   ["Fedex: UUC-TRO", "fedexuuctro"],
//   ["Fedex: UUC-Montreal", "fedexuucmtl"],
//   ["Fedex: UUC-Calgary", "fedexuuccalg"]
// ])

const accountOptions = [
  "UPS: JLINK",
  "UPS: UUC-TRO",
  "Fedex: JLINK",
  "Fedex: UUC-TRO",
];

const accountOptionsValue = {
  "UPS: JLINK": "upsjlink",
  "UPS: UUC-TRO": "upsuuctro",
  "Fedex: JLINK": "fedexuucrcm",
  "Fedex: UUC-TRO": "fedexuuctro",
};

const addressOptions = {
  upsjlink: {
    name: "jlink1",
    address: "6751 Westminster Hwy",
    city: "Richmond",
    zipCode: "V7C4V4",
    province: "BC",
    phone: "6043215355",
  },
  upsuuctro: {
    name: "TOUV",
    address: "Unit 12,13 - 1 Select Ave",
    city: "Scarborough",
    zipCode: "M1V5J3",
    province: "ON",
    phone: "4169000110",
  },
  fedexuucrcm: {
    name: "jlink1",
    address: "6751 Westminster Hwy",
    city: "Richmond",
    zipCode: "V7C4V4",
    province: "BC",
    phone: "6043215355",
  },
  fedexuuctro: {
    name: "TOUV",
    address: "Unit 12,13 - 1 Select Ave",
    city: "Scarborough",
    zipCode: "M1V5J3",
    province: "ON",
    phone: "4169000110",
  },
};

function CsvFFHanlder() {
  const classes = useStyles();

  const matches = useMediaQuery("(max-width:600px)");

  const matches800 = useMediaQuery("(max-width:800px)");

  // Form data to store all the address information
  const [formatData, setFormatData] = useState(initForm);

  // const [errMsg, setErrMsg] = useState("");
  const [uploadErrMsg, setUploadErrMsg] = useState("");

  const [fileObj, setFileObj] = useState(null);

  const [contactFileObj, setContactFileObj] = useState(null);

  const [uploading, setUploadingState] = useState(false);

  const [optionEmtpy, setOptionEmpty] = useState(false);

  const [listRateData, setListRateData] = useState([]);

  const [listShippingData, setListShippingData] = useState([]);

  const [uploadingContact, setUploadingContactState] = useState(false);

  const [uploadingContactShipping, setUploadingContactShippingState] =
    useState(false);

  const [uploadingSuccess, setUploadingSuccessState] = useState(false);

  const [uploadingContactSuccess, setUploadingContactSuccessState] =
    useState(false);

  const [account, setAccount] = useState(null);

  const [typeValue, setTypeValue] = useState("rate");

  const [totalPriceWithTaxes, setToalPriceWithTaxes] = useState("");

  const [theInputKey, setTheInputKey] = useState(null);

  const functionThatResetsTheFileInput = () => {
    const randomString = Math.random().toString(36);
    setTheInputKey(randomString);
  };

  const handleChange = (_value) => {
    setTypeValue(_value);
  };

  const handleAddressChange = (fieldName) => (e) => {
    setFormatData(
      produce((draft) => {
        draft[fieldName] = e.target.value;
      })
    );
  };

  const bodyFormData = new FormData();

  const onDrop = (_type) => (e) => {
    if (!(e.target.files && e.target.files[0])) return;
    setUploadingSuccessState(false);
    if (_type === "bol") {
      setFileObj({
        selectedFile: e.target.files[0],
        //**** added line below ****//
        selectedFileName: e.target.files[0].name,
      });
    } else {
      setContactFileObj({
        selectedFile: e.target.files[0],
        //**** added line below ****//
        selectedFileName: e.target.files[0].name,
      });
    }
  };

  const handleCloseSnackBar = () => {
    setUploadErrMsg("");
  };

  const handleSubmit = (_type) => {
    if (!account) {
      setOptionEmpty(true);
      return;
    }
    const tmpFileObj = _type === "bol" ? fileObj : contactFileObj;
    if (tmpFileObj?.selectedFile) {
      _type === "bol"
        ? setUploadingState(true)
        : setUploadingContactState(true);
      // const accountValue =
      //   account === "UPS: UUC-TRO" ? "upsuuctro" : "upsjlink";
      const accountValue = accountOptionsValue[account];
      try {
        bodyFormData.append("xlsx_file", tmpFileObj.selectedFile);
        bodyFormData.append("accountBaseCombinedName", accountValue);
        bodyFormData.append("shipFromAddressData", JSON.stringify(formatData));
      } finally {
        axios({
          method: "post",
          url: UPLOAD_RATING_XLSX_FILE,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data;",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then((res) => {
            setToalPriceWithTaxes(res.data.totalPriceWithTaxes);
            setListRateData(res.data.dataList);
            setUploadingContactSuccessState(true);
            setContactFileObj(null);
            setUploadingContactState(false);
          })
          .catch((error) => {
            errHandling(error, setUploadErrMsg);
            _type === "bol"
              ? setUploadingState(false)
              : setUploadingContactState(false);
          });
      }
    }
  };

  const handleSubmitForShipping = () => {
    if (!account) {
      setOptionEmpty(true);
      return;
    }
    const tmpFileObj = contactFileObj;
    if (tmpFileObj?.selectedFile) {
      // setUploadingContactState(true);
      setUploadingContactShippingState(true);
      // const accountValue = account === "UPS: JLINK" ? "upsjlink" : "upsuuctro";
      const accountValue = accountOptionsValue[account];
      try {
        bodyFormData.append("xlsx_file", tmpFileObj.selectedFile);
        bodyFormData.append("accountBaseConbinedName", accountValue);
        bodyFormData.append("shipFromAddressData", JSON.stringify(formatData));
      } finally {
        axios({
          method: "post",
          url: UPLOAD_SHIPPING_XLSX_FILE,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data;",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then((res) => {
            setListShippingData(res.data.result);
            setUploadingContactSuccessState(true);
            setUploadingContactShippingState(false);
            // setContactFileObj(null);
            // setUploadingContactState(false);
          })
          .catch((error) => {
            errHandling(error, setUploadErrMsg);
            // setUploadingContactState(false);
            setUploadingContactShippingState(false);
          });
      }
    }
  };

  return (
    <>
      <Box
        // className={
        //   matches ? classes.smPackageTabContainer : classes.packageTabContainer
        // }
        width={matches ? "100%" : "600px"}
        margin="0 auto"
      >
        <br />
        {/* {getMainComponent(authChecking, errMsg, authorized)} */}

        <Box
          display="flex"
          flexDirection="column"
          width="300px"
          margin="0 auto"
          justifyContent="center"
          alignItems="center"
          mb={4}
        >
          <Box textAlign="center" mb={2}>
            {/* Max. file size: <b>10MB</b> <br /> */}
            Upload contacts files, file type: <b>XLS, XLSX</b>
          </Box>
          <input
            accept=".xlsx, .xls"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            // onChange={()={}}
            onChange={onDrop("contacts")}
            key={theInputKey || ""}
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
              }}
              onClick={functionThatResetsTheFileInput}
            >
              Select one file
            </Button>
          </label>
          {contactFileObj && (
            <Box className={classes.buttonFilesWrapper} mt={3}>
              <Box display="flex" alignItems="center">
                <InsertDriveFileIcon />
                {contactFileObj.selectedFileName}
              </Box>
              {/* <Box mt={2} mb={1}>
                <Button
                  variant="contained"
                  component="span"
                  style={{
                    textTransform: "unset",
                    fontSize: "1rem",
                    backgroundColor: "rgba(0,0,255,0.2)",
                    width: "7rem",
                  }}
                  onClick={() => handleSubmit("contacts")}
                >
                  {uploadingContact ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </Box> */}
            </Box>
          )}
          {uploadingContactSuccess && (
            <Box textAlign="center" mt={3} color="green" fontSize="1.2rem">
              File uploaded successfully.
            </Box>
          )}
        </Box>
        <hr />

        <Box mt={2} mb={4}>
          <CustomInput
            label="Select an account:"
            value={account}
            onChange={(e) => {
              // if (e.target.value === "UPS: JLINK") {
              //   setAccount("upsjlink");
              // } else {
              //   setAccount("upsuuctro");
              // }
              if (e.target.value) {
                const accountValue = e.target.value;
                setOptionEmpty(false);
                const addressObj =
                  addressOptions[accountOptionsValue[accountValue]];
                setFormatData({
                  name: addressObj.name,
                  address: addressObj.address,
                  addressLineTwo: "",
                  zipCode: addressObj.zipCode,
                  city: addressObj.city,
                  province: addressObj.province,
                  phone: addressObj.phone,
                });
              }
              setAccount(e.target.value);
            }}
            type="select"
            options={accountOptions}
          />
          <Box pt="1vh" width="100%">
            {optionEmtpy ? (
              <Alert severity="error">{"Account option is required"}</Alert>
            ) : (
              ""
            )}
          </Box>
        </Box>

        <hr />

        <Box
          display="flex"
          ml={2}
          mt={2}
          width="100%"
          justifyContent="space-between"
        >
          <Box visibility={typeValue === "rate" ? "hidden" : "visible"}>
            <Button
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "10rem",
              }}
              onClick={() => handleChange("rate")}
            >
              {"<- Go to ratings"}
            </Button>
          </Box>

          {typeValue === "rate" && (
            <Box>
              <Button
                variant="contained"
                component="span"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  width: "7rem",
                  ...(uploadingContact
                    ? { backgroundColor: "rgba(0,0,255,0.2)" }
                    : {}),
                }}
                onClick={() => handleSubmit("contacts")}
              >
                {uploadingContact ? <CircularProgress size={24} /> : "Get rate"}
              </Button>
            </Box>
          )}

          {typeValue === "shipping" && (
            <Box>
              <Button
                variant="contained"
                component="span"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  ...(uploadingContactShipping
                    ? { backgroundColor: "rgba(0,0,255,0.2)" }
                    : {}),
                }}
                onClick={handleSubmitForShipping}
              >
                {uploadingContactShipping ? (
                  <CircularProgress size={24} />
                ) : (
                  "Start placing order"
                )}
              </Button>
            </Box>
          )}

          <Box visibility={typeValue === "rate" ? "visible" : "hidden"}>
            <Button
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "10rem",
              }}
              onClick={() => handleChange("shipping")}
            >
              {"Go to shipping ->"}
            </Button>
          </Box>
        </Box>
        {typeValue === "rate" && (
          <Box
            mt={2}
            mb={2}
          >{`Total price with taxes: C$ ${totalPriceWithTaxes}`}</Box>
        )}
      </Box>

      <Box width={matches800 ? "100%" : "800px"} margin="0 auto">
        <AddressInputCombo
          handleChange={handleAddressChange}
          addressObject={formatData}
        />
      </Box>

      <Box width={matches800 ? "100%" : "800px"} margin="0 auto">
        {typeValue === "rate" && (
          <Box mt={2}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.idTH}>Row number</th>
                  <th className={classes.nameTH}>Product / SKU</th>
                  <th className={classes.nameTH}>Rates</th>
                  <th className={classes.nameTH}>RatesWithTaxes</th>
                  <th>Errors</th>
                </tr>
              </thead>
              <tbody className={classes.tbody}>
                {listRateData.map((s, index) => {
                  return <CsvRateTableItem data={s} key={index} />;
                })}
              </tbody>
            </table>
          </Box>
        )}

        {typeValue === "shipping" && (
          <Box mt={2}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.idTH}>Row#</th>
                  <th className={classes.nameTH}>Product/SKU</th>
                  <th className={classes.nameTH}>Price</th>
                  <th className={classes.nameTH}>Labels</th>
                  <th className={classes.nameTH}>Tracking#</th>
                  <th>Errors</th>
                </tr>
              </thead>
              <tbody className={classes.tbody}>
                {listShippingData.map((s, index) => {
                  return <CsvShipTableItem data={s} key={index} />;
                })}
              </tbody>
            </table>
          </Box>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={Boolean(uploadErrMsg)}
          onClose={handleCloseSnackBar}
          message={uploadErrMsg}
          autoHideDuration={3000}
        />
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
}));

export default CsvFFHanlder;
