import {Fragment, useEffect, useState} from "react";
import {FormLabel, Grid, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldNumber} from "../../utils/customComponent";
import TextField from "@mui/material/TextField";
import {calculateTaxes} from "../../utils/Helper";
import Box from "@mui/material/Box";

export const LTLQuoteCost = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [fuelCharge, setFuelCharge] = useState(0);
    const [tariffCharge, setTariffCharge] = useState(0);
    const [crossBorderCharge, setCrossBorderCharge] = useState(0);
    const [taxCharge, setTaxCharge] = useState(0);
    const [cashCollectFee, setCashCollectFee] = useState(0);
    const [carbonTax, setCarbonTax] = useState(0);
    const [convenienceFee, setConvenienceFee] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [taxes, setTaxes] = useState([]);
    const [selectTax, setSelectTax] = useState(null);
    const [currencyCode, setCurrencyCode] = useState('CAD');

    const onChange = (field, value) => {
        switch (field) {
            case 'fuelCharge':
                setFuelCharge(+value);
                break;
            case 'tariffCharge':
                setTariffCharge(+value);
                break;
            case 'taxCharge':
                setTaxCharge(+value);
                break;
            case 'crossBorderCharge':
                setCrossBorderCharge(+value);
                break;
            case 'cashCollectFee':
                setCashCollectFee(+value);
                break;
            case 'carbonTax':
                setCarbonTax(+value);
                break;
            case 'convenienceFee':
                setConvenienceFee(+value);
                break;
            default:
        }
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setTaxCharge(+event.target.value.amount);
        setSelectTax(event.target.value);
    };

    const handleCurrencyCode = (event) => {
        setCurrencyCode(event?.target?.value);
    }

    useEffect(() => {
        setTaxCharge(0);
        setSelectTax(null);
    }, [taxes])

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                shippingCost: {
                    fuelCharge,
                    tariffCharge: +tariffCharge + +cashCollectFee + +carbonTax + +convenienceFee,
                    crossBorderCharge,
                    taxCharge,
                    cashCollectFee,
                    carbonTax,
                    convenienceFee,
                    currencyCode
                }
            });
        }
    }, [triggerAction]);

    useEffect(() => {
        const subtotalCost = +fuelCharge + +tariffCharge + +convenienceFee + +cashCollectFee + +carbonTax;
        setSubTotal(subtotalCost.toFixed(2));
    }, [fuelCharge, tariffCharge, convenienceFee, cashCollectFee, carbonTax]);

    useEffect(() => {
        const taxes = calculateTaxes(subTotal);
        setTaxes(taxes);
    }, [subTotal]);

    console.log('[LTLQuoteCost] taxes', taxes);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                            Cost Information
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center'
                        }}>
                            <FormLabel>
                                Currency
                            </FormLabel>
                            <Select
                                value={currencyCode}
                                size='small'
                                onChange={handleCurrencyCode}
                            >
                                <MenuItem value='CAD'>
                                    CAD
                                </MenuItem>
                                <MenuItem value='USD'>
                                    USD
                                </MenuItem>
                            </Select>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth required>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Tariff Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='tariffCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                    error={validation?.tariffChargeError}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Cash Collect Fee
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='cashCollectFee'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Carbon Tax
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='carbonTax'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Convenience Fee
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='convenienceFee'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Fuel Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='fuelCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Subtotal
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    value={subTotal}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Tax Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <Select
                                    value={taxCharge}
                                    size='small'
                                    onChange={handleChange}
                                    renderValue={() => selectTax?.amount || 0}
                                    disabled={isConfirm}
                                    style={{textAlign: 'left'}}
                                >
                                    {
                                        taxes?.map((tax, index) => (
                                            <MenuItem key={index} value={tax}>
                                                {tax?.percent} | {tax?.amount}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                {/*<CustomTextFieldNumber*/}
                                {/*    field='taxCharge'*/}
                                {/*    onChange={onChange}*/}
                                {/*    isConfirm={isConfirm}*/}
                                {/*/>*/}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Cross Border Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='crossBorderCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Final Total
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    value={+tariffCharge + +fuelCharge + +taxCharge + +crossBorderCharge + +cashCollectFee + +carbonTax + +convenienceFee}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}