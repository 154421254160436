import React, { useRef, useState } from 'react';
import { Box, Button, TextField, useMediaQuery, FormControl, FormLabel, RadioGroup,FormControlLabel, Radio } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import RoutePriceTable from "./RoutePriceTable";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX, SERVICE_SERVICE_URL } from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const RoutePrice = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");


  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);

  const { register, handleSubmit, control } = useForm();
  const [shippingListData, setShippingListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputFile = useRef(null);
  const [fileObj, setFileObj] = useState(null);
  const [fileName, setFileName] = useState("");
  const [taxRate, setTaxRate] = useState(12);
  const [errorMessage, setErrorMessage] = useState("");

  // const getData = async ({ order_id, product_id, ref_other_no, account_base, cargo_control_no, send_to_name, send_to_tel, send_to_addr, currentPage }) => {
  //     const { data } = await axios.get(`${PARTNER_URI}/shipping/list?account_base=${account_base}&order_id=${order_id}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.id}&cargo_control_no=${cargo_control_no}&send_to_name=${send_to_name}&send_to_tel=${send_to_tel}&send_to_addr=${send_to_addr}&page=${currentPage - 1}`);
  //     const result = data.result;
  //     setShippingListData(result);
  //     try {
  //     } catch (e) {
  //       console.log(e);
  //       setShippingListData([]);
  //     } finally {
  //       setIsLoading(false);
  //     }
  // }
  const getData = async () => {
    console.log(fileObj);
    try {
      if (!fileObj) {
        return;
      }
      const formData = new FormData();
      formData.append("csv_file", fileObj);
      const { data } = await axios.post(`${SERVICE_SERVICE_URL}/routeprice/excel/area-to-area`, formData, {
        headers: {
          "Content-Type": "multipart/form-data;",
          "X-Requested-With": "XMLHttpRequest",
        }
      })
      console.log(data.result);
      setShippingListData(data.result);
      setErrorMessage("");
    } catch (e) {
      console.log(e);
      setErrorMessage("Error! This CSV file is invalid! Either row errors or you already submit this before!");
    }
  }

  const onSubmit = data => {
    // console.log(data);
    getData();
  }

  const onDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) {
      return;
    }
    setFileObj(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  return (
      <>

        <Box>
          <Box component="form" width={"1000px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
            alignItems: "center", alignContent: "center"
          }}>
            <h1>Create / Update Route Price</h1>
            <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }}>
              <Button
                variant="contained"
                component="span"
                color="primary"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                }}
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                Select one file
              </Button>
              <input type="file" {...register("file")} style={{ display: "none" }} ref={inputFile} onChange={onDrop} accept=".csv" name="file" />
            </Box>
            {fileName && (
              <Box className={classes.buttonFilesWrapper} mt={3}>
                <Box display="flex" alignItems="center">
                  <InsertDriveFileIcon />
                  {fileName}
                </Box>
              </Box>
            )}
            <Box mt={2} mb={4}>
              {errorMessage !== "" &&
                <Alert severity="error">
                  {errorMessage}
                </Alert>}
            </Box>
            <Box>
              <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  style={{
                    textTransform: "unset",
                    fontSize: "1rem",
                    width: "7rem",
                  }}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Box>
          </Box>

          <br />
          {
            isLoading ? (
                <>
                <Box>
                  <h1>Loading...</h1>
                </Box>
                </>
            ) : (
                <>
                  <Box>
                    <hr width="100%" />
                    <Box>
                      <Box mt={2}>
                        <table className={classes.table}>
                          <thead>
                          <tr>
                            <th className={classes.idTH}>Route Price ID</th>
                            <th className={classes.idTH}>Route ID</th>
                            <th className={classes.idTH}>Departure Area</th>
                            <th className={classes.idTH}>Arrival Area</th>
                            <th className={classes.nameTH}>Shipment Type ID</th>
                            <th className={classes.nameTH}>Price</th>
                            <th className={classes.nameTH}>Bonus</th>
                            <th className={classes.nameTH}>Service Fee</th>
                            <th className={classes.nameTH}>Insurance Fee</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {shippingListData.map((v, index) => {
                            return <RoutePriceTable key={index}
                                                    routePriceId={v.id}
                                                    routeId={v.route_id}
                                                    departureArea={v.departure_area}
                                                    departureAreaId={v.arrival_area_id}
                                                    arrivalArea={v.departure_area}
                                                    arrivalAreaId={v.arrival_area_id}
                                                    shipmentTypeId={v.shipment_type_id}
                                                    price={v.price}
                                                    bonus={v.bonus}
                                                    serviceFee={v.service_fee}
                                                    insuranceFee={v.insurance_fee}
                            />;
                          })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  </Box>
                </>
            )
          }
        </Box>
      </>
  );
};

export default RoutePrice;
