import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import CompanyListItem from "./CompanyListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const CompanyListTable = ({ companyList }) => {
  const classes = useStyles();
  return (
    <div>
      <Box maxWidth="70%" margin="0 auto">
        <Box mt={2}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.idTH}>ID</th>
                <th className={classes.idTH}>Name</th>
                <th className={classes.idTH}>Email</th>
                <th className={classes.idTH}>Phone</th>
                <th className={classes.nameTH}>Address</th>
                <th className={classes.nameTH}>Admin</th>
                <th className={classes.nameTH}>Partner Status</th>
                <th className={classes.nameTH}>Type</th>
                <th className={classes.nameTH}>CRM #</th>
                <th className={classes.nameTH}></th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {companyList.map((v, index) => {
                return (
                  <CompanyListItem
                    key={index}
                    id={v.id}
                    businessName={v.business_name}
                    email={v.email}
                    phone={v.phone}
                    address={`${v.address} ${v.city} ${v.province} ${v.postal_code}`}
                    adminId={v.admin_id}
                    admin={v.administrator}
                    partnerId={v.partner_id}
                    partnerStatus={v.partner_status}
                    companyType={v.company_type}
                    crmNumber={v.crm_number}
                  />
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </div>
  );
};

export default CompanyListTable;
