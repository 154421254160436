import {FormLabel, Grid} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormalWithUnit, CustomTextFieldPallet} from "../../utils/customComponent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEffect, useState} from "react";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG} from "../../utils/Helper";

export const LTLOrderDashboardPalletEach = ({pallet, handleUpdatePallet, handleDeletePallet, isConfirm, metric}) => {
    const [palletInfo, setPalletInfo] = useState();

    const onChange = (field, value) => {
        switch (field) {
            case 'description':
                setPalletInfo(prevState => ({
                    ...prevState,
                    description: value
                }))
                break;
            case 'length':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        length: value
                    }))
                }
                break;
            case 'width':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        width: value
                    }))
                }
                break;
            case 'height':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        height: value
                    }))
                }
                break;
            case 'weight':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        weight: value
                    }))
                }
                break;
            case 'quantity':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        quantity: value
                    }))
                }
                break;
            default:
        }
    }

    useEffect(() => {
        setPalletInfo(pallet);
    }, [pallet?.palletId]);

    useEffect(() => {
        handleUpdatePallet(palletInfo);
    }, [palletInfo]);


    useEffect(() => {
        if (metric && pallet?.weightUnit?.toLowerCase() !== 'kg') {
            setPalletInfo(prevState => ({
                ...prevState,
                lengthUnit: 'cm',
                weightUnit: 'kg',
                weight: convertLBToKG(pallet?.weight),
                length: convertINToCM(pallet?.length),
                width: convertINToCM(pallet?.width),
                height: convertINToCM(pallet?.height),
            }))
        } else if (!metric && pallet?.weightUnit?.toLowerCase() !== 'lb') {
            setPalletInfo(prevState => ({
                ...prevState,
                lengthUnit: 'in',
                weightUnit: 'lb',
                weight: convertKGToLB(pallet?.weight),
                length: convertCMToIN(pallet?.length),
                width: convertCMToIN(pallet?.width),
                height: convertCMToIN(pallet?.height),
            }))
        }
    }, [metric])

    // console.log('pallet each from parent', pallet);
    // console.log('pallet metric', metric, palletInfo?.palletId);
    // console.log('pallet each from local', palletInfo);

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Description
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={pallet?.description}
                        field='description'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={7}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Length
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.length}
                                field='length'
                                onChange={onChange}
                                unit={pallet?.lengthUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Width
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.width}
                                field='width'
                                onChange={onChange}
                                unit={pallet?.lengthUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Height
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.height}
                                field='height'
                                onChange={onChange}
                                unit={pallet?.lengthUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Weight
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.weight}
                                field='weight'
                                onChange={onChange}
                                unit={pallet?.weightUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Quantity
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={pallet?.quantity}
                        field='quantity'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        // unit={pallet?.weightUnit}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <IconButton
                        disabled={isConfirm}
                        onClick={() => handleDeletePallet(pallet)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}