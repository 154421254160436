import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function RoutePriceTable({
  routePriceId,
  routeId,
  departureArea,
  departureAreaId,
  arrivalArea,
  arrivalAreaId,
  shipmentTypeId,
  price,
  bonus,
  serviceFee,
  insuranceFee
}) {
  const classes = useStyles();

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>{routePriceId}</td>
        <td>{routeId}</td>
        <td>{departureAreaId} - {departureArea}</td>
        <td>{arrivalAreaId} - {arrivalArea}</td>
        <td>{shipmentTypeId}</td>
        <td>
          <CurrencyFormat value={price} displayType={'text'} thousandSeparator={true} prefix={`$`} />
        </td>
        <td>
          <CurrencyFormat value={bonus} displayType={'text'} thousandSeparator={true} prefix={`$`} />
        </td>
        <td>
          <CurrencyFormat value={serviceFee} displayType={'text'} thousandSeparator={true} prefix={`$`} />
        </td>
        <td>
          <CurrencyFormat value={insuranceFee} displayType={'text'} thousandSeparator={true} prefix={`$`} />
        </td>
      </tr>
    </>
  );
}
