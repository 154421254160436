import {FormLabel, Grid, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldPallet} from "../../utils/customComponent";
import {Fragment, useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {extractFullAddress} from "../../utils/Helper";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLOrderListShipperAddress = ({
                                               getDataFromChildComponent,
                                               triggerAction,
                                               isConfirm,
                                               validation,
                                               selectPartner,
                                               getShipperResidential
                                           }) => {

    const [residential, setResidential] = useState(false);
    const [shipperName, setShipperName] = useState();
    const [shipperPhone, setShipperPhone] = useState();
    const [shipperCompanyName, setShipperCompanyName] = useState();
    const [shipperEmail, setShipperEmail] = useState();
    const [shipperAddress, setShipperAddress] = useState();
    const [shipperAddressTwo, setShipperAddressTwo] = useState();
    const [shipperCity, setShipperCity] = useState();
    const [shipperProvince, setShipperProvince] = useState();
    const [shipperCountry, setShipperCountry] = useState();
    const [shipperPostalCode, setShipperPostalCode] = useState();
    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);
    const [displayAddress, setDisplayAddress] = useState({
        description: null,
        placeId: null,
        text: null
    });

    const classes = useStyles();

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setDisplayAddress(prevState => ({
            ...prevState,
            description: '',
            placeId: '',
            text: value
        }));
        setShipperAddress(value);
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setDisplayAddress(value);
            setShipperCity(address?.city);
            setShipperProvince(address?.state?.code);
            setShipperCountry(address?.country?.code);
            setShipperPostalCode(address?.zip);
        } else {
            setDisplayAddress('');
        }
    }

    const verifyAddressIsResidential = async () => {
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let data = {
            address: shipperAddress,
            city: shipperCity,
            province: shipperProvince,
            postalCode: shipperPostalCode,
            country: shipperCountry
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            // console.log('result', result);
            setResidential(result?.data?.result);
            getShipperResidential(result?.data?.result);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'shipperName':
                setShipperName(value);
                break;
            case 'shipperPhone':
                setShipperPhone(value);
                break;
            case 'shipperCompanyName':
                setShipperCompanyName(value);
                break;
            case 'shipperEmail':
                setShipperEmail(value);
                break;
            case 'shipperAddress':
                setShipperAddress(value);
                break;
            case 'shipperAddressTwo':
                setShipperAddressTwo(value);
                break;
            case 'shipperCity':
                setShipperCity(value);
                break;
            case 'shipperProvince':
                setShipperProvince(value);
                break;
            case 'shipperCountry':
                setShipperCountry(value);
                break;
            case 'shipperPostalCode':
                setShipperPostalCode(value);
                break;
            default:
        }
    }
    //
    // console.log('display address', displayAddress);
    // console.log('shipper address', shipperAddress);

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                shipperInfo: {
                    shipperName,
                    shipperPhone,
                    shipperEmail,
                    shipperCompanyName,
                    shipperAddress,
                    shipperAddressTwo,
                    shipperCity,
                    shipperProvince,
                    shipperCountry,
                    shipperPostalCode
                }
            });
        }
    }, [triggerAction])

    useEffect(() => {
        if (selectPartner) {
            setShipperName(`${selectPartner?.firstname} ${selectPartner?.lastname}`);
            setShipperPhone(selectPartner?.phone);
            setShipperCompanyName(selectPartner?.business_name);
            setShipperEmail(selectPartner?.email);
        }
    }, [selectPartner]);

    useEffect(() => {
        if (shipperAddress && shipperCity && shipperProvince && shipperCountry && shipperPostalCode) {
            getShipperResidential(false);
            verifyAddressIsResidential();
        }
    }, [shipperAddress, shipperCity, shipperProvince, shipperCountry, shipperPostalCode])

    console.log('[LTLOrderListShipperAddress] residential', residential);

    return (
        <Fragment>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Name
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperName}
                        field='shipperName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperName')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Phone
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperPhone}
                        field='shipperPhone'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperPhone')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Company
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperCompanyName}
                        field='shipperCompanyName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperCompanyName')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Email
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperEmail}
                        field='shipperEmail'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperEmail')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address
                    </FormLabel>
                    <Autocomplete
                        freeSolo
                        value={displayAddress}
                        fullWidth
                        disabled={isConfirm}
                        options={shipFromAddressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputChangeAddress}
                        onChange={handleChangeAddress}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                            error={validation?.shipFromMissing?.includes('shipperAddress')}
                            helperText={
                                (validation?.shipFromMissing?.includes('shipperAddress')) && 'Required Field'
                            }
                        />}
                        renderOption={option => (
                            <Typography>
                                {option?.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address Two
                    </FormLabel>
                    <CustomTextFieldNormal
                        field='shipperAddressTwo'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        City
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperCity}
                        field='shipperCity'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperCity')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Province
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperProvince}
                        field='shipperProvince'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperProvince')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Country
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperCountry}
                        field='shipperCountry'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperCountry')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Postal Code
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperPostalCode}
                        field='shipperPostalCode'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperPostalCode')}
                    />
                </FormControl>
            </Grid>
            {
                residential &&
                <Grid item xs={12}>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        Attention: This is a residential address according to Canada Post.
                    </Typography>
                </Grid>
            }
        </Fragment>
    )
}