import HelpIcon from '@mui/icons-material/Help';
import Button from '@mui/material/Button';
import {useEffect, useState} from "react";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import CurrencyFormat from "react-currency-format";
import {useParams, Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import actions from "../Action"
import {Card, CardContent, CardHeader, Tooltip, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export const AdminDashboardSummary = ({classes}) => {

    const [availableCredit, setAvailableCredit] = useState(0);

    const [notBillAmount, setNotBillAmount] = useState(0);

    const [partnerInfo, setPartnerInfo] = useState({})

    const [unpaidTotalChargeIncludeTax, setUnpaidTotalChargeIncludeTax] = useState(0);


    let url = useParams()

    const getPartnerInfo = async () => {
        let requestURI = `${PARTNER_URI}/partner/operator/getPartnerInfo/${url.partnerId}`;

        try {
            const {data} = await axios({
                method: "get",
                url: requestURI,
            });
            // console.log(data.partner)
            setPartnerInfo(data.partner)
        } catch (e) {
            console.log(e);
        }
    };

    const getCreditData = async () => {
        let requestURI = `${PARTNER_URI}/billingCenter/operator/getCRMCreditFromCRMByPartnerId/${url.partnerId}`;

        try {
            const {data} = await axios({
                method: "get",
                url: requestURI,
            });
            setAvailableCredit(data.result);
        } catch (e) {
            console.log(e);
        }
    };

    const getNotBillAmount = async () => {
        try {
            let requestURI = `${PARTNER_URI}/billingCenter/operator/getAmountOfNotBilledOrderForPartner/${url.partnerId}`;
            try {
                const {data} = await axios({
                    method: "get",
                    url: requestURI,
                });
                setNotBillAmount(data.result);
            } catch (e) {
                console.log(e);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getUnpaidListData = async () => {
        let requestURI = `${PARTNER_URI}/billingCenter/operator/getNotPaidInvoiceListFromCrmForThirdParty/${url.partnerId}`;

        try {
            const {data} = await axios({
                method: "get",
                url: requestURI,
            });
            setUnpaidTotalChargeIncludeTax(data.totalChargeIncludeTax);
        } catch (e) {
            console.log(e);
        }
    };

    const history = useHistory();

    const dispatch = useDispatch();

    useEffect(() => {
        getCreditData();
        getNotBillAmount();
        getPartnerInfo();
        getUnpaidListData();
    }, []);

    return (
        <>
            <Card className={classes.card}>
                <CardHeader
                    title={
                        <>
                            Hello {partnerInfo.firstname}, here is your billing summary
                            <Tooltip title="Redirect to Billing Center Support Page" placement="top">
                                <IconButton
                                    // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                                    onClick={() => {
                                        window.open("https://uucargo.ca/support/billing-centre/", "_blank")
                                    }}
                                >
                                    <HelpIcon color="info" sx={{fontSize: 30}}/>
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    titleStyle={{textAlign: "left"}}
                >
                </CardHeader>
                <CardContent>
                    <Box
                        sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}
                    >
                        <Box
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <Typography variant="h6">{"Available Credit"}</Typography>
                            <Typography
                                style={{wordWrap: "break-word"}}
                                variant={{subtitle1: "h5"}}
                            >
                                <CurrencyFormat value={Math.abs((+availableCredit).toFixed(2))} displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={+availableCredit >= 0 ? `CAD $` : `CAD $-`}/>
                            </Typography>
                        </Box>
                        <Box
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <Typography variant="h6">{"Unpaid Amount"}</Typography>
                            <Typography
                                style={{wordWrap: "break-word"}}
                                variant={{subtitle1: "h5"}}
                            >
                                <CurrencyFormat value={+(Math.abs((+unpaidTotalChargeIncludeTax).toFixed(2)))}
                                                displayType={'text'} thousandSeparator={true}
                                                prefix={+(unpaidTotalChargeIncludeTax).toFixed(2) >= 0 ? `CAD $` : `CAD $-`}/>
                            </Typography>
                        </Box>
                        <Box
                            sx={{display: 'flex', flexDirection: 'column'}}
                        >
                            <Typography variant="h6">{"Not-Billed Order Amount"}</Typography>
                            <Typography
                                style={{wordWrap: "break-word"}}
                                variant={{subtitle1: "h5"}}
                            >
                                <CurrencyFormat value={+(Math.abs((+notBillAmount).toFixed(2)))} displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={(+notBillAmount).toFixed(2) >= 0 ? `CAD $` : `CAD $-`}/>
                            </Typography>
                        </Box>
                        <Typography>
                            <Button variant="contained"
                                    endIcon={<AttachMoneyIcon/>}
                                    color="primary"
                                    onClick={()=>{
                                        history.replace("/v2/email/topUp")
                                        dispatch(actions.menuBarAction.passPartnerId(url.partnerId))
                                    }}
                            >
                                Top-Up
                            </Button>
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}