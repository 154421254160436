import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
    Button,
    FormGroup,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Snackbar,
    Typography
} from "@mui/material";
import {MuiTelInput} from "mui-tel-input";
import IconButton from "@mui/material/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/styles";
import axios from "axios";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import {
    extractFullAddress,
    getMissingKeysForBecomePartner, getMissingKeysFromObject,
    isValidatePassword,
    isValidatePhone
} from "../../utils/Helper";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const businessTypeOption = [
    "E-Commerce",
    "Logistic Transportation",
    "Warehouse",
    "Solution Provider",
    "Other"
]

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    },
    SignInButton: {
        color: 'white',
        backgroundColor: 'green',
        width: '100%',
        margin: '0 auto',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
});

export const AccountCompany = () => {

    const classes = useStyles();

    const [user, setUser] = useState(null);
    const [userList, setUserList] = useState([]);
    const [welcomeEmail, setWelcomeEmail] = useState(false);
    const [activeEmail, setActiveEmail] = useState(false);
    const [companyEmailRegex, setCompanyEmailRegex] = useState(null);
    const [missingKeys, setMissingKeys] = useState([]);
    const [validationCompanyEmail, setValidationCompanyEmail] = useState(true);
    const [validationPhone, setValidationPhone] = useState(true);
    const [validationPassword, setValidationPassword] = useState(true);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [accountInformation, setAccountInformation] = useState({
        companyPhone: "",
        companyName: "",
        companyEmail: "",
        companyAddress: ""
    });
    const [companyAddress, setCompanyAddress] = useState({
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
    });
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [success, setSuccess] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const searchEndUser = async (searchText) => {
        let requestURI = `${PARTNER_URI}/partner/searchAllUserProfile`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                params: {
                    query_string: searchText
                }
            })
            console.log('[searchCompany] result', result);
            return result?.data;

        } catch (e) {
            console.log('error', e)
            return []
        }
    }

    const handleInputChangeUser = async (event, value) => {
        const result = await searchEndUser(value);
        console.log('[handleInputChangeUser] result', result);
        setUserList(result);
    }

    const handleChangeUser = (event, value) => {
        setUser(value);
    }

    const registerCompany = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/login_middleware/registerPartnerForExistingUser`;
        
        const data = {
            companyName: accountInformation?.companyName,
            companyEmail: accountInformation?.companyEmail,
            companyPhone: accountInformation?.companyPhone,
            address: companyAddress?.address2 ? `${companyAddress?.address2}-${companyAddress?.address}` : companyAddress?.address,
            city: companyAddress?.city,
            province: companyAddress?.province?.code,
            country: companyAddress?.country,
            postalCode: companyAddress?.postalCode?.replace(/\s+/g, '').toUpperCase(),
            companyType: accountInformation?.companyType,
            userId: user?.id,
            sendWelcomeEmail: welcomeEmail,
            sendActiveEmail: activeEmail
        }
        
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log(result.data);
            setErrorMessage('');
            setSuccess(true);
        } catch (e) {
            console.log(e.response);
            const errorCode = e?.response?.data?.code
            console.log(errorCode);
            if (errorCode === 401) {
                setErrorMessage("We're sorry, but we were unable to register you as a partner. The email address you entered is already registered. Please use a different email address.");
            } else if (errorCode === 403) {
                setErrorMessage("We're sorry, but we were unable to register you as a partner. The company email address and company name you entered do not match.");
            } else {
                setErrorMessage("We're sorry, but we were unable to register you as a partner. Please check your information and try again.");
            }
            handleErrorMessage();
            setSuccess(false);
        } finally {
            setLoading(false);
        }
    }


    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleWelcomeEmail = event => {
        setWelcomeEmail(event?.target?.checked);
    }

    const handleActiveEmail = event => {
        setActiveEmail(event?.target?.checked);
    }

    const handleCompanyName = event => {
        const value = event.target.value;
        setAccountInformation(prevState => ({
            ...prevState,
            companyName: value
        }))
    }

    const handleCompanyPhone = value => {
        setAccountInformation(prevState => ({
            ...prevState,
            companyPhone: value
        }))
    }

    const handleCompanyEmail = event => {
        const value = event.target.value;
        setAccountInformation(prevState => ({
            ...prevState, companyEmail: value
        }))
    }

    const handleBusinessType = (event, value) => {
        setAccountInformation(prevState => ({
            ...prevState, companyType: value
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address', address);
            setCompanyAddress(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value,
                address: value?.text
            }))
            setAccountInformation(prevState => ({
                ...prevState, companyAddress: value?.text
            }))
        } else {
            setCompanyAddress(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
            setAccountInformation(prevState => ({
                ...prevState, companyAddress: ""
            }))
        }
    }

    const handleCompanyAddress2 = event => {
        const value = event.target.value;
        setCompanyAddress(prevState => ({
            ...prevState,
            address2: value
        }));
    }

    const validatePhone = () => {
        const result = isValidatePhone(accountInformation?.companyPhone);
        setValidationPhone(result);
        return result;
    }

    const handleCreateAccount = async () => {
        const missingKeys = getMissingKeysFromObject({
            companyPhone: accountInformation?.companyPhone,
            companyName: accountInformation?.companyName,
            companyEmail: accountInformation?.companyEmail,
            companyAddress: accountInformation?.companyAddress,
            userId: user?.id && `${user?.id}`
        });
        setMissingKeys(missingKeys);
        const companyEmailCheck = await validateEmailAddress(accountInformation?.companyEmail);
        setValidationCompanyEmail(companyEmailCheck?.valid);
        setCompanyEmailRegex(companyEmailCheck?.validators?.regex);
        const phoneCheck = validatePhone();
        if (companyEmailCheck?.valid && phoneCheck && missingKeys.length === 0) {
            await registerCompany();
        }
    }

    const handleBackToRegistration = () => {
        setSuccess(false);
    }

    if (!loading && success) {
        return (
            <>
                <Grid item xs={12}>
                    <Typography sx={{textAlign: 'center', color: '#1D8B45', fontWeight: '600'}}>
                        Registration complete! Account has been successfully set up and linked to new company..
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            size='small'
                            onClick={handleBackToRegistration}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Back to Registration
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </>
        )
    }

    console.log('[AccountCompany] missingKeys', missingKeys);


    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={8}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Select EndUser
                    </FormLabel>
                    <Autocomplete
                        value={user}
                        fullWidth
                        filterOptions={(options, state) => options}
                        options={userList}
                        getOptionLabel={option => `${option?.id} | ${option?.username} | ${option?.firstname} ${option?.lastname}`}
                        onInputChange={handleInputChangeUser}
                        onChange={handleChangeUser}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                className={classes.smallInput}
                                error={(missingKeys?.includes('userId') && !user?.id)}
                                helperText={
                                    ((missingKeys.includes('userId') && !user?.id) && "Required")
                                }
                            />}
                        renderOption={(option) => (
                            <Typography>
                                {option?.id} | {option?.username} | {option?.firstname} {option?.lastname}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Business Type
                    </FormLabel>
                    <Autocomplete
                        value={accountInformation?.companyType}
                        fullWidth
                        options={businessTypeOption}
                        onChange={handleBusinessType}
                        renderInput={(params) => <TextField
                            variant="outlined"
                            {...params}
                            className={classes.smallInput}
                            size='small'
                            InputLabelProps={{style: {fontSize: 16}}}
                        />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Company Name
                    </FormLabel>
                    <TextField
                        value={accountInformation?.companyName}
                        required
                        fullWidth
                        variant="outlined"
                        onInput={handleCompanyName}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        error={(missingKeys.includes('companyName') && !accountInformation?.companyName)}
                        helperText={
                            ((missingKeys.includes('companyName') && !accountInformation?.companyName) && "Required")
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Company Email
                    </FormLabel>
                    <TextField
                        value={accountInformation?.companyEmail}
                        required
                        fullWidth
                        variant="outlined"
                        onInput={handleCompanyEmail}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        error={(missingKeys.includes('companyEmail') && !accountInformation?.companyEmail) || !validationCompanyEmail}
                        helperText={((missingKeys.includes('companyEmail') && !accountInformation?.companyEmail) || !validationCompanyEmail) && (companyEmailRegex?.reason || 'Invalid')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Company Phone
                    </FormLabel>
                    <MuiTelInput
                        value={accountInformation?.companyPhone}
                        fullWidth
                        variant="outlined"
                        defaultCountry="CA"
                        onChange={handleCompanyPhone}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        error={(missingKeys.includes('companyPhone') && !accountInformation?.companyPhone) || !validationPhone}
                        helperText={(missingKeys.includes('companyPhone') && !accountInformation?.companyPhone) ?
                            "Required" : !validationPhone &&
                            "Invalid Phone Number"}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Company Address
                    </FormLabel>
                    <Autocomplete
                        value={companyAddress?.displayAddress}
                        fullWidth
                        options={addressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.description}
                        onInputChange={handleInputChangeAddress}
                        onChange={handleChangeAddress}
                        renderInput={params => <TextField
                            {...params}
                            multiline={companyAddress?.displayAddress && companyAddress?.displayAddress?.description?.length > 50}
                            className={classes.smallInput}
                            // sx={{height: 40}}
                            size='small'
                            variant="outlined"
                            InputLabelProps={{style: {fontSize: 16}}}
                            error={(missingKeys.includes('companyAddress') && !companyAddress?.displayAddress?.description)}
                            helperText={
                                ((missingKeys.includes('companyAddress') && !companyAddress?.displayAddress?.description) && "Required")
                            }
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '14px'}}>
                                {option?.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Company Address 2
                    </FormLabel>
                    <TextField
                        value={accountInformation?.address2}
                        required
                        fullWidth
                        variant="outlined"
                        onInput={handleCompanyAddress2}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        // error={(missingKeys.includes('lastName') && !lastName)}
                        // helperText={
                        //     ((missingKeys.includes('lastName') && !lastName) && intl.get('CROSS_BORDER.REQUIRED'))
                        // }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <LoadingButton
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        height: '100%',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        },
                    }}
                    size='small'
                    loading={loading}
                    onClick={handleCreateAccount}
                >
                    <Typography sx={{textTransform: 'none'}}>
                        Create
                    </Typography>
                </LoadingButton>
            </Grid>
        </>
    )
}