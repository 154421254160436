import {Fragment, useEffect, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import {CustomTextFieldPallet} from "../../utils/customComponent";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardInvoice = ({
                                             orderData,
                                             isConfirm,
                                             getDataFromChildComponent,
                                             triggerAction,
                                             validation,
                                             getCrmInvoiceNumber,
                                             getOrderDetails,
                                             getShippingCostRequest,
                                             shippingCostDetail
                                         }) => {

    const [crmInvoiceNumber, setCrmInvoiceNumber] = useState();
    const [crmInvoiceId, setCrmInvoiceId] = useState('');
    const [isGenerateInvoice, setIsGenerateInvoice] = useState(false);

    const [originalCrmInvoiceNumber, setOriginalInvoiceNumber] = useState();

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'crmInvoiceNumber':
                setCrmInvoiceNumber(value);
                getCrmInvoiceNumber(value);
                break;
            default:
        }
    }

    const handleGenerateInvoice = (event) => {
        setIsGenerateInvoice(event?.target?.checked);
        getShippingCostRequest(event?.target?.checked);
    }

    const getInvoiceNumber = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    invoiceId: orderData?.crm_invoice_no
                }
            })

            setCrmInvoiceNumber(result?.data?.invoiceRefNumber);
            setOriginalInvoiceNumber(result?.data?.invoiceRefNumber);
            getCrmInvoiceNumber(result?.data?.invoiceRefNumber);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const getInvoiceId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/getInvoiceInfoByRefNumber`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    invoiceRefNumber: crmInvoiceNumber
                }
            })
            setCrmInvoiceId(result?.data?.id);
        } catch (e) {
            console.log('error', e.response);
            setCrmInvoiceId('');
        }
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data;
            if (isGenerateInvoice) {
                data = {
                    orderId: orderData?.id,
                    shippingFee: (+shippingCostDetail?.shippingFee),
                    serviceFee: (+shippingCostDetail?.serviceFee),
                    taxCharge: (+shippingCostDetail?.taxCharge),
                    totalCharge: (+shippingCostDetail?.totalCharge),
                    discount: (+shippingCostDetail?.discount),
                    finalTotal: (+shippingCostDetail?.finalTotal),
                    generateInvoice: isGenerateInvoice
                }
            } else {
                data =
                    {
                        orderId: orderData?.id,
                        crmInvoiceNumber: crmInvoiceId || "",
                    }
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalInvoiceNumber(crmInvoiceNumber);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({crmInvoiceNumber});
            getDataFromChildComponent({crmInvoiceId});
            getDataFromChildComponent({isGenerateInvoice});
        }
    }, [triggerAction])

    useEffect(() => {
        if (orderData?.crm_invoice_no) {
            getInvoiceNumber();
        }
    }, [orderData])

    useEffect(() => {
        if (crmInvoiceNumber) {
            getInvoiceId();
            setIsGenerateInvoice(false);
        }
    }, [crmInvoiceNumber]);

    console.log('[LTLOrderDashboardInvoice] shippingCostDetail', shippingCostDetail);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                sx={{width: "100%"}}
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                       >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <CustomTextFieldPallet
                        textValue={crmInvoiceNumber}
                        field='crmInvoiceNumber'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={crmInvoiceNumber && !crmInvoiceId}
                    />
                </FormControl>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={isGenerateInvoice}
                            onChange={handleGenerateInvoice}
                        />}
                        label={
                            <Typography>
                                Generate Invoice From Shipping Cost
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={crmInvoiceNumber || isConfirm}
                    />
                </Box>
            </Grid>
            {
                (
                    (crmInvoiceNumber !== originalCrmInvoiceNumber && crmInvoiceId) ||
                    isGenerateInvoice
                ) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>

    )
}
