import {getAccessToken} from "../../utils/doToken";
import {Fragment, useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {Divider, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import TextField from "@mui/material/TextField";
import {calculateServiceFee, calculateTaxes} from "../../utils/Helper";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingOrderEditPrice = ({orderInfo, getMovingOrderDetails, paidPayment}) => {

    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [serviceFee, setServiceFee] = useState([]);
    const [selectServiceFee, setSelectServiceFee] = useState(null);
    const [orderPrice, setOrderPrice] = useState({
        freightCharge: 0,
        deliverCharge: 0,
        pickupCharge: 0,
        pelletingCharge: 0,
        storageCharge: 0,
        shippingCharge: 0
    });
    const [totalCharge, setTotalCharge] = useState(0);
    const [finalTotal, setFinalTotal] = useState(0);
    const [taxCharge, setTaxCharge] = useState(0);
    const [shippingCharge, setShippingCharge] = useState(0);
    const [originalShippingCharge, setOriginalShippingCharge] = useState(0);
    const [serviceCharge, setServiceCharge] = useState(0);
    const [originalServiceCharge, setOriginalServiceCharge] = useState(0);
    const [adjustmentAmount, setAdjustmentAmount] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleShippingCharge = (event) => {
        setShippingCharge(event?.target?.value);
    }

    const handleServiceFee = (event) => {
        setServiceCharge(+event.target.value.amount);
        setSelectServiceFee(event.target.value);
    }

    const handleUpdateOrderPrice = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingService/admin/editMovingOrderDetails`;

            let data = {
                backendOrderId: orderInfo?.order_id,
                taskShippingFee: +shippingCharge,
                taskServiceFee: +serviceCharge,
                // adjustTaskPrice: true
            }

            console.log('[handleUpdateOrderPrice] data', data);

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })

            setErrorMessage('');
            setSuccessMessage('Successful Update Moving Order');
            handleSuccessMessage();
            setTimeout(() => {
                getMovingOrderDetails();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Moving Order');
            handleErrorMessage()
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const serviceFee = calculateServiceFee(shippingCharge);
        setServiceFee(serviceFee);
        const filterServiceFee = serviceFee.filter((each) => (
            each.rate === selectServiceFee?.rate
        ));
        console.log('[filterServiceFee]', filterServiceFee);
        setSelectServiceFee(filterServiceFee[0]);
        setServiceCharge(+filterServiceFee?.[0]?.amount);
        const adjustmentAmount = +(+shippingCharge - +orderInfo?.orderPrice?.shipping_fee)?.toFixed(2);
        setAdjustmentAmount(adjustmentAmount);
    }, [shippingCharge]);

    useEffect(() => {
        const totalCharge = +shippingCharge + +serviceCharge;
        setTotalCharge(+totalCharge?.toFixed(2))
    }, [shippingCharge, serviceCharge]);

    useEffect(() => {
        const taxCharge = +(totalCharge * 0.05).toFixed(2);
        setTaxCharge(taxCharge);
    }, [totalCharge])

    useEffect(() => {
        const finalTotal = +taxCharge + +totalCharge;
        setFinalTotal(+finalTotal?.toFixed(2));
    }, [taxCharge, totalCharge])

    useEffect(() => {
        if (orderInfo) {
            setOrderPrice(prevState => ({
                ...prevState,
                freightCharge: orderInfo?.orderPrice?.moving_freight_charge,
                deliverCharge: orderInfo?.orderPrice?.moving_deliver_charge,
                pickupCharge: orderInfo?.orderPrice?.moving_pickup_charge,
                pelletingCharge: orderInfo?.orderPrice?.moving_palleting_charge,
                storageCharge: orderInfo?.orderPrice?.moving_storage_charge,
                shippingCharge: orderInfo?.orderPrice?.shipping_fee
            }));
            setTaxCharge(orderInfo?.taskTaxCharge);
            setTotalCharge(orderInfo?.taskTotalCharge);
            setFinalTotal(orderInfo?.taskFinalTotal);
            setServiceCharge(orderInfo?.taskServiceFee);
            setOriginalServiceCharge(orderInfo?.taskServiceFee);
            setShippingCharge(orderInfo?.taskShippingFee);
            setOriginalShippingCharge(orderInfo?.taskShippingFee);
            const serviceFee = calculateServiceFee(orderInfo?.taskShippingFee);
            const rate = +(orderInfo?.taskServiceFee / orderInfo?.taskShippingFee)?.toFixed(2);
            setSelectServiceFee({
                rate,
                percent: `${rate * 100}%`,
                amount: orderInfo?.taskServiceFee
            })
            setServiceFee(serviceFee);
            const adjustmentAmount = +(+orderInfo?.taskShippingFee - +orderInfo?.orderPrice?.shipping_fee)?.toFixed(2);
            setAdjustmentAmount(adjustmentAmount);
        }
    }, [orderInfo])

    console.log('[MovingOrderEditPrice] order price', orderPrice);
    console.log('[MovingOrderEditPrice] selectServiceFee', selectServiceFee);

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Order Price
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Freight Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={orderPrice?.freightCharge}
                                    // onInput={handleFreightCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    type='number'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Pickup Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={orderPrice?.pickupCharge}
                                    // onInput={handlePickupCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    type='number'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Deliver Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={orderPrice?.deliverCharge}
                                    // onInput={handleDeliverCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    type='number'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Storage Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={orderPrice?.storageCharge}
                                    // onInput={handleStorageCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    type='number'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Palleting Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={orderPrice?.pelletingCharge}
                                    // onInput={handlePalletingCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    type='number'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Shipping Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={orderPrice?.shippingCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    // onInput={handleShippingCharge}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Adjustment Amount
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={adjustmentAmount}
                                    // onInput={handleMovingFromCity}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Adjusted Shipping Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={shippingCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    onInput={handleShippingCharge}
                                    disabled={orderInfo?.shipping_status_id === 1}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Service Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Select
                                    value={serviceCharge}
                                    size='small'
                                    onChange={handleServiceFee}
                                    renderValue={() => selectServiceFee?.amount || 0}
                                    style={{textAlign: 'left'}}
                                    disabled={orderInfo?.shipping_status_id === 1}
                                >
                                    {
                                        serviceFee?.map((each, index) => (
                                            <MenuItem key={index} value={each}>
                                                {each?.percent} | {each?.amount}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Subtotal
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={totalCharge}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Tax
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={taxCharge}
                                    // onInput={handleMovingFromCity}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Total
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    value={finalTotal}
                                    // onInput={handleMovingFromCity}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        {
                            ((+shippingCharge !== originalShippingCharge) || (+serviceCharge !== +originalServiceCharge)) &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleUpdateOrderPrice}
                                        loading={loading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )

}