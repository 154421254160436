import {
  americaProvinceCodeToNameMap,
  americaProvinceNameToCodeMap,
  canadaProvinceCodeToNameMap
} from "../shared/constInfo";

const getProvinceCodeByName = (name) => {
  switch (name) {
    case "Newfoundland and Labrador":
      return "NL";
    case "Prince Edward Island":
      return "PE";
    case "Nova Scotia":
      return "NS";
    case "New Brunswick":
      return "NB";
    case "Quebec":
      return "QC";
    case "Québec":
      return "QC";
    case "Ontario":
      return "ON";
    case "Manitoba":
      return "MB";
    case "Saskatchewan":
      return "SK";
    case "Alberta":
      return "AB";
    case "British Columbia":
      return "BC";
    case "Yukon":
      return "YT";
    case "Northwest Territories":
      return "NT";
    case "Nunavut":
      return "NU";
    case "NL":
      return "NL";
    case "PE":
      return "PE";
    case "NS":
      return "NS";
    case "NB":
      return "NB";
    case "QC":
      return "QC";
    case "ON":
      return "ON";
    case "MB":
      return "MB";
    case "SK":
      return "SK";
    case "AB":
      return "AB";
    case "BC":
      return "BC";
    case "YT":
      return "YT";
    case "NT":
      return "NT";
    case "NU":
      return "NU";
    default:
      return name;
  }
};

export const getUSProvinceCodeByName = (name) => {
  return americaProvinceNameToCodeMap[name] || name;
}

export const getCanadaProvinceNameByCode = (name) => {
  return canadaProvinceCodeToNameMap[name] || name;
}

export const getUSProvinceNameByCode = (code) => {
  return americaProvinceCodeToNameMap[code] || code;

}


export default getProvinceCodeByName;
