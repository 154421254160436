import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider, Grid, Link, Snackbar} from "@mui/material";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const PackageOrderTransactionPickupEach = ({appointment, key, handleUpdatePickup, getAppointmentList}) => {

    const storedToken = getAccessToken("access_token");

    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [loadingClose, setLoadingClose] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const getInvoiceNumber = async (invoiceId) => {

        if (!invoiceId) {
            return 'N/A'
        }

        const requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: invoiceId
                }
            })
            console.log('result', result);
            const {data: {invoiceRefNumber}} = result;
            return invoiceRefNumber;
        } catch (e) {
            console.log('e', e?.response);
            return 'N/A'
        }
    }

    const handleClosePickup = async () => {
        setLoadingClose(true);
        // let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/admin/closeSchedulePickupAppointment`;
        let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/admin/pickup/cancel`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURI,
                params: {
                    appointmentId: appointment?.appoint_id,
                    forceCancel: 1
                }
            });
            setErrorMessage('');
            setSuccessMessage('Successfully close the pickup.');
            handleSuccessMessage();
            handleDialogClose();
            setTimeout(() => {
                getAppointmentList();
            }, 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to close the pickup.');
            handleErrorMessage();
        } finally {
            setLoadingClose(false);
        }
    }

    useEffect(() => {
        (async () => {
            if (appointment?.crm_invoice_no) {
                const crmInvoiceNumber = await getInvoiceNumber(appointment?.crm_invoice_no);
                setInvoiceNumber(crmInvoiceNumber);
            }
        })()
    }, [appointment?.crm_invoice_no]);

    console.log('[PackageOrderTransactionPickupEach] appointment', appointment);

    return (
        <Grid container spacing={1} key={key}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Close pickup
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to close this pickup? This action cannot be undone.
                        Please confirm if you'd like to proceed with closing the pickup. {
                        appointment?.crm_invoice_no && 'Please note that this action will also initiate the refund of the pickup fee.'
                    }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <LoadingButton onClick={handleClosePickup} variant="outlined" type="button" loading={loadingClose}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Appointment Id
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.appoint_id}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Confirmation #
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.confirm_appoint_no || 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Invoice #
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {invoiceNumber || 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Appointment Date
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.appoint_date}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Pickup Time
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.appoint_time_start?.split(':').slice(1).join(':')} - {appointment?.appoint_time_end?.split(':').slice(1).join(':')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={6}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Pickup Location
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_location}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Pickup Address
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_addr_line2 && `${appointment?.pickup_addr_line2}-`}{appointment?.pickup_addr}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_city}, {appointment?.pickup_province}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_country}, {appointment?.pickup_postal_code}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Contact Information
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_name}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_tel}
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.pickup_email}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Tracking #
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {appointment?.tracking_nos}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Remark Notes
                    </Typography>
                    <Typography style={{
                        textAlign: 'left',
                        fontSize: '14px',
                    }}>
                        {appointment?.remark_notes || 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Package Label
                    </Typography>
                    {
                        appointment?.pickup_label_uri ?
                            <Link href={`${LABEL_DOWNLOAD_URL_PREFIX}${appointment?.pickup_label_uri}`} target="_blank"
                                  rel="noopener noreferrer" sx={{
                                textDecoration: 'none'
                            }}>
                                <Typography style={{
                                    textAlign: 'left',
                                    fontSize: '14px',
                                    textDecoration: 'none',
                                    color: '#000000'
                                }}>
                                    View
                                </Typography>
                            </Link>
                            :
                            <Typography style={{
                                textAlign: 'left',
                                fontSize: '14px',
                                textDecoration: 'none',
                                color: '#000000'
                            }}>
                                N/A
                            </Typography>
                    }
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box>
                    <Typography
                        style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                        Status
                    </Typography>
                    <Typography style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        color: +appointment?.status === 1 ? '#1D8B45' : +appointment?.status === -1 ? '#FF0303' : '#454545'
                    }}>
                        {+appointment?.status === 1 ? 'Submitted' : +appointment?.status === 0 ? 'Initiated' : +appointment?.status === -1 ? 'Canceled' : +appointment?.status === 100 ? 'Inactive' : 'Cancel Request'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px'
                }}>
                    {
                        +appointment?.status !== -1 &&
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#454545',
                                "&:hover": {
                                    backgroundColor: '#454545',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleDialogOpen}
                            size='small'
                        >
                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                Close
                            </Typography>
                        </LoadingButton>
                    }
                    {
                        (+appointment?.status === 0 || +appointment?.status === 100) &&
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={() => handleUpdatePickup(appointment)}
                            size='small'
                        >
                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                Update
                            </Typography>
                        </Button>
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>

    )
}