import {Fragment, useEffect, useState} from "react";
import {Grid, InputAdornment, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLQuoteTransitDays = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const days = [
        '1 - 3',
        '3 - 5',
        '5 - 7',
        '7 - 10',
        '10 - 15'
    ]

    const classes = useStyles();

    const [transitDays, setTransitDays] = useState('');

    const handleInputChange = async (event, value) => {
        setTransitDays(value);
    }

    const handleChange = (event, value) => {
        setTransitDays(value);
    };

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({transitDays});
        }
    }, [triggerAction])

    // console.log('[LTLQuoteTransitDays] transitDays', transitDays);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Transit Days
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <FormControl fullWidth>
                            <Autocomplete
                                freeSolo
                                options={days}
                                fullWidth
                                disabled={isConfirm}
                                renderInput={(params) => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {params.InputProps.endAdornment}
                                                <InputAdornment position="end">day</InputAdornment>
                                            </>
                                        ),
                                    }}
                                />}
                                onInputChange={handleInputChange}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )

}