import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Fragment} from "react";

export const MovingOrderDetailItems = ({orderInfo}) => {

    console.log('[MovingOrderDetailItems] orderInfo', orderInfo);

    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Item List
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Typography>
                                    Category
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    Description
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography>
                                    Dimension
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography>
                                    Weight
                                </Typography>
                            </Grid>
                            <Grid item xs={1.5}>
                                <Typography>
                                    Quantity
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            {
                                orderInfo?.backendPackages?.map((item, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={3}>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {item?.category_name}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {item?.item_description}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography>
                                                {item?.length}x{item?.width}x{item?.height} {item?.dimension_unit}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <Typography>
                                                {item?.weight} {item?.weight_unit}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5}>
                                            <Typography>
                                                {item?.number}
                                            </Typography>
                                        </Grid>
                                    </Fragment>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Piece: </span>{orderInfo?.pieceCount}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Pallet: </span>{orderInfo?.palletCount?.toFixed(1)}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Total Weight: </span>{orderInfo?.totalWeight?.toFixed(2)} lb
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Total Volume: </span>{orderInfo?.totalVolume?.toFixed(2)} {orderInfo?.volumeUnit}{'\u00B3'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Total Volume Metric Weight: </span>{orderInfo?.totalVolumeMetricWeight?.toFixed(2)} {orderInfo?.volumeMetricWeightUnit}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}