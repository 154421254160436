import { getAccessToken } from "./doToken";
import axios from "axios";
import {PARTNER_URI} from "./apiUrl";

const GET_ADDRESS_BOOK_URL = "https://api.uucargo.ca/api/addressbooks";
const ADD_ADDRESS_BOOK_URL = "https://api.uucargo.ca/api/addressbook";

export const getAddressBookList = async () => {
  const accessToken = getAccessToken("access_token");
  const { data } = await axios.get(GET_ADDRESS_BOOK_URL, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return data;
}

export const saveAddressBookList = async ({ address, city, province, postal_code, phone }) => {
  const accessToken = getAccessToken("access_token");
  const { data } = await axios.post(`${ADD_ADDRESS_BOOK_URL}?address=${address}&city=${city}&province=${province}&postalcode=${postal_code}&phone=${phone}`, {

  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  console.log(data);
}

export const deleteAddressBookList = async ({ addressId }) => {
  const accessToken = getAccessToken("access_token");
  const { data } = await axios.delete(`${ADD_ADDRESS_BOOK_URL}/${addressId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  console.log(data);
}

export const getSenderAddressBookList = async ({ partnerId }) => {
  const { data } = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`, {
    partnerId: `${partnerId}`
  });
  return data?.result || [];
}

export const getDeliverAddressBookList = async ({ partnerId }) => {
  const { data } = await axios.post(`${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`, {
    partnerId: `${partnerId}`
  });
  return data?.result || [];
}

export const addSenderAddressBookList = async ({ partnerId, address, address_2, city, email, province, postalCode, country, phone, name }) => {
  return await axios.put(`${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/sender`, {
    partnerId: `${partnerId}`,
    address,
    address_2,
    city,
    email,
    province,
    postalCode,
    country,
    phone,
    name,
    addressEmail: ""
  });
}

export const addDeliverAddressBookList = async ({ partnerId, address, address_2, city, email, province, postalCode, country, phone, name }) => {
  return await axios.put(`${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/deliver`, {
    partnerId: `${partnerId}`,
    address,
    address_2,
    city,
    email,
    province,
    postalCode,
    country,
    phone,
    name,
    addressEmail: ""
  });
}

export const deleteAddressBookByPartnerIdAndAddressBookId = async ({ partnerId, id }) => {
  return await axios.delete(`${PARTNER_URI}/loose-item/3rd-party/partner/addressbooks/`, {
    data: {
      partnerId: `${partnerId}`,
      id: `${id}`
    }
  })
};