import {Fragment, useEffect, useState} from "react";
import {FormHelperText, FormLabel, Grid, MenuItem, Select} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";

export const LTLOrderListSelectCarrier = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [availableService, setAvailableService] = useState([]);
    const [selectCarrier, setSelectCarrier] = useState('default');

    const getAllAvailableServiceNames = async () => {
        let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    all: true
                }
            })
            console.log('result', result);
            setAvailableService(result?.data);
        } catch (e) {
            console.log('error', e);
        }
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setSelectCarrier(event.target.value);
    };

    useEffect(() => {
        getAllAvailableServiceNames();
    },[])

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({selectCarrier});
        }
    },[triggerAction])

    return (
        <Fragment>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControl fullWidth>
                        <Select
                            value={selectCarrier}
                            size='small'
                            // placeholder='Select Partner'
                            onChange={handleChange}
                            disabled={isConfirm}
                            error={validation && validation?.carrierMissing}
                        >
                            <MenuItem disabled value='default'>
                                <em>Select Carrier</em>
                            </MenuItem>
                            {
                                availableService?.map((carrier, index) => (
                                    <MenuItem key={index} value={carrier?.account_base}>
                                        {carrier?.account_base}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText
                            sx={{
                                color: "error.main",
                            }}
                        >
                            {(validation && validation?.carrierMissing) && 'Required field'}
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
        </Fragment>
    )

}