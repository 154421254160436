import {Fragment, useEffect, useRef, useState} from "react";
import {Button, Dialog, FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {containerShippingServiceList} from "../../utils/Helper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})

export const ContainerShippingOrderDetailServiceAndPriceUpdate = ({
                                                                      serviceInfo,
                                                                      handleCancelUpdateService,
                                                                      getContainerShippingOrderInfo
                                                                  }) => {

    const classes = useStyles();

    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [serviceName, setServiceName] = useState('');
    const [unitPrice, setUnitPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [tax, setTax] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0)
    const [serviceDesc, setServiceDesc] = useState('');
    const [originalService, setOriginalService] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [validationResult, setValidationResult] = useState(null);

    const handleConfirmDelete = () => {
        handleDialogOpen();
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleInputChangeServiceName = (event, value) => {
        setServiceName(value);
    }

    const handleChangeServiceName = (event, value) => {
        setServiceName(value);
    }

    const handleServiceDesc = (event) => {
        setServiceDesc(event?.target?.value);
    }

    const handleUnitPrice = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setUnitPrice(value);
        }
    }

    const handleQuantity = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)$/.test(value)) {
            setQuantity(value);
        }
    }

    const handleTax = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setTax(value);
        }
    }

    const deleteService = async () => {
        setLoadingDelete(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/deleteOrderPriceDetails`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                params: {
                    priceDetailsId: serviceInfo?.id
                }
            });
            handleDialogClose();
            setErrorMessage('');
            setSuccessMessage(`Successfully delete ${serviceInfo?.serviceName} service.`);
            handleSuccessMessage();
            handleCancelUpdateService();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(`Fail to delete ${serviceInfo?.serviceName} service.`);
            handleErrorMessage();
        } finally {
            setLoadingDelete(false);
        }
    }

    const updateService = async () => {
        setLoadingUpdate(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/editOrderPriceDetails`;

        const data = {
            priceDetailsId: serviceInfo?.id,
            unitPrice,
            serviceName,
            finalTotal: total,
            taxes: tax,
            total: subtotal,
            quantity,
            serviceDescription: serviceDesc
        }

        try {
            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            });
            setErrorMessage('');
            setSuccessMessage(`Successfully update ${serviceInfo?.serviceName} service.`);
            handleSuccessMessage();
            setTimeout(() => {
                getContainerShippingOrderInfo();
                handleCancelUpdateService();
            }, 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(`Fail to update ${serviceInfo?.serviceName} service.`);
            handleErrorMessage();
        } finally {
            setLoadingUpdate(false);
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);

        !serviceName && setErrorMessage(prevState => prevState + 'Service name is missing.');
        !unitPrice && setErrorMessage(prevState => prevState + 'Unit price is missing.');
        unitPrice <= 0 && setErrorMessage(prevState => prevState + 'Unit price is smaller than 0.');
        !quantity && setErrorMessage(prevState => prevState + 'Quantity is missing');
        quantity < 1 && setErrorMessage(prevState => prevState + 'Quantity is smaller than 1.');

        setValidationResult(preState => ({
            ...preState,
            serviceNameError: !serviceName,
            unitPriceError: !unitPrice,
            unitPriceInvalid: unitPrice <= 0,
            quantityError: !quantity,
            quantityInvalid: quantity < 1
        }));

        if (!serviceName ||
            !unitPrice ||
            unitPrice <= 0 ||
            !quantity ||
            quantity < 1
            // consigneeMissingKeys?.length > 0 ||
            // shipperPhoneError ||
            // consigneePhoneError
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }


    const handleConfirmUpdate = () => {
        const result = validation();
        if (result) {
            updateService();
        }
    }

    useEffect(() => {
        const calculatedSubtotal = ((+unitPrice) * (+quantity)).toFixed(2);
        setSubtotal(calculatedSubtotal);
    }, [unitPrice, quantity]);

    useEffect(() => {
        const calculatedSubtotal = ((+subtotal) + (+tax)).toFixed(2);
        setTotal(calculatedSubtotal);
    }, [subtotal, tax]);

    useEffect(() => {
        if (serviceInfo) {
            setUnitPrice(serviceInfo?.unitPrice);
            setQuantity(serviceInfo?.quantity);
            setServiceDesc(serviceInfo?.serviceDescription);
            setServiceName(serviceInfo?.serviceName);
            setTax(serviceInfo?.taxes);
            setOriginalService(serviceInfo);
        }
    }, [serviceInfo]);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Delete Service
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will delete service <b>{serviceInfo?.serviceName}</b>.
                        Are you sure to delete?
                        <hr/>
                        Click <b>Confirm</b> to delete this service.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <LoadingButton onClick={deleteService} variant="outlined" type="button" loading={loadingDelete}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={3.5}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Service Name
                    </FormLabel>
                    <Autocomplete
                        value={serviceName}
                        freeSolo
                        fullWidth
                        onInputChange={handleInputChangeServiceName}
                        onChange={handleChangeServiceName}
                        options={containerShippingServiceList}
                        renderInput={(params) => <TextField
                            {...params}
                            className={classes.smallInput}
                            error={validationResult?.serviceNameError}
                            helperText={validationResult?.serviceNameError && 'Required'}
                        />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3.5}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Service Description
                    </FormLabel>
                    <TextField
                        value={serviceDesc}
                        onInput={handleServiceDesc}
                        size='small'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Unit Price
                    </FormLabel>
                    <TextField
                        value={unitPrice}
                        onInput={handleUnitPrice}
                        fullWidth
                        variant="outlined"
                        size='small'
                        type='number'
                        error={validationResult?.unitPriceError || validationResult?.unitPriceInvalid}
                        helperText={validationResult?.unitPriceError ? 'Required' : validationResult?.unitPriceInvalid && 'Invalid'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Quantity
                    </FormLabel>
                    <TextField
                        value={quantity}
                        onInput={handleQuantity}
                        fullWidth
                        variant="outlined"
                        size='small'
                        type='number'
                        error={validationResult?.quantityError || validationResult?.quantityInvalid}
                        helperText={validationResult?.quantityError ? 'Required' : validationResult?.quantityInvalid && 'Invalid'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Subtotal
                    </FormLabel>
                    <TextField
                        value={subtotal}
                        disabled={true}
                        size='small'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Tax
                    </FormLabel>
                    <TextField
                        value={tax}
                        onInput={handleTax}
                        fullWidth
                        variant="outlined"
                        size='small'
                        type='number'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Total
                    </FormLabel>
                    <TextField
                        value={total}
                        disabled={true}
                        size='small'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#454545',
                            height: '100%',
                            "&:hover": {
                                backgroundColor: '#454545',
                                filter: 'brightness(0.9)'
                            },
                        }}
                        size='small'
                        onClick={handleCancelUpdateService}
                    >
                        <Typography sx={{
                            textTransform: 'none',
                            fontSize: '14px'
                        }}>
                            Cancel
                        </Typography>
                    </Button>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        height: '100%',
                        gap: '10px'
                    }}>
                        {
                            (
                                (serviceDesc !== originalService?.serviceDescription) ||
                                (serviceName !== originalService?.serviceName) ||
                                (+unitPrice !== originalService?.unitPrice) ||
                                (+quantity !== originalService?.quantity) ||
                                (+tax !== originalService?.taxes)
                            ) &&
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    height: '100%',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    },
                                }}
                                size='small'
                                onClick={handleConfirmUpdate}
                                loading={loadingUpdate}
                            >
                                <Typography sx={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                }}>
                                    Update
                                </Typography>
                            </LoadingButton>
                        }
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#F2BE22',
                                height: '100%',
                                "&:hover": {
                                    backgroundColor: '#F2BE22',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleConfirmDelete}
                            // loading={loadingDelete}
                        >
                            <Typography sx={{
                                textTransform: 'none',
                                fontSize: '14px'
                            }}>
                                Delete
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Fragment>
    )
}