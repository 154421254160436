import {Divider, Grid, Menu, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import {Link} from "@material-ui/core";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import {mapCountryIcon, movingOrderStatus, movingShippingStatus} from "../../utils/Helper";
import {Fragment} from "react";

export const MovingOrderListEach = ({order_info}) => {

    console.log('[MovingOrderListEach] order_info', order_info)

    return (
        <Fragment>
            <Grid item md={2}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <img
                            src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png'
                            alt="logo"
                            height={40}
                        />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            height: '100%'
                        }}>
                            <Link
                                href={`/v2/moving-order-list/${order_info?.order_id}`}
                                underline="none"
                                sx={{
                                    color: '#1D8B45',
                                }}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: 'left',
                                    width: '100%',
                                    color: '#1D8B45',
                                }}>
                                    {order_info?.order_number}
                                </Typography>
                            </Link>
                            <Typography style={{
                                fontSize: '12px',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                {dayjs(order_info?.created_at).format("YYYY-MM-DD")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1.5}>
                <Grid container spacing={0}>
                    <Grid item xs={2}>
                        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            {mapCountryIcon(order_info?.send_from_country?.toUpperCase())}
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {order_info?.sender}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                {order_info?.send_from_country}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                {order_info?.pickup_area}, {order_info?.pickup_postal_code}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1.5}>
                <Grid container spacing={0}>
                    <Grid item xs={2}>
                        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            {mapCountryIcon(order_info?.send_to_country?.toUpperCase())}
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{display: 'flex', alignItems: 'center', height: '100%'}}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {order_info?.deliver_name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                {order_info?.send_to_country}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={10}>
                        <Box sx={{display: 'flex', alignItems: 'flex-start', height: '100%'}}>
                            <Typography style={{fontSize: '12px', textAlign: 'left'}}>
                                {order_info?.deliver_area}, {order_info?.deliver_postal_code}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        {+order_info?.sender_id}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        {order_info?.sender_type?.toUpperCase() === 'ENDUSER' ? `${order_info?.sender_user_firstname} ${order_info?.sender_user_lastname}` : order_info?.sender_partner_business_name}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    {
                        order_info?.task_partner_id ?
                            <>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {order_info?.task_partner_id}
                                </Typography>
                                <Typography style={{
                                    fontSize: '14px',
                                }}>
                                    {order_info?.task_partner_business_name}
                                </Typography>
                            </>
                            :
                            <Typography style={{
                                fontSize: '14px',
                            }}>
                                N/A
                            </Typography>
                    }
                </Box>
            </Grid>
            <Grid item md={1.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        textAlign: "center",
                        color: +order_info?.status_id === 0 ? '#454545' : +order_info?.status_id === 100 ? '#F2BE22' : +order_info?.status_id === 1 ? '#1D8B45' : '#FF0303'
                    }}>
                        {movingOrderStatus(+order_info?.status_id)}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        textAlign: "center",
                        color: (+order_info?.shipping_status_id === 1 || +order_info?.shipping_status_id === 2) ? '#454545' : (+order_info?.shipping_status_id === 20) ? '#F2BE22' : '#1D8B45'
                    }}>
                        {movingShippingStatus(+order_info?.shipping_status_id)}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        $ {(+order_info?.final_total)?.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}
