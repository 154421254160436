import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import axios from "axios";
import { NODE_ROUTE_URI } from "../../utils/apiUrl";
import { CSVLink } from "react-csv";

const ExportPickupListDataButton = () => {

  const [loading, setLoading] = useState(false);
  const [pickupData, setPickupData] = useState([]);
  const csvLink = useRef();

  const newExcelRowHeader = [
    "Appointment Id",
    "Confirmation Number",
    "Invoice Number",
    "User Type",
    "User Id",
    "Carrier",
    "Appoint Date",
    "Tracking Numbers",
    "Pickup Fee",
    "Taxes",
    "Total",
    "Final Total"
  ]

  const getPickupListData = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${NODE_ROUTE_URI}/packagesPickupAppointment/exportPickupAppointmentListData`, {
        params: {
          status: 1
        }
      })
      const resultArray = [
        newExcelRowHeader
      ];
      for (const column of data) {
        const {
          appoint_id: appointmentId,
          partner_id: userId,
          owner_role: ownerRole,
          account_base: accountBase,
          pickup_appointment_fee: pickupFee,
          tracking_nos: trackingNumbers,
          taxes,
          total,
          final_total: finalTotal,
          appoint_date: appointmentDate,
          confirm_appoint_no: confirmationNumber,
          crmInvoiceRefNumber
        } = column;

        const columnData = [];
        const userType = +ownerRole === 1 ? "End User" : "Partner";
        columnData.push(appointmentId);
        columnData.push(confirmationNumber);
        columnData.push(crmInvoiceRefNumber);
        columnData.push(userType);
        columnData.push(userId);
        columnData.push(accountBase);
        columnData.push(appointmentDate);
        columnData.push(trackingNumbers);
        columnData.push(pickupFee || 0);
        columnData.push(taxes || 0);
        columnData.push(total || 0);
        columnData.push(finalTotal || 0);
        resultArray.push(columnData);
      }
      setPickupData(resultArray);
      csvLink.current.link.click()

    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }


  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      height: '100%'
    }}>
      <LoadingButton
        variant='contained'
        sx={{
          backgroundColor: '#1D8B45',
          "&:hover": {
            backgroundColor: '#1D8B45',
            filter: 'brightness(0.9)'
          }
        }}
        size='small'
        onClick={getPickupListData}
        loading={loading}
      >
        <Typography sx={{textTransform: 'none'}}>
          Export
        </Typography>
      </LoadingButton>
      <CSVLink
        data={pickupData}
        filename={`Pickup Data`}
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </Box>
  );
};

export default ExportPickupListDataButton;
