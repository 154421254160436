import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    ButtonBase,
    Divider,
    Grid,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import LoadingContainer from "../../shared/loadingContainer";
import {Fragment, useEffect, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingLTLQuickQuoteRateHeading: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '18px'
    },
    ShippingLTLQuickQuoteRateBox: {
        border: '1px solid #609966',
        padding: '20px 0',
        borderRadius: '5px'
    },
    ShippingLTLQuickQuoteRateText: {
        textAlign: 'left',
        fontSize: '16px',
    },
    ShippingLTLQuickQuoteRateCarrierBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        paddingLeft: '20px'
    }
}

export const LTLQuickQuoteRating = ({sortRate, isCallAllRate, availableService}) => {

    const [selectedRate, setSelectedRate] = useState();

    const handleSelect = (rate) => {
        setSelectedRate(rate);
    }

    useEffect(() => {
        if (isCallAllRate === availableService?.length) {
            setSelectedRate(sortRate[0]);
        }
    }, [isCallAllRate]);

    if (isCallAllRate === availableService?.length) {
        const noResult = sortRate?.every(service => !service.loading && service.finalTotalBeforeTax === 0);
        if (noResult) {
            return (
                <Grid item xs={12}>
                    <Box sx={styles.ShippingOrderList}>
                        <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                            LTL Rating
                        </Typography>
                        <Typography>
                            Sorry, we couldn't find any rating results based on the shipping information you
                            provided. Please double-check the details and try again.
                        </Typography>
                    </Box>
                </Grid>
            )
        }
    }

    return (
        <>
            <Grid item xs={12}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        LTL Rating
                    </Typography>
                    <Grid container spacing={2}>
                        {
                            sortRate?.map((each, index) => {
                                    return (
                                        (each.loading || each.finalTotalBeforeTax !== 0) &&
                                        <Grid item key={index} xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '10px'
                                            }}>
                                                <ButtonBase sx={{
                                                    width: '100%'
                                                }}
                                                            onClick={() => handleSelect(each)}
                                                            disabled={isCallAllRate !== availableService?.length}
                                                >
                                                    <Box sx={{
                                                        border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                        borderRadius: '5px',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        width: '100%'
                                                    }}
                                                    >
                                                        <Box sx={{padding: '20px 0'}}>
                                                            <Grid container spacing={4}>
                                                                <Grid item md={1} xs={3}>
                                                                    <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                        <img
                                                                            src={`${LABEL_DOWNLOAD_URL_PREFIX}${each?.icon_uri}`}
                                                                            alt="logo"
                                                                            width={60}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={2} xs={4}>
                                                                    <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                        <Typography
                                                                            style={styles.ShippingLTLQuickQuoteRateText}>
                                                                            {
                                                                                each?.serviceName.toUpperCase() === "DAYANDROSS" ? "DAY && ROSS" : each?.serviceName.toUpperCase() === "TFORCE" ? "TForce" : each?.serviceName.toUpperCase()
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={2} xs={4}>
                                                                    <Box sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                        <Typography
                                                                            style={styles.ShippingLTLQuickQuoteRateText}>
                                                                            {
                                                                                each?.carrierName !== each?.serviceName && each?.carrierName
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                {each.loading ?
                                                                    <Grid item xs={7}>
                                                                        <LoadingContainer/>
                                                                    </Grid> : <>
                                                                        <Grid item md={5} xs={12}>
                                                                            <Box
                                                                                sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                                <Typography
                                                                                    style={styles.ShippingLTLQuickQuoteRateHeading}>
                                                                                    ${(each?.finalTotalBeforeTax).toFixed(2)} {each?.currency}
                                                                                </Typography>
                                                                                <h4
                                                                                    style={{paddingLeft: "1rem"}}>({each?.source})</h4>
                                                                                {
                                                                                    (() => {
                                                                                        if (each?.sourceId) {
                                                                                            return (
                                                                                                <h4
                                                                                                    style={{paddingLeft: "1rem"}}>(Id: {each?.sourceId})</h4>
                                                                                            )
                                                                                        }
                                                                                    })()
                                                                                }
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={2} xs={12}>
                                                                            <Box
                                                                                sx={styles.ShippingLTLQuickQuoteRateCarrierBox}>
                                                                                {selectedRate === each ?
                                                                                    <Button
                                                                                        variant='contained'
                                                                                        color='success'
                                                                                        // disabled={confirm}
                                                                                    >
                                                                                        <Typography
                                                                                            style={{
                                                                                                textTransform: 'none',
                                                                                                fontSize: '16px'
                                                                                            }}>
                                                                                            Selected
                                                                                        </Typography>
                                                                                    </Button>
                                                                                    :
                                                                                    <Button
                                                                                        variant='outlined'
                                                                                        color='success'
                                                                                        onClick={() => handleSelect(each)}
                                                                                        // disabled={confirm}
                                                                                    >
                                                                                        <Typography
                                                                                            style={{
                                                                                                textTransform: 'none',
                                                                                                fontSize: '16px'
                                                                                            }}>
                                                                                            Select
                                                                                        </Typography>
                                                                                    </Button>
                                                                                }
                                                                            </Box>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                            </Grid>
                                                        </Box>
                                                        {selectedRate === each &&
                                                            <Box sx={{borderTop: '3px solid #609966', padding: '0 20px'}}>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <Accordion
                                                                            sx={{
                                                                                boxShadow: 'none',
                                                                            }}
                                                                            defaultExpanded={true}
                                                                        >
                                                                            <AccordionSummary
                                                                                sx={{
                                                                                    flexDirection: 'row-reverse'
                                                                                }}
                                                                                expandIcon={<ExpandMoreIcon/>}
                                                                            >
                                                                                < Typography
                                                                                    style={{
                                                                                        fontSize: '16px',
                                                                                        textAlign: 'left'
                                                                                    }}>
                                                                                    Price Details
                                                                                </Typography>
                                                                            </AccordionSummary>
                                                                            <AccordionDetails>
                                                                                <Grid container spacing={1}>
                                                                                    {
                                                                                        each?.shipmentCharges?.map((eachItem, index) => {
                                                                                            const cost = +eachItem?.value;
                                                                                            if (cost !== 0) {
                                                                                                return (
                                                                                                    <Fragment key={index}>
                                                                                                        <Grid item xs={6}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '16px',
                                                                                                                    textAlign: 'right'
                                                                                                                }}>
                                                                                                                {eachItem?.name?.toUpperCase()}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                        <Grid item xs={6}>
                                                                                                            <Typography
                                                                                                                style={{
                                                                                                                    fontSize: '16px',
                                                                                                                    textAlign: 'right'
                                                                                                                }}>
                                                                                                                ${eachItem?.name === 'TARIFF AMOUNT' ? (((+each?.serviceFee) + (+eachItem?.value)).toFixed(2)) : (+eachItem?.value)?.toFixed(2)} {each?.currency}
                                                                                                            </Typography>
                                                                                                        </Grid>
                                                                                                    </Fragment>
                                                                                                )
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    <Grid item xs={12}>
                                                                                        <Divider/>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '18px',
                                                                                                textAlign: 'right',
                                                                                                fontWeight: '600'
                                                                                            }}>
                                                                                            SUBTOTAL
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={6}>
                                                                                        <Typography
                                                                                            style={{
                                                                                                fontSize: '18px',
                                                                                                textAlign: 'right',
                                                                                                fontWeight: '600'
                                                                                            }}>
                                                                                            ${(each?.finalTotalBeforeTax).toFixed(2)} {each?.currency}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </AccordionDetails>
                                                                        </Accordion>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        }
                                                    </Box>
                                                </ButtonBase>
                                            </Box>
                                        </Grid>
                                    )
                                }
                            )
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}