import {Divider, Grid, Typography} from "@mui/material";
import {LTLOrderListEach} from "./LTLOrderListEach";
import {Fragment} from "react";

export const LTLOrderListTable = ({order}) => {

    return (
        <Grid container spacing={1}>
            <Grid item md={8} xs={8}>
                <Grid container spacing={1}>
                    <Grid item md={3} xs={5}>
                        <Typography style={{fontWeight: '600'}}>
                            Order Number
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={2}>
                        <Typography style={{fontWeight: '600'}}>
                            Partner
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Sender
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Recipient
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={4} xs={4}>
                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Status
                        </Typography>
                    </Grid>
                    <Grid item md={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Invoice
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={3}>
                        <Typography style={{fontWeight: '600'}}>
                            Carrier
                        </Typography>
                    </Grid>
                    <Grid item md={3} xs={4}>
                        <Typography style={{fontWeight: '600'}}>
                            Cost
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Fragment key={index}>
                        <LTLOrderListEach order_info={orderEach}/>
                    </Fragment>
                )
            }
        </Grid>
    )
}