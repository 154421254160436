//To-do move all the API calls in this file
export const BACKEND_ROUTE_URI = "https://admin.uucargo.ca/api";

export const NODE_API =
  process.env.NODE_ENV === "production"
    ? "https://api.uucargo.ca/v2"
    : "http://localhost:9001";

export const NODE_API_TEST = "http://localhost:9000";

// Check login state
export const BACKEND_LOGIN_STATE = `${BACKEND_ROUTE_URI}/loginstate`;

// Upload file API
export const UPLOAD_CSV_FILE = `${NODE_API}/cargo_control_tracking/upload`;
export const UPLOAD_XLSX_FILE = `${NODE_API}/bol/upload-bol`;

//shipment management
export const SEARCH_SHIPMENT =
  process.env.NODE_ENV === "production"
    ? `${NODE_API}/shipment_management/search`
    : `${NODE_API}/shipment_management/search`;

export const PARTNER_URI =
  process.env.NODE_ENV === "production"
    ? "https://partner.uucargo.ca/api"
    : "http://localhost:9001/api";

//HS Code
export const GET_HSCODE_SECTIONS = `${NODE_API}/hscode/sections`;
export const GET_HSCODE_DETAIL = `${NODE_API}/hscode/hscodedetails/hscode`;
export const UPDATE_HSCODE_SECTION = `${NODE_API}/hscode/sections/update`;
export const UPDATE_HSCODE_DETAIL = `${NODE_API}/hscode/hscodedetails/update`;
export const INIT_ELASTIC_SEARCH = `${NODE_API}/elastic-search/init`;

// Bol List Code
export const BOL_LIST_ALL = `${NODE_API}/bol/bol-ref`;
// Bol update bol ref record status
export const BOL_REF_STATUS_UPDATE = `${NODE_API}/bol/update-bol-status`;

export const BOL_REF_NUMBER_UPDATE = `${NODE_API}/bol/update-bol-number`;

export const BACKEND_ME_API = `${BACKEND_ROUTE_URI}/me`;

// Bol details
export const BOL_DETAILS = `${NODE_API}/bol/bol-details-be`;
// Search order API
export const SEARCH_ORDER = `${NODE_API}/order_management/list`;

// Get rating  by uploading the Xlsx file
// export const UPLOAD_RATING_XLSX_FILE = `${NODE_API}/api/freight-forwarding/test`;
// export const UPLOAD_SHIPPING_XLSX_FILE = `${NODE_API}/api/freight-forwarding/test_2`;

export const UPLOAD_RATING_XLSX_FILE = `${NODE_API_TEST}/order_management/rates`;

export const UPLOAD_SHIPPING_XLSX_FILE = `${NODE_API_TEST}/order_management/shipping`;

export const LABEL_DOWNLOAD_URL_PREFIX = "https://storage.googleapis.com/";

export const LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE = "https://storage.googleapis.com";


export const SERVICE_BASE_URL =  process.env.NODE_ENV === "production" ? `https://api.uucargo.ca` : `http://localhost:9003`;

export const SERVICE_SERVICE_URL =  process.env.NODE_ENV === "production" ? `https://api.uucargo.ca/service/service` : `http://localhost:9003/service/service`;

export const SERVICE_DAO_URL =  process.env.NODE_ENV === "production" ? `https://api.uucargo.ca/service/dao` : `http://localhost:9003/service/dao`;

export const NODE_ROUTE_URI =
  process.env.NODE_ENV === "development"
    ? "http://localhost:9000"
    : "https://api.uucargo.ca/v2";