import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Snackbar, Select
} from "@material-ui/core";
import LoadingButton from '@mui/lab/LoadingButton';
import {useForm, Controller} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {Alert, Pagination} from "@material-ui/lab";
import axios from "axios";
import {PARTNER_URI, SERVICE_SERVICE_URL} from "../../utils/apiUrl";
import {useSelector} from "react-redux";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import dayjs from "dayjs";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Upload from '@mui/icons-material/Upload';
import InputLabel from "@mui/material/InputLabel";
import {MenuItem} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%"
    },
    unTrackingTable: {
        "border-spacing": "2rem 1rem"
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
        "white-space": "nowrap"
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
}));

const EmailTopUp = () => {

    let userId = useSelector(state => state?.menuBarReducer?.partnerId)

    const classes = useStyles();
    const matches = useMediaQuery("(max-width:600px)");

    // const partnerInfo = getPartnerInfo();
    const partnerInfo = useSelector((state) => state.user);

    const {register, handleSubmit, control} = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [sentDate, setSentDate] = useState(dayjs());
    const [currency, setCurrency] = useState("CAD");
    // const [originalText, setOriginalText] = useState("");
    const [partnerProfilesList, setPartnerProfilesList] = useState([]);
    const [partnerId, setPartnerId] = useState("");
    const [amount, setAmount] = useState(0);

    const [toastOpen, setToastOpen] = useState(false);
    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const getPartnerProfileListResult = async (partnerSearch) => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/partner/searchAllPartnerProfileView?query_string=${partnerSearch}`);
            const result = [];
            for (const partner of data) {
                const partnerData = {
                    label: `${partner?.partner_id || "N/A"} | ${partner?.firstname || "N/A"} ${partner?.lastname || "N/A"} | ${partner?.phone || "N/A"} | ${partner?.business_name || "N/A"} | ${partner?.company_crm_no || partner?.crm_email || "N/A"}`,
                    value: partner?.partner_id
                }
                result.push(partnerData);
            }
            setPartnerProfilesList(result);
            console.log(result);
            return result;
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        userId && getPartnerProfileListResult(userId)
    }, [userId])

    const getData = async ({
                               senderEmail,
                               senderName,
                               subject,
                               sentDate,
                               remarkMessage,
                               refNumber,
                               originalText,
                               amount,
                               partnerId,
                               currency
                           }) => {
        try {
            setIsLoading(true);
            setSuccessMessage("");
            const requestBody = {
                "senderEmail": senderEmail,
                "senderName": senderName,
                "subject": subject,
                "originalText": originalText,
                "sentDate": sentDate.format("YYYY-MM-DD"),
                "remarkMessage": remarkMessage,
                "refNumber": refNumber,
                "partnerId": partnerId,
                "amount": (+amount).toFixed(2),
                "currency": currency,
            };
            const {data} = await axios.post(`${PARTNER_URI}/billingCenter/operator/createTopUpPaymentInvoiceFromEmailTransfer`, requestBody);
            console.log(data);
            setErrorMessage("");
            setSuccessMessage("Create Top-Up Success!");
            handleToastClick();
        } catch (e) {
            console.log(e);
            setErrorMessage("Error! The Ref is redundant!");
            handleToastClick();
            setSuccessMessage("");
        } finally {
            setIsLoading(false);
        }
    }

    const onSubmit = data => {
        // console.log(data);
        const {senderEmail, senderName, subject, remarkMessage, refNumber, originalText} = data;

        if (sentDate === null) {
            setErrorMessage("Please Select Date!");
            handleToastClick();
            return;
        }

        if (!senderName || senderName?.trim() === "") {
            setErrorMessage("Please Enter Sender Name!");
            handleToastClick();
            return;
        }

        if (!subject || subject?.trim() === "") {
            setErrorMessage("Please Enter Subject!");
            handleToastClick();
            return;
        }
        console.log("amount: ", amount);

        if (!amount || `${amount}`?.trim() === "" || +amount === 0) {
            setErrorMessage("Amount can not be non-negative number!");
            handleToastClick();
            return;
        }

        if (!refNumber || refNumber?.trim() === "") {
            setErrorMessage("Please Enter refNumber!");
            handleToastClick();
            return;
        }

        if (!partnerId || partnerId?.trim() === "") {
            setErrorMessage("Please Select Partner!");
            handleToastClick();
            return;
        }

        setErrorMessage("");
        getData({
            senderEmail,
            senderName,
            subject,
            sentDate,
            amount,
            partnerId,
            currency,
            originalText,
            refNumber,
            remarkMessage
        });
    }

    return (
        <Box paddingBottom="3rem">
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction">
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{width: '100%'}}>
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{width: '100%'}}>
                            {successMessage}
                        </Alert>
                    )
                })()}
            </Snackbar>
            <Box>
                <Box component="form" width={"1000px"} margin="0 auto" sx={{
                    display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
                    alignItems: "center", alignContent: "center"
                }}>
                    <h1>Email Transfer Top-Up</h1>
                    <Box flex="45%" width="100%">
                        <Autocomplete
                            disablePortal
                            options={partnerProfilesList}
                            fullWidth
                            filterOptions={(options, state) => options}
                            getOptionLabel={(option) => option.label}
                            {...register("partnerId",)}
                            onChange={(e, value) => {
                                setPartnerId(value.value);
                            }}
                            onInputChange={async (e, value) => {
                                setPartnerId("");
                                const result = await getPartnerProfileListResult(value);
                                console.log(result);
                                setPartnerProfilesList(result);
                            }}
                            renderInput={(params) => <TextField id="outlined-basic"
                                                                label="Search Partner (Name, Email Or Phone)"
                                                                variant="outlined"
                                                                {...register("partnerId")}
                                                                required
                                                                fullWidth
                                                                {...params}

                            />}
                        />
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField id="outlined-basic" label="Sender Email" variant="outlined"
                                   type="email" {...register("senderEmail")} fullWidth/>
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField id="outlined-basic" label="Sender Name"
                                   variant="outlined" {...register("senderName")} required fullWidth/>
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField id="outlined-basic" label="Email Subject" variant="outlined" {...register("subject")}
                                   required fullWidth/>
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField id="outlined-basic" label="Remark Message"
                                   variant="outlined" {...register("remarkMessage")} fullWidth/>
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField id="outlined-basic" label="Ref Number" variant="outlined" {...register("refNumber")}
                                   required fullWidth/>
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField
                            id="outlined-basic"
                            multiline
                            fullWidth
                            rows={4}
                            variant="outlined"
                            placeholder="Copy the entire email content and paste here"
                            {...register("originalText")}
                            // value={originalText}
                            // onChange={e => {
                            //   setOriginalText(e.target.value);
                            // }
                            // }
                        />
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField
                            id="outlined-basic"
                            label="Amount"
                            variant="outlined"
                            value={amount}
                            onChange={(e) => {
                                console.log(e.target.value);
                                setAmount(+e.target.value);
                            }
                            }
                            fullWidth
                            required
                            defaultValue={0}
                            type="number"
                            InputProps={{
                                startAdornment: (
                                    <Select
                                        defaultValue={"CAD"}
                                        onChange={e => {
                                            setCurrency(e.target.value);
                                        }
                                        }
                                        disableUnderline
                                        variant="standard"
                                        value={currency}
                                    >
                                        <MenuItem value={"CAD"}>CAD</MenuItem>
                                        <MenuItem value={"USD"}>USD</MenuItem>
                                    </Select>
                                )
                            }}
                        />
                        {/*<Box mb={2}>*/}
                        {/*  <FormControl fullWidth size="small">*/}
                        {/*    <Select*/}
                        {/*      labelId="demo-simple-select-label"*/}
                        {/*      id="demo-simple-select"*/}
                        {/*      label="Currency"*/}
                        {/*      defaultValue={"CAD"}*/}
                        {/*      onChange={e => {*/}
                        {/*        setCurrency(e.target.value);*/}
                        {/*      }*/}
                        {/*      }*/}
                        {/*    >*/}
                        {/*      <MenuItem value={"CAD"}>CAD</MenuItem>*/}
                        {/*      <MenuItem value={"USD"}>USD</MenuItem>*/}
                        {/*    </Select>*/}
                        {/*  </FormControl>*/}
                        {/*</Box>*/}
                    </Box>
                    <Box flex="45%" width="100%">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Sent Date"
                                value={sentDate}
                                onChange={setSentDate}
                                maxDate={dayjs().toDate()}
                                renderInput={(params) => <TextField {...params} id="outlined-basic" label="Sent Date"
                                                                    variant="outlined" {...register("invoiceDate")}
                                                                    fullWidth/>}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box display="flex" sx={{gap: "3rem"}}>
                        <LoadingButton
                            variant="contained"
                            component="span"
                            color="primary"
                            style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                width: "7rem",
                            }}
                            loading={isLoading}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Submit
                        </LoadingButton>
                    </Box>
                    <Box>
                        {(() => {
                            if (errorMessage !== "") {
                                return (
                                    <Alert severity="error" sx={{width: '100%'}}>
                                        Error!
                                        <hr/>
                                        {errorMessage}
                                    </Alert>
                                )
                            }
                            if (successMessage !== "") {
                                return (
                                    <Alert severity="success" sx={{width: '100%'}}>
                                        {successMessage}
                                    </Alert>
                                )
                            }
                        })()}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default EmailTopUp;
