import React, { useEffect, useRef, useState } from "react";
import { FormLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import { Box, Button, Snackbar } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { NODE_ROUTE_URI, SERVICE_SERVICE_URL } from "../../utils/apiUrl";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { VendorInvoiceLineList } from "./VendorInvoiceLineList";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert } from "@material-ui/lab";

const styles = {
  ShippingOrderRoot: {
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  ShippingOrderContent: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '10px',
    // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    border: '1px solid #D1D1D1',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
}

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px"
    }
  },
  smallInput: {
    "& .MuiInputBase-input": {
      height: "2px", // Adjust the height as per your preference
      padding: "2px", // Adjust the padding as per your preference
      fontSize: "14px", // Adjust the font size as per your preference
    },
  }
});

const getStatusText = (statusCode) => {
  if (statusCode === 0) {
    return "Initial"
  }
  return "Processed";
}

const ProcessVendorInvoiceTable = () => {
  const classes = useStyles();


  const [accountBase, setAccountBase] = useState("All");
  const [distinctVendorInvoiceList, setDistinctVendorInvoiceList] = useState([]);
  const [selectVendorInvoice, setSelectVendorInvoice] = useState(0);

  const [invoiceLineData, setInvoiceLineData] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };


  const handleSetAccountBase = (event) => {
    event.preventDefault();
    setAccountBase(event?.target?.value);
  }

  const handleChangeVendorInvoice = (event, value) => {
    if (value) {
      setSelectVendorInvoice(value);
    } else {
      setSelectVendorInvoice(null);
    }
  };

  const getInvoiceLineDataList = async () => {
    try {
      const { data } = await axios.get(`${SERVICE_SERVICE_URL}/transactionAdjustments/getInvoiceLineByInvoiceId`, {
        params: {
          invoiceId: selectVendorInvoice.id
        }
      });
      setInvoiceLineData(data);
    } catch (e) {
      console.log(e);
    }
  }

  const processInvoiceLineData = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const { data } = await axios.post(`${SERVICE_SERVICE_URL}/transactionAdjustments/processInvoiceLineData`, {
        invoiceId: selectVendorInvoice.id
      });
      console.log(data);
      handleToastClick();
      setSuccessMessage("Success!");
      await getDistinctInvoiceList();
    } catch (e) {
      console.log(e);
      setErrorMessage(e?.response?.data?.error || "Error!");
      handleToastClick();
    } finally {
      setIsLoading(false);
    }
  }

  const getDistinctInvoiceList = async () => {
    const requestParams = {
      accountBase: accountBase === "All" ? "" : accountBase
    }

    const { data } = await axios.get(`${NODE_ROUTE_URI}/transactionAdjustments/getDistinctInvoiceLineSeparateList`, {
      params: requestParams
    });
    console.log("distintVendorInvoiceList: ", data);
    setDistinctVendorInvoiceList(data);
    setSelectVendorInvoice(null);
  }

  useEffect(() => {
    (async () => {
      await getDistinctInvoiceList();
    })()
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Submit Transaction">
        {(() => {
          if (errorMessage !== "") {
            return (
              <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                Error!
                <hr />
                {errorMessage}
              </Alert>
            )
          }
          return (
            <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          )
        })()}

      </Snackbar>
      <Grid item xs={12}>
        <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'center'}}>
          Search and Process Vendor Invoice
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={styles.ShippingOrderContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{
                textAlign: 'left',
                fontWeight: '600'
              }}>
                Filter By
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Typography style={{textAlign: 'left'}}>
                      Carrier
                    </Typography>
                  </FormLabel>
                  <Select
                    value={accountBase}
                    size='small'
                    style={{textAlign: 'left', fontSize: '14px'}}
                    onChange={handleSetAccountBase}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"UPS"}>UPS</MenuItem>
                    <MenuItem value={"FedEx"}>FedEx</MenuItem>
                    <MenuItem value={"Canpar"}>Canpar</MenuItem>
                    <MenuItem value={"CanadaPost"}>Canada Post</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                      <Typography style={{ textAlign: 'left' }}>Vendor Invoice</Typography>
                      <Typography style={{ fontSize: '12px', color: '#888' }}>
                        Total Invoices: {distinctVendorInvoiceList.length}
                      </Typography>
                    </Box>
                  </FormLabel>
                  <Autocomplete
                    value={selectVendorInvoice}
                    options={distinctVendorInvoiceList}
                    getOptionLabel={(option) => `${option.account_base} | ${option.invoice_no} | ${dayjs(option.invoice_date).format("YYYY-MM-DD HH:mm")} | ${getStatusText(option.status)}`}
                    fullWidth
                    renderInput={(params) => <TextField
                      {...params}
                      className={classes.smallInput}
                    />}
                    // onInputChange={handleInputChangeUser}
                    onChange={handleChangeVendorInvoice}
                    renderOption={(option) => (
                      <Typography
                        style={{fontSize: '14px'}}
                      >
                        {option.account_base} | {option.invoice_no} | {dayjs(option.invoice_date).format("YYYY-MM-DD HH:mm")} | {getStatusText(option.status)}
                      </Typography>
                    )}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                height: '100%',
                justifyContent: "flex-end"
              }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: '#1D8B45',
                    height: '100%',
                    "&:hover": {
                      backgroundColor: '#1D8B45',
                      filter: 'brightness(0.9)'
                    },
                  }}
                  type="button"
                  onClick={getInvoiceLineDataList}
                  disabled={selectVendorInvoice === null}
                >
                  <Typography
                    style={{textTransform: 'none', fontSize: '16px'}}
                  >
                    Search Invoice Lines
                  </Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <VendorInvoiceLineList invoiceLineList={invoiceLineData} />
            </Grid>
          </Grid>
          {
            invoiceLineData?.length > 0 && selectVendorInvoice ? (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <LoadingButton
                    variant="contained"
                    component="span"
                    color="secondary"
                    loading={isLoading}
                    disabled={+selectVendorInvoice?.status === 1}
                    onClick={() => {
                      processInvoiceLineData()
                    }}
                  >
                    Process Data
                  </LoadingButton>
                </Box>
              </Grid>
            ) : (
              <>

              </>
            )
          }
        </Box>
      </Grid>
    </>
  );
};

export default ProcessVendorInvoiceTable;
