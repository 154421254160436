import {Button, Container, Divider, FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const CarQuoteDetails = () => {

    const {quoteId} = useParams();
    const history = useHistory();

    const [quoteInfo, setQuoteInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [quoteStatus, setQuoteStatus] = useState('default');
    const [carSize, setCarSize] = useState([]);

    const getQuoteDetails = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingCar/getMovingCarQuoteByQuoteId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    quoteId: quoteId
                }
            })
            console.log('[CarQuoteDetails] getQuoteDetails api - result', result);
            setQuoteInfo(result?.data);
            setQuoteStatus(result?.data?.status);

        } catch (e) {
            console.log('[CarQuoteDetails] getQuoteDetails api - error', e?.response);
        } finally {
            setLoading(false);
        }
    }

    const handleEditStatus = async (status) => {
        let requestURL = `${NODE_ROUTE_URI}/movingCar/editMovingCarQuotesByQuoteId`;
        try {
            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: {
                    quoteId: quoteId,
                    status: status
                }
            })
            console.log('[CarQuoteDetails] handleEditStatus api - result', result);

        } catch (e) {
            console.log('[CarQuoteDetails] handleEditStatus api - error', e?.response);
        } finally {
            setLoading(false);
        }
    }

    const handleChangeStatus = (event) => {
        setQuoteStatus(event?.target?.value);
        handleEditStatus(event?.target?.value);
    }

    useEffect(() => {
        if (quoteId) {
            getQuoteDetails();
        }
    }, [quoteId]);

    useEffect(() => {
        if (quoteInfo) {
            const result = quoteInfo?.category_value?.split(', ').map(item => item.replace('CAR-SIZE-', ''));
            setCarSize(result);
        }
    }, [quoteInfo]);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Car Quote Details
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            {
                                quoteInfo?.icon_uri ?
                                    <img src={`${LABEL_DOWNLOAD_URL_PREFIX}${quoteInfo?.icon_uri}`} alt="logo"
                                         width='60px'/>
                                    :
                                    <Fragment>
                                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                            Carrier
                                        </Typography>
                                        <Typography style={{fontSize: '18px'}}>
                                            {quoteInfo?.account_base}
                                        </Typography>
                                    </Fragment>
                            }
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Quote Number
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.quote_no || 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Expired Date
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {dayjs(quoteInfo?.expired_time).format("YYYY-MM-DD")}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Quote Status
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={quoteStatus}
                                    size='small'
                                    onChange={handleChangeStatus}
                                >
                                    <MenuItem disabled value='default'>
                                        <em>Select Status</em>
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        <Typography style={{color: '#1D8B45', fontSize: '18px'}}>
                                            Active
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem value={-1}>
                                        <Typography style={{color: '#454545', fontSize: '18px'}}>
                                            Inactive
                                        </Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Make
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.make}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Model
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.model}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Year
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.year_of_production}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Shipping Mode
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.shipping_mode}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Moving From
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.ship_from_city}, {quoteInfo?.ship_from_province}, {quoteInfo?.ship_from_country}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Moving To
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.ship_to_city}, {quoteInfo?.ship_to_province}, {quoteInfo?.ship_to_country}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Items allowed inside vehicle
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.items_allowed_inside_car}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Average shipping days
                            </Typography>
                            <Typography style={{fontSize: '18px'}}>
                                {quoteInfo?.shipping_days_range}
                            </Typography>
                        </Grid>
                        {
                            (+quoteInfo?.includes_insurance === 1) &&
                            <Grid item xs={3}>
                                <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                    Insurance
                                </Typography>
                                <Typography style={{fontSize: '18px'}}>
                                    {quoteInfo?.insurance_covered_desc}
                                </Typography>
                            </Grid>
                        }
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Car Size
                            </Typography>
                            {
                                carSize?.map((size, index) => (
                                    <Typography key={index} style={{fontSize: '18px'}}>
                                        {size}
                                    </Typography>
                                ))
                            }
                        </Grid>
                        <Grid item xs={6}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Cost
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Tariff Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.tariff_charge?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Fuel Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.fuel_charge?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Tax
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.taxes?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                {
                                    +quoteInfo?.insurance_charge > 0 &&
                                    <>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                Insurance Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                $ {quoteInfo?.insurance_charge?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                {
                                    +quoteInfo?.cross_border_fee > 0 &&
                                    <>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                Cross Border Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                $ {quoteInfo?.cross_border_fee?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                {
                                    +quoteInfo?.extra_local_pickup_charge > 0 &&
                                    <>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                Extra Local Pickup Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                $ {quoteInfo?.extra_local_pickup_charge?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                {
                                    +quoteInfo?.extra_local_delivery_charge > 0 &&
                                    <>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                Extra Local Deliver Charge
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                                $ {quoteInfo?.extra_local_delivery_charge?.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{fontSize: '18px', textAlign: 'right'}}>
                                        $ {quoteInfo?.final_amount?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{marginBottom: '20px'}}>
                    <Button
                        variant='contained'
                        onClick={() => history.push(('/v2/car-quote-list'))}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Back to Quote List
                        </Typography>
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}