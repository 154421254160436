import {Fragment} from "react";
import {Button, Grid, Link, Snackbar, Typography} from "@mui/material";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../utils/apiUrl";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import {useHistory} from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const PickupListEach = ({pickupData, getPickupList}) => {

    const history = useHistory();

    const storedToken = getAccessToken("access_token");

    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loadingClose, setLoadingClose] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [closedStatus, setClosedStatus] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const getInvoiceNumber = async (invoiceId) => {

        if (!invoiceId) {
            return 'N/A'
        }

        const requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: invoiceId
                }
            })
            console.log('result', result);
            const {data: {invoiceRefNumber}} = result;
            return invoiceRefNumber;
        } catch (e) {
            console.log('e', e?.response);
            return 'N/A'
        }
    }

    const handleView = () => {
        const index = pickupData?.shippingOrders?.length - 1;
        history.push(`/v2/package-orders/${pickupData?.shippingOrders?.[index]?.order_id}`);
    }

    const handleClosePickup = async () => {
        setLoadingClose(true);
        // let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/admin/closeSchedulePickupAppointment`;
        let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/admin/pickup/cancel`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURI,
                params: {
                    appointmentId: pickupData?.appoint_id,
                    forceCancel: 1
                }
            });
            setErrorMessage('');
            setSuccessMessage('Successfully close the pickup.');
            handleSuccessMessage();
            handleDialogClose();
            // setTimeout(() => {
            //     getPickupList();
            // }, 1000);
            setClosedStatus(true);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to close the pickup.');
            handleErrorMessage();
        } finally {
            setLoadingClose(false);
        }
    }

    useEffect(() => {
        (async () => {
            if (pickupData?.crm_invoice_no) {
                const crmInvoiceNumber = await getInvoiceNumber(pickupData?.crm_invoice_no);
                setInvoiceNumber(crmInvoiceNumber);
            }
        })()
    }, [pickupData?.crm_invoice_no]);

    console.log('[PickupListEach] pickupData', pickupData);

    return (
        <Grid container spacing={1}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Close pickup
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to close this pickup? This action cannot be undone.
                        Please confirm if you'd like to proceed with closing the pickup. {
                        pickupData?.crm_invoice_no && 'Please note that this action will also initiate the refund of the pickup fee.'
                    }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <LoadingButton onClick={handleClosePickup} variant="outlined" type="button" loading={loadingClose}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Appointment Id
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {pickupData?.appoint_id}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{
                        textAlign: 'left',
                        fontSize: '14px',
                        fontWeight: '600',
                    }}>
                    Status
                </Typography>
                {
                    closedStatus ?
                        <Typography style={{
                            fontSize: '14px',
                            textAlign: 'left',
                            color: '#FF0303'
                        }}>
                            Canceled
                        </Typography>
                        :
                        <Typography style={{
                            fontSize: '14px',
                            textAlign: 'left',
                            color: +pickupData?.status === 1 ? '#1D8B45' : +pickupData?.status === -1 ? '#FF0303' : '#454545'
                        }}>
                            {pickupData?.status === 0 ? 'Initialed' : pickupData?.status === 100 ? 'Inactive' : pickupData?.status === 1 ? 'Submitted' : pickupData?.status === -1 ? 'Canceled' : 'Cancel Request'}
                        </Typography>
                }
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Carrier
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {pickupData?.account_base}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Confirmation #
                </Typography>
                <Typography style={{
                    fontSize: '14px',
                    textAlign: 'left',
                    wordWrap: 'break-word', // Wrap text to the next line
                    wordBreak: 'break-word', // Break long words if necessary
                    whiteSpace: 'normal', // Allows the text to wrap onto multiple lines
                }}>
                    {pickupData?.confirm_appoint_no || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Invoice #
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {invoiceNumber || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Tracking #
                </Typography>
                <Typography style={{
                    fontSize: '14px',
                    textAlign: 'left',
                    wordWrap: 'break-word', // Wrap text to the next line
                    wordBreak: 'break-word', // Break long words if necessary
                    whiteSpace: 'normal', // Allows the text to wrap onto multiple lines
                }}>
                    {pickupData?.tracking_nos}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Type
                </Typography>
                <Box sx={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {
                            pickupData?.shippingOrders?.length > 1 ? "Multiple Order Pickup" : "Single Order Pickup"
                        }
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Related Shipping Orders
                </Typography>
                <Box sx={{display: 'flex', gap: '5px', flexWrap: 'wrap'}}>
                    {
                        pickupData?.shippingOrders?.map((order) => (
                            <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                {order?.order_id}
                            </Typography>
                        ))
                    }
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Package Label
                </Typography>
                {
                    pickupData?.pickup_label_uri ?
                        <Link href={`${LABEL_DOWNLOAD_URL_PREFIX}${pickupData?.pickup_label_uri}`} target="_blank"
                              rel="noopener noreferrer" sx={{
                            textDecoration: 'none',
                            '&:hover': {
                                textDecoration: 'underline', // Add underline on hover
                                textDecorationColor: '#1D8B45'
                            },
                        }}>
                            <Typography style={{
                                textAlign: 'left',
                                fontSize: '14px',
                                textDecoration: 'none',
                                color: '#1D8B45'
                            }}>
                                View
                            </Typography>
                        </Link>
                        :
                        <Typography style={{
                            textAlign: 'left',
                            fontSize: '14px',
                            textDecoration: 'none',
                            color: '#000000'
                        }}>
                            N/A
                        </Typography>
                }
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Appointment Date
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {pickupData?.appoint_date}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Pickup Time
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {pickupData?.appoint_time_start?.split(':').slice(1).join(':')} - {pickupData?.appoint_time_end?.split(':').slice(1).join(':')}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Pickup Location
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {pickupData?.pickup_location}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Pickup Address
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {pickupData?.pickup_addr_line2 && `${pickupData?.pickup_addr_line2}-`}{pickupData?.pickup_addr}
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {pickupData?.pickup_city}, {pickupData?.pickup_province}
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {pickupData?.pickup_country}, {pickupData?.pickup_postal_code}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Contact Information
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {pickupData?.pickup_name}
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {pickupData?.pickup_tel}
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {pickupData?.pickup_email}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Remark Notes
                </Typography>
                <Typography style={{
                    textAlign: 'left',
                    fontSize: '14px',
                }}>
                    {pickupData?.remark_notes || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: '10px'
                }}>
                    {
                        (pickupData?.status !== -1 && !closedStatus) &&
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#454545',
                                "&:hover": {
                                    backgroundColor: '#454545',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleDialogOpen}
                            size='small'
                        >
                            <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                                Close
                            </Typography>
                        </LoadingButton>
                    }
                    {
                        pickupData?.shippingOrders?.length > 0 &&
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            size='small'
                            onClick={handleView}
                        >
                            <Typography style={{
                                textAlign: 'left',
                                fontSize: '14px',
                                textTransform: 'none'
                            }}>
                                View
                            </Typography>
                        </Button>
                    }
                </Box>
            </Grid>
        </Grid>
    )

}