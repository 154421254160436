import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Box from "@mui/material/Box";
import {CircularProgress, Container} from "@mui/material";
import {CreditCardSetupForm} from "./CreditCardSetupForm";
import {getAccessToken} from "../../utils/doToken";

const stripePromise = loadStripe(
    process.env.NODE_ENV === "development" ? "pk_test_51IK8FMH0JPRrjEMmbN0wv8VwP6yvr8SPUd1Kqgsgw930pSRYVrPQxpwRl2XU9VBHcsJjh8uj0CFV7dq3asN9H8w500ihTaHCIB" : "pk_live_51IK8FMH0JPRrjEMm7eHYO7OrcX5T7ddazAQysXyyD4iYnQrTAFY7NWJoH0L28yv48g1KOBUnMH9reHqu1ofPlTbw00YwMFKWVH"
)

export const CreditCardSetup = ({userId, roleId, handleCancelCreditCard}) => {

    const [clientSecret, setClientSecret] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const setupIntent = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/admin/setupIntent`;
        setLoading(true);
        const params = {
            userId,
            roleId
        }
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: params
            })
            console.log('result', result);
            setClientSecret(result.data.client_secret);
            setError(false);
            setLoading(false);

        } catch (e) {
            console.log('error', e.response);
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        setupIntent();
    }, [])

    if (loading) {
        return <Box sx={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
            <CircularProgress/>
        </Box>
    }

    if (error && !loading) {
        return <>
            <h3>Sorry We currently encounter some issues, please try again later.</h3>
        </>
    }

    return (
        <Container maxWidth='xl'>
            {
                loading ? <CircularProgress/>
                    :
                    <Elements stripe={stripePromise}>
                        <CreditCardSetupForm clientSecret={clientSecret}
                                             handleCancelCreditCard={handleCancelCreditCard}
                                             userId={userId}
                                             roleId={roleId}/>
                    </Elements>
            }
        </Container>
    )
}