import {Container, FormLabel, Grid, InputAdornment, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {useEffect, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Pagination} from "@mui/lab";
import {ClaimTable} from "./ClaimTable";

const styles = {
    ClaimRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ClaimContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ClaimList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "16px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        },
    }
});

export const Claim = () => {

    const classes = useStyles();

    const [selectCarrier, setSelectCarrier] = useState('All');
    const [ownerRole, setOwnerRole] = useState(0);
    const [userList, setUserList] = useState([]);
    const [displayUser, setDisplayUser] = useState(null);
    const [status, setStatus] = useState(null);
    const [trackingNumber, setTrackingNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [claimList, setClaimList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);

    const getClaimList = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/claim/getPackageClaimListAdmin`;
        let params = {
            page: 0,
            pageSize: 10,
            ownerRole: ownerRole === 0 ? null : ownerRole,
            userId: displayUser?.partner_id,
            status: status,
            carrier: selectCarrier === 'All' ? null : selectCarrier,
            trackingNumber: trackingNumber
        }

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: params
            });

            console.log('[getClaimList] - result', result);

            setClaimList(result?.data?.data);
            setError(false);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getClaimListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/claim/getPackageClaimListAdmin`;
        let params = {
            page: page,
            pageSize: 10,
            ownerRole: ownerRole === 0 ? null : ownerRole,
            userId: displayUser?.partner_id,
            status: status,
            carrier: selectCarrier === 'All' ? null : selectCarrier,
            trackingNumber: trackingNumber
        }

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: params
            });

            console.log('[getClaimList] - result', result);

            setClaimList(result?.data?.data);
            setError(false);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const getAllEndUserList = async () => {
        const requestURL = `${PARTNER_URI}/partner/getAllUserProfileList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleChangeCarrier = (event) => {
        console.log(event.target.value);
        setSelectCarrier(event.target.value);
    };

    const handleChangeOwnerRole = (event) => {
        setOwnerRole(event?.target?.value);
        setDisplayUser(null);
    }

    const handleChangeUser = (event, value) => {
        if (value) {
            setDisplayUser(value);
        } else {
            setDisplayUser(null);
        }
    };

    const handleChangeStatus = (event) => {
        setStatus(event?.target?.value);
    }

    const handleInputTrackingNumber = (event) => {
        setTrackingNumber(event?.target?.value);
    }

    const handleSearch = async () => {
        await getClaimList();
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value - 1;
        getClaimListByPage(page);
    };

    useEffect(() => {
        (
            async () => {
                await getClaimList();
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                if (ownerRole === 1) {
                    await getAllEndUserList();
                } else if (ownerRole === 3) {
                    await getAllPartnerList();
                } else {
                    setUserList([]);
                }
            }
        )()
    }, [ownerRole]);

    return (
        <Container maxWidth="xl">
            <Box sx={styles.ClaimRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Claim List
                </Typography>
                <Box sx={styles.ClaimContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography style={{textAlign: 'left'}}>
                                        Role
                                    </Typography>
                                </FormLabel>
                                <Select
                                    value={ownerRole}
                                    size='small'
                                    style={{textAlign: 'left'}}
                                    onChange={handleChangeOwnerRole}
                                >
                                    <MenuItem value={0}>
                                        All
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Partner
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        EndUser
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography style={{textAlign: 'left'}}>
                                        {ownerRole === 1 ? 'EndUser' : ownerRole === 3 ? 'Partner' : 'All'}
                                    </Typography>
                                </FormLabel>
                                <Autocomplete
                                    value={displayUser}
                                    options={userList}
                                    getOptionLabel={
                                        option => ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                            `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                    }
                                    // filterOptions={(options, state) => options}
                                    disabled={ownerRole === 0}
                                    fullWidth
                                    renderInput={(params) => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                    />}
                                    // onInputChange={handleInputChangeUser}
                                    onChange={handleChangeUser}
                                    renderOption={(option) => (
                                        <Typography>
                                            {
                                                ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                                    `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                            }
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography style={{textAlign: 'left'}}>
                                        Carrier
                                    </Typography>
                                </FormLabel>
                                <Select
                                    value={selectCarrier}
                                    size='small'
                                    style={{textAlign: 'left'}} // font size of input text
                                    onChange={handleChangeCarrier}
                                >
                                    <MenuItem value='All'>
                                        All
                                    </MenuItem>
                                    <MenuItem value='CanadaPost'>
                                        Canada Post
                                    </MenuItem>
                                    <MenuItem value='Canpar'>
                                        Canpar
                                    </MenuItem>
                                    <MenuItem value='FedEx'>
                                        FedEx
                                    </MenuItem>
                                    <MenuItem value='UPS'>
                                        UPS
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography style={{textAlign: 'left'}}>
                                        Status
                                    </Typography>
                                </FormLabel>
                                <Select
                                    value={status}
                                    size='small'
                                    style={{textAlign: 'left'}} // font size of input text
                                    onChange={handleChangeStatus}
                                >
                                    <MenuItem value={0}>
                                        Initial
                                    </MenuItem>
                                    <MenuItem value={100}>
                                        Processing
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Accepted
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Refunded
                                    </MenuItem>
                                    <MenuItem value={-1}>
                                        Cancelled
                                    </MenuItem>
                                    <MenuItem value={-2}>
                                        Declined
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>
                                    <Typography style={{textAlign: 'left'}}>
                                        Tracking Number
                                    </Typography>
                                </FormLabel>
                                <TextField
                                    value={trackingNumber}
                                    fullWidth
                                    size='small'
                                    onInput={handleInputTrackingNumber}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    size='small'
                                    onClick={handleSearch}
                                    loading={loading}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Search
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        {
                            loading ?
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <CircularProgress/>
                                    </Box>
                                </Grid>
                                :
                                error ?
                                    <Grid item xs={12}>
                                        <Box sx={styles.ShippingOrderContent}>
                                            <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                Sorry We currently encounter some issues, please try again later.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    :
                                    claimList?.length === 0 ?
                                        <Grid item xs={12}>
                                            <Box sx={styles.ShippingOrderContent}>
                                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                    No Claim Found
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <ClaimTable claimList={claimList}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Pagination
                                                    count={lastPage}
                                                    page={+currentPage}
                                                    size='small'
                                                    onChange={handlePageChange}
                                                />
                                            </Grid>
                                        </>

                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}