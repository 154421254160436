import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import HandshakeIcon from '@mui/icons-material/Handshake';
import BusinessIcon from '@mui/icons-material/Business';
import {Box, Button, Card, Container} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import InventoryIcon from '@mui/icons-material/Inventory';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {CloudUploadOutlined} from "@material-ui/icons";
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import BuildIcon from '@mui/icons-material/Build';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const LandingPage = () => {

    const history = useHistory();
    const classes = useStyles();

    return (
        <Box p="2rem">
            <Container>
                {/*<h1 className={classes.title}>Hi {firstname},</h1>*/}
                <Box display="flex" sx={{
                    flexWrap: "wrap"
                }}>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() => history.push("/v2/transaction/adjustment")}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <LocalShippingIcon/>
                            Transaction Adjustment
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() => history.push("/v2/reviseInvoice")}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <LocalShippingIcon/>
                            Revise Invoices
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() => history.push("/v2/emailCampaign")}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <LocalShippingIcon/>
                            Email Campaign
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/routePrice")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ListAltIcon/>
                            Create/Update Route Price
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/uploadLabel")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <CloudUploadOutlined/>
                            Upload PNG Label
                        </Box>
                    </Box>
                    {/*<Box*/}
                    {/*    className={classes.root}*/}
                    {/*    mr={4}*/}
                    {/*    role="button"*/}
                    {/*    onClick={() =>*/}
                    {/*        history.push("/v2/myLTLs")*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Box*/}
                    {/*        display="flex"*/}
                    {/*        justifyContent="center"*/}
                    {/*        height="100%"*/}
                    {/*        alignItems="center"*/}
                    {/*    >*/}
                    {/*        <SearchIcon/>*/}
                    {/*        Pallets*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/companylist")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <BusinessIcon/>
                            Company List
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/partnerList")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <HandshakeIcon/>
                            Partner List
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/orderpayment/backendorder/notpaid/list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <InventoryIcon/>
                            Not Payed Backend Orders
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/orderpayment/backendorder/all/list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <InventoryIcon/>
                            All Backend Orders
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/orderpayment/shippingorder/notpaid/list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <InventoryIcon/>
                            Not Paid Shipping Orders
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/orderpayment/shippingorder/all/list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <InventoryIcon/>
                            All Shipping Orders
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/etransferemailList")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <MarkEmailReadIcon/>
                            Process End-user Etransfers
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/searchtransactions")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <SearchIcon/>
                            Search All Transactions
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/tracking")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <LocationSearchingIcon/>
                            Tracking Package
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/email/topUp")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <LocationSearchingIcon/>
                            e-Transfer Top-Up
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/end-user-list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <AccountCircleIcon/>
                            End User List
                        </Box>
                    </Box>
                    {/*<Box*/}
                    {/*    className={classes.root}*/}
                    {/*    mr={4}*/}
                    {/*    role="button"*/}
                    {/*    onClick={() =>*/}
                    {/*        history.push("/v2/ltl-order-list")*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Box*/}
                    {/*        display="flex"*/}
                    {/*        justifyContent="center"*/}
                    {/*        height="100%"*/}
                    {/*        alignItems="center"*/}
                    {/*    >*/}
                    {/*        <ListAltIcon/>*/}
                    {/*        LTL Order List*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/ltl-quote-list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ListAltIcon/>
                            LTL Quote & Order
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/moving-category-list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ListAltIcon/>
                            Category List
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/car-quote-list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ListAltIcon/>
                            Car Quote List
                        </Box>
                    </Box>
                    {/*<Box*/}
                    {/*    className={classes.root}*/}
                    {/*    mr={4}*/}
                    {/*    role="button"*/}
                    {/*    onClick={() =>*/}
                    {/*        history.push("/v2/upload-ltl-rate-file")*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Box*/}
                    {/*        display="flex"*/}
                    {/*        justifyContent="center"*/}
                    {/*        height="100%"*/}
                    {/*        alignItems="center"*/}
                    {/*    >*/}
                    {/*        <FileUploadIcon/>*/}
                    {/*        Upload LTL Rate File*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/package-orders")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ListAltIcon/>
                            Package Order List
                        </Box>
                    </Box>
                    {/*<Box*/}
                    {/*    className={classes.root}*/}
                    {/*    mr={4}*/}
                    {/*    role="button"*/}
                    {/*    onClick={() =>*/}
                    {/*        history.push("/v2/upload-box-rate-file")*/}
                    {/*    }*/}
                    {/*>*/}
                    {/*    <Box*/}
                    {/*        display="flex"*/}
                    {/*        justifyContent="center"*/}
                    {/*        height="100%"*/}
                    {/*        alignItems="center"*/}
                    {/*    >*/}
                    {/*        <FileUploadIcon/>*/}
                    {/*        Upload Box Rate File*/}
                    {/*    </Box>*/}
                    {/*</Box>*/}
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/upload-file")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <FileUploadIcon/>
                            Upload File
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/moving-order-list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ListAltIcon/>
                            Moving Order List
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/claim-list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ListAltIcon/>
                            Claim List
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/container-shipping-list")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <DirectionsBoatIcon/>
                            Container Shipping
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/tools/")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <BuildIcon/>
                            Tools
                        </Box>
                    </Box>
                    <Box
                        className={classes.root}
                        mr={4}
                        role="button"
                        onClick={() =>
                            history.push("/v2/account/")
                        }
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                            alignItems="center"
                        >
                            <ManageAccountsIcon/>
                            Account
                        </Box>
                    </Box>
                    {/*<Box*/}
                    {/*    className={classes.root}*/}
                    {/*    mr={4}*/}
                    {/*    role="button"*/}
                    {/*    onClick={() => history.push("/freight-forwarding/bol-list/created")}*/}
                    {/*>*/}
                    {/*  <Box*/}
                    {/*      display="flex"*/}
                    {/*      justifyContent="center"*/}
                    {/*      height="100%"*/}
                    {/*      alignItems="center"*/}
                    {/*  >*/}
                    {/*    <ListAltIcon />*/}
                    {/*    Bol List*/}
                    {/*  </Box>*/}
                    {/*</Box>*/}
                    {/*<Box*/}
                    {/*    className={classes.root}*/}
                    {/*    mr={4}*/}
                    {/*    role="button"*/}
                    {/*    onClick={() => history.push("/loose-item/3rd-party/shipping/trans")}*/}
                    {/*>*/}
                    {/*  <Box*/}
                    {/*      display="flex"*/}
                    {/*      justifyContent="center"*/}
                    {/*      height="100%"*/}
                    {/*      alignItems="center"*/}
                    {/*  >*/}
                    {/*    <WidgetsIcon />*/}
                    {/*    Tracking Products*/}
                    {/*  </Box>*/}
                    {/*</Box>*/}
                </Box>
            </Container>
        </Box>
    );
};

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Montserrat",
        fontSize: "2rem",
        letterSpacing: "2px",
        color: "green",
        marginTop: "2vh",
        textAlign: "left",
    },
    root: {
        width: 150,
        height: "6rem",
        backgroundColor: "white",
        borderRadius: 5,
        cursor: "pointer",
        alignItems: "center",
        "&:hover": {
            backgroundColor: "orange",
            color: "white",
        },
    },
    lgRoot: {
        minHeight: 200,
        margin: 10,
        marginLeft: 0,
        marginTop: 10,
        // width: '90%',
        width: "100%",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
        textAlign: "left",
    },
    nameTH: {
        width: "100px",
    },
    idTH: {},
}));

export default LandingPage;
