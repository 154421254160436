import React, { useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShippingExportsTransaction from "../ExportTransaction/ShippingExportsTransaction";
import ExportVendorInvoiceTable from "../ExportVendorInvoice/ExportVendorInvoiceTable";
import TransactionAdjustment from "../TransactionAdjustment/TransactionAdjustment";
import UploadVendorInvoice from "../UploadVendorInvoices/UploadVendorInvoice";
import ProcessVendorInvoiceTable from "../ProcessVendorInvoice/ProcessVendorInvoiceTable";

const NavBar = ({
                  tabValue,
                  setTabValue
}) => {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexWrap: "wrap",
          gap: "10px",
          backgroundColor: "#FFFFFF",
          padding: "20px",
          borderRadius: "10px",
          border: "1px solid #D1D1D1"
        }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            color="success"
            checked={tabValue === 0}
            onClick={() => {
              setTabValue(0);
            }}
          />
          <Typography style={{ color: "#545454", padding: "0 5px", fontSize: "20px" }}>
            Process Vendor Invoices
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            color="success"
            checked={tabValue === 1}
            onClick={() => {
              setTabValue(1);
            }}
          />
          <Typography style={{ color: "#545454", padding: "0 5px", fontSize: "20px" }}>
            Upload Vendor Invoices
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            color="success"
            checked={tabValue === 2}
            onClick={() => {
              setTabValue(2);
            }}
          />
          <Typography style={{ color: "#545454", padding: "0 5px", fontSize: "20px" }}>
            Search Vendor Invoice And Process Line Data
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            color="success"
            checked={tabValue === 3}
            onClick={() => {
              setTabValue(3);
            }}
          />
          <Typography style={{ color: "#545454", padding: "0 5px", fontSize: "20px" }}>
            Export Transactions Excel
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<CheckCircleIcon />}
            color="success"
            checked={tabValue === 4}
            onClick={() => {
              setTabValue(4);
            }}
          />
          <Typography style={{ color: "#545454", padding: "0 5px", fontSize: "20px" }}>
            Export Wrongly Billed Tracking# and Invoices
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const ShippingExportTransactionPage = () => {

  const [tabValue, setTabValue] = useState(0);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <NavBar tabValue={tabValue} setTabValue={setTabValue} />
        <Grid item xs={12}>
          {
            (() => {
              switch (tabValue) {
                case 0:
                  return <TransactionAdjustment />
                case 1:
                  return <UploadVendorInvoice />
                case 2:
                  return <ProcessVendorInvoiceTable />
                case 3:
                  return <ShippingExportsTransaction />
                case 4:
                  return <ExportVendorInvoiceTable />
                default:
                  return <TransactionAdjustment />
              }
            })()
          }
        </Grid>

      </Grid>
    </Container>
  );
};

export default ShippingExportTransactionPage;
