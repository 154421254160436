import {Fragment, useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {
    CustomTextFieldNormalWithUnit,
    CustomTextFieldPallet
} from "../../utils/customComponent";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardBrokerage = ({
                                               isConfirm,
                                               orderData,
                                               getOrderDetails
                                           }) => {

    const [brokerage, setBrokerage] = useState({
        description: '',
        brokerageCost: 0,
        dutyTax: 0
    });
    const [originalBrokerage, setOriginalBrokerage] = useState({
        description: '',
        brokerageCost: 0,
        dutyTax: 0
    });

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);

    }

    const onChange = (field, value) => {
        switch (field) {
            case 'description':
                setBrokerage(prevState => ({
                    ...prevState,
                    description: value
                }))
                break;
            case 'brokerageCost':
                setBrokerage(prevState => ({
                    ...prevState,
                    brokerageCost: +value
                }))
                break;
            case 'dutyTax':
                setBrokerage(prevState => ({
                    ...prevState,
                    dutyTax: +value
                }))
                break;
            default:
        }
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    brokerageDescription: brokerage?.description,
                    brokerageFee: brokerage?.brokerageCost,
                    dutyTax: brokerage?.dutyTax,
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        setBrokerage(prevState => ({
            ...prevState,
            description: orderData?.prices?.brokerage_desc,
            brokerageCost: orderData?.prices?.brokerage_fee,
            dutyTax: orderData?.prices?.duty_tax
        }))
        setOriginalBrokerage(prevState => ({
            ...prevState,
            description: orderData?.prices?.brokerage_desc,
            brokerageCost: orderData?.prices?.brokerage_fee,
            dutyTax: orderData?.prices?.duty_tax
        }))
    }, [orderData])

    console.log('[LTLOrderDashboardBrokerage] brokerage', brokerage);
    console.log('[LTLOrderDashboardBrokerage] original brokerage', originalBrokerage);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={4}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Description
                        </FormLabel>
                        <CustomTextFieldPallet
                            textValue={brokerage?.description}
                            field='description'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Brokerage Cost
                        </FormLabel>
                        <CustomTextFieldNormalWithUnit
                            textValue={brokerage?.brokerageCost}
                            field='brokerageCost'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Duty Tax
                        </FormLabel>
                        <CustomTextFieldNormalWithUnit
                            textValue={brokerage?.dutyTax}
                            field='dutyTax'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Box>
            </Grid>
            {
                (JSON.stringify(brokerage) !== JSON.stringify(originalBrokerage)) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}