import React, { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import { BACKEND_LOGIN_STATE, UPLOAD_XLSX_FILE } from "../../utils/apiUrl";
import { errHandling } from "../../utils/fetchUtils";

import axios from "axios";

//Test mode
// const testMode = process.env.NODE_ENV === "production" ? false : true;

function BolManagement() {
  const classes = useStyles();

  const matches = useMediaQuery("(max-width:600px)");

  // const [errMsg, setErrMsg] = useState("");
  const [uploadErrMsg, setUploadErrMsg] = useState("");

  const [fileObj, setFileObj] = useState(null);

  const [contactFileObj, setContactFileObj] = useState(null);

  const [uploading, setUploadingState] = useState(false);

  const [uploadingContact, setUploadingContactState] = useState(false);

  const [uploadingSuccess, setUploadingSuccessState] = useState(false);

  const [uploadingContactSuccess, setUploadingContactSuccessState] =
    useState(false);

  const bodyFormData = new FormData();

  const onDrop = (_type) => (e) => {
    if (!(e.target.files && e.target.files[0])) return;
    setUploadingSuccessState(false);
    if (_type === "bol") {
      setFileObj({
        selectedFile: e.target.files[0],
        //**** added line below ****//
        selectedFileName: e.target.files[0].name,
      });
    } else {
      setContactFileObj({
        selectedFile: e.target.files[0],
        //**** added line below ****//
        selectedFileName: e.target.files[0].name,
      });
    }
  };

  const handleCloseSnackBar = () => {
    setUploadErrMsg("");
  };

  const handleSubmit = (_type) => {
    const tmpFileObj = _type === "bol" ? fileObj : contactFileObj;
    if (tmpFileObj?.selectedFile) {
      _type === "bol"
        ? setUploadingState(true)
        : setUploadingContactState(true);
      try {
        bodyFormData.append("xlsx_file", tmpFileObj.selectedFile);
        bodyFormData.append("file_type", _type);
      } finally {
        axios({
          method: "post",
          url: UPLOAD_XLSX_FILE,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data;",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then((res) => {
            if (_type === "bol") {
              setUploadingSuccessState(true);
              setFileObj(null);
              setUploadingState(false);
            } else {
              setUploadingContactSuccessState(true);
              setContactFileObj(null);
              setUploadingContactState(false);
            }
          })
          .catch((error) => {
            errHandling(error, setUploadErrMsg);
            _type === "bol"
              ? setUploadingState(false)
              : setUploadingContactState(false);
          });
      }
    }
  };

  // const getMainComponent = (_authChecking, _errMsg, _authorized) => {
  //   if (_errMsg) {
  //     return (
  //       <Box>
  //         {_errMsg}
  //         <Box mt={2}>
  //           <a
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             href="https://admin.uucargo.ca/"
  //           >
  //             Go back
  //           </a>
  //         </Box>
  //       </Box>
  //     );
  //   }
  //   if (_authorized) {
  //   }

  //   return (
  //     <div className={classes.root}>
  //       <CircularProgress />
  //     </div>
  //   );
  // };

  return (
    <Box
      className="App"
      // className={
      //   matches ? classes.smPackageTabContainer : classes.packageTabContainer
      // }
      width={matches ? "100%" : "600px"}
      margin="0 auto"
    >
      <br />
      {/* {getMainComponent(authChecking, errMsg, authorized)} */}

      <Box
        display="flex"
        flexDirection="column"
        width="300px"
        margin="0 auto"
        justifyContent="center"
        alignItems="center"
        mb={4}
      >
        <Box textAlign="center" mb={2}>
          {/* Max. file size: <b>10MB</b> <br /> */}
          Upload contacts files, file type: <b>XLSX</b>
        </Box>
        <input
          accept=".xlsx"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          // onChange={()={}}
          onChange={onDrop("contacts")}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="span"
            color="primary"
            style={{
              textTransform: "unset",
              fontSize: "1rem",
            }}
          >
            Select one contact file
          </Button>
        </label>
        {contactFileObj && (
          <Box className={classes.buttonFilesWrapper} mt={3}>
            <Box display="flex" alignItems="center">
              <InsertDriveFileIcon />
              {contactFileObj.selectedFileName}
            </Box>
            <Box mt={2} mb={1}>
              <Button
                variant="contained"
                component="span"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  backgroundColor: "rgba(0,0,255,0.2)",
                  width: "7rem",
                }}
                onClick={() => handleSubmit("contacts")}
              >
                {uploadingContact ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Box>
          </Box>
        )}
        {uploadingContactSuccess && (
          <Box textAlign="center" mt={3} color="green" fontSize="1.2rem">
            File uploaded successfully.
          </Box>
        )}
      </Box>
      <hr />

      <Box
        display="flex"
        flexDirection="column"
        width="300px"
        margin="0 auto"
        justifyContent="center"
        alignItems="center"
        mt={4}
      >
        <Box textAlign="center" mb={2}>
          {/* Max. file size: <b>10MB</b> <br /> */}
          Upload BOL files, file type: <b>XLSX</b>
        </Box>
        <input
          accept=".xlsx"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          // onChange={()={}}
          onChange={onDrop("bol")}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="span"
            color="primary"
            style={{
              textTransform: "unset",
              fontSize: "1rem",
            }}
          >
            Select one BOL file
          </Button>
        </label>
        {fileObj && (
          <Box className={classes.buttonFilesWrapper} mt={3}>
            <Box display="flex" alignItems="center">
              <InsertDriveFileIcon />
              {fileObj.selectedFileName}
            </Box>
            <Box mt={2} mb={1}>
              <Button
                variant="contained"
                component="span"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  backgroundColor: "rgba(0,0,255,0.2)",
                  width: "7rem",
                }}
                onClick={() => handleSubmit("bol")}
              >
                {uploading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Box>
          </Box>
        )}
        {uploadingSuccess && (
          <Box textAlign="center" mt={3} color="green" fontSize="1.2rem">
            File uploaded successfully.
          </Box>
        )}
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(uploadErrMsg)}
        onClose={handleCloseSnackBar}
        message={uploadErrMsg}
        autoHideDuration={3000}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
}));

export default BolManagement;
