import {useEffect, useState} from "react";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import Box from "@mui/material/Box";
import {Button, Divider, Snackbar} from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {SchedulePickup} from "../SchedulePickup/SchedulePickup";
import {EditSchedulePickup} from "../SchedulePickup/EditSchedulePickup";
import {getAccessToken} from "../../utils/doToken";
import {PackageOrderTransactionPickupEach} from "./PackageOrderTransactionPickupEach";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
    PackageOrderDashboardPickupAppointmentRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        background: '#FFFFFF'
    }
}

export const PackageOrderTransactionPickup = ({orderInfo, selectTransactionData}) => {

    const [appointmentList, setAppointmentList] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSchedule, setIsSchedule] = useState(false);
    const [editSchedule, setEditSchedule] = useState(false);
    const [pickupInformation, setPickupInformation] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleScheduleOpen = () => {
        setIsSchedule(true);
    }

    const handleScheduleClose = () => {
        setIsSchedule(false);
    }

    const handleUpdatePickup = (pickup) => {
        setPickupInformation(pickup);
        handleEditScheduleOpen();
    }

    const handleCancelPickup = () => {
        setPickupInformation(null);
        handleEditScheduleClose();
    }

    const handleEditScheduleOpen = () => {
        setEditSchedule(true);
    }

    const handleEditScheduleClose = () => {
        setEditSchedule(false);
    }

    const getAppointmentList = async () => {
        try {
            const {data} = await axios.get(`${NODE_ROUTE_URI}/packagesPickupAppointment/getPickupAppointmentListByOrderIdAdmin`, {
                    params: {
                        orderId: orderInfo?.order_id,
                        partnerId: orderInfo?.partner_id,
                        ownerRole: orderInfo?.owner_role
                    },
                }
            );
            console.log("[PackageOrderTransactionPickup] getAppointmentList", data);
            setAppointmentList(data);
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        getAppointmentList();
    }, []);

    if (isSchedule) {
        return (
            <Box sx={style.PackageOrderDashboardPickupAppointmentRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Pickup Appointment
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{padding: '0 20px'}}>
                            <SchedulePickup selectTransactionData={selectTransactionData}
                                            handleScheduleClose={handleScheduleClose}
                                            orderInfo={orderInfo}/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    if (editSchedule) {
        return (
            <Box sx={style.PackageOrderDashboardPickupAppointmentRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Pickup Appointment
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{padding: '0 20px'}}>
                            <EditSchedulePickup handleCancelPickup={handleCancelPickup}
                                                pickupData={pickupInformation}
                                                orderInfo={orderInfo}/>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    console.log('[PackageOrderTransactionPickup] appointmentList', appointmentList);

    return (
        <Box sx={style.PackageOrderDashboardPickupAppointmentRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Pickup Appointment
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '0 20px'}}>
                        {
                            appointmentList?.length > 0 ?
                                appointmentList?.map((element, index) => (
                                    <PackageOrderTransactionPickupEach appointment={element}
                                                                       key={index}
                                                                       handleUpdatePickup={handleUpdatePickup}
                                                                       getAppointmentList={getAppointmentList}/>
                                )) :
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            The shipments haven't yet been scheduled for pickup.
                                        </Typography>
                                    </Grid>
                                </Grid>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant='outlined'
                            sx={{
                                border: '2px solid #1D8B45',
                                "&:hover": {
                                    border: '2px solid #1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleScheduleOpen}
                        >
                            <Typography style={{
                                textTransform: 'none',
                                fontSize: '14px',
                                color: '#1D8B45',
                            }}>
                                Schedule Now
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}