import {Fragment, useEffect, useState} from "react";
import {Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../utils/constInfo";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardSpecialService = ({
                                                    getDataFromChildComponent,
                                                    triggerAction,
                                                    isConfirm,
                                                    orderData,
                                                    shipperResidential,
                                                    consigneeResidential,
                                                    getOrderDetails
                                                }) => {

    const [specialService, setSpecialService] = useState([]);

    const [originalSpecialService, setOriginalSpecialService] = useState([]);

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const addOrRemoveSpecialService = (serviceName) => {
        const hasServiceCode = specialService?.some(service => service.serviceCode === serviceName);

        if (hasServiceCode) {
            // Remove the element with serviceCode 'serviceName'
            const updatedServices = specialService?.filter(service => service.serviceCode !== serviceName);
            setSpecialService(updatedServices);
        } else {
            // Add the element with serviceCode 'serviceName'
            const updatedServices = [...specialService, {serviceCode: serviceName}];
            setSpecialService(updatedServices);
        }
    }

    const addOrRemoveSpecialServices = (serviceNameParent, serviceNameChild) => {
        const hasParentServiceCode = specialService.some(service => service.serviceCode === serviceNameParent);
        const hasChildServiceCode = specialService.some(service => service.serviceCode === serviceNameChild);
        if (!hasParentServiceCode && !hasChildServiceCode) {
            const updatedServices = [...specialService, {serviceCode: serviceNameParent}, {serviceCode: serviceNameChild}];
            setSpecialService(updatedServices);
        } else if (!hasParentServiceCode && hasChildServiceCode) {
            const updatedServices = [...specialService, {serviceCode: serviceNameParent}];
            setSpecialService(updatedServices);
        } else {
            const updatedServices = specialService?.filter(service => service.serviceCode !== serviceNameParent);
            setSpecialService(updatedServices);
        }
    }

    const handleAppointmentDelivery = () => {
        addOrRemoveSpecialService('APTFGT');
    }

    const handleProtectedService = () => {
        addOrRemoveSpecialService('PROTEC');
    }

    const handleInsideDelivery = () => {
        addOrRemoveSpecialService('INSDLY');
    }

    const handleInsidePickup = () => {
        addOrRemoveSpecialService('INSPIC');
    }

    const handlePrivateResidenceDelivery = () => {
        addOrRemoveSpecialServices('PRESDL', 'TLGDEL');
    }

    const handlePrivateResidencePickup = () => {
        addOrRemoveSpecialServices('PRESPU', 'TLGPU');
    }

    const handleTailgateDelivery = () => {
        addOrRemoveSpecialService('TLGDEL');
    }

    const handleTailgatePickup = () => {
        addOrRemoveSpecialService('TLGPU');
    }

    const verifyAddressIsResidentialShipper = async () => {
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let data = {
            address: shipperResidential?.shipperAddress,
            city: shipperResidential?.shipperCity,
            province: shipperResidential?.shipperProvince,
            postalCode: shipperResidential?.shipperPostalCode,
            country: shipperResidential?.shipperCountry
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            console.log('result shipper residence', result?.data?.result);
            // getShipperResidential(result?.data?.result);
            const hasServiceCode = specialService?.some(service => service.serviceCode === 'PRESPU');
            if (result?.data?.result) {
                if (!hasServiceCode) {
                    // Add the element with serviceCode 'serviceName'
                    const updatedServices = [...specialService, {serviceCode: 'PRESPU'}];
                    setSpecialService(updatedServices);
                }
            } else {
                if (hasServiceCode) {
                    // Remove the element with serviceCode 'serviceName'
                    const updatedServices = specialService.filter(service => service.serviceCode !== 'PRESPU');
                    setSpecialService(updatedServices);
                }
            }
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const verifyAddressIsResidentialConsignee = async () => {
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let data = {
            address: consigneeResidential?.consigneeAddress,
            city: consigneeResidential?.consigneeCity,
            province: consigneeResidential?.consigneeProvince,
            postalCode: consigneeResidential?.consigneePostalCode,
            country: consigneeResidential?.consigneeCountry
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            console.log('result consignee residence', result?.data?.result);
            // getShipperResidential(result?.data?.result);
            const hasServiceCode = specialService?.some(service => service.serviceCode === 'PRESDL');
            if (result?.data?.result) {
                if (!hasServiceCode) {
                    // Add the element with serviceCode 'serviceName'
                    const updatedServices = [...specialService, {serviceCode: 'PRESDL'}];
                    setSpecialService(updatedServices);
                }
            } else {
                if (hasServiceCode) {
                    // Remove the element with serviceCode 'serviceName'
                    const updatedServices = specialService.filter(service => service.serviceCode !== 'PRESDL');
                    setSpecialService(updatedServices);
                }
            }
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    specialServices: specialService,
                    shipperAddressType: specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
                    consigneeAddressType: specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalSpecialService(specialService);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({specialService});
        }
    }, [triggerAction]);

    useEffect(() => {
        if (orderData?.bolInfo?.pickup_instruction) {
            const stringArray = orderData?.bolInfo?.pickup_instruction?.split('\n');
            const dataArray = stringArray.map((str) => ({
                serviceCode: str,
            }));
            setSpecialService(dataArray);
            setOriginalSpecialService(dataArray);
        }
    }, [orderData]);

    useEffect(() => {
        if (shipperResidential) {
            if (orderData?.bolInfo?.shipper_address !== shipperResidential?.shipperAddress) {
                verifyAddressIsResidentialShipper();
            } else {

            }
        }
    }, [shipperResidential]);

    useEffect(() => {
        if (consigneeResidential) {
            if (orderData?.bolInfo?.consignee_address !== consigneeResidential?.consigneeAddress) {
                verifyAddressIsResidentialConsignee();
            } else {

            }
        }
    }, [consigneeResidential]);


    // console.log('special service', specialService);
    // console.log('original special service', originalSpecialService);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PRESPU")}
                            onChange={handlePrivateResidencePickup}
                        />}
                        label={
                            <Typography>
                                Private Residence Pickup
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "TLGPU")}
                            onChange={handleTailgatePickup}
                        />}
                        label={
                            <Typography>
                                Tailgate Pickup
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "INSPIC")}
                            onChange={handleInsidePickup}
                        />}
                        label={
                            <Typography>
                                Inside Pickup
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PROTEC")}
                            onChange={handleProtectedService}
                        />}
                        label={
                            <Typography>
                                Protected Service
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "PRESDL")}
                            onChange={handlePrivateResidenceDelivery}
                        />}
                        label={
                            <Typography>
                                Private Residence Delivery
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "TLGDEL")}
                            onChange={handleTailgateDelivery}
                        />}
                        label={
                            <Typography>
                                Tailgate Delivery
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService.some(service => service?.serviceCode === "INSDLY")}
                            onChange={handleInsideDelivery}
                        />}
                        label={
                            <Typography>
                                Inside Delivery
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            <Grid item md={3} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControlLabel
                        value="start"
                        control={<Checkbox
                            checked={specialService?.some(service => service?.serviceCode === "APTFGT")}
                            onChange={handleAppointmentDelivery}
                        />}
                        label={
                            <Typography>
                                Appointment Delivery
                            </Typography>
                        }
                        labelPlacement="end"
                        disabled={isConfirm}
                    />
                </Box>
            </Grid>
            {
                (
                    JSON.stringify(specialService) !== JSON.stringify(originalSpecialService)
                ) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}