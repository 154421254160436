import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar, Select, IconButton
} from "@material-ui/core";
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm, Controller } from "react-hook-form";
import Upload from '@mui/icons-material/Upload';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { Alert } from "@material-ui/lab";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { InputAdornment, MenuItem } from "@mui/material";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import axios from "axios";
import { PARTNER_URI, SERVICE_BASE_URL, SERVICE_DAO_URL } from "../../../utils/apiUrl";
import SaveIcon from '@material-ui/icons/Save';
import { Calculate, Savings } from "@mui/icons-material";
import { ReactComponent as CalculatorIcon } from "../../../images/Calculator.svg";
import { Cancel } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%"
  },
  unTrackingTable: {
    "border-spacing": "2rem 1rem"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
    "white-space": "nowrap"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const currencyList = [
  {
    label: "CAD"
  },
  {
    label: "USD"
  }
]

const getQuantityOfItems = (items) => {
  let result = 0;
  for (const item of items) {
    result += item.quantity;
  }
  return result;
}

const EditQuote = ({ quoteId,
                     refreshList,
                     setEdit,
                     originalCompanyName,
                     originalStandardAmount,
                     originalServiceFee,
                     originalAdditionalFee,
                     originalTax,
                     originalDiscount,
                     originalFinalAmount,
                     originalTotalAmount,
                     originalCurrencyCode,
                     originalExpiredDate,
                     originalRemarkNotes,
                     originBonus,
                     originDeliverLoadingDock,
                     originDeliverAppointment,
                     originInsurance,
                     originPickupLoadingDock,
                     items
                   }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");


  // const partnerInfo = getPartnerInfo();
  const { id } = useSelector((state) => state.user);
  const [errorMessage, setErrorMessage] = useState("");

  const [standardAmount, setStandardAmount] = useState(originalStandardAmount);
  const [quantity, setQuantity] = useState(getQuantityOfItems(items));
  const [unitPrice, setUnitPrice] = useState(((+standardAmount - +originBonus) / quantity).toFixed(2));
  const [serviceFee, setServiceFee] = useState(originalServiceFee);
  const [additionalFee, setAdditionalFee] = useState(originalAdditionalFee);
  const [tax, setTax] = useState(originalTax);
  const [bonus, setBonus] = useState(originBonus);
  const [finalAmount, setFinalAmount] = useState(originalFinalAmount);
  const [totalAmount, setTotalAmount] = useState(originalTotalAmount);
  const [currencyCode, setCurrencyCode] = useState(originalCurrencyCode);
  const [discount, setDiscount] = useState(originalDiscount);
  const [successMessage, setSuccessMessage] = useState("");
  const [expiredDate, setExpiredDate] = useState(dayjs(originalExpiredDate));
  // Additional Service Fee
  const [insurance, setInsurance] = useState(originInsurance);
  const [pickupLoadingDockFee, setPickupLoadingDockFee] = useState(originPickupLoadingDock);
  const [deliverLoadingDockFee, setDeliverLoadingDockFee] = useState(originDeliverLoadingDock);
  const [deliverAppointmentFee, setDeliverAppointmentFee] = useState(originDeliverAppointment);
  // const [companyId, setCompanyId] = useState(null);
  const [remarkNotes, setRemarkNotes] = useState(originalRemarkNotes);
  const inputFile = useRef(null);
  const [fileObj, setFileObj] = useState(null);
  const [inputFileName, setInputFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const onDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) {
      return;
    }
    setFileObj(e.target.files[0]);
    setInputFileName(e.target.files[0].name);
  };

  useEffect(() => {
    setQuantity(getQuantityOfItems(items));
  }, [items]);

  useEffect(() => {
    setTotalAmount(+standardAmount + +serviceFee + +additionalFee + +tax);
    setFinalAmount(+standardAmount + +serviceFee + +additionalFee + +tax - (+discount));
  }, [additionalFee, discount, serviceFee, standardAmount, tax])

  useEffect(() => {
    setStandardAmount(((+unitPrice * +quantity) + +bonus).toFixed(2));
  }, [bonus, quantity, unitPrice])

  useEffect(() => {
    setServiceFee((+standardAmount * 0.05).toFixed(2));
  }, [standardAmount])

  const onSubmit = async (e) => {
    try {

      if (finalAmount <= 0) {
        setErrorMessage("Final Amount must be a position number!");
        handleToastClick();
        return;
      }

      setErrorMessage("");
      setIsLoading(true);
      const formData = new FormData();
      formData.append("quoteId", quoteId);
      // formData.append("companyId", companyId);
      formData.append("expiredDate", expiredDate.toDate());
      formData.append("currencyCode", currencyCode);
      // formData.append("totalAmountGross", amountGross);
      // formData.append("totalAmount", amount);
      formData.append("standardAmount", standardAmount);
      formData.append("serviceFee", serviceFee);
      formData.append("additionalFee", additionalFee);
      formData.append("tax", tax);
      formData.append("bonus", bonus);
      formData.append("pickupLoadingDockFee", pickupLoadingDockFee);
      formData.append("deliverLoadingDockFee", deliverLoadingDockFee);
      formData.append("deliverAppointmentFee", deliverAppointmentFee);
      formData.append("insurance", insurance);
      formData.append("discount", discount);
      formData.append("finalAmount", finalAmount);
      formData.append("totalAmount", totalAmount);
      formData.append("remarkNotes", remarkNotes);
      formData.append("quoteFile", fileObj);
      const { data } = await axios.post(`${PARTNER_URI}/partnerPallets/editQuoteByQuoteId`, formData, {
        headers: {
          "Content-Type": "multipart/form-data;",
          "X-Requested-With": "XMLHttpRequest",
        }
      })
      console.log(data);
      setErrorMessage("");
      setSuccessMessage("Update Quote Success!");
      setEdit(false);
      // refresh quotes list
      refreshList();

      handleToastClick();
    } catch (e) {
      setErrorMessage("Update Quote Failed!");
      handleToastClick();
    } finally {
      setIsLoading(false);
    }
  }

  const calculateFinalAmount = () => {
    setFinalAmount(+standardAmount + +serviceFee + +additionalFee + +tax);
  }

  return (
    <Box paddingY="2rem">
      {/*Pop-up Toast*/}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Submit Transaction">
        {(() => {
          if (errorMessage !== "") {
            return (
              <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                Error!
                <hr />
                {errorMessage}
              </Alert>
            )
          }
          return (
            <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          )
        })()}

      </Snackbar>
      <Box>
        <Box component="form" width={"1000px"} margin="0 auto" sx={{
          display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
          alignItems: "center", alignContent: "center"
        }}>
          <h2>Edit Quote</h2>
          <Box flex="45%" width="100%">
            {/*<TextField id="outlined-basic" label="Company Name" variant="outlined" fullWidth />*/}
            {/*<Autocomplete*/}
            {/*  getOptionLabel={(option) => option.business_name}*/}
            {/*  options={companyList}*/}
            {/*  renderInput={(params) => (*/}
            {/*    <TextField*/}
            {/*      {...params}*/}
            {/*      label="Company Name"*/}
            {/*      fullWidth*/}
            {/*      variant="outlined"*/}
            {/*    />*/}
            {/*  )}*/}
            {/*  onChange={(e, value) => {*/}
            {/*    console.log("Company:", value);*/}
            {/*    setCompanyId(value.id);*/}
            {/*  }}*/}
            {/*/>*/}
            <TextField id="outlined-basic" label="Company Name" value={originalCompanyName} disabled variant="outlined" fullWidth />
          </Box>

          {/*  <TextField id="outlined-basic" label="Amount Gross" variant="outlined" fullWidth type="number" error={amountGross > amount}  value={amountGross} onChange={(e) => {*/}
          {/*    setAmountGross(e.target.value);*/}
          {/*  }}*/}
          {/*             InputProps={{*/}
          {/*               inputProps: {*/}
          {/*                 min: 0 }}}*/}
          {/*  />*/}
          {/*<TextField id="outlined-basic" label="Amount Net" variant="outlined" value={amount}*/}
          {/*           error={amountGross > amount}*/}
          {/*           onChange={(e) => {*/}
          {/*             setAmount(e.target.value);*/}
          {/*           }}*/}
          {/*           InputProps={{*/}
          {/*             inputProps: {*/}
          {/*               min: 0 }}}*/}
          {/*           fullWidth type="number" />*/}
          <TextField id="outlined-basic" label="Unit Price" variant="outlined" fullWidth type="number" value={unitPrice} onChange={(e) => {
            setUnitPrice(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0,
                       }, startAdornment: <InputAdornment position="start">{quantity} &times;</InputAdornment>}}
          />
          <TextField id="outlined-basic" label="Additional Fee" variant="outlined" fullWidth type="number" value={additionalFee} onChange={(e) => {
            setAdditionalFee(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Tax" variant="outlined" fullWidth type="number" value={tax} onChange={(e) => {
            setTax(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Bonus" variant="outlined" fullWidth type="number" value={bonus} error={bonus < 0} onChange={(e) => {
            setBonus(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Pickup Loading Dock" variant="outlined" fullWidth type="number" value={pickupLoadingDockFee} error={pickupLoadingDockFee < 0} onChange={(e) => {
            setPickupLoadingDockFee(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Deliver Loading Dock" variant="outlined" fullWidth type="number" value={deliverLoadingDockFee} error={deliverLoadingDockFee < 0} onChange={(e) => {
            setDeliverLoadingDockFee(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Deliver Appointment" variant="outlined" fullWidth type="number" value={deliverAppointmentFee} error={deliverAppointmentFee < 0} onChange={(e) => {
            setDeliverAppointmentFee(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Insurance" variant="outlined" fullWidth type="number" value={insurance} error={insurance < 0} onChange={(e) => {
            setInsurance(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Discount" variant="outlined" fullWidth type="number" value={discount} onChange={(e) => {
            setDiscount(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Service Fee (Default 5%)" variant="outlined" fullWidth type="number" value={serviceFee} onChange={(e) => {
            setServiceFee(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" label="Standard Fee" variant="outlined" disabled fullWidth type="number" value={standardAmount} onChange={(e) => {
            setStandardAmount(e.target.value);
          }}
                     InputProps={{
                       inputProps: {
                         min: 0 }}}
          />
          <TextField id="outlined-basic" disabled label="Total Amount" variant="outlined" fullWidth type="number" error={totalAmount < 0} value={totalAmount} onChange={(e) => {
            setTotalAmount(e.target.value);
          }} />
          <TextField id="outlined-basic" disabled label="Final Amount" variant="outlined" fullWidth type="number" error={finalAmount < 0} value={finalAmount} onChange={(e) => {
            setFinalAmount(e.target.value);
          }} />

          <Box flex="45%" width="100%">
            {/*<Autocomplete*/}
            {/*    getOptionLabel={(option) => option.label}*/}
            {/*    options={currencyList}*/}
            {/*    renderInput={(params) => (*/}
            {/*        <TextField*/}
            {/*            {...params}*/}
            {/*            label="Currency"*/}
            {/*            fullWidth*/}
            {/*            variant="outlined"*/}
            {/*        />*/}
            {/*    )}*/}
            {/*    defaultValue={currencyCode}*/}
            {/*    onChange={(e, value) => {*/}
            {/*      console.log(value);*/}
            {/*      setCurrencyCode(value.label);*/}
            {/*    }}*/}
            {/*/>*/}
            <TextField
              variant="outlined"
              fullWidth
              select
              value={currencyCode}
              label="Currency"
              onChange={(e) => {
                setCurrencyCode(e.target.value);
              }}
              className={classes.textfield}
              classes={{ root: classes.selectRoot }}
              MenuProps={{ classes: { paper: classes.menuPaper } }}
            >
              <MenuItem value="CAD">CAD</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
            </TextField>
          </Box>
          <Box flex="45%" width="100%" >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expired Date"
                value={expiredDate}
                onChange={setExpiredDate}
                renderInput={(params) => <TextField {...params} id="outlined-basic" label="Expired Date" variant="outlined" fullWidth />}
              />
            </LocalizationProvider>
          </Box>
          <TextField id="outlined-basic" label="Remark Notes (Optional)" multiline variant="outlined" value={remarkNotes} onChange={(e) => {
            setRemarkNotes(e.target.value);
          }} fullWidth />
          <input type="file" style={{ display: "none" }} ref={inputFile} onChange={onDrop} name="file" />
          <Button
            variant="contained"
            component="span"
            color="primary"
            style={{
              textTransform: "unset",
              fontSize: "1rem",
            }}
            onClick={() => {
              inputFile.current.click();
            }}
            endIcon={<Upload />}
          >
            Upload Original File
          </Button>
          {inputFileName && (
            <Box className={classes.buttonFilesWrapper} mt={3}>
              <Box display="flex" alignItems="center">
                <InsertDriveFileIcon />
                {inputFileName}
              </Box>
            </Box>
          )}
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" sx={{ gap: "3rem" }}>
            <Button variant="contained"
                    component="span"
                    type="submit"
                    style={{
                      textTransform: "unset",
                      fontSize: "1rem",
                      width: "10rem",
                      marginTop: "1rem",
                      backgroundColor: "#555555",
                      color: "white"
                    }}
                    onClick={() => {
                      setEdit(false);
                    }}
                    endIcon={<Cancel />}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "10rem",
                marginTop: "1rem"
              }}
              loading={isLoading}
              type="submit"
              onClick={onSubmit}
              endIcon={<SaveIcon />}
            >
              Save Quote
            </LoadingButton>

          </Box>

        </Box>
      </Box>
    </Box>
  )
}

export default EditQuote;