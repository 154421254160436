import React, { useState } from "react";
import { useForm, Controller } from 'react-hook-form';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel, makeStyles,
    MenuItem,
    Select, Snackbar,
    Typography
} from "@material-ui/core";
import { CloudUpload, InsertDriveFile } from "@material-ui/icons";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { NODE_ROUTE_URI } from "../../utils/apiUrl";

const useStyles = makeStyles((theme) => ({
    submitButton: {
        marginTop: theme.spacing(2),
    },
    fileNameContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    fileNameIcon: {
        marginRight: theme.spacing(1),
    },
}));

const EmailSuppressions = () => {
    const { handleSubmit, control, setValue } = useForm();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);

    const [toastOpen, setToastOpen] = useState(false);
    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

    const onFileChange = (event) => {
        if (event.target.files.length) {
            setUploadedFile(event.target.files[0]);
        }
    };

    const onSubmit = (data) => {
        // Handle form submission and API request here
        console.log(data);
        if (!uploadedFile) {
            setErrorMessage(`Upload File is missing!!`);
            handleToastClick();
            return;
        }
        const { type } = data;
        submitEmailSupperssiom({
            type
        });
    };

    const submitEmailSupperssiom = async ({
                                                  type
                                              }) => {
        try {

            if (!uploadedFile) {
                return;
            }
            setIsLoading(true);
            setErrorMessage("");
            const formData = new FormData();
            formData.append("type", type);
            formData.append("file", uploadedFile);

            const { data } = await axios.post(`${NODE_ROUTE_URI}/emailCampaign/uploadEmailSupperssionList`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data;",
                    "X-Requested-With": "XMLHttpRequest",
                }
            })
            console.log(data);
            setSuccessMessage("Upload Email Supperssions Success!");
            handleToastClick();

        } catch (e) {
            console.log(e);
            setErrorMessage(`Error! ${e?.response?.data?.error}`);
            handleToastClick();
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction">
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                                Error!
                                <hr />
                                {errorMessage}
                            </Alert>
                        )
                    }
                    return (
                        <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                            {successMessage}
                        </Alert>
                    )
                })()}

            </Snackbar>
            <Box
                component="form"
                width={"1000px"}
                margin="2rem auto"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    gap: "2rem",
                    alignItems: "center",
                    alignContent: "center",
                }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Controller
                            name="type"
                            control={control}
                            defaultValue="Invalid"
                            render={({ field }) => (
                                <FormControl fullWidth required>
                                    <InputLabel variant="filled">Type</InputLabel>
                                    <Select {...field}>
                                        <MenuItem value="Invalid">Invalid</MenuItem>
                                        <MenuItem value="Bounces">Bounces</MenuItem>
                                        <MenuItem value="Spam">Spam</MenuItem>
                                        <MenuItem value="Blocks">Blocks</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                accept=".csv,.xlsx"
                                id="upload-file-suppressions"
                                onChange={onFileChange}
                            />
                            <label htmlFor="upload-file-suppressions">
                                <Button variant="contained" color="primary" component="span" startIcon={<CloudUpload />}>
                                    Upload Suppersions File
                                </Button>
                            </label>
                        </FormControl>
                        {uploadedFile && (
                            <div className={classes.fileNameContainer}>
                                <InsertDriveFile className={classes.fileNameIcon} />
                                <Typography variant="body1">{uploadedFile.name}</Typography>
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {/*<Button type="submit" variant="contained" color="primary" fullWidth>*/}
                        {/*    Submit*/}
                        {/*</Button>*/}
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submitButton}
                            fullWidth
                            loading={isLoading}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit(onSubmit)(e)
                            }
                            }
                        >
                            Submit
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
        </>

    );
};

export default EmailSuppressions;




