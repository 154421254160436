import {FormLabel, Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

export const ClaimDashboardContactInfo = ({claimData, resetTrigger, handleContactInfo, validation}) => {

    const [contactInfo, setContactInfo] = useState({
        contactName: "",
        contactPhone: "",
        contactEmail: ""
    })

    const handleInputContactName = (event) => {
        setContactInfo(prevState => ({
            ...prevState,
            contactName: event?.target?.value
        }))
    }

    const handleInputContactPhone = (event) => {
        setContactInfo(prevState => ({
            ...prevState,
            contactPhone: event?.target?.value
        }))
    }

    const handleInputContactEmail = (event) => {
        setContactInfo(prevState => ({
            ...prevState,
            contactEmail: event?.target?.value
        }))
    }

    const handleSetClaimData = () => {
        setContactInfo(prevState => ({
            ...prevState,
            contactName: claimData?.contact_name,
            contactPhone: claimData?.contact_tel,
            contactEmail: claimData?.contact_email

        }))
    }

    useEffect(() => {
        handleSetClaimData();
    }, [claimData])

    useEffect(() => {
        handleSetClaimData();
    }, [resetTrigger])

    useEffect(() => {
        handleContactInfo(contactInfo);
    }, [contactInfo])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    Contact Information
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Contact Name
                    </FormLabel>
                    <TextField
                        value={contactInfo?.contactName}
                        onInput={handleInputContactName}
                        size='small'
                        error={validation?.contactInfoHasMissing?.includes('fullName') && !contactInfo?.contactName?.trim()}
                        helperText={(validation?.contactInfoHasMissing?.includes('fullName') && !contactInfo?.contactName?.trim()) && 'Required'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Contact Phone
                    </FormLabel>
                    <TextField
                        value={contactInfo?.contactPhone}
                        onInput={handleInputContactPhone}
                        size='small'
                        error={validation?.contactInfoHasMissing?.includes('phone') && !contactInfo?.contactPhone?.trim()}
                        helperText={(validation?.contactInfoHasMissing?.includes('phone') && !contactInfo?.contactPhone?.trim()) && 'Required'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Contact Email
                    </FormLabel>
                    <TextField
                        value={contactInfo?.contactEmail}
                        onInput={handleInputContactEmail}
                        size='small'
                        error={validation?.contactInfoHasMissing?.includes('email') && !contactInfo?.contactEmail?.trim()}
                        helperText={(validation?.contactInfoHasMissing?.includes('email') && !contactInfo?.contactEmail?.trim()) && 'Required'}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}