import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function ShippingListTransactionTable({
  tran_id,
  product_id,
  label_uri,
  tracking_no
}) {
  const classes = useStyles();

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link
            to={`/v2/shipping/list/product/${tran_id}`}
            target="_blank"
            rel="noopener noreferrer">
            {tran_id}
          </Link>
        </td>
        <td>{product_id}</td>
        <td>{tracking_no}</td>
        <td>
          <a href={label_uri} target="_blank" rel="noopener noreferrer">{label_uri ? "Download" : ""}</a>
        </td>
      </tr>
    </>
  );
}
