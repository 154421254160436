import {Button, Dialog, Divider, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Fragment, useEffect, useRef, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import {saveAs} from 'file-saver';
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {containerShippingFileList} from "../../utils/Helper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})

export const ContainerShippingOrderDetailFile = ({orderInfo, getContainerShippingOrderInfo}) => {

    const accessToken = getAccessToken("access_token");

    const inputFile = useRef(null);

    const classes = useStyles();

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isUpload, setIsUpload] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [fileType, setFileType] = useState('')
    const [fileName, setFileName] = useState(null);
    const [fileObj, setFileObj] = useState(null);
    const [contactFileObj, setContactFileObj] = useState(null);
    const [validationResult, setValidationResult] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteFile, setDeleteFile] = useState(null);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleDownUploadedFile = (file) => {
        const fileURL = `${LABEL_DOWNLOAD_URL_PREFIX}${file?.file_location_uri}`;
        saveAs(fileURL, file.fileName);
    }

    const handleStartUpload = async () => {
        setIsUpload(true);
    }

    const handleCancelUpload = () => {
        setIsUpload(false);
        setFileName(null);
        setFileType('');
        setFileObj(null);
        setContactFileObj(null);
        setValidationResult(null);
        setDeleteFile(null);
    }

    const handleDeleteFile = async () => {
        setLoadingDelete(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/deleteOceanShippingFileById`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                params: {
                    fileId: deleteFile?.file_id
                }
            });
            handleDialogClose();
            setErrorMessage('');
            setSuccessMessage(`Successfully delete ${deleteFile?.origi_file_name} file.`);
            handleSuccessMessage();
            handleCancelUpload();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(`Fail to delete ${deleteFile?.origi_file_name} file.`);
            handleErrorMessage();
        } finally {
            setLoadingDelete(false);
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);

        !fileType && setErrorMessage(prevState => prevState + 'File type is missing.');
        !fileName && setErrorMessage(prevState => prevState + 'Upload file is missing.');

        setValidationResult(preState => ({
            ...preState,
            fileTypeError: !fileType,
            fileNameError: !fileName
        }));

        if (!fileType ||
            !fileName
            // consigneeMissingKeys?.length > 0 ||
            // shipperPhoneError ||
            // consigneePhoneError
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handleConfirmUpload = () => {
        const result = validation();
        if (result) {
            handleUploadFile();
        }
    }

    const handleUploadFile = async () => {
        setLoadingUpload(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/uploadOceanShippingFilesAndAddFileURLsToOceanShippingOrder`;
        const bodyFormData = new FormData();
        const tmpFileObj = fileObj || contactFileObj;

        try {
            bodyFormData.append("files", tmpFileObj.selectedFile);
            bodyFormData.append("backendOrderId", orderInfo?.orderId);
            bodyFormData.append("activityType", fileType);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: bodyFormData
            });
            setErrorMessage('');
            setSuccessMessage(`Successfully upload ${fileType} file.`);
            handleSuccessMessage();
            handleCancelUpload();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(`Fail to upload ${fileType} file.`);
            handleErrorMessage();
        } finally {
            setLoadingUpload(false);
        }
    }

    const handleConfirmDelete = (file) => {
        setDeleteFile(file);
        handleDialogOpen();
    }

    const handleInputChangeFileType = (event, value) => {
        setFileType(value);
    }

    const handleChangeFileType = (event, value) => {
        setFileType(value);
    }

    const onDrop = (_type) => (e) => {
        if (!(e.target.files && e.target.files[0])) return;
        if (_type === "bol") {
            setFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
        } else {
            setContactFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
            setFileName(e.target.files[0].name);
        }
    };

    useEffect(() => {
        if (orderInfo) {
            setFileList(orderInfo?.uploadFiles);
        }
    }, [orderInfo])

    console.log('[ContainerShippingOrderDetailFile] orderInfo', orderInfo);
    console.log('[ContainerShippingOrderDetailFile] delete file', deleteFile);

    return (
        <Grid item xs={12}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Delete File
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This process will delete {deleteFile?.activity_type} file <b>{deleteFile?.origi_file_name}</b>.
                        Are you sure to delete?
                        <hr/>
                        Click <b>Confirm</b> to delete this file.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">No</Button>
                    <LoadingButton onClick={handleDeleteFile} variant="outlined" type="button" loading={loadingDelete}>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            File List
                        </Typography>
                    </Grid>
                    {
                        !isUpload &&
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleStartUpload}
                                >
                                    <Typography sx={{
                                        textTransform: 'none',
                                    }}>
                                        Upload A New File
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {
                        fileList?.length > 0 ?
                            fileList?.map((file, index) => (
                                <Grid item md={12} xs={12} key={index}>
                                    <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                        {file?.activityType}
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {
                                            file?.fileList?.map((eachFile, index) => (
                                                <Fragment key={index}>
                                                    <Grid item xs={3} sx={{display: 'flex', alignItems: 'center'}}>
                                                        <Typography sx={{textAlign: 'left'}}>
                                                            {eachFile?.origi_file_name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5} sx={{display: 'flex', alignItems: 'center'}}>
                                                        <Typography sx={{textAlign: 'left'}}>
                                                            {eachFile?.file_location_uri}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sx={{display: 'flex', alignItems: 'center'}}>
                                                        <Typography sx={{textAlign: 'left'}}>
                                                            {dayjs(eachFile?.created_at).format("YYYY-MM-DD HH:mm a")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            variant='outlined'
                                                            sx={{
                                                                border: '2px solid #1D8B45',
                                                                "&:hover": {
                                                                    border: '2px solid #1D8B45',
                                                                    filter: 'brightness(0.9)'
                                                                }
                                                            }}
                                                            size='small'
                                                            onClick={() => handleDownUploadedFile(eachFile)}
                                                        >
                                                            <Typography sx={{
                                                                textTransform: 'none',
                                                                fontSize: '14px',
                                                                color: '#1D8B45',
                                                            }}>
                                                                View
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Button
                                                            variant='outlined'
                                                            sx={{
                                                                border: '2px solid #454545',
                                                                "&:hover": {
                                                                    border: '2px solid #454545',
                                                                    filter: 'brightness(0.9)'
                                                                }
                                                            }}
                                                            size='small'
                                                            onClick={() => handleConfirmDelete(eachFile)}
                                                            // onClick={() => handleDeleteFile(eachFile)}
                                                        >
                                                            <Typography sx={{
                                                                textTransform: 'none',
                                                                fontSize: '14px',
                                                                color: '#454545',
                                                            }}>
                                                                Delete
                                                            </Typography>
                                                        </Button>
                                                    </Grid>
                                                </Fragment>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            ))
                            :
                            <Grid item xs={12}>
                                <Typography sx={{fontWeight: '600'}}>
                                    No Results
                                </Typography>
                            </Grid>

                    }
                    {
                        isUpload &&
                        <>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        File Type
                                    </FormLabel>
                                    <Autocomplete
                                        freeSolo
                                        fullWidth
                                        onInputChange={handleInputChangeFileType}
                                        onChange={handleChangeFileType}
                                        options={containerShippingFileList}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            className={classes.smallInput}
                                            error={validationResult?.fileTypeError}
                                            helperText={validationResult?.fileTypeError && 'Required'}
                                        />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Upload File
                                    </FormLabel>
                                    <TextField
                                        value={fileName}
                                        fullWidth
                                        onClick={() => {
                                            inputFile.current.click();
                                        }}
                                        inputProps={{readOnly: true}} // Make the TextField read-only
                                        size='small'
                                        error={validationResult?.fileNameError}
                                        helperText={validationResult?.fileNameError && 'Required'}
                                    />
                                </FormControl>
                                <input
                                    accept=".pdf"
                                    style={{display: "none"}}
                                    ref={inputFile}
                                    multiple
                                    type="file"
                                    onChange={onDrop("contacts")}
                                    key={""}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    height: '100%'
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '10px'
                                    }}>
                                        <LoadingButton
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                height: '100%',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                },
                                            }}
                                            onClick={handleConfirmUpload}
                                            loading={loadingUpload}
                                        >
                                            <Typography sx={{
                                                textTransform: 'none',
                                            }}>
                                                Upload
                                            </Typography>
                                        </LoadingButton>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#454545',
                                                height: '100%',
                                                "&:hover": {
                                                    backgroundColor: '#454545',
                                                    filter: 'brightness(0.9)'
                                                },
                                            }}
                                            onClick={handleCancelUpload}
                                        >
                                            <Typography sx={{
                                                textTransform: 'none',
                                            }}>
                                                Cancel
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}