import {Button, Container, FormLabel, Grid, InputAdornment, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Pagination} from "@mui/lab";
import {PackageOrderList} from "../PackageOrders/PackageOrderList";
import {PickupListTable} from "./PickupListTable";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ExportPickupListDataButton from "./ExportPickupListDataButton";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

export const PickupList = () => {

    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [status, setStatus] = useState([0, 100, -100]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [ownerRole, setOwnerRole] = useState(0);
    const [userList, setUserList] = useState([]);
    const [displayUser, setDisplayUser] = useState(null);
    const [pickupList, setPickupList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [carrier, setCarrier] = useState('All');
    const [searchString, setSearchString] = useState(null);

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const getAllEndUserList = async () => {
        const requestURL = `${PARTNER_URI}/partner/getAllUserProfileList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const getPickupList = async (data = null) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getPickupAppointmentListForAdmin`;
        let params = {
            page: 0,
            pageSize: 20,
            createDateAfter: startDate || null,
            createDateBefore: endDate || null,
            ownerRole: ownerRole === 0 ? null : ownerRole,
            partnerId: displayUser?.partner_id,
            status: status,
            accountBase: carrier === 'All' ? null : carrier,
            queryString: searchString
        }

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: params
            });

            console.log('[getPickupList] - result', result);
            setPickupList(result?.data?.result);
            setError(false);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getPickupListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getPickupAppointmentListForAdmin`;
        let params = {
            page: page,
            pageSize: 20,
            createDateAfter: startDate || null,
            createDateBefore: endDate || null,
            ownerRole: ownerRole === 0 ? null : ownerRole,
            partnerId: displayUser?.partner_id,
            status: status,
            accountBase: carrier === 'All' ? null : carrier,
            queryString: searchString
        }

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: params
            });

            console.log('[getPickupList] - result', result);
            setPickupList(result?.data?.result);
            setError(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handleStatus = event => {
        const {
            target: {value},
        } = event;
        setStatus(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const handleCarrier = event => {
        const {target: {value}} = event
        setCarrier(value);
    }

    const handleStartDate = (event) => {
        const inputDate = event.target.value;
        if (endDate) {
            if (inputDate <= endDate) {
                setStartDate(inputDate);
            } else {
                setStartDate(endDate);
            }
        } else {
            setStartDate(inputDate);
        }
    }

    const handleEndDate = (event) => {
        const inputDate = event.target.value;
        if (startDate) {

            if (inputDate >= startDate) {
                setEndDate(inputDate);
            } else {
                setEndDate(startDate);
            }
        } else {
            setEndDate(inputDate);
        }
    }

    const handleOwnerRole = (event) => {
        setOwnerRole(event?.target?.value);
        setDisplayUser(null);
    }

    const handleChangeUser = (event, value) => {
        if (value) {
            setDisplayUser(value);
        } else {
            setDisplayUser(null);
        }
    };

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value - 1;
        getPickupListByPage(page);
    };

    const handleSearch = async () => {
        await getPickupList();
    }

    const handlePackageOrders = () => {
        history.push({
            pathname: '/v2/package-orders',
            state: false
        });
    }

    const handleServiceFeeSetting = () => {
        history.push({
            pathname: '/v2/package-orders',
            state: true
        });
    }

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getPickupList();
        }
    }

    useEffect(() => {
        (
            async () => {
                await getPickupList();
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                if (ownerRole === 1) {
                    await getAllEndUserList();
                } else if (ownerRole === 3) {
                    await getAllPartnerList();
                } else {
                    setUserList([]);
                }
            }
        )()
    }, [ownerRole]);

    console.log('[PickupList] status', status);
    console.log('[PickupList] displayUser', displayUser);

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                            gap: '10px',
                            backgroundColor: '#FFFFFF',
                            padding: '20px',
                            borderRadius: '10px',
                            border: '1px solid #D1D1D1',
                        }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked={false}
                                onClick={handlePackageOrders}
                            />
                            <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                                Package Orders
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked={true}
                            />
                            <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                                Pickup List
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked={false}
                                onClick={handleServiceFeeSetting}
                            />
                            <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                                Service Fee Setting
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'center'}}>
                        Pickup List
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={styles.ShippingOrderContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography style={{textAlign: 'left'}}>
                                                Role
                                            </Typography>
                                        </FormLabel>
                                        <Select
                                            value={ownerRole}
                                            size='small'
                                            style={{textAlign: 'left', fontSize: '14px'}}
                                            onChange={handleOwnerRole}
                                        >
                                            <MenuItem value={0}>
                                                All
                                            </MenuItem>
                                            <MenuItem value={3}>
                                                Partner
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                EndUser
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography style={{textAlign: 'left'}}>
                                                {ownerRole === 1 ? 'EndUser' : ownerRole === 3 ? 'Partner' : 'All'}
                                            </Typography>
                                        </FormLabel>
                                        <Autocomplete
                                            value={displayUser}
                                            options={userList}
                                            getOptionLabel={
                                                option => ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                                    `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                            }
                                            // filterOptions={(options, state) => options}
                                            disabled={ownerRole === 0}
                                            fullWidth
                                            renderInput={(params) => <TextField
                                                {...params}
                                                className={classes.smallInput}
                                            />}
                                            // onInputChange={handleInputChangeUser}
                                            onChange={handleChangeUser}
                                            renderOption={(option) => (
                                                <Typography
                                                    style={{fontSize: '14px'}}
                                                >
                                                    {
                                                        ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                                            `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                                    }
                                                </Typography>
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography style={{textAlign: 'left'}}>
                                                Carrier
                                            </Typography>
                                        </FormLabel>
                                        <Select
                                            value={carrier}
                                            size='small'
                                            style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                                            onChange={handleCarrier}
                                        >
                                            <MenuItem value='All'>
                                                All
                                            </MenuItem>
                                            <MenuItem value='CanadaPost'>
                                                Canada Post
                                            </MenuItem>
                                            <MenuItem value='Canpar'>
                                                Canpar
                                            </MenuItem>
                                            <MenuItem value='FedEx'>
                                                FedEx
                                            </MenuItem>
                                            <MenuItem value='UPS'>
                                                UPS
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography style={{textAlign: 'left'}}>
                                                Status
                                            </Typography>
                                        </FormLabel>
                                        <Select
                                            value={status}
                                            size='small'
                                            style={{fontSize: 14, textAlign: 'left'}} // font size of input text
                                            onChange={handleStatus}
                                            multiple
                                        >
                                            <MenuItem value={0}>
                                                Initial
                                            </MenuItem>
                                            <MenuItem value={100}>
                                                Inactive
                                            </MenuItem>
                                            <MenuItem value={1}>
                                                Submitted
                                            </MenuItem>
                                            <MenuItem value={-100}>
                                                Cancel Request
                                            </MenuItem>
                                            <MenuItem value={-1}>
                                                Cancelled
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography style={{textAlign: 'left'}}>
                                                Start Date
                                            </Typography>
                                            <TextField
                                                value={startDate}
                                                fullWidth
                                                type='date'
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    style: {fontSize: '14px'}, // Adjust the font size here
                                                }}
                                                onChange={handleStartDate}
                                                size='small'
                                            />
                                        </FormLabel>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}>
                                    <FormControl fullWidth>
                                        <FormLabel>
                                            <Typography style={{textAlign: 'left'}}>
                                                End Date
                                            </Typography>
                                        </FormLabel>
                                        <TextField
                                            value={endDate}
                                            fullWidth
                                            type='date'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                style: {fontSize: '14px'}, // Adjust the font size here
                                            }}
                                            onChange={handleEndDate}
                                            size='small'
                                        />
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    value={searchString}
                                    fullWidth
                                    size='small'
                                    onInput={handleSearchString}
                                    onKeyDown={handleSearchShippingOrderByKeyDown}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Appoint Id / Tracking# / Name / Email / Address / Phone"
                                />
                            </Grid>
                            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="center" sx={{ "gap": "2rem" }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: '100%'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        size='small'
                                        onClick={handleSearch}
                                        loading={loading}
                                    >
                                        <Typography sx={{textTransform: 'none'}}>
                                            Search
                                        </Typography>
                                    </LoadingButton>
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    height: '100%'
                                }}>
                                    <ExportPickupListDataButton />
                                </Box>

                            </Grid>
                            {
                                loading ?
                                    <Grid item xs={12}>
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                            <CircularProgress/>
                                        </Box>
                                    </Grid>
                                    :
                                    error ?
                                        <Grid item xs={12}>
                                            <Box sx={styles.ShippingOrderContent}>
                                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                    Sorry We currently encounter some issues, please try again later.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        :
                                        pickupList?.length === 0 ?
                                            <Grid item xs={12}>
                                                <Box sx={styles.ShippingOrderContent}>
                                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                        No Shipping Order Found
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            :
                                            <>
                                                <Grid item xs={12}>
                                                    <PickupListTable pickupList={pickupList}
                                                                     getPickupList={getPickupList}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Pagination
                                                        count={lastPage}
                                                        page={+currentPage}
                                                        size='small'
                                                        // defaultPage={6}
                                                        onChange={handlePageChange}
                                                        // boundaryCount={2}
                                                    />
                                                </Grid>
                                            </>

                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}