import {actionType} from "../../utils/redux";

const saveAdminInfo = data => (
    {
        type: actionType.SAVE_ADMIN_INFO,
        payload: data
    }
)


export default {
    saveAdminInfo
}