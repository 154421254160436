import React, {useEffect, useState} from "react";
import {Route, useHistory} from "react-router-dom";
import {deleteToken, getAccessToken} from "../utils/doToken";
import axios from "axios";
import {deleteUserInfo, updateUserInfo} from "../slices/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {NODE_ROUTE_URI} from "../utils/apiUrl";
import LoadingContainer from "../shared/loadingContainer";
import action from "./Action";
import LandingPage from "./landingPage";
import {SignInPage} from "./LandingPage/SignInPage";

const PrivateRoute = props => {
    const [userIsLogged, setUserIsLogged] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const dispatch = useDispatch();
    const storedToken = getAccessToken("access_token");

    const adminInfo = useSelector(state => state?.adminReducer?.adminInfo);

    const getAdminInfo = async () => {
        let requestURL = `${NODE_ROUTE_URI}/admin/me`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            console.log('result - data', result?.data);
            setUserIsLogged(true);
            dispatch(action?.adminAction?.saveAdminInfo(result?.data));

        } catch (e) {
            console.log(e.response);
            deleteToken("access_token");
            history?.push("/v2");
            setUserIsLogged(false);
        } finally {
            setIsLoading(false);
        }
    }

    // if it has token
    // step 1: call me api to get admin information (verify token)
    // step 2: if success, save admin information into redux, and redux login state is set true. else, empty admin information from redux, redux login state is set false and delete the token

    // check if it has token every time
    useEffect(async () => {
        const isToken = Boolean(getAccessToken("access_token"));
        if (isToken) {
            if (adminInfo) {
                setUserIsLogged(true);
                setIsLoading(false);
            } else {
                await getAdminInfo();
            }
        } else {
            setUserIsLogged(false);
            setIsLoading(false);
        }
    });

    console.log('[private route] token', storedToken);
    console.log('[private route] admin info', adminInfo);
    console.log('[private route] user is logged', userIsLogged);

    if (isLoading) {
        return <Route {...props}><LoadingContainer/></Route>
    }
    return (
        <Route {...props}>{userIsLogged ? props.children : <SignInPage/>}</Route>
    );
};

export default PrivateRoute;