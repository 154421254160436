import {Divider} from "@mui/material";
import {Grid,  Typography} from "@mui/material";
import VendorInvoiceLineEach from "./VendorInvoiceLineEach";

export const VendorInvoiceLineList = ({
                                        invoiceLineList
}) => {

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
          Line Id
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
          Tracking #
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
          Charge (Exclude Tax)
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
          Charge (Include Tax)
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography style={{fontSize: '14px', fontWeight: '600'}}>
          Status
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider/>
      </Grid>
      {
        invoiceLineList?.map((invoiceLine, index) => {
          const {
            id: lineId,
            tracking_no: trackingNumber,
            shipping_fee_exclude_tax: shippingFeeExcludeTax,
            shipping_fee_include_tax: shippingFeeIncludeTax,
            status
          } = invoiceLine;
          return (
            <Grid item xs={12} key={index}>
              <VendorInvoiceLineEach
                lineId={lineId}
                shippingFeeExcludeTax={shippingFeeExcludeTax}
                shippingFeeIncludeTax={shippingFeeIncludeTax}
                status={status}
                trackingNumber={trackingNumber}
              />
            </Grid>
          )
        }
        )
      }
    </Grid>
  )
}