import {Button, CircularProgress, Container, Dialog, Grid, Snackbar, Typography} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import {useEffect, useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import {ClaimDashboardPackageData} from "./ClaimDashboardPackageData";
import {ClaimDashboardClaimSummary} from "./ClaimDashboardClaimSummary";
import {ClaimDashboardItemList} from "./ClaimDashboardItemList";
import {ClaimDashboardUserInfo} from "./ClaimDashboardUserInfo";
import {ClaimDashboardContactInfo} from "./ClaimDashboardContactInfo";
import {ClaimDashboardFileList} from "./ClaimDashboardFileList";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getMissingKeysFromObject, isValidatePhone} from "../../utils/Helper";
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    Root: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    Content: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    List: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const requiredProperties = ["item_desc", "unit_price", "quantity"];
const validateRequiredProperties = ["unit_price", "quantity"];
const requiredPropertiesFile = ["file_type"];

export const ClaimDashboard = () => {

    const {claimId} = useParams();

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [claimData, setClaimData] = useState(null);
    const [transactionData, setTransactionData] = useState(null)
    const [itemListData, setItemListData] = useState([]);
    const [fileListData, setFileListData] = useState([]);
    const [userInfoData, setUserInfoData] = useState(null);
    const [resetTrigger, setResetTrigger] = useState(0); // Initialize trigger
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [claimSummary, setClaimSummary] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [contactInfo, setContactInfo] = useState(null);
    const [validation, setValidation] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClaimSummary = (data) => {
        setClaimSummary(data)
    }

    const handleItemList = (data) => {
        setItemList(data)
    }

    const handleFileList = (data) => {
        setFileList(data)
    }

    const handleContactInfo = (data) => {
        setContactInfo(data)
    }

    const handleResetData = () => {
        setResetTrigger(prevTrigger => prevTrigger + 1);
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data;
        } catch (e) {
            console.log(e.response)
        }
    }

    const getPackageClaimDetailsAdmin = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/claim/getPackageClaimDetailsAdmin`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    claimId
                }
            });

            console.log('[getPackageClaimDetailsAdmin] - result', result);

            setClaimData(result?.data?.claim_data);
            setTransactionData(result?.data?.shipping_order_info?.transactions?.[0]);
            setItemListData(result?.data?.item_list);
            setFileListData(result?.data?.file_list);
            setUserInfoData(result?.data?.user_info);

            setError(false);

        } catch (e) {
            console.log(e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);

        const itemListMissingValues = itemList.some(object => requiredProperties.some((property) => !object[property]));
        const itemListValidateValues = itemList.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        const fileListMissingValues = fileList.some(object => requiredPropertiesFile.some((property) => !object[property]));

        const contactInfoMissingValues = getMissingKeysFromObject({
            fullName: contactInfo?.contactName,
            phone: contactInfo?.contactPhone,
            email: contactInfo?.contactEmail
        })

        const phoneValidate = isValidatePhone(contactInfo?.contactPhone);
        const emailValidate = await validateEmailAddress(contactInfo.contactEmail);

        setValidation({
            itemListEmpty: itemList?.length === 0,
            itemHasMissing: itemListMissingValues,
            itemListError: itemListValidateValues,
            fileListEmpty: fileList?.length === 0,
            fileHasMissing: fileListMissingValues,
            contactInfoHasMissing: contactInfoMissingValues,
            phoneValidate: phoneValidate,
            emailValidate: emailValidate

        })

        itemList?.length === 0 && setErrorMessage(prevState => prevState + ' Your item list is empty.');
        fileList?.length === 0 && setErrorMessage(prevState => prevState + ' Your file list is empty.');
        itemListMissingValues && setErrorMessage(prevState => prevState + ' Your item list has missing one or more required fields.');
        itemListValidateValues && setErrorMessage(prevState => prevState + ' Your item list has one or more invalid fields.');
        fileListMissingValues && setErrorMessage(prevState => prevState + ' Your file list has missing one or more required fields.');
        contactInfoMissingValues?.length > 0 && setErrorMessage(prevState => prevState + ' Your contact information has missing one or more required fields.');

        if (itemList?.length !== 0 &&
            !itemListMissingValues &&
            !itemListValidateValues &&
            fileList?.length !== 0 &&
            !fileListMissingValues &&
            contactInfoMissingValues?.length === 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const updateClaim = async () => {
        setLoadingUpdate(true);
        const requestURL = `${NODE_ROUTE_URI}/claim/editPackageClaimAdmin`;

        const sumValue = itemList?.reduce((acc, each) => {
            return acc + (+each?.unit_price * +each?.quantity)
        }, 0);

        let data = {
            userId: claimData?.partner_id,
            backendOrderId: claimData?.uuc_order_id,
            accountBase: claimData?.account_base,
            trackingNumber: claimData?.tracking_nos,
            claimId: claimData?.claim_id,
            claimStatus: claimSummary?.status,
            claimType: claimSummary?.type,
            claimResult: claimSummary?.result,
            caseNumber: claimSummary?.caseNumber,
            refundType: claimSummary?.refundType,
            refundAmount: claimSummary?.refundAmount || 0,
            numberOfPackage: claimData?.number_of_package,
            contactName: contactInfo?.contactName,
            contactEmail: contactInfo?.contactEmail,
            contactPhone: contactInfo?.contactPhone,
            totalItemPrice: sumValue,
            remarkNotes: claimSummary?.remarkNotes,
            itemList: itemList,
            fileList: fileList,
            sendEmail: sendEmail
        }

        try {
            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })

            setErrorMessage('');
            setSuccessMessage('Successfully Update the Claim');
            handleSuccessMessage();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to Update the Claim');
            handleErrorMessage();
        } finally {
            setLoadingUpdate(false);
            handleDialogClose();
        }
    }

    const handleUpdateData = () => {
        const validationResult = validate();
        if (validationResult) {
            handleDialogOpen();
        }
    }

    const handleBackToClaimList = () => {
        history.push('/v2/claim-list');
        window.scrollTo(0, 0); // Scroll to the top
    }

    useEffect(() => {
        if (claimId) {
            getPackageClaimDetailsAdmin();
        }
    }, [claimId])

    if (loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.Root}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Update Claim
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '50vh'
                    }}>
                        <CircularProgress/>
                    </Box>
                </Box>
            </Container>
        )
    }

    if (!loading && error) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.Root}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Update Claim
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: '50vh'
                    }}>
                        <Typography sx={{fontSize: '20px', fontWeight: 600}}>
                            Sorry We currently encounter some issues, please try again later.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        )
    }

    console.log('[ClaimDashboard] claim data', claimData);
    console.log('[ClaimDashboard] claimSummary', claimSummary);
    console.log('[ClaimDashboard] itemList', itemList);
    console.log('[ClaimDashboard] fileList', fileList);
    console.log('[ClaimDashboard] contactInfo', contactInfo);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    Update Claim
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This process will update the claim. Are you sure to update?
                        <hr/>
                        Click <b>Confirm</b> to update this claim.
                    </DialogContentText>
                    <DialogContentText>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sendEmail}
                                    onChange={(e) => setSendEmail(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label='Send update email to the user'
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button">
                        No
                    </Button>
                    <LoadingButton
                        onClick={updateClaim}
                        variant="outlined"
                        autoFocus
                        loading={loadingUpdate}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Box sx={styles.Root}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Update Claim
                </Typography>
                <Box sx={styles.Content}>
                    <ClaimDashboardUserInfo claimData={claimData} userInfoData={userInfoData}/>
                    <ClaimDashboardPackageData claimData={claimData} transactionData={transactionData}/>
                    <ClaimDashboardClaimSummary claimData={claimData}
                                                resetTrigger={resetTrigger}
                                                handleClaimSummary={handleClaimSummary}
                                                validation={validation}/>
                    <ClaimDashboardItemList itemListData={itemListData}
                                            resetTrigger={resetTrigger}
                                            handleItemList={handleItemList}
                                            validation={validation}/>
                    <ClaimDashboardFileList fileListData={fileListData}
                                            resetTrigger={resetTrigger}
                                            handleFileList={handleFileList}
                                            validation={validation}/>
                    <ClaimDashboardContactInfo claimData={claimData}
                                               resetTrigger={resetTrigger}
                                               handleContactInfo={handleContactInfo}
                                               validation={validation}/>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#454545',
                                "&:hover": {
                                    backgroundColor: '#454545',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            size='small'
                            onClick={handleBackToClaimList}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Back To Claim List
                            </Typography>
                        </Button>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '10px',
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#454545',
                                    "&:hover": {
                                        backgroundColor: '#454545',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='small'
                                onClick={handleResetData}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Reset
                                </Typography>
                            </Button>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='small'
                                onClick={handleUpdateData}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Update
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}