import {Grid, Typography} from "@mui/material";

export const ClaimDashboardUserInfo = ({claimData, userInfoData}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    User Information
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontWeight: 600, textAlign: 'left'}}>
                    User Id
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {claimData?.partner_id}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontWeight: 600, textAlign: 'left'}}>
                    Owner Role
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {claimData?.owner_role === 1 ? 'EndUser' : 'Partner'}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontWeight: 600, textAlign: 'left'}}>
                    User Name
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {userInfoData?.username}
                </Typography>
            </Grid>
        </Grid>
    )
}