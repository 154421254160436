import React, { useEffect, useRef, useState } from "react";
import { FormLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import { Box, Button } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { NODE_ROUTE_URI, PARTNER_URI } from "../../utils/apiUrl";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";

const styles = {
  ShippingOrderRoot: {
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  ShippingOrderContent: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '10px',
    // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    border: '1px solid #D1D1D1',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
}

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px"
    }
  },
  smallInput: {
    "& .MuiInputBase-input": {
      height: "2px", // Adjust the height as per your preference
      padding: "2px", // Adjust the padding as per your preference
      fontSize: "14px", // Adjust the font size as per your preference
    },
  }
});


const ExportEmails = () => {

  const excelRowHeader = [
    "Role",
    "Email"
  ]

  const csvLink = useRef();

  const [role, setRole] = useState("All");
  const [emailList, setEmailList] = useState([]);


  const handleSearchInvoice = async () => {
    try {
      const requestParams = {
        role
      }
      let requestURL = `${PARTNER_URI}/companies/getAllEmailList`;

      const { data } = await axios.get(requestURL, {
        params: requestParams
      });

      const resultArray = [
        excelRowHeader
      ];

      for (const column of data) {
        const columnData = [];
        columnData.push(column?.role);
        columnData.push(column?.email);

        resultArray.push(columnData);
      }

      setEmailList(resultArray);
      csvLink.current.link.click();
    } catch (e) {
      console.log(e);
    }
  }

  const handleSetRole = (event) => {
    event.preventDefault();
    setRole(event?.target?.value);
  }


  return (
    <>
      <Grid item xs={12}>
        <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'center'}}>
          Export Emails
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={styles.ShippingOrderContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{
                textAlign: 'left',
                fontWeight: '600'
              }}>
                Filter By
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Typography style={{textAlign: 'left'}}>
                      Carrier
                    </Typography>
                  </FormLabel>
                  <Select
                    value={role}
                    size='small'
                    style={{textAlign: 'left', fontSize: '14px'}}
                    onChange={handleSetRole}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"Partner"}>Partner</MenuItem>
                    <MenuItem value={"User"}>User</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <>
                  <Button
                    onClick={handleSearchInvoice}
                    variant="contained"
                    color="secondary"
                    style={{
                      textTransform: "unset",
                      fontSize: "1rem",
                      display: "flex",
                      padding: "0.5rem",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center"
                    }}>
                    Download
                  </Button>
                  <CSVLink
                    data={emailList}
                    filename={"Email List"}
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                </>
              </Box>

            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ExportEmails;
