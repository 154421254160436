import {Fragment, useEffect, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import {CustomTextFieldPallet} from "../../utils/customComponent";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import {getAccessToken} from "../../utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ContainerShippingOrderDetailInvoice = ({orderInfo, getContainerShippingOrderInfo}) => {

    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [invoice, setInvoice] = useState(null);
    const [isGenerateInvoice, setIsGenerateInvoice] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleInvoice = (event) => {
        setIsGenerateInvoice(event?.target?.checked);
    }

    const handleGenerateInvoice = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/generateOrUpdateOceanShippingInvoice`;

        let data = {
            backendOrderId: orderInfo?.orderId,
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });
            setErrorMessage('');
            setSuccessMessage(`Successfully generate invoice.`);
            handleSuccessMessage();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(`Fail to generate invoice.`);
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (orderInfo) {
            setInvoice(prevState => ({
                ...prevState,
                invoiceNumber: orderInfo?.crmInvoiceRefNumber,
                invoiceId: orderInfo?.crmInvoiceId
            }));
        }
    }, [orderInfo])

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                        CRM Invoice Number
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Invoice Number
                                </FormLabel>
                                <TextField
                                    value={invoice?.invoiceNumber}
                                    // onInput={handleNumber}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        {
                            ((orderInfo?.orderStatus === 1) && (+orderInfo?.finalTotal > 0) && (!orderInfo?.crmInvoiceId)) &&
                            <Grid item xs={3}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        height: '100%'
                                    }}>
                                    <FormControlLabel
                                        value="start"
                                        control={<Checkbox
                                            checked={isGenerateInvoice}
                                            onChange={handleInvoice}
                                        />}
                                        label={
                                            <Typography>
                                                Generate Invoice
                                            </Typography>
                                        }
                                        labelPlacement="end"
                                        disabled={invoice?.invoiceNumber}
                                    />
                                </Box>
                            </Grid>
                        }
                        {
                            isGenerateInvoice &&
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    height: '100%'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleGenerateInvoice}
                                        loading={loading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}