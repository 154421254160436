import {makeStyles} from "@material-ui/core/styles";
import {Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {isValid} from "date-fns";
import FormControl from "@mui/material/FormControl";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getDateFromDaysLater} from "../../utils/Helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    MovingServiceDateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    MovingServiceDateSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    MovingServiceDateHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceMovingDateErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const MovingOrderEditDate = ({orderInfo, getMovingOrderDetails, additionalData}) => {

    const accessToken = getAccessToken("access_token");

    const classes = useStyles();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [pickupLoading, setPickupLoading] = useState(false);
    const [deliverLoading, setDeliverLoading] = useState(false);

    const [originalMovingDate, setOriginalMovingDate] = useState(dayjs(new Date()));
    const [originalDeliverDate, setOriginalDeliverDate] = useState(dayjs(new Date()));
    const [originalMovingFromWareHouse, setOriginalMovingFromWareHouse] = useState({
        option: 'schedule',
        warehouse: null
    });
    const [originalMovingToWareHouse, setOriginalMovingToWareHouse] = useState({
        option: 'schedule',
        warehouse: null
    });

    const [movingDate, setMovingDate] = useState(dayjs(new Date()));
    const [movingTime, setMovingTime] = useState({
        movingTimeFrom: dayjs('2022-04-17T10:00'),
        movingTimeTo: null
    });
    const [deliverDate, setDeliverDate] = useState(dayjs(new Date()));
    const [deliverTime, setDeliverTime] = useState({
        deliverTimeFrom: null,
        deliverTimeTo: null
    });
    const [wareHouseLocation, setWareHouseLocation] = useState([]);
    const [movingFromWareHouse, setMovingFromWareHouse] = useState({
        option: 'schedule',
        warehouse: null
    });
    const [movingToWareHouse, setMovingToWareHouse] = useState({
        option: 'schedule',
        warehouse: null
    });

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getWareHouseLocation = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingExpressService/getWarehousesList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL
            })
            // console.log('[MovingServiceDate] - getWareHouseLocation - result', result?.data);
            setWareHouseLocation(result?.data);
        } catch (e) {
            console.log('[MovingServiceDate] - getWareHouseLocation - error', e?.response);
        }
    }

    const handleSelectMovingFromWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        setMovingFromWareHouse(prevState => ({
            ...prevState,
            warehouse: value,
        }));
    }

    const handleSelectMovingToWareHouse = (event, value) => {
        console.log('[handleSelectMovingFromWareHouse] wareHouseInfo', value);
        setMovingToWareHouse(prevState => ({
            ...prevState,
            warehouse: value,
        }));
    }

    const handleMovingDateChange = (date) => {
        setMovingDate(date);
    };

    const handleDeliverDateChange = (date) => {
        setDeliverDate(date);
    };

    const handleIsSelfDropOff = (event) => {
        setMovingFromWareHouse(prevState => ({
            ...prevState,
            option: event.target.checked ? 'self' : 'schedule',
            warehouse: null
        }))
    }

    const handleIsSelfPickup = (event) => {
        setMovingToWareHouse(prevState => ({
            ...prevState,
            option: event.target.checked ? 'self' : 'schedule',
            warehouse: null
        }))
    }

    const handleUpdateOrderPickup = async () => {
        setPickupLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingService/admin/editMovingOrderDetails`;

            let data = {
                backendOrderId: orderInfo?.order_id,
                // pickupWarehouseId: movingFromWareHouse?.warehouse?.id
                scheduledPickupDate: `${dayjs(movingDate).format('YYYY-MM-DD')} ${dayjs(movingTime?.movingTimeFrom).format('hh:mm')}`
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Moving Order');
            handleSuccessMessage();
            setTimeout(() => {
                getMovingOrderDetails();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Moving Order');
            handleErrorMessage();

        } finally {
            setPickupLoading(false);
        }
    }

    useEffect(() => {

        getWareHouseLocation();

        // Get the current time components
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        const currentMinute = currentTime.getMinutes();

        if ((currentHour > 15) || (currentHour === 15 && currentMinute > 0)) {
            const nextDate = getDateFromDaysLater(1);
            setMovingDate(nextDate);
        } else {
            setMovingDate(dayjs(currentTime));
        }

    }, []);

    useEffect(() => {
        if (orderInfo) {
            const pickupDate = orderInfo?.task_pickedup_at || orderInfo?.scheduled_pickup_date;
            const deliverDate = orderInfo?.task_deliver_at || orderInfo?.scheduled_deliver_date;
            setMovingDate(pickupDate);
            setOriginalMovingDate(pickupDate);
            setDeliverDate(deliverDate || pickupDate);
            setOriginalDeliverDate(pickupDate || pickupDate);
            setMovingFromWareHouse(prevState => ({
                ...prevState,
                option: orderInfo?.pickup_addr_type?.toLowerCase(),
                warehouse: orderInfo?.pickup_addr_type?.toLowerCase() === 'self' ? orderInfo?.pickup_warehouse : null
            }))
            setOriginalMovingFromWareHouse(prevState => ({
                ...prevState,
                option: orderInfo?.pickup_addr_type?.toLowerCase(),
                warehouse: orderInfo?.pickup_addr_type?.toLowerCase() === 'self' ? orderInfo?.pickup_warehouse : null
            }))
            setMovingToWareHouse(prevState => ({
                ...prevState,
                option: orderInfo?.deliver_addr_type?.toLowerCase(),
                warehouse: orderInfo?.deliver_addr_type?.toLowerCase() === 'self' ? orderInfo?.deliver_warehouse : null
            }))
            setOriginalMovingToWareHouse(prevState => ({
                ...prevState,
                option: orderInfo?.deliver_addr_type?.toLowerCase(),
                warehouse: orderInfo?.deliver_addr_type?.toLowerCase() === 'self' ? orderInfo?.deliver_warehouse : null
            }))
        }
    }, [orderInfo])

    console.log('[MovingOrderEditDate] moving date', dayjs(movingDate).format('YYYY-MM-DD'));
    console.log('[MovingOrderEditDate] original moving date', dayjs(originalMovingDate).format('YYYY-MM-DD'));
    console.log('[MovingOrderEditDate] deliver date', deliverDate);
    console.log('[MovingOrderEditDate] additionalData', additionalData);
    console.log('[MovingOrderEditDate] movingToWareHouse', movingToWareHouse);
    console.log('[MovingOrderEditDate] movingFromWareHouse', movingFromWareHouse);

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={6}>
                <Box sx={styles.MovingServiceDateRoot}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Pickup Date
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    When can your shipment be picked up?
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        // shouldDisableDate={isWeekend}
                                        value={movingDate}
                                        onChange={handleMovingDateChange}
                                        disablePast
                                        renderInput={(props) =>
                                            <TextField
                                                {...props}
                                                fullWidth
                                            />}
                                        slotProps={{
                                            textField: {
                                                helperText: !isValid(movingDate?.$d) && 'Invalid Pickup Date',
                                                size: 'small'
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={movingFromWareHouse?.option === 'self'}
                                            onChange={handleIsSelfDropOff}
                                            name="narrow street"
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                },
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography sx={{textAlign: 'left'}}>
                                            Drop off at our warehouse by yourself
                                        </Typography>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        {
                            movingFromWareHouse?.option === 'self' &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Warehouse Location
                                    </FormLabel>
                                    <Autocomplete
                                        value={movingFromWareHouse?.warehouse}
                                        fullWidth
                                        options={wareHouseLocation}
                                        getOptionLabel={(option) => (`${option?.name}, ${option?.city}, ${option?.province}, ${option?.postal_code}, ${option?.country}`)}
                                        onChange={handleSelectMovingFromWareHouse}
                                        renderInput={(params) => <TextField
                                            variant="outlined"
                                            className={classes.smallInput}
                                            {...params}
                                            // error={validationResultPlaceOrder?.validateWareHouseFromError}
                                            // helperText={validationResultPlaceOrder?.validateWareHouseFromError && 'Required'}
                                        />}
                                        renderOption={(option) => (
                                            <div>
                                                {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                            </div>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }
                        {
                            ((dayjs(movingDate).format('YYYY-MM-DD') !== dayjs(originalMovingDate).format('YYYY-MM-DD')) ||
                                JSON.stringify(movingFromWareHouse) !== JSON.stringify(originalMovingFromWareHouse)
                            ) &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleUpdateOrderPickup}
                                        loading={pickupLoading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={styles.MovingServiceDateRoot}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Delivery Date
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    When do you need your shipment delivery?
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        // shouldDisableDate={isWeekend}
                                        value={deliverDate}
                                        onChange={handleDeliverDateChange}
                                        disablePast
                                        renderInput={(props) =>
                                            <TextField
                                                {...props}
                                                fullWidth
                                            />}
                                        slotProps={{
                                            textField: {
                                                helperText: !isValid(deliverDate?.$d) && 'Invalid Pickup Date',
                                                size: 'small'
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={movingToWareHouse?.option === 'self'}
                                            onChange={handleIsSelfPickup}
                                            name="narrow street"
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                            Self Pickup at our warehouse by yourself
                                        </>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        {
                            movingToWareHouse?.option === 'self' &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Warehouse Location
                                    </FormLabel>
                                    <Autocomplete
                                        value={movingToWareHouse?.warehouse}
                                        fullWidth
                                        options={wareHouseLocation}
                                        getOptionLabel={(option) => (`${option?.name}, ${option?.city}, ${option?.province}, ${option?.postal_code}, ${option?.country}`)}
                                        onChange={handleSelectMovingToWareHouse}
                                        renderInput={(params) => <TextField
                                            variant="outlined"
                                            className={classes.smallInput}
                                            {...params}
                                            // error={validationResultPlaceOrder?.validateWareHouseToError}
                                            // helperText={validationResultPlaceOrder?.validateWareHouseToError && 'Required'}
                                        />}
                                        renderOption={(option) => (
                                            <div>
                                                {option?.name}, {option?.city}, {option?.province}, {option?.postal_code}, {option?.country}
                                            </div>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}