import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Typography,
  Grid,
  makeStyles,
  Box, Snackbar, Divider
} from "@material-ui/core";
import { CloudUpload, InsertDriveFile } from '@material-ui/icons';
import dayjs from 'dayjs';
import LoadingButton from "@mui/lab/LoadingButton";
import { FormHelperText } from "@mui/material";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { NODE_ROUTE_URI, SERVICE_SERVICE_URL } from "../../utils/apiUrl";
import EmailSupperssions from "./EmailSupperssions";

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
  },
  fileNameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  fileNameIcon: {
    marginRight: theme.spacing(1),
  },
}));

const EmailCampaignForm = () => {
  const { handleSubmit, control, watch, setValue, setError, clearErrors } = useForm();
  const isScheduled = watch('isScheduled', false);
  const classes = useStyles();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [toastOpen, setToastOpen] = useState(false);
    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastOpen(false);
    };

  const onFileChange = (event) => {
    if (event.target.files.length) {
      setUploadedFile(event.target.files[0]);
    }
  };

  const onSubmit = (data) => {
      console.log(data);
      const {
          campaignName,
          senderName,
          senderEmail,
          replyToEmail,
          emailSubject,
          templateId,
          apiKey,
          isScheduled,
          scheduledSendAt
      } = data;
      const requiredFields = ['campaignName', 'senderName', 'senderEmail', 'replyToEmail', 'emailSubject', 'templateId', 'apiKey', 'isScheduled'];
      const missingFields = requiredFields.filter((fieldName) => !data[fieldName]);

      if (missingFields.length > 0) {
          setErrorMessage(`${ missingFields[0]} is required!`);
          handleToastClick();
          return;

          // missingFields.forEach((fieldName) => {
          //     setError(fieldName, {
          //         type: 'manual',
          //         message: `${fieldName} is required`,
          //     });
          //     setErrorMessage(`${fieldName} is required!`);
          //     handleToastClick();
          // });
      }
      // Handle form submission and API request here
      if (!uploadedFile) {
          setErrorMessage(`Upload File is missing!!`);
          handleToastClick();
          return;
      }
      sendEmailByReceiptListFile({
          campaignName,
          senderName,
          senderEmail,
          replyToEmail,
          emailSubject,
          templateId,
          apiKey,
          isScheduled,
          scheduledSendAt
      });
  };

  const sendEmailByReceiptListFile = async ({
                                                campaignName,
                                                senderName,
                                                senderEmail,
                                                replyToEmail,
                                                emailSubject,
                                                templateId,
                                                isScheduled,
                                                apiKey,
                                                scheduledSendAt
                                            }) => {
      try {

          if (!uploadedFile) {
              return;
          }
          setIsLoading(true);
          setErrorMessage("");
          const formData = new FormData();
          formData.append("campaignName", campaignName);
          formData.append("senderName", senderName);
          formData.append("senderEmail", senderEmail);
          formData.append("replyToEmail", replyToEmail);
          formData.append("emailSubject", emailSubject);
          formData.append("templateId", templateId);
          formData.append("isScheduled", isScheduled === "true" ? 1 : 0);
          formData.append("apiKey", apiKey);
          formData.append("scheduledSendAt", scheduledSendAt);
          formData.append("file", uploadedFile);

          const { data } = await axios.post(`${NODE_ROUTE_URI}/emailCampaign/sendEmailByReceiptListFile`, formData, {
              headers: {
                  "Content-Type": "multipart/form-data;",
                  "X-Requested-With": "XMLHttpRequest",
              }
          })
          console.log(data);
          setSuccessMessage("Send Email Success!");
          handleToastClick();

      } catch (e) {
          console.log(e);
          setErrorMessage(`Error! ${e?.response?.data?.error}`);
          handleToastClick();
      } finally {
          setIsLoading(false);
      }
  }

  return (
    <>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={toastOpen}
            onClose={handleToastClose}
            autoHideDuration={3000}
            message="Submit Transaction">
            {(() => {
                if (errorMessage !== "") {
                    return (
                        <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                            Error!
                            <hr />
                            {errorMessage}
                        </Alert>
                    )
                }
                return (
                    <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                )
            })()}

        </Snackbar>
        <Box
            // component="form"
            // width={"1000px"}
            // margin="2rem auto"
            // sx={{
            //     display: "flex",
            //     flexDirection: "column",
            //     justifyContent: "space-evenly",
            //     gap: "2rem",
            //     alignItems: "center",
            //     alignContent: "center",
            // }}
        >
            <Box
                component="form"
                width={"1000px"}
                margin="2rem auto"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    gap: "2rem",
                    alignItems: "center",
                    alignContent: "center",
                }}
            >
                <Typography variant="h5" align="center" gutterBottom>Email Campaign</Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="campaignName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField label="Campaign Name" fullWidth {...field} />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="senderName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField label="Sender Name" fullWidth {...field} />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="senderEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField label="Sender Email" fullWidth {...field} />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="replyToEmail"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField label="Reply-To Email" fullWidth {...field} />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="emailSubject"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField label="Email Subject" fullWidth {...field} />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="templateId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField label="Template ID" fullWidth {...field} />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="apiKey"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <TextField label="API Key" fullWidth {...field} />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      accept=".csv,.xlsx"
                      id="upload-file"
                      onChange={onFileChange}
                    />
                    <label htmlFor="upload-file">
                      <Button variant="contained" color="primary" component="span" startIcon={<CloudUpload />}>
                        Upload File
                      </Button>
                    </label>
                  </FormControl>

                  {uploadedFile && (
                    <div className={classes.fileNameContainer}>
                      <InsertDriveFile className={classes.fileNameIcon} />
                      <Typography variant="body1">{uploadedFile.name}</Typography>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Is Scheduled</FormLabel>
                    <Controller
                      name="isScheduled"
                      control={control}
                      defaultValue={"false"}
                      render={({ field }) => (
                        <>
                          <RadioGroup {...field}>
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                          </RadioGroup>
                        </>
                      )}
                    />
                  </FormControl>
                </Grid>


                <Grid item xs={12}>
                  <Controller
                    name="scheduledSendAt"
                    control={control}
                    defaultValue={dayjs().format("YYYY-MM-DD HH:mm")}
                    render={({ field }) => (
                      <>
                        <TextField
                          label="Scheduled Send At"
                          type="datetime-local"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          name="scheduledSendAt"
                          {...field}
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submitButton}
                    fullWidth
                    loading={isLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit(onSubmit)(e)
                    }
                    }
                  >
                    Send Email
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>

            <hr style={{ marginBlock: "3rem" }} />

            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                gap: "2rem",
                alignItems: "center",
                alignContent: "center",
            }}>
                <Typography variant="h5" align="center" gutterBottom>Add Email Supperssions</Typography>
                <EmailSupperssions />
            </Box>

        </Box>
    </>

  );
};

export default EmailCampaignForm;