import {Button, CircularProgress, Container, Grid, Typography} from "@mui/material";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import {useEffect, useState} from "react";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import {MovingOrderDetailMovingFrom} from "./MovingOrderDetailMovingFrom";
import {MovingOrderDetailMovingTo} from "./MovingOrderDetailMovingTo";
import {MovingOrderDetailItems} from "./MovingOrderDetailItems";
import {MovingOrderDetailShipper} from "./MovingOrderDetailShipper";
import {MovingOrderDetailConsignee} from "./MovingOrderDetailConsignee";
import {MovingOrderDetailPickup} from "./MovingOrderDetailPickup";
import {MovingOrderDetailDeliver} from "./MovingOrderDetailDeliver";
import {MovingOrderDetailSummary} from "./MovingOrderDetailSummary";
import {MovingOrderDetailCost} from "./MovingOrderDetailCost";
import {MovingOrderDetailTransaction} from "./MovingOrderDetailTransaction";
import {MovingOrderDetailImage} from "./MovingOrderDetailImage";
import {MovingOrderDetailOperator} from "./MovingOrderDetailOperator";
import {MovingOrderDetailUser} from "./MovingOrderDetailUser";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingOrderDetail = () => {

    const history = useHistory();

    const accessToken = getAccessToken("access_token");

    const {orderId} = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orderInfo, setOrderInfo] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);
    const [additionalService, setAdditionalService] = useState([]);
    const [storageService, setStorageService] = useState({
        cubicMeterSpace: 0,
        storageDays: 0
    });
    const [downPayment, setDownPayment] = useState();
    const [paidPayment, setPaidPayment] = useState([]);
    const [declinedPayment, setDeclinedPayment] = useState([]);
    const [underReviewPayment, setUnderReviewPayment] = useState([]);

    const isValidJSON = (jsonString) => {
        try {
            JSON?.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0;
    }

    const getMovingOrderDetails = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/movingService/admin/getMovingOrderDashBoardDetails`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderId
                }
            })
            console.log('[getMovingOrderDetails] result', result);
            setOrderInfo(result?.data);
            setError(false);
            const downPayment = result?.data?.backendPayments?.filter(payment => payment?.remark_notes === "Down_Payment");
            const paidPayment = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "CAPTURED_SUCCEEDED" || payment?.payment_status === "UNDER_REVIEW");
            const declinedPayment = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "DECLINE");
            const underReviewPayment = result?.data?.backendPayments?.filter(payment => payment?.payment_status === "UNDER_REVIEW");
            setDownPayment(downPayment[0]);
            setPaidPayment(paidPayment);
            setDeclinedPayment(declinedPayment);
            setUnderReviewPayment(underReviewPayment);
        } catch (e) {
            console.log('error', e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => {
        history.push('/v2/moving-order-list')
    }

    const handleCancel = () => {

    }

    const handleUpdate = () => {
        history.push(`/v2/moving-order-edit/${orderId}`);
    }

    useEffect(() => {
        getMovingOrderDetails();
    }, []);

    useEffect(() => {
        if (orderInfo?.messages) {
            const jsonString = orderInfo?.messages;
            const json = JSON?.parse(jsonString);

            console.log('json', json);

            if (isValidJSON(jsonString) && !isEmptyObject(json)) {
                const jsonData = JSON?.parse(jsonString);
                setAdditionalData(jsonData);
                const additionalServicesArray = Object?.keys(jsonData?.additionalService)
                    .filter(service => jsonData?.additionalService[service] === true)
                    .map(service => {
                        const capitalizedService = service.charAt(0).toUpperCase() + service.slice(1);
                        return capitalizedService.replace(/([a-z])([A-Z])/g, '$1 $2');
                    });
                console.log('[MovingOrderDashboardTransaction] additionalServicesArray', additionalServicesArray);
                setAdditionalService(additionalServicesArray);
            }

            // For Storage Service
            if (isValidJSON(jsonString)) {
                const jsonData = JSON?.parse(jsonString);
                if (jsonData?.additionalService?.storageService) {
                    const jsonStorageService = jsonData?.additionalService?.storageService;
                    setStorageService(jsonStorageService);
                }
            }

        }
    }, [orderInfo])

    if (loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingOrderRoot}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Moving Order Details
                    </Typography>
                    <Box sx={styles.ShippingOrderContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    if (error && !loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingOrderRoot}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Moving Order Details
                    </Typography>
                    <Box sx={styles.ShippingOrderContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{fontWeight: '600'}}>
                                    Sorry We currently encounter some issues, please try again later.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Moving Order Details
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <MovingOrderDetailSummary orderInfo={orderInfo}/>
                                <MovingOrderDetailMovingFrom orderInfo={orderInfo} additionalData={additionalData}/>
                                <MovingOrderDetailMovingTo orderInfo={orderInfo} additionalData={additionalData}/>
                                <MovingOrderDetailShipper orderInfo={orderInfo}/>
                                <MovingOrderDetailConsignee orderInfo={orderInfo}/>
                                <MovingOrderDetailPickup orderInfo={orderInfo}/>
                                <MovingOrderDetailDeliver orderInfo={orderInfo}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <MovingOrderDetailUser orderInfo={orderInfo}/>
                                {
                                    (orderInfo?.task_status_id !== -1) &&
                                    <MovingOrderDetailOperator orderInfo={orderInfo}/>
                                }
                                <MovingOrderDetailCost
                                    orderInfo={orderInfo}
                                    downPayment={downPayment}
                                    paidPayment={paidPayment}/>
                                <MovingOrderDetailTransaction orderInfo={orderInfo}/>
                                <MovingOrderDetailItems orderInfo={orderInfo}/>
                                {
                                    orderInfo?.images?.length > 0 && <MovingOrderDetailImage orderInfo={orderInfo}/>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleBack}
                                    // disabled={isClone}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Back To Moving Order List
                                    </Typography>
                                </Button>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    gap: '15px'
                                }}>
                                    {/*<Button*/}
                                    {/*    variant='contained'*/}
                                    {/*    sx={{*/}
                                    {/*        backgroundColor: '#B2B2B2',*/}
                                    {/*        "&:hover": {*/}
                                    {/*            backgroundColor: '#B2B2B2',*/}
                                    {/*            filter: 'brightness(0.9)'*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    onClick={handleCancel}*/}
                                    {/*    // disabled={isClone}*/}
                                    {/*>*/}
                                    {/*    <Typography style={{*/}
                                    {/*        textTransform: 'none'*/}
                                    {/*    }}>*/}
                                    {/*        Cancel Order*/}
                                    {/*    </Typography>*/}
                                    {/*</Button>*/}
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleUpdate}
                                        // disabled={isClone}
                                    >
                                        <Typography style={{
                                            textTransform: 'none'
                                        }}>
                                            Update Order
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}