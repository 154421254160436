import {actionType} from "../../utils/redux";

const initState = {
    leftSpace: 0,
    partnerId: null
}

export const menuBarReducer = (state = initState, action) => {
    switch (action.type) {
        case actionType.NAVIGATE_BAR_OPEN_AND_CLOSE:
            return {...state, leftSpace: action.payload}
        case actionType.PARTNER_ID:
            console.log("id",action.payload)
            return {...state, partnerId: action.payload}
        default:
            return state
    }
}
