import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import UnpaidInvoices from "./UnpaidInvoices"
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export const AdminDashboardUnpaidInvoices = ({classes}) => {

    const [unpaidInvoiceList, setUnpaidInvoiceList] = useState([]);

    let url = useParams()

    const getUnpaidListData = async () => {
        let requestURI = `${PARTNER_URI}/billingCenter/operator/getNotPaidInvoiceListFromCrmForThirdParty/${url.partnerId}`;

        try {
            const {data} = await axios({
                method: "get",
                url: requestURI,
            });
            setUnpaidInvoiceList(data.invoiceList);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getUnpaidListData();
    }, []);

    return (
        <Grid item xs={6} sm={6} md={6}>
            <Card
                className={classes.paper}
                style={{minHeight: "55vh", boxShadow: "none"}}
            >
                <Typography variant="h6">{`Unpaid Invoices`}</Typography>
                <UnpaidInvoices invoices={unpaidInvoiceList}/>
            </Card>
        </Grid>
    )
}