import {Grid, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {extractFullAddress} from "../../utils/Helper";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

export const CarQuoteFrom = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const classes = useStyles();

    const [shipFrom, setShipFrom] = useState({
        city: null,
        province: null,
        postalCode: null,
        country: null
    });

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;

            return predictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromCity = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        console.log('[handleInputShipFromCity] predictions', predictions);
        setMovingFromAddressPredictions(predictions);
    }

    const handleChangeShipFromCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship from', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,

                }
            }))
        }
    }


    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({shipFrom});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Moving From
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={movingFromAddressPredictions}
                            getOptionLabel={option => option?.description}
                            onInputChange={handleInputShipFromCity}
                            onChange={handleChangeShipFromCity}
                            fullWidth
                            disabled={isConfirm}
                            PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                            renderInput={params => <TextField
                                {...params}
                                variant='outlined'
                                className={classes.smallInput}
                                // placeholder={'Moving To City'}
                                error={validation?.shipFromMissing?.includes('shipperCity')}
                                helperText={
                                    (validation?.shipFromMissing?.includes('shipperCity')) && 'Required Field'
                                }
                            />}
                            renderOption={(option) => (
                                <Typography
                                    style={{fontSize: '14px'}}
                                >
                                    {/*<Typography>*/}
                                    {option.description}
                                    {/*</Typography>*/}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>

    )
}