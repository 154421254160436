import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLOrderListSelectPartner = ({
                                              getDataFromChildComponent,
                                              triggerAction,
                                              isConfirm,
                                              validation,
                                              getPartnerInfo
                                          }) => {

    const [partnerList, setPartnerList] = useState([]);
    const [partner, setPartner] = useState('default');

    const classes = useStyles();

    const searchPartner = async (queryString) => {
        const requestURL = `${PARTNER_URI}/elasticsearch/partner/searchPartners`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: queryString,
                }
            });
            console.log('[partner list] - result', result);
            return result?.data?.result;
        } catch (e) {
            console.log(e.response);
        }
    }

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setPartnerList(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleChange = (event, value) => {
        console.log(value);
        if (value) {
            setPartner(value?.id);
            getPartnerInfo(value);
        } else {
            setPartner('default');
        }
    };

    const handleInputChange = async (event, value) => {
        console.log('value', value);
        // if (value) {
        const result = await searchPartner(value);
        setPartnerList(result);
        // }
    }

    useEffect(() => {
        getAllPartnerList();
    }, []);

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({partner});
        }
    }, [triggerAction])

    console.log('validation', validation);

    return (
        <Fragment>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <Autocomplete
                            options={partnerList}
                            getOptionLabel={option => `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`}
                            filterOptions={(options, state) => options}
                            fullWidth
                            disabled={isConfirm}
                            renderInput={(params) => <TextField
                                {...params}
                                className={classes.smallInput}
                                placeholder='Select A Partner'
                                error={validation && validation?.partnerMissing}
                                helperText={
                                    (validation && validation?.partnerMissing) && 'Required Field'
                                }
                            />}
                            onInputChange={handleInputChange}
                            onChange={handleChange}
                            renderOption={(option) => (
                                <Typography
                                    style={{fontSize: '14px'}}
                                >
                                    {/*<Typography>*/}
                                    {option?.admin_user_id} | {option?.business_name} | {option?.email}
                                    {/*</Typography>*/}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Box>
            </Grid>
        </Fragment>
    )

}