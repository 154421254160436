import {Divider, useMediaQuery} from "@mui/material";
import {useState} from "react";
import {Button, Container, Grid, InputAdornment, Typography} from "@mui/material";
import {PackageOrderEach} from "./PackageOrderEach";

export const PackageOrderList = ({order}) => {

    const isMobile = useMediaQuery('(max-width: 480px)');

    // const {firstname, id, isLoading} = useSelector((state) => state.user);

    const [openAction, setOpenAction] = useState(null);
    const [showTracking, setShowTracking] = useState(null);

    const cbHandleShowTracking = (orderId) => {
        setShowTracking(orderId)
    }


    const cbSetOpenAction = (openActionNum) => {
        setOpenAction(openActionNum);
    }

    // console.log('package order', order);

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                            Order
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                            User
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                            Client
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                            Sender
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'left'}}>
                            Recipient
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                    Status
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                    Freight
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                    Carrier
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                    Charge
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontSize: '14px', fontWeight: '600'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Grid item xs={12} key={index}>
                        <PackageOrderEach orderData={orderEach} cbHandleShowTracking={cbHandleShowTracking} showTracking={showTracking}/>
                    </Grid>
                )
            }
        </Grid>
    )
}