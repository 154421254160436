import {Divider, Grid, Typography} from "@mui/material";
import {Fragment, useState} from "react";
import {MovingCategoryAreaListEach} from "./MovingCategoryAreaListEach";
import {MovingCategoryFreightListEach} from "./MovingCategoryFreightListEach";

export const MovingCategoryFreightListTable = ({freightList, getFreightPricePolicyListByCategoryId}) => {
    console.log('[MovingCategoryFreightListTable] areaList', freightList);

    const [policyId, setPolicyId] = useState(null);

    const cbGetPolicyId = (value) => {
        setPolicyId(value);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Route / Area
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Standard Dimension
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Maximum Payload Capacity
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography style={{fontWeight: '600'}}>
                                    Over Weight Rate
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{fontWeight: '600'}}>
                                    Price Adjust Ratio
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontWeight: '600'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                freightList?.map((areaEach, index) =>
                    <Fragment key={index}>
                        <MovingCategoryFreightListEach area_info={areaEach} cbGetPolicyId={cbGetPolicyId}
                                                       policyId={policyId}
                                                       getFreightPricePolicyListByCategoryId={getFreightPricePolicyListByCategoryId}/>
                    </Fragment>
                )
            }
        </Grid>
    )
}