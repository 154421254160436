import React, { useRef, useState } from 'react';
import { Box, Button, TextField, useMediaQuery, FormControl, FormLabel, RadioGroup,FormControlLabel, Radio } from "@material-ui/core";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX, SERVICE_SERVICE_URL } from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { InputAdornment } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const UploadLabel = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");


  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      links: [{ link: "" }]
    }
  });
  const [resultURL, setResultURL] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { fields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "links"
  });

  const getData = async ({ links, fileName, dest }) => {
    try {
      const requestBody = {
        links: links.filter(v => v?.link?.trim() !== "").map(v => v.link),
        fileName,
        dest
      }
      console.log("requestBody: ", requestBody);
      const { data } = await axios.post(`${SERVICE_SERVICE_URL}/generatePDFByBase64Array/mergePNGsToPDF`, requestBody)
      console.log(data.result);
      setResultURL(data.result);
      setErrorMessage("");
    } catch (e) {
      console.log(e);
      setErrorMessage("Error! This CSV file is invalid! Either row errors or you already submit this before!");
    }
  }

  const onSubmit = data => {
    // console.log(data);
    const { links, fileName, dest } = data;
    getData({ links, fileName, dest });
  }

  return (
    <>

      <Box>
        <Box component="form" width={"1000px"} margin="0 auto" sx={{
          display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
          alignItems: "center", alignContent: "center"
        }}>
          <h1>Upload PNG</h1>
          <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }} flexDirection="column">
            <ul>
              <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" sx={{ gap: "2rem" }} width="1000px">
                {fields.map((item, index) => (
                  <li key={item.id}>
                    <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center" sx={{ gap: "2rem" }}>
                      <TextField {...register(`links.${index}.link`)} placeholder="PNG Image Link" fullWidth />
                      <button type="button" onClick={() => remove(index)}>Delete</button>
                    </Box>
                  </li>
                ))}
              </Box>
            </ul>
          </Box>
          <Button
            onClick={() => append({ link: "" })}
            type="button"
            variant="contained"
            color="secondary"
            style={{
              fontSize: "1rem",
              width: "7rem",
            }}
          >
            append
          </Button>
          <TextField id="outlined-basic" label="File Name" variant="outlined" {...register("fileName")} InputProps={{
            endAdornment: <InputAdornment position="start">.pdf</InputAdornment>,
          }} />
          <TextField id="outlined-basic" label="Destination" variant="outlined" {...register("dest")} InputProps={{
            startAdornment: <InputAdornment position="start">/uucargo-resource/shipping-label/batch/</InputAdornment>,
          }} />

          <Box mt={2} mb={4}>
            {errorMessage !== "" &&
              <Alert severity="error">
                {errorMessage}
              </Alert>}
          </Box>
          <Box>
            <Button
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem",
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Box>
        </Box>

        <br />
        <Box>
          <hr width="100%" />
          <Box>
            <Box mt={2} textAlign="center">
              {
                resultURL?.trim() !== "" ?
                  <>
                    <h1>PDF URI: <a href={resultURL} target="_blank" rel="noopener noreferrer">{resultURL}</a></h1>
                  </> :
                  <></>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UploadLabel;
