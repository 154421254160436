import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Container, FormHelperText, Grid, MenuItem, Select, Snackbar, Stack, Typography} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldNormalWithUnit} from "../../utils/customComponent";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/constInfo";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    convertCMToIN,
    convertINToCM,
    convertKGToLB,
    convertLBToKG,
    getMissingKeysShipFromInfo, getMissingKeysShipToInfo, roomPlaces
} from "../../utils/Helper";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const MovingCategoryListAdd = () => {

    const history = useHistory();

    const [isConfirm, setIsConfirm] = useState(false);
    const [validation, setValidation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [parentCategory, setParentCategory] = useState(-1);
    const [categoryName, setCategoryName] = useState('');
    const [categoryNameCN, setCategoryNameCN] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [volume, setVolume] = useState(0);
    const [weight, setWeight] = useState(0);
    const [height, setHeight] = useState(0);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [lengthUnit, setLengthUnit] = useState('in');
    const [weightUnit, setWeightUnit] = useState('lb');
    const [volumeUnit, setVolumeUnit] = useState('in\u00B3');
    const [metric, setMetric] = useState(false);
    const [isRootCategory, setIsRootCategory] = useState(false);
    const [parentCategoryList, setParentCategoryList] = useState([])
    const [roomPlace, setRoomPlace] = useState([]);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleEdit = () => {
        setIsConfirm(false);
    }

    const handleConfirm = () => {
        setIsConfirm(true);
    }

    const handleMetric = (event) => {
        setMetric(event.target.checked);
        if (event.target.checked) {
            setLengthUnit('cm');
            setWeightUnit('kg');
            setVolumeUnit('cm\u00B3')
        } else {
            setLengthUnit('in');
            setWeightUnit('lb');
            setVolumeUnit('in\u00B3')
        }
    }

    const handleParentCategory = (event) => {
        setIsRootCategory(event.target.checked);
        if (event.target.checked) {
            setParentCategory(0);
            setRoomPlace([]);
        } else {
            setParentCategory(-1);
        }
    }

    const handleChangeParentCategory = (event) => {
        setParentCategory(event.target.value);
    }

    const handleChangeRoomPlace = (event) => {
        const {target: {value}} = event;
        // console.log('[handleChangeRoomPlace] value', value);
        setRoomPlace(typeof value === 'string' ? value.split(',') : value);
    }

    const getAllParentList = async () => {
        const requestURL = `${NODE_ROUTE_URI}/movingService/searchCategories`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    page: 0,
                    pageSize: PAGE_SIZE,
                    parentCateId: 0
                }
            });
            console.log('[partner list] - result', result);
            setParentCategoryList(result?.data?.data || []);
        } catch (e) {
            console.log(e.response);
        }
    }

    const createCategory = async () => {
        setLoading(true);

        const requestURL = `${NODE_ROUTE_URI}/movingCategory/addMovingCategory`;

        let data = {
            parentCategoryId: parentCategory,
            categoryName: categoryName,
            categoryNameCN: categoryNameCN,
            categoryDescription: categoryDescription,
            weight: weight,
            height: height,
            length: length,
            width: width,
            volume: volume,
            lengthUnit: lengthUnit,
            weightUnit: weightUnit,
            roomPlace: roomPlace?.join(', ')
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('[MovingCategoryListAdd] createCategory api - result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Create Category');
            handleSuccessMessage();
            setTimeout(() => {
                history.push("/v2/moving-category-list");
            }, 2000);

        } catch (e) {
            console.log('[MovingCategoryListAdd] createCategory api - error', e?.response);
            setErrorMessage('Fail Create Category');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'categoryName':
                setCategoryName(value);
                break;
            case 'categoryNameCN':
                setCategoryNameCN(value);
                break;
            case  'categoryDescription':
                setCategoryDescription(value);
                break;
            case  'length':
                setLength(value);
                break;
            case  'width':
                setWidth(value);
                break;
            case  'height':
                setHeight(value);
                break;
            case  'weight':
                setWeight(value);
                break;
            case  'volume':
                setVolume(value);
                break;
            default:
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const validation = {
            categoryNameMissing: !categoryName,
            parentCategoryMissing: parentCategory === -1,
            lengthError: length <= 0,
            widthError: width <= 0,
            heightError: height <= 0,
            weightError: weight <= 0,
            volumeError: volume <= 0,
            roomPlaceMissing: ((parentCategory !== 0) && (roomPlace?.length === 0))
        }

        console.log('validation', validation);
        setValidation(validation);

        (!categoryName) && setErrorMessage(prevState => prevState + 'Category Name is missing.');
        (parentCategory === -1) && setErrorMessage(prevState => prevState + 'Select Category is missing.');
        ((parentCategory !== 0) && (roomPlace?.length === 0)) && setErrorMessage(prevState => prevState + 'Room Place is missing.');
        length <= 0 && setErrorMessage(prevState => prevState + 'Length is smaller or equal to 0.');
        width <= 0 && setErrorMessage(prevState => prevState + 'Width is smaller or equal to 0.');
        height <= 0 && setErrorMessage(prevState => prevState + 'Height is smaller or equal to 0.');
        weight <= 0 && setErrorMessage(prevState => prevState + 'Weight is smaller or equal to 0.');
        volume <= 0 && setErrorMessage(prevState => prevState + 'Volume is smaller or equal to 0.');

        if (length > 0 &&
            width > 0 &&
            height > 0 &&
            weight > 0 &&
            volume > 0 &&
            categoryName &&
            parentCategory !== -1 &&
            ((parentCategory === 0) || (roomPlace?.length > 0))
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreateCategory = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            createCategory();
        }
    }

    useEffect(() => {
        getAllParentList();
    }, []);

    useEffect(() => {
        if (metric) {
            setWeight(convertLBToKG(weight));
            setLength(convertINToCM(length));
            setWidth(convertINToCM(width));
            setHeight(convertINToCM(height));
        } else {
            setWeight(convertKGToLB(weight));
            setLength(convertCMToIN(length));
            setWidth(convertCMToIN(width));
            setHeight(convertCMToIN(height));
        }
    }, [metric])
    //
    // console.log('[MovingCategoryListAdd] category name', categoryName);
    // console.log('[MovingCategoryListAdd] category nameCN', categoryNameCN);
    // console.log('[MovingCategoryListAdd] category description', categoryDescription);
    // console.log('[MovingCategoryListAdd] parent category', parentCategory);
    // console.log('[MovingCategoryListAdd] category length', length);
    // console.log('[MovingCategoryListAdd] category width', width);
    // console.log('[MovingCategoryListAdd] category height', height);
    // console.log('[MovingCategoryListAdd] category weight', weight);
    // console.log('[MovingCategoryListAdd] room place', roomPlace?.join(', '));

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Create Category
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormal
                                            field='categoryName'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            error={validation?.categoryNameMissing}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Chinese Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormal
                                            field='categoryNameCN'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            // error={validation?.carModelMissing}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Description
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormal
                                            field='categoryDescription'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            // error={validation?.carModelMissing}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Select Category
                                        </Typography>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <AntSwitch
                                                checked={isRootCategory}
                                                onChange={handleParentCategory}
                                                disabled={isConfirm}
                                            />
                                            <Typography>
                                                Parent
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={parentCategory}
                                            size='small'
                                            onChange={handleChangeParentCategory}
                                            disabled={isConfirm || isRootCategory}
                                            style={{textAlign: 'left'}}
                                            error={validation && validation?.parentCategoryMissing}
                                        >
                                            <MenuItem disabled value={-1}>
                                                <em>Select Category</em>
                                            </MenuItem>
                                            {
                                                isRootCategory &&
                                                <MenuItem disabled value={0}>
                                                    Parent
                                                </MenuItem>
                                            }
                                            {
                                                parentCategoryList?.map((category, index) => (
                                                    <MenuItem key={index} value={category?.cate_id}>
                                                        {category?.category_name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <FormHelperText
                                            sx={{
                                                color: "error.main",
                                            }}
                                        >
                                            {(validation && validation?.parentCategoryMissing) && 'Required field'}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Room Place
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={roomPlace}
                                            multiple
                                            onChange={handleChangeRoomPlace}
                                            size='small'
                                            style={{textAlign: 'left'}}
                                            disabled={isConfirm || isRootCategory}
                                            error={validation && validation?.roomPlaceMissing}
                                        >
                                            {
                                                roomPlaces?.map((place) => (
                                                    <MenuItem
                                                        key={place?.roomPlace}
                                                        value={place?.roomPlace}
                                                    >
                                                        {place?.roomPlace}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                        <FormHelperText
                                            sx={{
                                                color: "error.main",
                                            }}
                                        >
                                            {(validation && validation?.roomPlaceMissing) && 'Required field'}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch
                                        checked={metric}
                                        onChange={handleMetric}
                                        disabled={isConfirm}
                                    />
                                    <Typography>
                                        Metric (cm/kg)
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Length
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormalWithUnit
                                            textValue={length}
                                            field='length'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            unit={lengthUnit}
                                            error={validation?.lengthError}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Width
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormalWithUnit
                                            textValue={width}
                                            field='width'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            unit={lengthUnit}
                                            error={validation?.widthError}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Height
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormalWithUnit
                                            textValue={height}
                                            field='height'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            unit={lengthUnit}
                                            error={validation?.heightError}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Weight
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormalWithUnit
                                            textValue={weight}
                                            field='weight'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            unit={weightUnit}
                                            error={validation?.weightError}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2.4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Volume
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <CustomTextFieldNormalWithUnit
                                            textValue={volume}
                                            field='volume'
                                            onChange={onChange}
                                            isConfirm={isConfirm}
                                            unit={volumeUnit}
                                            error={validation?.volumeError}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    variant='contained'
                                    onClick={() => history.push('/v2/moving-category-list')}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Back To Category List
                                    </Typography>
                                </Button>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    justifyContent: 'center'
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={isConfirm ? handleEdit : handleConfirm}
                                    >
                                        <Typography style={{
                                            textTransform: 'none'
                                        }}>
                                            {isConfirm ? 'Edit' : 'Confirm'}
                                        </Typography>
                                    </Button>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            }
                                        }}
                                        onClick={handleCreateCategory}
                                        disabled={!isConfirm}
                                        loading={loading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none'
                                        }}>
                                            Create
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}