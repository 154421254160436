import {
    Button,
    CircularProgress,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    Snackbar,
    Typography
} from "@mui/material";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {isValid} from "date-fns";
import FormControl from "@mui/material/FormControl";
import {LocalizationProvider, MobileDatePicker, TimePicker,} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import {MuiTelInput} from "mui-tel-input";
import Paper from "@mui/material/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {extractFullAddress, getMissingKeysForPickupInformation} from "../../utils/Helper";
import {makeStyles} from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import IconButton from "@mui/material/IconButton";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    SchedulePickupImageBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '20px',
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const SchedulePickup = ({selectTransactionData, handleScheduleClose, orderInfo}) => {

    const storedToken = getAccessToken("access_token");
    const classes = useStyles();

    const inputFile = useRef(null);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [pickupLabelError, setPickupLabelError] = useState(null);
    const [pickupLabelLoading, setPickupLabelLoading] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileObj, setFileObj] = useState(null);
    const [contactFileObj, setContactFileObj] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [pickupInformationMissing, setPickupInformationMissing] = useState(null);
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [trackingPackageDetailList, setTrackingPackageDetailList] = useState([]);
    const [pickupDetails, setPickupDetails] = useState(null);
    const [pickupInformation, setPickupInformation] = useState({
        status: 0,
        confirmationNumber: null,
        invoiceNumber: null,
        subTotal: 0,
        tax: 0,
        total: 0,
        postalCode: null,
        province: null,
        city: null,
        address: null,
        address2: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        name: "",
        phone: null,
        email: "",
        company: "",
        saveAddress: false,
        addressList: {
            name: null,
            phone: null,
            email: null,
            address_email: null,
            company_name: null,
            city: null,
            province: null,
            country: null
        },
        pickupPoint: 'Front Door',
        isResidential: true,
        pickupDate: dayjs(new Date()),
        pickupReadyTime: dayjs('2022-04-17T11:00'),
        pickupCloseTime: dayjs('2022-04-17T16:00'),
        isPickup: 1,
        remarkNotes: '',
        isFree: false,
    });
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailValidation, setEmailValidation] = useState({valid: true});
    const [pickupFee, setPickupFee] = useState(null);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getPickupFeeByAccountBaseAndRole = async () => {
        let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/admin/getPickupFeeByAccountBaseAndRole`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                params: {
                    accountBase: selectTransactionData?.account_base,
                    ownerRole: orderInfo?.owner_role
                }
            });

            setPickupFee(result?.data);
            setPickupInformation(prevState => ({
                ...prevState,
                isFree: result?.data?.isFree
            }))

        } catch (e) {
            console.log('error', e);
        }
    }

    const createSchedulePickup = async (fileURL) => {
        setPickupLabelLoading(true);
        let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/admin/initSchedulePickupAppointment`;

        const pickupDate = dayjs(pickupInformation?.pickupDate).format("YYYYMMDD");
        const pickupReadyTime = dayjs(pickupInformation?.pickupReadyTime).format('HHmm');
        const pickupCloseTime = dayjs(pickupInformation?.pickupCloseTime).format('HHmm');

        let data = {
            userId: orderInfo?.partner_id,
            role: orderInfo?.owner_role,
            status: pickupInformation?.status,
            confirmationNumber: pickupInformation?.confirmationNumber?.trim() || "",
            appointFee: pickupInformation?.subTotal,
            tax: pickupInformation?.tax,
            total: pickupInformation?.total,
            readyTime: pickupReadyTime,
            closeTime: pickupCloseTime,
            pickupDate: pickupDate,
            address: pickupInformation?.address,
            addressTwo: pickupInformation?.address2 || "",
            city: pickupInformation.city,
            province: pickupInformation?.province?.code,
            country: pickupInformation?.country,
            postalCode: pickupInformation?.postalCode?.replace(/\s*/g, ""),
            pickupPoint: pickupInformation?.pickupPoint,
            name: pickupInformation.name,
            companyName: pickupInformation.name,
            phone: pickupInformation?.phone,
            email: pickupInformation?.email,
            phoneExtension: pickupInformation?.ext || "",
            trackingNumbers: selectTransactionData?.tran_tracking_nos,
            accountBase: selectTransactionData?.account_base,
            accountNumber: selectTransactionData?.account_name,
            remarkNotes: pickupInformation?.remarkNotes,
            orderId: orderInfo?.uuc_order_id,
            isFree: pickupInformation?.isFree,
            pickupLabel: fileURL
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })

            console.log('[createSchedulePickup] result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully create a pickup.');
            handleSuccessMessage();
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (e) {
            setErrorMessage('Fail to create a pickup');
            handleErrorMessage();
        } finally {
            setPickupLabelLoading(false);
        }
    }

    const getValidTrackingListByTranId = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getValidTrackingListByTranId/${selectTransactionData?.tran_id}`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });

            console.log('[getValidTrackingListByTranId] result', result);
            const {data} = result;
            setTrackingPackageDetailList(data);
            setError(false);
        } catch (e) {
            console.log('error', e);
            setError(true)
        } finally {
            setLoading(false);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type, country) => {
        console.log('[googleMapsPlaceAutocomplete] country', country);
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocompleteFromCountry`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type,
                    country: country || 'ca'
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const isWeekend = (date) => {
        const day = date.day();
        return day === 0 || day === 6;
    };

    const getNextWeekday = () => {
        const today = new Date();
        const tomorrow = new Date(today.getTime() + 48 * 60 * 60 * 1000); // Adding two day

        console.log('tomorrow', tomorrow);

        // Skip the weekend (Saturday and Sunday)
        if (tomorrow.getDay() === 0) {
            tomorrow.setDate(tomorrow.getDate() + 2); // Adjust to Tuesday
        } else if (tomorrow.getDay() === 6) {
            tomorrow.setDate(tomorrow.getDate() + 3); // Adjust to Tuesday
        }

        console.log('tomorrow', tomorrow);

        return dayjs(tomorrow);
    };

    const handleStatus = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            status: event?.target?.value
        }))
    }

    const handleConfirmationNumber = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            confirmationNumber: event?.target?.value
        }))
    }

    const handleSubTotal = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPickupInformation(prevState => ({
                ...prevState,
                subTotal: value
            }))
        }
    }

    const handleTax = event => {
        const {value} = event.target;

        if (/^\d*\.?\d*$/.test(value)) {
            setPickupInformation(prevState => ({
                ...prevState,
                tax: value
            }))
        }
    }

    const handleDateChange = (date) => {
        // console.log('data', date);
        if (date) {
            const valid = isValid(date?.$d);
            if (valid) {
                // const dateString = date.toISOString().split('T')[0];
                setPickupInformation(prevState => ({
                    ...prevState,
                    pickupDate: date
                }))
            } else {
                setPickupInformation(prevState => ({
                    ...prevState,
                    pickupDate: date
                }))
                // const defaultData = getNextWeekday();
                // setSelectedDate(defaultData);
            }
        } else {
            const defaultData = getNextWeekday();
            setPickupInformation(prevState => ({
                ...prevState,
                pickupDate: defaultData
            }))
            // setSelectedDate(defaultData);
        }
    };

    const handleEarliestPickupTime = (newValue) => {
        if (newValue) {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupReadyTime: newValue,
            }))
            // setEarliestPickupTime(newValue);
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupReadyTime: dayjs('2022-04-17T11:00'),
            }))
            // setEarliestPickupTime(dayjs('2022-04-17T11:00'));
        }
    }

    const handleLatestPickupTime = (newValue) => {
        if (newValue) {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupCloseTime: newValue,
            }))
            // setLatestPickupTime(newValue);
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                pickupCloseTime: dayjs('2022-04-17T16:00'),
            }))
            // setLatestPickupTime(dayjs('2022-04-17T16:00'));
        }
    }

    const handleName = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            name: event?.target?.value
        }))
    }

    const handlePhone = value => {
        setPickupInformation(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    const handleEmail = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            email: event?.target?.value
        }))
    }

    const handleInputChangeAddress = async (event, value) => {
        setPickupInformation(prevState => ({
            ...prevState,
            address: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address', pickupInformation?.country);
        setAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setPickupInformation(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setPickupInformation(prevState => ({
                ...prevState,
                address: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleAddress2 = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            address2: event?.target?.value
        }))
    }

    const handleCity = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            city: event?.target?.value
        }))
    }

    const handleProvince = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            province: {
                code: event?.target?.value,
                name: event?.target?.value
            }
        }))
    }

    const handleCountry = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            postalCode: event?.target?.value
        }))
    }

    const handlePickupPoint = event => {
        setPickupInformation(prevState => ({
            ...prevState,
            pickupPoint: event?.target?.value
        }))
    }

    const handleIsResidential = event => {
        const isResidential = event?.target.value ? true : false;
        setPickupInformation(prevState => ({
            ...prevState,
            isResidential: isResidential
        }))
    }

    const handleRemarkNotes = event => {
        const value = event?.target?.value;
        if (value.length <= 30) {
            setPickupInformation(prevState => ({
                ...prevState,
                remarkNotes: value
            }));
        }
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data;
        } catch (e) {
            console.log(e.response)
        }
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);

        const pickupInformationMissing = getMissingKeysForPickupInformation({
            name: pickupInformation?.name,
            phone: pickupInformation?.phone,
            email: pickupInformation?.email,
            address: pickupInformation?.address,
            city: pickupInformation?.city,
            province: pickupInformation?.province?.code,
            country: pickupInformation?.country,
            postalCode: pickupInformation?.postalCode
        })

        const emailValid = await validateEmailAddress(pickupInformation?.email);

        setEmailValidation(prevState => ({
            ...prevState,
            valid: emailValid?.valid,
            ...emailValid
        }));
        setPickupInformationMissing(pickupInformationMissing);

        pickupInformationMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Pickup Information is missing one or more required fields.');
        !emailValid?.valid && setErrorMessage(prevState => prevState + ' Email address is invalid.');

        if (pickupInformationMissing.length === 0 &&
            emailValid?.valid
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreatePickup = async () => {
        const validationResult = await validate();
        if (validationResult) {
            handleDialogOpen();
        }
    }

    const onDrop = (_type) => (e) => {
        if (!(e.target.files && e.target.files[0])) return;
        if (_type === "bol") {
            setFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
        } else {
            setContactFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
            setFileName(e.target.files[0].name);
        }
    };

    const uploadPickupLabel = async () => {
        setPickupLabelLoading(true);
        let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/admin/uploadPickupLabel`;
        const bodyFormData = new FormData();
        const tmpFileObj = fileObj || contactFileObj;

        try {
            bodyFormData.append("files", tmpFileObj.selectedFile);
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: bodyFormData
            });
            console.log('[uploadPickupLabel] result', result);

            setPickupLabelError(prevState => ({
                ...prevState,
                errorMessage: '',
                status: false
            }));

            const url = `uucargo-resource/${result?.data?.url}`;
            const filePath = url?.replace(/\\/g, '/');

            return filePath;

        } catch (e) {
            console.log('[uploadPickupLabel] error', e.response);
            setPickupLabelError(prevState => ({
                ...prevState,
                errorMessage: 'Error to upload file',
                status: true
            }))
            return '';
        } finally {
            setPickupLabelLoading(false);
        }
    }

    const handleDelete = event => {
        event.stopPropagation(); // Prevent the click from triggering the TextField's onClick
        console.log('delete');
        setFileObj(null);
        setContactFileObj(null);
        setFileName("");
    }

    const createPickup = async () => {
        const fileURL = fileName ? await uploadPickupLabel() : '';
        await createSchedulePickup(fileURL);
    }

    useEffect(() => {
        (async () => {
                if (selectTransactionData?.tran_id) {
                    await getPickupFeeByAccountBaseAndRole();
                    await getValidTrackingListByTranId();
                    setPickupInformation(prevState => ({
                        ...prevState,
                        postalCode: selectTransactionData?.send_from_postal_code,
                        province: {
                            ...prevState.province,
                            name: selectTransactionData?.send_from_province,
                            code: selectTransactionData?.send_from_province

                        },
                        city: selectTransactionData?.send_from_city,
                        address: selectTransactionData?.send_from_addr,
                        address2: selectTransactionData?.send_from_addr_line2,
                        country: selectTransactionData?.send_from_country,
                        displayAddress: {
                            ...prevState.displayAddress,
                            description: null,
                            placeId: null,
                            text: selectTransactionData?.send_from_addr,
                        },
                        name: selectTransactionData?.send_from_name,
                        phone: selectTransactionData?.send_from_tel,
                        email: selectTransactionData?.send_from_email,
                        company: selectTransactionData?.send_from_name,
                    }))
                }
            }
        )();
    }, [selectTransactionData?.tran_id]);

    useEffect(() => {
        if (pickupFee) {
            const isSameDate = dayjs(pickupInformation?.pickupDate).isSame(dayjs(), 'day');
            console.log('isSameDate', isSameDate);
            setPickupInformation(prevState => ({
                ...prevState,
                subTotal: isSameDate ? pickupFee?.pickupSameDayCharge : pickupFee?.pickupFutureCharge,
                tax: isSameDate ? pickupFee?.pickupSameDayTax : pickupFee?.pickupFutureTax,
            }))
        }
    }, [pickupInformation?.pickupDate, pickupFee]);

    useEffect(() => {
        const total = (+pickupInformation?.subTotal || 0) + (+pickupInformation?.tax || 0);
        setPickupInformation(prevState => ({
            ...prevState,
            total: total
        }))
    }, [pickupInformation?.subTotal, pickupInformation?.tax]);

    useEffect(() => {
        const trimValue = pickupInformation?.confirmationNumber?.trim();
        setPickupInformation(prevState => ({
            ...prevState,
            status: trimValue ? 1 : 0
        }))
    }, [pickupInformation?.confirmationNumber]);

    if (loading) {
        return <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <CircularProgress/>
        </Box>
    }

    if (error && !loading) {
        return <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                Sorry We currently encounter some issues, please try again later.
            </Typography>
        </Box>
    }

    // console.log('[SchedulePickup] selectTransactionData', selectTransactionData);
    // console.log('[SchedulePickup] trackingPackageDetailList', trackingPackageDetailList);
    // console.log('[SchedulePickup] emailValidation', emailValidation);
    // console.log('[SchedulePickup] pickupFee', pickupFee);
    // console.log('[SchedulePickup] pickInformation', pickupInformation);
    console.log('[SchedulePickup] fileName', fileName);
    console.log('[SchedulePickup] fileObj', fileObj);
    console.log('[SchedulePickup] contactFileObj', contactFileObj);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    Confirm Pickup Request
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            pickupInformation?.status === 1 ?
                                'Are you sure you want to create the pickup appointment? Since you have provided a confirmation number, the email will be sent to the customer.'
                                :
                                'Are you sure you want to create the pickup appointment?'
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}>
                        <Typography sx={{color: '#424242', textTransform: 'none'}}>
                            Cancel
                        </Typography>
                    </Button>
                    <LoadingButton
                        loading={pickupLabelLoading}
                        onClick={createPickup} autoFocus>
                        <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                            Confirm
                        </Typography>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Pickup Date
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                            shouldDisableDate={isWeekend}
                            value={pickupInformation?.pickupDate}
                            onChange={handleDateChange}
                            disablePast
                            disabled={pickupDetails}
                            renderInput={(props) =>
                                <TextField
                                    {...props}
                                    fullWidth
                                    size='small'
                                />}
                            slotProps={{
                                textField: {
                                    helperText: !isValid(pickupInformation?.pickupDate?.$d) && 'Invalid Pickup Date',
                                    size: 'small'
                                },
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Earliest Pickup Time
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            onChange={handleEarliestPickupTime}
                            value={pickupInformation?.pickupReadyTime}
                            maxTime={dayjs().hour(16).minute(0).second(0)}
                            minTime={dayjs().hour(7).minute(0).second(0)}
                            disabled={pickupDetails}
                            renderInput={(props) =>
                                <TextField
                                    {...props}
                                    fullWidth
                                    size='small'
                                />}
                            slotProps={{
                                textField: {
                                    helperText: !isValid(pickupInformation?.pickupReadyTime?.$d) && 'Invalid Pickup Earliest Time',
                                    size: 'small'
                                },
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Latest Pickup Time
                    </FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            onChange={handleLatestPickupTime}
                            value={pickupInformation?.pickupCloseTime}
                            maxTime={dayjs().hour(19).minute(0).second(0)}
                            minTime={dayjs().hour(10).minute(0).second(0)}
                            disabled={pickupDetails}
                            renderInput={(props) =>
                                <TextField
                                    {...props}
                                    fullWidth
                                    size='small'
                                />}
                            slotProps={{
                                textField: {
                                    helperText: !isValid(pickupInformation?.pickupCloseTime?.$d) && 'Invalid Pickup Latest Time',
                                    size: 'small'
                                },
                            }}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Name
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.name}
                        fullWidth
                        variant='outlined'
                        onInput={handleName}
                        size='small'
                        disabled={pickupDetails}
                        error={pickupInformationMissing?.includes('name')}
                        helperText={
                            (pickupInformationMissing?.includes('name')) && 'Required'
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Phone
                    </FormLabel>
                    <MuiTelInput
                        value={pickupInformation?.phone}
                        fullWidth
                        defaultCountry="CA"
                        variant='outlined'
                        onChange={handlePhone}
                        size='small'
                        disabled={pickupDetails}
                        error={pickupInformationMissing?.includes('phone')}
                        helperText={
                            (pickupInformationMissing?.includes('phone')) && 'Required'
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Email
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.email}
                        fullWidth
                        variant='outlined'
                        onInput={handleEmail}
                        size='small'
                        disabled={pickupDetails}
                        error={pickupInformationMissing?.includes('email') || !emailValidation?.valid}
                        helperText={
                            (pickupInformationMissing?.includes('email')) ? 'Required' :
                                !emailValidation?.valid && (emailValidation?.validators?.regex?.reason || 'Invalid')
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={9} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Address
                    </FormLabel>
                    <Autocomplete
                        freeSolo
                        value={pickupInformation?.displayAddress}
                        fullWidth
                        options={addressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        disabled={pickupDetails}
                        onInputChange={handleInputChangeAddress}
                        onChange={handleChangeAddress}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                            error={pickupInformationMissing?.includes('address')}
                            helperText={
                                (pickupInformationMissing?.includes('address')) && 'Required'
                            }
                        />}
                        renderOption={option => (
                            <Typography style={{fontSize: '12px'}}>
                                {option.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address 2
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.address2}
                        fullWidth
                        variant='outlined'
                        onInput={handleAddress2}
                        size='small'
                        disabled={pickupDetails}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        City
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.city}
                        fullWidth
                        variant='outlined'
                        onInput={handleCity}
                        disabled={pickupDetails}
                        size='small'
                        error={pickupInformationMissing?.includes('city')}
                        helperText={
                            (pickupInformationMissing?.includes('city')) && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Province
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.province?.code}
                        fullWidth
                        variant='outlined'
                        onInput={handleProvince}
                        disabled={pickupDetails}
                        size='small'
                        error={pickupInformationMissing?.includes('province')}
                        helperText={
                            (pickupInformationMissing?.includes('province')) && "Required"
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Country
                    </FormLabel>
                    <Select
                        value={pickupInformation?.country}
                        size='small'
                        onChange={handleCountry}
                        disabled={pickupDetails}
                        error={pickupInformationMissing?.includes('country')}
                        style={{textAlign: 'left'}}
                    >
                        <MenuItem value='CA'>Canada</MenuItem>
                        <MenuItem value='US'>Unites States</MenuItem>
                    </Select>
                    <FormHelperText
                        sx={{
                            color: "error.main",
                        }}
                    >
                        {(pickupInformationMissing?.includes('country')) && "Required"}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Postal Code
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.postalCode}
                        fullWidth
                        variant='outlined'
                        onInput={handlePostalCode}
                        disabled={pickupDetails}
                        size='small'
                        error={pickupInformationMissing?.includes('postalCode')}
                        helperText={
                            (pickupInformationMissing?.includes('postalCode')) && 'Required'
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}} required>
                        Pickup Location
                    </FormLabel>
                    <Select
                        value={pickupInformation?.pickupPoint}
                        onChange={handlePickupPoint}
                        disabled={pickupDetails}
                        size='small'
                        style={{textAlign: 'left'}}
                    >
                        <MenuItem value={"Front Door"}>Front Door</MenuItem>
                        <MenuItem value={"Rear Door"}>Rear Door</MenuItem>
                        <MenuItem value={"Side Door"}>Side Door</MenuItem>
                        <MenuItem value={"None"}>None</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={9}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Remark Notes
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.remarkNotes}
                        fullWidth
                        variant='outlined'
                        onInput={handleRemarkNotes}
                        disabled={pickupDetails}
                        size='small'
                    />
                    <FormHelperText sx={{textAlign: 'right'}}>
                        30 characters maximum
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Confirmation Number
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.confirmationNumber}
                        fullWidth
                        variant='outlined'
                        onInput={handleConfirmationNumber}
                        size='small'
                        disabled={pickupDetails}
                    />
                </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Pickup Label Upload
                    </FormLabel>
                    <TextField
                        value={fileName}
                        fullWidth
                        onClick={() => {
                            inputFile.current.click();
                        }}
                        inputProps={{readOnly: true}} // Make the TextField read-only
                        size='small'
                        InputProps={{
                            endAdornment:
                                <IconButton
                                    edge="end"
                                    sx={{
                                        position: 'absolute',
                                        top: 0, // Align the icon to the top
                                        right: 0, // Align the icon to the right
                                        transform: 'translate(0, -50%)', // Adjust vertical alignment
                                    }}
                                    onClick={handleDelete}
                                >
                                    <CancelOutlinedIcon/>
                                </IconButton>
                        }}
                    />
                </FormControl>
                <input
                    accept=".pdf"
                    style={{display: "none"}}
                    ref={inputFile}
                    // multiple
                    type="file"
                    onChange={onDrop("contacts")}
                    key={""}
                />
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Is Free Service
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.isFree ? 'True' : 'False'}
                        fullWidth
                        variant='outlined'
                        size='small'
                        disabled={true}
                    />
                </FormControl>
            </Grid>
            {/*{*/
            }
            {/*    !pickupInformation?.isFree &&*/
            }
            {/*    <>*/
            }
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Appointment Fee
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.subTotal}
                        fullWidth
                        variant='outlined'
                        onInput={handleSubTotal}
                        size='small'
                        disabled={pickupInformation?.isFree}
                        type='number'
                    />
                </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Tax
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.tax}
                        fullWidth
                        variant='outlined'
                        onInput={handleTax}
                        size='small'
                        disabled={pickupInformation?.isFree}
                        type='number'
                    />
                </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Total
                    </FormLabel>
                    <TextField
                        value={pickupInformation?.total}
                        fullWidth
                        variant='outlined'
                        size='small'
                        disabled={true}
                    />
                </FormControl>
            </Grid>
            {/*    </>*/
            }
            {/*}*/
            }
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#424242',
                            "&:hover": {
                                backgroundColor: '#424242',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleScheduleClose}
                    >
                        <Typography sx={{textTransform: 'none'}}>
                            Cancel
                        </Typography>
                    </Button>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleCreatePickup}
                    >
                        <Typography sx={{textTransform: 'none'}}>
                            Create
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}