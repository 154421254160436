import {Fragment, useEffect, useState} from "react";
import {FormLabel, Grid, MenuItem, Select, Typography} from "@mui/material";
import {calculateTaxes} from "../../utils/Helper";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNumber} from "../../utils/customComponent";
import TextField from "@mui/material/TextField";

export const CarQuoteCost = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [extraLocalPickupCharge, setExtraLocalPickupCharge] = useState(0);
    const [extraLocalDeliverCharge, setExtraLocalDeliverCharge] = useState(0);
    const [fuelCharge, setFuelCharge] = useState(0);
    const [tariffCharge, setTariffCharge] = useState(0);
    const [crossBorderCharge, setCrossBorderCharge] = useState(0);
    const [insuranceCharge, setInsuranceCharge] = useState(0);
    const [taxCharge, setTaxCharge] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [taxes, setTaxes] = useState([]);
    const [selectTax, setSelectTax] = useState(null);


    const onChange = (field, value) => {
        switch (field) {
            case 'fuelCharge':
                setFuelCharge(+value);
                break;
            case 'tariffCharge':
                setTariffCharge(+value);
                break;
            case 'taxCharge':
                setTaxCharge(+value);
                break;
            case 'crossBorderCharge':
                setCrossBorderCharge(+value);
                break;
            case 'insuranceCharge':
                setInsuranceCharge(+value);
                break;
            case 'extraLocalPickupCharge':
                setExtraLocalPickupCharge(+value);
                break;
            case 'extraLocalDeliverCharge':
                setExtraLocalDeliverCharge(+value);
                break;
            default:
        }
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setTaxCharge(+event.target.value.amount);
        setSelectTax(event.target.value);
    };

    useEffect(() => {
        setTaxCharge(0);
        setSelectTax(null);
    }, [taxes]);

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                shippingCost: {
                    fuelCharge,
                    tariffCharge,
                    insuranceCharge,
                    crossBorderCharge,
                    taxCharge,
                    extraLocalPickupCharge,
                    extraLocalDeliverCharge
                }
            });
        }
    }, [triggerAction]);

    useEffect(() => {
        const subtotalCost = +fuelCharge + +tariffCharge + +insuranceCharge + +extraLocalPickupCharge + +extraLocalDeliverCharge;
        setSubTotal(subtotalCost.toFixed(2));
    }, [fuelCharge, tariffCharge, insuranceCharge, extraLocalPickupCharge, extraLocalDeliverCharge]);

    useEffect(() => {
        const taxes = calculateTaxes(subTotal);
        setTaxes(taxes);
    }, [subTotal]);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Cost Information
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth required>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Tariff Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='tariffCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                    error={validation?.tariffChargeError}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Fuel Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='fuelCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Insurance Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='insuranceCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Extra Local Pickup Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='extraLocalPickupCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Extra Local Deliver Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='extraLocalDeliverCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Subtotal
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    value={subTotal}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Tax Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Select
                                    value={taxCharge}
                                    size='small'
                                    onChange={handleChange}
                                    renderValue={() => selectTax?.amount || 0}
                                    disabled={isConfirm}
                                    style={{textAlign: 'left'}}
                                >
                                    {
                                        taxes?.map((tax, index) => (
                                            <MenuItem key={index} value={tax}>
                                                {tax?.percent} | {tax?.amount}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                {/*<CustomTextFieldNumber*/}
                                {/*    field='taxCharge'*/}
                                {/*    onChange={onChange}*/}
                                {/*    isConfirm={isConfirm}*/}
                                {/*/>*/}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Cross Border Charge
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <CustomTextFieldNumber
                                    field='crossBorderCharge'
                                    onChange={onChange}
                                    isConfirm={isConfirm}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Final Total
                                </FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    fullWidth
                                    value={+tariffCharge + +fuelCharge + +taxCharge + +insuranceCharge + +crossBorderCharge + +extraLocalPickupCharge + +extraLocalDeliverCharge}
                                    size='small'
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}