import React from "react";
import { Box, makeStyles, TextField } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "10px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "3rem",
  }
}));

export default function TransactionUnTrackingInvoicesTable({ invoiceId, invoiceNumber, trackingNumber, index, setUnTrackingInvoiceList, grossAmount, carrier }) {
  const classes = useStyles();
  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>{invoiceId}</td>
        <td style={{ width: "200px" }}>{invoiceNumber}</td>
        <td>{carrier}</td>
        <td>{grossAmount}</td>
        <td style={{ width: "300px" }}>{trackingNumber}</td>
        <td>
          <Box width="300px">
            <TextField id="outlined-basic" label="" variant="outlined" fullWidth onChange={(e) => {
              setUnTrackingInvoiceList(v => {
                const untrackingInvoiceListCopy = [...v];
                untrackingInvoiceListCopy[index]["newTrackingNumber"] = e.target.value;
                return untrackingInvoiceListCopy
              })
            }}  />
          </Box>
        </td>
      </tr>
    </>
  );
}
