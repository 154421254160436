import {FormGroup, FormHelperText, FormLabel, Grid, MenuItem, Select, Tooltip, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {extractFullAddress, provinces_states} from "../../utils/Helper";
import IconButton from "@mui/material/IconButton";
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

const DISTANCE_FROM_TRUCK_TO_DOOR_OPTIONS = ["0 - 50m", "50 - 100m", ">100m"];
const FLOOR_LEVEL_OPTIONS = [
    '1',
    '2',
    '3',
];

export const house_type = [
    {propertyType: 'Residential', houseType: 'House'},
    {propertyType: 'Residential', houseType: 'Townhouse'},
    {propertyType: 'Residential', houseType: 'Apartment'},
    {propertyType: 'Residential', houseType: 'Curb Side'},
]

export const MovingOrderLocation = ({
                                        cbHandleGetMovingFrom,
                                        cbHandleGetMovingTo,
                                        validationResult,
                                        isContinuePlaceOrder
                                    }) => {

    const classes = useStyles();

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);

    const [movingFrom, setMovingFrom] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: null,
        floorLevel: '1',
        lift: 0,
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'schedule',
        warehouse: null,
        distanceFromTruckToDoor: "0 - 50m",
        // staircase: "Do not include the basement of your residence",
        staircaseNumber: 0,
        isNarrowStreet: false,
        isSteepDriveway: false,
        drivewaySteepLevel: 0
    });

    const [movingTo, setMovingTo] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        province: null,
        postalCode: null,
        country: null,
        propertyType: null,
        floorLevel: '1',
        lift: 0,
        storey: '1',
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        },
        option: 'schedule',
        warehouse: null,
        distanceFromTruckToDoor: "0 - 50m",
        // staircase: "Do not include the basement of your residence",
        staircaseNumber: 0,
        isNarrowStreet: false,
        isSteepDriveway: false,
        drivewaySteepLevel: 0
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeMovingFromAddress = async (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingFromAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingFromAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleMovingFromApt = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleMovingFromCity = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleMovingFromProvince = (event, value) => {
        console.log(value);
        if (value) {
            setMovingFrom(prevState => ({
                ...prevState,
                province: {
                    ...prevState.province,
                    code: value?.code,
                    name: value?.name
                },
                country: value?.countryCode
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                province: null
            }))
        }
    }

    const handleMovingFromCountry = event => {
        setMovingFrom(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleMovingFromPostalCode = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    const handleMovingFromPropertyType = (event, value) => {
        if (value?.houseType === 'House' || value?.houseType === 'Townhouse') {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0
            }))
        } else {
            setMovingFrom(prevState => ({
                ...prevState,
                propertyType: value,
            }))
        }
    }

    const handleMovingFromFloorLevel = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, floorLevel: value
        }))
    }

    const handleMovingFromLift = (event) => {
        setMovingFrom(prevState => ({
            ...prevState, lift: event?.target.value
        }))
    }

    const handleMovingDistanceFromTruckToDoor = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, distanceFromTruckToDoor: value
        }))
    }

    const increaseMovingFromStaircase = () => {
        const currentStairCase = movingFrom.staircaseNumber;
        setMovingFrom(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase + 1
        }))
    }

    const decreaseMovingFromStaircase = () => {
        const currentStairCase = movingFrom.staircaseNumber;
        if (currentStairCase <= 0) {
            return;
        }
        setMovingFrom(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase - 1
        }))
    }

    const handleMovingFromIsNarrowStreet = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, isNarrowStreet: value
        }))
    }

    const handleMovingFromIsSteepDriveway = (event, value) => {
        setMovingFrom(prevState => ({
            ...prevState, isSteepDriveway: value
        }))
    }

    const handleInputChangeMovingToAddress = async (event, value) => {
        setMovingTo(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setMovingToAddressPredictions(predictionsAddress);
    }

    const handleChangeMovingToAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setMovingTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleMovingToApt = (event) => {
        setMovingTo(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleMovingToCity = (event) => {
        setMovingTo(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleMovingToProvince = (event, value) => {
        if (value) {
            setMovingTo(prevState => ({
                ...prevState,
                province: {
                    ...prevState.province,
                    code: value?.code,
                    name: value?.name
                },
                country: value?.countryCode
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                province: null
            }))
        }
    }

    const handleMovingToCountry = event => {
        setMovingTo(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handleMovingToPostalCode = (event) => {
        setMovingTo(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    const handleMovingToPropertyType = (event, value) => {
        if (value?.houseType === 'House' || value?.houseType === 'Townhouse') {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
                lift: 0
            }))
        } else {
            setMovingTo(prevState => ({
                ...prevState,
                propertyType: value,
            }))
        }
    }

    const handleMovingToFloorLevel = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, floorLevel: value
        }))
    }

    const handleMovingToLift = (event) => {
        setMovingTo(prevState => ({
            ...prevState, lift: event.target.value
        }))
    }

    const handleMovingToDistanceFromTruckToDoor = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, distanceFromTruckToDoor: value
        }))
    }

    const increaseMovingToStaircase = () => {
        const currentStairCase = movingFrom.staircaseNumber;
        setMovingFrom(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase + 1
        }))
    }

    const decreaseMovingToStaircase = () => {
        const currentStairCase = movingFrom.staircaseNumber;
        if (currentStairCase <= 0) {
            return;
        }
        setMovingFrom(prevState => ({
            ...prevState,
            staircaseNumber: currentStairCase - 1
        }))
    }

    const handleMovingToIsNarrowStreet = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, isNarrowStreet: value
        }))
    }

    const handleMovingToIsSteepDriveway = (event, value) => {
        setMovingTo(prevState => ({
            ...prevState, isSteepDriveway: value
        }))
    }

    useEffect(() => {
        cbHandleGetMovingFrom(movingFrom);
    }, [movingFrom]);

    useEffect(() => {
        cbHandleGetMovingTo(movingTo);
    }, [movingTo]);

    return (
        <>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Ship From
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Address
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={movingFrom?.displayAddress}
                                    disabled={isContinuePlaceOrder}
                                    fullWidth
                                    options={movingFromAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeMovingFromAddress}
                                    onChange={handleChangeMovingFromAddress}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        error={validationResult?.validateMovingFromMissing?.includes('streetAddress')}
                                        helperText={validationResult?.validateMovingFromMissing?.includes('streetAddress') && 'Required'}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Apt / Suite
                                </FormLabel>
                                <TextField
                                    value={movingFrom.apt}
                                    onInput={handleMovingFromApt}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    disabled={isContinuePlaceOrder}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    City
                                </FormLabel>
                                <TextField
                                    value={movingFrom.city}
                                    onInput={handleMovingFromCity}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    disabled={isContinuePlaceOrder}
                                    error={validationResult?.validateMovingFromMissing?.includes('city')}
                                    helperText={validationResult?.validateMovingFromMissing?.includes('city') && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Province
                                </FormLabel>
                                <Autocomplete
                                    value={movingFrom.province}
                                    groupBy={(option) => option.country}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    options={provinces_states}
                                    getOptionLabel={(option) => option.code}
                                    onChange={handleMovingFromProvince}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validationResult?.validateMovingFromMissing?.includes('province')}
                                        helperText={validationResult?.validateMovingFromMissing?.includes('province') && 'Required'}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.name}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Country
                                </FormLabel>
                                <FormControl fullWidth>
                                    <Select
                                        value={movingFrom?.country}
                                        size='small'
                                        disabled={isContinuePlaceOrder}
                                        onChange={handleMovingFromCountry}
                                        style={{textAlign: 'left'}}
                                        error={validationResult?.validateMovingFromMissing?.includes('country')}
                                    >
                                        <MenuItem value='CA'>Canada</MenuItem>
                                        <MenuItem value='US'>Unites States</MenuItem>
                                    </Select>
                                    <FormHelperText
                                        sx={{
                                            color: "error.main",
                                        }}
                                    >
                                        {validationResult?.validateMovingFromMissing?.includes('country') && 'Required'}
                                    </FormHelperText>
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Postal Code
                                </FormLabel>
                                <TextField
                                    value={movingFrom.postalCode}
                                    onInput={handleMovingFromPostalCode}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    error={validationResult?.validateMovingFromMissing?.includes('postalCode') || validationResult?.movingFromPostalCodeError}
                                    helperText={validationResult?.validateMovingFromMissing?.includes('postalCode') ? 'Required' : validationResult?.movingToPostalCodeError && "Invalid"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    House Type
                                </FormLabel>
                                <Autocomplete
                                    value={movingFrom.propertyType}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    groupBy={(option) => option.propertyType}
                                    options={house_type}
                                    getOptionLabel={(option) => option.houseType}
                                    onChange={handleMovingFromPropertyType}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validationResult?.validateMovingFromMissing?.includes('propertyType')}
                                        helperText={validationResult?.validateMovingFromMissing?.includes('propertyType') && 'Required'}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.houseType}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Has Lift
                                </FormLabel>
                                <Select
                                    value={movingFrom.lift}
                                    size='small'
                                    // placeholder='Select Partner'
                                    onChange={handleMovingFromLift}
                                    style={{textAlign: 'left'}}
                                    // error={validation && validation?.carrierMissing}
                                    disabled={movingFrom?.propertyType?.houseType !== 'Condo' || isContinuePlaceOrder}
                                >
                                    <MenuItem value={0}>
                                        No
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Yes
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Floor Level
                                </FormLabel>
                                <Autocomplete
                                    value={movingFrom.floorLevel}
                                    fullWidth
                                    options={FLOOR_LEVEL_OPTIONS}
                                    onChange={handleMovingFromFloorLevel}
                                    disabled={movingFrom?.lift || isContinuePlaceOrder}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validationResult?.validateMovingFromMissing?.includes('floorLevel')}
                                        helperText={validationResult?.validateMovingFromMissing?.includes('floorLevel') && 'Required'}
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        {/*<Grid item xs={12} md={12}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel required sx={{textAlign: 'left'}}>*/}
                        {/*            How far is the distance from the truck to the door?*/}
                        {/*        </FormLabel>*/}
                        {/*        <Autocomplete*/}
                        {/*            value={movingFrom.distanceFromTruckToDoor}*/}
                        {/*            fullWidth*/}
                        {/*            disabled={isContinuePlaceOrder}*/}
                        {/*            options={DISTANCE_FROM_TRUCK_TO_DOOR_OPTIONS}*/}
                        {/*            onChange={handleMovingDistanceFromTruckToDoor}*/}
                        {/*            onInputChange={handleMovingDistanceFromTruckToDoor}*/}
                        {/*            renderInput={(params) => <TextField*/}
                        {/*                variant="outlined"*/}
                        {/*                className={classes.smallInput}*/}
                        {/*                {...params}*/}
                        {/*                error={validationResult?.validateMovingFromMissing?.includes('distanceFromTruckToDoor')}*/}
                        {/*                helperText={validationResult?.validateMovingFromMissing?.includes('distanceFromTruckToDoor') && 'Required'}*/}
                        {/*            />}*/}
                        {/*        />*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={12}>*/}
                        {/*    <FormControl fullWidth style={{*/}
                        {/*        display: "flex",*/}
                        {/*        flexDirection: "row",*/}
                        {/*        justifyContent: "space-between",*/}
                        {/*        gap: "3px"*/}
                        {/*    }}>*/}
                        {/*        <FormLabel>*/}
                        {/*            How many staircase are there?*/}
                        {/*            <Tooltip*/}
                        {/*                placement="top"*/}
                        {/*                arrow*/}
                        {/*                componentsProps={{*/}
                        {/*                    tooltip: {*/}
                        {/*                        sx: {*/}
                        {/*                            bgcolor: 'white',*/}
                        {/*                            color: 'black',*/}
                        {/*                            boxShadow: 1,*/}
                        {/*                            border: '1px solid #ddd',*/}
                        {/*                            borderRadius: '8px', // Added border radius*/}
                        {/*                        },*/}
                        {/*                    },*/}
                        {/*                    arrow: {*/}
                        {/*                        sx: {*/}
                        {/*                            color: 'white',*/}
                        {/*                        },*/}
                        {/*                    },*/}
                        {/*                }}*/}
                        {/*                title={*/}
                        {/*                    <Box sx={{backgroundColor: "#ffffff", p: 1}}>*/}
                        {/*                        <ul style={{*/}
                        {/*                            paddingBlock: "1.5px",*/}
                        {/*                            margin: 0,*/}
                        {/*                            paddingInline: "3px"*/}
                        {/*                        }}>*/}
                        {/*                            <li>*/}
                        {/*                                A staircase consists of 3 or more steps.*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                A staircase connects 2 stories together.*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                It is a single staircase even if it is*/}
                        {/*                                interrupted by a*/}
                        {/*                                landing.*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                Do not include the basement of your*/}
                        {/*                                residence.*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                    </Box>*/}
                        {/*                }>*/}
                        {/*                <IconButton>*/}
                        {/*                    <FmdBadOutlinedIcon/>*/}
                        {/*                </IconButton>*/}
                        {/*            </Tooltip>*/}
                        {/*        </FormLabel>*/}
                        {/*        <Box sx={{*/}
                        {/*            display: 'flex',*/}
                        {/*            alignItems: 'center',*/}
                        {/*            border: '1px solid #ddd',*/}
                        {/*            borderRadius: '10px',*/}
                        {/*            padding: "7px"*/}
                        {/*        }}>*/}
                        {/*            <IconButton size="small"*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                        onClick={decreaseMovingFromStaircase}><RemoveCircleOutlineIcon/></IconButton>*/}
                        {/*            <Typography variant="body1"*/}
                        {/*                        mx={1}>{movingFrom.staircaseNumber}</Typography>*/}
                        {/*            <IconButton size="small"*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                        onClick={increaseMovingFromStaircase}><AddCircleOutlineIcon/></IconButton>*/}
                        {/*        </Box>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={12}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel required sx={{textAlign: 'left'}}>*/}
                        {/*            Restrictions at collection*/}
                        {/*        </FormLabel>*/}
                        {/*        <FormGroup>*/}
                        {/*            <FormControlLabel*/}
                        {/*                control={*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={movingFrom.isNarrowStreet}*/}
                        {/*                        onChange={handleMovingFromIsNarrowStreet}*/}
                        {/*                        name="narrow street"*/}
                        {/*                        style={{*/}
                        {/*                            color: 'green',*/}
                        {/*                            '&.Mui-checked': {*/}
                        {/*                                color: 'green',*/}
                        {/*                            }*/}
                        {/*                        }}*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*                label={*/}
                        {/*                    <>*/}
                        {/*                        Narrow Street:&nbsp;*/}
                        {/*                        <span style={{fontSize: "12px"}}>Tight, Space, Difficult for Trucks or Movers.*/}
                        {/*                                </span>*/}
                        {/*                    </>*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*            <FormControlLabel*/}
                        {/*                control={*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={movingFrom.isSteepDriveway}*/}
                        {/*                        onChange={handleMovingFromIsSteepDriveway}*/}
                        {/*                        name="steep driveway"*/}
                        {/*                        style={{*/}
                        {/*                            color: 'green',*/}
                        {/*                            '&.Mui-checked': {*/}
                        {/*                                color: 'green',*/}
                        {/*                            }*/}
                        {/*                        }}*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*                label={*/}
                        {/*                    <>*/}
                        {/*                        Steep Driveway:&nbsp;*/}
                        {/*                        <span style={{fontSize: "12px"}}>Trucks & Movers may struggle with slope.*/}
                        {/*                </span>*/}
                        {/*                    </>*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        </FormGroup>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Ship To
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Address
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={movingTo?.displayAddress}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    options={movingToAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeMovingToAddress}
                                    onChange={handleChangeMovingToAddress}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                        error={validationResult?.validateMovingToMissing?.includes('streetAddress')}
                                        helperText={validationResult?.validateMovingToMissing?.includes('streetAddress') && 'Required'}
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Apt / Suit
                                </FormLabel>
                                <TextField
                                    value={movingTo.apt}
                                    onInput={handleMovingToApt}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    variant="outlined"
                                    size="small"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    City
                                </FormLabel>
                                <TextField
                                    value={movingTo.city}
                                    onInput={handleMovingToCity}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size="small"
                                    error={validationResult?.validateMovingToMissing?.includes('city')}
                                    helperText={validationResult?.validateMovingToMissing?.includes('city') && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Province
                                </FormLabel>
                                <Autocomplete
                                    value={movingTo.province}
                                    groupBy={(option) => option.country}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    options={provinces_states}
                                    getOptionLabel={(option) => option.code}
                                    onChange={handleMovingToProvince}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validationResult?.validateMovingToMissing?.includes('province')}
                                        helperText={validationResult?.validateMovingToMissing?.includes('province') && 'Required'}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.name}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Country
                                </FormLabel>
                                <FormControl fullWidth>
                                    <Select
                                        value={movingTo?.country}
                                        size='small'
                                        disabled={isContinuePlaceOrder}
                                        style={{textAlign: 'left'}}
                                        onChange={handleMovingToCountry}
                                        error={validationResult?.validateMovingToMissing?.includes('country')}
                                    >
                                        <MenuItem value='CA'>Canada</MenuItem>
                                        <MenuItem value='US'>Unites States</MenuItem>
                                    </Select>
                                    <FormHelperText
                                        sx={{
                                            color: "error.main",
                                        }}
                                    >
                                        {validationResult?.validateMovingToMissing?.includes('country') && 'Required'}
                                    </FormHelperText>
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Postal Code
                                </FormLabel>
                                <TextField
                                    value={movingTo.postalCode}
                                    onInput={handleMovingToPostalCode}
                                    disabled={isContinuePlaceOrder}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                    error={validationResult?.validateMovingToMissing?.includes('postalCode') || validationResult?.movingToPostalCodeError}
                                    helperText={validationResult?.validateMovingToMissing?.includes('postalCode') ? 'Required' : validationResult?.movingToPostalCodeError && "Invalid"}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    House Type
                                </FormLabel>
                                <Autocomplete
                                    value={movingTo.propertyType}
                                    fullWidth
                                    disabled={isContinuePlaceOrder}
                                    groupBy={(option) => option.propertyType}
                                    options={house_type}
                                    getOptionLabel={(option) => option.houseType}
                                    onChange={handleMovingToPropertyType}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validationResult?.validateMovingToMissing?.includes('propertyType')}
                                        helperText={validationResult?.validateMovingToMissing?.includes('propertyType') && 'Required'}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.houseType}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Has Lift
                                </FormLabel>
                                <Select
                                    value={movingTo.lift}
                                    size='small'
                                    // placeholder='Select Partner'
                                    onChange={handleMovingToLift}
                                    style={{textAlign: 'left'}}
                                    disabled={movingTo?.propertyType?.houseType !== 'Condo' || isContinuePlaceOrder}
                                    // error={validation && validation?.carrierMissing}
                                >
                                    <MenuItem value={0}>
                                        No
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Yes
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Floor Level
                                </FormLabel>
                                <Autocomplete
                                    value={movingTo.floorLevel}
                                    fullWidth
                                    options={FLOOR_LEVEL_OPTIONS}
                                    onChange={handleMovingToFloorLevel}
                                    disabled={movingTo?.lift || isContinuePlaceOrder}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validationResult?.validateMovingToMissing?.includes('floorLevel')}
                                        helperText={validationResult?.validateMovingToMissing?.includes('floorLevel') && 'Required'}
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        {/*<Grid item xs={12} md={12}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel required sx={{textAlign: 'left'}}>*/}
                        {/*            How far is the distance from the truck to the door?*/}
                        {/*        </FormLabel>*/}
                        {/*        <Autocomplete*/}
                        {/*            value={movingTo.distanceFromTruckToDoor}*/}
                        {/*            fullWidth*/}
                        {/*            disabled={isContinuePlaceOrder}*/}
                        {/*            options={DISTANCE_FROM_TRUCK_TO_DOOR_OPTIONS}*/}
                        {/*            onChange={handleMovingToDistanceFromTruckToDoor}*/}
                        {/*            onInputChange={handleMovingToDistanceFromTruckToDoor}*/}
                        {/*            renderInput={(params) => <TextField*/}
                        {/*                variant="outlined"*/}
                        {/*                className={classes.smallInput}*/}
                        {/*                {...params}*/}
                        {/*                error={validationResult?.validateMovingToMissing?.includes('distanceFromTruckToDoor')}*/}
                        {/*                helperText={validationResult?.validateMovingToMissing?.includes('distanceFromTruckToDoor') && 'Required'}*/}
                        {/*            />}*/}
                        {/*        />*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={12}>*/}
                        {/*    <FormControl fullWidth style={{*/}
                        {/*        display: "flex",*/}
                        {/*        flexDirection: "row",*/}
                        {/*        justifyContent: "space-between",*/}
                        {/*        gap: "3px"*/}
                        {/*    }}>*/}
                        {/*        <FormLabel>*/}
                        {/*            How many staircase are there?*/}
                        {/*            <Tooltip*/}
                        {/*                placement="top"*/}
                        {/*                arrow*/}
                        {/*                componentsProps={{*/}
                        {/*                    tooltip: {*/}
                        {/*                        sx: {*/}
                        {/*                            bgcolor: 'white',*/}
                        {/*                            color: 'black',*/}
                        {/*                            boxShadow: 1,*/}
                        {/*                            border: '1px solid #ddd',*/}
                        {/*                            borderRadius: '8px', // Added border radius*/}
                        {/*                        },*/}
                        {/*                    },*/}
                        {/*                    arrow: {*/}
                        {/*                        sx: {*/}
                        {/*                            color: 'white',*/}
                        {/*                        },*/}
                        {/*                    },*/}
                        {/*                }}*/}
                        {/*                title={*/}
                        {/*                    <Box sx={{backgroundColor: "#ffffff", p: 1}}>*/}
                        {/*                        <ul style={{*/}
                        {/*                            paddingBlock: "1.5px",*/}
                        {/*                            margin: 0,*/}
                        {/*                            paddingInline: "3px"*/}
                        {/*                        }}>*/}
                        {/*                            <li>*/}
                        {/*                                A staircase consists of 3 or more steps.*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                A staircase connects 2 stories together.*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                It is a single staircase even if it is*/}
                        {/*                                interrupted by a*/}
                        {/*                                landing.*/}
                        {/*                            </li>*/}
                        {/*                            <li>*/}
                        {/*                                Do not include the basement of your*/}
                        {/*                                residence.*/}
                        {/*                            </li>*/}
                        {/*                        </ul>*/}
                        {/*                    </Box>*/}
                        {/*                }>*/}
                        {/*                <IconButton>*/}
                        {/*                    <FmdBadOutlinedIcon/>*/}
                        {/*                </IconButton>*/}
                        {/*            </Tooltip>*/}
                        {/*        </FormLabel>*/}
                        {/*        <Box sx={{*/}
                        {/*            display: 'flex',*/}
                        {/*            alignItems: 'center',*/}
                        {/*            border: '1px solid #ddd',*/}
                        {/*            borderRadius: '10px',*/}
                        {/*            padding: "7px"*/}
                        {/*        }}>*/}
                        {/*            <IconButton size="small"*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                        onClick={decreaseMovingToStaircase}><RemoveCircleOutlineIcon/></IconButton>*/}
                        {/*            <Typography variant="body1"*/}
                        {/*                        mx={1}>{movingTo.staircaseNumber}</Typography>*/}
                        {/*            <IconButton size="small"*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                        onClick={increaseMovingToStaircase}><AddCircleOutlineIcon/></IconButton>*/}
                        {/*        </Box>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={12}>*/}
                        {/*    <FormControl fullWidth>*/}
                        {/*        <FormLabel required sx={{textAlign: 'left'}}>*/}
                        {/*            Restrictions at collection*/}
                        {/*        </FormLabel>*/}
                        {/*        <FormGroup>*/}
                        {/*            <FormControlLabel*/}
                        {/*                control={*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={movingTo.isNarrowStreet}*/}
                        {/*                        onChange={handleMovingToIsNarrowStreet}*/}
                        {/*                        name="narrow street"*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                        sx={{*/}
                        {/*                            color: 'green',*/}
                        {/*                            '&.Mui-checked': {*/}
                        {/*                                color: 'green',*/}
                        {/*                            },*/}
                        {/*                        }}*/}
                        {/*                        style={{*/}
                        {/*                            color: 'green',*/}
                        {/*                            '&.Mui-checked': {*/}
                        {/*                                color: 'green',*/}
                        {/*                            }*/}
                        {/*                        }}*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*                label={*/}
                        {/*                    <>*/}
                        {/*                        Narrow Street:&nbsp;*/}
                        {/*                        <span style={{fontSize: "12px"}}>Tight, Space, Difficult for Trucks or Movers.*/}
                        {/*                                </span>*/}
                        {/*                    </>*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*            <FormControlLabel*/}
                        {/*                control={*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={movingTo.isSteepDriveway}*/}
                        {/*                        onChange={handleMovingToIsSteepDriveway}*/}
                        {/*                        disabled={isContinuePlaceOrder}*/}
                        {/*                        name="steep driveway"*/}
                        {/*                        style={{*/}
                        {/*                            color: 'green',*/}
                        {/*                            '&.Mui-checked': {*/}
                        {/*                                color: 'green',*/}
                        {/*                            }*/}
                        {/*                        }}*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*                label={*/}
                        {/*                    <>*/}
                        {/*                        Steep Driveway:&nbsp;*/}
                        {/*                        <span style={{fontSize: "12px"}}>Trucks & Movers may struggle with slope.*/}
                        {/*                </span>*/}
                        {/*                    </>*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        </FormGroup>*/}
                        {/*    </FormControl>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}