import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {FormLabel, Snackbar} from "@mui/material";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {CSVLink} from "react-csv";
import {useEffect, useRef, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AdjustPackageOrderDetailsCanpar = () => {

    const csvLink = useRef();

    const [createdDateAfter, setCreatedDateAfter] = useState(null);
    const [createdDateBefore, setCreatedDateBefore] = useState(null);
    const [tracking, setTracking] = useState([]);
    const [invoiceData, setInvoiceData] = useState([]);
    const [trackingLength, setTrackingLength] = useState(0);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [filterLoading, setFilterLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleCreatedDateAfter = event => {
        setCreatedDateAfter(event?.target?.value);
    }

    const handleCreatedDateBefore = event => {
        setCreatedDateBefore(event?.target?.value);
    }

    const searchAdjustCSV = async () => {
        setFilterLoading(true);
        let requestURL = `${PARTNER_URI}/invoices/admin/searchAdjustmentViews`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    createdDateAfter: createdDateAfter,
                    createdDateBefore: createdDateBefore,
                    accountBase: 'canpar'
                }
            })
            console.log('result', result);
            setTracking(result?.data);
        } catch (e) {
            console.log('error', e.response);
        } finally {
            setFilterLoading(false);
        }
    }

    const getInvoiceData = async (tackingNumber, invoiceNumber) => {
        let requestURL = `${PARTNER_URI}/invoices/admin/exportInvoiceDataForTracking`;
        let data = {
            trackingNumber: tackingNumber,
            invoiceNumber: invoiceNumber
        }
        console.log('data', data);
        const invoiceResult = [];
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('result get invoice data', result);
            if (result?.data?.headData?.length === 0) {
            } else {
                setInvoiceData(prevState => {
                    if (prevState?.length === 0) {
                        return [result?.data?.headData, ...result?.data?.lineData]
                    } else {
                        return [...prevState, ...result?.data?.lineData]
                    }
                })
            }
        } catch (e) {
            // setErrorMessage('There is an issue with downloading invoice data.');
            // handleErrorMessage();
            console.log('error', e.response);
        } finally {
            setTrackingLength(prevState => prevState + 1);
        }
    }

    const handleDownloadInvoice = () => {
        setLoading(true)
        setTrackingLength(0);
        setInvoiceData([]);
        for (const adjustment of tracking) {
            getInvoiceData(adjustment?.tracking_no, adjustment?.invoice_no);
        }
    }

    useEffect(() => {
        if (trackingLength > 0 && trackingLength === tracking?.length) {
            if (invoiceData?.length > 1) {
                // console.log('download csv');
                setErrorMessage('');
                setSuccessMessage('Invoice data downloaded successfully.');
                handleSuccessMessage();
                csvLink.current.link.click();
            } else {
                setErrorMessage('There is no invoice data available for download.');
                handleErrorMessage();
            }
            setLoading(false);
        }
    }, [trackingLength])

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item md={12} xs={12}>
                <Typography style={{
                    textAlign: 'left',
                    fontWeight: '600'
                }}>
                    Filter Canpar Adjust CSV
                </Typography>
            </Grid>
            <Grid item md={5} xs={6}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        Start Date
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={createdDateAfter}
                        onChange={handleCreatedDateAfter}
                        size='small'
                    />
                </Box>
            </Grid>
            <Grid item md={5} xs={6}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        End Date
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={createdDateBefore}
                        onChange={handleCreatedDateBefore}
                        size='small'
                    />
                </Box>
            </Grid>
            <Grid item md={2} xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    height: '100%'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={searchAdjustCSV}
                        loading={filterLoading}
                    >
                        <Typography style={{
                            textTransform: 'none'
                        }}>
                            Filter
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
            {
                tracking?.length > 0 ?
                    <>
                        <Grid item md={6} xs={12}>
                            <Typography style={{
                                fontSize: '16px',
                                textAlign: 'left'
                            }}>
                                Total Number of Adjusted Tracking Package: {tracking?.length}
                            </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                gap: '20px',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                                justifyContent: 'flex-start'
                            }}>
                                <LoadingButton
                                    variant='outlined'
                                    sx={{
                                        border: '2px solid #1D8B45',
                                        "&:hover": {
                                            border: '2px solid #1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleDownloadInvoice}
                                    loading={loading}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                        fontSize: '12px',
                                        color: '#1D8B45',
                                    }}>
                                        Download CSV
                                    </Typography>
                                </LoadingButton>
                                <CSVLink
                                    data={invoiceData}
                                    filename={`Adjust-Invoice.csv`}
                                    className='hidden'
                                    ref={csvLink}
                                    target='_blank'
                                />
                            </Box>
                        </Grid>
                    </> :
                    <Grid item md={6} xs={12}>
                        <Typography style={{
                            fontSize: '16px',
                            textAlign: 'left'
                        }}>
                            Total No of Adjusted Tracking Package: {tracking?.length}
                        </Typography>
                    </Grid>
            }
        </Grid>
    )
}