import React, { useRef, useImperativeHandle } from 'react'

export const StripeInput = React.forwardRef(
    ({ component: Component, ...props }, ref) => { // Forward the ref here
        const elementRef = useRef();
        useImperativeHandle(ref, () => ({
            focus: () => elementRef.current.focus
        }));
        return (
            <Component
                onReady={element => (elementRef.current = element)}
                {...props}
            />
        )
    }
);