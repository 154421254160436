import {Button, Grid, Snackbar, Typography} from "@mui/material";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useHistory} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ClaimItem = ({claimData}) => {

    const history = useHistory();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loadingCancelClaim, setLoadingCancelClaim] = useState(false);
    const [cancelStatus, setCancelStatus] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const cancelClaim = async () => {
        setLoadingCancelClaim(true);
        let requestURL = `${NODE_ROUTE_URI}/claim/cancelPackageClaimAdmin`;

        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                params: {
                    claimId: claimData?.claim_id,
                }
            })
            console.log('[cancelClaim] result', result);

            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`The claim has been successfully cancelled.`);
            handleDialogClose();
            setCancelStatus(true);
        } catch (e) {
            console.log('[cancelClaim] error', e);
            handleErrorMessage();
            setErrorMessage(`The claim could not be cancelled. Please try again.`);
            setCancelStatus(false);
        } finally {
            setLoadingCancelClaim(false);
        }
    }

    const handleUpdate = () => {
        history.push(`/v2/claim-list/${claimData?.claim_id}`);
        window.scrollTo(0, 0); // Scroll to the top
    }

    return (
        <Grid container spacing={1}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    Cancel Claim
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This process will cancel the claim. Are you sure to cancel?
                        <hr/>
                        Click <b>Confirm</b> to cancel this claim.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button">
                        No
                    </Button>
                    <LoadingButton
                        onClick={cancelClaim}
                        variant="outlined"
                        autoFocus
                        loading={loadingCancelClaim}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Id
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {claimData?.claim_id}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Date
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {dayjs(claimData?.create_at).format("MMMM, D YYYY")}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Status
                </Typography>
                {

                    cancelStatus ?
                        <Typography style={{
                            fontSize: '14px',
                            textAlign: 'left',
                            color: '#FF0303'
                        }}>
                            Cancelled
                        </Typography>
                        :
                        <Typography style={{
                            fontSize: '14px',
                            textAlign: 'left',
                            color: +claimData?.claim_status === 2 ? '#1D8B45' : +claimData?.claim_status < 0 ? '#FF0303' : '#454545'
                        }}>
                            {claimData?.claim_status === 0 ? 'Initialed' : claimData?.claim_status === 100 ? 'Processing' : claimData?.claim_status === 1 ? 'Accepted' : claimData?.claim_status === 2 ? 'Refunded' : claimData?.claim_status === -1 ? 'Cancelled' : 'Declined'}
                        </Typography>
                }
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Claim Type
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {claimData?.claim_type === 'lost' ? 'Shipment not received - Not delivered' : 'Shipment Damage'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Case #
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {claimData?.ref_case_no || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Refund Type
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.refund_type || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Refund Amount
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.refund_total_amount === 0 ? 'N/A' : `$${claimData?.refund_total_amount} CAD`}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Claim Value
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    ${(+claimData?.total_item_price)?.toFixed(2)} {claimData?.currency_code}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Claim Quantity
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.number_of_package}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Claim Result
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.claim_result || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Remark Notes
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.remark_notes || 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Backend Order Id
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.uuc_order_id}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    User Id
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.partner_id}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Owner Role
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.owner_role === 1 ? 'EndUser' : 'Partner'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Carrier
                </Typography>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {claimData?.account_base}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Tracking #
                </Typography>
                <Typography style={{
                    fontSize: '14px',
                    textAlign: 'left',
                    wordWrap: 'break-word', // Wrap text to the next line
                    wordBreak: 'break-word', // Break long words if necessary
                    whiteSpace: 'normal', // Allows the text to wrap onto multiple lines
                }}>
                    {claimData?.tracking_nos}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Shipment Date
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {dayjs(claimData?.shipped_date).format('MMMM DD, YYYY')}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Contact Name
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.contact_name}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Contact Phone
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.contact_tel}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography
                    style={{textAlign: 'left', fontSize: '14px', fontWeight: '600'}}>
                    Contact Email
                </Typography>
                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                    {claimData?.contact_email}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px'
                }}>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#454545',
                            "&:hover": {
                                backgroundColor: '#454545',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={handleDialogOpen}
                        size='small'
                        disabled={claimData?.claim_status === -1}
                    >
                        <Typography sx={{textTransform: 'none', fontSize: '14px'}}>
                            Cancel
                        </Typography>
                    </LoadingButton>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        size='small'
                        onClick={handleUpdate}
                    >
                        <Typography style={{
                            textAlign: 'left',
                            fontSize: '14px',
                            textTransform: 'none'
                        }}>
                            Update
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}