import {Button, FormLabel, Grid, MenuItem, Select} from "@mui/material";
import {CustomTextFieldNormal, CustomTextFieldNormalWithUnit, CustomTextFieldPallet} from "../../utils/customComponent";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG} from "../../utils/Helper";

const palletTypeDefault = [
    {name: '48"x40"', length: '48', width: '40', type: 1},
    {name: '48"x48"', length: '48', width: '48', type: 2},
    {name: 'Custom', length: null, width: null, type: 3}
]

const palletTypeMetric = [
    {name: '122cmx102cm', length: '122', width: '102', type: 1},
    {name: '122cmx122cm', length: '122', width: '122', type: 2},
    {name: 'Custom', length: null, width: null, type: 3}
]

export const LTLOrderListPalletEach = ({pallet, handleUpdatePallet, handleDeletePallet, isConfirm, metric}) => {

    const [palletInfo, setPalletInfo] = useState();
    const [palletTypeList, setPalletTypeList] = useState([]);
    const [palletType, setPalletType] = useState({name: '48"x40"', length: '48', width: '40', type: 1});

    const onChange = (field, value) => {
        switch (field) {
            case 'description':
                setPalletInfo(prevState => ({
                    ...prevState,
                    description: value
                }))
                break;
            case 'length':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        length: value
                    }))
                }
                break;
            case 'width':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        width: value
                    }))
                }
                break;
            case 'height':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        height: value
                    }))
                }
                break;
            case 'weight':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        weight: value
                    }))
                }
                break;
            case 'quantity':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        quantity: value
                    }))
                }
                break;
            default:
        }
    }

    const handleChangePalletType = (event) => {
        const pallet = event?.target?.value
        setPalletType(pallet);
        if (pallet?.name !== 'Custom') {
            setPalletInfo(prevState => ({
                ...prevState,
                length: pallet?.length,
                width: pallet?.width
            }))
        }
    }

    useEffect(() => {
        setPalletInfo(pallet);
    }, [pallet?.palletId]);

    useEffect(() => {
        handleUpdatePallet(palletInfo);
    }, [palletInfo]);

    useEffect(() => {
        if (metric) {
            setPalletInfo(prevState => ({
                ...prevState,
                lengthUnit: 'cm',
                weightUnit: 'kg',
                weight: convertLBToKG(pallet?.weight),
                length: convertINToCM(pallet?.length),
                width: convertINToCM(pallet?.width),
                height: convertINToCM(pallet?.height),
            }))
            setPalletTypeList(palletTypeMetric);
            setPalletType(palletTypeMetric[palletType?.type - 1]);
        } else {
            setPalletInfo(prevState => ({
                ...prevState,
                lengthUnit: 'in',
                weightUnit: 'lb',
                weight: convertKGToLB(pallet?.weight),
                length: convertCMToIN(pallet?.length),
                width: convertCMToIN(pallet?.width),
                height: convertCMToIN(pallet?.height),
            }))
            setPalletTypeList(palletTypeDefault);
            setPalletType(palletTypeDefault[palletType?.type - 1]);
        }
    }, [metric])

    // console.log('pallet each from parent', pallet);
    // console.log('pallet metric', metric, palletInfo?.palletId);
    // console.log('pallet each from local', palletInfo);

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Description
                            </FormLabel>
                            <CustomTextFieldPallet
                                textValue={pallet?.description}
                                field='description'
                                onChange={onChange}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Pallet Type
                            </FormLabel>
                            <Select
                                value={palletType}
                                size='small'
                                onChange={handleChangePalletType}
                                disabled={isConfirm}
                            >
                                {
                                    palletTypeList?.map((each, index) => (
                                        <MenuItem key={index} value={each}>
                                            {each?.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Length
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.length}
                                field='length'
                                onChange={onChange}
                                unit={pallet?.lengthUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Width
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.width}
                                field='width'
                                onChange={onChange}
                                unit={pallet?.lengthUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Height
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.height}
                                field='height'
                                onChange={onChange}
                                unit={pallet?.lengthUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Unit Weight
                            </FormLabel>
                            <CustomTextFieldNormalWithUnit
                                textValue={pallet?.weight}
                                field='weight'
                                onChange={onChange}
                                unit={pallet?.weightUnit}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Quantity
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={pallet?.quantity}
                        field='quantity'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        // unit={pallet?.weightUnit}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <IconButton
                        disabled={isConfirm}
                        onClick={() => handleDeletePallet(pallet)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>

    )
}