import {Divider, Grid, Link, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Fragment} from "react";
import {movingOrderStatus, movingShippingStatus} from "../../utils/Helper";
import {LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE} from "../../utils/apiUrl";

export const MovingOrderDetailSummary = ({orderInfo}) => {
    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Order Summary
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span style={{fontWeight: 600}}>Order Id: </span>{orderInfo?.order_id}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span style={{fontWeight: 600}}>Order #: </span>{orderInfo?.order_number}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span style={{fontWeight: 600}}>Order Type: </span>{orderInfo?.order_package_type}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Order Status: </span>{movingOrderStatus(orderInfo?.status_id)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Shipping Status: </span>{movingShippingStatus(orderInfo?.shipping_status_id)}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Invoice #: </span>{orderInfo?.invoiceInfo?.invoiceRefNumber || 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>BOL #: </span>
                                    {
                                        orderInfo?.bol_no ?
                                            <Link
                                                href={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${orderInfo?.bol_doc_uri}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                sx={{
                                                    color: '#1D8B45',
                                                    textDecoration: 'none', // Ensure no underline initially
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                        color: '#1D8B45', // Ensure no underline on hover
                                                    },
                                                }}>
                                                {orderInfo?.bol_no}
                                            </Link>
                                            :
                                            <>
                                                N/A
                                            </>
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>Original Estimate: </span>$ {orderInfo?.orderPrice?.final_total?.toFixed(2)}
                                </Typography>
                            </Grid>
                            {
                                (orderInfo?.shipping_status_id !== 1 && orderInfo?.shipping_status_id !== 2) &&
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        <span
                                            style={{fontWeight: 600}}>Approved Price: </span>$ {orderInfo?.price?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            }
                            {
                                orderInfo?.pending_amount > 0 &&
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        <span
                                            style={{fontWeight: 600}}>Pending Amount: </span>$ {orderInfo?.pending_amount?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            }
                            <Grid item xs={6}>
                                <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: 600}}>{orderInfo?.isLongDistance ? 'Long' : 'Local'} Distance: </span>{orderInfo?.distance?.toFixed(2)} km
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )

}