import {Button, FormLabel, Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
    convertCMToIN,
    convertINToCM,
    convertKGToLB,
    convertLBToKG,
    generateRandomString,
    getAmountOfFTLCount
} from "../../utils/Helper";
import {useEffect, useState} from "react";
import {LTLQuotePalletEach} from "./LTLQuotePalletEach";
import {LTLQuickQuotePalletEach} from "./LTLQuickQuotePalletEach";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const LTLQuickQuotePallet = ({cbHandleGetPallet, cbHandleGetFTL}) => {

    const [itemId, setItemId] = useState(1);
    const [metric, setMetric] = useState(false);
    const [palletList, setPalletList] = useState([]);
    const [FTL, setFTL] = useState(0);

    const handleMetric = (event) => {
        setMetric(event.target.checked)
    }

    const handleAddPallet = () => {

        const palletId = generateRandomString(8);
        const newPallet = {
            description: '',
            length: metric ? '48' : '122',
            width: metric ? '40' : '102',
            height: metric ? '60' : '152',
            weight: metric ? '400' : '181.5',
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            quantity: '1',
            palletId: palletId
        }
        setItemId(prevState => prevState + 1);
        setPalletList(prevState => [...prevState, newPallet]);
    }

    const handleUpdatePallet = (palletData) => {
        const updatedPalletList = palletList.map(pallet => {
            if (pallet?.palletId === palletData?.palletId) {
                return {...palletData}
            }
            return pallet
        });

        setPalletList(updatedPalletList);
    }

    const handleDeletePallet = (palletData) => {
        const palletIdToFind = palletData?.palletId;
        const filteredPalletList = palletList?.filter(pallet => pallet?.palletId !== palletIdToFind);
        setPalletList(filteredPalletList);
    }

    const handleFTL = (event) => {
        const value = event?.target?.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setFTL(value);
        }
    }

    useEffect(() => {
        const updatedPalletList = palletList.map(pallet => {
            if (metric) {
                return {
                    ...pallet,
                    lengthUnit: 'cm',
                    weightUnit: 'kg',
                    weight: convertLBToKG(pallet?.weight),
                    length: convertINToCM(pallet?.length),
                    width: convertINToCM(pallet?.width),
                    height: convertINToCM(pallet?.height),
                }
            } else {
                return {
                    ...pallet,
                    lengthUnit: 'in',
                    weightUnit: 'lb',
                    weight: convertKGToLB(pallet?.weight),
                    length: convertCMToIN(pallet?.length),
                    width: convertCMToIN(pallet?.width),
                    height: convertCMToIN(pallet?.height),
                }
            }
        });
        setPalletList(updatedPalletList);
    }, [metric])

    useEffect(() => {
        cbHandleGetPallet(palletList);
        if (palletList?.length > 0) {
            const FTLCount = getAmountOfFTLCount(palletList);
            setFTL(FTLCount);
        }
    }, [palletList]);

    useEffect(() => {
        cbHandleGetFTL(FTL);
    }, [FTL])

    console.log('[LTLQuickQuotePallet] palletList', palletList);

    return (
        <>
            <Grid item xs={12}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Pallet List
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <AntSwitch
                                        checked={metric}
                                        onChange={handleMetric}
                                    />
                                    <Typography>
                                        Metric (cm/kg)
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <IconButton
                                        sx={{color: '#609966'}}
                                        onClick={handleAddPallet}
                                        // disabled={isConfirm}
                                    >
                                        <AddCircleIcon/>
                                    </IconButton>
                                    <Typography>
                                        Add New Pallet
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>
                        {
                            palletList?.length > 0 ?
                                <>
                                    {
                                        palletList?.map((each, index) => (
                                            <Grid item xs={12} key={index}>
                                                <LTLQuickQuotePalletEach
                                                    pallet={each}
                                                    handleUpdatePallet={handleUpdatePallet}
                                                    handleDeletePallet={handleDeletePallet}
                                                    metric={metric}
                                                />
                                            </Grid>))
                                    }
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <FormLabel sx={{textAlign: 'left'}}>
                                                FTL
                                            </FormLabel>
                                            <TextField
                                                value={FTL}
                                                onInput={handleFTL}
                                                type='number'
                                                fullWidth
                                                variant="outlined"
                                                size='small'
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                                : <Grid item xs={12}>
                                    <Box>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            size='small'
                                            onClick={handleAddPallet}
                                        >
                                            <Typography style={{
                                                textTransform: 'none'
                                            }}>
                                                Add New Pallet
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}