import React, { useState, useEffect } from "react";

// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
  Container,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  // MenuItem,
  // Select,
  Button,
  Dialog,
} from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import { Check, Cancel } from "@material-ui/icons";
// import store from '../../store/index'
import { useHistory } from "react-router-dom";
// import { useDispatch } from 'react-redux';
import axios from "axios";

import LoadingContainer from "../../shared/loadingContainer";
import {
  BOL_LIST_ALL,
  BOL_REF_STATUS_UPDATE,
  BOL_REF_NUMBER_UPDATE,
} from "../../utils/apiUrl";
import { getURLParams, errHandling } from "../../utils/fetchUtils";
import BolManagement from "./BolManagement";
import BolListPageTableCells from "./BolListPageTableCells";
import BolDetails from "./BolDetails";

// const tabNumber = ["created", "accepted", "rejected"];
const statusColumn = "STATUS";
const bolNumberColumn = "BOL_NUMBER";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} pt={1}>
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const BolListPage = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  // const { tab: defaultTab } = useParams();

  const [page, setPage] = useState(1);
  // Tab value
  const [value, setValue] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  // const [userType, setuserType] = useState('')
  // const [isLoading, setLoadingState] = useState(true)
  const [errorMsg, setErrorMsg] = useState("");
  const [userBillData, setUserBillData] = useState([]);
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:600px)");

  const classes = useStyles({ matches });

  const [pageLoading, setPageLoading] = useState(false);
  const [statusValue, setStatusValue] = useState(0);
  const [refetchData, setRefetchData] = useState(false);
  const [tabClick, setTabClick] = useState(false);

  // individual status state
  const [renderMode, setRenderMode] = useState({});

  // Current Status Value
  const [bolRefRecordStatus, setBolRefRecordStatus] = useState(0);

  const [open, setOpen] = useState(false);

  const [bolNumber, setBolNumber] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setPageLoading(true);
    setStatusValue(value);
    const getParam = (tabNum) => {
      // 0 = created
      if (tabNum === 0) return { status: 0 };
      // 1 = accepted
      if (tabNum === 1) return { status: 1 };
      // -1 = rejected
      if (tabNum === 2) return { status: -1 };
    };
    setUserBillData([]);

    axios
      .get(
        getURLParams(BOL_LIST_ALL, {
          page: page,
          ...getParam(statusValue),
        })
      )
      .then((response) => {
        response.data.result && setUserBillData(response.data.result);
        setLastPage(response.data.total_page);
        setPageLoading(false);
        setTabClick(false);
      })
      .catch((err) => {
        console.log(JSON.stringify(err));
        // errHandling(err, setErrorMsg);
        setPageLoading(false);
        setTabClick(false);
      });
  }, [page, value, statusValue, refetchData]);

  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setPage(1);
      setTabClick(true);
    }
    // setValue(newValue);
    // history.replace(`/v2/bol-ref/${tabNumber[newValue]}`);
    setValue(newValue);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getDate = (date) => {
    return date.split(" ")[0];
  };

  // const handleChangeIndex = (index) => {
  //     setValue(index);
  // };

  // const goOrderDetailPage = (orderNumber, index, pkgSection = false) => {
  //   let path = `/order-details/${orderNumber}`;
  //   history.push({
  //     pathname: path,
  //     state: { activeIndex: index },
  //     hash: pkgSection ? "#packageSection" : "",
  //   });
  //   // dispatch(selectOrder({selected_order_number: orderNumber}))
  // };

  const goToDetailPage = (_bolNumber) => {
    setBolNumber(_bolNumber);
    setOpen(true);
  };

  const updateBofRefStatus = (bolRefNumber, newStatus, currentStatus) => {
    if (currentStatus !== newStatus) {
      axios
        .put(
          getURLParams(BOL_REF_STATUS_UPDATE, {
            status: newStatus,
            bol_ref_no: bolRefNumber,
            condition: "STATUS",
          })
        )
        .then((res) => {
          if (res.status === 200) {
            if (refetchData) {
              setRefetchData(false);
            } else {
              setRefetchData(true);
            }
            return true;
          }
        })
        .catch((error) => {
          return false;
        });
    }
  };

  const updateBolNumber = (bolRefNumber, newBolNumber) => {
    axios
      .put(
        getURLParams(BOL_REF_NUMBER_UPDATE, {
          bol_no: newBolNumber,
          bol_ref_no: bolRefNumber,
        })
      )
      .then((res) => {
        if (res.status === 200) {
          if (refetchData) {
            setRefetchData(false);
          } else {
            setRefetchData(true);
          }
          return true;
        }
      })
      .catch((error) => {
        return false;
      });
  };

  const panelContent =
    userBillData.length === 0 ? (
      <Box>
        <br />
        <br />
        There is no bill of lading for this type.
      </Box>
    ) : (
      <>
        <TableContainer className={classes.tBody}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Bol Ref Number</TableCell>
                <TableCell align="center">Created At</TableCell>
                <TableCell align="center">Shipper Name</TableCell>
                <TableCell align="center">Bol Number</TableCell>
                <TableCell align="right">Status </TableCell>
              </TableRow>
            </TableHead>
            {userBillData.map((bill) => {
              return (
                <TableBody className={classes.tBody}>
                  <TableRow key={bill.ref_no}>
                    <TableCell align="left">
                      <Button
                        variant="text"
                        onClick={() => goToDetailPage(bill.ref_no)}
                        className={classes.linkText}
                      >
                        {bill.ref_no}
                      </Button>
                    </TableCell>
                    <TableCell align="center">{bill.created_at}</TableCell>
                    <TableCell align="center">{bill.shipper_name}</TableCell>
                    <TableCell align="center">
                      <BolListPageTableCells
                        bolRefNumber={bill.ref_no}
                        bolNumber={bill.bol_no}
                        axiosCallBack={updateBolNumber}
                        columnAttribute={bolNumberColumn}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <BolListPageTableCells
                        bolRefNumber={bill.ref_no}
                        bolStatus={bill.status}
                        axiosCallBack={updateBofRefStatus}
                        columnAttribute={statusColumn}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
      </>
    );

  const pageSelection = lastPage > 1 && !tabClick && (
    <div className={classes.pagination}>
      <Pagination
        className={classes.pagination}
        count={lastPage}
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
      />
    </div>
  );

  return (
    <Container>
      {/* <div className="order-list-container"> */}
      <BolManagement />
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={3}
        mb={3}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/hs-code")}
          style={{
            textTransform: "unset",
            fontSize: "1.1rem",
          }}
        >
          <AddIcon />
          &nbsp; Add new &nbsp;
          <b>bill of lading</b>
        </Button>
      </Box> */}
      <Box className={classes.root} mt={4}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            // centered
            // scrollButtons="auto"
          >
            <Tab label="Created" {...a11yProps(0)} />
            <Tab label="Accepted" {...a11yProps(1)} />
            <Tab label="Rejected " {...a11yProps(2)} />
            {/* <Tab label="Canceled by backend" {...a11yProps(3)} /> */}
          </Tabs>
        </AppBar>
        {/* {isLoading ? <LoadingContainer /> : */}
        {/* (<> */}
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        {/* </>)} */}
        <Box pt="1vh" width="100%">
          {errorMsg ? <Alert severity="error">{errorMsg}</Alert> : ""}
        </Box>
      </Box>
      {/* </div> */}
      <Dialog fullScreen open={open} onClose={handleClose}>
        <BolDetails handleClose={handleClose} bolNumber={bolNumber} />
      </Dialog>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'white',
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 2),
    // width: props => props.matches ? '90vw': '80vw',
    width: "100%",
    // height: '80vh',
    // borderRadius: '20px',
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "scroll",
    // margin: '0 3vw'
  },
  // panel: {
  //   backgroundColor: "white",
  // },
  tabRoot: {
    // height: '100%',
    overflow: "none",
    height: "65vh",
  },
  // headerRoot: {
  //     marginTop: '0',
  // },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  linkText: {
    fontSize: "1rem !important",
    color: "blue",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pkgWrapper: {
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      // border: '1px solid rgba(0,0,0,0.3)',
      backgroundColor: "rgba(0,0,0,0.1)",
      borderRadius: "5px",
    },
  },
  textTitle: {
    flexBasis: "40%",
    textAlign: "left",
  },
  activeWrapper: {
    backgroundColor: "grey",
    color: "white",
    borderRadius: 5,
    padding: theme.spacing(0.5),
    height: "40px",
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
  itemWrapper: {
    "& hr": {
      // border: '1px solid rgba(0,0,0,0.3)',
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
      borderTop: "3px solid rgba(0,0,0,.1)",
    },
  },
  tBody: {
    width: "100%",
  },
}));

// const mapStateToProps = state => ({
//     orders: state.orders,
//     packages: state.packages
// })

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default BolListPage;
