import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import {useHistory, useLocation} from "react-router-dom";
// import Slide from 'react-reveal';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Snackbar,
  Input, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";

import PropTypes from "prop-types";
import produce from "immer";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { green, red, blue } from "@mui/material/colors";
import axios from "axios";
import { SERVICE_DAO_URL, NODE_API } from "../../../utils/apiUrl";
import { PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX } from "../../../utils/apiUrl";
import { redirectHome } from "../../../utils/redirect";
import CurrencyFormat from "react-currency-format";
import ReviewOrderHeader from "./reviewOrderHeader";
import {getAccessToken} from "../../../utils/doToken";
import ShippingListTrackingTable from "./ShippingListTrackingTable";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexDirection: 'column',
    margin: "0 auto",
    width: "40vw",
    minWidth: "500px",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    textAlign: "left"
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    textAlign: "left"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 5,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    width: "14rem",
  },
  smButton: {
    borderRadius: 5,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    width: "12rem",
  },
  textfield: {
    width: "100%",
    backgroundColor: "white",
    // border: "1px solid white",
  },
  dockerFee: {
    width: "4rem",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    // backgroundColor: "#FEEAAE",
    backgroundColor: "white",
  },

  infoWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  textButton: {
    color: "blue",
  },
  bodyText: {
    fontSize: "1rem",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  timePicker: {
    width: 160,
  },
  divider: {
    borderBottom: "2px solid rgba(0,0,0,0.3)",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  radioWrapper: {
    border: "1px solid red",
    paddingLeft: theme.spacing(1),
    borderRadius: "5px",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

// const formKeys = [
//   {
//     type: "textField",
//     formKey: "orderNumber",
//     objectKey: "order_number", // db column
//     fieldName: "Order #",
//     edittable: true,
//   },
//   {
//     type: "textField",
//     formKey: "sender",
//     objectKey: "sender",
//     fieldName: "Sender Name",
//     edittable: true,
//   },
//   {
//     type: "textField",
//     formKey: "senderEmail",
//     objectKey: "sender_email",
//     fieldName: "Sender Email",
//     edittable: true,
//   },
// ];

// Compare number with limit
const numberExceed = (num, limit) => {
  return parseFloat(num) > limit;
};

// Different Text for different place order condition
const placeOrderText = (error) => {
  return error ? "Submit" : "Confirm Order";
};

const ShippingOrderPaymentDetails = () => {
  // const classes = useStyles;

  // const [orderId] = useState(useParams().id);
  // const [orderDetails, setOrderDetails] = useState({});
  // const [initialPartnerDetails, setInitialPartnerDetails] = useState({});
  // const [canEdit, setCanEdit] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // const [statusMessage, setStatusMessage] = useState("");

  // useEffect(() => {
  //   const getOrderDetails = async () => {
  //     try {
  //       // Get company details from api
  //       const result = await axios.get(
  //         NODE_API +
  //           "/orderPaymentManagementRouter/get3PLBackendOrderByIdForOperator",
  //         { params: { id: orderId } }
  //       );
  //       if (result.status === 200) {
  //         const newPartnerDetails = result.data;
  //         console.log(result.data);
  //         setOrderDetails(newPartnerDetails);
  //         setInitialPartnerDetails(newPartnerDetails);
  //       } else {
  //         throw new Error();
  //       }
  //     } catch (e) {
  //       return;
  //     }
  //   };
  //   getOrderDetails();
  // }, [orderId]);

  const dispatch = useDispatch();
  // const pallets = useSelector((state) => state.shipping.shippingItems);
  // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
  const userEmail = useSelector((state) => state.user.email);
  const id = useSelector((state) => state.user.id);

  const orderId = useParams().id;

  const [sampleLabel, setSampleLabel] = useState("");
  const [creatingOrder, setCreatingStatus] = useState(false);


  const [cardLoading, setCardLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [reload, setReload] = useState(false);
  // const [sendingEmail, setSendingStatus] = useState(false);

  const [emailSentSuccess, setEmailSentSuccessState] = useState(false);
  const [emailSentFail, setEmailSentFailState] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [cancelDiaglogOpen, setCancelDialogOpen] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [orderNumber, setOrderNumber] = useState("");
  const [orderStatus, setOrderStatus] = useState(0);
  const [refOrderNo, setRefOrderNo] = useState(undefined);
  // const [pickupName, setpickupName] = useState("");
  // const [pickupPhone, setPickupPhone] = useState("");
  // const [pickupAddress, setPickupAddress] = useState("");
  // const [pickupAddressTwo, setPickupAddressTwo] = useState("");
  // const [pickupCity, setPickupCity] = useState("");
  // const [pickupProvince, setPickupProvince] = useState("");
  // const [pickupPostalCode, setPickupPostalCode] = useState("");

  // send_from_addr,
  //           send_from_city,
  //           send_from_country,
  //           send_from_name,
  //           send_from_postal_code,
  //           send_from_province,
  //           send_from_tel,

  const [sendFromAddr, setSendFromAddr] = useState("");
  const [sendFromCity, setSendFromCity] = useState("");
  const [sendFromCountry, setSendFromCountry] = useState("");
  const [sendFromName, setSendFromName] = useState("");
  const [sendFromPostalCode, setSendFromPostalCode] = useState("");
  const [sendFromProvince, setSendFromProvince] = useState("");
  const [sendFromTel, setSendFromTel] = useState("");
  const [pallets, setPallets] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [finalTotalAmount, setFinalTotalAmount] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("");
  const [shippingFee, setShippingFee] = useState(0);
  const [serviceFeeTax, setServiceFeeTax] = useState(0);
  const [delay, setDelay] = useState(2000);


  const [backendOrderStatus, setBackendOrderStatus] = useState(-1);

  // Shipping Order Details
  const [shippingOrderInfo, setShippingOrderInfo] = useState({
    transactions: []
  });

  const getOrderStatus = async () => {
    try {
      const accessToken = getAccessToken("access_token");
      const { data } = await axios.get(`${NODE_API}/looseItem/getBackendOrderStatusByOrderId/${orderId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log(data);
      setBackendOrderStatus(data.orderStatus);
    } catch (e) {
      setBackendOrderStatus(0);
      console.log(e);
    }
  }

  // const getShippingOrderInfo = async () => {
  //   try {
  //     const accessToken = getAccessToken("access_token");
  //     const { data } = await axios.get(`${NODE_API}/looseItem/getShippingOrderInfoByBackendOrderId/${orderId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`
  //       }
  //     });
  //     console.log("shipping order info: ", data);
  //     // setShippingOrderInfo(data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }


  useEffect(() => {
    let isMounted = true;
    if (orderId) {
      (async () => {

        const result = await axios.get(
          PARTNER_URI +
            `/shipping/list/getShippingOrderInfoById/${orderId}`
        );
        const data = result.data
        console.log("Order Details: ", data);

        if (isMounted) setShippingOrderInfo(data);
        const {
            order_number,
            order_status,
            send_from_addr,
            send_from_city,
            send_from_country,
            send_from_name,
            send_from_postal_code,
            send_from_province,
            send_from_tel,
            ship_service_type,
            account_base,
            ref_order_no,
            total_amount,
            total_amount_gross,
            currency_code,
            status_id
        } = data;
        // const [pickup_city, pickup_province] = pickup_area.split(", ");
        // const [deliver_city, deliver_province] = deliver_area.split(", ");

        // const account_base = account_base_combine_name ? account_base_combine_name.split("-")[0] : "";
        // const { taxes, final_total, service_fee, shipping_fee, residential_charge } = prices;

        //// setOrderNumber(order_number);
        if (isMounted) {

          setRefOrderNo(ref_order_no)
          setOrderStatus(order_status);

          setSendFromAddr(send_from_addr)
          setSendFromCity(send_from_city)
          setSendFromCountry(send_from_country)
          setSendFromName(send_from_name)
          setSendFromPostalCode(send_from_postal_code)
          setSendFromProvince(send_from_province)
          setSendFromTel(send_from_tel)
          setCompanyName(account_base);
          setServiceName(ship_service_type);
          setFinalTotalAmount(total_amount);
          setCurrencyCode(currency_code)

          setBackendOrderStatus(order_status);
          setOrderStatus(order_status);
        }
      })();
    }
    return () => { isMounted = false };
  }, [orderId])

  // useEffect(() => {
  //   if (orderId) {
  //     getOrderStatus();
  //   }
  // }, [orderId])
  // useInterval(
  //     () => {
  //       if (orderId) {
  //         getOrderStatus();
  //       }
  //     },
  //     // Delay in milliseconds or null to stop it
  //     backendOrderStatus === 0 ? delay : null,
  // )

  // useEffect(() => {
  //   if (backendOrderStatus === 1) {
  //     (async () => {
  //       await getShippingOrderInfo();
  //     })()
  //   }
  // }, [backendOrderStatus])


  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancelDialogOpen = () => {
    setCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
  };

  // Error indication to check the fee numbers when there are more than two packages
  const [extraFeeError, setExtraFeeError] = useState({
    pickupDock: false,
    deliverDock: false,
    appointment: false,
  });

  // const [personalEmtpy, setPersonalEmpty] = useState(false);

  const history = useHistory();
  // const location = useLocation();

  const handlePlaceOrder = async (e) => {
    handleConfirmDialogOpen();
  }

  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const example = (data) => (
      <img
          src={`data:image/jpeg;base64,${data}`}
          alt="label"
          width={700}
          height="auto"
      />
  );

  const saveAndReturn = () => {
    redirectHome(history);
  };

  return (
    <Box width={"70%"} margin="0 auto" sx={{
      display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
      alignItems: "center", alignContent: "center", textAlign: "start"
    }}>

      {/*Pop-up Toast*/}
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Cancel Order">
        {(() => {
          if (errorMessage !== "") {
            return (
                <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                  Place Order Failed!
                  <hr />
                  Error: {errorMessage}
                </Alert>
            )
          }
          return (
              <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                Place Order Success!
              </Alert>
          )
        })()}

      </Snackbar>
      {/*Pop-up Toast*/}


      {/*Confirm Place Order Dialog*/}
      <Dialog
          open={confirmDialogOpen}
          onClose={handleConfirmDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Place Order
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will place your Shipping Order. Are you sure to continue?
            <hr />
            Click <b>Confirm</b> to place an order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>
          <Button onClick={() => {}} variant="outlined" type="button" disabled={placeOrderLoading} >
            {placeOrderLoading && <CircularProgress size={14} />}
            {!placeOrderLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Confirm Place Order Dialog*/}

      {/*Cancel Place Order Dialog*/}
      <Dialog
          open={cancelDiaglogOpen}
          onClose={handleCancelDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cancel Order
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will cancel your Shipping Order. Are you sure to continue?
            <hr />
            Click <b>Confirm</b> to cancel this order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} variant="outlined" autoFocus type="button" >No</Button>
          <Button onClick={() => {}} variant="outlined" type="button" disabled={cancelOrderLoading} >
            {cancelOrderLoading && <CircularProgress size={14} />}
            {!cancelOrderLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Cancel Place Order Dialog*/}


      <Box width={"100%"} margin="0 auto">
        <h2 className="orange-title">Shipping Order Details</h2>
        <ReviewOrderHeader
            sendFromAddr={sendFromAddr}
            sendFromCity={sendFromCity}
            sendFromCountry={sendFromCountry}
            sendFromName={sendFromName}
            sendFromPostalCode={sendFromPostalCode}
            sendFromProvince={sendFromProvince}
            sendFromTel={sendFromTel}
            pallets={pallets}
            refOrderNo={refOrderNo}
            orderStatus={orderStatus}
        />
        <Box mb={2}>
            <Box>
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Box className={classes.summaryHeader}>
                    <b>Order Status</b>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.summaryBodyText}>
                {
                  (() => {
                    switch (orderStatus) {
                      case 0:
                        return "Initial"
                      case 1:
                        return "Active";
                      case -1:
                        return "Cancelled"
                    }
                  })()
                }
                {/*{orderStatus === 0 ? 'Initial, not paid' : 'Paid'}*/}
              </Box>
            </Box>
        </Box>
        <Box mb={2}>
            <Box>
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Box className={classes.summaryHeader}>
                    <b>Service</b>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.summaryBodyText}>
                {companyName}
                {/* <ul>
                  <li>
                    <h6>{serviceName}</h6>
                  </li>
                </ul> */}
              </Box>
            </Box>
        </Box>

        <Box>
          <Box>
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.summaryHeader}>
                  <b>Price</b>
                </Box>
              </Box>
            </Box>
            <Box className={classes.summaryBodyText}>
              Total: <CurrencyFormat value={finalTotalAmount || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />{` (${currencyCode})`}
            </Box>
          </Box>
        </Box>
        <hr />
        <Dialog
            maxWidth="sm"
            fullWidth
            open={dialogOpen}
            onClose={() => {
              setReload(true);
              setDialogOpen(false);
            }}
        >
          <div className={classes.dialogTitle}>
            <Box fontSize="1.5rem"></Box>
            <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => {
                  setReload(true);
                  setDialogOpen(false);
                }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          {/*<AddCreditCardPage addCard={true} popUp={true} />*/}
        </Dialog>
      </Box>

      {
          backendOrderStatus === 1 && (
              <>
                <Box width={"100%"} margin="0 auto">
                  <Box>
                  <Box mt={2}>
                        <table className={classes.table}>
                          <thead>
                          <tr className={classes.listItemWrapper}>
                            <th className={classes.idTH}>Transaction ID</th>
                            <th className={classes.idTH}>Order ID</th>
                            {/*<th className={classes.nameTH}>Ref Order Number</th>*/}
                            {/*<th className={classes.nameTH}>Ref Product ID</th>*/}
                            {/*<th className={classes.nameTH}>Ref Other Number</th>*/}
                            <th className={classes.nameTH}>Ref #</th>
                            <th className={classes.nameTH}>Send To</th>
                            {/*<th className={classes.nameTH}>Send To Name/Email/Tel</th>*/}
                            {/*<th className={classes.nameTH}>Send To Address/City/Province/Country/Postal Code</th>*/}
                            <th className={classes.nameTH}>Account Base</th>
                            {/*<th className={classes.nameTH}>Exclude Tax</th>*/}
                            {/*<th className={classes.nameTH}>Include Tax</th>*/}
                            <th className={classes.nameTH}>Price</th>
                            {/* <th className={classes.nameTH}>Tracking #</th> */}
                            <th className={classes.nameTH}>Labels</th>
                            {/*<th className={classes.nameTH}>ZIP Label URI</th>*/}
                            {/*<th className={classes.nameTH}>Label PDF URI</th>*/}
                            <th className={classes.nameTH}>Date</th>
                            <th className={classes.nameTH}>Status</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {shippingOrderInfo.transactions.filter?.(v => v.tran_status > 0).map((v, index) => {
                            return <ShippingListTrackingTable key={index}
                                                              account_base={v.account_base}
                                                              date={v.tranacted_at}
                                                              gross_price={v?.amount_gross}
                                                              net_price={v?.amount}
                                                              order_id={v?.order_id}
                                                              ref_product_no={v.ref_product_no}
                                                              ref_other_no={v?.ref_other_no}
                                                              ref_order_no={v?.ref_order_no}
                                                              tran_id={v.tran_id}
                                                              zip_label_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                                              label_pdf_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                                              send_to_addr={v.send_to_addr}
                                                              send_to_city={v.send_to_city}
                                                              send_to_province={v.send_to_province}
                                                              send_to_country={v.send_to_country}
                                                              send_to_postal_code={v.send_to_postal_code}
                                                              send_to_name={v.send_to_name}
                                                              send_to_tel={v.send_to_tel}
                                                              send_to_email={v.send_to_email}
                                                              currency_code={v?.currency_code || "CAD"}
                                                              transactionNumbers={v.tran_tracking_nos}
                                                              tran_status={v.tran_status}
                                                              shipping_id_no={v.shipping_id_no}
                            />;
                          })}
                          </tbody>
                        </table>
                      </Box>
                  </Box>
                </Box>
              </>
          )
      }

      <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={emailSentSuccess}
          onClose={() => setEmailSentSuccessState(false)}
          message="Send email successfully"
          autoHideDuration={3000}
      />

      <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={emailSentFail}
          onClose={() => setEmailSentFailState(false)}
          message="Failed to send email"
          autoHideDuration={3000}
      />
    </Box>


    // <div>
    //   <BackArrow link={"/orderpayment/enduser/all/list"} />
    //   <Box maxWidth="70%" margin="0 auto" paddingBottom={"200px"}>
    //     <Box mt={2}>
    //       <h1>
    //         Order Payment Details
    //       </h1>
    //       {statusMessage}

    //       <table className={classes.table}>
    //         <thead>
    //           <tr>
    //             <th className={classes.idTH} width="20%"></th>
    //             <th className={classes.idTH} width="100%"></th>
    //             {/* <th className={classes.idTH} width="10%"></th> */}
    //           </tr>
    //         </thead>
    //         <tbody className={classes.tbody}>
    //         {formKeys.map((formElement, index) => {
    //               return (
    //                 <tr key={index}>
    //                   <td>{formElement.fieldName || "Placeholder"}</td>
    //                   <td>
    //                     <Box flex="45%" width="100%">
    //                       <TextField
    //                         id="outlined-basic"
    //                         value={
    //                           orderDetails[formElement.objectKey]
    //                             ? orderDetails[formElement.objectKey]
    //                             : ""
    //                         }
    //                         onChange={(e) => {
    //                           setOrderDetails((prev) => ({
    //                             ...prev,
    //                             [formElement.objectKey]: e.target.value,
    //                           }));
    //                         }}
    //                         variant="outlined"
    //                         fullWidth
    //                         disabled={!canEdit}
    //                       />
    //                     </Box>
    //                   </td>
    //                 </tr>
    //               );
    //             })}
    //         </tbody>
    //       </table>
    //     </Box>
    //   </Box>
    // </div>
  );
};


export default ShippingOrderPaymentDetails;
