import {useHistory, useParams} from "react-router-dom";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import {Button, CircularProgress, Container, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ContainerShippingOrderDetailShipper} from "./ContainerShippingOrderDetailShipper";
import {ContainerShippingOrderDetailConsignee} from "./ContainerShippingOrderDetailConsignee";
import {ContainerShippingOrderDetailVessel} from "./ContainerShippingOrderDetailVessel";
import {ContainerShippingOrderDetailUser} from "./ContainerShippingOrderDetailUser";
import {ContainerShippingOrderDetailFile} from "./ContainerShippingOrderDetailFile";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {ContainerShippingOrderDetailScheduleAndPorts} from "./ContainerShippingOrderDetailScheduleAndPorts";
import {ContainerShippingOrderDetailContainer} from "./ContainerShippingOrderDetailContainer";
import {ContainerShippingOrderDetailServiceAndPrice} from "./ContainerShippingOrderDetailServiceAndPrice";
import {ContainerShippingOrderDetailStatus} from "./ContainerShippingOrderDetailStatus";
import {ContainerShippingOrderDetailInvoice} from "./ContainerShippingOrderDetailInvoice";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ContainerShippingOrderDetail = () => {

    const history = useHistory();

    const accessToken = getAccessToken("access_token");

    const {orderId} = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orderInfo, setOrderInfo] = useState(null);

    const getContainerShippingOrderInfo = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/getOceanBackendOrderDetails`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderId
                }
            })
            console.log('[getContainerShippingOrderInfo] result', result);
            setOrderInfo(result?.data);
            setError(false);
        } catch (e) {
            console.log('[getContainerShippingOrderInfo] error', e.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const handleBack = () => {
        history.push('/v2/container-shipping-list/')
    }

    useEffect(() => {
        if (orderId) {
            getContainerShippingOrderInfo();
        }
    }, [orderId])

    if (loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingOrderRoot}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Moving Order Details
                    </Typography>
                    <Box sx={styles.ShippingOrderContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CircularProgress/>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    if (error && !loading) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingOrderRoot}>
                    <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                        Moving Order Details
                    </Typography>
                    <Box sx={styles.ShippingOrderContent}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{fontWeight: '600'}}>
                                    Sorry We currently encounter some issues, please try again later.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Container Shipping Order Details
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <ContainerShippingOrderDetailUser orderInfo={orderInfo}/>
                        <ContainerShippingOrderDetailStatus orderInfo={orderInfo}
                                                            getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailShipper orderInfo={orderInfo}
                                                             getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailConsignee orderInfo={orderInfo}
                                                               getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailVessel orderInfo={orderInfo}
                                                            getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailScheduleAndPorts orderInfo={orderInfo}
                                                                      getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailContainer orderInfo={orderInfo}
                                                               getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailFile orderInfo={orderInfo}
                                                          getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailServiceAndPrice orderInfo={orderInfo}
                                                                     getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <ContainerShippingOrderDetailInvoice orderInfo={orderInfo}
                                                             getContainerShippingOrderInfo={getContainerShippingOrderInfo}/>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#454545',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#454545',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleBack}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Back
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}
