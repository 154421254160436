import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  useMediaQuery
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {Alert, Pagination} from "@material-ui/lab";
import axios from "axios";
import { LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI, NODE_ROUTE_URI } from "../../utils/apiUrl";
import ShippingListTransactionTable from "./ShippingListTransactionTable";
import { useParams } from "react-router-dom";
import {CSVLink} from "react-csv";
import {useSelector} from "react-redux";
import {getAccessToken} from "../../utils/doToken";
import CircularProgress from "@material-ui/core/CircularProgress";
import xlsx from "json-as-xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word"
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff"
  },
  table: {
    width: "100%"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)"
    }
  },
  nameTH: {
    width: "100px"
  },
  idTH: {
    width: "100px"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2)
    },
    display: "flex",
    justifyContent: "center"
  }
}));

const ShippingTransactionDetails = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 10;
  const { tran_id: default_tran_id } = useParams();

  const { register, handleSubmit } = useForm();
  const [orderData, setOrderData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const cacheInput = useRef({
    tran_id: default_tran_id
  });

  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [currentTrackingNumber, setCurrentTrackingNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const handleChange = (e) => {
    setCurrentTrackingNumber(e.target.value);
  };

  const [cancelTrackingNumberText, setCancelTrackingNumberText] = useState("");
  const [cancelTrackingNumberErrorMessage, setCancelTrackingNumberErrorMessage] = useState("");
  const [cancelLoading, setCancelLoading] = useState(false);

  // const { id: partnerId } = useSelector((state) => state.user);

  const csvLink = useRef();

  // Download Data State
  const [downloadData, setDownloadData] = useState([
    ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Order Total Amount", "Transaction Amount", "Currency", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
  ]);

  // Download Data in Excel
  const excelFields = {
    "Transaction ID": "",
    "Product ID": "",
    "Tracking Number": "",
    "Label URI": ""
  }
  // const getTransactionDataExcel = async () => {
  //   const { tran_id, trackingNumbers, ref_product_no, ref_order_no, order_id } = cacheInput.current;
  //   const { data } = await axios.post(`${PARTNER_URI}/shipping/list/product/all`, {
  //     tran_id,
  //     trackingNumbers,
  //     ref_product_no,
  //     ref_order_no,
  //     order_id,
  //     partnerId
  //   })
  //   const result = data.result;
  //   const resultArray = [];
  //   for (const column of result) {
  //     const columnData = {...excelFields};
  //     // columnData.push(column.tran_id);
  //     // columnData.push(column.ref_product_no);
  //     // columnData.push(column.tracking_no);
  //     // columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
  //     // resultArray.push(columnData);
  //     columnData["Transaction ID"] = column.tran_id;
  //     columnData["Product ID"] = column.ref_product_no;
  //     columnData["Tracking Number"] = column.tracking_no;
  //     columnData["Label URI"] = column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A"
  //     resultArray.push(columnData);
  //     const excelBody = [
  //       {
  //         sheet: "Transactions",
  //         columns: [
  //           {
  //             label: "Transaction ID",
  //             value: "transactionId"
  //           },
  //           {
  //             label: "Product ID",
  //             value: "productId"
  //           },
  //           {
  //             label: "Tracking Number",
  //             value: "trackingNumber"
  //           },
  //           {
  //             label: "Label URI",
  //             value: "labelUri"
  //           }
  //         ],
  //         content: resultArray.map(v => {
  //           return {
  //             transactionId: v["Transaction ID"],
  //             productId: v["Product ID"],
  //             trackingNumber: v["Tracking Number"],
  //             labelUri: v["Label URI"]
  //           }
  //         })
  //       }
  //     ]
  //     let settings = {
  //       fileName: `tracking-no-list`,
  //     }
  //     // excelLink.current.link.click()
  //     xlsx(excelBody, settings)
  //   }
  // }

  // const handleKeyUp = (e) => {
  //   if (e.keyCode === 32 && currentTrackingNumber.trim() !== "") {
  //     setTrackingNumbers((oldState) => [...oldState, e.target.value?.trim()]);
  //     setCurrentTrackingNumber("");
  //   }
  // };

  const handleDelete = (item, index) => {
    let arr = [...trackingNumbers];
    arr.splice(index, 1);
    console.log(item);
    setTrackingNumbers(arr);
  };

  const getLabelURL = (pdf, zip) => {
    if (pdf) {
      return pdf;
    }
    if (zip) {
      return zip;
    }
    return "";
  }

  const getTrackingListData = async () => {
    try {
      const { tran_id, ref_product_no, ref_order_no, order_id, trackingNumberString } = cacheInput.current;
      // const { data } = await axios.post(`${PARTNER_URI}/shipping/list/product/all`, {
      //   tran_id,
      //   trackingNumbers,
      //   ref_product_no,
      //   ref_order_no,
      //   order_id,
      //   partnerId
      // })
      const resultArray = [];
      const { data } = await axios.post(`${PARTNER_URI}/shipping/list/order/multipleTrackings`, {
        // partnerId: partnerId,
        orderId: order_id,
        refOrderNumber: ref_order_no,
        refProductNumber: ref_product_no,
        trackingNumbers: trackingNumberString,
        tranId: tran_id
      });
      const result = data.result;
      for (const column of result) {
        const columnData = [];
        columnData.push(column.order_id);
        columnData.push(column.ref_order_no);
        columnData.push(column.cargo_control_no);
        columnData.push(column.account_base);
        columnData.push(column.tracking_no);
        columnData.push(column.total_amount);
        columnData.push(column.amount);
        columnData.push(column.currency_code);
        columnData.push(column.tran_id);
        // Product ID
        columnData.push(column.ref_product_no);
        columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
        columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
        columnData.push(column.tranacted_at);

        resultArray.push(columnData);
      }
      setDownloadData(v => {
        const temp = [...v];
        temp.push(...resultArray);
        return temp;
      })
      console.log(resultArray);
      csvLink.current.link.click()
    } catch (e) {
      console.log(e);
    }
  }

  const getData = async ({ tran_id, ref_product_no, ref_order_no, order_id, trackingNumbersData, partnerId, currentPage, trackingNumberString }) => {
    const { data } = await axios.post(`${NODE_ROUTE_URI}/shippingTransactions/shippingTransaction/detail`, {
      tran_id,
      // trackingNumbers: trackingNumbersData,
      page: currentPage,
      ref_product_no,
      ref_order_no,
      order_id,
      trackingNumbers: trackingNumberString
    });
    const result = data.result.data;
    const resultTotalNumber = data.result.pagination.total;
    const resultLastPage = data.result.pagination.lastPage;
    console.log(result)
    setTotalNumber(resultTotalNumber);
    setOrderData(result);
    setLastPage(resultLastPage);
    try {
    } catch (e) {
      setTotalNumber(0);
      console.log(e);
      setOrderData([]);
    } finally {
      setIsLoading(false);
      // Remove Download Data
      setDownloadData([
        ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Order Total Amount", "Transaction Amount", "Currency", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"],
      ])
    }
  };

  const onSubmit = (data) => {
    const { tran_id, ref_product_no, ref_order_no, order_id, trackingNumberString } = data;
    // const currentTrackingNumbers = [...trackingNumbers];
    //
    // if (currentTrackingNumber.trim() !== "") {
    //   currentTrackingNumbers.push(currentTrackingNumber.trim());
    //   setCurrentTrackingNumber("");
    //   setTrackingNumbers(currentTrackingNumbers);
    // }

    // At Least one input, otherwise won't send request
    // if (!tran_id && !ref_product_no && !ref_order_no && !order_id && currentTrackingNumbers.length === 0) {
    //   console.log("At least one input");
    //   setErrorMessage("* Please Enter at least ONE field");
    //   return;
    // }
    if (!tran_id && !ref_product_no && !ref_order_no && !order_id && !trackingNumberString) {
      console.log("At least one input");
      setErrorMessage("* Please Enter at least ONE field");
      return;
    }
    setErrorMessage("");

    cacheInput.current = {
      tran_id,
      // trackingNumbers: currentTrackingNumbers,
      ref_product_no,
      ref_order_no,
      order_id,
      // partnerId,
      trackingNumberString
    };
    setPage(1);
    getData({
      tran_id,
      // trackingNumbersData: currentTrackingNumbers,
      currentPage: 1,
      ref_product_no,
      ref_order_no,
      order_id,
      // partnerId,
      trackingNumberString
    });
  };
  const handlePageChange = (e, value) => {
    if (value !== page) {
      console.log(value);
      setPage(value);
      const {
        tran_id,
        ref_product_no,
        ref_order_no,
        order_id,
        // partnerId,
        trackingNumberString
      } = cacheInput.current;
      getData({
        tran_id,
        trackingNumbersData: trackingNumbers,
        currentPage: value,
        // partnerId,
        ref_product_no,
        ref_order_no,
        order_id,
        trackingNumberString
      });
    }
  };

  useEffect(() => {
    getData({ tran_id: default_tran_id, currentPage: 1, trackingNumbersData: [], trackingNumberString: "" });
  }, [default_tran_id]);

  const [toastOpen, setToastOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const cancelOrderByTrackingNumber = async (trackingNumber) => {
    try {
      setCancelLoading(true);
      const storedToken = getAccessToken("access_token");
      const { data } = await axios.delete(`${PARTNER_URI}/loose-item/3rd-party/internal/cancel-shipping`, {
        headers: { Authorization: `Bearer ${storedToken}` },
        data: {
          trackingNumber
        }
      });
      console.log(data);
      setCancelTrackingNumberErrorMessage("");
    } catch (e) {
      console.log(e);
      setCancelTrackingNumberErrorMessage(e?.response?.data?.errorMessage || "Error!");
    } finally {
      setCancelLoading(false);
    }
  }

  const confirmCancelOrder = async (e) => {
    e.preventDefault();
    await cancelOrderByTrackingNumber(cancelTrackingNumberText);
    handleToastClick();
    handleDialogClose();
  }

  // Cancel Tracking Order
  const onCancelOrder = (trackingNumber) => {
    console.log(trackingNumber)
    setCancelTrackingNumberText(trackingNumber);
    handleDialogOpen();
  }

  return (
    <>
      {/*Pop-up Toast*/}
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Cancel Order">
        {(() => {
          if (cancelTrackingNumberErrorMessage !== "") {
            return (
                <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                  Tracking {cancelTrackingNumberText} can't be cancelled!
                  <hr />
                  Error: {cancelTrackingNumberErrorMessage}
                </Alert>
            )
          }
          return (
              <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                Tracking {cancelTrackingNumberText} has been cancelled!
              </Alert>
          )
        })()}

      </Snackbar>
      {/*Pop-up Toast*/}

      {/*Confirm Delete Tracking Dialog*/}
      <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cancel Tracking Order
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will cancel ALL the orders related to this Tracking Number <b>{cancelTrackingNumberText}</b>. Are you sure to cancel?
            <hr />
            Click <b>Confirm</b> to cancel this shipping.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button" >No</Button>
          <Button onClick={confirmCancelOrder} variant="outlined" type="button" disabled={cancelLoading} >
            {cancelLoading && <CircularProgress size={14} />}
            {!cancelLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Confirm Delete Tracking Dialog*/}
      <Box>
        <Box
          component="form"
          width={matches ? "100%" : "600px"}
          margin="0 auto"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            gap: "2rem"
          }}>
          <h1>Tracking Orders</h1>
          <TextField
            id="outlined-basic"
            label="Transaction ID"
            variant="outlined"
            defaultValue={default_tran_id}
            {...register("tran_id")}
          />
          <TextField
              id="outlined-basic"
              label="Product ID (Ref Product Number)"
              variant="outlined"
              {...register("ref_product_no")}
          />
          <TextField
              id="outlined-basic"
              label="Ref Order Number"
              variant="outlined"
              {...register("ref_order_no")}
          />
          <TextField
              id="outlined-basic"
              label="Order Id"
              variant="outlined"
              {...register("order_id")}
          />
          <TextField
              id="outlined-basic"
              label="Tracking Numbers (Separate by Comma and Line Break)"
              variant="outlined"
              {...register("trackingNumberString")}
              multiline
          />
          {/*<TextField*/}
          {/*  id="outlined-basic"*/}
          {/*  label="Tracking Numbers (Enter/Separate by Space)"*/}
          {/*  variant="outlined"*/}
          {/*  onKeyDown={handleKeyUp}*/}
          {/*  value={currentTrackingNumber}*/}
          {/*  onChange={handleChange}*/}
          {/*/>*/}
          <Box
            sx={{
              gap: "0.5rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center"
            }}>
            {trackingNumbers.map((item, index) => (
              <Chip
                size="small"
                onDelete={() => handleDelete(item, index)}
                label={item}
                key={index}
              />
            ))}
          </Box>
          <Box>
            <Button
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem"
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          </Box>
          {
              errorMessage !== "" && (
                  <Alert severity="error">
                    {errorMessage}
                  </Alert>
              )
          }
        </Box>

        <br />
        {isLoading ? (
          <>
            <Box>
              <h1>Loading...</h1>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center" sx={{gap: "2rem"}}>
                <h2>Found {totalNumber} results</h2>
                <Box display={totalNumber === 0 ? "none" : "flex"} justifyContent="center" alignContent="center" flexBasis={0}>
                  {/*<button onClick={getTransactionDataExcel}>Download</button>*/}
                  <button onClick={getTrackingListData}>Download</button>
                  <CSVLink
                      data={downloadData}
                      filename="tracking-no-list"
                      className='hidden'
                      ref={csvLink}
                      target='_blank'
                  />
                </Box>
              </Box>

              <hr width={matches ? "100%" : "rem"} />
              <Box maxWidth="70%" margin="0 auto">
                <Box mt={2}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.idTH}>Order ID</th>
                        <th className={classes.idTH}>Ref Order #</th>
                        <th className={classes.idTH}>Cargo Control #</th>
                        <th className={classes.idTH}>Transaction ID</th>
                        <th className={classes.nameTH}>Product ID</th>
                        <th className={classes.nameTH}>Tracking #</th>
                        <th className={classes.nameTH}>Label URI</th>
                        <th className={classes.nameTH}>Status</th>
                      </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                      {orderData.map((v, index) => {
                        return (
                          <ShippingListTransactionTable
                            key={index}
                            product_id={v?.ref_product_no}
                            tracking_no={v?.tracking_no}
                            tran_id={v.tran_id}
                            label_uri={
                              v?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_uri}` : ""
                            }
                            tn_status={v?.tn_status}
                            onCancelOrder={onCancelOrder}
                            order_id={v?.order_id}
                            ref_order_number={v.ref_order_no}
                            cargo_control_no={v.cargo_control_no}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              </Box>
              <br />
              <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <Pagination
                  count={lastPage}
                  page={page}
                  onChange={handlePageChange}
                  className={classes.pagination}
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ShippingTransactionDetails;
