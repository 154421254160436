import React, { useState, useEffect } from "react";
import {
  Box,
  useMediaQuery,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";

import { BACKEND_LOGIN_STATE, UPLOAD_CSV_FILE } from "../utils/apiUrl";
import { errHandling } from "../utils/fetchUtils";

import axios from "axios";

//Test mode
const testMode = process.env.NODE_ENV === "production" ? false : true;

function CargoControlTrackingPage() {
  const classes = useStyles();

  const matches = useMediaQuery("(max-width:600px)");

  const [authChecking, setAuthChecking] = useState(testMode ? false : true);

  const [authorized, setAuthorized] = useState(testMode ? true : false);

  const [errMsg, setErrMsg] = useState("");
  const [uploadErrMsg, setUploadErrMsg] = useState("");

  const [fileObj, setFileObj] = useState(null);

  const [uploading, setUploadingState] = useState(false);

  const [uploadingSuccess, setUploadingSuccessState] = useState(false);

  const bodyFormData = new FormData();

  useEffect(() => {
    if (!testMode) {
      axios
        .get(BACKEND_LOGIN_STATE)
        .then((response) => {
          console.log("res", response);
          // response.data.data && dispatch(setOrders({orders: response.data.data}));
          if (response.data?.state === "authorized") setAuthorized(true);
          else setErrMsg("Loading error");
          // setLoadingState(false);
          setAuthChecking(false);
        })
        .catch((err) => {
          console.table("Error", err.response, err.status);
          if (err.response?.status === 401) {
            setErrMsg("Unauthorized, try to re-login.");
            setAuthorized(false);
          } else {
            setErrMsg("Unknown error, try to re-login.");
            setAuthorized(false);
          }
          setAuthChecking(false);
        });
    }
  }, []);

  const onDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) return;
    setUploadingSuccessState(false);
    setFileObj({
      selectedFile: e.target.files[0],
      //**** added line below ****//
      selectedFileName: e.target.files[0].name,
    });
  };

  const handleCloseSnackBar = () => {
    setUploadErrMsg("");
  };

  const handleSubmit = () => {
    if (fileObj?.selectedFile) {
      setUploadingState(true);
      try {
        bodyFormData.append("csv_file", fileObj.selectedFile);
        bodyFormData.append("file_type", "cargo_control");
      } finally {
        axios({
          method: "post",
          url: UPLOAD_CSV_FILE,
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data;",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then((res) => {
            setUploadingSuccessState(true);
            setFileObj(null);
            setUploadingState(false);
          })
          .catch((error) => {
            errHandling(error, setUploadErrMsg);
            setUploadingState(false);
          });
      }
    }
  };

  // const getMainComponent = (_authChecking, _errMsg, _authorized) => {
  //   if (_errMsg) {
  //     return (
  //       <Box>
  //         {_errMsg}
  //         <Box mt={2}>
  //           <a
  //             target="_blank"
  //             rel="noopener noreferrer"
  //             href="https://admin.uucargo.ca/"
  //           >
  //             Go back
  //           </a>
  //         </Box>
  //       </Box>
  //     );
  //   }
  //   if (_authorized) {
  //   }

  //   return (
  //     <div className={classes.root}>
  //       <CircularProgress />
  //     </div>
  //   );
  // };

  return authChecking ? (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  ) : !authorized || errMsg ? (
    //go back
    <Box className={classes.root}>
      {errMsg}
      <Box mt={2}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://admin.uucargo.ca/"
        >
          Go back
        </a>
      </Box>
    </Box>
  ) : (
    <Box
      className="App"
      // className={
      //   matches ? classes.smPackageTabContainer : classes.packageTabContainer
      // }
      width={matches ? "100%" : "600px"}
      margin="0 auto"
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* {getMainComponent(authChecking, errMsg, authorized)} */}

      <Box
        display="flex"
        flexDirection="column"
        width="300px"
        margin="0 auto"
        justifyContent="center"
        alignItems="center"
      >
        <Box textAlign="center" mb={2}>
          {/* Max. file size: <b>10MB</b> <br /> */}
          Accepted file type: <b>CSV</b>
        </Box>
        <input
          accept=".csv"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          // onChange={()={}}
          onChange={onDrop}
        />
        <label htmlFor="raised-button-file">
          <Button
            variant="contained"
            component="span"
            color="primary"
            style={{
              textTransform: "unset",
              fontSize: "1rem",
            }}
          >
            Select file
          </Button>
        </label>
        {fileObj && (
          <Box className={classes.buttonFilesWrapper} mt={3}>
            <Box display="flex" alignItems="center">
              <InsertDriveFileIcon />
              {fileObj.selectedFileName}
            </Box>
            <Box mt={2} mb={1}>
              <Button
                variant="contained"
                component="span"
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  backgroundColor: "rgba(0,0,255,0.2)",
                  width: "7rem",
                }}
                onClick={handleSubmit}
              >
                {uploading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            </Box>
          </Box>
        )}
        {uploadingSuccess && (
          <Box textAlign="center" mt={3} color="green" fontSize="1.2rem">
            File uploaded successfully.
          </Box>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(uploadErrMsg)}
        onClose={handleCloseSnackBar}
        message={uploadErrMsg}
        autoHideDuration={3000}
      />
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
}));

export default CargoControlTrackingPage;
