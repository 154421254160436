import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import CurrencyFormat from "react-currency-format";


const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
  link: {
    textDecoration: "none",
  },
}));

const OrderPaymentListItem = ({
  order_id,
  partner_id,
  ref_order_no,
  cargo_control_no,
  account_base,
  total_amount,
  send_from_name,
  send_from_email,
  send_from_tel,
  send_from_addr,
  send_from_city,
  send_from_postal_code,
  send_from_province,
  origi_shipping_item_file,
  currency_code,
  date
}) => {
  const classes = useStyles();
  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link to={`/v2/orderpayment/shippingorder/notpaid/detail/${order_id}`}>
            {order_id}
          </Link>
        </td>
        <td>{ref_order_no}</td>
        <td>{cargo_control_no}</td>
        <td>{account_base}</td>
        <td>
          <CurrencyFormat value={(total_amount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />
        </td>
        <td>{`${send_from_name ? send_from_name : "N/A"} / ${send_from_email ? send_from_email : "N/A"} / ${send_from_tel ? send_from_tel : "N/A"}`}</td>
        <td>{`${send_from_addr ? send_from_addr : "N/A"} / ${send_from_city ? send_from_city : "N/A"} / ${send_from_province ? send_from_province : "N/A"} / ${send_from_postal_code ? send_from_postal_code : "N/A"}`}</td>
        <td>{date}</td>
        {/* <td>
          <Box
            display="inline-flex"
            sx={{
              alignItems: "center",
            }}
          >
            <Link
              className={classes.link}
              to={`/v2/orderpayment/shippingorder/notpaid/detail/${id}`}
            >
              <Button
                className={classes.detailBtn}
                color="secondary"
                variant="outlined"
              >
                Details
              </Button>
            </Link>
            <Link
              className={classes.link}
              to={`/v2/orderpayment/shippingorder/notpaid/detail/${id}`}
            >
              <Button
                className={classes.detailBtn}
                color="secondary"
                variant="outlined"
              >
                Email Transfer
              </Button>
            </Link>
          </Box>
        </td> */}
      </tr>
    </>
  );
};

export default OrderPaymentListItem;
