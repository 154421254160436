import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  created_at: "",
  deliver_area: "",
  deliver_city: "",
  deliver_province: "",
  delivered_at: "",
  driver_name: "",
  order_number: "",
  pickup_area: "",
  pickup_city: "",
  pickup_province: "",
  sender_name: "",
  shipment_number: "",
  shipping_status: "",
  pickup_address: "",
  deliver_address: "",
  pickup_phone: "",
  deliver_phone: "",
  schedule_pickup: "",
};

export const updateShipmentSlice = createSlice({
  name: "updateShipment",
  initialState: { ...initialState },
  reducers: {
    updateShipmentInfo: (state, action) => {
      if (action.payload.shipment) {
        for (const key in action.payload.shipment) {
          state[key] = action.payload.shipment[key];
        }
      } else {
        state[action.payload.fieldName] = action.payload.value;
      }
    },
    removeShipmentInfo: () => {
      return { ...initialState };
    },
  },
});

export const { updateShipmentInfo, removeShipmentInfo } =
  updateShipmentSlice.actions;

export default updateShipmentSlice.reducer;
