import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import { visuallyHidden } from "@mui/utils";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import CurrencyFormat from "react-currency-format";
import axios from "axios";
import {PARTNER_URI} from "../../utils/apiUrl";
import { triggerBase64Download } from 'common-base64-downloader-react';
import {getAccessToken} from "../../utils/doToken";

function createData({
                      Empty,
                      InvoiceNumber,
                      BilledAmount,
                      InvoiceDate,
                      Action,
                      invoiceId,
                      currency,
                    }) {
  return {
    Empty,
    InvoiceNumber,
    BilledAmount,
    InvoiceDate,
    Action,
    invoiceId,
    currency
  };
}

const headCells = [
  {
    id: "Empty",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "InvoiceNumber",
    numeric: false,
    disablePadding: true,
    label: "Invoice #",
  },
  {
    id: "BilledAmount",
    numeric: true,
    disablePadding: false,
    label: "Billed Amount",
  },
  {
    id: "InvoiceDate",
    numeric: true,
    disablePadding: false,
    label: "Invoice Date",
  },

  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
              <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// function EnhancedTableToolbar(props) {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) =>
//             alpha(
//               theme.palette.primary.main,
//               theme.palette.action.activatedOpacity
//             ),
//         }),
//       }}
//     >
//       {numSelected > 0 ? (
//         <Typography
//           sx={{ flex: "1 1 100%" }}
//           color="inherit"
//           variant="subtitle1"
//           component="div"
//         >
//           {numSelected} selected
//         </Typography>
//       ) : (
//         <Typography
//           sx={{ flex: "1 1 100%" }}
//           variant="h6"
//           id="tableTitle"
//           component="div"
//         >
//           Nutrition
//         </Typography>
//       )}

//       {numSelected > 0 ? (
//         <Tooltip title="Delete">
//           <IconButton>
//             <DeleteIcon />
//           </IconButton>
//         </Tooltip>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )}
//     </Toolbar>
//   );
// }

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function PaidInvoices({ invoices }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionsOpen, setActionsOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [placement, setPlacement] = useState();
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [singleRowInvoice, setSingleRowInvoice] = useState({});

  useEffect(() => {
    const newRows = [];
    invoices.map((invoice) => {
      newRows.push(
          createData({
                Empty: "",
                InvoiceNumber: invoice.invoiceRefNumber,
                BilledAmount: (+invoice.totalChargeIncludeTax).toFixed(2),
                InvoiceDate: invoice.invoiceDate,
                invoiceId:  invoice.id,
                currency:  invoice.currency || "CAD",
                Action: ""
              }
          )
      );
    });
    setRows(newRows);
  }, [invoices]);
  //
  // useEffect(() => {
  //   console.log("invoices: ", invoices);
  // }, [invoices])

  useEffect(() => {
    const filteredSeletedInvoices = [];
    selected.map((eachSelect) => {
      const filtered = invoices.filter(
          (invoice) => invoice.invoiceRefClientNumber === eachSelect
      );
      filteredSeletedInvoices.push(filtered[0]);
    });
    setCheckedRows(filteredSeletedInvoices);
    // const paymentAmount = filteredSeletedInvoices.reduce(
    //   (accumulator, currentValue) =>
    //     accumulator + parseFloat(currentValue.totalChargeIncludeTax),
    //   0
    // );

    // const newMessages = [];
    // filteredSeletedInvoices.map((eachFilter) =>
    //   newMessages.push(eachFilter.invoiceRefNumber)
    // );
    // setMessage(newMessages);
    // setPlacement(paymentAmount);
  }, [selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const actionsHandler = (newPlacement, row) => (event) => {
    setSingleRowInvoice(row);
    setAnchorEl(event.currentTarget);
    setActionsOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const paymentDialogOpenHandler = (e) => {
    setActionType(e.target.outerText);
    setActionsOpen(!actionsOpen);
    setPaymentDialogOpen(true);
  };
  const paymentDialogCloseHandler = () => {
    setPaymentDialogOpen(false);
  };

  const downloadPDFByInvoiceId = async ({ invoiceId }) => {
    try {
      const storedToken = getAccessToken("access_token");
      const { data } = await axios.get(`${PARTNER_URI}/billingCenter/downloadInvoice?invoiceId=${invoiceId}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      const content = data.content;
      const contentType = data["content-type"];
      const pdfContent = `data:${contentType};base64,${content}`;
      const fileName = data.filename;
      triggerBase64Download(pdfContent, fileName);
    } catch (e) {
      console.log(e);
    }
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
      <Box sx={{ width: "100%" }}>
        {/* {Action popper} */}
        <Popper
            open={actionsOpen}
            anchorEl={anchorEl}
            placement={placement}
            transition
        >
          {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <MenuList style={{ backgroundColor: "#f2eee6" }}>
                  <MenuItem onClick={async () => {
                    await downloadPDFByInvoiceId({
                      invoiceId: singleRowInvoice.invoiceId
                    });
                  }
                  }>Download</MenuItem>
                  {/*<MenuItem onClick={(e) => paymentDialogOpenHandler(e)}>*/}
                  {/*  E-transfer*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem onClick={(e) => paymentDialogOpenHandler(e)}>*/}
                  {/*  WeChat*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem onClick={(e) => paymentDialogOpenHandler(e)}>*/}
                  {/*  AliPay*/}
                  {/*</MenuItem>*/}
                </MenuList>
              </Fade>
          )}
        </Popper>

        <Paper sx={{ width: "100%", mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer style={{ maxHeight: 500 }}>
            <Table
                sx={{ Width: 450 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.InvoiceNumber);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                          <TableRow
                              hover
                              tabIndex={-1}
                              key={row.name}
                              selected={isItemSelected}
                          >
                            <TableCell
                                padding="checkbox"
                                onClick={(event) =>
                                    handleClick(event, row.InvoiceNumber)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                            >
                              <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              {row.InvoiceNumber}
                            </TableCell>

                            <TableCell align="right">
                              {/*{row.BilledAmount}*/}
                              <CurrencyFormat
                                  value={Math.abs(row.BilledAmount)}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={
                                    +row.BilledAmount >= 0 ? `${row.currency} $` : `${row.currency} $-`
                                  }
                              />
                            </TableCell>
                            <TableCell align="right">{row.InvoiceDate}</TableCell>
                            <TableCell align="right">
                              <IconButton onClick={actionsHandler("left", row)}>
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                      );
                    })}
                {emptyRows > 0 && (
                    <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
  );
}
