import {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {extractFullAddress} from "../../utils/Helper";
import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldPallet} from "../../utils/customComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLOrderDashboardShipper = ({
                                             getDataFromChildComponent,
                                             triggerAction,
                                             isConfirm,
                                             validation,
                                             orderData,
                                             selectPartner,
                                             getShipperResidential,
                                             getOrderDetails
                                         }) => {

    const [shipperName, setShipperName] = useState();
    const [shipperPhone, setShipperPhone] = useState();
    const [shipperCompanyName, setShipperCompanyName] = useState();
    const [shipperEmail, setShipperEmail] = useState();
    const [shipperAddress, setShipperAddress] = useState();
    const [shipperAddressTwo, setShipperAddressTwo] = useState();
    const [shipperCity, setShipperCity] = useState();
    const [shipperProvince, setShipperProvince] = useState();
    const [shipperCountry, setShipperCountry] = useState();
    const [shipperPostalCode, setShipperPostalCode] = useState();

    const [originalShipperName, setOriginalShipperName] = useState();
    const [originalShipperPhone, setOriginalShipperPhone] = useState();
    const [originalShipperCompanyName, setOriginalShipperCompanyName] = useState();
    const [originalShipperEmail, setOriginalShipperEmail] = useState();
    const [originalShipperAddress, setOriginalShipperAddress] = useState();
    const [originalShipperAddressTwo, setOriginalShipperAddressTwo] = useState();
    const [originalShipperCity, setOriginalShipperCity] = useState();
    const [originalShipperProvince, setOriginalShipperProvince] = useState();
    const [originalShipperCountry, setOriginalShipperCountry] = useState();
    const [originalShipperPostalCode, setOriginalShipperPostalCode] = useState();

    const [shipFromAddressPredictions, setShipFromAddressPredictions] = useState([]);
    const [displayAddress, setDisplayAddress] = useState({
        description: null,
        placeId: null,
        text: null
    });

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const classes = useStyles();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setDisplayAddress(prevState => ({
            ...prevState,
            description: '',
            placeId: '',
            text: value
        }));
        setShipperAddress(value);
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipFromAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setDisplayAddress(value);
            setShipperCity(address?.city);
            setShipperProvince(address?.state?.code);
            setShipperCountry(address?.country?.code);
            setShipperPostalCode(address?.zip);
        } else {
            setDisplayAddress('');
        }
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'shipperName':
                setShipperName(value);
                break;
            case 'shipperPhone':
                setShipperPhone(value);
                break;
            case 'shipperCompanyName':
                setShipperCompanyName(value);
                break;
            case 'shipperEmail':
                setShipperEmail(value);
                break;
            case 'shipperAddress':
                setShipperAddress(value);
                break;
            case 'shipperAddressTwo':
                setShipperAddressTwo(value);
                break;
            case 'shipperCity':
                setShipperCity(value);
                break;
            case 'shipperProvince':
                setShipperProvince(value);
                break;
            case 'shipperCountry':
                setShipperCountry(value);
                break;
            case 'shipperPostalCode':
                setShipperPostalCode(value);
                break;
            default:
        }
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    shipperName: shipperName,
                    shipperPhone: shipperPhone,
                    shipperCompanyName: shipperCompanyName,
                    shipperEmail: shipperEmail,
                    shipperAddress: shipperAddress,
                    shipperCity: shipperCity,
                    shipperProvince: shipperProvince,
                    shipperPostalCode: shipperPostalCode,
                    shipperCountry: shipperCountry,
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalShipperName(shipperName);
            // setOriginalShipperCompanyName(shipperCompanyName);
            // setOriginalShipperPhone(shipperPhone);
            // setOriginalShipperEmail(shipperEmail);
            // setOriginalShipperCity(shipperCity);
            // setOriginalShipperProvince(shipperProvince);
            // setOriginalShipperCountry(shipperCountry);
            // setOriginalShipperPostalCode(shipperPostalCode);
            // setOriginalShipperAddress(shipperAddress);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                shipperInfo: {
                    shipperName,
                    shipperPhone,
                    shipperEmail,
                    shipperCompanyName,
                    shipperAddress,
                    shipperAddressTwo,
                    shipperCity,
                    shipperProvince,
                    shipperCountry,
                    shipperPostalCode,
                }
            });
        }
    }, [triggerAction])

    useEffect(() => {
        setShipperName(orderData?.bolInfo?.shipper_name);
        setShipperCompanyName(orderData?.bolInfo?.shipper_company_name);
        setShipperPhone(orderData?.bolInfo?.shipper_phone);
        setShipperEmail(orderData?.bolInfo?.shipper_email);
        setShipperCity(orderData?.bolInfo?.shipper_city);
        setShipperProvince(orderData?.bolInfo?.shipper_province);
        setShipperCountry(orderData?.bolInfo?.shipper_country);
        setShipperPostalCode(orderData?.bolInfo?.shipper_postal_code);
        setShipperAddress(orderData?.bolInfo?.shipper_address);
        setDisplayAddress(prevState => ({
            ...prevState, text: orderData?.bolInfo?.shipper_address
        }))
        setShipperAddressTwo(orderData?.bolInfo?.shipper_address_two);
        setOriginalShipperName(orderData?.bolInfo?.shipper_name);
        setOriginalShipperCompanyName(orderData?.bolInfo?.shipper_company_name);
        setOriginalShipperPhone(orderData?.bolInfo?.shipper_phone);
        setOriginalShipperEmail(orderData?.bolInfo?.shipper_email);
        setOriginalShipperCity(orderData?.bolInfo?.shipper_city);
        setOriginalShipperProvince(orderData?.bolInfo?.shipper_province);
        setOriginalShipperCountry(orderData?.bolInfo?.shipper_country);
        setOriginalShipperPostalCode(orderData?.bolInfo?.shipper_postal_code);
        setOriginalShipperAddress(orderData?.bolInfo?.shipper_address);
        setOriginalShipperAddressTwo(orderData?.bolInfo?.shipper_address_two);
    }, [orderData]);

    useEffect(() => {
        if (selectPartner) {
            setShipperName(`${selectPartner?.firstname} ${selectPartner?.lastname}`);
            setShipperPhone(selectPartner?.phone);
            setShipperCompanyName(selectPartner?.business_name);
            setShipperEmail(selectPartner?.email);
        }
    }, [selectPartner])

    useEffect(() => {
        if (shipperAddress && shipperCity && shipperProvince && shipperCountry && shipperPostalCode) {
            getShipperResidential({
                shipperInfo: {
                    shipperAddress,
                    shipperCity,
                    shipperProvince,
                    shipperCountry,
                    shipperPostalCode,
                }
            });
        }
    }, [shipperAddress, shipperCity, shipperProvince, shipperCountry, shipperPostalCode])

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Name
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperName}
                        field='shipperName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperName')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Phone
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperPhone}
                        field='shipperPhone'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperPhone')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Company
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperCompanyName}
                        field='shipperCompanyName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperCompanyName')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Email
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperEmail}
                        field='shipperEmail'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperEmail')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address
                    </FormLabel>
                    <Autocomplete
                        freeSolo
                        value={displayAddress}
                        fullWidth
                        disabled={isConfirm}
                        options={shipFromAddressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputChangeAddress}
                        onChange={handleChangeAddress}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                            error={validation?.shipFromMissing?.includes('shipperAddress')}
                            helperText={
                                (validation?.shipFromMissing?.includes('shipperAddress')) && 'Required Field'
                            }
                        />}
                        renderOption={option => (
                            <Typography>
                                {option?.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address Two
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperAddressTwo}
                        field='shipperAddressTwo'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        City
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperCity}
                        field='shipperCity'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperCity')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Province
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperProvince}
                        field='shipperProvince'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperProvince')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Country
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperCountry}
                        field='shipperCountry'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperCountry')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Postal Code
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={shipperPostalCode}
                        field='shipperPostalCode'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipFromMissing?.includes('shipperPostalCode')}
                    />
                </FormControl>
            </Grid>
            {
                (
                    shipperName !== originalShipperName ||
                    shipperPhone !== originalShipperPhone ||
                    shipperCompanyName !== originalShipperCompanyName ||
                    shipperEmail !== originalShipperEmail ||
                    shipperAddress !== originalShipperAddress ||
                    // shipperAddressTwo !== originalShipperAddressTwo ||
                    shipperCity !== originalShipperCity ||
                    shipperProvince !== originalShipperProvince ||
                    shipperCountry !== originalShipperCountry ||
                    shipperPostalCode !== originalShipperPostalCode
                ) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}
