import {Button, Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {useEffect, useRef, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {ClaimDashboardFileDetails} from "./ClaimDashboardFileDetails";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {generateRandomString} from "../../utils/Helper";

export const ClaimDashboardFileList = ({fileListData, resetTrigger, handleFileList, validation}) => {

    const inputFile = useRef(null);

    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState(fileListData);

    const handleUpdateFileList = (updatedFile) => {
        const updateFileList = fileList?.map(file => {
            if (file?.file_id === updatedFile?.file_id) {
                return {...updatedFile}
            }
            return file
        })
        setFileList(updateFileList);
    }

    const handleRemoveFileList = (updatedItem) => {
        const filteredList = fileList?.filter(file => file?.file_id !== updatedItem?.file_id);
        setFileList(filteredList);
    }

    const uploadFileToServer = async (uploadedFile) => {

        console.log('[uploadFileToServer] uploadedFile', uploadedFile);

        try {
            if (!uploadedFile) {
                return;
            }
            let requestURL = `${NODE_ROUTE_URI}/claim/uploadFile`;
            const data = new FormData();
            data.append('file', uploadedFile);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: data
            })

            console.log('[uploadFileToServer] result', result);

            const fileId = generateRandomString(8);
            const newFile = {
                file_id: fileId,
                file_type: "",
                file_location_uri: result?.data?.imageURL,
                origi_file_name: result?.data?.uploadedFileName,
            }
            setFileList(prevState => [...prevState, newFile]);

        } catch (e) {
            console.log('[uploadFileToServer] error', e.response);
        } finally {
            setLoading(false)
        }
    }

    const handleFileChange = async (event) => {
        setLoading(true);
        const uploadedFile = event.target.files[0];
        await uploadFileToServer(uploadedFile);
    };

    useEffect(() => {
        setFileList(fileListData);
    }, [fileListData])

    useEffect(() => {
        setFileList(fileListData);
    },[resetTrigger])

    useEffect(() => {
        handleFileList(fileList)
    },[fileList])

    // console.log('[ClaimDashboardFileList] fileListData', fileListData);
    // console.log('[ClaimDashboardFileList] fileList', fileList);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                        Photos and Documents
                    </Typography>
                    <input
                        accept="image/jpeg, image/png, application/pdf"
                        style={{display: 'none'}}
                        ref={inputFile}
                        type="file"
                        onChange={handleFileChange}
                    />
                    <LoadingButton
                        onClick={() => {
                            inputFile.current.click()
                        }}
                        loading={loading}
                        startIcon={<CloudUploadIcon sx={{color: '#1D8B45'}}/>}>
                        <Typography sx={{textTransform: 'none', color: '#1D8B45'}}>
                            Upload
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
            {
                fileList?.length > 0 ?
                    <>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                File Name
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                File Type
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                                Actions
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        {
                            fileList?.map((fileData, index) => (
                                <ClaimDashboardFileDetails fileData={fileData}
                                                           key={index}
                                                           index={index}
                                                           handleRemoveFileList={handleRemoveFileList}
                                                           handleUpdateFileList={handleUpdateFileList}
                                                           validation={validation}
                                />
                            ))
                        }
                    </>
                    :
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #B7B7B7',
                            padding: '20px',
                            borderRadius: '10px'
                        }}>
                            <Typography sx={{fontSize: '20px', fontWeight: '600', color: '#7F8487'}}>
                                No documents or photos have been uploaded.
                            </Typography>
                        </Box>
                    </Grid>
            }
        </Grid>
    )
}
