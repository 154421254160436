import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {makeStyles} from "@material-ui/core/styles";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {LocalizationProvider, MobileDatePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import dayjs from "dayjs";
import {getAccessToken} from "../../utils/doToken";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})

export const ContainerShippingOrderDetailScheduleAndPorts = ({orderInfo, getContainerShippingOrderInfo}) => {

    const classes = useStyles();

    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [displayDischargePort, setDisplayDischargePort] = useState(null);
    const [dischargePortList, setDischargePortList] = useState([]);
    const [displayLoadingPort, setDisplayLoadingPort] = useState(null);
    const [loadingPortList, setLoadingPortList] = useState([]);
    const [scheduleAndPort, setScheduleAndPort] = useState({
        ETD: null,
        ETA: null,
        loadingPort: "",
        dischargePort: ""
    });
    const [originalScheduleAndPort, setOriginalScheduleAndPort] = useState({
        ETD: null,
        ETA: null,
        loadingPort: "",
        dischargePort: ""
    });

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const searchOceanPort = async (searchString) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/oceanPorts/searchOceanPorts`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString,
                    page: 0,
                    pageSize: 10
                }
            })
            console.log('[searchOceanPort] result', result);
            return result?.data?.result;
        } catch (e) {

        }
    }

    const handleChangeDischargePort = (event, value) => {
        console.log(value);
        if (value) {
            setScheduleAndPort(prevState => ({
                ...prevState,
                dischargePort: value?.port_code
            }))
            setDisplayDischargePort(value);
        } else {
            setScheduleAndPort(prevState => ({
                ...prevState,
                dischargePort: null
            }));
            setDisplayDischargePort(null);
            setDischargePortList([]);
        }
    };

    const handleInputChangeDischargePort = async (event, value) => {
        console.log('value', value);
        setScheduleAndPort(prevState => ({
            ...prevState,
            dischargePort: value
        }));
        if (value) {
            const result = await searchOceanPort(value);
            setDischargePortList(result);
        }
    }

    const handleChangeLoadingPort = (event, value) => {
        console.log(value);
        if (value) {
            setScheduleAndPort(prevState => ({
                ...prevState,
                loadingPort: value?.port_code
            }))
            setDisplayLoadingPort(value);
        } else {
            setScheduleAndPort(prevState => ({
                ...prevState,
                loadingPort: null
            }));
            setDisplayLoadingPort(null);
            setLoadingPortList([]);
        }
    };

    const handleInputChangeLoadingPort = async (event, value) => {
        console.log('value', value);
        setScheduleAndPort(prevState => ({
            ...prevState,
            loadingPort: value
        }));
        if (value) {
            const result = await searchOceanPort(value);
            setLoadingPortList(result);
        }
    }

    const handleETD = (value) => {
        setScheduleAndPort(prevState => ({
            ...prevState,
            ETD: value
        }))
    }

    const handleETA = (value) => {
        setScheduleAndPort(prevState => ({
            ...prevState,
            ETA: value
        }))
    }

    const handleUpdateContainerShippingOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${PARTNER_URI}/oceanShipping/editOceanBackendOrderByOrderId`;

            let data = {
                backendOrderId: orderInfo?.orderId,
                ETD: dayjs(scheduleAndPort?.ETD)?.format('YYYY-MM-DD'),
                ETA: dayjs(scheduleAndPort?.ETA)?.format('YYYY-MM-DD'),
                portOfLoading: scheduleAndPort?.loadingPort,
                portOfDischarge: scheduleAndPort?.dischargePort,
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Container Shipping Order');
            handleSuccessMessage();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Container Shipping Order');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (orderInfo) {
            setScheduleAndPort(prevState => ({
                ...prevState,
                ETD: orderInfo?.ETD,
                ETA: orderInfo?.ETA,
                loadingPort: orderInfo?.portOfLoading,
                dischargePort: orderInfo?.portOfDischarge
            }));
            setOriginalScheduleAndPort(prevState => ({
                ...prevState,
                ETD: orderInfo?.ETD,
                ETA: orderInfo?.ETA,
                loadingPort: orderInfo?.portOfLoading,
                dischargePort: orderInfo?.portOfDischarge
            }));
            setDisplayLoadingPort(prevState => ({
                ...prevState,
                port_code: orderInfo?.portOfLoading,
                port_en_name: orderInfo?.portOfLoadingName
            }))
            setDisplayDischargePort(prevState => ({
                ...prevState,
                port_code: orderInfo?.portOfDischarge,
                port_en_name: orderInfo?.portOfDischargeName
            }))
        }
    }, [orderInfo]);

    console.log('[ContainerShippingOrderDetailScheduleAndPorts] orderInfo', orderInfo);
    console.log('[ContainerShippingOrderDetailScheduleAndPorts] scheduleAndPort', scheduleAndPort);
    console.log('[ContainerShippingOrderDetailScheduleAndPorts] originalScheduleAndPort', originalScheduleAndPort);
    console.log('[ContainerShippingOrderDetailScheduleAndPorts] ETD', dayjs(scheduleAndPort?.ETD).format('YYYY-MM-DD'));
    console.log('[ContainerShippingOrderDetailScheduleAndPorts] original ETD', originalScheduleAndPort?.ETD);

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Schedule And Port Information
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    ETD
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={scheduleAndPort?.ETD}
                                        onChange={handleETD}
                                        renderInput={(props) =>
                                            <TextField
                                                {...props}
                                                size='small'
                                                fullWidth
                                            />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    ETA
                                </FormLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        value={scheduleAndPort?.ETA}
                                        onChange={handleETA}
                                        renderInput={(props) =>
                                            <TextField
                                                {...props}
                                                size='small'
                                                fullWidth
                                            />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Port of Loading
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={displayLoadingPort}
                                    options={loadingPortList}
                                    getOptionLabel={option => `${option?.port_code}`}
                                    filterOptions={(options, state) => options}
                                    fullWidth
                                    renderInput={(params) => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                    />}
                                    onInputChange={handleInputChangeLoadingPort}
                                    onChange={handleChangeLoadingPort}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '14px'}}
                                        >
                                            {option?.port_code} - {option?.port_en_name}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Port of Discharge
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={displayDischargePort}
                                    options={dischargePortList}
                                    getOptionLabel={option => `${option?.port_code}`}
                                    filterOptions={(options, state) => options}
                                    fullWidth
                                    renderInput={(params) => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                    />}
                                    onInputChange={handleInputChangeDischargePort}
                                    onChange={handleChangeDischargePort}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '14px'}}
                                        >
                                            {option?.port_code} - {option?.port_en_name}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {
                            (
                                (dayjs(scheduleAndPort?.ETA)?.format('YYYY-MM-DD') !== originalScheduleAndPort?.ETA) ||
                                (dayjs(scheduleAndPort?.ETD)?.format('YYYY-MM-DD') !== originalScheduleAndPort?.ETD) ||
                                (scheduleAndPort?.loadingPort?.split(' - ')[0] !== originalScheduleAndPort?.loadingPort) ||
                                (scheduleAndPort?.dischargePort?.split(' - ')[0] !== originalScheduleAndPort?.dischargePort)
                            ) &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleUpdateContainerShippingOrder}
                                        loading={loading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}