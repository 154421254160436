import {useEffect, useState} from "react";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG, generateRandomString} from "../../utils/Helper";
import {Button, Grid, Stack, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {LTLQuickQuotePalletEach} from "../LTLQuoteList/LTLQuickQuotePalletEach";
import {MovingOrderCustomizedItemEach} from "./MovingOrderCustomizedItemEach";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const MovingOrderCustomizedItemList = ({cbHandleGetCustomizedItemList, isContinuePlaceOrder}) => {

    const [metric, setMetric] = useState(false);
    const [itemList, setItemList] = useState([]);

    const handleMetric = (event) => {
        setMetric(event.target.checked)
    }

    const handleAddItem = () => {

        const itemId = generateRandomString(8);

        const newItemGeneral = {
            description: null,
            category: null,
            type: 'item',
            boxSize: null,
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            volume: 0,
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            quantity: 1,
            cate_id: 0,
            categoryInfo: null,
            itemId: itemId,
            packageSpecialServices: {
                wrappingService: false,
                packingService: false,
                assemblyService: false,
                disassemblyService: false
            }
        }
        setItemList(prevState => [...prevState, newItemGeneral]);
    }

    const handleUpdateItem = (itemData) => {
        const updatedItemList = itemList.map(item => {
            if (item?.itemId === itemData?.itemId) {
                return {...itemData}
            }
            return item
        });
        setItemList(updatedItemList);
    }

    const handleDeleteItem = (itemData) => {
        const itemIdToFind = itemData?.itemId;
        const filteredItemList = itemList?.filter(item => item?.itemId !== itemIdToFind);
        setItemList(filteredItemList);
    }

    useEffect(() => {
        const updatedItemList = itemList.map(item => {
            if (metric) {
                return {
                    ...item,
                    lengthUnit: 'cm',
                    weightUnit: 'kg',
                    weight: convertLBToKG(item?.weight),
                    length: convertINToCM(item?.length),
                    width: convertINToCM(item?.width),
                    height: convertINToCM(item?.height),
                }
            } else {
                return {
                    ...item,
                    lengthUnit: 'in',
                    weightUnit: 'lb',
                    weight: convertKGToLB(item?.weight),
                    length: convertCMToIN(item?.length),
                    width: convertCMToIN(item?.width),
                    height: convertCMToIN(item?.height),
                }
            }
        });
        setItemList(updatedItemList);
    }, [metric]);

    useEffect(() => {
        cbHandleGetCustomizedItemList(itemList);
    }, [itemList]);

    console.log('[MovingOrderCustomizedItemList] itemList', itemList);

    return (
        <Grid item xs={12}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Customized Item List
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <AntSwitch
                                    checked={metric}
                                    onChange={handleMetric}
                                    disabled={isContinuePlaceOrder}
                                />
                                <Typography>
                                    Metric (cm/kg)
                                </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <IconButton
                                    sx={{color: '#609966'}}
                                    onClick={handleAddItem}
                                    disabled={isContinuePlaceOrder}
                                >
                                    <AddCircleIcon/>
                                </IconButton>
                                <Typography>
                                    Add New Item
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    {
                        itemList?.length > 0 ? itemList?.map((each, index) => (
                            <Grid item xs={12} key={index}>
                                <MovingOrderCustomizedItemEach
                                    item={each}
                                    handleUpdateItem={handleUpdateItem}
                                    handleDeleteItem={handleDeleteItem}
                                    metric={metric}
                                    isContinuePlaceOrder={isContinuePlaceOrder}

                                />
                            </Grid>
                        )) : <Grid item xs={12}>
                            <Box>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    size='small'
                                    disabled={isContinuePlaceOrder}
                                    onClick={handleAddItem}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Add New Item
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}