import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider, FormControlLabel,
    Grid, Snackbar,
    Typography
} from "@mui/material";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {Alert} from "@mui/lab";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import Checkbox from "@mui/material/Checkbox";

export const PackageOrderEach = ({orderData, showTracking, cbHandleShowTracking}) => {

    const history = useHistory();
    const storedToken = getAccessToken("access_token");

    const [trackingNumbers, setTrackingNumbers] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [forceCancel, setForceCancel] = useState(false);
    const [originalOrder, setOriginalOrder] = useState(null);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleTracking = (orderId) => {
        orderId === showTracking ? cbHandleShowTracking(null) : cbHandleShowTracking(orderId);
    }

    const handleDetails = () => {
        history.push({
            pathname: `/v2/package-orders/${orderData?.order_id}`,
            state: {orderData: orderData}
        });
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleCancelOrder = () => {
        handleDialogOpen();
    }

    const cancelOrderByOrderId = async () => {

        setCancelLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/looseItem/admin/cancelShippingOrder`;

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                data: {
                    orderId: orderData?.order_id,
                    forceCancel
                }
            })

            console.log('[cancelOrderByOrderId] result', result);
            // if (result?.data?.)
            setErrorMessage("");
            if (result?.data?.failList?.length === 0 && result?.data?.successList?.length > 0) {
                setErrorMessage("");
            } else if (result?.data?.successList?.length === 0) {
                setErrorMessage(`The following transactions cannot be cancelled: transaction IDs ${result?.data?.failList}`);
            } else {
                setErrorMessage(`The following transactions has been cancelled: transaction IDs ${result?.data?.successList}, and The following transactions cannot be cancelled: transaction IDs ${result?.data?.failList}`);
            }
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(
                e?.response?.data?.errorMessage || "Error!"
            );
        } finally {
            setCancelLoading(false);
        }
    }

    const confirmCancelOrder = async (e) => {
        e.preventDefault();
        await cancelOrderByOrderId();
        handleToastClick();
        handleDialogClose();
    };

    const handleForceCancel = (event) => {
        setForceCancel(event?.target?.checked);
    }

    const getAdjustOrderDetails = async () => {
        let requestURL = `${PARTNER_URI}/invoices/admin/getPartnerShippingAdjustOrderDetailsByShippingOrderId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    orderId: orderData?.order_id
                }
            });

            setOriginalOrder(result?.data?.trackings[0]?.originalShippingOrderInfo);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    useEffect(() => {
        (async () => {
            const trackingNumbers = [];
            orderData?.transactions?.forEach((transaction) => {
                transaction?.trackings?.forEach((tracking) => {
                    trackingNumbers.push(tracking.tracking_no);
                });
            });
            setTrackingNumbers(trackingNumbers);
            orderData?.is_adjust_charge && await getAdjustOrderDetails();
        })()
    }, [orderData]);

    // console.log('[PackageOrderEach] orderData', orderData);
    // console.log('[PackageOrderEach] trackingNumbers', trackingNumbers);
    // console.log('[PackageOrderEach] force cancel', forceCancel);

    return (
        <Grid container spacing={1}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Cancel Order"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Order can't be cancelled!
                                <hr/>
                                Error: {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            Order has been cancelled!
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Cancel Order
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography>
                                    This process will cancel the order.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={forceCancel}
                                        onChange={handleForceCancel}
                                    />}
                                    label={
                                        <Typography>
                                            Force Cancel
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{fontSize: '14px'}}>
                                    Selecting the "Force Cancel" option will immediately cancel the order,
                                    regardless of its current status in the shipping process.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    Click Confirm to cancel this shipping.
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        No
                    </Button>
                    <LoadingButton
                        onClick={confirmCancelOrder}
                        variant="outlined"
                        type="button"
                        disabled={cancelLoading}
                        loading={cancelLoading}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Grid item xs={2}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {orderData?.order_id}
                            </Typography>
                            <Typography style={{
                                fontSize: '14px',
                                textAlign: 'left',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}>
                                {orderData?.ref_order_no}
                            </Typography>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {dayjs(orderData?.created_at).format("YYYY-MM-DD")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {orderData?.partner_id}
                            </Typography>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {+orderData?.owner_role === 1 ? 'Enduser' : 'Partner'}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                {+orderData?.owner_role === 1 ? `${orderData?.firstname} ${orderData?.lastname}` : orderData?.business_name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {
                                !orderData?.is_adjust_charge ?
                                    <>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            {orderData?.send_from_name}
                                        </Typography>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            {orderData?.send_from_city} {orderData?.send_from_province}
                                        </Typography>
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            {orderData?.send_from_postal_code} {orderData?.send_from_country}
                                        </Typography>
                                    </>
                                    :
                                    originalOrder ?
                                        <>
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                {originalOrder?.send_from_name}
                                            </Typography>
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                {originalOrder?.send_from_city} {originalOrder?.send_from_province}
                                            </Typography>
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                {originalOrder?.send_from_postal_code} {originalOrder?.send_from_country}
                                            </Typography>
                                        </>
                                        :
                                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                            N/A
                                        </Typography>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {
                                orderData?.isMultipleAddress ?
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        Multiple Address
                                    </Typography> :
                                    !orderData?.is_adjust_charge ?
                                        <>
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                {orderData?.send_to_name}
                                            </Typography>
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                {orderData?.send_to_city} {orderData?.send_to_province}
                                            </Typography>
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                {orderData?.send_to_postal_code} {orderData?.send_to_country}
                                            </Typography>
                                        </>
                                        :
                                        originalOrder ?
                                            <>
                                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                    {originalOrder?.send_to_name}
                                                </Typography>
                                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                    {originalOrder?.send_to_city} {originalOrder?.send_to_province}
                                                </Typography>
                                                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                    {originalOrder?.send_to_postal_code} {originalOrder?.send_to_country}
                                                </Typography>
                                            </>
                                            :
                                            <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                                N/A
                                            </Typography>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: +orderData?.order_status === 1 ? '#1D8B45' : '#FF0303'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {(+orderData?.order_status) === 1 ? 'Active' : 'Inactive'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {orderData?.isCrossBorder ? 'International' : 'Domestic'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontSize: '14px'}}>
                    {orderData?.account_base}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontSize: '14px'}}>
                    $ {orderData?.total_amount_include_tax}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    gap: '5px',
                    // width: '80%'
                }}>
                    <Button
                        sx={{
                            "&:hover": {
                                filter: 'brightness(0.9)'
                            }
                        }}
                        size='small'
                        disabled={!(+orderData?.order_status === 1)}
                        onClick={() => handleTracking(orderData?.order_id)}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: +orderData?.order_status === 1 ? '#1D8B45' : '#B2B2B2'
                        }}>
                            Tracking Number
                        </Typography>
                    </Button>
                    <Button
                        sx={{
                            "&:hover": {
                                filter: 'brightness(0.9)'
                            }
                        }}
                        size='small'
                        // disabled={!(+orderData?.order_status === 1)}
                        onClick={handleDetails}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45'
                        }}>
                            Details
                        </Typography>
                    </Button>
                    <Button
                        sx={{
                            "&:hover": {
                                filter: 'brightness(0.9)'
                            }
                        }}
                        size='small'
                        disabled={!(+orderData?.order_status === 1)}
                        onClick={handleCancelOrder}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: +orderData?.order_status === 1 ? '#FF0303' : '#B2B2B2'
                        }}>
                            Cancel
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            {
                showTracking === orderData?.order_id &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        flexWrap: 'wrap'
                    }}>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Tracking Numbers:
                        </Typography>
                        {
                            trackingNumbers?.map((tracking, index) => (
                                <Typography style={{fontSize: '14px', textAlign: 'left'}} key={index}>
                                    {tracking}
                                </Typography>
                            ))
                        }
                    </Box>
                </Grid>
            }
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
        </Grid>
    )
}