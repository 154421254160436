import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export const MovingOrderDetailMovingFrom = ({orderInfo, additionalData}) => {

    console.log('[MovingOrderDetailMovingFrom] additionalData', additionalData);

    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Moving From
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.pickup_address}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.pickup_area}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.send_from_country}, {orderInfo?.pickup_postal_code}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Property Type: {additionalData?.movingFromAddress?.propertyType?.houseType}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            Elevator: {additionalData?.movingFromAddress?.lift ? 'Yes' : 'No'}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            Floor Level: {additionalData?.movingFromAddress?.floorLevel}
                        </Typography>
                        {/*<Typography sx={{textAlign: 'left'}}>*/}
                        {/*    Distance from Truck to Door: {additionalData?.movingFromAddress?.distanceFromTruckToDoor}*/}
                        {/*</Typography>*/}
                        {/*<Typography sx={{textAlign: 'left'}}>*/}
                        {/*    Narrow Street: {additionalData?.movingFromAddress?.isNarrowStreet ? 'Yes' : 'No'}*/}
                        {/*</Typography>*/}
                        {/*<Typography sx={{textAlign: 'left'}}>*/}
                        {/*    Steep Driveway: {additionalData?.movingFromAddress?.isSteepDriveway ? 'Yes' : 'No'}*/}
                        {/*</Typography>*/}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}