import React, {useEffect, useState} from "react";
// import { useSelector } from "react-redux";
import {
  // TextField,
  Box,
} from "@material-ui/core";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
// import { makeStyles } from "@material-ui/core/styles";
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { Alert } from '@material-ui/lab';

import PropTypes from "prop-types";
// import { CustomInput } from '../shared';
// import { defaultShippingItem } from "../../slices";
// import { shipmentTypesDetails } from "../shared/constInfo";
import ShippingItemInput from "./components/shippingItemInput";
import ShippingItemEditor from "./components/shippingItemEditor";

// import nextId from "react-id-generator";
import "../../styles/shippingItem.css";

const ShippingItem = ({
  // backToThird,
  // toSeventh,
  shippingItems,
  setShippingItems,
  itemInfo,
  setEmtpyItemList,
  setSelectedLooseItem,
  selectedLooseItem,
  palletContainerLoose,
  setPalletContainerLoose,
}) => {
  // const dispatch = useDispatch();
  // const shippingItems = useSelector((state) => state.shipping.shippingItems);

  const [errorMsg, setErrorMsg] = useState("");
  // const [showError, setShowErrorState] = useState(false);
  // const classes = useStyles();

  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  useEffect(() => {
    console.log("shipping items: ", shippingItems);
  }, [shippingItems])

  const [orderIndex, setOrderIndex] = useState(0);

  const showPallets = ({ itemInfo }) => {
    return shippingItems?.map((item, i) => {
      return (
        <ShippingItemEditor
          key={item.selectedTypeID}
          item={item}
          setShippingItems={setShippingItems}
          index={i}
          setSelectedLooseItem={setSelectedLooseItem}
          palletContainerLoose={palletContainerLoose}
          setPalletContainerLoose={setPalletContainerLoose}
          itemInfo={itemInfo}
        />
      );
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      // heigh="100%"
      m="0 3vw"
    >
      <Box display="flex" flexDirection="column">
        <Box color="orange" mb="1vh">
          <h1>What items are you shipping?</h1>
        </Box>
        <Box mb="2vh" display="flex" flexDirection="column">
          {showPallets({ itemInfo })}
        </Box>
        <ShippingItemInput
          edit={false}
          // item={shippingItem}
          setEmtpyItemList={setEmtpyItemList}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          setShippingItems={setShippingItems}
          item={itemInfo}
          setSelectedLooseItem={setSelectedLooseItem}
          selectedLooseItem={selectedLooseItem}
          palletContainerLoose={palletContainerLoose}
          setPalletContainerLoose={setPalletContainerLoose}
          editShippingItem={itemInfo}
        />

        {/* <Box alignSelf="center" mt={2}>
          <IconButton onClick={backToThird} className={classes.backButton}>
            <ArrowBackIos
              viewBox="-6 0 24 24"
              style={{ fontSize: "160%", fill: "green" }}
            />
          </IconButton>{" "}
          <IconButton id="iconbuttonarrow" onClick={toSeventh}>
            <ArrowForwardIos style={{ fontSize: "160%", fill: "white" }} />
          </IconButton>
        </Box> */}
      </Box>
    </Box>
  );
};

// const theme = createMuiTheme({
//     palette: {
//         primary: { light: orange[300], main: orange[500], dark: orange[700] }
//     }
// })

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: "20px",
//     padding: theme.spacing(2, 4, 3),
//     overflow: "hidden",
//   },
//   input: {
//     color: "grey",
//     width: "90%",
//   },
//   label: {
//     flexDirection: "column",
//   },
//   icon: {
//     fontSize: "32px !important",
//     marginBottom: theme.spacing(1),
//   },
//   list: {
//     width: "100%",
//     backgroundColor: theme.palette.background.paper,
//     boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
//   },
//   backButton: {
//     marginRight: theme.spacing(4),
//     backgroundColor: "white",
//   },
//   subTitle: {
//     color: "darkgreen",
//     fontWeight: 600,
//     fontSize: "1.2rem",
//     fontFamily: "Montserrat",
//   },
// }));

ShippingItem.propTypes = {
  backToThird: PropTypes.func.isRequired,
  toSeventh: PropTypes.func.isRequired,
  shippingItems: PropTypes.array.isRequired,
  setShippingItems: PropTypes.func.isRequired,
  itemInfo: PropTypes.object,
};

export default ShippingItem;
