import {Fragment, useEffect, useState} from "react";
import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormalWithUnit, CustomTextFieldNumber} from "../../utils/customComponent";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardShippingCost = ({
                                                  getDataFromChildComponent,
                                                  triggerAction,
                                                  isConfirm,
                                                  orderData,
                                                  crmInvoiceNumber,
                                                  getOrderDetails,
                                                  shippingRequest,
                                                  getShippingCostData
                                              }) => {

    const [shippingFee, setShippingFee] = useState('');
    const [serviceFee, setServiceFee] = useState('');
    const [taxes, setTaxes] = useState('');
    const [subtotal, setSubTotal] = useState('');
    const [discount, setDiscount] = useState('');
    const [total, setTotal] = useState('');
    const [isGenerateInvoice, setIsGenerateInvoice] = useState(false);

    const [originalShippingFee, setOriginalShippingFee] = useState('');
    const [originalServiceFee, setOriginalServiceFee] = useState('');
    const [originalTaxes, setOriginalTaxes] = useState('');
    const [originalSubtotal, setOriginalSubTotal] = useState('');
    const [originalDiscount, setOriginalDiscount] = useState('');
    const [originalTotal, setOriginalTotal] = useState('');

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'shippingFee':
                setShippingFee(+value);
                break;
            case 'serviceFee':
                setServiceFee(+value);
                break;
            case 'taxes':
                setTaxes(+value);
                break;
            case 'discount':
                setDiscount(+value);
                break;
            default:
        }
    }

    const handleGenerateInvoice = (event) => {
        setIsGenerateInvoice(event?.target?.checked);
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    shippingFee: (+shippingFee),
                    serviceFee: (+serviceFee),
                    taxCharge: (+taxes),
                    totalCharge: (+subtotal),
                    discount: (+discount),
                    finalTotal: (+total),
                    // generateInvoice: isGenerateInvoice
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalShippingFee(+shippingFee);
            // setOriginalServiceFee(+serviceFee);
            // setOriginalTaxes(+taxes);
            // setOriginalDiscount(+discount);
            // setIsGenerateInvoice(false);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        const subtotalCost = +shippingFee + +serviceFee - +discount;
        setSubTotal(subtotalCost.toFixed(2));
    }, [shippingFee, serviceFee, discount]);

    useEffect(() => {
        const totalCost = +subtotal + +taxes;
        setTotal(totalCost.toFixed(2));
    }, [taxes, subtotal])

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                shippingCost: {
                    shippingFee,
                    serviceFee,
                    taxCharge: taxes,
                    totalCharge: subtotal,
                    discount,
                    finalTotal: total
                }
            });
        }
    }, [triggerAction])

    useEffect(() => {
        setShippingFee(orderData?.prices?.shipping_fee);
        setServiceFee(orderData?.prices?.service_fee);
        setTaxes(orderData?.prices?.taxes);
        setDiscount(orderData?.prices?.discount);
        setOriginalShippingFee(orderData?.prices?.shipping_fee);
        setOriginalServiceFee(orderData?.prices?.service_fee);
        setOriginalTaxes(orderData?.prices?.taxes);
        setOriginalDiscount(orderData?.prices?.discount);
    }, [orderData])

    useEffect(() => {
        setIsGenerateInvoice(false);
    }, [crmInvoiceNumber])

    useEffect(() => {
        if (shippingRequest) {
            getShippingCostData({
                shippingFee,
                serviceFee,
                taxCharge: taxes,
                totalCharge: subtotal,
                discount,
                finalTotal: total
            })
        }
    },[shippingRequest])

    console.log('[LTLOrderDashboardShippingCost] shippingRequest', shippingRequest);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Shipping Fee <span style={{fontSize: '12px'}}>(include special service fee)</span>
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextFieldNormalWithUnit
                            textValue={shippingFee}
                            field='shippingFee'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Service Fee
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextFieldNormalWithUnit
                            textValue={serviceFee}
                            field='serviceFee'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Discount
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextFieldNormalWithUnit
                            textValue={discount}
                            field='discount'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Subtotal
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            value={subtotal}
                            size='small'
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Taxes
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextFieldNormalWithUnit
                            textValue={taxes}
                            field='taxes'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Final Total
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            value={total}
                            size='small'
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <Box sx={{*/}
            {/*        display: 'flex',*/}
            {/*        flexDirection: 'column',*/}
            {/*        alignItems: 'flex-start'*/}
            {/*    }}>*/}
            {/*        <FormControlLabel*/}
            {/*            value="start"*/}
            {/*            control={<Checkbox*/}
            {/*                checked={isGenerateInvoice}*/}
            {/*                onChange={handleGenerateInvoice}*/}
            {/*            />}*/}
            {/*            label={*/}
            {/*                <Typography>*/}
            {/*                    Generate Invoice*/}
            {/*                </Typography>*/}
            {/*            }*/}
            {/*            labelPlacement="end"*/}
            {/*            disabled={crmInvoiceNumber || isConfirm}*/}
            {/*        />*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
            {
                (
                    shippingFee !== originalShippingFee ||
                    serviceFee !== originalServiceFee ||
                    discount !== originalDiscount ||
                    taxes !== originalTaxes ||
                    isGenerateInvoice
                ) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}