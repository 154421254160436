import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import HSCodeSectionListItem from "./HSCodeSectionListItem";

const useStyles = makeStyles((theme) => ({
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "400px",
  },
  idTH: {
    width: "100px",
  },
}));

export default function HSSectionList({ data, listIndex }) {
  const classes = useStyles();
  const selectedTR = useRef({});
  const handleTableRowClick = (eleId, tableIndex) => {
    selectElement(eleId, tableIndex);
  };

  const selectElement = (_id, _listIndex) => {
    if (!selectedTR.current[_listIndex]) {
      document.getElementById(_id).parentElement.style.background = "aliceblue";
    } else {
      //remove selected tr's background
      const selectedEle = document.getElementById(
        selectedTR.current[_listIndex]
      );
      if (selectedEle) {
        selectedEle.parentElement.style.background = "";
      }
      document.getElementById(_id).parentElement.style.background = "aliceblue";
    }
    selectedTR.current[_listIndex] = _id;
  };

  return (
    <div className={classes.hscodeListWrapper}>
      {data.type === "section" ? (
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.idTH}>Section</th>
              <th className={classes.nameTH}>Name</th>
              <th>Simplified Chinese</th>
              <th>Tradictional Chinese</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {data.data.map((s, index) => {
              return (
                <HSCodeSectionListItem
                  data={s}
                  key={index}
                  type={data.type}
                  listIndex={listIndex}
                  tableRowClick={(eleId, tableIndex) =>
                    handleTableRowClick(eleId, tableIndex)
                  }
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.idTH}>HS Code</th>
              <th className={classes.nameTH}>Name</th>
              <th>Simplified Chinese</th>
              <th>Tradictional Chinese</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody className={classes.tbody}>
            {data.data.map((s, index) => {
              return (
                <HSCodeSectionListItem
                  data={s}
                  key={index}
                  type={data.type}
                  listIndex={listIndex}
                  tableRowClick={(eleId, tableIndex) =>
                    handleTableRowClick(eleId, tableIndex)
                  }
                />
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}
