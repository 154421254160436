import {FormLabel, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useState, useEffect} from "react";
import dayjs from 'dayjs';
import {
    LocalizationProvider,
    MobileDatePicker,
} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})

export const ContainerShippingScheduleAndPorts = ({cbHandleScheduleAndPort}) => {

    const classes = useStyles();

    const [displayDischargePort, setDisplayDischargePort] = useState(null);
    const [dischargePortList, setDischargePortList] = useState([]);
    const [displayLoadingPort, setDisplayLoadingPort] = useState(null);
    const [loadingPortList, setLoadingPortList] = useState([]);
    const [scheduleAndPort, setScheduleAndPort] = useState({
        ETD: null,
        ETA: null,
        loadingPort: "",
        dischargePort: ""
    });

    const searchOceanPort = async (searchString) => {
        let requestURL = `${PARTNER_URI}/elasticsearch/oceanPorts/searchOceanPorts`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString,
                    page: 0,
                    pageSize: 10
                }
            })
            console.log('[searchOceanPort] result', result);
            return result?.data?.result;
        } catch (e) {

        }
    }

    const handleChangeDischargePort = (event, value) => {
        console.log(value);
        if (value) {
            setScheduleAndPort(prevState => ({
                ...prevState,
                dischargePort: value?.port_code
            }))
            setDisplayDischargePort(value);
        } else {
            setScheduleAndPort(prevState => ({
                ...prevState,
                dischargePort: null
            }));
            setDisplayDischargePort(null);
            setDischargePortList([]);
        }
    };

    const handleInputChangeDischargePort = async (event, value) => {
        console.log('value', value);
        setScheduleAndPort(prevState => ({
            ...prevState,
            dischargePort: value
        }));
        if (value) {
            const result = await searchOceanPort(value);
            setDischargePortList(result);
        }
    }

    const handleChangeLoadingPort = (event, value) => {
        console.log(value);
        if (value) {
            setScheduleAndPort(prevState => ({
                ...prevState,
                loadingPort: value?.port_code
            }))
            setDisplayLoadingPort(value);
        } else {
            setScheduleAndPort(prevState => ({
                ...prevState,
                loadingPort: null
            }));
            setDisplayLoadingPort(null);
            setLoadingPortList([]);
        }
    };

    const handleInputChangeLoadingPort = async (event, value) => {
        console.log('value', value);
        setScheduleAndPort(prevState => ({
            ...prevState,
            loadingPort: value
        }));
        if (value) {
            const result = await searchOceanPort(value);
            setLoadingPortList(result);
        }
    }

    const handleETD = (value) => {
        setScheduleAndPort(prevState => ({
            ...prevState,
            ETD: value
        }))
    }

    const handleETA = (value) => {
        setScheduleAndPort(prevState => ({
            ...prevState,
            ETA: value
        }))
    }

    useEffect(() => {
        cbHandleScheduleAndPort(scheduleAndPort);
    }, [scheduleAndPort]);

    console.log('[ContainerShippingScheduleAndPorts] scheduleAndPort', scheduleAndPort);

    return (
        <Grid item xs={12}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Schedule And Port Information
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                ETD
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    value={scheduleAndPort?.ETD}
                                    onChange={handleETD}
                                    renderInput={(props) =>
                                        <TextField
                                            {...props}
                                            size='small'
                                            fullWidth
                                        />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                ETA
                            </FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    value={scheduleAndPort?.ETA}
                                    onChange={handleETA}
                                    renderInput={(props) =>
                                        <TextField
                                            {...props}
                                            size='small'
                                            fullWidth
                                        />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Port of Loading
                            </FormLabel>
                            <Autocomplete
                                freeSolo
                                value={displayLoadingPort}
                                options={loadingPortList}
                                getOptionLabel={option => `${option?.port_code} - ${option?.port_en_name}`}
                                filterOptions={(options, state) => options}
                                fullWidth
                                renderInput={(params) => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                />}
                                onInputChange={handleInputChangeLoadingPort}
                                onChange={handleChangeLoadingPort}
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '14px'}}
                                    >
                                        {option?.port_code} - {option?.port_en_name}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Port of Discharge
                            </FormLabel>
                            <Autocomplete
                                freeSolo
                                value={displayDischargePort}
                                options={dischargePortList}
                                getOptionLabel={option => `${option?.port_code} - ${option?.port_en_name}`}
                                filterOptions={(options, state) => options}
                                fullWidth
                                renderInput={(params) => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                />}
                                onInputChange={handleInputChangeDischargePort}
                                onChange={handleChangeDischargePort}
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '14px'}}
                                    >
                                        {option?.port_code} - {option?.port_en_name}
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}