import {makeStyles} from "@material-ui/core/styles";
import {Fragment, useEffect, useState} from "react";
import {Button, Grid, Link, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ClaimDashboardFileDetails = ({
                                              fileData,
                                              index,
                                              handleRemoveFileList,
                                              handleUpdateFileList,
                                              validation
                                          }) => {

    const classes = useStyles();

    const fileTypeList = ["Purchase Receipt", "Sale Receipt", "Screenshot", "Photo"];
    const [fileDetails, setFileDetails] = useState(fileData);

    const handleRemove = () => {
        handleRemoveFileList(fileDetails);
    }

    const handleInputChangeFileType = async (event, value) => {
        setFileDetails(prevState => ({
            ...prevState,
            file_type: value
        }))
    }

    const handleChangeFileType = (event, value) => {
        setFileDetails(prevState => ({
            ...prevState,
            file_type: value
        }))
    };

    useEffect(() => {
        handleUpdateFileList(fileDetails);
    }, [fileDetails]);

    useEffect(() => {
        setFileDetails(fileData);
    }, [fileData?.file_id])

    // console.log('[ClaimDashboardFileDetails] fileDetails', fileDetails);

    return (
        <Fragment>
            <Grid item xs={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography sx={{textAlign: 'left'}}>
                        # {index + 1}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography sx={{textAlign: 'left'}}>
                        {fileData?.origi_file_name}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Autocomplete
                    value={fileData.file_type}
                    freeSolo
                    options={fileTypeList}
                    fullWidth
                    renderInput={(params) => <TextField
                        {...params}
                        error={validation?.fileHasMissing && !fileDetails?.file_type}
                        helperText={(validation?.fileHasMissing && !fileDetails?.file_type) && 'Required'}
                        className={classes.smallInput}
                    />}
                    onInputChange={handleInputChangeFileType}
                    onChange={handleChangeFileType}
                />
            </Grid>
            <Grid item xs={3}>
                <Box sx={{
                    display: 'flex', gap: '10px',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Link href={`${LABEL_DOWNLOAD_URL_PREFIX}${fileData?.file_location_uri}`} target="_blank"
                          rel="noopener noreferrer"
                          style={{color: '#1D8B45', textDecoration: 'none'}}
                    >
                        <Button
                            sx={{color: '#1D8B45'}}
                            startIcon={<VisibilityIcon/>}>
                            <Typography sx={{textTransform: 'none'}}>
                                View
                            </Typography>
                        </Button>
                    </Link>
                    <Button
                        sx={{color: '#FF0303'}}
                        onClick={handleRemove}
                        startIcon={<DeleteIcon/>}>
                        <Typography sx={{textTransform: 'none'}}>
                            Delete
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Fragment>
    )
}