import {Fragment, useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal} from "../../utils/customComponent";

export const CarQuoteItemAllow = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [itemsAllowed, setItemsAllowed] = useState('Tires & Baby Seats');

    const onChange = (field, value) => {
        switch (field) {
            case 'itemsAllowed':
                setItemsAllowed(value);
                break;
            default:
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({itemsAllowed});
        }
    }, [triggerAction])


    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Items allowed inside vehicle
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <CustomTextFieldNormal
                            defaultValue={itemsAllowed}
                            field='itemsAllowed'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
        )
}
