import {Button, FormLabel, Grid, InputAdornment, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {useEffect, useState} from "react";

export const ClaimDashboardItemDetails = ({
                                              itemData,
                                              index,
                                              handleUpdateItemList,
                                              handleRemoveItemList,
                                              validation
                                          }) => {

    const [itemDetails, setItemDetails] = useState(itemData);

    const handleInputDescription = (event) => {
        setItemDetails(prevState => ({
            ...prevState,
            item_desc: event?.target?.value,
            item_name: event?.target?.value
        }))
    }

    const handleInputValue = (event) => {
        setItemDetails(prevState => ({
            ...prevState,
            unit_price: event?.target?.value,
        }))
    }

    const handleInputQuantity = (event) => {
        setItemDetails(prevState => ({
            ...prevState,
            quantity: event?.target?.value,
        }))
    }

    const handleRemove = () => {
        handleRemoveItemList(itemDetails);
    }

    useEffect(() => {
        handleUpdateItemList(itemDetails);
    }, [itemDetails]);

    useEffect(() => {
        setItemDetails(itemData);
    }, [itemData?.id])

    // console.log('[ClaimDashboardItemDetails] itemDetails', itemDetails);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{color: '#1D8B45', fontWeight: '600', textAlign: 'left'}}>
                    Item {index + 1}
                </Typography>
            </Grid>
            <Grid item xs={5.25}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Item Description
                    </FormLabel>
                    <TextField
                        value={itemDetails?.item_desc}
                        fullWidth
                        onInput={handleInputDescription}
                        size='small'
                        variant='outlined'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2.75}>
                <FormControl fullWidth>
                    <FormLabel
                    >
                        Item Value
                    </FormLabel>
                    <TextField
                        value={itemData?.unit_price}
                        fullWidth
                        onInput={handleInputValue}
                        size='small'
                        variant='outlined'
                        type='number'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    CAD
                                </InputAdornment>
                            ),
                            inputProps: {
                                min: 1
                            },
                        }}
                        error={(validation?.itemHasMissing || validation?.itemListError) && (!itemDetails?.unit_price || +itemDetails?.unit_price <= 0)}
                        helperText={(validation?.itemHasMissing || validation?.itemListError) && ((!itemDetails?.unit_price) ? 'Required' : +itemDetails?.unit_price <= 0 && 'Larger than 0')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2.75}>
                <FormControl fullWidth>
                    <FormLabel>
                        Quantity
                    </FormLabel>
                    <TextField
                        value={itemData?.quantity}
                        fullWidth
                        onInput={handleInputQuantity}
                        size='small'
                        variant='outlined'
                        type='number'
                        InputProps={{
                            inputProps: {
                                min: 1
                            },
                        }}
                        error={(validation?.itemHasMissing || validation?.itemListError) && (!itemDetails?.quantity || +itemDetails?.quantity <= 0)}
                        helperText={(validation?.itemHasMissing || validation?.itemListError) && ((!itemDetails?.quantity) ? 'Required' : +itemDetails?.quantity <= 0 && 'Larger than 0')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1.25}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    height: '100%',
                }}>
                    <Button
                        onClick={handleRemove}
                        startIcon={<DeleteIcon sx={{color: '#FF0303'}}/>}
                    >
                        <Typography sx={{textTransform: 'none', color: '#FF0303'}}>
                            Remove
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}