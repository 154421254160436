import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
  link: {
    textDecoration: "none",
  },
}));

const OrderPaymentListItem = ({
  id,
  orderNumber,
  orderType,
  sender,
  senderId,
  status
}) => {
  const classes = useStyles();
  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link
              to={`/v2/orderpayment/backendorder/notpaid/detail/${id}`}>
          {id}
          </Link>
        </td>
        <td>{orderNumber}</td>
        <td>{orderType}</td>
        <td>{sender}</td>
        <td>{senderId}</td>
        <td>{status}</td>
        <td>
          <Box display="inline-flex" sx={{
            alignItems: 'center'
          }} >
            <Link className={classes.link} to={`/v2/orderpayment/backendorder/notpaid/detail/${id}`}>
              <Button
                className={classes.detailBtn}
                color="secondary"
                variant="outlined"
              >
                Details
              </Button>
            </Link>
          {/* <Link className={classes.link} to={`/v2/orderpayment/enduser/notpaid/detail/${id}`}>
            <Button
              className={classes.detailBtn}
              color="secondary"
              variant="outlined"
            >
              Email Transfer
            </Button>
          </Link> */}
          </Box>
        </td>
        {/* <td>
        </td> */}
      </tr>
    </>
  );
};

export default OrderPaymentListItem;
