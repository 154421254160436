import {Button, Divider, FormLabel, Grid, InputAdornment, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";
import {ClaimDashboardItemDetails} from "./ClaimDashboardItemDetails";
import AddIcon from '@mui/icons-material/Add';
import {generateRandomString} from "../../utils/Helper";

export const ClaimDashboardItemList = ({itemListData, resetTrigger, handleItemList, validation}) => {

    const [itemList, setItemList] = useState(itemListData);
    const [totalValue, setTotalValue] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);

    const handleUpdateItemList = (updatedItem) => {
        const updateItemList = itemList?.map(item => {
            if (item?.id === updatedItem?.id) {
                return {...updatedItem}
            }
            return item
        })
        setItemList(updateItemList);
    }

    const handleRemoveItemList = (updatedItem) => {
        const filteredList = itemList?.filter(item => item?.id !== updatedItem?.id);
        setItemList(filteredList);
    }

    const handleAddItem = () => {
        const itemId = generateRandomString(8);
        const newItem = {
            item_desc: "",
            item_name: "",
            unit_price: "",
            quantity: "",
            id: itemId
        }
        setItemList(prevState => [...prevState, newItem]);
    }

    useEffect(() => {
        setItemList(itemListData);
    }, [resetTrigger])

    useEffect(() => {
        setItemList(itemListData);
    }, [itemListData]);

    useEffect(() => {
        const sumValue = itemList?.reduce((acc, each) => {
            return acc + (+each?.unit_price * +each?.quantity)
        }, 0);

        const sumQuantity = itemList?.reduce((acc, each) => {
            return acc + +each?.quantity
        }, 0);

        setTotalValue(sumValue);
        setTotalQuantity(sumQuantity);
        handleItemList(itemList);
    }, [itemList])

    // console.log('[ClaimDashboardItemList] itemListData', itemListData);
    // console.log('[ClaimDashboardItemList] itemList', itemList);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                        Information About the Items
                    </Typography>
                    <Button
                        onClick={handleAddItem}
                        startIcon={<AddIcon sx={{color: '#1D8B45'}}/>}>
                        <Typography sx={{textTransform: 'none', color: '#1D8B45'}}>
                            Add
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            {
                itemList?.length > 0 ? itemList?.map((itemData, index) => (
                        <Grid item xs={12} key={index}>
                            <ClaimDashboardItemDetails
                                key={index}
                                index={index}
                                itemData={itemData}
                                handleUpdateItemList={handleUpdateItemList}
                                handleRemoveItemList={handleRemoveItemList}
                                validation={validation}
                            />
                        </Grid>
                    ))
                    :
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '1px solid #B7B7B7',
                            padding: '20px',
                            borderRadius: '10px'
                        }}>
                            <Typography sx={{fontSize: '20px', fontWeight: '600', color: '#7F8487'}}>
                                Item list is empty
                            </Typography>
                        </Box>
                    </Grid>
            }
            <Grid item xs={5.25}>
            </Grid>
            <Grid item xs={5.5}>
                <Divider/>
            </Grid>
            <Grid item xs={5.25}>
            </Grid>
            <Grid item xs={2.75}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{fontWeight: '600'}}>
                        Total:
                    </Typography>
                    <Typography sx={{fontWeight: '600'}}>
                        ${(+totalValue)?.toFixed(2)} CAD
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={2.75}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <Typography sx={{fontWeight: '600'}}>
                        {totalQuantity}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={1.25}>
            </Grid>
        </Grid>
    )
}