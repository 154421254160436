import {Fragment, useEffect, useState} from "react";
import {FormLabel, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export const LTLOrderListPickupDateAndTime = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [shipDateAndTime, setShipDateAndTime] = useState({
        shipDate: null,
        pickupDate: null,
        availablePickupTime: '12:00',
        earliestPickupTime: '10:00',
        latestPickupTime: '16:00',
    });

    const handlePickupDate = (event) => {
        const inputDate = event.target.value;
        setShipDateAndTime(prevState => ({
            ...prevState,
            pickupDate: inputDate,
            shipDate: inputDate
        }))
    }

    const handlePickupEarliestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            earliestPickupTime: inputTime
        }))
    }

    const handlePickupLatestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            latestPickupTime: inputTime
        }))
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({shipDateAndTime});
        }
    },[triggerAction])

    // console.log('current date and time', shipDateAndTime);

    return (
        <Fragment>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        Shipping Date
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDateAndTime?.pickupDate}
                        onChange={handlePickupDate}
                        size='small'
                        disabled={isConfirm}
                        error={validation?.shipDateAndTimeMissing.includes('pickupDate')}
                        helperText={validation?.shipDateAndTimeMissing.includes('pickupDate') && "Required field"}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        Pickup Earliest Time
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDateAndTime?.earliestPickupTime}
                        onChange={handlePickupEarliestTime}
                        size='small'
                        disabled={isConfirm}
                        error={validation?.shipDateAndTimeMissing.includes('earliestPickupTime')}
                        helperText={validation?.shipDateAndTimeMissing.includes('earliestPickupTime') && "Required field"}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        Pickup Latest Time
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDateAndTime?.latestPickupTime}
                        onChange={handlePickupLatestTime}
                        size='small'
                        disabled={isConfirm}
                        error={validation?.shipDateAndTimeMissing.includes('latestPickupTime')}
                        helperText={validation?.shipDateAndTimeMissing.includes('latestPickupTime') && "Required field"}
                    />
                </Box>
            </Grid>
        </Fragment>
    )
}