import {Button, Divider, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Fragment, useEffect, useRef, useState} from "react";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import {saveAs} from 'file-saver';
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {containerShippingServiceList} from "../../utils/Helper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";
import {getAccessToken} from "../../utils/doToken";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";
import {ContainerShippingOrderDetailServiceAndPriceUpdate} from "./ContainerShippingOrderDetailServiceAndPriceUpdate";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})

export const ContainerShippingOrderDetailServiceAndPrice = ({orderInfo, getContainerShippingOrderInfo}) => {

    const classes = useStyles();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [priceDetails, setPriceDetails] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [serviceName, setServiceName] = useState('');
    const [unitPrice, setUnitPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [tax, setTax] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0)
    const [serviceDesc, setServiceDesc] = useState('');
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [updateService, setUpdateService] = useState(null);
    const [validationResult, setValidationResult] = useState(null);

    const handleConfirmUpdateService = (service) => {
        console.log('[handleConfirmUpdateService] service', service);
        setUpdateService(service);
    }

    const handleCancelUpdateService = () => {
        setUpdateService(null);
    }

    const handleAddService = async () => {
        setIsAdd(true);
    }

    const handleCancelService = () => {
        setIsAdd(false);
        setValidationResult(null);
        setUnitPrice(0);
        setQuantity(1);
        setTax(0);
        setServiceName('');
        setServiceDesc('');
        setTotal(0);
        setSubtotal(0);
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleInputChangeServiceName = (event, value) => {
        setServiceName(value);
    }

    const handleChangeServiceName = (event, value) => {
        setServiceName(value);
    }

    const handleServiceDesc = (event) => {
        setServiceDesc(event?.target?.value);
    }

    const handleUnitPrice = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setUnitPrice(value);
        }
    }

    const handleQuantity = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)$/.test(value)) {
            setQuantity(value);
        }
    }

    const handleTax = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setTax(value);
        }
    }

    const validation = () => {
        setErrorMessage('');
        setToastOpen(false);

        !serviceName && setErrorMessage(prevState => prevState + 'Service name is missing.');
        !unitPrice && setErrorMessage(prevState => prevState + 'Unit price is missing.');
        unitPrice <= 0 && setErrorMessage(prevState => prevState + 'Unit price is smaller than 0.');
        !quantity && setErrorMessage(prevState => prevState + 'Quantity is missing');
        quantity < 1 && setErrorMessage(prevState => prevState + 'Quantity is smaller than 1.');

        setValidationResult(preState => ({
            ...preState,
            serviceNameError: !serviceName,
            unitPriceError: !unitPrice,
            unitPriceInvalid: unitPrice <= 0,
            quantityError: !quantity,
            quantityInvalid: quantity < 1
        }));

        if (!serviceName ||
            !unitPrice ||
            unitPrice <= 0 ||
            !quantity ||
            quantity < 1
            // consigneeMissingKeys?.length > 0 ||
            // shipperPhoneError ||
            // consigneePhoneError
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handleCreateService = async () => {
        setLoadingCreate(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/initOrderPriceDetails`;
        const data = {
            backendOrderId: orderInfo?.orderId,
            unitPrice,
            serviceName,
            finalTotal: total,
            taxes: tax,
            total: subtotal,
            quantity,
            serviceDescription: serviceDesc
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });
            setErrorMessage('');
            setSuccessMessage(`Successfully create "${serviceName}" service.`);
            handleSuccessMessage();
            handleCancelService();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage(`Fail to create "${serviceName}" service.`);
            handleErrorMessage();
        } finally {
            setLoadingCreate(false);
        }
    }

    const handleConfirmCreate = () => {
        const result = validation();
        if (result) {
            handleCreateService();
        }
    }

    useEffect(() => {
        const calculatedSubtotal = ((+unitPrice) * (+quantity)).toFixed(2);
        setSubtotal(calculatedSubtotal);
    }, [unitPrice, quantity]);

    useEffect(() => {
        const calculatedSubtotal = ((+subtotal) + (+tax)).toFixed(2);
        setTotal(calculatedSubtotal);
    }, [subtotal, tax]);

    useEffect(() => {
        if (orderInfo) {
            setPriceDetails(orderInfo?.priceDetails);
        }
    }, [orderInfo]);

    console.log('[ContainerShippingOrderDetailServiceAndPrice] updateService', updateService);
    console.log('[ContainerShippingOrderDetailServiceAndPrice] priceDetails', priceDetails);

    return (
        <Grid item xs={12}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Service And Price
                        </Typography>
                    </Grid>
                    {
                        !isAdd &&
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleAddService}
                                >
                                    <Typography sx={{
                                        textTransform: 'none',
                                    }}>
                                        Add Service
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                            Service Name
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{textAlign: 'left', fontWeight: '600'}}>
                            Service Description
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                            Unit Price
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                            Quantity
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                            Subtotal
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                            Tax
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                            Total
                        </Typography>
                    </Grid>
                    {
                        priceDetails?.length > 0 ?
                            <>
                                {
                                    priceDetails?.map((service, index) => (
                                        <Fragment key={index}>
                                            <Grid item xs={3}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Typography sx={{textAlign: 'left'}}>
                                                    {service?.serviceName}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Typography sx={{textAlign: 'left'}}>
                                                    {service?.serviceDescription}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Typography sx={{textAlign: 'left'}}>
                                                    $ {(+service?.unitPrice)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Typography sx={{textAlign: 'center'}}>
                                                    {service?.quantity}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Typography sx={{textAlign: 'center'}}>
                                                    $ {(+service?.total)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Typography sx={{textAlign: 'center'}}>
                                                    $ {(+service?.taxes)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Typography sx={{textAlign: 'center'}}>
                                                    $ {(+service?.finalTotal)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}
                                                  sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'
                                                  }}>
                                                <Button
                                                    variant='outlined'
                                                    sx={{
                                                        border: '2px solid #1D8B45',
                                                        "&:hover": {
                                                            border: '2px solid #1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                    size='small'
                                                    onClick={() => handleConfirmUpdateService(service)}
                                                >
                                                    <Typography sx={{
                                                        textTransform: 'none',
                                                        fontSize: '14px',
                                                        color: '#1D8B45',
                                                    }}>
                                                        Update
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                            {
                                                (+service?.id === +updateService?.id) &&
                                                <ContainerShippingOrderDetailServiceAndPriceUpdate
                                                    serviceInfo={service}
                                                    handleCancelUpdateService={handleCancelUpdateService}
                                                    getContainerShippingOrderInfo={getContainerShippingOrderInfo}
                                                />
                                            }
                                        </Fragment>
                                    ))
                                }
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography sx={{textAlign: 'right', fontWeight: '600'}}>
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                                        {orderInfo?.quantity}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                                        $ {(+orderInfo?.total)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                                        $ {(+orderInfo?.taxes)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Typography sx={{textAlign: 'center', fontWeight: '600'}}>
                                        $ {(+orderInfo?.finalTotal)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                            :
                            <Grid item xs={12}>
                                <Typography sx={{fontWeight: '600'}}>
                                    No Price Details or Services Selected
                                </Typography>
                            </Grid>
                    }
                    {
                        isAdd &&
                        <>
                            <Grid item xs={3.5}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Service Name
                                    </FormLabel>
                                    <Autocomplete
                                        freeSolo
                                        fullWidth
                                        onInputChange={handleInputChangeServiceName}
                                        onChange={handleChangeServiceName}
                                        options={containerShippingServiceList}
                                        renderInput={(params) => <TextField
                                            {...params}
                                            className={classes.smallInput}
                                            error={validationResult?.serviceNameError}
                                            helperText={validationResult?.serviceNameError && 'Required'}
                                        />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3.5}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{textAlign: 'left'}}>
                                        Service Description
                                    </FormLabel>
                                    <TextField
                                        value={serviceDesc}
                                        onInput={handleServiceDesc}
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Unit Price
                                    </FormLabel>
                                    <TextField
                                        value={unitPrice}
                                        onInput={handleUnitPrice}
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        type='number'
                                        error={validationResult?.unitPriceError || validationResult?.unitPriceInvalid}
                                        helperText={validationResult?.unitPriceError ? 'Required' : validationResult?.unitPriceInvalid && 'Invalid'}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Quantity
                                    </FormLabel>
                                    <TextField
                                        value={quantity}
                                        onInput={handleQuantity}
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        type='number'
                                        error={validationResult?.quantityError || validationResult?.quantityInvalid}
                                        helperText={validationResult?.quantityError ? 'Required' : validationResult?.quantityInvalid && 'Invalid'}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{textAlign: 'left'}}>
                                        Subtotal
                                    </FormLabel>
                                    <TextField
                                        value={subtotal}
                                        disabled={true}
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{textAlign: 'left'}}>
                                        Tax
                                    </FormLabel>
                                    <TextField
                                        value={tax}
                                        onInput={handleTax}
                                        fullWidth
                                        variant="outlined"
                                        size='small'
                                        type='number'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControl fullWidth>
                                    <FormLabel sx={{textAlign: 'left'}}>
                                        Total
                                    </FormLabel>
                                    <TextField
                                        value={total}
                                        disabled={true}
                                        size='small'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    height: '100%'
                                }}>
                                    <Box sx={{
                                        display: 'flex',
                                        gap: '10px'
                                    }}>
                                        <LoadingButton
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                height: '100%',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                },
                                            }}
                                            onClick={handleConfirmCreate}
                                            loading={loadingCreate}
                                        >
                                            <Typography sx={{
                                                textTransform: 'none',
                                            }}>
                                                Create
                                            </Typography>
                                        </LoadingButton>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#454545',
                                                height: '100%',
                                                "&:hover": {
                                                    backgroundColor: '#454545',
                                                    filter: 'brightness(0.9)'
                                                },
                                            }}
                                            onClick={handleCancelService}
                                        >
                                            <Typography sx={{
                                                textTransform: 'none',
                                            }}>
                                                Cancel
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}