import {Container, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {MovingCategoryListEdit} from "./MovingCategoryListEdit";
import {MovingCategoryAreaList} from "./MovingCategoryAreaList";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {MovingCategoryFreightList} from "./MovingCategoryFreightList";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingCategoryListDetails = () => {

    const {cateId} = useParams();

    const [cateInfo, setCateInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [tabValue, setTabValue] = useState('area');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const getCategoryDetails = async () => {
        let requestURL = `${NODE_ROUTE_URI}/movingService/getCategoryById`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    cateId: cateId
                }
            })
            console.log('[MovingCategoryListDetails] getCategoryDetails api - result', result);
            setCateInfo(result?.data);

        } catch (e) {
            console.log('[MovingCategoryListDetails] getCategoryDetails api - error', e?.response);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (cateId) {
            getCategoryDetails();
        }
    }, [cateId]);

    console.log('[MovingCategoryListDetails] cateInfo', cateInfo);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Category Details
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    {
                        cateInfo && <MovingCategoryListEdit cateInfo={cateInfo}/>
                    }
                </Box>
                {
                    cateInfo?.parent_cate_id === 0 &&
                    <>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}>
                            <Tab value="area" label='Area Price Policy'/>
                            <Tab value="freight" label='Freight Price Policy'/>
                        </Tabs>
                        <Box sx={styles.ShippingOrderContent}>
                            {
                                cateInfo && (
                                    tabValue === 'area' ?
                                        <MovingCategoryAreaList cateInfo={cateInfo}/>
                                        : <MovingCategoryFreightList cateInfo={cateInfo}/>

                                )
                            }
                        </Box>
                    </>
                }
            </Box>
        </Container>
    )
}