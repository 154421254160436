import {Button, Container, Grid, InputAdornment, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import {getAccessToken, setTokeninCookie} from "../../utils/doToken";
import {useHistory} from "react-router-dom";

export const SignInPage = () => {

    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [validation, setValidation] = useState(null);

    const handleEmail = (event) => {
        event.preventDefault();
        setEmail(event.target.value.trim());
    };

    const handlePassword = (event) => {
        event.preventDefault();
        setPassword(event.target.value.trim());
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleValidation = () => {
        setValidation(prevState => ({
            ...prevState,
            emailValidation: !email.trim(),
            passwordValidation: !password.trim(),
        }))

        if (email && password) {
            return true;
        } else {
            return false;
        }
    }

    // login logic
    // step1: call login api using email and password
    // step2: if success, get access token and save it. else, fail
    // step3: direct to home page
    const login = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/admin/signIn`;

        let data = {
            email: email.trim(),
            password: password.trim(),
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            });

            console.log('result', result);
            setErrorMessage('');
            setTokeninCookie("access_token", result.data.access_token, 30);
            history?.push("/v2/home");

        } catch (e) {
            console.log(e.response);
            setErrorMessage(e.response?.data?.message);
        } finally {
            setLoading(false);
        }
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        const validationResult = handleValidation();
        validationResult && await login();
    }

    useEffect(() => {
        if (getAccessToken("access_token")) {
            history.push("/v2/home");
        }
    }, []);

    console.log('[sign in page] validation', validation);

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontSize: '40px',
                        fontWeight: '600',
                        color: 'green',
                        textAlign: 'center'
                    }}>
                        Welcome to UUCargo
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '35vw',
                        margin: 'auto',
                        background: '#FFFFFF',
                        padding: '20px',
                        borderRadius: '5px'
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Email"
                                        onChange={handleEmail}
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonOutlineIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        size='small'
                                        error={validation?.emailValidation}
                                        helperText={validation?.emailValidation && 'Required'}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        placeholder="Password"
                                        onChange={handlePassword}
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOutlinedIcon/>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        size='small'
                                        error={validation?.passwordValidation}
                                        helperText={validation?.passwordValidation && 'Required'}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    loading={loading}
                                    onClick={handleLogin}
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                        Log In
                                    </Typography>
                                </LoadingButton>
                            </Grid>
                            {
                                errorMessage &&
                                <Grid item xs={12}>
                                    <Typography style={{color: 'red'}}>
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}