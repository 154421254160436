import {Fragment, useEffect, useState} from 'react';
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG, generateRandomString} from "../../utils/Helper";
import {Button, FormLabel, Grid, Snackbar, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {LTLOrderDashboardPalletEach} from "./LTLOrderDashboardPalletEach";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const LTLOrderDashboardPallet = ({
                                            getDataFromChildComponent,
                                            triggerAction,
                                            isConfirm,
                                            orderData,
                                            getOrderDetails
                                        }) => {

    const [itemId, setItemId] = useState(1);
    const [metric, setMetric] = useState(false);
    const [palletList, setPalletList] = useState([]);
    const [deleteList, setDeleteList] = useState([]);
    const [FTL, setFTL] = useState(0);

    const [originalFTL, setOriginalFTL] = useState(0);
    const [originalPalletList, setOriginalPalletList] = useState([]);

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleMetric = (event) => {
        setMetric(event.target.checked)
    }

    const handleAddPallet = () => {

        const palletId = generateRandomString(8);
        const newPallet = {
            description: '',
            length: '',
            width: '',
            height: '',
            weight: '',
            lengthUnit: metric ? 'cm' : 'in',
            weightUnit: metric ? 'kg' : 'lb',
            quantity: '',
            palletId: palletId
        }
        setItemId(prevState => prevState + 1);
        setPalletList(prevState => [...prevState, newPallet]);
    }

    const handleUpdatePallet = (palletData) => {
        const updatedPalletList = palletList.map(pallet => {
            if (pallet?.palletId === palletData?.palletId) {
                return {...palletData}
            }
            return pallet
        });

        setPalletList(updatedPalletList);
    }

    const handleDeletePallet = (palletData) => {
        const palletIdToFind = palletData?.palletId;
        const filteredPalletList = palletList?.filter(pallet => pallet?.palletId !== palletIdToFind);
        setPalletList(filteredPalletList);
        setDeleteList(prevState => [...prevState, palletData]);
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    packageData: palletList,
                    deletePackageData: deleteList,
                    ftlCount: FTL || 0,
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalPalletList(palletList);
            setDeleteList([]);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    const handleFTL = (event) => {
        const value = event?.target?.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setFTL(value);
        }
    }

    useEffect(() => {
        if (palletList?.length > 0) {
            const updatedPalletList = palletList.map(pallet => {
                if (metric && pallet?.weightUnit?.toLowerCase() !== 'kg') {
                    console.log('covert lb to kg')
                    return {
                        ...pallet,
                        lengthUnit: 'cm',
                        weightUnit: 'kg',
                        weight: convertLBToKG(pallet?.weight),
                        length: convertINToCM(pallet?.length),
                        width: convertINToCM(pallet?.width),
                        height: convertINToCM(pallet?.height),
                    }
                } else if (!metric && pallet?.weightUnit?.toLowerCase() !== 'lb') {
                    console.log('convert kg to lb')
                    return {
                        ...pallet,
                        lengthUnit: 'in',
                        weightUnit: 'lb',
                        weight: convertKGToLB(pallet?.weight),
                        length: convertCMToIN(pallet?.length),
                        width: convertCMToIN(pallet?.width),
                        height: convertCMToIN(pallet?.height),
                    }
                } else {
                    console.log('keep')
                    return pallet
                }
            });
            setPalletList(updatedPalletList);
        }
    }, [metric])

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({palletList});
            getDataFromChildComponent({deleteList});
        }
    }, [triggerAction])

    useEffect(() => {
        if (orderData?.packages?.length > 0) {
            const updatedPalletList = [];
            orderData?.packages?.forEach(element => {
                const palletId = generateRandomString(8);
                let object = {
                    id: element?.id,
                    description: element?.item_description,
                    length: element?.length?.toString(),
                    width: element?.width?.toString(),
                    height: element?.height?.toString(),
                    weight: element?.weight?.toString(),
                    lengthUnit: element?.dimension_unit,
                    weightUnit: element?.weight_unit,
                    quantity: element?.number,
                    palletId: palletId
                }
                updatedPalletList.push(object);
            })
            setPalletList(updatedPalletList);
            setOriginalPalletList(updatedPalletList);
            setFTL(orderData?.bolInfo?.ftl_count);
            setOriginalFTL(orderData?.bolInfo?.ftl_count);
            if (orderData?.packages[0]?.weight_unit.toLowerCase() === 'kg') {
                setMetric(true);
            }
        }
    }, [orderData])

    // console.log('delete', deleteList);
    // console.log('pallet list', palletList);
    // console.log('original pallet list', originalPalletList);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                            checked={metric}
                            onChange={handleMetric}
                            disabled={isConfirm}
                        />
                        <Typography>
                            Metric (cm/kg)
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                            sx={{color: '#609966'}}
                            onClick={handleAddPallet}
                            disabled={isConfirm}
                        >
                            <AddCircleIcon/>
                        </IconButton>
                        <Typography>
                            Add New Pallet
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
            {
                palletList?.length > 0
                    ?
                    <>
                        {
                            palletList?.map((each, index) => (
                                <Grid item xs={12} key={index}>
                                    <LTLOrderDashboardPalletEach
                                        pallet={each}
                                        handleUpdatePallet={handleUpdatePallet}
                                        handleDeletePallet={handleDeletePallet}
                                        isConfirm={isConfirm}
                                        metric={metric}
                                    />
                                </Grid>
                            ))
                        }
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    FTL
                                </FormLabel>
                                <TextField
                                    value={FTL}
                                    onInput={handleFTL}
                                    type='number'
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                    </>
                    :
                    <Grid item xs={12}>
                        <Box>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='small'
                                onClick={handleAddPallet}
                                disabled={isConfirm}
                            >
                                <Typography style={{
                                    textTransform: 'none'
                                }}>
                                    Add New Pallet
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
            }
            {
                (
                    (JSON.stringify(palletList) !== JSON.stringify(originalPalletList) || +FTL !== +originalFTL)
                ) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}