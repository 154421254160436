import React, { useRef, useState } from "react";
import { Box, Button, Chip, TextField, useMediaQuery } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { LABEL_DOWNLOAD_URL_PREFIX, NODE_API_TEST } from "../../utils/apiUrl";
import ShippingListTransactionTable from "./ShippingListTransactionTable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word"
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff"
  },
  table: {
    width: "100%"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)"
    }
  },
  nameTH: {
    width: "100px"
  },
  idTH: {
    width: "100px"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2)
    },
    display: "flex",
    justifyContent: "center"
  }
}));

const ShippingListMultipleTrackingNumber = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 10;

  const { register, handleSubmit } = useForm();
  const [orderData, setOrderData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const cacheInput = useRef({});

  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [currentTrackingNumber, setCurrentTrackingNumber] = useState("");

  const handleChange = (e) => {
    setCurrentTrackingNumber(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 32 && currentTrackingNumber.trim() !== "") {
      setTrackingNumbers((oldState) => [...oldState, e.target.value?.trim()]);
      setCurrentTrackingNumber("");
    }
  };

  const handleDelete = (item, index) => {
    let arr = [...trackingNumbers];
    arr.splice(index, 1);
    console.log(item);
    setTrackingNumbers(arr);
  };

  const getData = async ({ tran_id, trackingNumbersData, currentPage }) => {
    const { data } = await axios.post(`${NODE_API_TEST}/shipping/list/product/`, {
      tran_id,
      trackingNumbers: trackingNumbersData,
      page: currentPage
    });
    const result = data.result.data;
    const resultTotalNumber = data.result.pagination.total;
    const resultLastPage = data.result.pagination.lastPage;
    setTotalNumber(resultTotalNumber);
    setOrderData(result);
    setLastPage(resultLastPage);
    try {
    } catch (e) {
      setTotalNumber(0);
      console.log(e);
      setOrderData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = (data) => {
    const { tran_id } = data;
    const currentTrackingNumbers = [...trackingNumbers];
    if (currentTrackingNumber.trim() !== "") {
      currentTrackingNumbers.push(currentTrackingNumber.trim());
      setCurrentTrackingNumber("");
      setTrackingNumbers(currentTrackingNumbers);
    }

    cacheInput.current = {
      tran_id,
      trackingNumbers
    };
    setPage(1);
    getData({ tran_id, trackingNumbersData: currentTrackingNumbers, currentPage: 1 });
  };
  const handlePageChange = (e, value) => {
    if (value !== page) {
      console.log(value);
      setPage(value);
      const { tran_id, trackingNumbers } = cacheInput.current;
      getData({ tran_id, trackingNumbersData: trackingNumbers, currentPage: value });
    }
  };

  return (
    <>
      <Box>
        <Box
          component="form"
          width={matches ? "100%" : "600px"}
          margin="0 auto"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            gap: "2rem"
          }}>
          <h1>Tracking Orders</h1>
          <TextField
            id="outlined-basic"
            label="Transaction ID"
            variant="outlined"
            {...register("tran_id")}
          />
          <TextField
            id="outlined-basic"
            label="Tracking Numbers (Enter/Separate by Space)"
            variant="outlined"
            onKeyDown={handleKeyUp}
            value={currentTrackingNumber}
            onChange={handleChange}
          />
          <Box
            sx={{
              gap: "0.5rem",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center"
            }}>
            {trackingNumbers.map((item, index) => (
              <Chip
                size="small"
                onDelete={() => handleDelete(item, index)}
                label={item}
                key={index}
              />
            ))}
          </Box>
          <Box>
            <Button
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem"
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}>
              Submit
            </Button>
          </Box>
        </Box>

        <br />
        {isLoading ? (
          <>
            <Box>
              <h1>Loading...</h1>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <h2>Found {totalNumber} results</h2>
              <hr width={matches ? "100%" : "rem"} />
              <Box maxWidth="70%" margin="0 auto">
                <Box mt={2}>
                  <table className={classes.table}>
                    <thead>
                      <tr>
                        <th className={classes.idTH}>Transaction ID</th>
                        <th className={classes.nameTH}>Product ID</th>
                        <th className={classes.nameTH}>Tracking Number</th>
                        <th className={classes.nameTH}>Label URI</th>
                      </tr>
                    </thead>
                    <tbody className={classes.tbody}>
                      {orderData.map((v, index) => {
                        return (
                          <ShippingListTransactionTable
                            key={index}
                            product_id={v?.ref_product_no}
                            tracking_no={v?.tracking_no}
                            tran_id={v.tran_id}
                            label_uri={
                              v?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_uri}` : ""
                            }
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </Box>
              </Box>
              <br />
              <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                <Pagination
                  count={lastPage}
                  page={page}
                  onChange={handlePageChange}
                  className={classes.pagination}
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ShippingListMultipleTrackingNumber;
