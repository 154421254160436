import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

const styles = {
    MovingServiceDateRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    MovingServiceDateSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    MovingServiceDateHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceMovingDateErrorText: {
        fontSize: '12px',
        color: "#FF0303"
    },
}

export const MovingOrderRating = ({rating}) => {

    console.log('[MovingOrderRating] rating', rating);

    if (rating?.message) {
        return (
            <Grid item xs={12}>
                <Typography sx={{color: '#FF0303'}}>
                    {rating?.message}
                </Typography>
            </Grid>
        )
    }

    return (
        <>
            <Grid item xs={6}>
                <Box sx={styles.MovingServiceDateRoot}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Rating
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                Pickup Charge
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                ${(+rating?.pickupCharge).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                Deliver Charge
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                ${(+rating?.deliverCharge).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                Freight Charge
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                ${(+rating?.freightCharge).toFixed(2)}
                            </Typography>
                        </Grid>
                        {
                            (+rating?.storageCharge) > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'right'}}>
                                        Storage Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'right'}}>
                                        ${(+rating?.storageCharge).toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            (+rating?.pelletingCharge) > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'right'}}>
                                        Pallet Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'right'}}>
                                        ${(+rating?.pelletingCharge).toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            (+rating?.serviceFee) > 0 &&
                            <>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'right'}}>
                                        Service Charge
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{textAlign: 'right'}}>
                                        ${(+rating?.serviceFee).toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                ${(+rating?.totalCharge).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                Tax
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                ${(+rating?.taxCharge).toFixed(2)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                Total
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{textAlign: 'right'}}>
                                ${(+rating?.totalChargeWithTaxes).toFixed(2)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={styles.MovingServiceDateRoot}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Summary
                    </Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography sx={{fontWeight: '600'}}>
                                Distance
                            </Typography>
                            <Typography>
                                {(+rating?.distance).toFixed(2)} km
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontWeight: '600'}}>
                                Total Weight
                            </Typography>
                            <Typography>
                                {(+rating?.totalWeight).toFixed(2)} lb
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontWeight: '600'}}>
                                Total Volume
                            </Typography>
                            <Typography>
                                {(+rating?.totalVolume).toFixed(2)} {rating?.volumeUnit}{'\u00B3'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontWeight: '600'}}>
                                Total Volume Metric Weight
                            </Typography>
                            <Typography>
                                {(+rating?.totalVolumeMetricWeight).toFixed(2)} {rating?.volumeMetricWeightUnit}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontWeight: '600'}}>
                                Piece
                            </Typography>
                            <Typography>
                                {(+rating?.pieceCount)}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography sx={{fontWeight: '600'}}>
                                Pallet
                            </Typography>
                            <Typography>
                                {(+rating?.palletCount).toFixed(1)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}