import Box from "@mui/material/Box";
import {Grid, Snackbar, Typography} from "@mui/material";
import {LTLQuickQuoteLocation} from "./LTLQuickQuoteLocation";
import {LTLQuickQuotePallet} from "./LTLQuickQuotePallet";
import LoadingButton from "@mui/lab/LoadingButton";
import {LTLQuickQuoteOptions} from "./LTLQuickQuoteOptions";
import {LTLQuickQuoteServices} from "./LTLQuickQuoteServices";
import {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {getMissingKeysForQuickQuotePalletAddress, isValidPostalCode} from "../../utils/Helper";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {LTLQuickQuoteRating} from "./LTLQuickQuoteRating";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight"];

export const LTLQuickQuote = () => {

    const [isGetRate, setIsGetRate] = useState(false);
    const [shipFrom, setShipFrom] = useState();
    const [shipTo, setShipTo] = useState();
    const [pallet, setPallet] = useState();
    const [FTL, setFTL] = useState(0);
    const [options, setOptions] = useState();
    const [services, setServices] = useState();
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validationList, setValidationList] = useState();
    const [availableService, setAvailableService] = useState([]);
    const [rate, setRate] = useState([]);
    const [sortRate, setSortRate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isCallAllRate, setIsCallAllRate] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbHandleGetShipFrom = (data) => {
        setShipFrom(data);
    }

    const cbHandleGetShipTo = (data) => {
        setShipTo(data);
    }

    const cbHandleGetPallet = (data) => {
        setPallet(data);
    }

    const cbHandleGetFTL = (data) => {
        setFTL(data);
    }

    const cbHandleGetOptions = (data) => {
        setOptions(data);
    }

    const cbHandleGetServices = (data) => {
        setServices(data);
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const moveFromMissing = getMissingKeysForQuickQuotePalletAddress({
            city: shipFrom?.city || shipFrom?.region,
            province: shipFrom?.province
        });
        const moveToMissing = getMissingKeysForQuickQuotePalletAddress({
            city: shipTo?.city || shipTo?.region,
            province: shipTo?.province
        });
        const hasMissingValues = pallet?.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = pallet?.some(object => validateRequiredProperties.some((property) => object[property] <= 0));
        const shipFromPostalCodeMissing = (shipFrom?.country?.code?.toLowerCase() === 'us' || shipTo?.country?.code?.toLowerCase() === 'us') && !shipFrom?.postalCode;
        const shipToPostalCodeMissing = (shipFrom?.country?.code?.toLowerCase() === 'us' || shipTo?.country?.code?.toLowerCase() === 'us') && !shipTo?.postalCode;
        const shipFromPostalCodeValid = (shipFrom?.country?.code?.toLowerCase() === 'ca' && shipTo?.country?.code?.toLowerCase() === 'us' && shipFrom?.postalCode) ? isValidPostalCode(shipFrom?.postalCode) : true;
        const shipToPostalCodeValid = (shipFrom?.country?.code?.toLowerCase() === 'us' && shipTo?.country?.code?.toLowerCase() === 'ca' && shipFrom?.postalCode) ? isValidPostalCode(shipTo?.postalCode) : true;

        console.log('[ShippingLTLQuickQuote] - validate function - shipFromPostalCodeMissing', shipFromPostalCodeMissing);

        pallet?.length === 0 && setErrorMessage(prevState => prevState + 'Pallet list is empty.');
        moveFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Ship from address is missing one or more required fields.');
        moveToMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Ship to address is missing one or more required fields.');
        hasMissingValues && setErrorMessage(prevState => prevState + 'You pallet list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + 'Your pallet list has one or more invalid fields.');
        shipFromPostalCodeMissing && setErrorMessage(prevState => prevState + 'Ship From postal code is missing.');
        shipToPostalCodeMissing && setErrorMessage(prevState => prevState + 'Ship To postal code is missing.');
        !shipFromPostalCodeValid && setErrorMessage(prevState => prevState + 'Ship From postal code is invalid.');
        !shipToPostalCodeValid && setErrorMessage(prevState => prevState + 'Ship To postal code is invalid.');

        setValidationList({
            moveFromMissing,
            moveToMissing,
            shipFromPostalCodeMissing,
            shipToPostalCodeMissing,
            shipFromPostalCodeValid,
            shipToPostalCodeValid
        })
        // console.log('moving from missing', moveFromMissing);
        // console.log('moving to missing', moveToMissing);
        // console.log('pallet missing value', hasMissingValues);

        if (moveFromMissing.length === 0 &&
            moveToMissing.length === 0 &&
            pallet?.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            !shipFromPostalCodeMissing &&
            !shipToPostalCodeMissing &&
            shipFromPostalCodeValid &&
            shipToPostalCodeValid
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const getAllAvailableServiceNames = async () => {
        const totalQty = pallet?.reduce((acc, item) => acc + (+item?.quantity), 0);

        if (totalQty > 25) {
            const availableService = [];
            setAvailableService([]);
            setRate([]);
            setErrorMessage('The pallet quantity exceeds the limit, and there is no available service.');
            handleErrorMessage();
            return availableService;
        } else {
            let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
            try {
                const result = await axios({
                    method: 'get',
                    url: requestURL,
                    params: {
                        count: 1
                    }
                })
                console.log('result', result);
                const availableService = result?.data;
                setAvailableService(availableService);
                const updateRate = result?.data?.map(item => ({
                    ...item,
                    serviceName: item?.account_base?.toLowerCase(),
                    carrierName: item?.account_base?.toLowerCase(),
                    loading: false,
                    finalTotalBeforeTax: 0
                }));
                setRate(updateRate);
                return availableService;
            } catch (e) {
                console.log('error', e);
            }
        }
    }

    // Depreciated LTL rating api
    // const getLTLRatingByOneService = async (serviceName) => {
    //     // setLoading(true);
    //     let requestURL = `${NODE_ROUTE_URI}/shippingLTL/LTLShippingRatingByServiceName`;
    //
    //     // todo edit battery details
    //     let packageData = pallet?.map(element => {
    //         const updatedElement = {...element};
    //         updatedElement.packageSpecialServices = {
    //             ...updatedElement.packageSpecialServices,
    //             batteryDetails: options,
    //         };
    //         return updatedElement
    //     });
    //
    //     let exchangedPackageData = packageData.map(item => {
    //         let {length, width} = item;
    //         if (parseInt(length) < parseInt(width)) {
    //             return {
    //                 ...item,
    //                 length: width,
    //                 width: length
    //             }
    //         }
    //         return item;
    //     });
    //
    //     let data = {
    //         shipperAddress: "",
    //         shipperAddressType: services?.some(service => service.serviceCode === 'PRESPU') ? "RESIDENTIAL" : "WAREHOUSE",
    //         shipperCity: shipFrom?.parentCity || shipFrom?.region,
    //         shipperSubCity: shipFrom?.city,
    //         shipperProvince: shipFrom?.province?.code,
    //         shipperPostalCode: shipFrom?.postalCode,
    //         shipperCountry: shipFrom?.country?.code,
    //         consigneeAddress: "",
    //         consigneeAddressType: services?.some(service => service.serviceCode === 'PRESDL') ? "RESIDENTIAL" : "WAREHOUSE",
    //         consigneeCity: shipTo?.parentCity || shipFrom?.region,
    //         consigneeSubCity: shipTo?.city,
    //         consigneeProvince: shipTo?.province?.code,
    //         consigneePostalCode: shipTo?.postalCode,
    //         consigneeCountry: shipTo?.country?.code,
    //         packageData: exchangedPackageData,
    //         serviceName: serviceName,
    //         specialServices: services,
    //         ftlCount: FTL || 0
    //     }
    //
    //     console.log('[getLTLRatingByOneService] api data', data);
    //
    //     try {
    //         const result = await axios({
    //             method: 'post',
    //             url: requestURL,
    //             data: data
    //         })
    //         const LTLRate = result?.data;
    //         console.log('ltl rating', LTLRate);
    //         // dispatch(updateShippingLTLQuickQuoteSpecialService([]));
    //         setRate(prevState => {
    //             return prevState?.map(rateItem => {
    //                 if (rateItem?.serviceName === LTLRate?.serviceName) {
    //                     return LTLRate;
    //                 }
    //                 return rateItem;
    //             })
    //         })
    //         // setRate(prevState => [...prevState, LTLRate]);
    //         setLoading(false);
    //         setIsGetRate(true);
    //         return true;
    //     } catch (e) {
    //         setLoading(false);
    //         // setError(prevState => [...prevState, serviceName]);
    //         console.log('service name', serviceName);
    //         return false;
    //
    //     } finally {
    //         setIsCallAllRate(prevState => prevState + 1);
    //         setRate(prevRate => {
    //             return prevRate.map(rateItem => {
    //                 if (rateItem?.serviceName.toUpperCase() === serviceName.toUpperCase()) {
    //                     return {...rateItem, loading: false};
    //                 }
    //                 return rateItem;
    //             });
    //         });
    //     }
    // }

    const getLTLRatingByOneServiceNew = async (serviceName) => {
        // setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/shippingLTL/LTLShippingRatingListByServiceName`;

        // todo edit battery details
        let packageData = pallet?.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: options,
            };
            return updatedElement
        });

        let exchangedPackageData = packageData.map(item => {
            let {length, width} = item;
            if (parseInt(length) < parseInt(width)) {
                return {
                    ...item,
                    length: width,
                    width: length
                }
            }
            return item;
        });

        let data = {
            shipperAddress: "",
            shipperAddressType: services?.some(service => service.serviceCode === 'PRESPU') ? "RESIDENTIAL" : "WAREHOUSE",
            shipperCity: shipFrom?.parentCity || shipFrom?.region,
            shipperSubCity: shipFrom?.city,
            shipperProvince: shipFrom?.province?.code,
            shipperPostalCode: shipFrom?.postalCode,
            shipperCountry: shipFrom?.country?.code,
            consigneeAddress: "",
            consigneeAddressType: services?.some(service => service.serviceCode === 'PRESDL') ? "RESIDENTIAL" : "WAREHOUSE",
            consigneeCity: shipTo?.parentCity || shipFrom?.region,
            consigneeSubCity: shipTo?.city,
            consigneeProvince: shipTo?.province?.code,
            consigneePostalCode: shipTo?.postalCode,
            consigneeCountry: shipTo?.country?.code,
            packageData: exchangedPackageData,
            serviceName: serviceName,
            specialServices: services,
            ftlCount: FTL || 0
        }

        console.log('[getLTLRatingByOneService] api data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            const LTLRateList = result?.data;
            console.log('[getLTLRatingByOneServiceNew] ltl rating list', LTLRateList);
            // dispatch(updateShippingLTLQuickQuoteSpecialService([]));

            setRate(prevState => [...prevState, ...LTLRateList]);

            setLoading(false);
            setIsGetRate(true);
            return true;
        } catch (e) {
            setLoading(false);
            // setError(prevState => [...prevState, serviceName]);
            console.log('service name', serviceName);
            return false;

        } finally {
            setIsCallAllRate(prevState => prevState + 1);
            setRate(prevRate => {
                return prevRate.map(rateItem => {
                    if (rateItem?.serviceName.toUpperCase() === serviceName.toUpperCase()) {
                        return {...rateItem, loading: false};
                    }
                    return rateItem;
                });
            });
        }
    }

    const handleGetRating = async () => {
        const validationResult = validate();
        if (validationResult) {
            setIsCallAllRate(0);
            setIsGetRate(false);
            const availableService = await getAllAvailableServiceNames();
            console.log('[handleGetRating] availableService', availableService);
            if (availableService?.length > 0) {
                setRate(prevRate => {
                    return prevRate?.map(rateItem => {
                        return {...rateItem, loading: true};
                    });
                });
                try {
                    for (const service of availableService) {
                        await getLTLRatingByOneServiceNew(service?.account_base);
                    }
                } catch (e) {
                    console.log('error', e);
                }
            }
        }
    }

    useEffect(() => {
        const sortedRate = [...rate].sort((a, b) => (a?.finalTotalBeforeTax || Infinity) - (b?.finalTotalBeforeTax || Infinity));
        setSortRate(sortedRate);
    }, [rate]);

    // console.log('[LTLQuickQuote] ship from', shipFrom);
    // console.log('[LTLQuickQuote] ship to', shipTo);
    // console.log('[LTLQuickQuote] palletList', pallet);
    // console.log('[LTLQuickQuote] options', options);
    // console.log('[LTLQuickQuote] services', services);
    // console.log('[LTLQuickQuote] validationList', validationList);
    console.log('[LTLQuickQuote] rate', rate);
    console.log('[LTLQuickQuote] sort rate', sortRate);
    console.log('[isCallAllRate] isCallAllRate', isCallAllRate);

    return (
        <Box sx={styles.ShippingOrderRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                LTL Quick Quote
            </Typography>
            <Box sx={styles.ShippingOrderContent}>
                <Grid container spacing={2}>
                    <LTLQuickQuoteLocation cbHandleGetShipFrom={cbHandleGetShipFrom}
                                           cbHandleGetShipTo={cbHandleGetShipTo}
                                           validationList={validationList}/>
                    <LTLQuickQuotePallet cbHandleGetPallet={cbHandleGetPallet} cbHandleGetFTL={cbHandleGetFTL}/>
                    <LTLQuickQuoteOptions cbHandleGetOptions={cbHandleGetOptions}/>
                    <LTLQuickQuoteServices cbHandleGetServices={cbHandleGetServices}/>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    height: '100%',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    },
                                }}
                                onClick={handleGetRating}
                                loading={(isCallAllRate !== 0 || isCallAllRate === 0) && (isCallAllRate !== availableService?.length)}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Get Rating
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>
                    {
                        isGetRate ? <LTLQuickQuoteRating sortRate={sortRate}
                                                         isCallAllRate={isCallAllRate}
                                                         availableService={availableService}/>
                            : (isCallAllRate > 0 && isCallAllRate === availableService?.length) && <Grid item xs={12}>
                            <Box sx={styles.ShippingOrderList}>
                                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                                    LTL Rating
                                </Typography>
                                <Typography>
                                    Sorry, we couldn't find any rating results based on the shipping information you
                                    provided. Please double-check the details and try again.
                                </Typography>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}