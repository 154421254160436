import {Fragment, useEffect, useState} from "react";
import {Grid, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal} from "../../utils/customComponent";

export const CarQuoteModel = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [carModel, setCarModel] = useState('');

    const onChange = (field, value) => {
        switch (field) {
            case 'carModel':
                setCarModel(value);
                break;
            default:
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({carModel});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Car Model
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <CustomTextFieldNormal
                            field='carModel'
                            onChange={onChange}
                            isConfirm={isConfirm}
                            error={validation?.carModelMissing}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    )
}