import React from "react";
import PartnerServiceFeeSettingTable from "../PartnerServiceFeeSettingTable";

const ServiceFeeSettingTableForLTL = () => {
  return (
    <>
      <PartnerServiceFeeSettingTable partnerId={0} isGlobal shipmentTypeGroupId={1} />
    </>
  );
};

export default ServiceFeeSettingTableForLTL;
