export const separateTaxes = ({ excludeTaxAmount, includeTaxAmount}) => {
  // $taxes = ($includeTaxAmount - $excludeTaxAmount) > 0 ? ($includeTaxAmount - $excludeTaxAmount) : 0;
  //
  // $gstTax = $includeTaxAmount * 0.05;
  //
  // $pstTax = ($taxes - $gstTax) > 0 ? ($taxes - $gstTax) : 0;
  //
  // return ($excludeTaxAmount, $includeTaxAmount, $taxes, $gstTax, $pstTax);
  includeTaxAmount = +includeTaxAmount;
  excludeTaxAmount = +excludeTaxAmount;
  const taxes = (includeTaxAmount - excludeTaxAmount) > 0 ? (includeTaxAmount - excludeTaxAmount) : 0;
  const gstTax = includeTaxAmount * 0.05 < taxes ? includeTaxAmount * 0.05 : taxes;
  const pstTax = (taxes - gstTax) > 0 ? (taxes - gstTax) : 0;
  return {
    excludeTaxAmount,
    includeTaxAmount,
    taxes: taxes.toFixed(2),
    // gstTax: gstTax.toFixed(2),
    // pstTax: pstTax.toFixed(2),
    gstTax: 0,
    pstTax: 0,
    "GST/HST": taxes.toFixed(2)
  }
}
