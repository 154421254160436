import {actionType} from "../../utils/redux";

const initState = {
    adminInfo: null,

}

export const adminReducer = (state = initState, action) => {
    switch (action.type) {
        case actionType.SAVE_ADMIN_INFO:
            console.log('[adminReducer] payload', action?.payload);
            return {...state, adminInfo: action.payload}
        default:
            return state
    }
}

