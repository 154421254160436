import {Fragment, useEffect, useState} from "react";
import {FormGroup, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export const LTLQuoteTransitCargoType = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [transitCargoType, setTransitCargoType] = useState(false);

    const handleTransitCargoType = event => {
        setTransitCargoType(event?.target?.checked);
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({transitCargoType});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Transit Cargo Type
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                sx={{margin: '0',}}
                                control={
                                    <Checkbox
                                        checked={transitCargoType}
                                        onChange={handleTransitCargoType}
                                        style={{
                                            color: 'green',
                                            '&.Mui-checked': {
                                                color: 'green',
                                            }
                                        }}
                                        disabled={isConfirm}
                                    />
                                }
                                label='Bonded'
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}