import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, Grid, InputAdornment, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {useHistory} from "react-router-dom";
import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MovingOrderListTable} from "../MovingOrder/MovingOrderListTable";
import {Pagination} from "@mui/lab";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/constInfo";
import {ContainerShippingListTable} from "./ContainerShippingListTable";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const ContainerShippingList = () => {

    const classes = useStyles();

    const history = useHistory();

    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState(null);
    const [error, setError] = useState(false);
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);

    const getContainerShippingOrderList = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/oceanShipping/getOceanBackendOrderDetailsList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    queryString: searchString || '',
                    page: 0,
                    pageSize: PAGE_SIZE,
                }
            });
            console.log('[ContainerShippingList] result', result);
            setOrderData(result?.data?.data);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getContainerShippingOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/oceanShipping/getOceanBackendOrderDetailsList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    queryString: searchString || '',
                    page: page,
                    pageSize: PAGE_SIZE,
                }
            });
            console.log('[ContainerShippingList] result', result);
            setOrderData(result?.data?.data);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const handleSearchContainerShippingOrderByKeyDown = async (event) => {
        if (event.key === 'Enter') {
            await getContainerShippingOrderList();
        }
    }

    const handleSearchContainerShippingOrder = async () => {
        await getContainerShippingOrderList();
    }

    const handleAddOrder = () => {
        history.push('/v2/container-shipping-add/');
    }

    const handlePageChange = async (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        await getContainerShippingOrderListByPage(page - 1);
    };

    useEffect(() => {
        (async () => {
            await getContainerShippingOrderList();
        })()
    }, []);

    console.log('[ContainerShippingList] orderData', orderData);

    return (
        <Container maxWidth='xl'>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Container Shipping Order List
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item md={8} xs={12}>
                            <TextField
                                value={searchString}
                                fullWidth
                                size='small'
                                onInput={handleSearchString}
                                onKeyDown={handleSearchContainerShippingOrderByKeyDown}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleSearchContainerShippingOrder}
                                            >
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                classes={{root: classes.customTextField}}
                                // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                                placeholder='Order# / Name / Email / Address / Phone'
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleAddOrder}
                                >
                                    <Typography
                                        style={{textTransform: 'none', fontSize: '16px'}}
                                    >
                                        Add Order
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        loading ?
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            error ?
                                <Box sx={styles.ShippingOrderList}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        Sorry it currently encounter some issues, please try again later
                                    </Typography>
                                </Box>
                                :
                                orderData?.length === 0 ?
                                    <Box sx={styles.ShippingOrderList}>
                                        <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                            No result
                                        </Typography>
                                    </Box>
                                    :
                                    <Box sx={styles.ShippingOrderList}>
                                        <ContainerShippingListTable
                                            order={orderData}
                                        />
                                        <Pagination
                                            count={lastPage}
                                            page={+currentPage}
                                            size='small'
                                            onChange={handlePageChange}
                                        />
                                    </Box>
                    }
                </Box>
            </Box>
        </Container>
    )
}