import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export const MovingOrderDetailMovingTo = ({orderInfo, additionalData}) => {
    console.log('[MovingOrderDetailMovingTo] additionalData', additionalData);

    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Moving To
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.deliver_address}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.deliver_area}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.send_to_country}, {orderInfo?.deliver_postal_code}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Property Type: {additionalData?.movingToAddress?.propertyType?.houseType}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            Elevator: {additionalData?.movingToAddress?.lift ? 'Yes' : 'No'}
                        </Typography>
                        <Typography sx={{textAlign: 'left'}}>
                            Floor Level: {additionalData?.movingToAddress?.floorLevel}
                        </Typography>
                        {/*<Typography sx={{textAlign: 'left'}}>*/}
                        {/*    Distance from Truck to Door: {additionalData?.movingToAddress?.distanceFromTruckToDoor}*/}
                        {/*</Typography>*/}
                        {/*<Typography sx={{textAlign: 'left'}}>*/}
                        {/*    Narrow Street: {additionalData?.movingToAddress?.isNarrowStreet ? 'Yes' : 'No'}*/}
                        {/*</Typography>*/}
                        {/*<Typography sx={{textAlign: 'left'}}>*/}
                        {/*    Steep Driveway: {additionalData?.movingToAddress?.isSteepDriveway ? 'Yes' : 'No'}*/}
                        {/*</Typography>*/}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}