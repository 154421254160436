import {Fragment, useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";

export const LTLQuoteSelectService = ({getDataFromChildComponent, triggerAction, isConfirm, validation, updateService}) => {
    const [availableService, setAvailableService] = useState([]);
    const [selectService, setSelectService] = useState('default');

    const getAllAvailableServiceNames = async () => {
        let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    all: true
                }
            })
            console.log('result', result);
            setAvailableService(result?.data);
        } catch (e) {
            console.log('error', e);
        }
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setSelectService(event.target.value);
    };

    useEffect(() => {
        getAllAvailableServiceNames();
    }, [])

    useEffect(() => {
        if (selectService) {
            updateService(selectService);
        }
    },[selectService]);

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({selectService});
        }
    }, [triggerAction]);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Select Carrier
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <FormControl fullWidth>
                            <Select
                                value={selectService}
                                size='small'
                                // placeholder='Select Partner'
                                onChange={handleChange}
                                disabled={isConfirm}
                                error={validation && validation?.carrierMissing}
                            >
                                <MenuItem disabled value='default'>
                                    <em>Select Service</em>
                                </MenuItem>
                                {
                                    availableService?.map((carrier, index) => (
                                        <MenuItem key={index} value={carrier?.account_base}>
                                            {carrier?.account_base}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                                {(validation && validation?.carrierMissing) && 'Required field'}
                            </FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )

}