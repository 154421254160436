import {Divider, Grid, Typography} from "@mui/material";
import {Fragment} from "react";
import {MovingCategoryListEach} from "./MovingCategoryListEach";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/constInfo";

export const MovingCategoryListTable = ({order, partner, searchString}) => {

    console.log('[MovingCategoryListTable] order', order);

    return (
        <Grid container spacing={1}>
            <Grid item md={1.25}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Category Id
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Parent Category
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Name
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Length (in)
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Width (in)
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Height (in)
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Volume (in{'\u00B3'})
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Weight (lb)
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Grid item xs={12} key={index} sx={{backgroundColor: '#EEEEEE', borderRadius: '5px'}}>
                        <Grid container spacing={2}>
                            <MovingCategoryListEach order_info={orderEach} partner={partner}
                                                    searchString={searchString}/>
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
}