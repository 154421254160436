import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import logo from "../images/logo.png"
import {styled, useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LanguageIcon from '@mui/icons-material/Language';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {useDispatch} from "react-redux";
import actions from "./Action";
import {Button, ListItem, Menu, MenuItem, Typography} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";

const drawerWidth = 240;

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const SideBar = () => {

    const MenuItems = [
        {
            text: 'Transaction Adjustment',
            location: "/v2/transaction/adjustment"
        },
        {
            text: 'Create/UPdate Route Price',
            location: "/v2/routePrice"
        },
        {
            text: 'Upload PNG Label',
            location: "/v2/uploadLabel"
        },
        {
            text: 'Pallets',
            location: "/v2/myLTLs"
        },
        {
            text: 'Company List',
            location: "/v2/companylist"
        },
        {
            text: 'Partner List',
            location: "/v2/partnerList"
        },
        {
            text: 'Not Payed Backend Orders',
            location: "/v2/orderpayment/backendorder/notpaid/list"
        },
        {
            text: 'All Backend Orders',
            location: "/v2/orderpayment/backendorder/all/list"
        },
        {
            text: 'Not Paid Shipping Orders',
            location: "/v2/orderpayment/shippingorder/notpaid/list"
        },
        {
            text: 'All Shipping Orders',
            location: "/v2/orderpayment/shippingorder/all/list"
        },
        {
            text: 'Process End-user Etransfers',
            location: "/v2/etransferemailList"
        },
        {
            text: 'Search All Transactions',
            location: "/v2/searchtransactions"
        },
        {
            text: 'Tracking Package',
            location: "/v2/tracking"
        },
        {
            text: "e-Transfer Top-Up",
            location: "/v2/email/topUp"
        },
        {
            text: "End User List",
            location: "/v2/end-user-list"
        },
        {
            text: "LTL Order List",
            location: "/v2/ltl-order-list"
        },
        {
            text: "LTL Quote List",
            location: "/v2/ltl-quote-list"
        }
    ]

    const [open, setOpen] = React.useState(true);
    const {height, width} = useWindowDimensions();

    const theme = useTheme()

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <>
             <IconButton
                 onClick={handleDrawerOpen}
                 edge="start"
             >
             <MenuIcon/>
             </IconButton>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <img src={logo} alt="logo" width="120px"/>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </DrawerHeader>
                <Divider/>
                <List>
                    {MenuItems.map((item, index) => (
                        <ListItem key={index} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                                </ListItemIcon>
                                <ListItemText primary={item.text}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider/>
            </Drawer>
        </>
    );
};

export default SideBar;