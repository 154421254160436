import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { TextField, Box, Button, OutlinedInput } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm } from "react-hook-form";
import { green, red, blue, orange } from "@mui/material/colors";
import BackArrow from "../BackArrow";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { purple } from "@mui/material/colors";

import axios from "axios";
import { SERVICE_DAO_URL, NODE_ROUTE_URI } from "../../utils/apiUrl";

const theme = createTheme({
  palette: {
    secondary: {
      main: orange[500],
      backgroundColor: orange[500],
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    // height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// After changing or marking as processed, close modal after 3 seconds
const closeModalTimeoutDelay = 3 * 1000

const EtransferEmailDetails = ({ emailObj, removeEmailById, handleClose }) => {
  const classes = useStyles;

  const [companyId] = useState(useParams().id);
  const [emailDetails, setEmailDetails] = useState({});
  const [initialEmailDetails, setInitialEmailDetails] = useState({});

  const [canEdit, setCanEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [value, setValue] = useState(0);

  useEffect(() => {
    setEmailDetails(emailObj);
    setInitialEmailDetails(emailObj);
  }, [emailObj]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // Reverts all Textfields/inputs to intial, turns canEdit off
  const cancelChanges = () => {
    setCanEdit(false);
    setEmailDetails(initialEmailDetails);
  };

  const saveEtransferDetails = async () => {
    if (isNaN(emailDetails.amount)) {
      setStatusMessage("Amount needs to be a number");
      return;
    }
    const body = {
      id: emailDetails.id,
      remarkMessage: emailDetails.remark_message,
      refNumber: emailDetails.reference_number,
      uucOrderNumber:
        emailDetails.reference_number === "" ||
        emailDetails.reference_number === undefined
          ? 0
          : emailDetails.reference_number,

      amount: emailDetails.amount,
      currency: emailDetails.currency,
      senderName: emailDetails.sender_name,
    };
    console.log("Body:", body);

    try {
      const { status } = await axios.post(
        `${SERVICE_DAO_URL}/processetransferemail/setOrderInfoForEmailRecord`,
        body
      );
      console.log(status)
      if (status === 200) {
        setStatusMessage("Successfully setOrderInfoForEmailRecord. Closing modal in 3 seconds");
        setTimeout(handleClose, closeModalTimeoutDelay)
        setCanEdit(false);
        removeEmailById(emailObj.id);
      } else {
        setStatusMessage("Failed to setOrderInfoForEmailRecord");
      }
    } catch (e) {
      console.log(e)
    }
  };

  const setAsProcessed = async () => {
    const body = {
      id: emailDetails.id
    };
    console.log("Body:", body);

    const { status } = await axios.post(
      `${SERVICE_DAO_URL}/processetransferemail/setStatusForEmailRecord`,
      body
    );
    if (status === 200) {
      setStatusMessage("Successfully Set as Processed. Closing window in 3 seconds");
      setTimeout(handleClose, closeModalTimeoutDelay)
      setCanEdit(false);
      removeEmailById(emailObj.id);
    } else {
      setStatusMessage("Failed to  Set as Processed");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {statusMessage}
            {canEdit ? (
              <>
              <Box>

                <Button
                  style={{
                    backgroundColor: green[500],
                    color: "white",
                  }}
                  variant="contained"
                  onClick={saveEtransferDetails}
                >
                  Save
                </Button>
                <Button
                  style={{
                    backgroundColor: blue[500],
                    marginLeft: "20px",
                    color: "white",
                  }}
                  variant="contained"
                  onClick={setAsProcessed}
                >
                  Set as Processed
                </Button>
                <Button
                  style={{
                    backgroundColor: red[500],
                    marginLeft: "20px",
                    color: "white",
                  }}
                  variant="contained"
                  onClick={() => cancelChanges()}
                >
                  Cancel
                </Button>
              </Box>
              </>
            ) : (
              <Box>
                <Button
                  style={{
                    backgroundColor: blue[500],
                    color: "white",
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => setCanEdit(true)}
                >
                  Edit Basic Info
                </Button>
              </Box>
            )}
          </Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Etransfer Remark/Message" {...a11yProps(1)} />
            <Tab label="Email" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            {/* <EtransferEmailDetails emailObj={emailObj}  /> */}
            <Box
              sx={{
                height: 500,
              }}
              maxWidth="100%"
              margin="0"
              paddingBottom={"200px"}
            >
              <Box mt={2}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th className={classes.idTH} width="20%"></th>
                      <th className={classes.idTH} width="100%"></th>
                      {/* <th className={classes.idTH} width="10%"></th> */}
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    <tr>
                      <td>
                        <strong>Reference #</strong>
                      </td>
                      <td>
                        <Box flex="45%" width="100%">
                          <TextField
                            id="outlined-basic"
                            value={
                              emailDetails.ref_no ? emailDetails.ref_no : ""
                            }
                            onChange={(e) => {
                              setEmailDetails((prev) => ({
                                ...prev,
                                ref_no: e.target.value,
                              }));
                            }}
                            // variant={canEdit ? "outlined" : "filled"}
                            variant="outlined"
                            color="secondary"
                            // {...register("businessName")}
                            fullWidth
                            disabled={!canEdit}
                          />
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Amount</strong>
                      </td>
                      <td>
                        <Box flex="45%" width="100%">
                          <OutlinedInput
                            color="secondary"
                            id="outlined-basic"
                            value={
                              emailDetails.amount ? emailDetails.amount : ""
                            }
                            variant="outlined"
                            // {...register("normalizedBusinessName")}
                            startAdornment={
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            }
                            onChange={(e) => {
                              setEmailDetails((prev) => ({
                                ...prev,
                                amount: e.target.value,
                              }));
                            }}
                            fullWidth
                            disabled={!canEdit}
                          />
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Currency</strong>
                      </td>
                      <td>
                        <Box flex="45%" width="100%">
                          <TextField
                            id="outlined-basic"
                            value={
                              emailDetails.currency ? emailDetails.currency : ""
                            }
                            className={classes.textField}
                            InputProps={{
                              className: classes.input,
                            }}
                            color="secondary"
                            variant="outlined"
                            // {...register("gstNumber")}
                            onChange={(e) => {
                              setEmailDetails((prev) => ({
                                ...prev,
                                currency: e.target.value,
                              }));
                            }}
                            fullWidth
                            disabled={!canEdit}
                          />
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Order Id</strong>
                      </td>
                      <td>
                        <Box flex="45%" width="100%">
                          <TextField
                            id="outlined-basic"
                            color="secondary"
                            value={
                              emailDetails.orderId ? emailDetails.orderId : ""
                            }
                            variant="outlined"
                            // {...register("email")}
                            onChange={(e) => {
                              setEmailDetails((prev) => ({
                                ...prev,
                                orderId: e.target.value,
                              }));
                            }}
                            fullWidth
                            disabled={!canEdit}
                          />
                        </Box>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Order #</strong>
                      </td>
                      <td>
                        <Box flex="45%" width="100%">
                          <TextField
                            id="outlined-basic"
                            color="secondary"
                            value={
                              emailDetails.orderNumber
                                ? emailDetails.orderNumber
                                : ""
                            }
                            variant="outlined"
                            // {...register("phone")}
                            onChange={(e) => {
                              setEmailDetails((prev) => ({
                                ...prev,
                                orderNumber: e.target.value,
                              }));
                            }}
                            fullWidth
                            disabled={!canEdit}
                          />
                        </Box>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {emailObj.remark_message
              ? emailObj.remark_message
              : "No remark found. See full Email"}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {emailObj.origi_text
              ? emailObj.origi_text
              : "No Email Text Available"}
          </TabPanel>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default EtransferEmailDetails;
