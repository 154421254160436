import {Fragment, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldPallet} from "../../utils/customComponent";
import {Grid} from "@mui/material";

export const LTLOrderListPurchaseOrderNumber = ({
                                                    getDataFromChildComponent,
                                                    triggerAction,
                                                    isConfirm,
                                                }) => {

    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState();

    const onChange = (field, value) => {
        switch (field) {
            case 'purchaseOrderNumber':
                setPurchaseOrderNumber(value);
                break;
            default:
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({purchaseOrderNumber});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <CustomTextFieldPallet
                            textValue={purchaseOrderNumber}
                            field='purchaseOrderNumber'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Box>
            </Grid>
        </Fragment>
    )
}