import {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {extractFullAddress} from "../../utils/Helper";
import FormControl from "@mui/material/FormControl";
import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import {CustomTextFieldNormal, CustomTextFieldPallet} from "../../utils/customComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLOrderDashboardConsignee = ({
                                               getDataFromChildComponent,
                                               triggerAction,
                                               isConfirm,
                                               validation,
                                               orderData,
                                               getConsigneeResidential,
                                               getOrderDetails
                                           }) => {

    const [consigneeName, setConsigneeName] = useState();
    const [consigneePhone, setConsigneePhone] = useState();
    const [consigneeCompanyName, setConsigneeCompanyName] = useState();
    const [consigneeEmail, setConsigneeEmail] = useState();
    const [consigneeAddress, setConsigneeAddress] = useState();
    const [consigneeAddressTwo, setConsigneeAddressTwo] = useState();
    const [consigneeCity, setConsigneeCity] = useState();
    const [consigneeProvince, setConsigneeProvince] = useState();
    const [consigneeCountry, setConsigneeCountry] = useState();
    const [consigneePostalCode, setConsigneePostalCode] = useState();

    const [originalConsigneeName, setOriginalConsigneeName] = useState();
    const [originalConsigneePhone, setOriginalConsigneePhone] = useState();
    const [originalConsigneeCompanyName, setOriginalConsigneeCompanyName] = useState();
    const [originalConsigneeEmail, setOriginalConsigneeEmail] = useState();
    const [originalConsigneeAddress, setOriginalConsigneeAddress] = useState();
    const [originalConsigneeAddressTwo, setOriginalConsigneeAddressTwo] = useState();
    const [originalConsigneeCity, setOriginalConsigneeCity] = useState();
    const [originalConsigneeProvince, setOriginalConsigneeProvince] = useState();
    const [originalConsigneeCountry, setOriginalConsigneeCountry] = useState();
    const [originalConsigneePostalCode, setOriginalConsigneePostalCode] = useState();

    const [shipToAddressPredictions, setShipToAddressPredictions] = useState([]);
    const [displayAddress, setDisplayAddress] = useState({
        description: null,
        placeId: null,
        text: null
    });

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const classes = useStyles();

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setDisplayAddress(prevState => ({
            ...prevState,
            description: '',
            placeId: '',
            text: value
        }));
        setConsigneeAddress(value);
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipToAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setDisplayAddress(value);
            setConsigneeCity(address?.city);
            setConsigneeProvince(address?.state?.code);
            setConsigneeCountry(address?.country?.code);
            setConsigneePostalCode(address?.zip);
        } else {
            setDisplayAddress('');
        }
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'consigneeName':
                setConsigneeName(value);
                break;
            case 'consigneePhone':
                setConsigneePhone(value);
                break;
            case 'consigneeCompanyName':
                setConsigneeCompanyName(value);
                break;
            case 'consigneeEmail':
                setConsigneeEmail(value);
                break;
            case 'consigneeAddress':
                setConsigneeAddress(value);
                break;
            case 'consigneeAddressTwo':
                setConsigneeAddressTwo(value);
                break;
            case 'consigneeCity':
                setConsigneeCity(value);
                break;
            case 'consigneeProvince':
                setConsigneeProvince(value);
                break;
            case 'consigneeCountry':
                setConsigneeCountry(value);
                break;
            case 'consigneePostalCode':
                setConsigneePostalCode(value);
                break;
            default:
        }
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    consigneeName: consigneeName,
                    consigneePhone: consigneePhone,
                    consigneeCompanyName: consigneeCompanyName,
                    consigneeEmail: consigneeEmail,
                    consigneeAddress: consigneeAddress,
                    consigneeCity: consigneeCity,
                    consigneeProvince: consigneeProvince,
                    consigneePostalCode: consigneePostalCode,
                    consigneeCountry: consigneeCountry,
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalConsigneeName(consigneeName);
            // setOriginalConsigneeCompanyName(consigneeCompanyName);
            // setOriginalConsigneePhone(consigneePhone);
            // setOriginalConsigneeEmail(consigneeEmail);
            // setOriginalConsigneeCity(consigneeCity);
            // setOriginalConsigneeProvince(consigneeProvince);
            // setOriginalConsigneeCountry(consigneeCountry);
            // setOriginalConsigneePostalCode(consigneePostalCode);
            // setOriginalConsigneeAddress(consigneeAddress);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                consigneeInfo: {
                    consigneeName,
                    consigneePhone,
                    consigneeEmail,
                    consigneeCompanyName,
                    consigneeAddress,
                    consigneeAddressTwo,
                    consigneeCity,
                    consigneeProvince,
                    consigneeCountry,
                    consigneePostalCode
                }
            });
        }
    }, [triggerAction])

    useEffect(() => {
        setConsigneeName(orderData?.bolInfo?.consignee_name);
        setConsigneeCompanyName(orderData?.bolInfo?.consignee_company_name);
        setConsigneePhone(orderData?.bolInfo?.consignee_phone);
        setConsigneeEmail(orderData?.bolInfo?.consignee_email);
        setConsigneeCity(orderData?.bolInfo?.consignee_city);
        setConsigneeProvince(orderData?.bolInfo?.consignee_province);
        setConsigneeCountry(orderData?.bolInfo?.consignee_country);
        setConsigneePostalCode(orderData?.bolInfo?.consignee_postal_code);
        setConsigneeAddress(orderData?.bolInfo?.consignee_address);
        setDisplayAddress(prevState => ({
            ...prevState, text: orderData?.bolInfo?.consignee_address
        }))
        setConsigneeAddressTwo(orderData?.bolInfo?.consignee_address_two);
        setOriginalConsigneeName(orderData?.bolInfo?.consignee_name);
        setOriginalConsigneeCompanyName(orderData?.bolInfo?.consignee_company_name);
        setOriginalConsigneePhone(orderData?.bolInfo?.consignee_phone);
        setOriginalConsigneeEmail(orderData?.bolInfo?.consignee_email);
        setOriginalConsigneeCity(orderData?.bolInfo?.consignee_city);
        setOriginalConsigneeProvince(orderData?.bolInfo?.consignee_province);
        setOriginalConsigneeCountry(orderData?.bolInfo?.consignee_country);
        setOriginalConsigneePostalCode(orderData?.bolInfo?.consignee_postal_code);
        setOriginalConsigneeAddress(orderData?.bolInfo?.consignee_address);
    }, [orderData])

    useEffect(() => {
        if (consigneeAddress && consigneeCity && consigneeProvince && consigneeCountry && consigneePostalCode) {
            getConsigneeResidential({
                consigneeInfo: {
                    consigneeAddress,
                    consigneeCity,
                    consigneeProvince,
                    consigneeCountry,
                    consigneePostalCode
                }
            })
        }
    }, [consigneeAddress, consigneeCity, consigneeProvince, consigneeCountry, consigneePostalCode])

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Name
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeName}
                        field='consigneeName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeName')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Phone
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneePhone}
                        field='consigneePhone'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneePhone')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Company
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeCompanyName}
                        field='consigneeCompanyName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Email
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeEmail}
                        field='consigneeEmail'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address
                    </FormLabel>
                    <Autocomplete
                        freeSolo
                        value={displayAddress}
                        fullWidth
                        disabled={isConfirm}
                        options={shipToAddressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputChangeAddress}
                        onChange={handleChangeAddress}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                            error={validation?.shipToMissing?.includes('consigneeAddress')}
                            helperText={
                                (validation?.shipToMissing?.includes('consigneeAddress')) && 'Required Field'
                            }
                        />}
                        renderOption={option => (
                            <Typography>
                                {option?.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address Two
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeAddressTwo}
                        field='consigneeAddressTwo'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        City
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeCity}
                        field='consigneeCity'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeCity')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Province
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeProvince}
                        field='consigneeProvince'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeProvince')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Country
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeCountry}
                        field='consigneeCountry'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeCountry')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Postal Code
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneePostalCode}
                        field='consigneePostalCode'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneePostalCode')}
                    />
                </FormControl>
            </Grid>
            {
                (
                    consigneeName !== originalConsigneeName ||
                    consigneePhone !== originalConsigneePhone ||
                    consigneeCompanyName !== originalConsigneeCompanyName ||
                    consigneeEmail !== originalConsigneeEmail ||
                    consigneeAddress !== originalConsigneeAddress ||
                    // shipperAddressTwo !== originalShipperAddressTwo ||
                    consigneeCity !== originalConsigneeCity ||
                    consigneeProvince !== originalConsigneeProvince ||
                    consigneeCountry !== originalConsigneeCountry ||
                    consigneePostalCode !== originalConsigneePostalCode
                ) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}