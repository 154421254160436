import {actionType} from "../../utils/redux"


const navigateBarToggle = data => ({
    type: actionType.NAVIGATE_BAR_OPEN_AND_CLOSE,
    payload: data
})

const passPartnerId = data => ({
    type: actionType.PARTNER_ID,
    payload: data
})

export default {
    navigateBarToggle,
    passPartnerId
}