import {Button, Container, Grid, Typography} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from "@mui/material/Box";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getAccessToken} from "../../utils/doToken";
import {PackageOrderTransactionList} from "./PackageOrderTransactionList";
import {PackageOrderTransactionDetails} from "./PackageOrderTransactionDetails";
import {PackageOrderTransactionCost} from "./PackageOrderTransactionCost";
import {PackageOrderTransactionDocuments} from "./PackageOrderTransactionDocuments";
import {PackageOrderTransactionPickup} from "./PackageOrderTransactionPickup";
import {PackageOrderTransactionAccount} from "./PackageOrderTransactionAccount";
import {PackageOrderCrossBorder} from "./PackageOrderCrossBorder";
import {PackageOrderAdjustment} from "./PackageOrderAdjustment";
import {AdjustPackageOrderDetails} from "./AdjustPackageOrderDetails";
import dayjs from "dayjs";

export const PackageOrderDetails = () => {

    const history = useHistory();
    const storedToken = getAccessToken("access_token");
    const location = useLocation();
    const orderData = location?.state?.orderData;

    const {orderId} = useParams();
    const [orderInfo, setOrderInfo] = useState();
    const [transactionList, setTransactionList] = useState([]);
    const [selectTransactionData, setSelectedTransactionData] = useState(null);

    const getOrderInfoByOrderId = async () => {
        const requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/order`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: orderId
                }
            });
            console.log('[getOrderInfoByOrderId] result', result);
            setOrderInfo(result?.data);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const getTransactionsListByOrderId = async () => {
        const requestURL = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getTransactionsByOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    orderId: orderId
                }
            });
            console.log('[getTransactionsListByOrderId] result', result);
            setTransactionList(result?.data?.result);
            setSelectedTransactionData(result?.data?.result?.[0]);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const cbHandleSelectedTransactionData = (transactionData) => {
        setSelectedTransactionData(transactionData);
    }

    const cbHandleTransactionList = (transactionList) => {
        setTransactionList(transactionList);
    }

    const handleBack = () => {
        history.push({
            pathname: `/v2/package-orders/`,
            state: {orderData: orderData}
        });
    }

    useEffect(() => {
        getOrderInfoByOrderId();
        getTransactionsListByOrderId();
    }, []);

    if (orderInfo?.is_adjust_charge) {
        return <AdjustPackageOrderDetails/>
    }

    console.log('[PackageOrderDetails] order id', orderId);
    console.log('[PackageOrderDetails] order info', orderInfo);
    console.log('[PackageOrderDetails] transactions list', transactionList);
    console.log('[PackageOrderDetails] orderData', orderData);
    console.log('[PackageOrderDetails] location', location);

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={4}>
                <Grid item md={8} xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center'}}>
                        <CheckCircleIcon sx={{color: '#1D8B45', fontSize: '30px'}}/>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Order Id: {orderId}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center'}}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Order Cost: $ {orderInfo?.final_total?.toFixed(2)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item md={8} xs={12}>
                    <Grid container spacing={2}>
                        {
                            (transactionList === null || transactionList?.length > 1) &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderTransactionList orderId={orderId}
                                                             cbHandleSelectedTransactionData={cbHandleSelectedTransactionData}
                                                             cbHandleTransactionList={cbHandleTransactionList}
                                                             transactionList={transactionList}
                                                             selectTransactionData={selectTransactionData}
                                                             orderInfo={orderInfo}
                                />
                            </Grid>
                        }
                        {
                            transactionList?.length === 1 &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderTransactionDetails orderId={orderId}
                                                                selectTransactionData={selectTransactionData}
                                />
                            </Grid>
                        }
                        {
                            orderInfo &&
                            <Grid item md={12} xs={12}>
                                <PackageOrderTransactionPickup orderInfo={orderInfo}
                                                               selectTransactionData={selectTransactionData}/>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PackageOrderTransactionCost orderInfo={orderInfo}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PackageOrderTransactionDocuments orderInfo={orderInfo}/>
                        </Grid>
                        <Grid item xs={12}>
                            <PackageOrderTransactionAccount orderData={orderData} orderInfo={orderInfo}/>
                        </Grid>
                        {
                            orderData?.isCrossBorder &&
                            <Grid item xs={12}>
                                <PackageOrderCrossBorder orderData={orderData} orderInfo={orderInfo}/>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <PackageOrderAdjustment/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                height: '100%',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            onClick={handleBack}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Back To Package List
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}