import {Fragment, useEffect, useState} from "react";
import {Button, Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {MovingCategoryAreaEdit} from "./MovingCategoryAreaEdit";
import {MovingCategoryFreightEdit} from "./MovingCategoryFreightEdit";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingCategoryFreightListEach = ({
                                                  area_info,
                                                  cbGetPolicyId,
                                                  policyId,
                                                  getFreightPricePolicyListByCategoryId
                                              }) => {

    return (
        <Fragment>
            <Grid item xs={3}>
                {
                    area_info?.route_id ?
                        <>
                            <Typography style={{textAlign: 'left'}}>
                                Departure: {area_info?.route_departure_area}
                            </Typography>
                            <Typography style={{textAlign: 'left'}}>
                                Arrival: {area_info?.route_arrival_area}
                            </Typography>
                        </>
                        :
                        <Typography style={{textAlign: 'left'}}>
                            Cover Area: {area_info?.group_name}, {area_info?.group_province_shortname}, {area_info?.group_country}
                        </Typography>
                }
            </Grid>
            <Grid item xs={2}>
                <Typography style={{textAlign: 'left'}}>
                    {area_info?.standard_length_ins} x {area_info?.standard_width_ins} x {area_info?.standard_height_ins} in
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'left'}}>
                            Dimension: {area_info?.max_dimension_ins} in
                        </Typography>
                        <Typography style={{textAlign: 'left'}}>
                            Weight: {area_info?.max_weight_lbs} lb
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Typography>
                                    {area_info?.over_weight_per_lb_price} $/lb
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {area_info?.price_adjust_ratio}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Button
                    variant='outlined'
                    sx={{
                        border: '2px solid #1D8B45',
                        "&:hover": {
                            border: '2px solid #1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={() => cbGetPolicyId(area_info?.policy_id)}
                >
                    <Typography style={{
                        fontSize: '14px',
                        textTransform: 'none',
                        color: '#1D8B45'
                    }}>
                        Details
                    </Typography>
                </Button>
            </Grid>
            {
                policyId === area_info?.policy_id &&
                <Grid item xs={12}>
                    <Box sx={styles.ShippingOrderList}>
                        <MovingCategoryFreightEdit policyId={policyId} cbGetPolicyId={cbGetPolicyId}
                                                   getFreightPricePolicyListByCategoryId={getFreightPricePolicyListByCategoryId}/>
                    </Box>
                </Grid>
            }
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )

}