import React, { useRef, useState } from "react";
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Snackbar } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { Alert } from "@material-ui/lab";
import Upload from "@mui/icons-material/Upload";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { makeStyles } from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { SERVICE_SERVICE_URL } from "../../utils/apiUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%"
  },
  unTrackingTable: {
    "border-spacing": "2rem 1rem"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
    "white-space": "nowrap"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const RetrieveUpdatedInvoice = () => {
  const classes = useStyles();
  const { register, handleSubmit, control } = useForm();

  const [toastOpen, setToastOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [csvFileObj, setCsvFileObj] = useState(null);
  const [csvFileName, setCsvFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputCSVFile = useRef(null);
  const [csvInvoiceURL, setCsvInvoiceURL] = useState("");

  const onCsvDrop = (e) => {
    if (!(e.target.files && e.target.files[0])) {
      return;
    }
    setCsvFileObj(e.target.files[0]);
    setCsvFileName(e.target.files[0].name);
  };
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getData = async ({ accountBase }) => {
    try {
      if (!csvFileObj) {
        return;
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", csvFileObj);
      formData.append("accountBase", accountBase);
      const { data } = await axios.post(`${SERVICE_SERVICE_URL}/transactionAdjustments/retrieveUpdatedInvoiceFile`, formData, {
        headers: {
          "Content-Type": "multipart/form-data;",
          "X-Requested-With": "XMLHttpRequest",
        }
      });
      const { fileURL } = data;
      setCsvInvoiceURL(`https://storage.googleapis.com/${fileURL}`)
      setErrorMessage("");
      setSuccessMessage("Transaction CSV upload success!");
      handleToastClick();
    } catch (e) {
      console.log(e);
      setErrorMessage("Error! This CSV file is invalid! Either row errors or you already submit this before!");
      handleToastClick();
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = data => {
    const { account_base } = data;
    console.log("account_base: ", account_base);
    setErrorMessage("");
    getData({
      accountBase: account_base
    })
  }

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Submit Transaction">
        {(() => {
          if (errorMessage !== "") {
            return (
              <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                Error!
                <hr />
                {errorMessage}
              </Alert>
            )
          }
          return (
            <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          )
        })()}
      </Snackbar>
      <Box component="form" width={"1000px"} margin="0 auto" sx={{
        display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
        alignItems: "center", alignContent: "center"
      }}>
        <h1>Retrieve Updated CSV File</h1>
        <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }}>
          <input type="file" style={{ display: "none" }} ref={inputCSVFile} onChange={onCsvDrop} accept=".csv" name="file" />
          <Button
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
              }}
              onClick={() => {
                inputCSVFile.current.click();
              }}
              endIcon={<Upload />}
          >
            Upload CSV
          </Button>
        </Box>

        {csvFileName && (
            <Box className={classes.buttonFilesWrapper} mt={3}>
              <Box display="flex" alignItems="center">
                <InsertDriveFileIcon />
                {csvFileName}
              </Box>
            </Box>
        )}
        <Box mt={2} mb={4}>
          <FormControl>
            <FormLabel required color="primary">Account Base</FormLabel>
            <Controller control={control} name="account_base" rules={{ required: true }} render={({ field }) => (
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    {...field}
                >
                  <FormControlLabel value="FedEx" control={<Radio />} label="FedEx" />
                  <FormControlLabel value="UPS" control={<Radio />} label="UPS" />
                  <FormControlLabel value="Canpar" control={<Radio />} label="Canpar" />
                  <FormControlLabel value="CanadaPost" control={<Radio />} label="Canada Post" />
                </RadioGroup>
            )}/>

          </FormControl>
          {errorMessage !== "" &&
              <Alert severity="error">
                {errorMessage}
              </Alert>}
        </Box>
        <Box display="flex" sx={{ gap: "3rem" }}>
          <LoadingButton
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem",
              }}
              loading={isLoading}
              type="submit"
              onClick={handleSubmit(onSubmit)}
          >
            Submit
          </LoadingButton>
        </Box>
        {
          csvInvoiceURL ? (
              <h3>Invoice URL: &nbsp;
                <a href={csvInvoiceURL} target="_blank" rel="noopener noreferrer">
                  {csvInvoiceURL ? "Download" : ""}
                </a>
              </h3>
          ) : <></>
        }
      </Box>
    </Box>
  );
};

export default RetrieveUpdatedInvoice;
