import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import CurrencyFormat from 'react-currency-format';

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingOrderContact = ({
                                       cbHandleGetMovingContact,
                                       selectUser,
                                       validationResultPlaceOrder,
                                       contactValidate
                                   }) => {

    const [contactInfo, setContactInfo] = useState({
        pickupName: '',
        pickupPhone: '',
        pickupEmail: '',
        deliverName: '',
        deliverPhone: '',
        deliverEmail: ''
    });

    const handlePickupName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupName: event.target.value
        }))
    };

    const handlePickupPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupPhone: event.target.value
        }))
    };

    const handlePickupEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupEmail: event.target.value
        }))
    };

    const handleDeliverName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverName: event.target.value
        }))
    };

    const handleDeliverPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverPhone: event.target.value
        }))
    };

    const handleDeliverEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverEmail: event.target.value
        }))
    };

    useEffect(() => {
        cbHandleGetMovingContact(contactInfo);
    }, [contactInfo]);

    useEffect(() => {
        if (selectUser?.id) {
            // if (!contactInfo?.pickupName) {
            setContactInfo(prevState => ({
                ...prevState,
                pickupName: `${selectUser?.firstname} ${selectUser?.lastname}`,
                pickupPhone: selectUser?.phone || '',
                pickupEmail: selectUser?.email,
                deliverName: `${selectUser?.firstname} ${selectUser?.lastname}`,
                deliverPhone: selectUser?.phone || '',
                deliverEmail: selectUser?.email,
            }))
            // }

            // if (!contactInfo?.deliverName) {
            //     setContactInfo(prevState => ({
            //         ...prevState,
            //         deliverName: `${selectUser?.firstname} ${selectUser?.lastname}`,
            //         deliverPhone: selectUser?.phone,
            //         deliverEmail: selectUser?.email,
            //     }))
            // }
        }
    }, [selectUser]);

    console.log('[MovingOrderContact] selectUser', selectUser);

    return (
        <>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Shipper Information
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Name
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.pickupName}
                                    onInput={handlePickupName}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validationResultPlaceOrder?.contactMissing?.includes('pickupName')}
                                    helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupName') && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Phone
                                </FormLabel>
                                <CurrencyFormat
                                    value={contactInfo?.pickupPhone}
                                    customInput={TextField}
                                    onInput={handlePickupPhone}
                                    format="+1 (###) ###-####"
                                    mask="_"
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validationResultPlaceOrder?.contactMissing?.includes('pickupPhone') || contactValidate?.pickupPhoneError}
                                    helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupPhone') ? 'Required' : contactValidate?.pickupPhoneError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Email
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.pickupEmail}
                                    onInput={handlePickupEmail}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') || contactValidate?.pickupEmailError}
                                    helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') ? 'Required' : contactValidate?.pickupEmailError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Consignee Information
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Name
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.deliverName}
                                    onInput={handleDeliverName}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validationResultPlaceOrder?.contactMissing?.includes('deliverName')}
                                    helperText={validationResultPlaceOrder?.contactMissing?.includes('deliverName') && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Phone
                                </FormLabel>
                                <CurrencyFormat
                                    value={contactInfo?.deliverPhone}
                                    customInput={TextField}
                                    onInput={handleDeliverPhone}
                                    format="+1 (###) ###-####"
                                    mask="_"
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validationResultPlaceOrder?.contactMissing?.includes('deliverPhone') || contactValidate?.deliverPhoneError}
                                    helperText={validationResultPlaceOrder?.contactMissing?.includes('deliverPhone') ? 'Required' : contactValidate?.deliverPhoneError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Email
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.deliverEmail}
                                    onInput={handleDeliverEmail}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    error={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') || contactValidate?.pickupEmailError}
                                    helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') ? 'Required' : contactValidate?.pickupEmailError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}