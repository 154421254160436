import {Divider, Grid, Menu, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import {Link} from "@material-ui/core";
import {Fragment, useEffect, useState} from "react";

export const ContainerShippingListEach = ({order_info, partnerList, endUserList}) => {

    const [user, setUser] = useState(null);

    useEffect(() => {
        if (order_info?.ownerRole === 1) {
            const filteredUser = endUserList?.filter((userInfo) => userInfo?.id === order_info?.userId);
            setUser(filteredUser[0]);
        } else {
            const filteredUser = partnerList?.filter((userInfo) => userInfo?.id === order_info?.userId);
            setUser(filteredUser[0]);
        }
    }, [order_info, partnerList, endUserList]);

    // console.log('[ContainerShippingListEach] user', user);

    return (
        <Fragment>
            <Grid item md={1.75}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <img
                            src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.png'
                            alt="logo"
                            height={40}
                        />
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            height: '100%'
                        }}>
                            <Link
                                href={`/v2/container-shipping-detail/${order_info?.orderId}`}
                                underline="none"
                                sx={{
                                    color: '#1D8B45',
                                }}>
                                <Typography style={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    textAlign: 'left',
                                    width: '100%',
                                    color: '#1D8B45',
                                }}>
                                    {order_info?.orderId}
                                </Typography>
                            </Link>
                            <Typography style={{
                                fontSize: '12px',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                {dayjs(order_info?.created_at).format("YYYY-MM-DD")}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={1.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        {order_info?.ownerRole === 3 ? 'Partner Id' : 'EndUser Id:'}: {+order_info?.userId}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        {order_info?.ownerRole === 3 ? user?.business_name : `${user?.firstName} ${user?.lastname}`}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1.75}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        Vessel: {order_info?.vesselNumber}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        Voyage: {order_info?.voyage}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        Route Code: {order_info?.routeCode}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1.75}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        ETD: {order_info?.ETD}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        ETA: {order_info?.ETA}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={3.25}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        Loading Port: {order_info?.portOfLoading}, {order_info?.portOfLoadingName}
                    </Typography>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        Discharge Port : {order_info?.portOfDischarge}, {order_info?.portOfDischargeName}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        color: +order_info?.orderStatus === 0 ? '#454545' : +order_info?.orderStatus === 1 ? '#1D8B45' : '#FF0303'
                    }}>
                        {+order_info?.orderStatus === 0 ? 'Initial' : +order_info?.orderStatus === 1 ? 'Confirm' : 'Cancelled'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    flexDirection: 'column'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                    }}>
                        $ {(+order_info?.finalTotal)?.toFixed(2)}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>

    )
}