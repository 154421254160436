import {Divider, Grid, Typography} from "@mui/material";
import {Fragment} from "react";
import {CarQuoteListEach} from "./CarQuoteListEach";

export const CarQuoteTable = ({quote}) => {

    return (
        <Grid container spacing={1}>
            <Grid item md={1.75} xs={3}>
                <Typography style={{fontWeight: '600', textAlign: 'left', fontSize: '14px'}}>
                    Car Information
                </Typography>
            </Grid>
            <Grid item md={1.75} xs={3}>
                <Typography style={{fontWeight: '600', textAlign: 'left', fontSize: '14px'}}>
                    Car Size
                </Typography>
            </Grid>
            <Grid item md={1.5} xs={6}>
                <Typography style={{fontWeight: '600', textAlign: 'left', fontSize: '14px'}}>
                    Moving From
                </Typography>
            </Grid>
            <Grid item md={1.5} xs={6}>
                <Typography style={{fontWeight: '600', textAlign: 'left', fontSize: '14px'}}>
                    Moving To
                </Typography>
            </Grid>
            <Grid item md={1} xs={6}>
                <Typography style={{fontWeight: '600', textAlign: 'left', fontSize: '14px'}}>
                    Cost
                </Typography>
            </Grid>
            <Grid item md={1} xs={6}>
                <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                    Carrier
                </Typography>
            </Grid>
            <Grid item md={1.5} xs={3}>
                <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                    Shipping Day
                </Typography>
            </Grid>
            <Grid item md={1} xs={3}>
                <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                    Status
                </Typography>
            </Grid>
            <Grid item md={1} xs={3}>
                <Typography style={{fontWeight: '600', fontSize: '14px'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                quote?.map((quoteEach, index) =>
                    <Fragment key={index}>
                        <CarQuoteListEach quote_info={quoteEach}/>
                    </Fragment>
                )
            }
        </Grid>

    )
}

