// import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import { TextField, Box, Button } from "@material-ui/core";
import { green, red, blue } from "@mui/material/colors";
import BackArrow from "../../BackArrow";

import axios from "axios";
import { SERVICE_DAO_URL, NODE_ROUTE_URI } from "../../../utils/apiUrl";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import {useHistory, useLocation} from "react-router-dom";
// import Slide from 'react-reveal';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Snackbar,
  Input, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";

import PropTypes from "prop-types";
// import axios from "axios";
import produce from "immer";


import { redirectHome } from "../../../utils/redirect";
import CurrencyFormat from "react-currency-format";
import ReviewOrderHeader from "./reviewOrderHeader";
import {getAccessToken} from "../../../utils/doToken";
// import PaymentsAccordion from "./PaymentsAccordion";
// import ShippingOrderListTable from "./ShippingOrderListTable";
// import {useInterval} from "usehooks-ts";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexDirection: 'column',
    margin: "0 auto",
    width: "40vw",
    minWidth: "500px",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    textAlign: "left"
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
    textAlign: "left"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 5,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    width: "14rem",
  },
  smButton: {
    borderRadius: 5,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    width: "12rem",
  },
  textfield: {
    width: "100%",
    backgroundColor: "white",
    // border: "1px solid white",
  },
  dockerFee: {
    width: "4rem",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    // backgroundColor: "#FEEAAE",
    backgroundColor: "white",
  },

  infoWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  textButton: {
    color: "blue",
  },
  bodyText: {
    fontSize: "1rem",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  timePicker: {
    width: 160,
  },
  divider: {
    borderBottom: "2px solid rgba(0,0,0,0.3)",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  radioWrapper: {
    border: "1px solid red",
    paddingLeft: theme.spacing(1),
    borderRadius: "5px",
  },
}));

// const formKeys = [
//   {
//     type: "textField",
//     formKey: "orderNumber",
//     objectKey: "order_number", // db column
//     fieldName: "Order #",
//     edittable: true,
//   },
//   {
//     type: "textField",
//     formKey: "sender",
//     objectKey: "sender",
//     fieldName: "Sender Name",
//     edittable: true,
//   },
//   {
//     type: "textField",
//     formKey: "senderEmail",
//     objectKey: "sender_email",
//     fieldName: "Sender Email",
//     edittable: true,
//   },
// ];

// Compare number with limit
const numberExceed = (num, limit) => {
  return parseFloat(num) > limit;
};

// Different Text for different place order condition
const placeOrderText = (error) => {
  return error ? "Submit" : "Confirm Order";
};

const useQuery = () =>{
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const BackendOrderPaymentDetails = () => {
  // const classes = useStyles;

  // const [orderId] = useState(useParams().id);
  // const [orderDetails, setOrderDetails] = useState({});
  // const [initialPartnerDetails, setInitialPartnerDetails] = useState({});
  // const [canEdit, setCanEdit] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  // const [statusMessage, setStatusMessage] = useState("");

  // useEffect(() => {
  //   const getOrderDetails = async () => {
  //     try {
  //       // Get company details from api
  //       const result = await axios.get(
  //         NODE_ROUTE_URI +
  //           "/orderPaymentManagementRouter/get3PLBackendOrderByIdForOperator",
  //         { params: { id: orderId } }
  //       );
  //       if (result.status === 200) {
  //         const newPartnerDetails = result.data;
  //         console.log(result.data);
  //         setOrderDetails(newPartnerDetails);
  //         setInitialPartnerDetails(newPartnerDetails);
  //       } else {
  //         throw new Error();
  //       }
  //     } catch (e) {
  //       return;
  //     }
  //   };
  //   getOrderDetails();
  // }, [orderId]);

  const dispatch = useDispatch();
  // const pallets = useSelector((state) => state.shipping.shippingItems);
  // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
  const userEmail = useSelector((state) => state.user.email);
  const id = useSelector((state) => state.user.id);

  const query = useQuery();
  const orderId = useParams().id;

  const [sampleLabel, setSampleLabel] = useState("");
  const [creatingOrder, setCreatingStatus] = useState(false);


  const [cardLoading, setCardLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [reload, setReload] = useState(false);
  // const [sendingEmail, setSendingStatus] = useState(false);

  const [emailSentSuccess, setEmailSentSuccessState] = useState(false);
  const [emailSentFail, setEmailSentFailState] = useState(false);

  const [toastOpen, setToastOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [cancelDiaglogOpen, setCancelDialogOpen] = useState(false);
  const [placeOrderLoading, setPlaceOrderLoading] = useState(false);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [orderNumber, setOrderNumber] = useState("");
  const [orderStatus, setOrderStatus] = useState(0);
  const [pickupName, setpickupName] = useState("");
  const [pickupPhone, setPickupPhone] = useState("");
  const [pickupAddress, setPickupAddress] = useState("");
  const [pickupAddressTwo, setPickupAddressTwo] = useState("");
  const [pickupCity, setPickupCity] = useState("");
  const [pickupProvince, setPickupProvince] = useState("");
  const [pickupPostalCode, setPickupPostalCode] = useState("");
  const [deliverAddress, setDeliverAddress] = useState("");
  const [deliverAddressTwo, setDeliverAddressTwo] = useState("");
  const [deliverProvince, setDeliverProvince] = useState("");
  const [deliverPhone, setDeliverPhone] = useState("");
  const [deliverName, setDeliverName] = useState("");
  const [deliverCity, setDeliverCity] = useState("");
  const [deliverPostalCode, setDeliverPostalCode] = useState("");
  const [pallets, setPallets] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [finalTotalAmount, setFinalTotalAmount] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [serviceFeeTax, setServiceFeeTax] = useState(0);
  const [delay, setDelay] = useState(2000);


  const [backendOrderStatus, setBackendOrderStatus] = useState(-1);

  // Shipping Order Details
  const [shippingOrderInfo, setShippingOrderInfo] = useState({
    transactions: []
  });

  const getOrderStatus = async () => {
    try {
      const accessToken = getAccessToken("access_token");
      const { data } = await axios.get(`${NODE_ROUTE_URI}/looseItem/getBackendOrderStatusByOrderId/${orderId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      console.log(data);
      setBackendOrderStatus(data.orderStatus);
    } catch (e) {
      setBackendOrderStatus(0);
      console.log(e);
    }
  }

  // const getShippingOrderInfo = async () => {
  //   try {
  //     console.log("Hello there")
  //     const accessToken = getAccessToken("access_token");
  //     const { data } = await axios.get(`${NODE_ROUTE_URI}/looseItem/getShippingOrderInfoByBackendOrderId/${orderId}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`
  //       }
  //     });
  //     console.log("shipping order info: ", data);
  //     setShippingOrderInfo(data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }


  useEffect(() => {
    if (orderId) {
      (async () => {
        // const accessToken = getAccessToken("access_token");
        // const { data } = await axios.get(`${NODE_ROUTE_URI}/looseItem/getBackendOrderWithPackagesByOrderId/${orderId}`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`
        //   }
        // });
        const result = await axios.get(
          NODE_ROUTE_URI +
            "/orderPaymentManagementRouter/get3PLBackendOrderByIdForOperator",
          { params: { id: orderId } }
        );
        const data = result.data
        console.log("Order Details: ", data);
        const {
            order_number,
            order_status,
            sender,
            pickup_phone,
            pickup_address,
            pickup_area,
            deliver_area,
            pickup_postal_code,
            deliver_address,
            deliver_phone,
            deliver_name,
            deliver_postal_code,
            ship_service_type,
            account_base_combine_name,
            packages,
            prices,
            status_id
        } = data;
        const [pickup_city, pickup_province] = pickup_area.split(", ");
        const [deliver_city, deliver_province] = deliver_area.split(", ");
        const account_base = account_base_combine_name ? account_base_combine_name.split("-")[0] : "";
        // const { taxes, final_total, service_fee, shipping_fee, residential_charge } = prices;

        setOrderNumber(order_number);
        setOrderStatus(order_status);
        setpickupName(sender);
        setPickupPhone(pickup_phone);
        setPickupAddress(pickup_address);
        setPickupCity(pickup_city);
        setPickupProvince(pickup_province);
        setPickupPostalCode(pickup_postal_code);
        setDeliverAddress(deliver_address);
        setDeliverProvince(deliver_province);
        setDeliverPhone(deliver_phone);
        setDeliverName(deliver_name);
        setDeliverCity(deliver_city);
        setDeliverPostalCode(deliver_postal_code);
        // setPallets(packages.map(v => {
        //   return {
        //     description: v.item_description,
        //     looseUnit: v.number,
        //     weightUnit: v.weight_unit,
        //     lengthUnit: v.dimension_unit,
        //     ...v
        //   }
        // }));
        setCompanyName(account_base);
        setServiceName(ship_service_type);
        // setFinalTotalAmount(final_total);
        // setShippingFee(+shipping_fee + +service_fee + +residential_charge);
        // setServiceFeeTax(taxes);
        setBackendOrderStatus(status_id);
        setOrderStatus(status_id);
      })();
    }
  }, [orderId])

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleCancelDialogOpen = () => {
    setCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
  };

  // Error indication to check the fee numbers when there are more than two packages
  const [extraFeeError, setExtraFeeError] = useState({
    pickupDock: false,
    deliverDock: false,
    appointment: false,
  });

  // const [personalEmtpy, setPersonalEmpty] = useState(false);

  const history = useHistory();
  // const location = useLocation();

  const handlePlaceOrder = async (e) => {
    handleConfirmDialogOpen();
  }

  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const example = (data) => (
      <img
          src={`data:image/jpeg;base64,${data}`}
          alt="label"
          width={700}
          height="auto"
      />
  );

  const saveAndReturn = () => {
    redirectHome(history);
  };

  return (
    <Box width={"70%"} margin="0 auto" sx={{
      display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
      alignItems: "center", alignContent: "center", textAlign: "start"
    }}>

      {/*Pop-up Toast*/}
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toastOpen}
          onClose={handleToastClose}
          autoHideDuration={3000}
          message="Cancel Order">
        {(() => {
          if (errorMessage !== "") {
            return (
                <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                  Place Order Failed!
                  <hr />
                  Error: {errorMessage}
                </Alert>
            )
          }
          return (
              <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
                Place Order Success!
              </Alert>
          )
        })()}

      </Snackbar>
      {/*Pop-up Toast*/}


      {/*Confirm Place Order Dialog*/}
      <Dialog
          open={confirmDialogOpen}
          onClose={handleConfirmDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Place Order
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will place your Shipping Order. Are you sure to continue?
            <hr />
            Click <b>Confirm</b> to place an order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} variant="outlined" autoFocus type="button" >No</Button>
          <Button onClick={() => {}} variant="outlined" type="button" disabled={placeOrderLoading} >
            {placeOrderLoading && <CircularProgress size={14} />}
            {!placeOrderLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Confirm Place Order Dialog*/}

      {/*Cancel Place Order Dialog*/}
      <Dialog
          open={cancelDiaglogOpen}
          onClose={handleCancelDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cancel Order
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This process will cancel your Shipping Order. Are you sure to continue?
            <hr />
            Click <b>Confirm</b> to cancel this order
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDialogClose} variant="outlined" autoFocus type="button" >No</Button>
          <Button onClick={() => {}} variant="outlined" type="button" disabled={cancelOrderLoading} >
            {cancelOrderLoading && <CircularProgress size={14} />}
            {!cancelOrderLoading && "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
      {/*Cancel Place Order Dialog*/}


      <Box width={"100%"} margin="0 auto">
        <h2 className="orange-title">Backend Order Details</h2>
        <ReviewOrderHeader
            pickupAddress={pickupAddress}
            pickupAddressTwo={pickupAddressTwo}
            pickupPostalCode={pickupPostalCode}
            pickupProvince={pickupProvince}
            deliverAddress={deliverAddress}
            deliverPhone={deliverPhone}
            pickupName={pickupName}
            pickupPhone={pickupPhone}
            deliverAddressTwo={deliverAddressTwo}
            deliverProvince={deliverProvince}
            deliverName={deliverName}
            deliverCity={deliverCity}
            pickupCity={pickupCity}
            deliverPostalCode={deliverPostalCode}
            pallets={pallets}
            orderNumber={orderNumber}
            orderStatus={orderStatus}
        />
        <Box mb={2}>
            <Box>
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Box className={classes.summaryHeader}>
                    <b>Order Status</b>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.summaryBodyText}>
                {orderStatus === 0 ? 'Initial, not paid' : 'Paid'}
              </Box>
            </Box>
        </Box>
        <Box>
            <Box>
              <Box>
                <Box display="flex" justifyContent="space-between">
                  <Box className={classes.summaryHeader}>
                    <b>Service</b>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.summaryBodyText}>
                {companyName}
                <ul>
                  <li>
                    <h6>{serviceName}</h6>
                  </li>
                </ul>
              </Box>
            </Box>
        </Box>

        {/* <Box>
          <Box>
            <Box>
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.summaryHeader}>
                  <b>Price</b>
                </Box>
              </Box>
            </Box>
            <Box className={classes.summaryBodyText}>
              Total: <CurrencyFormat value={finalTotalAmount || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} />
              <ul>
                <li>
                  <h6>Shipping Fee: <CurrencyFormat value={shippingFee || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h6>
                </li>
                <li>
                  <h6>Tax: <CurrencyFormat value={serviceFeeTax || 0} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h6>
                </li>
              </ul>
            </Box>
          </Box>
        </Box> */}
        <hr />
        {/* {
          backendOrderStatus === 0 && (
              <>
                <h2 className="orange-title">Payments</h2>
                <PaymentsAccordion orderId={orderId} orderNumber={orderNumber} />
              </>
            )
        } */}
        <Dialog
            maxWidth="sm"
            fullWidth
            open={dialogOpen}
            onClose={() => {
              setReload(true);
              setDialogOpen(false);
            }}
        >
          <div className={classes.dialogTitle}>
            <Box fontSize="1.5rem"></Box>
            <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => {
                  setReload(true);
                  setDialogOpen(false);
                }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          {/*<AddCreditCardPage addCard={true} popUp={true} />*/}
        </Dialog>
      </Box>

      {
          backendOrderStatus === 1 && (
              <>
                <h3 className="orange-title">Thank You! You Already Paid this Order</h3>
                <Box width={"100%"} margin="0 auto">
                  <Box>
                    {/* <ShippingOrderListTable shippingListData={shippingOrderInfo?.transactions} backendOrderId={orderId} /> */}
                  </Box>
                </Box>
              </>
          )
      }

      <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={emailSentSuccess}
          onClose={() => setEmailSentSuccessState(false)}
          message="Send email successfully"
          autoHideDuration={3000}
      />

      <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={emailSentFail}
          onClose={() => setEmailSentFailState(false)}
          message="Failed to send email"
          autoHideDuration={3000}
      />
    </Box>


    // <div>
    //   <BackArrow link={"/orderpayment/enduser/all/list"} />
    //   <Box maxWidth="70%" margin="0 auto" paddingBottom={"200px"}>
    //     <Box mt={2}>
    //       <h1>
    //         Order Payment Details
    //       </h1>
    //       {statusMessage}

    //       <table className={classes.table}>
    //         <thead>
    //           <tr>
    //             <th className={classes.idTH} width="20%"></th>
    //             <th className={classes.idTH} width="100%"></th>
    //             {/* <th className={classes.idTH} width="10%"></th> */}
    //           </tr>
    //         </thead>
    //         <tbody className={classes.tbody}>
    //         {formKeys.map((formElement, index) => {
    //               return (
    //                 <tr key={index}>
    //                   <td>{formElement.fieldName || "Placeholder"}</td>
    //                   <td>
    //                     <Box flex="45%" width="100%">
    //                       <TextField
    //                         id="outlined-basic"
    //                         value={
    //                           orderDetails[formElement.objectKey]
    //                             ? orderDetails[formElement.objectKey]
    //                             : ""
    //                         }
    //                         onChange={(e) => {
    //                           setOrderDetails((prev) => ({
    //                             ...prev,
    //                             [formElement.objectKey]: e.target.value,
    //                           }));
    //                         }}
    //                         variant="outlined"
    //                         fullWidth
    //                         disabled={!canEdit}
    //                       />
    //                     </Box>
    //                   </td>
    //                 </tr>
    //               );
    //             })}
    //         </tbody>
    //       </table>
    //     </Box>
    //   </Box>
    // </div>
  );
};


export default BackendOrderPaymentDetails;
