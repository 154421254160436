import React, {useState} from "react";
import {
    Box,
    TextField,
    useMediaQuery,
    Snackbar,
} from "@material-ui/core";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
import {useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import axios from "axios";
import {useSelector} from "react-redux";
import {SERVICE_DAO_URL, NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import {Redirect} from "react-router-dom";
import BackArrow from "../BackArrow";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {InputAdornment} from "@mui/material";
import { useLocation } from "react-router-dom";
import EndUserListTable from "./EndUserListTable";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    unTrackingTable: {
        "border-spacing": "2rem 1rem",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
        "white-space": "nowrap",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    link: {
        textDecoration: "none",
    },
}));

const EndUserAdd = () => {

    const {register, handleSubmit, control, formState: {errors}} = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const location = useLocation();



    const onSubmit = async (data) => {
        // console.log('old data', data)
        setSuccessMessage("");
        setErrorMessage("");
        setIsLoading(true);
        const driver = "false";
        delete data.phone;
        data = {...data, driver};
        // console.log('new data', data)
        // Set normalizedBusinessName as businessName

        let requestURI = `${PARTNER_URI}/login_middleware/operator/endUser/signup`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            });
            setSuccessMessage("Successfully added end user");
            console.log(result)
            // setRedirect(true)
        } catch (e) {
            setErrorMessage(`Failed to add end user, ${e?.response?.data?.message}`);
            return
        } finally {
            setToastOpen(true);
            setIsLoading(false);
        }
    }

    const handleToastClose = () => {
        setSuccessMessage("");
        setErrorMessage("");
        setToastOpen(false)
    }

    return (
        <Box paddingBottom="3rem">
            <BackArrow link={"/end-user-list"}/>
            {redirect ? <Redirect push to="/v2/end-user-list"/> : undefined}
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box>
                <Box
                    component="form"
                    width={"1000px"}
                    margin="0 auto"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        gap: "2rem",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <h1>Add End User</h1>
                    <Box flex="45%" width="100%">
                        <FormControl fullWidth>
                            {errors.email && <span>{errors.email.message}</span>}
                            <TextField
                                id="outlined-basic"
                                label="Email"
                                name="Email"
                                variant="outlined"
                                {...register("email", {
                                    required: "Email is required", pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email"
                                    }
                                })}
                                fullWidth
                                required
                            />
                        </FormControl>
                    </Box>
                    <Box flex="45%" width="100%">
                        {errors.password && <span>Password is required</span>}
                        <TextField
                            id="outlined-basic"
                            label="Password"
                            variant="outlined"
                            {...register("password", {required: true})}
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            />
                    </Box>
                    <Box flex="45%" width="100%">
                        {errors.phone && <span>Phone number is required</span>}
                        <TextField
                            id="outlined-basic"
                            label="Phone"
                            variant="outlined"
                            type="email"
                            {...register("phone")}
                            fullWidth
                        />
                    </Box>
                    <Box flex="45%" width="100%">
                        {errors.firstname && <span>First Name is required</span>}
                        <TextField
                            id="outlined-basic"
                            label="First Name"
                            variant="outlined"
                            {...register("firstname", {required: true})}
                            fullWidth
                            required
                        />
                    </Box>
                    <Box flex="45%" width="100%">
                        {errors.lastname && <span>Last Name is required</span>}
                        <TextField
                            id="outlined-basic"
                            label="Last Name"
                            variant="outlined"
                            {...register("lastname", {required: true})}
                            fullWidth
                            required
                        />
                    </Box>
                    <Box mt={2} mb={4}>
                        {errorMessage !== "" && (
                            <Alert severity="error">{errorMessage}</Alert>
                        )}
                    </Box>
                    <Box display="flex" sx={{gap: "3rem"}}>
                        <LoadingButton
                            variant="contained"
                            component="span"
                            color="primary"
                            style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                width: "10rem",
                            }}
                            loading={isLoading}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Create End User
                        </LoadingButton>
                    </Box>
                </Box>
                <hr style={{marginBlock: "3rem"}}/>
                {isLoading ? (
                    <>
                        <Box>
                            <h1>Loading...</h1>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box>
                            <Box>
                                <Box mt={2}>
                                    <EndUserListTable endUserList={location?.state?.data}/>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default EndUserAdd;