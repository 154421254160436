import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

export const MovingOrderDetailDeliver = ({orderInfo}) => {
    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Deliver Information
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Moving Type:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.deliver_addr_type?.toUpperCase() === 'SCHEDULE' ? 'Schedule Delivery' : 'Self Pickup'}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Moving Date:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            (orderInfo?.shipping_status_id === 1 || orderInfo?.shipping_status_id === 2 || orderInfo?.shipping_status_id === 20) ?
                                <Typography style={{textAlign: 'left'}}>
                                    {orderInfo?.scheduled_deliver_date ? dayjs(orderInfo?.scheduled_deliver_date).format('YYYY-MM-DD') : 'To be determined'}
                                </Typography>
                                :
                                <Typography style={{textAlign: 'left'}}>
                                    {orderInfo?.schedule_deliver ? dayjs(orderInfo?.schedule_deliver).format("YYYY-MM-DD") : 'To be determined'}
                                </Typography>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Moving Time:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {
                            (orderInfo?.shipping_status_id === 1 || orderInfo?.shipping_status_id === 2 || orderInfo?.shipping_status_id === 20) ?
                                <Typography style={{textAlign: 'left'}}>
                                    {orderInfo?.scheduled_deliver_date ? dayjs(orderInfo?.scheduled_deliver_date).format('HH:mm A') : 'To be determined'}
                                </Typography>
                                :
                                <Typography style={{textAlign: 'left'}}>
                                    {orderInfo?.task_deliver_at ? dayjs(orderInfo?.task_deliver_at).format("HH:mm A") : 'To be determined'}
                                </Typography>
                        }
                    </Grid>
                    {
                        orderInfo?.pickup_addr_type?.toUpperCase() === 'SELF' &&
                        <>
                            <Grid item xs={6}>
                                <Typography style={{textAlign: 'left'}}>
                                    Warehouse Name:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{textAlign: 'left'}}>
                                    {orderInfo?.deliver_warehouse?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{textAlign: 'left'}}>
                                    Warehouse Address:
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography style={{textAlign: 'left'}}>
                                    {orderInfo?.deliver_warehouse?.address}, {orderInfo?.deliver_warehouse?.city}, {orderInfo?.deliver_warehouse?.province}, {orderInfo?.deliver_warehouse?.country}, {orderInfo?.deliver_warehouse?.postal_code}
                                </Typography>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}