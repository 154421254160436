import {FormLabel, Grid, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ContainerShippingContainer = ({cbHandleContainerInformation}) => {

    const [containerInformation, setContainerInformation] = useState({
        type: "",
        number: "",
        weight: "",
        measurement: "",
        bol: ""
    });

    const handleType = (event) => {
        setContainerInformation(prevState => ({
            ...prevState,
            type: event.target.value
        }))
    }

    const handleNumber = (event) => {
        setContainerInformation(prevState => ({
            ...prevState,
            number: event.target.value
        }))
    }

    const handleBOL = (event) => {
        setContainerInformation(prevState => ({
            ...prevState,
            bol: event.target.value
        }))
    }

    const handleWeight = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setContainerInformation(prevState => ({
                ...prevState,
                weight: value
            }));
        }
    }

    const handleMeasurement = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setContainerInformation(prevState => ({
                ...prevState,
                measurement: value
            }));
        }
    }

    useEffect(() => {
        cbHandleContainerInformation(containerInformation);
    }, [containerInformation])

    return (
        <Grid item xs={12}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Container
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Container Type
                            </FormLabel>
                            <Select
                                value={containerInformation?.type}
                                onChange={handleType}
                                size='small'
                                style={{textAlign: 'left'}}
                            >
                                <MenuItem value='20GP'>
                                    20GP
                                </MenuItem>
                                <MenuItem value='40GP'>
                                    40GP
                                </MenuItem>
                                <MenuItem value='40HQ'>
                                    40HQ
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Container Number
                            </FormLabel>
                            <TextField
                                value={containerInformation?.number}
                                onInput={handleNumber}
                                fullWidth
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                BOL Number
                            </FormLabel>
                            <TextField
                                value={containerInformation?.bol}
                                onInput={handleBOL}
                                fullWidth
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Gross Weight(KG)
                            </FormLabel>
                            <TextField
                                value={containerInformation?.weight}
                                onInput={handleWeight}
                                fullWidth
                                variant="outlined"
                                size='small'
                                type='number'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Measurement(CBM)
                            </FormLabel>
                            <TextField
                                value={containerInformation?.measurement}
                                onInput={handleMeasurement}
                                fullWidth
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}