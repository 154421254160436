import {Fragment, useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal} from "../../utils/customComponent";

export const LTLQuoteNumber = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [quoteNumber, setQuoteNumber] = useState('');

    const onChange = (field, value) => {
        switch (field) {
            case 'quoteNumber':
                setQuoteNumber(value);
                break;
            default:
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({quoteNumber});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Quote Number
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <CustomTextFieldNormal
                            field='quoteNumber'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>

    )
}