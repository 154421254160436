import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {Fragment, useEffect, useState} from "react";
import {CustomTextFieldPallet} from "../../utils/customComponent";


const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})


export const LTLOrderDashboardSelectPartner = ({getDataFromChildComponent, triggerAction, isConfirm, validation, orderData, getPartnerInfo}) => {
    const [partnerList, setPartnerList] = useState([]);
    const [partner, setPartner] = useState('default');
    const [displayPartner, setDisplayPartner] = useState(null);

    const classes = useStyles();

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setPartnerList(result?.data);
        } catch (e) {
            console.log(e.response);
        }
    }

    const searchPartner = async (queryString) => {
        const requestURL = `${PARTNER_URI}/elasticsearch/partner/searchPartners`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: queryString,
                }
            });
            console.log('[partner list] - result', result);
            return result?.data?.result;
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleChange = (event, value) => {
        console.log(value);
        if (value) {
            setPartner(value?.id);
            setDisplayPartner(value);
            getPartnerInfo(value);
        } else {
            setPartner('default');
            setDisplayPartner(null);
        }
    };

    const handleInputChange = async (event, value) => {
        console.log('value', value);
        if (value) {
            const result = await searchPartner(value);
            setPartnerList(result);
        }
    }

    useEffect(() => {
        getAllPartnerList();
    }, []);

    useEffect(()=> {
        if (orderData && partnerList?.length > 0) {
            const partner = partnerList?.filter(each => each?.id === orderData?.partner_id);
            console.log('partner', partner);
            setDisplayPartner(partner[0]);
            setPartner(orderData?.partner_id);
        }
    },[orderData, partnerList]);

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({partner});
        }
    }, [triggerAction])

    console.log('partner list', partnerList);
    console.log('order data', orderData);
    console.log('display partner', displayPartner);

    return (
        <Fragment>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    {/*<FormControl fullWidth>*/}
                    {/*    <Autocomplete*/}
                    {/*        value={displayPartner}*/}
                    {/*        options={partnerList}*/}
                    {/*        getOptionLabel={option => option?.business_name}*/}
                    {/*        filterOptions={(options, state) => options}*/}
                    {/*        fullWidth*/}
                    {/*        disabled={isConfirm}*/}
                    {/*        renderInput={(params) => <TextField*/}
                    {/*            {...params}*/}
                    {/*            className={classes.smallInput}*/}
                    {/*            placeholder='Select A Partner'*/}
                    {/*            error={validation && validation?.partnerMissing}*/}
                    {/*            helperText={*/}
                    {/*                (validation && validation?.partnerMissing) && 'Required Field'*/}
                    {/*            }*/}
                    {/*        />}*/}
                    {/*        onInputChange={handleInputChange}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        renderOption={(option) => (*/}
                    {/*            <Typography*/}
                    {/*                style={{fontSize: '14px'}}*/}
                    {/*            >*/}
                    {/*                /!*<Typography>*!/*/}
                    {/*                {option?.business_name}*/}
                    {/*                /!*</Typography>*!/*/}
                    {/*            </Typography>*/}
                    {/*        )}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                    <CustomTextFieldPallet
                        textValue={displayPartner?.business_name}
                        field='bolNumber'
                        // onChange={onChange}
                        isConfirm={true}
                        // error={validation?.shipFromMissing?.includes('shipperEmail')}
                    />
                </Box>
            </Grid>
        </Fragment>
    )
}