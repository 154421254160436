import React, {useEffect} from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";

// import ShipmentManagementPage from "./component/ShipmentManagementPage";
import CargoControlTrackingPage from "./component/CargoControlTrackingPage";
// import OrderManagementPage from "./component/OrderManagementPage";
// import ShipmentDetailPage from "./component/ShipmentDetailPage";
import HSCodeMainPage from "./component/HSCode/HSCodeMainPage";
import BolListPage from "./component/BolListCode/BolListPage";
import CsvFFHandler from "./component/CsvFFHandler/csvFFHandler";
import ShippingListTracking from "./component/ShippingList/ShippingListTracking";
import "./App.css";
import ShippingListProduct from "./component/ShippingList/ShippingListProduct";
import ShippingListMultipleTrackingNumber from "./component/ShippingList/ShippingListMultipleTrackingNumber";
import TransactionAdjustment from "./component/TransactionAdjustment/TransactionAdjustment";
import LandingPage from "./component/landingPage";
import RoutePrice from "./component/RoutePrice/RoutePrice";
import UploadLabel from "./component/UploadLabel/uploadLabel";
import StartPalletsQuote from "./component/Pallets/startPalletsQuote";
import store from "./redux/store";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {createTheme} from "@mui/material";
import PalletsListPage from "./component/PalletsList/paletsListPage";
import CompanyList from "./component/CompanyList/CompanyList";
import CompanyDetails from "./component/CompanyList/CompanyDetails";
import CompanyAdd from "./component/CompanyList/CompanyAdd";
import PartnerList from "./component/PartnerList/PartnerList";
import PartnerDetails from "./component/PartnerList/PartnerDetails";
import EtransferEmailList from "./component/EtransferEmailList/EtransferEmailList";
import EndUserList from "./component/EndUserList/EndUserList";
import EndUserAdd from "./component/EndUserList/EndUserAdd";
import EndUserDetails from "./component/EndUserList/EndUserDetails";

//* Backend Orders
import BackendOrderPaymentList
    from "./component/3PLShippingOrders/BackendOrders/NotPaid3PLBackendOrderList/OrderPaymentList";
import AllBackendOrderPaymentList
    from "./component/3PLShippingOrders/BackendOrders/All3PLBackendOrderList/OrderPaymentList";
import BackendOrderPaymentDetails from "./component/3PLShippingOrders/BackendOrders/BackendOrderPaymentDetails";
import ShippingOrderPaymentList
    from "./component/3PLShippingOrders/ShippingOrders/NotPaid3PLShippingOrderList/OrderPaymentList"
import ShippingOrderPaymentDetails from "./component/3PLShippingOrders/ShippingOrders/ShippingOrderPaymentDetails"
import AllShippingOrderPaymentList
    from "./component/3PLShippingOrders/ShippingOrders/All3PLShippingOrderList/OrderPaymentList"

//* Transactions
import SearchTransactions from "./component/SearchTransactions/SearchTransactions";
import ShippingTransactionDetails from "./component/SearchTransactions/ShippingTransactionDetail";
import TrackPackages from "./component/Tracking/TrackPackages";
import EmailTopUp from "./component/EmailTopUp/EmailTopUp";
import {getAccessToken} from "./utils/doToken";
import axios from "axios";
import {BACKEND_ME_API} from "./utils/apiUrl";
import {getHeaders} from "./utils/fetchUtil";
import PrivateRoute from "./component/PrivateRoute";
import {AdminDashboard} from "./component/BillingDashboard/AdminDashboard";
import {MenuBar} from "./component/MenuBar";
import MainContainer from "./component/MainContainer";
import EmailCampaign from "./component/EmailCampaign/EmailCampaign";
import {LTLOrderList} from "./component/LTLOrderList/LTLOrderList";
import {LTLOrderListAdd} from "./component/LTLOrderList/LTLOrderListAdd";
import {LTLOrderDashboard} from "./component/LTLOrderDashboard/LTLOrderDashboard";
import ReviseInvoicePage from "./component/ReviseInvoice/ReviseInvoicePage";
import {LTLQuoteList} from "./component/LTLQuoteList/LTLQuoteList";
import {LTLQuoteAdd} from "./component/LTLQuoteList/LTLQuoteAdd";
import {LTLQuoteViewDetails} from "./component/LTLQuoteList/LTLQuoteViewDetails";
import {MovingCategoryList} from "./component/MovingCategoryList/MovingCategoryListPage";
import {CarQuoteList} from "./component/CarQuote/CarQuoteList";
import {CarQuoteAdd} from "./component/CarQuote/CarQuoteAdd";
import {CarQuoteDetails} from "./component/CarQuote/CarQuoteDetails";
import {MovingCategoryListAdd} from "./component/MovingCategoryList/MovingCategoryListAdd";
import {MovingCategoryListDetails} from "./component/MovingCategoryList/MovingCategoryListDetails";
import {LTLQuote} from "./component/LTLQuoteList/LTLQuote";
import {SignInPage} from "./component/LandingPage/SignInPage";
import {PackageOrders} from "./component/PackageOrders/PackageOrders";
import {PackageOrderDetails} from "./component/PackageOrders/PackageOrderDetails";
import {UploadFile} from "./component/UploadFile/UploadFile";
import {MovingOrder} from "./component/MovingOrder/MovingOrder";
import {MovingOrderList} from "./component/MovingOrder/MovingOrderList";
import {MovingOrderDetail} from "./component/MovingOrderDetail/MovingOrderDetail";
import {MovingOrderEdit} from "./component/MovingOrderEdit/MovingOrderEdit";
import {ContainerShipping} from "./component/ContainerShipping/ContainerShipping";
import {ContainerShippingList} from "./component/ContainerShipping/ContainerShippingList";
import {ContainerShippingOrderDetail} from "./component/ContainerShippingDetail/ContainerShippingOrderDetail";
import {AddressLookUp} from "./component/Tools/AddressLookUp";
import {Account} from "./component/Account/Account";
import {Tools} from "./component/Tools/Tools";
import {PickupList} from "./component/SchedulePickup/PickupList";
import ShippingExportsTransaction from "./component/ExportTransaction/ShippingExportsTransaction";
import ShippingExportTransactionPage from "./component/ShippingExportTransactionPage/ShippingExportTransactionPage";
import {Claim} from "./component/Claim/Claim";
import {ClaimDashboard} from "./component/Claim/ClaimDashboard";

const theme = createTheme({
    palette: {
        primary: {
            main: "#ffa726",
        },
    },
});

export default function App() {

    return (
        <div className="App">
            <MuiThemeProvider theme={theme}>
                <Provider store={store}>
                    <BrowserRouter>
                        <MainContainer/>
                        <Switch>
                            <Route exact path="/v2">
                                {Boolean(getAccessToken("access_token")) ? (
                                    <Redirect to="/v2/home"/>
                                ) : (
                                    <Redirect to="/v2/sign-in"/>
                                )}
                            </Route>
                            <Route exact path="/v2/sign-in" component={SignInPage}/>
                            <PrivateRoute path="/v2/home" exact component={LandingPage}/>
                            <PrivateRoute path="/v2/partner/billing-center/:partnerId" exact
                                          component={AdminDashboard}/>
                            <PrivateRoute
                                path="/v2/cargo-control-tracking"
                                exact
                                component={CargoControlTrackingPage}
                            />
                            <PrivateRoute path="/v2/end-user-list" exact component={EndUserList}/>
                            <PrivateRoute path="/v2/end-user-add" exact component={EndUserAdd}/>
                            <PrivateRoute path="/v2/end-user/:id" exact component={EndUserDetails}/>
                            {/* <Route
            path="/v2/hbl-management"
            exact
            component={HblManagementPage}
          /> */}
                            <PrivateRoute path="/v2/hs-code" exact component={HSCodeMainPage}/>
                            <PrivateRoute path="/v2/bol-ref-list" exact component={BolListPage}/>
                            <PrivateRoute path="/v2/csv-ff-handler" exact component={CsvFFHandler}/>
                            <PrivateRoute path="/v2/companyadd" exact component={CompanyAdd}/>
                            <PrivateRoute path="/v2/companylist" exact component={CompanyList}/>
                            <PrivateRoute path="/v2/company/:id" exact component={CompanyDetails}/>
                            <PrivateRoute path="/v2/partnerlist" exact component={PartnerList}/>
                            <PrivateRoute path="/v2/partner/:id" exact component={PartnerDetails}/>
                            <PrivateRoute path="/v2/orderpayment/backendorder/notpaid/list" exact
                                          component={BackendOrderPaymentList}/>
                            <PrivateRoute path="/v2/orderpayment/backendorder/notpaid/detail/:id" exact
                                          component={BackendOrderPaymentDetails}/>
                            <PrivateRoute path="/v2/orderpayment/backendorder/all/list" exact
                                          component={AllBackendOrderPaymentList}/>
                            <PrivateRoute path="/v2/orderpayment/backendorder/all/detail/:id" exact
                                          component={BackendOrderPaymentDetails}/>
                            <PrivateRoute path="/v2/orderpayment/shippingorder/notpaid/list" exact
                                          component={ShippingOrderPaymentList}/>
                            <PrivateRoute path="/v2/orderpayment/shippingorder/notpaid/detail/:id" exact
                                          component={ShippingOrderPaymentDetails}/>
                            <PrivateRoute path="/v2/orderpayment/shippingorder/all/list" exact
                                          component={AllShippingOrderPaymentList}/>
                            <PrivateRoute path="/v2/orderpayment/shippingorder/all/detail/:id" exact
                                          component={ShippingOrderPaymentDetails}/>
                            <PrivateRoute path="/v2/etransferemailList" exact component={EtransferEmailList}/>
                            <PrivateRoute path="/v2/searchtransactions" exact component={SearchTransactions}/>
                            <PrivateRoute path="/v2/shippingtransaction/:tran_id" exact
                                          component={ShippingTransactionDetails}/>
                            <PrivateRoute path="/v2/shipping/list/" exact component={ShippingListTracking}/>
                            <PrivateRoute path="/v2/shipping/list/product/" exact
                                          component={ShippingListMultipleTrackingNumber}/>
                            <PrivateRoute path="/v2/shipping/list/product/:tran_id" component={ShippingListProduct}
                                          exact/>
                            {/*<PrivateRoute path="/v2/transaction/adjustment" component={TransactionAdjustment}/>*/}
                            <PrivateRoute path="/v2/reviseInvoice" component={ReviseInvoicePage}/>
                            <PrivateRoute path="/v2/routePrice" component={RoutePrice}/>
                            <PrivateRoute path="/v2/uploadLabel" component={UploadLabel}/>
                            <PrivateRoute
                                path="/v2/LTLs/:tab"
                                component={StartPalletsQuote}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/myLTLs/"
                                component={PalletsListPage}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/myLTLs/:tab"
                                component={PalletsListPage}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/tracking"
                                component={TrackPackages}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/emailCampaign"
                                component={EmailCampaign}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/email/topUp"
                                component={EmailTopUp}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/ltl-order-list"
                                component={LTLOrderList}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/ltl-order-list/add"
                                component={LTLOrderListAdd}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/ltl-order-dashboard/:orderId"
                                component={LTLOrderDashboard}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/ltl-quote-list"
                                component={LTLQuote}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/ltl-quote-list/add"
                                component={LTLQuoteAdd}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/ltl-quote-list/:quoteId"
                                component={LTLQuoteViewDetails}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/moving-category-list"
                                component={MovingCategoryList}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/moving-category-list/add"
                                component={MovingCategoryListAdd}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/moving-category-list/:cateId"
                                component={MovingCategoryListDetails}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/car-quote-list"
                                component={CarQuoteList}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/car-quote-list/add"
                                component={CarQuoteAdd}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/car-quote-list/:quoteId"
                                component={CarQuoteDetails}
                            />
                            {/*<PrivateRoute*/}
                            {/*    exact*/}
                            {/*    path="/v2/upload-ltl-rate-file"*/}
                            {/*    component={UploadLTLFile}*/}
                            {/*/>*/}
                            <PrivateRoute
                                exact
                                path="/v2/package-orders"
                                component={PackageOrders}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/package-orders/:orderId"
                                component={PackageOrderDetails}
                            />
                            {/*<PrivateRoute*/}
                            {/*    exact*/}
                            {/*    path="/v2/upload-box-rate-file"*/}
                            {/*    component={UploadBoxFile}*/}
                            {/*/>*/}
                            <PrivateRoute
                                exact
                                path="/v2/upload-file"
                                component={UploadFile}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/moving-order-list"
                                component={MovingOrderList}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/moving-order-list/add"
                                component={MovingOrder}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/moving-order-list/:orderId"
                                component={MovingOrderDetail}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/moving-order-edit/:orderId"
                                component={MovingOrderEdit}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/container-shipping-list"
                                component={ContainerShippingList}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/container-shipping-add"
                                component={ContainerShipping}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/container-shipping-detail/:orderId"
                                component={ContainerShippingOrderDetail}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/tools"
                                component={Tools}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/account"
                                component={Account}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/pickup-list"
                                component={PickupList}
                            />
                            {/*<PrivateRoute*/}
                            {/*  exact*/}
                            {/*  path="/v2/shipping/exportsTransactions"*/}
                            {/*  component={ShippingExportsTransaction}*/}
                            {/*/>*/}
                            {/*<PrivateRoute*/}
                            {/*  exact*/}
                            {/*  path="/v2/shipping/exportsTransactions"*/}
                            {/*  component={ShippingExportTransactionPage}*/}
                            {/*/>*/}
                            <PrivateRoute
                              exact
                              path="/v2/transaction/adjustment"
                              component={ShippingExportTransactionPage}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/claim-list"
                                component={Claim}
                            />
                            <PrivateRoute
                                exact
                                path="/v2/claim-list/:claimId"
                                component={ClaimDashboard}
                            />
                        </Switch>
                    </BrowserRouter>
                </Provider>
            </MuiThemeProvider>
        </div>
    );
}
