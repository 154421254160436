import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";
import Paper from "@mui/material/Paper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Carousel from "react-material-ui-carousel";


export const MovingOrderDetailImage = ({orderInfo}) => {
    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Image List
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{padding: '20px'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Carousel
                                        navButtonsAlwaysVisible={true}
                                        // indicators={false}
                                        fullHeightHover={false}     // We want the nav buttons wrapper to only be as big as the button element is
                                        NextIcon={<NavigateNextIcon/>}
                                        PrevIcon={<NavigateBeforeIcon/>}
                                        autoPlay={false}
                                    >
                                        {
                                            orderInfo?.images?.map((item, index) => (
                                                <Paper key={index}>
                                                    <img
                                                        src={`${LABEL_DOWNLOAD_URL_PREFIX}${(item?.file_location_uri).startsWith("/") ? item?.file_location_uri?.slice(1) : item?.file_location_uri}`}
                                                        alt={`image${index}`}
                                                        style={{height: '300px', objectFit: 'cover'}}
                                                    />
                                                </Paper>
                                            ))
                                        }
                                    </Carousel>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}