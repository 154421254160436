import {Fragment, useEffect, useRef, useState} from "react";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldPallet} from "../../utils/customComponent";

export const LTLOrderListReferenceOrderNumber = ({
                                                     getDataFromChildComponent,
                                                     triggerAction,
                                                     isConfirm,
                                                 }) => {

    const [referenceOrderNumber, setReferenceOrderNumber] = useState();

    const onChange = (field, value) => {
        switch (field) {
            case 'referenceOrderNumber':
                setReferenceOrderNumber(value);
                break;
            default:
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({referenceOrderNumber});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <CustomTextFieldPallet
                            textValue={referenceOrderNumber}
                            field='referenceOrderNumber'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Box>
            </Grid>
        </Fragment>
    )

}