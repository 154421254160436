import {Button, Container, Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ContainerShippingAddress} from "./ContainerShippingAddress";
import {useState} from "react";
import {ContainerShippingContainer} from "./ContainerShippingContainer";
import {ContainerShippingVessel} from "./ContainerShippingVessel";
import {ContainerShippingScheduleAndPorts} from "./ContainerShippingScheduleAndPorts";
import {ContainerShippingSelectUser} from "./ContainerShippingSelectUser";
import LoadingButton from "@mui/lab/LoadingButton";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {getMissingKeysForContainerShipping, isValidatePhone} from "../../utils/Helper";
import {getAccessToken} from "../../utils/doToken";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ContainerShipping = () => {

    const history = useHistory();

    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [userInformation, setUserInformation] = useState(null)
    const [shipper, setShipper] = useState(null);
    const [consignee, setConsignee] = useState(null);
    const [vessel, setVessel] = useState(null);
    const [scheduleAndPort, setScheduleAndPort] = useState(null);
    const [containerInformation, setContainerInformation] = useState(null)
    const [validationResultPlaceOrder, setValidationResultPlaceOrder] = useState(null);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbHandleUserInformation = (data) => {
        setUserInformation(data);
    }

    const cbHandleShipper = (data) => {
        setShipper(data);
    }

    const cbHandleConsignee = (data) => {
        setConsignee(data);
    }

    const cbHandleVessel = (data) => {
        setVessel(data);
    }

    const cbHandleScheduleAndPort = (data) => {
        setScheduleAndPort(data);
    }

    const cbHandleContainerInformation = (data) => {
        setContainerInformation(data);
    }

    const validationPlaceOrder = async () => {
        setErrorMessage('');
        setToastOpen(false);

        const shipperMissingKeys = getMissingKeysForContainerShipping(shipper);
        const consigneeMissingKeys = getMissingKeysForContainerShipping(consignee);
        const shipperPhoneError = isValidatePhone(shipper?.phone);
        const consigneePhoneError = isValidatePhone(consignee?.phone);

        !userInformation?.id && setErrorMessage(prevState => prevState + 'User information is missing.');
        // shipperMissingKeys.length > 0 && setErrorMessage(prevState => prevState + ' Shipper is missing one or more required fields.');
        // consigneeMissingKeys.length > 0 && setErrorMessage(prevState => prevState + ' Consignee is missing one or more required fields.');
        // shipperPhoneError && setErrorMessage(prevState => prevState + ' Shipper phone is invalid.');
        // consigneePhoneError && setErrorMessage(prevState => prevState + ' Consignee phone is invalid.');

        setValidationResultPlaceOrder(prevState => ({
            ...prevState,
            selectUserError: !userInformation?.id,
            // contactMissing: missingKeys,
            shipperMissingKeys,
            consigneeMissingKeys,
            shipperPhoneError,
            consigneePhoneError
        }))

        if (!userInformation?.id
            // shipperMissingKeys?.length > 0 ||
            // consigneeMissingKeys?.length > 0 ||
            // shipperPhoneError ||
            // consigneePhoneError
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handlePlaceContainerShippingOrder = async () => {
        setLoading(true);
        let requestURL = `${PARTNER_URI}/oceanShipping/initOceanShippingOrder`;

        const packages = [];

        const data = {
            userId: userInformation?.id,
            ownerRole: userInformation?.ownerRole,
            bolNumber: containerInformation?.bol,
            shipperName: shipper?.name,
            shipperPhone: shipper?.phone,
            shipperAddress: shipper?.streetAddress,
            shipperAddressTwo: shipper?.apt,
            shipperCity: shipper?.city,
            shipperProvince: shipper?.province?.code || '',
            shipperCountry: shipper?.country,
            shipperPostalCode: shipper?.postalCode,
            consigneeName: consignee?.name,
            consigneePhone: consignee?.phone,
            consigneeAddress: consignee?.streetAddress,
            consigneeAddressTwo: consignee?.apt,
            consigneeCity: consignee?.city,
            consigneeProvince: consignee?.province?.code || '',
            consigneeCountry: consignee?.country,
            consigneePostalCode: consignee?.postalCode,
            vesselNumber: vessel?.vesselNumber,
            voyage: vessel?.voyage || 0,
            routeCode: vessel?.routeCode,
            shipCompany: vessel?.shipCompany,
            ETD: scheduleAndPort?.ETD ? dayjs(scheduleAndPort?.ETD)?.format('YYYY-MM-DD HH:mm') : '',
            ETA: scheduleAndPort?.ETA ? dayjs(scheduleAndPort?.ETA)?.format('YYYY-MM-DD HH:mm') : '',
            portOfLoading: scheduleAndPort?.loadingPort,
            portOfDischarge: scheduleAndPort?.dischargePort,
            packages: [...packages, {
                containerNumber: containerInformation?.number,
                containerType: containerInformation?.type,
                grossWeightKG: containerInformation?.weight || 0,
                measurementCBM: containerInformation?.measurement || 0,
            }]
        }

        console.log('[handlePlaceContainerShippingOrder] data', data);

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            console.log('[handlePlaceContainerShippingOrder] result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully Place Container Shipping Order');
            handleSuccessMessage();
            setTimeout(() => {
                history.push(`/v2/container-shipping-detail/${result?.data?.orderId}`);
            }, 2000);
        } catch (e) {
            console.log('[handlePlaceContainerShippingOrder] error', e.response);
            setErrorMessage('Fail to Place Container Shipping Order');
            handleErrorMessage();
        } finally {
            setLoading(false)
        }
    }

    const handlePlaceOrder = async () => {
        const validationResult = await validationPlaceOrder();
        if (validationResult) {
            await handlePlaceContainerShippingOrder();
        }
    }

    const handleBack = () => {
        history.push('/v2/container-shipping-list/')
    }

    console.log('[ContainerShipping] userInformation', userInformation);
    console.log('[ContainerShipping] shipper', shipper);
    console.log('[ContainerShipping] consignee', consignee);
    console.log('[ContainerShipping] vessel', vessel);
    console.log('[ContainerShipping] scheduleAndPort', scheduleAndPort);
    console.log('[ContainerShipping] containerInformation', containerInformation);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Container Shipping
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <ContainerShippingSelectUser cbHandleUserInformation={cbHandleUserInformation}
                                                     validationResultPlaceOrder={validationResultPlaceOrder}/>
                        <ContainerShippingAddress cbHandleAddress={cbHandleShipper} heading='Shipper'
                                                  validationResultPlaceOrder={{
                                                      missingKeys: validationResultPlaceOrder?.shipperMissingKeys,
                                                      phoneError: validationResultPlaceOrder?.shipperPhoneError
                                                  }}/>
                        <ContainerShippingAddress cbHandleAddress={cbHandleConsignee} heading='Consignee'
                                                  validationResultPlaceOrder={{
                                                      missingKeys: validationResultPlaceOrder?.consigneeMissingKeys,
                                                      phoneError: validationResultPlaceOrder?.consigneePhoneError
                                                  }}/>
                        <ContainerShippingVessel cbHandleVessel={cbHandleVessel}/>
                        <ContainerShippingScheduleAndPorts cbHandleScheduleAndPort={cbHandleScheduleAndPort}/>
                        <ContainerShippingContainer cbHandleContainerInformation={cbHandleContainerInformation}/>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#454545',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#454545',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleBack}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Back
                                    </Typography>
                                </Button>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handlePlaceOrder}
                                    loading={loading}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Place Order
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}