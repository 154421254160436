import {actionType} from "../../utils/redux";

const initState = {
    searchParams: null
}

export const packageOrderReducer = (state = initState, action) => {
    switch (action.type) {
        case actionType.SAVE_PACKAGE_LIST_SEARCH_PARAMS:
            return {...state, searchParams: action.payload}
        default:
            return state
    }
}