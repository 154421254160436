import {Button, FormLabel, Grid, Link, Snackbar, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import {CustomTextFieldPallet} from "../../utils/customComponent";
import LoadingButton from "@mui/lab/LoadingButton";
import {Fragment, useEffect, useRef, useState} from "react";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, NODE_ROUTE_URI} from "../../utils/apiUrl";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardPOD = ({orderData, getDataFromChildComponent, triggerAction, validation, isConfirm, getBOLNumber, getOrderDetails}) => {

    const inputPDFFilePOD = useRef(null);

    const [podDocURI, setPodDocURI] = useState();
    const [fileNamePOD, setFileNamePOD] = useState();
    const [uploadPDFLoadingPOD, setUploadPDFLoadingPOD] = useState(false);

    const [originalPodDocURI, setOriginalPodDocURI] = useState();
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const uploadPDFToServerPOD = async ({fileObj}) => {
        try {
            if (!fileObj) {
                return;
            }
            console.log('file obj', fileObj);
            setUploadPDFLoadingPOD(true);
            const formData = new FormData();
            formData.append('file', fileObj);

            const {data} = await axios.post(`${NODE_ROUTE_URI}/shippingLTLForAdmin/uploadShippingLTLBOL`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data;",
                }
            })
            setPodDocURI(data?.fileURL);
            setFileNamePOD(fileObj?.name);
        } catch (e) {
            console.log(e);
        } finally {
            setUploadPDFLoadingPOD(false);
        }
    }

    const onPDFDropPOD = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }

        await uploadPDFToServerPOD({fileObj: e.target.files[0]});
    };

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    podDocURI: podDocURI || "",
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalPodDocURI(podDocURI)
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }



    useEffect(() => {
        setPodDocURI(orderData?.pod_doc_uri);
        setFileNamePOD(orderData?.pod_doc_uri?.split('/')?.pop());
        setOriginalPodDocURI(orderData?.pod_doc_uri);
    },[orderData])


    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        POD Label
                    </FormLabel>
                </FormControl>
                <Box sx={{
                    display: 'flex',
                }}>
                    <CustomTextFieldPallet
                        textValue={fileNamePOD}
                        field='podDocURI'
                        // onChange={onChange}
                        isConfirm={true}
                    />
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    height: '100%'
                }}>
                    <input type="file" style={{display: "none"}} ref={inputPDFFilePOD} onChange={onPDFDropPOD}
                           accept=".pdf"
                           name="file"/>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#454545',
                            "&:hover": {
                                backgroundColor: '#454545',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => {
                            inputPDFFilePOD.current.click();
                        }}
                        loading={uploadPDFLoadingPOD}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Upload
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    height: '100%'
                }}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        disabled={!orderData?.pod_doc_uri}
                    >
                        <Link
                            href={`${LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE}${orderData?.pod_doc_uri}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="none"
                        >
                            <Typography style={{
                                textTransform: 'none',
                                color: '#FFFFFF',
                            }}>
                                Download
                            </Typography>
                        </Link>
                    </Button>
                </Box>
            </Grid>
            {
                (podDocURI !== originalPodDocURI) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>

    )
}