import {Button, Container, FormLabel, Grid, InputAdornment, Snackbar, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {CustomTextField, CustomTextFieldNormal} from "../../utils/customComponent";
import InputLabel from "@mui/material/InputLabel";
import {useState} from "react";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {LTLOrderListPallet} from "./LTLOrderListPallet";
import {LTLOrderListSpecialService} from "./LTLOrderListSpecialService";
import {LTLOrderListShipperAddress} from "./LTLOrderListShipperAddress";
import {LTLOrderListConsigneeAddress} from "./LTLOrderListConsigneeAddress";
import {LTLOrderListPickupDateAndTime} from "./LTLOrderListPickupDateAndTime";
import {LTLOrderListSelectCarrier} from "./LTLOrderListSelectCarrier";
import {LTLOrderListShippingCost} from "./LTLOrderListShippingCost";
import LoadingButton from "@mui/lab/LoadingButton";
import {LTLOrderListSelectPartner} from "./LTLOrderListSelectPartner";
import {
    getMissingKeyPickupDateAndTime,
    getMissingKeysConsigneeInfo,
    getMissingKeysShipperInfo
} from "../../utils/Helper";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../utils/constInfo";
import axios from "axios";
import {useHistory} from "react-router-dom";
import {LTLOrderListInsurance} from "./LTLOrderListInsurance";
import {LTLOrderListBrokerage} from "./LTLOrderListBrokerage";
import {LTLOrderListPurchaseOrderNumber} from "./LTLOrderListPurchaseOrderNumber";
import {LTLOrderListReferenceOrderNumber} from "./LTLOrderListReferenceOrderNumber";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingLTLQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight", "quantity"];

export const LTLOrderListAdd = () => {

    const history = useHistory();
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [type, setType] = useState('addOrder');
    const [referenceOrderNumber, setReferenceOrderNumber] = useState();
    const [purchaseOrderNumber, setPurchaseOrderNumber] = useState();
    const [FTL, setFTL] = useState();
    const [shipperInfo, setShipperInfo] = useState();
    const [consigneeInfo, setConsigneeInfo] = useState();
    const [palletList, setPalletList] = useState();
    const [shippingCost, setShippingCost] = useState();
    const [selectCarrier, setSelectCarrier] = useState();
    const [shippingDateAndTime, setShippingDateAndTime] = useState();
    const [specialService, setSpecialService] = useState();
    const [partner, setPartner] = useState();
    const [insurance, setInsurance] = useState();
    const [brokerage, setBrokerage] = useState();
    const [loading, setLoading] = useState(false);

    const [isConfirm, setIsConfirm] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [validation, setValidation] = useState(null);

    const [triggerAction, setTriggerAction] = useState(false);
    const [selectPartner, setSelectedPartner] = useState(null);
    const [shipperResidential, setShipperResidential] = useState(false);
    const [consigneeResidential, setConsigneeResidential] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    // const validateShipperInfo = (shipperData) => {
    //     const shipperInfoMissing = getMissingKeysShipperInfo(shipperData);
    //     console.log('shipper missing', shipperInfoMissing);
    //     shipperInfoMissing?.length !== 0 && setErrorMessage(prevState => prevState + "Shipper information is missing one or more required fields.");
    //     setShipperInfoValidation(shipperInfoMissing);
    // }

    const handleEdit = () => {
        setIsConfirm(false);
    }

    const getShipperResidential = (data) => {
        console.log('shipper residential', data);
        setShipperResidential(data);
    }

    const getConsigneeResidential = (data) => {
        console.log('consignee residential', data);
        setConsigneeResidential(data);
    }

    const getPartnerInfo = (data) => {
        console.log('partner info', data);
        setSelectedPartner(data);
    }

    const handleButtonClick = () => {
        // Set the state to trigger the action in the child component
        setTriggerAction(true);
    };

    const getDataFromChildComponent = (data) => {
        console.log('child data', data);
        handleDataFromChildComponent(data);
        setTriggerAction(false);
        setIsConfirm(true);
    }

    const handleDataFromChildComponent = (data) => {
        const key = Object.keys(data);
        switch (key[0]) {
            case 'shipperInfo':
                setShipperInfo(data?.shipperInfo);
                break
            case 'consigneeInfo':
                setConsigneeInfo(data?.consigneeInfo);
                break
            case 'palletList':
                setPalletList(data?.palletList);
                break
            case 'selectCarrier':
                setSelectCarrier(data?.selectCarrier);
                break
            case 'shippingCost':
                setShippingCost(data?.shippingCost);
                break
            case 'specialService':
                setSpecialService(data?.specialService);
                break
            case 'shipDateAndTime':
                setShippingDateAndTime(data?.shipDateAndTime);
                break
            case 'partner':
                setPartner(data?.partner);
                break
            case 'insurance':
                setInsurance(data?.insurance);
                break
            case 'brokerage':
                setBrokerage(data?.brokerage);
                break
            case 'FTL':
                setFTL(data?.FTL);
                break
            case 'purchaseOrderNumber':
                setPurchaseOrderNumber(data?.purchaseOrderNumber);
                break
            case 'referenceOrderNumber':
                setReferenceOrderNumber(data?.referenceOrderNumber);
                break
            default:
        }
    }

    const createBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/createLTLOrder`;

            let data =
                {
                    partnerId: partner,
                    packageData: palletList,
                    ftlCount: FTL || 0,
                    shipperName: shipperInfo?.shipperName,
                    shipperPhone: shipperInfo?.shipperPhone,
                    shipperCompanyName: shipperInfo?.shipperCompanyName,
                    shipperEmail: shipperInfo?.shipperEmail,
                    shipperAddress: shipperInfo?.shipperAddress,
                    shipperAddressType: specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
                    shipperCity: shipperInfo?.shipperCity,
                    shipperProvince: shipperInfo?.shipperProvince,
                    shipperPostalCode: shipperInfo?.shipperPostalCode,
                    shipperCountry: shipperInfo?.shipperCountry,
                    consigneeName: consigneeInfo?.consigneeName,
                    consigneePhone: consigneeInfo?.consigneePhone,
                    consigneeCompanyName: consigneeInfo?.consigneeCompanyName,
                    consigneeEmail: consigneeInfo?.consigneeEmail,
                    consigneeAddress: consigneeInfo?.consigneeAddress,
                    consigneeAddressType: specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
                    consigneeCity: consigneeInfo?.consigneeCity,
                    consigneeProvince: consigneeInfo?.consigneeProvince,
                    consigneePostalCode: consigneeInfo?.consigneePostalCode,
                    consigneeCountry: consigneeInfo?.consigneeCountry,
                    serviceName: selectCarrier,
                    shipDate: shippingDateAndTime?.shipDate,
                    pickupLocationCloseTime: `${shippingDateAndTime?.shipDate} ${shippingDateAndTime?.latestPickupTime}`,
                    pickupLocationOpenTime: `${shippingDateAndTime?.shipDate} ${shippingDateAndTime?.earliestPickupTime}`,
                    pickupAvailableTime: `${shippingDateAndTime?.shipDate} ${shippingDateAndTime?.availablePickupTime}`,
                    pickupDate: shippingDateAndTime?.shipDate,
                    transMode: "INT_TRUCK",
                    shipmentType: "COMMERCIAL",
                    specialServices: specialService,
                    poNumber: purchaseOrderNumber || "",
                    refNumber: referenceOrderNumber || "",
                    shippingFee: (+shippingCost?.shippingFee),
                    serviceFee: (+shippingCost?.serviceFee),
                    taxCharge: (+shippingCost?.taxCharge),
                    crossBorderFee: (+shippingCost?.crossBorderFee),
                    totalCharge: (+shippingCost?.totalCharge + +shippingCost?.crossBorderFee),
                    discount: (+shippingCost?.discount),
                    insuranceFee: (+insurance?.insuranceCost),
                    brokerageFee: (+brokerage?.brokerageCost),
                    dutyTax: (+brokerage?.dutyTax),
                    insuranceDescription: insurance?.description,
                    brokerageDescription: brokerage?.description,
                    finalTotal: (+shippingCost?.finalTotal + +insurance?.insuranceCost + +brokerage?.brokerageCost + +brokerage?.dutyTax)
                }

            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Create Order');
            handleSuccessMessage();
            setTimeout(() => {
                history.push("/v2/ltl-order-list");
            }, 2000);

        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Create Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysShipperInfo(shipperInfo);
        const shipToMissing = getMissingKeysConsigneeInfo(consigneeInfo);
        const hasMissingValues = palletList?.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = palletList?.some(object => validateRequiredProperties.some((property) => object[property] <= 0));
        const shipDateAndTimeMissing = getMissingKeyPickupDateAndTime(shippingDateAndTime);

        const validation = {
            shipFromMissing,
            shipToMissing,
            hasMissingValues,
            hasValidateValues,
            partnerMissing: partner === 'default' || !partner,
            carrierMissing: selectCarrier === 'default' || !selectCarrier,
            palletListMissing: palletList?.length === 0,
            shipDateAndTimeMissing
        }
        console.log('validation', validation);
        setValidation(validation);
        // dispatch(updateCreateLabelValidation(validation));

        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Shipper information is missing one or more required fields.');
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Consignee information is missing one or more required fields.');
        (partner === 'default' || !partner) && setErrorMessage(prevState => prevState + 'Partner is missing.');
        (selectCarrier === 'default' || !selectCarrier) && setErrorMessage(prevState => prevState + 'Carrier is missing.');
        hasMissingValues && setErrorMessage(prevState => prevState + 'You pallet list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + 'You pallet list has one or more invalid fields.');
        palletList?.length === 0 && setErrorMessage(prevState => prevState + 'Pallet list is empty.');
        shipDateAndTimeMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Pickup date or time is missing one or more required fields.');

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            partner !== 'default' &&
            partner &&
            selectCarrier !== 'default' &&
            selectCarrier &&
            palletList.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            shipDateAndTimeMissing.length === 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreateOrder = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            createBackendOrder();
        }
    }

    const handleChangeType = (value) => {
        console.log('[handleChangeType] value', value);
        if (value === 'orderList') {
            history.push({
                pathname: '/v2/ltl-order-list',
                state: value
            })
        } else if (value !== 'addOrder') {
            history.push({
                pathname: '/v2/ltl-quote-list',
                state: value
            })
        } else {
            setType(value);
        }
    }

    // console.log('shipper info', shipperInfo);
    // console.log('consignee info', consigneeInfo);
    // console.log('pallet list', palletList);
    // console.log('shipping cost', shippingCost);
    // console.log('date and time', shippingDateAndTime);
    // console.log('special service', specialService);
    // console.log('select carrier', selectCarrier);
    // console.log('partner', partner);
    // console.log('[LTLOrderListAdd] insurance', insurance);
    // console.log('[LTLOrderListAdd] brokerage', brokerage);
    // console.log('[LTLOrderListAdd] purchaseOrderNumber', purchaseOrderNumber);
    // console.log('[LTLOrderListAdd] referenceOrderNumber', referenceOrderNumber);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingLTLQuickQuoteSection}>
                <Box
                    sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'quickQuote'}
                            onClick={() => handleChangeType('quickQuote')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quick Quote
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'quoteList'}
                            onClick={() => handleChangeType('quoteList')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quote List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'addQuote'}
                            onClick={() => handleChangeType('addQuote')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Add Quote
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'orderList'}
                            onClick={() => handleChangeType('orderList')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Order List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'addOrder'}
                            onClick={() => handleChangeType('orderOrder')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Add Order
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'setting'}
                            onClick={() => handleChangeType('setting')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Service Fee Setting
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Create LTL Order
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box sx={styles.ShippingOrderList}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Select Partner
                                        </Typography>
                                    </Grid>
                                    <LTLOrderListSelectPartner
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                        validation={validation}
                                        getPartnerInfo={getPartnerInfo}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={styles.ShippingOrderList}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Select Carrier
                                        </Typography>
                                    </Grid>
                                    <LTLOrderListSelectCarrier
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                        validation={validation}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Shipper Information
                                </Typography>
                            </Grid>
                            <LTLOrderListShipperAddress
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                selectPartner={selectPartner}
                                getShipperResidential={getShipperResidential}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Consignee Information
                                </Typography>
                            </Grid>
                            <LTLOrderListConsigneeAddress
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                getConsigneeResidential={getConsigneeResidential}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Pallet List
                                </Typography>
                            </Grid>
                            <LTLOrderListPallet
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Special Service
                                </Typography>
                            </Grid>
                            <LTLOrderListSpecialService
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                shipperResidential={shipperResidential}
                                consigneeResidential={consigneeResidential}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Insurance
                                </Typography>
                            </Grid>
                            <LTLOrderListInsurance
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Custom Brokerage and Duty Tax
                                </Typography>
                            </Grid>
                            <LTLOrderListBrokerage
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Shipping Cost
                                </Typography>
                            </Grid>
                            <LTLOrderListShippingCost
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Pickup Date and Time
                                </Typography>
                            </Grid>
                            <LTLOrderListPickupDateAndTime
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box sx={styles.ShippingOrderList}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Purchase Order Number
                                        </Typography>
                                    </Grid>
                                    <LTLOrderListPurchaseOrderNumber
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={styles.ShippingOrderList}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Reference Order Number
                                        </Typography>
                                    </Grid>
                                    <LTLOrderListReferenceOrderNumber
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                    />
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                "&:hover": {
                                    backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={isConfirm ? handleEdit : handleButtonClick}
                        >
                            <Typography style={{
                                textTransform: 'none'
                            }}>
                                {isConfirm ? 'Edit' : 'Confirm'}
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleCreateOrder}
                            disabled={!isConfirm}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none'
                            }}>
                                Create
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}