import {Divider, Grid, Typography} from "@mui/material";
import {Fragment} from "react";
import {LTLQuoteListEach} from "./LTLQuoteListEach";

export const LTLQuoteListTable = ({quote}) => {

    console.log('[LTLQuoteListTable] quote', quote);

    return (
        <Grid container spacing={1}>
            <Grid item md={1.875} xs={1.875}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Shipping From
                </Typography>
            </Grid>
            <Grid item md={1.875} xs={1.875}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Shipping To
                </Typography>
            </Grid>
            <Grid item md={0.75} xs={0.75}>
                <Typography style={{fontWeight: '600'}}>
                    Service
                </Typography>
            </Grid>
            <Grid item md={1} xs={0.75}>
                <Typography style={{fontWeight: '600'}}>
                    Carrier
                </Typography>
            </Grid>
            <Grid item md={1.5} xs={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    Cost
                </Typography>
            </Grid>
            <Grid item md={1} xs={1}>
                <Typography style={{fontWeight: '600'}}>
                    Total Weight / Quantity
                </Typography>
            </Grid>
            <Grid item md={1} xs={1}>
                <Typography style={{fontWeight: '600'}}>
                    Quote #
                </Typography>
            </Grid>
            <Grid item md={1} xs={1}>
                <Typography style={{fontWeight: '600'}}>
                    Expired Date
                </Typography>
            </Grid>
            <Grid item md={0.75} xs={0.75}>
                <Typography style={{fontWeight: '600'}}>
                    Status
                </Typography>
            </Grid>
            <Grid item md={1.25} xs={1.25}>
                <Typography style={{fontWeight: '600'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                quote?.map((quoteEach, index) =>
                    <Fragment key={index}>
                        <LTLQuoteListEach quoteInfo={quoteEach}/>
                    </Fragment>
                )
            }
        </Grid>
    )
}