import {FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../utils/doToken";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ContainerShippingOrderDetailContainer = ({orderInfo, getContainerShippingOrderInfo}) => {

    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [containerInformation, setContainerInformation] = useState({
        type: "",
        number: "",
        weight: "",
        measurement: "",
        bol: ""
    });
    const [originalContainerInformation, setOriginalContainerInformation] = useState({
        type: "",
        number: "",
        weight: "",
        measurement: "",
        bol: ""
    });

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleType = (event) => {
        setContainerInformation(prevState => ({
            ...prevState,
            type: event.target.value
        }))
    }

    const handleNumber = (event) => {
        setContainerInformation(prevState => ({
            ...prevState,
            number: event.target.value
        }))
    }

    const handleBOL = (event) => {
        setContainerInformation(prevState => ({
            ...prevState,
            bol: event.target.value
        }))
    }

    const handleWeight = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setContainerInformation(prevState => ({
                ...prevState,
                weight: value
            }));
        }
    }

    const handleMeasurement = (event) => {
        const value = event.target.value;
        if (value === "" || /^(0|[1-9]\d*)(\.\d+)?$/.test(value)) {
            setContainerInformation(prevState => ({
                ...prevState,
                measurement: value
            }));
        }
    }

    const handleUpdateContainerShippingOrderPackage = async () => {
        // setLoading(true);
        try {
            let requestURL = `${PARTNER_URI}/oceanShipping/editOceanBOLPackage`;

            let data = {
                packageId: containerInformation?.id,
                containerNumber: containerInformation?.number,
                containerType: containerInformation?.type,
                grossWeightKG: +containerInformation?.weight,
                measurementCBM: +containerInformation?.measurement,
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // setErrorMessage('');
            // setSuccessMessage('Successful Update Container Shipping Order');
            // handleSuccessMessage();
            // setTimeout(() => {
            //     getContainerShippingOrderInfo();
            // }, 1000);
        } catch (e) {
            console.log(e.response);
            // setErrorMessage('Fail Update Container Shipping Order');
            // handleErrorMessage();
            throw e;
        } finally {
            setLoading(false);
        }
    }

    const handleUpdateContainerShippingOrderBOL = async () => {
        // setLoading(true);
        try {
            let requestURL = `${PARTNER_URI}/oceanShipping/editOceanBackendOrderByOrderId`;

            let data = {
                backendOrderId: orderInfo?.orderId,
                bolNumber: containerInformation?.bol
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            // setErrorMessage('');
            // setSuccessMessage('Successful Update Container Shipping Order');
            // handleSuccessMessage();
            // setTimeout(() => {
            //     getContainerShippingOrderInfo();
            // }, 1000);
        } catch (e) {
            console.log(e.response);
            // setErrorMessage('Fail Update Container Shipping Order');
            // handleErrorMessage();
            throw e;
        } finally {
            setLoading(false);
        }
    }

    const handleUpdateContainerShippingOrder = async () => {
        setLoading(true);
        try {
            await handleUpdateContainerShippingOrderPackage();
            await handleUpdateContainerShippingOrderBOL();
            setErrorMessage('');
            setSuccessMessage('Successful Update Container Shipping Order');
            handleSuccessMessage();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Container Shipping Order');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (orderInfo) {
            setContainerInformation(prevState => ({
                ...prevState,
                type: orderInfo?.packages[0]?.containerType,
                number: orderInfo?.packages[0]?.containerNumber,
                weight: orderInfo?.packages[0]?.grossWeightKG,
                measurement: orderInfo?.packages[0]?.measurementCBM,
                id: orderInfo?.packages[0]?.id,
                bol: orderInfo?.bolNumber
            }));
            setOriginalContainerInformation(prevState => ({
                ...prevState,
                type: orderInfo?.packages[0]?.containerType,
                number: orderInfo?.packages[0]?.containerNumber,
                weight: orderInfo?.packages[0]?.grossWeightKG,
                measurement: orderInfo?.packages[0]?.measurementCBM,
                id: orderInfo?.packages[0]?.id,
                bol: orderInfo?.bolNumber
            }));
        }
    }, [orderInfo]);

    console.log('[ContainerShippingOrderDetailContainer] orderInfo', orderInfo);
    console.log('[ContainerShippingOrderDetailContainer] containerInformation', containerInformation);
    console.log('[ContainerShippingOrderDetailContainer] originalContainerInformation', originalContainerInformation);

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Container
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Container Type
                                </FormLabel>
                                <Select
                                    value={containerInformation?.type}
                                    onChange={handleType}
                                    size='small'
                                    style={{textAlign: 'left'}}
                                    // disabled={true}
                                >
                                    <MenuItem value='20GP'>
                                        20GP
                                    </MenuItem>
                                    <MenuItem value='40GP'>
                                        40GP
                                    </MenuItem>
                                    <MenuItem value='40HQ'>
                                        40HQ
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Container Number
                                </FormLabel>
                                <TextField
                                    value={containerInformation?.number}
                                    onInput={handleNumber}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    BOL Number
                                </FormLabel>
                                <TextField
                                    value={containerInformation?.bol}
                                    onInput={handleBOL}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Gross Weight(KG)
                                </FormLabel>
                                <TextField
                                    value={containerInformation?.weight}
                                    onInput={handleWeight}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    type='number'
                                    // disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Measurement(CBM)
                                </FormLabel>
                                <TextField
                                    value={containerInformation?.measurement}
                                    onInput={handleMeasurement}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        {
                            (
                                (containerInformation?.type !== originalContainerInformation?.type) ||
                                (containerInformation?.number !== originalContainerInformation?.number) ||
                                (containerInformation?.bol !== originalContainerInformation?.bol) ||
                                (+containerInformation?.weight !== +originalContainerInformation?.weight) ||
                                (+containerInformation?.measurement !== +originalContainerInformation?.measurement)
                            ) &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleUpdateContainerShippingOrder}
                                        loading={loading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )

}