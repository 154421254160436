import {CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {useState} from "react";
import {styled} from "@mui/material/styles";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {Button, Snackbar, Stack, Switch} from "@mui/material";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {CreditCardSection} from "./CreditCardSection";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 32,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 20,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#1D8B45' : '#1D8B45',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 16,
        height: 16,
        borderRadius: 8,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 20 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const CreditCardSetupForm = ({clientSecret, handleCancelCreditCard, userId, roleId}) => {

    const stripe = useStripe();
    const elements = useElements();

    const [isLoading, setIsLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [defaultCard, setDefaultCard] = useState(true);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDefaultCard = (event) => {
        setDefaultCard(event.target.checked);
    }

    const saveCreditCard = async (paymentMethod, setupIntentId) => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/admin/saveCard`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                // headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    paymentMethod: paymentMethod,
                    setupIntentId: setupIntentId,
                    isDefault: defaultCard,
                    userId: userId,
                    roleId: roleId
                }
            })
            console.log('result', result);
            // if (selectCardId) {
            //     await deleteCreditCard();
            // } else {
            setErrorMessage('');
            setSuccessMessage('Successfully Add Credit Card');
            handleSuccessMessage();
            setIsLoading(false);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            // }
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail To Add Credit Card');
            handleErrorMessage();
            setIsLoading(false);
        }
    }

    const handleSubmit = async (event) => {
        setIsLoading(true);
        setErrorMessage("");
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        //create payment method
        const stripeResult = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)
            }
        })
        console.log('stripe result', stripeResult);

        if (stripeResult?.error) {
            setErrorMessage(stripeResult?.error?.message);
            setIsLoading(false);
            handleErrorMessage();
        } else {
            const {setupIntent: {payment_method, id}} = stripeResult
            saveCreditCard(payment_method, id)
        }
    }

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <form
                onSubmit={handleSubmit}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                    flexDirection: 'column'
                }}>
                    <CreditCardSection/>
                    {/*<Stack direction="row" spacing={1} alignItems="center">*/}
                    {/*    <AntSwitch*/}
                    {/*        checked={defaultCard}*/}
                    {/*        onChange={handleDefaultCard}*/}
                    {/*        inputProps={{'aria-label': 'ant design'}}*/}
                    {/*    />*/}
                    {/*    <Typography style={{fontSize: '14px'}}>*/}
                    {/*        Set as default*/}
                    {/*    </Typography>*/}
                    {/*</Stack>*/}
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'flex-end'
                    }}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#454545',
                                "&:hover": {
                                    backgroundColor: '#454545',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleCancelCreditCard}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Cancel
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            loading={isLoading}
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                Submit
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Box>
            </form>
        </>
    )
}