import {Fragment, useEffect, useState} from "react";
import {Grid, InputAdornment, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLQuoteTransitMode = ({
                                        getDataFromChildComponent,
                                        triggerAction,
                                        isConfirm,
                                        validation,
                                        selectCarrier
                                    }) => {

    const mode = ['ROAD', 'RAIL', 'Intermodal']

    const classes = useStyles();

    const [transitMode, setTransitMode] = useState('');

    const handleInputChange = async (event, value) => {
        setTransitMode(value);
    }

    const handleChange = (event, value) => {
        setTransitMode(value);
    };

    useEffect(() => {
        if (selectCarrier?.toUpperCase() === 'CSA' ||
            selectCarrier?.toUpperCase() === 'DAYANDROSS' ||
            selectCarrier?.toUpperCase() === 'KINDERSLEY' ||
            selectCarrier?.toUpperCase() === 'MAERSK') {
            setTransitMode('ROAD');
        }
    },[selectCarrier])

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({transitMode});
        }
    }, [triggerAction]);

    console.log('[LTLQuoteTransitMode] transitMode', transitMode);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Transit Mode
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <FormControl fullWidth>
                            <Autocomplete
                                freeSolo
                                value={transitMode}
                                options={mode}
                                fullWidth
                                disabled={isConfirm}
                                renderInput={(params) => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                />}
                                onInputChange={handleInputChange}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )

}