import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import EtransferEmailListItem from "./EtransferEmailListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const EtransferEmailListTable = ({ emailList, reviewEmail }) => {
  const classes = useStyles();
  return (
    <div>
      <Box maxWidth="70%" margin="0 auto">
        <Box mt={2}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.idTH}>ID</th>
                <th className={classes.idTH}>Sender Email</th>
                <th className={classes.idTH}>Sender Name</th>
                <th className={classes.idTH}>Subject</th>
                <th className={classes.nameTH}>Created At</th>
                {/* <th className={classes.nameTH}>Admin</th>
                <th className={classes.nameTH}>Partner Status</th>
                <th className={classes.nameTH}>Type</th>
                <th className={classes.nameTH}>CRM #</th> */}
                <th className={classes.nameTH}></th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {emailList.map((v, index) => {
                return (
                  <EtransferEmailListItem
                    key={index}
                    id={v.id}
                    index={index}
                    senderEmail={v.sender_email}
                    senderName={v.sender_name}
                    subject={v.subject}
                    createdAt={v.created_at}
                    reviewEmail={reviewEmail}
                    // businessName={v.business_name}
                    // email={v.email}
                    // phone={v.phone}
                    // address={`${v.address} ${v.city} ${v.province} ${v.postal_code}`}
                    // adminId={v.admin_id}
                    // admin={v.administrator}
                    // partnerId={v.partner_id}
                    // partnerStatus={v.partner_status}
                    // companyType={v.company_type}
                    // crmNumber={v.crm_number}
                  />
                );
              })}
            </tbody>
          </table>
          {
            emailList.length === 0 ? <h1>No Emails to Review</h1> : undefined
          }
        </Box>
      </Box>
    </div>
  );
};

export default EtransferEmailListTable;


