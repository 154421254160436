import {Divider, Grid, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {ContainerShippingListEach} from "./ContainerShippingListEach";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";

export const ContainerShippingListTable = ({order}) => {

    const [partnerList, setPartnerList] = useState([]);
    const [endUserList, setEndUserList] = useState([]);

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setPartnerList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const getAllEndUserList = async () => {
        const requestURL = `${PARTNER_URI}/partner/getAllUserProfileList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setEndUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        getAllEndUserList();
        getAllPartnerList();
    }, [order])

    return (
        <Grid container spacing={1}>
            <Grid item md={1.75}>
                <Typography style={{fontWeight: '600'}}>
                    Order Number
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    User
                </Typography>
            </Grid>
            <Grid item md={1.75}>
                <Typography style={{fontWeight: '600'}}>
                    Vessel
                </Typography>
            </Grid>
            <Grid item md={1.75}>
                <Typography style={{fontWeight: '600'}}>
                    Schedule
                </Typography>
            </Grid>
            <Grid item md={3.25}>
                <Typography style={{fontWeight: '600'}}>
                    Port
                </Typography>
            </Grid>
            <Grid item md={1}>
                <Typography style={{fontWeight: '600'}}>
                    Status
                </Typography>
            </Grid>
            <Grid item md={1} xs={4}>
                <Typography style={{fontWeight: '600'}}>
                    Charge
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Fragment key={index}>
                        <ContainerShippingListEach order_info={orderEach} partnerList={partnerList}
                                                   endUserList={endUserList}/>
                    </Fragment>
                )
            }
        </Grid>
    )
}