import {FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {getAccessToken} from "../../utils/doToken";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const ContainerShippingOrderDetailStatus = ({orderInfo, getContainerShippingOrderInfo}) => {

    const accessToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [status, setStatus] = useState(0);
    const [originalStatus, setOriginalStatus] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleStatus = (event) => {
        setStatus(event.target.value);
    }

    const handleUpdateContainerShippingOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${PARTNER_URI}/oceanShipping/editOceanBackendOrderByOrderId`;

            let data = {
                backendOrderId: orderInfo?.orderId,
                orderStatus: status
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Container Shipping Order');
            handleSuccessMessage();
            setTimeout(() => {
                getContainerShippingOrderInfo();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Container Shipping Order');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (orderInfo) {
            setStatus(orderInfo?.orderStatus);
            setOriginalStatus(orderInfo?.orderStatus);
        }
    }, [orderInfo])

    console.log('[ContainerShippingOrderDetailStatus] status', status)

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={4}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Order Status
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Order Status
                                </FormLabel>
                                <Select
                                    value={status}
                                    onChange={handleStatus}
                                    size='small'
                                    style={{textAlign: 'left'}}
                                >
                                    <MenuItem value={0}>
                                        Initial
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Confirm
                                    </MenuItem>
                                    <MenuItem value={-1}>
                                        Cancel
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {
                            (status !== originalStatus) &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleUpdateContainerShippingOrder}
                                        loading={loading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}