import {Fragment, useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

export const CarQuoteExpiredDate = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [expiredDate, setExpiredDate] = useState(null);

    const handleExpiredDate = (event) => {
        const inputDate = event.target.value;
        setExpiredDate(inputDate)
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({expiredDate});
        }
    },[triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Expired Date
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={expiredDate}
                        onChange={handleExpiredDate}
                        size='small'
                        disabled={isConfirm}
                        // error={validation?.shipDateAndTimeMissing.includes('pickupDate')}
                        // helperText={validation?.shipDateAndTimeMissing.includes('pickupDate') && "Required field"}
                    />
                </Grid>
            </Grid>
        </Fragment>
    )

}