import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Fragment} from "react";

export const MovingOrderDetailCost = ({orderInfo, downPayment, paidPayment}) => {

    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Order Price
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Freight Charge
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(+orderInfo?.orderPrice?.moving_freight_charge)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Pickup Charge
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(+orderInfo?.orderPrice?.moving_pickup_charge)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Deliver Charge
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(+orderInfo?.orderPrice?.moving_deliver_charge)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    {
                        +orderInfo?.orderPrice?.moving_storage_charge > 0 &&
                        <>
                            <Grid item xs={7}>
                                <Typography sx={{textAlign: 'left'}}>
                                    Storage Charge
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{textAlign: 'right'}}>
                                    $ {(+orderInfo?.orderPrice?.moving_storage_charge)?.toFixed(2)}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        +orderInfo?.orderPrice?.moving_palleting_charge > 0 &&
                        <>
                            <Grid item xs={7}>
                                <Typography sx={{textAlign: 'left'}}>
                                    Pallet Charge
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{textAlign: 'right'}}>
                                    $ {(+orderInfo?.orderPrice?.moving_palleting_charge)?.toFixed(2)}
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Shipping Charge
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(+orderInfo?.orderPrice?.shipping_fee)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    {
                        (+orderInfo?.taskShippingFee - +orderInfo?.orderPrice?.shipping_fee) !== 0 &&
                        <>
                            <Grid item xs={7}>
                                <Typography sx={{textAlign: 'left'}}>
                                    Adjustment Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{textAlign: 'right'}}>
                                    $ {(+orderInfo?.taskShippingFee - +orderInfo?.orderPrice?.shipping_fee)?.toFixed(2)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={7}>
                                <Typography sx={{textAlign: 'left'}}>
                                    Adjusted Shipping Charge
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{textAlign: 'right'}}>
                                    $ {(+orderInfo?.taskShippingFee)?.toFixed(2)}
                                </Typography>
                            </Grid>
                        </>
                    }
                    {
                        +orderInfo?.orderPrice?.service_fee > 0 &&
                        <>
                            <Grid item xs={7}>
                                <Typography sx={{textAlign: 'left'}}>
                                    Service Charge
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography style={{textAlign: 'right'}}>
                                    $ {(+orderInfo?.taskServiceFee)?.toFixed(2)}
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Subtotal
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(+orderInfo?.taskTotalCharge)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Tax
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(+orderInfo?.taskTaxCharge)?.toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Total
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {(+orderInfo?.taskFinalTotal)?.toFixed(2)}
                        </Typography>
                    </Grid>

                    {
                        paidPayment?.map((payment, index) => (
                            <Fragment key={index}>
                                <Grid item xs={7}>
                                    <Typography sx={{textAlign: 'left'}}>
                                        {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{textAlign: 'right'}}>
                                        - $ {payment?.amount.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ))
                    }
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography sx={{textAlign: 'left'}}>
                            Amount To Be Paid
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography style={{textAlign: 'right'}}>
                            $ {orderInfo?.task_unpaid_amount?.toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}