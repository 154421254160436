import {Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import CurrencyFormat from 'react-currency-format';
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {getAccessToken} from "../../utils/doToken";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingOrderEditContact = ({orderInfo, getMovingOrderDetails}) => {

    const accessToken = getAccessToken("access_token");

    const [contactInfo, setContactInfo] = useState({
        pickupName: '',
        pickupPhone: '',
        pickupEmail: '',
        deliverName: '',
        deliverPhone: '',
        deliverEmail: ''
    })
    const [originalContactInfo, setOriginalContactInfo] = useState({
        pickupName: '',
        pickupPhone: '',
        pickupEmail: '',
        deliverName: '',
        deliverPhone: '',
        deliverEmail: ''
    })

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [shipperLoading, setShipperLoading] = useState(false);
    const [consigneeLoading, setConsigneeLoading] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handlePickupName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupName: event.target.value
        }))
    };

    const handlePickupPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupPhone: event.target.value
        }))
    };

    const handlePickupEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            pickupEmail: event.target.value
        }))
    };

    const handleDeliverName = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverName: event.target.value
        }))
    };

    const handleDeliverPhone = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverPhone: event.target.value
        }))
    };

    const handleDeliverEmail = event => {
        setContactInfo(prevState => ({
            ...prevState,
            deliverEmail: event.target.value
        }))
    };

    const handleUpdateOrderShipper = async () => {
        setShipperLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingService/admin/editMovingOrderDetails`;

            let data = {
                backendOrderId: orderInfo?.order_id,
                senderName: contactInfo?.pickupName,
                senderEmail: contactInfo?.pickupEmail,
                senderPhone: contactInfo?.pickupPhone
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Moving Order');
            handleSuccessMessage();
            setTimeout(() => {
                getMovingOrderDetails();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Moving Order');
            handleErrorMessage();

        } finally {
            setShipperLoading(false);
        }
    }

    const handleUpdateOrderConsignee = async () => {
        setConsigneeLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingService/admin/editMovingOrderDetails`;

            let data = {
                backendOrderId: orderInfo?.order_id,
                deliverName: contactInfo?.deliverName,
                deliverEmail: contactInfo?.deliverEmail,
                deliverPhone: contactInfo?.deliverPhone
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Moving Order');
            handleSuccessMessage();
            setTimeout(() => {
                getMovingOrderDetails();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Moving Order');
            handleErrorMessage();

        } finally {
            setConsigneeLoading(false);
        }
    }

    useEffect(() => {
        if (orderInfo) {
            setContactInfo(prevState => ({
                ...prevState,
                pickupName: orderInfo?.sender,
                pickupPhone: orderInfo?.pickup_phone,
                pickupEmail: orderInfo?.pickup_email,
                deliverName: orderInfo?.deliver_name,
                deliverPhone: orderInfo?.deliver_phone,
                deliverEmail: orderInfo?.deliver_email
            }))
            setOriginalContactInfo(prevState => ({
                ...prevState,
                pickupName: orderInfo?.sender,
                pickupPhone: orderInfo?.pickup_phone,
                pickupEmail: orderInfo?.pickup_email,
                deliverName: orderInfo?.deliver_name,
                deliverPhone: orderInfo?.deliver_phone,
                deliverEmail: orderInfo?.deliver_email
            }))
        }
    }, [orderInfo])

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Shipper Information
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Name
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.pickupName}
                                    onInput={handlePickupName}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // error={validationResultPlaceOrder?.contactMissing?.includes('pickupName')}
                                    // helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupName') && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Phone
                                </FormLabel>
                                <CurrencyFormat
                                    value={contactInfo?.pickupPhone}
                                    customInput={TextField}
                                    onInput={handlePickupPhone}
                                    format="+1 (###) ###-####"
                                    mask="_"
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // error={validationResultPlaceOrder?.contactMissing?.includes('pickupPhone') || contactValidate?.pickupPhoneError}
                                    // helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupPhone') ? 'Required' : contactValidate?.pickupPhoneError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Email
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.pickupEmail}
                                    onInput={handlePickupEmail}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // error={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') || contactValidate?.pickupEmailError}
                                    // helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') ? 'Required' : contactValidate?.pickupEmailError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                        {
                            ((contactInfo?.pickupName !== originalContactInfo?.pickupName) ||
                                (contactInfo?.pickupPhone !== originalContactInfo?.pickupPhone) ||
                                (contactInfo?.pickupEmail !== originalContactInfo?.pickupEmail)
                            ) &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleUpdateOrderShipper}
                                        loading={shipperLoading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Consignee Information
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Name
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.deliverName}
                                    onInput={handleDeliverName}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // error={validationResultPlaceOrder?.contactMissing?.includes('deliverName')}
                                    // helperText={validationResultPlaceOrder?.contactMissing?.includes('deliverName') && 'Required'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Phone
                                </FormLabel>
                                <CurrencyFormat
                                    value={contactInfo?.deliverPhone}
                                    customInput={TextField}
                                    onInput={handleDeliverPhone}
                                    format="+1 (###) ###-####"
                                    mask="_"
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // error={validationResultPlaceOrder?.contactMissing?.includes('deliverPhone') || contactValidate?.deliverPhoneError}
                                    // helperText={validationResultPlaceOrder?.contactMissing?.includes('deliverPhone') ? 'Required' : contactValidate?.deliverPhoneError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Email
                                </FormLabel>
                                <TextField
                                    value={contactInfo?.deliverEmail}
                                    onInput={handleDeliverEmail}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                    // error={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') || contactValidate?.pickupEmailError}
                                    // helperText={validationResultPlaceOrder?.contactMissing?.includes('pickupEmail') ? 'Required' : contactValidate?.pickupEmailError && 'Invalid'}
                                />
                            </FormControl>
                        </Grid>
                        {
                            ((contactInfo?.deliverName !== originalContactInfo?.deliverName) ||
                                (contactInfo?.deliverPhone !== originalContactInfo?.deliverPhone) ||
                                (contactInfo?.deliverEmail !== originalContactInfo?.deliverEmail)
                            ) &&
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        size='small'
                                        onClick={handleUpdateOrderConsignee}
                                        loading={consigneeLoading}
                                    >
                                        <Typography style={{
                                            textTransform: 'none',
                                        }}>
                                            Update
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}