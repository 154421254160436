import React, { useState, useEffect } from "react";
// import Slide from 'react-reveal';
// import { useParams } from "react-router-dom";
import { Box, Button, Grid, Container } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
// import PropTypes from "prop-types";
import axios from "axios";
import LoadingContainer from "../../shared/loadingContainer";

import { BOL_DETAILS } from "../../utils/apiUrl";
import { errHandling } from "../../utils/fetchUtils";

const defaultBolData = {
  shipperName: "",
  shipperAddress: "",
  carrierAgent: "",
  consigneeName: "",
  consigneeAddress: "",
  deliveryAgent: "",
  placeOfAcceptance: "",
  preCarriage: "",
  numberOfOriginal: null,
  payableAt: "",
  excessValueDeclaration: "",
  vessel: "",
  portOfLoading: "",
  portOfDischarge: "",
  placeOfDelivery: "",
  onCarriageTo: "",
  onBoardDate: "",
  freightDetails: "",
  issueDate: "",
  issuePlace: "",
  nofifyPartyName: "",
  nofifyPartyAddress: "",
};

const BolDetails = ({ handleClose, bolNumber }) => {
  const classes = useStyles();

  const [errMsg, setErrMsg] = useState("");

  const [shippingInfo, setShippingInfo] = useState(defaultBolData);

  const [itemList, updateItemList] = useState([]);

  const [loading, setLoadingState] = useState(true);

  useEffect(() => {
    axios
      .get(`${BOL_DETAILS}?ref_no=${bolNumber}`)
      .then((response) => {
        if (response.data) {
          const {
            shipper_name,
            shipper_address,
            carrier_agent,
            consignee_name,
            consignee_address,
            delivery_agent,
            place_of_acceptance,
            pre_carriage,
            number_of_original,
            payable_at,
            excess_value_declaration,
            vessel,
            port_of_loading,
            port_of_discharge,
            place_of_delivery,
            on_carriage_to,
            on_board_date,
            freight_details,
            issue_date,
            issue_place,
            nofify_party_name,
            nofify_party_address,
          } = response.data;
          setShippingInfo({
            shipperName: shipper_name,
            shipperAddress: shipper_address,
            carrierAgent: carrier_agent,
            consigneeName: consignee_name,
            consigneeAddress: consignee_address,
            deliveryAgent: delivery_agent,
            placeOfAcceptance: place_of_acceptance,
            preCarriage: pre_carriage,
            numberOfOriginal: number_of_original,
            payableAt: payable_at,
            excessValueDeclaration: excess_value_declaration,
            vessel: vessel,
            portOfLoading: port_of_loading,
            portOfDischarge: port_of_discharge,
            placeOfDelivery: place_of_delivery,
            onCarriageTo: on_carriage_to,
            onBoardDate: on_board_date,
            freightDetails: freight_details,
            issueDate: issue_date,
            issuePlace: issue_place,
            nofifyPartyName: nofify_party_name,
            nofifyPartyAddress: nofify_party_address,
          });
        }

        if (response.data?.item_list) {
          updateItemList(response.data?.item_list);
        }
        setLoadingState(false);
      })
      .catch((err) => {
        errHandling(err, setErrMsg);
        // setPriceErrMsg(err.response.data.message);
        setLoadingState(false);
      });
  }, []);

  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="md">
      {loading ? (
        <LoadingContainer />
      ) : (
        <Box>
          <br />
          <Box textAlign="center">
            <h2 className="orange-title">BOL Summary:</h2>
          </Box>
          <br />
          <Grid container spacing={3} alignContent="center">
            <Grid item xs={6}>
              <div id="summary-content2" className="bodytext">
                <Box display="flex" justifyContent="space-between">
                  <Box fontWeight="700">Address and shipping</Box>
                </Box>
              </div>
              <Box id="summary-content1" className="subtitle" pl={1}>
                <p className={classes.summaryHeader}>Shipper</p>
                <p className={classes.summaryBodyText}>
                  {`${shippingInfo.shipperName}`} <br />
                  {`${shippingInfo.shipperAddress}`}
                </p>
              </Box>
              <Box id="summary-content3" className="subtitle" pl={1}>
                <p className={classes.summaryHeader}>Consignee</p>
                <p className={classes.summaryBodyText}>
                  {`${shippingInfo.consigneeName}`} <br />
                  {`${shippingInfo.consigneeAddress}`}
                </p>
              </Box>
              {(shippingInfo.nofifyPartyName ||
                shippingInfo.nofifyPartyAddress) && (
                <Box id="summary-content5" className="subtitle" pl={1}>
                  <p className={classes.summaryHeader}>Notify Party</p>
                  <p className={classes.summaryBodyText}>
                    {`${shippingInfo.nofifyPartyName}`} <br />
                    {`${shippingInfo.nofifyPartyAddress}`}
                  </p>
                </Box>
              )}
              <Box id="summary-content6" className="bodytext" pl={1}>
                <p className={classes.summaryBodyText}>
                  <Box component="span" color="seagreen" fontWeight="600">
                    Carrier Agent
                  </Box>
                  : {shippingInfo.carrierAgent}
                </p>
                <p className={classes.summaryBodyText}>
                  <Box component="span" color="seagreen" fontWeight="600">
                    Delivery Agent
                  </Box>
                  : {shippingInfo.deliveryAgent}
                </p>
                {shippingInfo.placeOfAcceptance && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Place of Acceptance
                    </Box>
                    : {shippingInfo.placeOfAcceptance}
                  </p>
                )}
                {shippingInfo.preCarriage && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Pre-carriage by
                    </Box>
                    : {shippingInfo.preCarriage}
                  </p>
                )}
                {shippingInfo.numberOfOriginal && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Number of Original
                    </Box>
                    : {shippingInfo.numberOfOriginal}
                  </p>
                )}
                {shippingInfo.payableAt && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Freight & Charges Payable at
                    </Box>
                    : {shippingInfo.payableAt}
                  </p>
                )}
                {shippingInfo.excessValueDeclaration && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Excess Value Declaration
                    </Box>
                    : {shippingInfo.excessValueDeclaration}
                  </p>
                )}
                {shippingInfo.vessel && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Vessel / Voyage
                    </Box>
                    : {shippingInfo.vessel}
                  </p>
                )}
                {shippingInfo.portOfLoading && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Port of Loading
                    </Box>
                    : {shippingInfo.portOfLoading}
                  </p>
                )}
                {shippingInfo.portOfDischarge && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Port of Discharge
                    </Box>
                    : {shippingInfo.portOfDischarge}
                  </p>
                )}
                {shippingInfo.placeOfDelivery && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Port of Delivery
                    </Box>
                    : {shippingInfo.placeOfDelivery}
                  </p>
                )}
                {shippingInfo.onCarriageto && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      On Carriage to
                    </Box>
                    : {shippingInfo.onCarriageto}
                  </p>
                )}
                {shippingInfo.onBoardDate && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      On Board Date
                    </Box>
                    : {shippingInfo.onBoardDate}
                  </p>
                )}
                {shippingInfo.issueDate && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Date of Issue
                    </Box>
                    : {shippingInfo.issueDate}
                  </p>
                )}
                {shippingInfo.issuePlace && (
                  <p className={classes.summaryBodyText}>
                    <Box component="span" color="seagreen" fontWeight="600">
                      Place of Issue
                    </Box>
                    : {shippingInfo.issuePlace}
                  </p>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.itemSection}>
                <div id="summary-content8" className="bodytext">
                  <Box display="flex" justifyContent="space-between">
                    <Box fontWeight="700">Items</Box>
                  </Box>
                </div>
                <Box id="summary-content7" className="subtitle" pl={1}>
                  <div className={classes.summaryBodyText}>
                    {itemList.map((item, key) => {
                      return (
                        <div key={key}>
                          <p>
                            {item.hscode}, {item.hscodeDescription}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <div></div>
          <div></div>
        </Box>
      )}
      <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            style={{
              textTransform: "unset",
              width: "8rem",
            }}
          >
            Back
          </Button>
        </Box>
        {errMsg && (
          <Box pt="1vh" width="100%">
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              {errMsg}
            </Alert>
          </Box>
        )}
      </Box>
      <br />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 20,
    fontSize: "25pt",
    backgroundColor: "#ffa726",
    color: "white",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
  },
  itemSection: {
    borderLeft: "1px solid rgba(0,0,0,0.3)",
    paddingLeft: theme.spacing(2),
  },
}));

// BolDetails.propTypes = {
//   toSeventh: PropTypes.func.isRequired,
//   toFirst: PropTypes.func.isRequired,
//   toFourth: PropTypes.func.isRequired,
// };

// export default connect(mapStateToProps, { getShipmentItems, getPickUpDetails })(QuoteSummary);
export default BolDetails;
