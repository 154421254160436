export const errHandling = (err, setErr) => {
  if (err.response) {
    // Request made and server responded
    setErr(err.response.data.message);
  } else if (err.request) {
    if (typeof err.request === "string" || err.request instanceof String) {
      // The request was made but no response was received
      setErr(err.request);
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    setErr(err.message);
  }
};

export const getURLParams = (url, body) => {
  if (body && typeof body === "object") {
    let params = "";
    Object.keys(body).forEach((key) => {
      const iterItems = body[key];
      if (iterItems && Array.isArray(iterItems)) {
        iterItems.forEach((item) => {
          if (params.length === 0) {
            params += `?${key}=${item}`;
          } else {
            params += `&${key}=${item}`;
          }
        });
      } else if ((iterItems || iterItems === 0) && !Array.isArray(iterItems)) {
        if (params.length === 0) {
          params += `?${key}=${iterItems}`;
        } else {
          params += `&${key}=${iterItems}`;
        }
      }
    });
    return url + params;
  }
  return url;
};
