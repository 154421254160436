import {Button, FormHelperText, FormLabel, Grid, Snackbar, Stack, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {
    CustomTextFieldNormal,
    CustomTextFieldNormalWithUnit, CustomTextFieldNumber,
    CustomTextFieldNumberForCost
} from "../../utils/customComponent";
import {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import {useParams} from "react-router-dom";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core/styles";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG, extractFullAddress} from "../../utils/Helper";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        },
    }
});

const AntSwitch = styled(Switch)(({theme}) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#609966' : '#609966',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export const MovingCategoryFreightAdd = ({handleCloseNewArea, getFreightPricePolicyListByCategoryId, cateInfo}) => {

    const {cateId} = useParams();
    const classes = useStyles();

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [shipFrom, setShipFrom] = useState({
        city: null,
        province: null,
        postalCode: null,
        country: null
    });
    const [shipTo, setShipTo] = useState({
        city: null,
        province: null,
        postalCode: null,
        country: null
    });

    const [isConfirm, setIsConfirm] = useState(false);
    const [validation, setValidation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    // const [weight, setWeight] = useState(0);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0);
    const [lengthUnit, setLengthUnit] = useState('in');
    const [weightUnit, setWeightUnit] = useState('lb');
    const [overWeightRate, setOverWeightRate] = useState(0.3);
    const [priceAdjustRatio, setPriceAdjustRatio] = useState(1);

    const [maxWeight, setMaxWeight] = useState(130);
    const [maxDimension, setMaxDimension] = useState(150);
    const [metric, setMetric] = useState(false);

    const [routeId, setRouteId] = useState(null);
    const [areaGroupId, setAreaGroupId] = useState(null);

    const [routeOrAreaGroupError, setRouteOrAreaGroupError] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;

            return predictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromCity = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        console.log('[handleInputShipFromCity] predictions', predictions);
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToCity = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            city: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality');
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipFromCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship from', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,

                }
            }))
        }
    }

    const handleChangeShipToCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.place_id);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,
                }
            }))
        }
    }

    const handleMetric = (event) => {
        setMetric(event.target.checked);
        if (event.target.checked) {
            setLengthUnit('cm');
            setWeightUnit('kg');
            // setWeight(convertLBToKG(weight));
            setLength(convertINToCM(length));
            setWidth(convertINToCM(width));
            setHeight(convertINToCM(height));
            setMaxDimension(convertINToCM(maxDimension));
            setMaxWeight(convertLBToKG(maxWeight));
        } else {
            setLengthUnit('in');
            setWeightUnit('lb');
            // setWeight(convertKGToLB(weight));
            setLength(convertCMToIN(length));
            setWidth(convertCMToIN(width));
            setHeight(convertCMToIN(height));
            setMaxDimension(convertCMToIN(maxDimension));
            setMaxWeight(convertKGToLB(maxWeight));
        }
    }

    const onChange = (field, value) => {
        switch (field) {
            case  'length':
                setLength(value);
                break;
            case  'width':
                setWidth(value);
                break;
            case  'height':
                setHeight(value);
                break;
            // case  'weight':
            //     setWeight(value);
            //     break;
            case  'maxDimension':
                setMaxDimension(value);
                break;
            case  'maxWeight':
                setMaxWeight(value);
                break;
            case  'overWeightRate':
                setOverWeightRate(value);
                break;
            case  'priceAdjustRatio':
                setPriceAdjustRatio(value);
                break;
            default:
        }
    }

    const addFreightPricePolicy = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingCategory/addFreightCategoryPricePolicy`;

        let data = {
            cateId,
            routeId,
            areaGroupId,
            length,
            width,
            height,
            maxDimension,
            maxWeight,
            overWeightPerLBPrice: overWeightRate,
            priceAdjustRatio
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[MovingCategoryFreightAdd] addFreightPricePolicy api - result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Add a New Freight Price Policy');
            handleSuccessMessage();
            setIsConfirm(false);
            handleCloseNewArea();
            getFreightPricePolicyListByCategoryId();

        } catch (e) {
            console.log('[MovingCategoryFreightAdd] addFreightPricePolicy api - error', e?.response);
            setErrorMessage('Fail To Add a New Freight Price Policy');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const handleEdit = () => {
        setIsConfirm(false);
    }

    const handleConfirm = async () => {
        const validationResult = validate();
        if (validationResult) {
            const validationOfRouteIdOrAreaGroupId = await getMovingRouteOrAreaGroup();
            if (validationOfRouteIdOrAreaGroupId) {
                setIsConfirm(true);
            }
        }
    }

    const getMovingRouteOrAreaGroup = async () => {
        const requestURL = `${NODE_ROUTE_URI}/movingService/getMovingRouteOrAreaGroup`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    pickupCity: shipFrom?.city,
                    pickupProvince: shipFrom?.province?.code,
                    deliverCity: shipTo?.city,
                    deliverProvince: shipTo?.province?.code
                }
            })

            console.log('[MovingCategoryFreightAdd] getMovingRouteOrAreaGroup api - result', result);
            const {data: {areaGroupId}} = result;
            const {data: {routeId}} = result

            setRouteId(routeId);
            setAreaGroupId(areaGroupId);
            if (areaGroupId || routeId) {
                setRouteOrAreaGroupError(false);
                return true;
            } else {
                setErrorMessage('Area and route not found!');
                handleErrorMessage();
                setRouteOrAreaGroupError(true);
                return false;
            }

        } catch (e) {
            console.log('[MovingCategoryFreightAdd] getMovingRouteOrAreaGroup api - error', e?.response);
            setErrorMessage('Area and route not found!');
            handleErrorMessage();
            setRouteId(null);
            setAreaGroupId(null);
            setRouteOrAreaGroupError(true);
            return false;
        }
    }

    const handleCreate = () => {
        // const validationResult = validate();
        // if (validationResult) {
        //     console.log('call api');
        addFreightPricePolicy();
        // }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const validation = {
            lengthError: length <= 0,
            widthError: width <= 0,
            heightError: height <= 0,
            // weightError: weight <= 0,
            maxDimensionError: maxDimension <= 0,
            maxWeightError: maxWeight <= 0,
            overWeightRateError: overWeightRate <= 0,
            priceAdjustRatioError: priceAdjustRatio <= 0,
            shipFromError: !shipFrom?.city,
            shipToError: !shipTo?.city
        }
        console.log('validation', validation);
        setValidation(validation);
        length <= 0 && setErrorMessage(prevState => prevState + 'Length is smaller or equal to 0.');
        width <= 0 && setErrorMessage(prevState => prevState + 'Width is smaller or equal to 0.');
        height <= 0 && setErrorMessage(prevState => prevState + 'Height is smaller or equal to 0.');
        // weight <= 0 && setErrorMessage(prevState => prevState + 'Weight is smaller or equal to 0.');
        maxDimension <= 0 && setErrorMessage(prevState => prevState + 'Maximum dimension is smaller or equal to 0.');
        maxWeight <= 0 && setErrorMessage(prevState => prevState + 'Maximum weight is smaller or equal to 0.');
        overWeightRate <= 0 && setErrorMessage(prevState => prevState + 'Over weight rate is smaller or equal to 0.');
        priceAdjustRatio <= 0 && setErrorMessage(prevState => prevState + 'Price adjust ratio is smaller or equal to 0.');
        !shipFrom?.city && setErrorMessage(prevState => prevState + 'Ship from city is missing.');
        !shipTo?.city && setErrorMessage(prevState => prevState + 'Ship to city is missing.');

        if (length > 0 &&
            width > 0 &&
            height > 0 &&
            // weight > 0 &&
            maxDimension > 0 &&
            maxWeight > 0 &&
            overWeightRate > 0 &&
            priceAdjustRatio > 0 &&
            shipFrom?.city &&
            shipTo?.city
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    useEffect(() => {
        setLength(cateInfo?.standard_length_ins);
        setWidth(cateInfo?.standard_width_ins);
        setHeight(cateInfo?.standard_height_ins);
        // setWeight(cateInfo?.standard_weight_lbs);
    }, [cateInfo])

    // console.log('[MovingCategoryFreightAdd] cate info', cateInfo);
    // console.log('[MovingCategoryFreightAdd] length', length);
    // console.log('[MovingCategoryFreightAdd] width', width);
    // console.log('[MovingCategoryFreightAdd] height', height);
    // console.log('[MovingCategoryFreightAdd] weight', weight);
    // console.log('[MovingCategoryFreightAdd] max dimension', maxDimension);
    // console.log('[MovingCategoryFreightAdd] max weight', maxWeight);
    // console.log('[MovingCategoryFreightAdd] over weight rate', overWeightRate);
    // console.log('[MovingCategoryFreightAdd] price adjust ratio', priceAdjustRatio);
    console.log('[MovingCategoryFreightAdd] ship from', shipFrom);
    console.log('[MovingCategoryFreightAdd] route id', routeId);
    console.log('[MovingCategoryFreightAdd] area group id', areaGroupId);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    New Freight Price Policy
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel>
                        <Typography style={{textAlign: 'left'}}>
                            Moving From City
                        </Typography>
                    </FormLabel>
                    <Autocomplete
                        options={movingFromAddressPredictions}
                        getOptionLabel={option => option?.description}
                        onInputChange={handleInputShipFromCity}
                        onChange={handleChangeShipFromCity}
                        fullWidth
                        disabled={isConfirm}
                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                        renderInput={params => <TextField
                            {...params}
                            variant='outlined'
                            className={classes.smallInput}
                            // placeholder={'Moving To City'}
                            error={validation?.shipFromError}
                            helperText={validation?.shipFromError && 'Required'}
                        />}
                        renderOption={(option) => (
                            <Typography
                                style={{fontSize: '14px'}}
                            >
                                {/*<Typography>*/}
                                {option.description}
                                {/*</Typography>*/}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel>
                        <Typography style={{textAlign: 'left'}}>
                            Moving To City
                        </Typography>
                    </FormLabel>
                    <Autocomplete
                        options={movingToAddressPredictions}
                        getOptionLabel={option => option?.description}
                        onInputChange={handleInputShipToCity}
                        onChange={handleChangeShipToCity}
                        fullWidth
                        disabled={isConfirm}
                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                        renderInput={params => <TextField
                            {...params}
                            variant='outlined'
                            className={classes.smallInput}
                            error={validation?.shipToError}
                            helperText={validation?.shipToError && 'Required'}
                            // placeholder={'Moving To City'}
                        />}
                        renderOption={(option) => (
                            <Typography
                                style={{fontSize: '14px'}}
                            >
                                {/*<Typography>*/}
                                {option.description}
                                {/*</Typography>*/}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                {
                    routeOrAreaGroupError &&
                    <Typography color="error">
                        Area and route not found!
                    </Typography>
                }
            </Grid>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                            checked={metric}
                            onChange={handleMetric}
                            disabled={isConfirm}
                        />
                        <Typography>
                            Metric (cm/kg)
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Length
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={length}
                        field='length'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        unit={lengthUnit}
                        error={validation?.lengthError}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Width
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={width}
                        field='width'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        unit={lengthUnit}
                        error={validation?.widthError}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Height
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={height}
                        field='height'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        unit={lengthUnit}
                        error={validation?.heightError}
                    />
                </FormControl>
            </Grid>
            {/*<Grid item xs={3}>*/}
            {/*    <FormControl fullWidth>*/}
            {/*        <FormLabel sx={{textAlign: 'left'}}>*/}
            {/*            Weight*/}
            {/*        </FormLabel>*/}
            {/*        <CustomTextFieldNormalWithUnit*/}
            {/*            textValue={weight}*/}
            {/*            field='weight'*/}
            {/*            onChange={onChange}*/}
            {/*            isConfirm={isConfirm}*/}
            {/*            unit={weightUnit}*/}
            {/*            error={validation?.weightError}*/}
            {/*        />*/}
            {/*    </FormControl>*/}
            {/*</Grid>*/}
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Maximum Dimension
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={maxDimension}
                        field='maxDimension'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        unit={lengthUnit}
                        error={validation?.maxDimensionError}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Maximum Weight
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={maxWeight}
                        field='maxWeight'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        unit={weightUnit}
                        error={validation?.maxWeightError}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Over Weight Rate
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={overWeightRate}
                        field='overWeightRate'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        unit='$/lb'
                        error={validation?.overWeightRateError}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Price Adjust Ratio
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={priceAdjustRatio}
                        field='priceAdjustRatio'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.priceAdjustRatioError}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px'
                }}>
                    {
                        isConfirm ?
                            <>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleEdit}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Cancel
                                    </Typography>
                                </Button>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleCreate}
                                    loading={loading}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Confirm
                                    </Typography>
                                </LoadingButton>
                            </>
                            :
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleConfirm}
                            >
                                <Typography style={{
                                    textTransform: 'none'
                                }}>
                                    Create
                                </Typography>
                            </Button>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}
