import {Button, FormLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, Select, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const ClaimDashboardClaimSummary = ({claimData, resetTrigger, handleClaimSummary, validation}) => {

    const classes = useStyles();

    const refundTypeList = ['Credit', 'Payout'];

    const [claimSummary, setClaimSummary] = useState({
        status: "",
        caseNumber: "",
        type: "",
        result: "",
        remarkNotes: "",
        refundType: "",
        refundAmount: ""
    })

    const handleChangeStatus = (event) => {
        setClaimSummary(prevState => ({
            ...prevState,
            status: event?.target?.value
        }))
    }

    const handleInputCaseNumber = (event) => {
        setClaimSummary(prevState => ({
            ...prevState,
            caseNumber: event?.target?.value
        }))
    }

    const handleChangeType = (event) => {
        setClaimSummary(prevState => ({
            ...prevState,
            type: event?.target?.value
        }))
    }

    const handleInputResult = (event) => {
        setClaimSummary(prevState => ({
            ...prevState,
            result: event?.target?.value
        }))
    }

    const handleInputRemarkNotes = (event) => {
        setClaimSummary(prevState => ({
            ...prevState,
            remarkNotes: event?.target?.value
        }))
    }

    const handleInputChangeRefundType = async (event, value) => {
        setClaimSummary(prevState => ({
            ...prevState,
            refundType: value
        }))
    }

    const handleChangeRefundType = async (event, value) => {
        setClaimSummary(prevState => ({
            ...prevState,
            refundType: value
        }))
    }

    const handleInputRefundAmount = (event) => {
        setClaimSummary(prevState => ({
            ...prevState,
            refundAmount: event?.target?.value
        }))
    }

    const handleSetClaimData = () => {

        setClaimSummary(prevState => ({
            ...prevState,
            status: claimData?.claim_status,
            caseNumber: claimData?.ref_case_no || "",
            type: claimData?.claim_type || "",
            result: claimData?.claim_result || "",
            remarkNotes: claimData?.remark_notes || "",
            refundType: claimData?.refund_type || "",
            refundAmount: claimData?.refund_total_amount
        }))
    }

    useEffect(() => {
        handleClaimSummary(claimSummary);
    }, [claimSummary])

    useEffect(() => {
        handleSetClaimData();
    }, [claimData]);

    useEffect(() => {
        handleSetClaimData();
    }, [resetTrigger]);

    useEffect(() => {
        const formattedDateTime = dayjs().format('MMMM D, YYYY h:mm A');

        if (claimSummary?.status === -1) {
            const claimResult = `The claim was canceled by UUCargo operator on ${formattedDateTime}.`
            setClaimSummary(prevState => ({
                ...prevState,
                result: claimResult
            }))
        } else if (claimSummary?.status === -2) {
            const claimResult = `The claim was declined by UUCargo operator on ${formattedDateTime}.`
            setClaimSummary(prevState => ({
                ...prevState,
                result: claimResult
            }))
        } else {
            setClaimSummary(prevState => ({
                ...prevState,
                result: ""
            }))
        }

    }, [claimSummary?.status])

    console.log('[ClaimDashboardClaimSummary] validation', validation)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    Claim Summary
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Claim Date
                    </FormLabel>
                    <TextField
                        value={dayjs(claimData?.created_at).format('MMMM, D YYYY')}
                        // onInput={handleInputCaseNumber}
                        size='small'
                        disabled={true}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Claim Status
                    </FormLabel>
                    <Select
                        value={claimSummary?.status}
                        size='small'
                        style={{textAlign: 'left'}} // font size of input text
                        onChange={handleChangeStatus}
                    >
                        <MenuItem value={0}>
                            Initial
                        </MenuItem>
                        <MenuItem value={100}>
                            Processing
                        </MenuItem>
                        <MenuItem value={1}>
                            Accepted
                        </MenuItem>
                        <MenuItem value={2}>
                            Refunded
                        </MenuItem>
                        <MenuItem value={-1}>
                            Cancelled
                        </MenuItem>
                        <MenuItem value={-2}>
                            Declined
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Claim Type
                    </FormLabel>
                    <RadioGroup
                        value={claimSummary?.type}
                        onChange={handleChangeType}
                        row
                    >
                        <FormControlLabel
                            value="lost"
                            control={
                                <Radio sx={{
                                    color: '#1D8B45',
                                    '&.Mui-checked': {color: '#1D8B45'},
                                }}/>}
                            label="Shipment not received - Not delivered"/>
                        <FormControlLabel
                            value="damage"
                            control={
                                <Radio sx={{
                                    color: '#1D8B45',
                                    '&.Mui-checked': {color: '#1D8B45'},
                                }}/>}
                            label="Shipment Damage"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Case Number
                    </FormLabel>
                    <TextField
                        value={claimSummary?.caseNumber}
                        onInput={handleInputCaseNumber}
                        size='small'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Refund Type
                    </FormLabel>
                    <Autocomplete
                        value={claimSummary?.refundType}
                        freeSolo
                        options={refundTypeList}
                        fullWidth
                        renderInput={(params) => <TextField
                            {...params}
                            // error={validation?.hasMissingValuesFile && !fileDetail?.fileType}
                            // helperText={(validation?.hasMissingValuesFile && !fileDetail?.fileType) && 'Required'}
                            className={classes.smallInput}
                        />}
                        onInputChange={handleInputChangeRefundType}
                        onChange={handleChangeRefundType}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Refund Amount
                    </FormLabel>
                    <TextField
                        value={claimSummary?.refundAmount}
                        fullWidth
                        onInput={handleInputRefundAmount}
                        size='small'
                        variant='outlined'
                        type='number'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    CAD
                                </InputAdornment>
                            ),
                        }}
                        // error={(validation?.hasMissingValues || validation?.itemListError) && (!itemInformation?.value || +itemInformation?.value <= 0)}
                        // helperText={(validation?.hasMissingValues || validation?.itemListError) && ((!itemInformation?.value) ? 'Required' : +itemInformation?.value <= 0 && 'Larger than 0')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Claim Result
                    </FormLabel>
                    <TextField
                        rows={5}
                        multiline
                        value={claimSummary?.result}
                        onInput={handleInputResult}
                        size='small'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Remark Notes
                    </FormLabel>
                    <TextField
                        rows={5}
                        multiline
                        value={claimSummary?.remarkNotes}
                        onInput={handleInputRemarkNotes}
                        size='small'
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}
