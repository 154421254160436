import {useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {Button, FormGroup, FormLabel, Grid, InputAdornment, Snackbar, Typography} from "@mui/material";
import {MuiTelInput} from "mui-tel-input";
import IconButton from "@mui/material/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import MuiAlert from "@mui/material/Alert";
import * as React from 'react';
import LoadingButton from "@mui/lab/LoadingButton";
import {getMissingKeysForBecomeEndUser, isValidatePassword} from "../../utils/Helper";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AccountEndUser = () => {

    const [welcomeEmail, setWelcomeEmail] = useState(false);
    const [activeEmail, setActiveEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [accountInformation, setAccountInformation] = useState({
        contactEmail: "",
        password: "",
        firstName: "",
        lastName: ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const [missingKeys, setMissingKeys] = useState([]);
    const [validationContactEmail, setValidationContactEmail] = useState(true);
    const [validationPassword, setValidationPassword] = useState(true);
    const [emailRegex, setEmailRegex] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleContactEmail = event => {
        const value = event.target.value.trim();
        setAccountInformation(prevState => ({
            ...prevState,
            contactEmail: value
        }))
    }

    const handlePassword = event => {
        const value = event.target.value.trim();
        setAccountInformation(prevState => ({
            ...prevState,
            password: value
        }))
    }

    const handleWelcomeEmail = event => {
        setWelcomeEmail(event?.target?.checked);
    }

    const handleActiveEmail = event => {
        setActiveEmail(event?.target?.checked);
    }

    const handlePhone = value => {
        setAccountInformation(prevState => ({
            ...prevState,
            phone: value
        }))
    }

    const handleFirstName = event => {
        const value = event.target.value;
        setAccountInformation(prevState => ({
            ...prevState,
            firstName: value
        }))
    }

    const handleLastName = event => {
        const value = event.target.value;
        setAccountInformation(prevState => ({
            ...prevState,
            lastName: value
        }))
    }

    const registerEndUser = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/login_middleware/operator/signup`;

        const data = {
            firstname: accountInformation?.firstName,
            lastname: accountInformation?.lastName,
            email: accountInformation?.contactEmail?.trim(),
            password: accountInformation?.password?.trim(),
            phone: accountInformation?.phone?.replace(/\D/g, ''),
            sendWelcomeEmail: welcomeEmail,
            sendActiveEmail: activeEmail
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
            });

            setErrorMessage('');
            // setSuccessMessage('Successfully registered as an endUser.');
            setSuccess(true);
            // handleSuccessMessage();
        } catch (e) {
            const errorCode = e?.response?.status
            if (errorCode === 422) {
                setErrorMessage("We're sorry, but we were unable to register you as a end user. The email address you entered is already registered. Please use a different email address.")
            } else {
                setErrorMessage("We're sorry, but we were unable to register you as a end user. Please check your information and try again.")
            }
            handleErrorMessage();
            setSuccess(false);
        } finally {
            setLoading(false);
        }

    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            setEmailRegex(result?.data?.validators?.regex);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const validatePassword = () => {
        const result = isValidatePassword(accountInformation?.password);
        setValidationPassword(result);
        return result;
    }

    const handleCreateAccount = async () => {
        const missingKeys = getMissingKeysForBecomeEndUser(accountInformation);
        setMissingKeys(missingKeys);
        const contactEmailCheck = await validateEmailAddress(accountInformation?.contactEmail);
        setValidationContactEmail(contactEmailCheck);
        const passwordCheck = validatePassword();
        if (contactEmailCheck && passwordCheck && missingKeys.length === 0) {
            await registerEndUser();
        }
    }

    const handleBackToRegistration = () => {
        setSuccess(false);
    }

    if (!loading && success) {
        return (
            <>
                <Grid item xs={12}>
                    <Typography sx={{textAlign: 'center', color: '#1D8B45', fontWeight: '600'}}>
                        Registration complete! Individual account has been successfully set up.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            size='small'
                            onClick={handleBackToRegistration}
                        >
                            <Typography sx={{textTransform: 'none'}}>
                                Back to Registration
                            </Typography>
                        </Button>
                    </Box>
                </Grid>
            </>
        )
    }

    console.log('[contactEmailCheck]', validationContactEmail)

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Sign-up Email
                    </FormLabel>
                    <TextField
                        value={accountInformation?.contactEmail}
                        required
                        fullWidth
                        variant="outlined"
                        onInput={handleContactEmail}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        error={(missingKeys.includes('contactEmail') && !accountInformation?.contactEmail) || !validationContactEmail}
                        helperText={((missingKeys.includes('contactEmail') && !accountInformation?.contactEmail) || !validationContactEmail) && (emailRegex?.reason || 'Invalid')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Password
                    </FormLabel>
                    <TextField
                        value={accountInformation?.password}
                        type={showPassword ? 'text' : 'password'}
                        required
                        fullWidth
                        variant="outlined"
                        onInput={handlePassword}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        error={(missingKeys.includes('password') && !accountInformation?.password) || !validationPassword}
                        helperText={(missingKeys.includes('password') && !accountInformation?.password) ?
                            "Required" : !validationPassword &&
                            "Password must be between 6-20 characters"}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Email Notification
                    </FormLabel>
                    <FormGroup row={true}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={welcomeEmail}
                                    onChange={handleWelcomeEmail}
                                    name="narrow street"
                                    sx={{
                                        color: 'green',
                                        '&.Mui-checked': {
                                            color: 'green',
                                        },
                                    }}
                                    style={{
                                        color: 'green',
                                        '&.Mui-checked': {
                                            color: 'green',
                                        }
                                    }}
                                />
                            }
                            label={
                                <>
                                    Welcome Email
                                </>
                            }
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={activeEmail}
                                    onChange={handleActiveEmail}
                                    name="steep driveway"
                                    style={{
                                        color: 'green',
                                        '&.Mui-checked': {
                                            color: 'green',
                                        }
                                    }}
                                />
                            }
                            label={
                                <>
                                    Active Email
                                </>
                            }
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        First Name
                    </FormLabel>
                    <TextField
                        value={accountInformation?.firstName}
                        required
                        fullWidth
                        variant="outlined"
                        onInput={handleFirstName}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        error={(missingKeys.includes('firstName') && !accountInformation?.firstName)}
                        helperText={
                            ((missingKeys.includes('firstName') && !accountInformation?.firstName) && "Required")
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item md={4} xs={12}>
                <FormControl fullWidth>
                    <FormLabel required sx={{textAlign: 'left'}}>
                        Last Name
                    </FormLabel>
                    <TextField
                        value={accountInformation?.lastName}
                        required
                        fullWidth
                        variant="outlined"
                        onInput={handleLastName}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                        error={(missingKeys.includes('lastName') && !accountInformation?.length)}
                        helperText={
                            ((missingKeys.includes('lastName') && !accountInformation?.lastname) && "Required")
                        }
                    />
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Phone Number
                    </FormLabel>
                    <MuiTelInput
                        value={accountInformation?.phone}
                        fullWidth
                        variant="outlined"
                        defaultCountry="CA"
                        onChange={handlePhone}
                        inputProps={{style: {fontSize: 16}}}
                        InputLabelProps={{style: {fontSize: 16}}}
                        size='small'
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <LoadingButton
                    variant='contained'
                    sx={{
                        backgroundColor: '#1D8B45',
                        height: '100%',
                        "&:hover": {
                            backgroundColor: '#1D8B45',
                            filter: 'brightness(0.9)'
                        },
                    }}
                    size='small'
                    loading={loading}
                    onClick={handleCreateAccount}
                >
                    <Typography sx={{textTransform: 'none'}}>
                        Create
                    </Typography>
                </LoadingButton>
            </Grid>
        </>
    )
}