import {useHistory} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Container,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import {makeStyles} from "@material-ui/core/styles";
import {useEffect, useState} from "react";
import {LTLOrderListTable} from "../LTLOrderList/LTLOrderListTable";
import {Pagination} from "@mui/lab";
import {NODE_API, NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/constInfo";
import {LTLQuoteListTable} from "./LTLQuoteListTable";
import FormControl from "@mui/material/FormControl";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        }
    }
});

export const LTLQuoteList = () => {

    const history = useHistory();
    const classes = useStyles();

    const [searchString, setSearchString] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [quoteData, setQuoteData] = useState([]);
    const [availableService, setAvailableService] = useState([]);
    const [selectCarrier, setSelectCarrier] = useState('all');
    const [quoteStatus, setQuoteStatus] = useState('all');
    const [expiredDate, setExpiredDate] = useState(null);

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const handleAddQuote = () => {
        history.push('/v2/ltl-quote-list/add');
    }

    const handleSearchQuote = () => {
        getQuoteList();
    }

    const handleSearchQuoteByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getQuoteList();
        }
    }

    const getAllAvailableServiceNames = async () => {
        let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    all: true
                }
            })
            console.log('result', result);
            setAvailableService(result?.data);
        } catch (e) {
            console.log('error', e);
        }
    }

    const getQuoteList = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/shippingLTLRatingHistory/getLTLCacheRatingHistoryList`;
        // const requestURL = `https://api.uucargo.ca/v2/shippingLTLRatingHistory/getLTLCacheRatingHistoryList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString || '',
                    page: 0,
                    pageSize: PAGE_SIZE,
                    carrier: selectCarrier === 'all' ? null : selectCarrier,
                    status: quoteStatus === 'all' ? null : quoteStatus,
                    expiredDate: expiredDate || null,
                    sort: 'desc'
                }
            })
            console.log('[LTLQuoteList] - getQuoteList result', result);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
            setQuoteData(result?.data?.data);
            setError(false);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getQuoteListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/shippingLTLRatingHistory/getLTLCacheRatingHistoryList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: searchString || '',
                    page: page,
                    pageSize: PAGE_SIZE,
                    carrier: selectCarrier === 'all' ? null : selectCarrier,
                    status: quoteStatus === 'all' ? null : quoteStatus,
                    expiredDate: expiredDate || null,
                    sort: 'desc'
                }
            });
            console.log('[LTLQuoteList] - getQuoteListByPage result', result);
            setQuoteData(result?.data?.data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        getQuoteListByPage(page);
    };

    const handleChangeCarrier = (event) => {
        console.log(event.target.value);
        setSelectCarrier(event.target.value);
    };

    const handleChangeQuoteStatus = (event) => {
        console.log(event.target.value);
        setQuoteStatus(event.target.value);
    };

    const handleExpiredDate = (event) => {
        const inputDate = event.target.value;
        setExpiredDate(inputDate)
    }

    useEffect(() => {
        getQuoteList();
    }, [selectCarrier, quoteStatus, expiredDate]);

    useEffect(() => {
        (async () => {
            await getQuoteList();
            await getAllAvailableServiceNames();
        })()
    }, []);

    console.log('[LTLQuoteList] selectCarrier', selectCarrier);
    console.log('[LTLQuoteList] quoteStatus', quoteStatus);

    return (
        <Box sx={styles.ShippingOrderRoot}>
            <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                LTL Quote List
            </Typography>
            <Box sx={styles.ShippingOrderContent}>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>
                                <Typography sx={{textAlign: 'left'}}>
                                    Select Carrier
                                </Typography>
                            </FormLabel>
                            <Select
                                value={selectCarrier}
                                size='small'
                                onChange={handleChangeCarrier}
                            >
                                <MenuItem value='all'>
                                    All
                                </MenuItem>
                                {
                                    availableService?.map((carrier, index) => (
                                        <MenuItem key={index} value={carrier?.account_base}>
                                            {carrier?.account_base}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>
                                <Typography sx={{textAlign: 'left'}}>
                                    Quote Status
                                </Typography>
                            </FormLabel>
                            <Select
                                value={quoteStatus}
                                size='small'
                                onChange={handleChangeQuoteStatus}
                            >
                                <MenuItem value='all'>
                                    All
                                </MenuItem>
                                <MenuItem value={1}>
                                    Active
                                </MenuItem>
                                <MenuItem value={-1}>
                                    Inactive
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>
                                <Typography sx={{textAlign: 'left'}}>
                                    Expired Date
                                </Typography>
                                <TextField
                                    fullWidth
                                    type='date'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={expiredDate}
                                    onChange={handleExpiredDate}
                                    size='small'
                                />
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <TextField
                            value={searchString}
                            fullWidth
                            size='small'
                            onInput={handleSearchString}
                            onKeyDown={handleSearchQuoteByKeyDown}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleSearchQuote}
                                        >
                                            <SearchIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            classes={{root: classes.customTextField}}
                            // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                            placeholder='Quote# / Name / Email / Address / Phone'
                        />
                    </Grid>
                    {/*<Grid item md={2} xs={2}>*/}
                    {/*    <Box sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        alignItems: 'center',*/}
                    {/*        height: '100%',*/}
                    {/*        justifyContent: 'flex-end'*/}
                    {/*    }}>*/}
                    {/*        <Button*/}
                    {/*            variant='contained'*/}
                    {/*            sx={{*/}
                    {/*                backgroundColor: '#1D8B45',*/}
                    {/*                height: '100%',*/}
                    {/*                "&:hover": {*/}
                    {/*                    backgroundColor: '#1D8B45',*/}
                    {/*                    filter: 'brightness(0.9)'*/}
                    {/*                },*/}
                    {/*            }}*/}
                    {/*            onClick={handleAddQuote}*/}
                    {/*        >*/}
                    {/*            <Typography*/}
                    {/*                style={{textTransform: 'none', fontSize: '16px'}}*/}
                    {/*            >*/}
                    {/*                Add Quote*/}
                    {/*            </Typography>*/}
                    {/*        </Button>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}
                </Grid>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderList}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    Sorry it currently encounter some issues, please try again later
                                </Typography>
                            </Box>
                            :
                            quoteData?.length === 0 ?
                                <Box sx={styles.ShippingOrderList}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        No result
                                    </Typography>
                                </Box>
                                :
                                <Box sx={styles.ShippingOrderList}>
                                    <LTLQuoteListTable
                                        quote={quoteData}
                                    />
                                    <Pagination
                                        count={lastPage}
                                        page={+currentPage}
                                        size='small'
                                        onChange={handlePageChange}
                                    />
                                </Box>
                }
            </Box>
        </Box>
    )
}