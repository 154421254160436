import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/constInfo";
import {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import {MovingCategoryListTable} from "./MovingCategoryListTable";
import {Pagination} from "@mui/lab";
import {MovingCategoryAreaListTable} from "./MovingCategoryAreaListTable";
import {MovingCategoryAreaAdd} from "./MovingCategoryAreaAdd";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingCategoryAreaList = ({cateInfo}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [areaList, setAreaList] = useState([]);
    const [addNew, setAddNew] = useState(false);

    const getAreaCategoryPricePolicyListByCategoryId = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingCategory/getAreaCategoryPriceListByCategoryId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    // queryString: searchString || '',
                    page: 0,
                    pageSize: PAGE_SIZE,
                    categoryId: cateInfo?.cate_id
                    // parentCateId: (partner === 'default' || partner === 'all') ? undefined : partner
                }
            });
            console.log('[Moving Category List] - result', result);
            setAreaList(result?.data?.data);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getAreaCategoryPricePolicyListByCategoryIdByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingCategory/getAreaCategoryPriceListByCategoryId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    // queryString: searchString || '',
                    page: page,
                    pageSize: PAGE_SIZE,
                    categoryId: cateInfo?.cate_id
                    // parentCateId: (partner === 'default' || partner === 'all') ? undefined : partner
                }
            });
            console.log('[Moving Category List] - result', result);
            setAreaList(result?.data?.data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleAddNewArea = () => {
        setAddNew(true);
    }

    const handleCloseNewArea = () => {
        setAddNew(false);
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        const page = value;
        getAreaCategoryPricePolicyListByCategoryIdByPage(page - 1);
    }

    useEffect(() => {
        (async () => {
            await getAreaCategoryPricePolicyListByCategoryId();
        })()
    }, [cateInfo])
    console.log('[MovingCategoryAreaList] area list', areaList);

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Typography style={{textAlign: 'left', fontSize: '20px', fontWeight: '600'}}>
                    Area Price Policy
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    {
                        cateInfo?.parent_cate_id === 0 &&
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: addNew ? '#454545' : '#1D8B45',
                                height: '100%',
                                "&:hover": {
                                    backgroundColor: addNew ? '#454545' : '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            onClick={addNew ? handleCloseNewArea : handleAddNewArea}
                        >
                            <Typography style={{textTransform: 'none'}}>
                                {addNew ? 'Close' : 'Add New'}
                            </Typography>
                        </Button>
                    }
                </Box>
            </Grid>
            {
                (cateInfo?.parent_cate_id === 0 && addNew) &&
                <Grid item xs={12}>
                    <Box sx={styles.ShippingOrderList}>
                        <MovingCategoryAreaAdd
                            handleCloseNewArea={handleCloseNewArea}
                            getAreaCategoryPricePolicyListByCategoryId={getAreaCategoryPricePolicyListByCategoryId}
                        />
                    </Box>
                </Grid>
            }
            {
                loading ?
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                    </Grid>
                    :
                    error ?
                        <Grid item xs={12}>
                            <Box sx={styles.ShippingOrderList}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    Sorry it currently encounter some issues, please try again later
                                </Typography>
                            </Box>
                        </Grid>
                        :
                        areaList?.length === 0 ?
                            <Grid item xs={12}>
                                <Box sx={styles.ShippingOrderList}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        No result
                                    </Typography>
                                </Box>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Box sx={styles.ShippingOrderList}>
                                    <MovingCategoryAreaListTable
                                        areaList={areaList}
                                        getAreaCategoryPricePolicyListByCategoryId={getAreaCategoryPricePolicyListByCategoryId}
                                    />
                                    <Pagination
                                        count={lastPage}
                                        page={+currentPage}
                                        size='small'
                                        onChange={handlePageChange}
                                    />
                                </Box>
                            </Grid>

            }
        </Grid>
    )
}
