import {ReactComponent as CanadaIcon} from "../images/svg/CA.svg";
import {ReactComponent as USIcon} from "../images/svg/US.svg";
import dayjs from "dayjs";

export const HS_CODE_HELPER = "https://uucargo.ca/blog/cross-border-shipping-guide-2024-expert-tips-for-canada-usa-logistics/";
export const HS_CODE_LOOKUP_URL = 'https://www.canadapost-postescanada.ca/information/app/wtz/business/findHsCode?execution=e1s1';

export const generateRandomString = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export const convertCMToIN = (value) => {
    const convertValue = (value / 2.54).toFixed(0);
    return convertValue;
}

export const convertINToCM = (value) => {
    const convertValue = (value * 2.54).toFixed(0);
    return convertValue;
}

export const convertVolumeCMToIN = (value) => {
    const convertValue = (value / 16.3871).toFixed(0);
    return convertValue;
}

export const convertVolumeINToCM = (value) => {
    const convertValue = (value * 16.3871).toFixed(0);
    return convertValue;
}

export const convertKGToLB = (value) => {
    const convertValue = (value * 2.20462).toFixed(0);
    return convertValue;
}

export const convertLBToKG = (value) => {
    const convertValue = (value / 2.20462).toFixed(0);
    return convertValue;
}

export const extractFullAddress = place => {
    console.log('extract full address', place);
    const address = {
        streetNumber: "",
        streetName: "",
        city: "",
        state: {},
        zip: "",
        country: {},
        neighborhood: "",
        sublocality: "",
        administrative_area_level_3: "",
        region: ""
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    const isSublocality = place?.types?.includes("sublocality");

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;
        const code = component.short_name;

        if (types.includes("street_number")) {
            address.streetNumber = value;
        }

        if (types.includes("route")) {
            address.streetName = value;
        }

        if (types.includes("locality")) {
            address.city = value;
        }

        if (types.includes("neighborhood")) {
            address.neighborhood = value;
        }

        if (types.includes("sublocality")) {
            address.sublocality = value;
        }

        if (types.includes("administrative_area_level_3")) {
            address.administrative_area_level_3 = value;
        }

        if (types.includes("administrative_area_level_2")) {
            address.region = value;
        }

        if (types.includes("administrative_area_level_1")) {
            address.state = {
                name: value,
                code
            }
        }

        if (types.includes("postal_code")) {
            address.zip = value;
        }

        if (types.includes("country")) {
            address.country = {
                name: value,
                code
            }
        }
    });
    return address;
}

// check missing key for quick quote pallet address
export const getMissingKeysForQuickQuotePalletAddress = (obj) => {
    const requiredKeys = ['city', 'province'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeysShipperInfo = (obj) => {
    const requiredKeys = ['shipperName', 'shipperPhone', 'shipperAddress', 'shipperCity', 'shipperProvince', 'shipperCountry', 'shipperPostalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeysConsigneeInfo = (obj) => {
    const requiredKeys = ['consigneeName', 'consigneePhone', 'consigneeAddress', 'consigneeCity', 'consigneeProvince', 'consigneeCountry', 'consigneePostalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeysShipFromInfo = (obj) => {
    const requiredKeys = ['shipperCity', 'shipperProvince', 'shipperCountry'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeysShipToInfo = (obj) => {
    const requiredKeys = ['consigneeCity', 'consigneeProvince', 'consigneeCountry'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const getMissingKeyPickupDateAndTime = (obj) => {
    const requiredKeys = ['availablePickupTime', 'earliestPickupTime', 'latestPickupTime', 'pickupDate', 'shipDate'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const CountryIcon = [
    {
        code: 'CA',
        component: <CanadaIcon width='20' height='20'/>
    },
    {
        code: 'US',
        component: <USIcon width='20' height='20'/>
    }
]

export const mapCountryIcon = (code) => {
    const countryIcon = CountryIcon.filter(item => item.code === code);
    // console.log(countryIcon);
    return countryIcon[0]?.component
}

export const calculateTaxes = (amount) => {
    const taxes =
        [
            {rate: 0, percent: '0%'},
            {rate: 0.05, percent: '5%'},
            {rate: 0.09975, percent: '9.975%'},
            {rate: 0.11, percent: '11%'},
            {rate: 0.12, percent: '12%'},
            {rate: 0.13, percent: '13%'},
            {rate: 0.14975, percent: '14.975%'},
            {rate: 0.15, percent: '15%'}
        ]

    const calculatedTaxes = taxes.map(tax => {
        const taxAmount = (+amount * tax.rate)?.toFixed(2);
        return {
            rate: tax.rate,
            percent: tax.percent,
            amount: taxAmount
        };
    });

    return calculatedTaxes;
}

export const calculateServiceFee = (amount) => {
    const servicesFee =
        [
            {rate: 0.05, percent: '5%'},
            {rate: 0.10, percent: '10%'},
            {rate: 0.15, percent: '15%'},
            {rate: 0.20, percent: '20%'},
        ]

    const calculatedServiceFee = servicesFee.map(each => {
        const eachAmount = (+amount * each.rate)?.toFixed(2);
        return {
            rate: each.rate,
            percent: each.percent,
            amount: eachAmount
        };
    });

    return calculatedServiceFee;
}

export const carManufactures = [
    "Abarth",
    "Alfa Romeo",
    "Aston Martin",
    "Audi",
    "Bentley",
    "BMW",
    "Bugatti",
    "Cadillac",
    "Chevrolet",
    "Chrysler",
    "Citroën",
    "Dacia",
    "Daewoo",
    "Daihatsu",
    "Dodge",
    "Donkervoort",
    "DS",
    "Ferrari",
    "Fiat",
    "Fisker",
    "Ford",
    "Honda",
    "Hummer",
    "Hyundai",
    "Infiniti",
    "Iveco",
    "Jaguar",
    "Jeep",
    "Kia",
    "KTM",
    "Lada",
    "Lamborghini",
    "Lancia",
    "Land Rover",
    "Landwind",
    "Lexus",
    "Lincoln",
    "Lotus",
    "Maserati",
    "Maybach",
    "Mazda",
    "McLaren",
    "Mercedes-Benz",
    "MG",
    "Mini",
    "Mitsubishi",
    "Morgan",
    "Nissan",
    "Opel",
    "Peugeot",
    "Porsche",
    "Renault",
    "Rolls-Royce",
    "Rover",
    "Saab",
    "Seat",
    "Skoda",
    "Smart",
    "SsangYong",
    "Subaru",
    "Suzuki",
    "Tesla",
    "Toyota",
    "Volkswagen",
    "Volvo"
]

export const shippingModeList = [
    "Open Truck",
    "Open Train",
    "Open Truck & Train",
]

export const quotePerUnit = [
    {value: "1lb per unit", unit: 1},
    {value: "10lb per unit", unit: 10},
    {value: "100lb per unit", unit: 100},
]

export const separateTaxes = ({excludeTaxAmount, includeTaxAmount}) => {
    // $taxes = ($includeTaxAmount - $excludeTaxAmount) > 0 ? ($includeTaxAmount - $excludeTaxAmount) : 0;
    //
    // $gstTax = $includeTaxAmount * 0.05;
    //
    // $pstTax = ($taxes - $gstTax) > 0 ? ($taxes - $gstTax) : 0;
    //
    // return ($excludeTaxAmount, $includeTaxAmount, $taxes, $gstTax, $pstTax);
    includeTaxAmount = +includeTaxAmount;
    excludeTaxAmount = +excludeTaxAmount;
    const taxes = (includeTaxAmount - excludeTaxAmount) > 0 ? (includeTaxAmount - excludeTaxAmount) : 0;
    const gstTax = taxes <= excludeTaxAmount * 0.051 ? taxes : 0;
    // const pstTax = (taxes - gstTax) > 0 ? (taxes - gstTax) : 0;
    const qstTax = (taxes >= excludeTaxAmount * 0.0970 && taxes <= excludeTaxAmount * 0.0980) ? taxes : 0;
    const hstTax = ((taxes > excludeTaxAmount * 0.051 && taxes < excludeTaxAmount * 0.0970) || taxes > excludeTaxAmount * 0.0980) ? taxes : 0;
    return {
        excludeTaxAmount,
        includeTaxAmount,
        taxes: taxes.toFixed(2),
        // gstTax: gstTax.toFixed(2),
        // pstTax: pstTax.toFixed(2),
        gstTax: gstTax.toFixed(2),
        pstTax: 0,
        qstTax: qstTax.toFixed(2),
        hstTax: hstTax.toFixed(2),
        "GST/HST": taxes.toFixed(2)
    }
}

export const trackingLink = (carrier, trackingNumber) => {

    switch (carrier.toUpperCase()) {
        case 'FEDEX':
            return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}&trkqual=12027~${trackingNumber}~FDEG`;
            break;
        case 'CANADAPOST':
            return `https://www.canadapost-postescanada.ca/track-reperage/en#/details/${trackingNumber}`;
            break;
        case 'UPS':
            return `https://www.ups.com/track?loc=en_CA&requester=QUIC&tracknum=${trackingNumber}/trackdetails`;
            break;
        case 'CANPAR':
            return `https://www.canpar.com/en/tracking/track.htm?barcode=${trackingNumber}`;
            break;
        default:
            return '#'
    }
}

export const provinces_states = [
    {countryCode: 'CA', country: 'Canada', code: 'AB', name: 'Alberta'},
    {countryCode: 'CA', country: 'Canada', code: 'BC', name: 'British Columbia'},
    {countryCode: 'CA', country: 'Canada', code: 'MB', name: 'Manitoba'},
    {countryCode: 'CA', country: 'Canada', code: 'NB', name: 'New Brunswick'},
    {countryCode: 'CA', country: 'Canada', code: 'NL', name: 'Newfoundland and Labrador'},
    {countryCode: 'CA', country: 'Canada', code: 'NS', name: 'Nova Scotia'},
    {countryCode: 'CA', country: 'Canada', code: 'NT', name: 'Northwest Territories'},
    {countryCode: 'CA', country: 'Canada', code: 'NU', name: 'Nunavut'},
    {countryCode: 'CA', country: 'Canada', code: 'ON', name: 'Ontario'},
    {countryCode: 'CA', country: 'Canada', code: 'PE', name: 'Prince Edward Island'},
    {countryCode: 'CA', country: 'Canada', code: 'QC', name: 'Quebec'},
    {countryCode: 'CA', country: 'Canada', code: 'SK', name: 'Saskatchewan'},
    {countryCode: 'CA', country: 'Canada', code: 'YT', name: 'Yukon'},
    {countryCode: 'US', country: 'United States', name: 'Alabama', code: 'AL'},
    {countryCode: 'US', country: 'United States', name: 'Alaska', code: 'AK'},
    {countryCode: 'US', country: 'United States', name: 'Arizona', code: 'AZ'},
    {countryCode: 'US', country: 'United States', name: 'Arkansas', code: 'AR'},
    {countryCode: 'US', country: 'United States', name: 'California', code: 'CA'},
    {countryCode: 'US', country: 'United States', name: 'Colorado', code: 'CO'},
    {countryCode: 'US', country: 'United States', name: 'Connecticut', code: 'CT'},
    {countryCode: 'US', country: 'United States', name: 'Delaware', code: 'DE'},
    {countryCode: 'US', country: 'United States', name: 'District of Columbia', code: 'DC'},
    {countryCode: 'US', country: 'United States', name: 'Florida', code: 'FL'},
    {countryCode: 'US', country: 'United States', name: 'Georgia', code: 'GA'},
    {countryCode: 'US', country: 'United States', name: 'Hawaii', code: 'HI'},
    {countryCode: 'US', country: 'United States', name: 'Idaho', code: 'ID'},
    {countryCode: 'US', country: 'United States', name: 'Illinois', code: 'IL'},
    {countryCode: 'US', country: 'United States', name: 'Indiana', code: 'IN'},
    {countryCode: 'US', country: 'United States', name: 'Iowa', code: 'IA'},
    {countryCode: 'US', country: 'United States', name: 'Kansas', code: 'KS'},
    {countryCode: 'US', country: 'United States', name: 'Kentucky', code: 'KY'},
    {countryCode: 'US', country: 'United States', name: 'Louisiana', code: 'LA'},
    {countryCode: 'US', country: 'United States', name: 'Maine', code: 'ME'},
    {countryCode: 'US', country: 'United States', name: 'Maryland', code: 'MD'},
    {countryCode: 'US', country: 'United States', name: 'Massachusetts', code: 'MA'},
    {countryCode: 'US', country: 'United States', name: 'Michigan', code: 'MI'},
    {countryCode: 'US', country: 'United States', name: 'Minnesota', code: 'MN'},
    {countryCode: 'US', country: 'United States', name: 'Mississippi', code: 'MS'},
    {countryCode: 'US', country: 'United States', name: 'Missouri', code: 'MO'},
    {countryCode: 'US', country: 'United States', name: 'Montana', code: 'MT'},
    {countryCode: 'US', country: 'United States', name: 'Nebraska', code: 'NE'},
    {countryCode: 'US', country: 'United States', name: 'Nevada', code: 'NV'},
    {countryCode: 'US', country: 'United States', name: 'New Hampshire', code: 'NH'},
    {countryCode: 'US', country: 'United States', name: 'New Jersey', code: 'NJ'},
    {countryCode: 'US', country: 'United States', name: 'New Mexico', code: 'NM'},
    {countryCode: 'US', country: 'United States', name: 'New York', code: 'NY'},
    {countryCode: 'US', country: 'United States', name: 'North Carolina', code: 'NC'},
    {countryCode: 'US', country: 'United States', name: 'North Dakota', code: 'ND'},
    {countryCode: 'US', country: 'United States', name: 'Ohio', code: 'OH'},
    {countryCode: 'US', country: 'United States', name: 'Oklahoma', code: 'OK'},
    {countryCode: 'US', country: 'United States', name: 'Oregon', code: 'OR'},
    {countryCode: 'US', country: 'United States', name: 'Pennsylvania', code: 'PA'},
    {countryCode: 'US', country: 'United States', name: 'Rhode Island', code: 'RI'},
    {countryCode: 'US', country: 'United States', name: 'South Carolina', code: 'SC'},
    {countryCode: 'US', country: 'United States', name: 'South Dakota', code: 'SD'},
    {countryCode: 'US', country: 'United States', name: 'Tennessee', code: 'TN'},
    {countryCode: 'US', country: 'United States', name: 'Texas', code: 'TX'},
    {countryCode: 'US', country: 'United States', name: 'Utah', code: 'UT'},
    {countryCode: 'US', country: 'United States', name: 'Vermont', code: 'VT'},
    {countryCode: 'US', country: 'United States', name: 'Virginia', code: 'VA'},
    {countryCode: 'US', country: 'United States', name: 'Washington', code: 'WA'},
    {countryCode: 'US', country: 'United States', name: 'West Virginia', code: 'WV'},
    {countryCode: 'US', country: 'United States', name: 'Wisconsin', code: 'WI'},
    {countryCode: 'US', country: 'United States', name: 'Wyoming', code: 'WY'}
]

export const getDateFromDaysLater = (day) => {
    const today = new Date();
    const hours = day * 24;
    const futureDate = new Date(today.getTime() + hours * 60 * 60 * 1000); // Adding two day

    return dayjs(futureDate);
};

// check missing keys for moving address from moving item
export const getMissingKeysForMovingAddressFromItem = (obj) => {
    const requiredKeys = [
        'streetAddress',
        'city',
        'province',
        'country',
        'postalCode',
        'propertyType',
        'lift',
        'floorLevel',
        'distanceFromTruckToDoor',
        'drivewaySteepLevel'
    ];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// valid Canadian Postal Code
export const isValidPostalCode = (postalCode) => {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i;
    return regex.test(postalCode);
}

// valid US Postal Code
export const isValidZipCode = (zipCode) => {
    const regex = /^\d{5}(?:[-\s]\d{4})?$/;
    return regex.test(zipCode);
}

// check missing keys for address book
export const getMissingKeysForMovingContact = (obj) => {
    const requiredKeys = ['pickupName', 'pickupPhone', 'pickupEmail', 'deliverName', 'deliverPhone', 'deliverEmail'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing keys for container shipping
export const getMissingKeysForContainerShipping = (obj) => {
    const requiredKeys = ['name', 'phone'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// validate email
export const isValidateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
}

// validate phone
export const isValidatePhone = (value) => {
    const cleanedString = value?.replace(/[^a-zA-Z0-9]/g, '');
    const phoneNumberRegex = /^\d{10,15}$/;
    return phoneNumberRegex.test(cleanedString);
}

export const movingOrderStatus = (orderStatus) => {
    switch (orderStatus) {
        case 0:
            return 'Initial';
            break;
        case 100:
            return 'Paid Deposit';
            break;
        case 1:
            return 'Payment Received';
            break;
        case 2:
            return 'Paused';
            break;
        case 3:
            return 'Refunded';
            break;
        case 4:
            return 'Refunded';
            break;
        case -1:
            return 'Cancelled';
            break;
        default:
            return 'Invalid';
    }
}

export const movingShippingStatus = (shippingStatus) => {
    switch (shippingStatus) {
        case 1:
            return 'Initial';
            break;
        case 2:
            return 'Processing';
            break;
        case 3:
            return 'Pickup Confirmed';
            break;
        case 4:
            return 'Deliver Confirmed';
            break;
        case 20:
            return 'Waiting Approved';
            break;
        case 25:
            return 'Confirmed';
            break;
        case 30:
            return 'Pickup Unconfirmed';
            break;
        case 40:
            return 'Deliver Unconfirmed';
            break;
        default:
            return 'Invalid';
    }
}

export const getAmountOfFTLCount = (packageData) => {
    console.log('package data', packageData);
    let result = 0;
    let totalQuantity = 0;
    for (const onePackage of packageData) {
        const {quantity} = onePackage;
        totalQuantity += +quantity;
    }
    if (totalQuantity > 19 && totalQuantity < 24) {
        return 1
    } else {
        result = Math.floor(totalQuantity / 24);
        return result;
    }
};

export const roomPlaces = [
    {
        roomPlace: "Bedroom",
        icon_uri: "uucargo-resource/moving/categories/Bedroom.svg",
    },
    {
        roomPlace: "Kitchen",
        icon_uri: "uucargo-resource/moving/categories/Kitchen.svg",
    },
    {
        roomPlace: "Living Room",
        icon_uri: "uucargo-resource/moving/categories/Living%20Room.svg",
    },
    {
        roomPlace: "Dinning Room",
        icon_uri: "uucargo-resource/moving/categories/Dining%20room.svg",
    },
    {
        roomPlace: "Bathroom",
        icon_uri: "uucargo-resource/moving/categories/Bathroom.svg",
    },
    {
        roomPlace: "Home Office",
        icon_uri: "uucargo-resource/moving/categories/Home%20Office.svg",
    },
    {
        roomPlace: "Garden/Patio",
        icon_uri: "uucargo-resource/moving/categories/Garden.svg",
    },
    {
        roomPlace: "Garage",
        icon_uri: "uucargo-resource/moving/categories/Garage.svg",
    },
    {
        roomPlace: "Boxes",
        icon_uri: "uucargo-resource/moving/categories/Box.svg",
    },
    {
        roomPlace: "Others",
        icon_uri: "uucargo-resource/moving/categories/Other.svg",
    }
];

export const getCountryCode = (accountBase = "ups") => {
    return [
        {
            name: "Argentina",
            code: "AR"
        },
        {
            name: "Australia",
            code: "AU"
        },
        {
            name: "Brazil",
            code: "BR"
        },
        {
            name: "Cambodia",
            code: "KH"
        },
        {
            name: "Canada",
            code: "CA"
        },
        {
            name: "China",
            code: "CN"
        },
        {
            name: "France",
            code: "FR"
        },
        {
            name: "Germany",
            code: "DE"
        },
        {
            name: "Hong Kong",
            code: "HK"
        },
        {
            name: "India",
            code: "IN"
        },
        {
            name: "Indonesia",
            code: "ID"
        },
        {
            name: "Italy",
            code: "IT"
        },
        {
            name: "Japan",
            code: "JP"
        },
        {
            name: "Laos People's Democratic LA Republic",
            code: "LA"
        },
        {
            name: "Malaysia",
            code: "MY"
        },
        {
            name: "Mexico",
            code: "MX"
        },
        {
            name: "Portugal",
            code: "PT"
        },
        {
            name: "Russia",
            code: "RU"
        },
        {
            name: "Saudi Arabia",
            code: "SA"
        },
        {
            name: "South Africa",
            code: "ZA"
        },
        {
            name: "South Korea",
            code: "KR"
        },
        {
            name: "Spain",
            code: "ES"
        },
        {
            name: "Taiwan",
            code: "TW"
        },
        {
            name: "Thailand",
            code: "TH"
        },
        {
            name: "Turkey",
            code: "TR"
        },
        {
            name: "United Kingdom",
            code: "GB"
        },
        {
            name: "United States",
            code: "US"
        },
        {
            name: "VietNam",
            code: "VN"
        },
    ]
}

export const containerShippingFileList = ['Commercial Invoice', 'Packing List', 'DG Form', 'E-Manifest', 'Bill of Lading']

export const containerShippingServiceList = ['Drayage: From Port to Warehouse', 'Transload / Destuff', 'Sorting', 'Customs Inspection & Storage', 'Palletizing', 'E-Manifest']

// check missing key from the object
export const getMissingKeysFromObject = (obj) => {
    console.log('[getMissingKeysFromObject] obj', obj);
    const missingKeys = Object.keys(obj).filter(key => {
        return obj[key]?.trim() === "" || obj[key] === undefined || obj[key] === null;
    });

    return missingKeys;
}

// check missing key for become endUser
export const getMissingKeysForBecomeEndUser = (obj) => {
    const requiredKeys = ['contactEmail', 'password', 'firstName', 'lastName'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const isValidatePassword = (value) => {
    const passwordRegex = /^.{6,20}$/;
    return passwordRegex.test(value);
}

export const getMissingKeysForBecomePartner = (obj) => {
    const requiredKeys = ['contactEmail', 'companyPhone', 'password', 'companyName', 'firstName', 'lastName', 'companyEmail', 'companyAddress'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

// check missing key for pickup info
export const getMissingKeysForPickupInformation = (obj) => {
    const requiredKeys = ['name', 'phone', 'email', 'address', 'city', 'province', 'country', 'postalCode'];
    const missingKeys = requiredKeys.filter(key => {
        return !obj.hasOwnProperty(key) || obj[key] === "" || obj[key] === undefined || obj[key] === null;
    });
    return missingKeys;
}

export const UOMList = [
    "BOX",
    "BAG",
    "CASE",
    "PALLET",
    "PACKAGE",
    "PAIRS",
    "EACH",
    "ARS",
    "BARREL",
    "BOLT",
    "BUNCH",
    "BUNDLE",
    "BUTT",
    "CANISTER",
    "CARTON",
    "CENTIMETER",
    "CONTAINER",
    "CRATE",
    "CYLINDER",
    "DOZEN",
    "ENVELOPE",
    "FEET",
    "KILOGRAM",
    "LITER",
    "METER",
    "NUMBER",
    "PACKET",
    "PIECES",
    "POUNDS",
    "PROOFLITER"
]

export const generateUniqueName = (baseName, nameList) => {
    // Extract names from the array of objects
    const existingNames = nameList.map(item => item.description);

    // Check if baseName already has a numeric suffix like "car(1)"
    const regex = /^(.*?)(\(\d+\))?$/;
    const match = baseName.match(regex);
    let nameWithoutSuffix = match[1]; // "car"
    let suffixNumber = match[3] ? parseInt(match[3], 10) : 0; // existing number or 0

    let newName = baseName;
    let counter = suffixNumber + 1;

    while (existingNames.includes(newName)) {
        newName = `${nameWithoutSuffix}(${counter})`;
        counter++;
    }

    return newName;

}

export const transportationList = [
    {"code": "INT_TRUCK", "name": "International Truck"},
    {"code": "INT_AIR", "name": "International Air"},
    {"code": "INT_OCEAN", "name": "International Ocean"},
    {"code": "INT_RAIL", "name": "International Rail"}
]

export const internationalShipmentTypeList = [
    "GIFT",
    "COMMERCIAL",
    "SALE",
    "SAMPLE",
    "REPAIR",
    "RETURN",
    "OTHER"
]

export const canadaProvinceCodeToNameMap = {
    "AB": "Alberta",
    "BC": "British Columbia",
    "MB": "Manitoba",
    "NB": "New Brunswick",
    "NF": "Northwest Territories",
    "NT": "Northwest Territories",
    "NS": "Nova Scotia",
    "NU": "Nunavut",
    "ON": "Ontario",
    "PE": "Prince Edward Island",
    "QC": "Quebec",
    "SK": "Saskatchewan",
    "YT": "Yukon"
}

export const americaProvinceCodeToNameMap = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export const checkHSCodeInValid = (errorList, hsCode) => {
    // console.log('[checkHSCodeInValid] errorList', errorList);
    // console.log('[checkHSCodeInValid] hsCode', hsCode);

    return errorList?.some(item => +item?.hsCode === +hsCode)
}

export const carSizeList = [
    "SEDAN",
    "COMPACT-SUV",
    "MIDDLE-SUV",
    "LARGE-SUV",
    "PICKUP-TRUCK",
]
