import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import ReviceShippingOrderInvoices from "./ReviceShippingOrderInvoices";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%"
  },
  unTrackingTable: {
    "border-spacing": "2rem 1rem"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
    "white-space": "nowrap"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const ReviseInvoicePage = () => {
  const classes = useStyles();
  return (
    <Box paddingBottom="3rem">
      <ReviceShippingOrderInvoices />
    </Box>
  );
};

export default ReviseInvoicePage;
