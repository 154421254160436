import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange, red, green, grey } from "@mui/material/colors";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LensIcon from '@mui/icons-material/Lens';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import dayjs from "dayjs";
// import utc from "dayjs/plugin/utc";
// import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/en";

// dayjs.extend(utc);
// dayjs.extend(timezone);
// const localTimeZone = dayjs.tz.guess()
// console.log(localTimeZone)

const theme = createTheme({
  palette: {
    primary: orange,
    danger: red,
    success: green,
    warning: red,
    secondary: grey,
  },
});

const formatDate = (dateString) => {
  return dayjs(dateString).toString()
}

const TrackPackageItem = ({ activity,
                            trackingNo,
                            courier,
                            deliverStatus,
                            transaction: {
                              send_to_addr,
                              send_to_city,
                              send_to_province,
                              send_to_postal_code,
                              send_to_country,
                              send_to_name
}}) => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          textAlign: "start",
          // p: 2,
          width: "100%",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{trackingNo}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{
            // ml: 2,
          }}>
            <Grid container>
              <Grid item xs={9}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" sx={{
                    color: "secondary.600"
                  }}>Courier: &nbsp;</Typography>
                  <Typography variant="h6">{courier?.toUpperCase()}</Typography>
                </Box>
                <Box
                  sx={{
                    // mt: 1,
                    display: "flex",
                  }}
                >
                  <LocationOnIcon />
                  <Typography sx={{
                    ml: 1,
                    color: "secondary.600"
                  }}>Deliver to: &nbsp;</Typography>
                  <Typography>
                    {send_to_name}
                    <br />
                    {send_to_addr}, {send_to_city}, {send_to_province}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
              <Box
                  sx={{
                    // mt: 1,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "end",
                    height: "100%",
                    justifyContent: "center",
                    // pr: 2,
                  }}
                >
                  <Typography sx={{
                    // ml: 1,
                    color: "secondary.600"
                  }}>Status</Typography>
                  <Typography>{deliverStatus}</Typography>
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{
              mt: 1,
              width: "100%",
              color: "secondary.main"
            }} />
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              {activity
                ? activity.map((activityElement, index) => {
                    return (
                      <ListItem key={index}>
                        <ListItemAvatar>
                          {/* <Avatar> */}
                            <LensIcon sx={{
                              // color: "primary.main",
                            }} />
                          {/* </Avatar> */}
                        </ListItemAvatar>
                        <ListItemText
                          primary={activityElement.description}
                          secondary={formatDate(activityElement.date)}
                        />
                      </ListItem>
                    );
                  })
                : undefined}
            </List>
            {/*<>*/}
            {/*  <Button*/}
            {/*    // loading={isLoading}*/}
            {/*    variant="outlined"*/}
            {/*    // onClick={getTrackedPackageList}*/}
            {/*    sx={{*/}
            {/*    // color: "white",*/}
            {/*    // backgroundColor: "primary.main",*/}
            {/*    // "&:hover": {*/}
            {/*    //   color: "primary.main",*/}
            {/*    //   backgroundColor: "white",*/}
            {/*    //   borderColor: "primary.main",*/}
            {/*    //   borderStyle: "solid",*/}
            {/*    //   borderWidth: 1,*/}
            {/*    // },*/}
            {/*    borderRadius: 5,*/}
            {/*    width: 100,*/}
            {/*    height: 35,*/}
            {/*    fontSize: "x-small",*/}
            {/*    mr: 1,*/}
            {/*  }}>Placeholder1</Button>*/}
            {/*  <Button*/}
            {/*    // loading={isLoading}*/}
            {/*    variant="outlined"*/}
            {/*    // onClick={getTrackedPackageList}*/}
            {/*    sx={{*/}
            {/*    // color: "white",*/}
            {/*    // backgroundColor: "primary.main",*/}
            {/*    // "&:hover": {*/}
            {/*    //   color: "primary.main",*/}
            {/*    //   backgroundColor: "white",*/}
            {/*    //   borderColor: "primary.main",*/}
            {/*    //   borderStyle: "solid",*/}
            {/*    //   borderWidth: 1,*/}
            {/*    // },*/}
            {/*    borderRadius: 5,*/}
            {/*    width: 100,*/}
            {/*    height: 35,*/}
            {/*    fontSize: "x-small",*/}
            {/*    mr: 1,*/}
            {/*  }}>Placeholder2</Button>*/}
            {/*  <Button*/}
            {/*    // loading={isLoading}*/}
            {/*    variant="outlined"*/}
            {/*    // onClick={getTrackedPackageList}*/}
            {/*    sx={{*/}
            {/*    // color: "white",*/}
            {/*    // backgroundColor: "primary.main",*/}
            {/*    // "&:hover": {*/}
            {/*    //   color: "primary.main",*/}
            {/*    //   backgroundColor: "white",*/}
            {/*    //   borderColor: "primary.main",*/}
            {/*    //   borderStyle: "solid",*/}
            {/*    //   borderWidth: 1,*/}
            {/*    // },*/}
            {/*    borderRadius: 5,*/}
            {/*    width: 100,*/}
            {/*    height: 35,*/}
            {/*    fontSize: "x-small",*/}
            {/*    mr: 1,*/}
            {/*  }}>Placeholder3</Button>*/}
            {/*  </>*/}
          </AccordionDetails>
        </Accordion>
      </Box>
    </ThemeProvider>
  );
};

export default TrackPackageItem;
