import {Fragment, useEffect, useState} from "react";
import {Button, Divider, Grid, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import {MovingCategoryAreaEdit} from "./MovingCategoryAreaEdit";
import Box from "@mui/material/Box";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingCategoryAreaListEach = ({
                                               area_info,
                                               cbGetPolicyId,
                                               policyId,
                                               getAreaCategoryPricePolicyListByCategoryId
                                           }) => {

    console.log('[MovingCategoryAreaListEach] policy id', policyId);

    return (
        <Fragment>
            <Grid item xs={2}>
                <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                    {area_info?.area_name || area_info?.group_name}, {area_info?.province_shortname}, {area_info?.country}
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Min Pickup: ${area_info?.pickup_min_charge}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Self Pickup: ${area_info?.self_pickup_charge}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Min Deliver: ${area_info?.deliver_min_charge}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Rate: ${area_info?.labour_hour_cost} / hour
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Min Labours: {area_info?.min_labours}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Moving: {area_info?.min_moving_hours}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Packing: {area_info?.min_packing_hours}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Wrapping: {area_info?.min_wrapping_hours}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Packing: ${area_info?.packing_unit_cost}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Wrapping: ${area_info?.wrapping_unit_cost}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Daily Storage: ${area_info?.storage_daily_unit_cost}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Weekly Storage: ${area_info?.storage_weekly_unit_cost}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Monthly Storage: ${area_info?.storage_monthly_unit_cost}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            House Type: {area_info?.houseType}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Floor Level: {area_info?.floorLevel}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Driveway Level: {area_info?.drivewaySteepLevel === 0 ? "Flat" : area_info?.drivewaySteepLevel === 1 ? "Moderate" : 'Steep'}
                        </Typography>
                        <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                            Has Lift: {area_info?.hasLift === 1 ? 'Yes' : 'No'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Button
                    variant='outlined'
                    sx={{
                        border: '2px solid #1D8B45',
                        "&:hover": {
                            border: '2px solid #1D8B45',
                            filter: 'brightness(0.9)'
                        }
                    }}
                    onClick={() => cbGetPolicyId(area_info?.policy_id)}
                >
                    <Typography style={{
                        fontSize: '14px',
                        textTransform: 'none',
                        color: '#1D8B45'
                    }}>
                        Details
                    </Typography>
                </Button>
            </Grid>
            {
                policyId === area_info?.policy_id &&
                <Grid item xs={12}>
                    <Box sx={styles.ShippingOrderList}>
                        <MovingCategoryAreaEdit policyId={policyId} cbGetPolicyId={cbGetPolicyId}
                                                getAreaCategoryPricePolicyListByCategoryId={getAreaCategoryPricePolicyListByCategoryId}/>
                    </Box>
                </Grid>
            }
            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}