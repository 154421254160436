import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import EndUserListItem from "./EndUserListItem";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
}));

const EndUserListTable = ({ endUserList }) => {
    const classes = useStyles();
    return (
        <div>
            <Box maxWidth="70%" margin="0 auto">
                <Box mt={2}>
                    <table className={classes.table}>
                        <thead>
                        <tr>
                            <th className={classes.idTH}>ID</th>
                            <th className={classes.idTH}>Name</th>
                            <th className={classes.idTH}>Email</th>
                            <th className={classes.idTH}>Phone</th>
                            {/*<th className={classes.nameTH}>Admin</th>*/}
                            <th className={classes.nameTH}>Status</th>
                            <th className={classes.nameTH}>Type</th>
                            <th className={classes.nameTH}></th>
                        </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                        {endUserList.map((v, index) => {
                            return (
                                <EndUserListItem
                                    key={index}
                                    id={v.id}
                                    name={`${v?.firstname} ${v?.lastname}`}
                                    email={v.email}
                                    phone={v?.phone}
                                    status={v?.status}
                                    userType={v?.usertype}
                                />
                            );
                        })}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </div>
    );
};

export default EndUserListTable;