import {Fragment, useEffect, useRef, useState} from "react";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {Button, FormHelperText, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
})

export const LTLOrderDashboardClone = ({orderData, cancelClone}) => {

    const classes = useStyles();

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [partnerList, setPartnerList] = useState([]);
    const [partner, setPartner] = useState('default');
    const [displayPartner, setDisplayPartner] = useState(null);
    const [availableService, setAvailableService] = useState([]);
    const [selectCarrier, setSelectCarrier] = useState('default');
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cloneLTLOrder = async () => {
        setLoading(true);
        try {
            const requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/cloneLTLOrder`;

            let data =
                {
                    orderId: orderData?.id,
                    partnerId: partner,
                    serviceName: selectCarrier
                }
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            // console.log('[LTLOrderDashboardClone] [LTLOrderDashboardClone] result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Clone Order');
            handleSuccessMessage();
            setTimeout(() => {
                history.push("/v2/ltl-order-list");
            }, 2000);
        } catch (e) {
            console.log('error', e.response);
            setSuccessMessage('Fail to Clone Order');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setPartnerList(result?.data);
            const partner = result?.data?.filter(each => +each?.id === +orderData?.partner_id);
            setDisplayPartner(partner[0]);
            setPartner(orderData?.partner_id);
        } catch (e) {
            console.log(e.response);
        }
    }

    const searchPartner = async (queryString) => {
        const requestURL = `${PARTNER_URI}/elasticsearch/partner/searchPartners`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: queryString,
                }
            });
            console.log('[partner list] - result', result);
            return result?.data?.result;
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleChangePartner = (event, value) => {
        console.log(value);
        if (value) {
            setPartner(value?.id);
            setDisplayPartner(value);
        } else {
            setPartner('default');
            setDisplayPartner(null);
        }
    };

    const handleInputChange = async (event, value) => {
        console.log('value', value);
        if (value) {
            const result = await searchPartner(value);
            setPartnerList(result);
        }
    }

    const getAllAvailableServiceNames = async () => {
        let requestURL = `${PARTNER_URI}/shippingLTL/getAllAvailableServiceNames`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            })
            // console.log('result', result);
            setAvailableService(result?.data);
        } catch (e) {
            console.log('error', e);
        }
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setSelectCarrier(event.target.value);
    };

    useEffect(() => {
        getAllAvailableServiceNames();
        getAllPartnerList();
    }, [])

    useEffect(() => {
        console.log('filer partner list');
        setSelectCarrier(orderData?.account_base_combine_name?.toLowerCase());
    }, [orderData])

    console.log('[LTLOrderDashboardClone] orderData', orderData);
    console.log('[LTLOrderDashboardClone] partnerList', partnerList);
    console.log('[LTLOrderDashboardClone] displayPartner', displayPartner);
    console.log('[LTLOrderDashboardClone] partner', partner);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px'
                }}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Carrier
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            value={selectCarrier}
                            size='small'
                            // placeholder='Select Partner'
                            onChange={handleChange}
                            // disabled={isConfirm}
                        >
                            <MenuItem disabled value='default'>
                                <em>Select Carrier</em>
                            </MenuItem>
                            {
                                availableService?.map((carrier, index) => (
                                    <MenuItem key={index} value={carrier?.account_base.toLowerCase()}>
                                        {carrier?.account_base}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '10px'
                }}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Partner
                    </Typography>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={displayPartner}
                            options={partnerList}
                            getOptionLabel={option => option?.business_name}
                            filterOptions={(options, state) => options}
                            fullWidth
                            renderInput={(params) => <TextField
                                {...params}
                                className={classes.smallInput}
                                placeholder='Select A Partner'
                            />}
                            onInputChange={handleInputChange}
                            onChange={handleChangePartner}
                            renderOption={(option) => (
                                <Typography
                                    style={{fontSize: '14px'}}
                                >
                                    {/*<Typography>*/}
                                    {option?.business_name}
                                    {/*</Typography>*/}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end'
                }}>
                    <Button
                        variant='contained'
                        sx={{
                            backgroundColor: '#454545',
                            "&:hover": {
                                backgroundColor: '#454545',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={cancelClone}
                    >
                        <Typography style={{
                            textTransform: 'none'
                        }}>
                            Cancel
                        </Typography>
                    </Button>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={cloneLTLOrder}
                        loading={loading}
                    >
                        <Typography style={{
                            textTransform: 'none'
                        }}>
                            Confirm
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
        </Grid>
    )
}