import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox, Select
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { PARTNER_URI, LABEL_DOWNLOAD_URL_PREFIX } from "../../utils/apiUrl";
import { useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { separateTaxes } from "../../utils/calculateTax";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ExportTransactionTable from "./ExportTransactionTable";
import { getAccessToken } from "../../utils/doToken";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word"
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff"
  },
  table: {
    width: "80%"
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)"
    }
  },
  nameTH: {
    width: "100px"
  },
  idTH: {
    width: "100px"
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2)
    },
    display: "flex",
    justifyContent: "center"
  },
  listItemWrapper: {
    textAlign: "left"
  }
}));

const ShippingExportsTransaction = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 100;

  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);
  console.log(partnerInfo);

  const { register, handleSubmit, control } = useForm();
  const [shippingListData, setShippingListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectUPS, setSelectUPS] = useState(false);
  const [selectFedEx, setSelectFedEx] = useState(false);
  const cacheInput = useRef({});
  const [transactionStatus, setTransactionStatus] = useState(1);
  const [carrier, setCarrier] = useState("ALL");
  const [dateRange, setDateRange] = useState([
      {
        startDate: dayjs(new Date()).subtract(30, "days").toDate(),
        endDate: dayjs(new Date()).add(1, "day").toDate(),
        key: "selection"
      }
    ]
  );
  const [csvFileName, setCSVFileName] = useState(`Trans-${partnerInfo.id}-${dayjs(dateRange[0].startDate).format("YYYY-MM-DD")}-${dayjs(dateRange[0].endDate).format("YYYY-MM-DD")}.csv`);
  const [startDate, setStartDate] = useState(dayjs(new Date()).subtract(30, "days"));
  const [endDate, setEndDate] = useState(dayjs(new Date()).add(1, "day"));

  const newExcelRowHeader = [
    "Order ID",
    "Tran ID",
    "User Type",
    "User Id",
    "Rate",
    "Client Name",
    "Freight",
    "Carrier",
    "Number of Package",
    "Total Charge",
    "Exclude Tax",
    "Include Tax",
    "Service Fee Exclude Tax",
    "Service Fee Include Tax",
    "Brokerage Charge",
    "Duties Charge",
    "Duties Tax Charge",
    "Shipping Total Tax",
    "Freight Charge",
    "Surcharge Details",
    "Date",
    "Tracking #",
    // "Send From Name",
    // "Send From Address",
    "Send From City",
    "Send From Province",
    "Send From Country",
    "Send From Postal Code",
    // "Send To Name",
    // "Send To Phone",
    // "Send To Email",
    // "Send To Address",
    "Send To City",
    "Send To Province",
    "Send To Country",
    "Send To Postal Code",

    "CRM Invoice Ref",
    "Vendor Invoice Number",
    "Vendor Shipping Fee Exclude Tax",
    "Vendor Shipping Fee Include Tax",
    "Adjustment Amount Exclude Tax",
    "Adjustment Amount Include Tax"
  ];

  const [transactions, setTransactions] = useState([
    newExcelRowHeader
  ]);

  const csvLink = useRef();

  const getTransactionData = async () => {
    try {
      let {
        startOrderId,
        endOrderId,
        refOrderNumber,
        refProductNumber,
        transactionStatus,
        crmInvoices,
        startTransactionDate,
        endTransactionDate
      } = cacheInput.current;
      let requestURI = `${PARTNER_URI}/shipping/list/admin/exportAllTransactionsByPartnerId`;
      startTransactionDate = dayjs(startTransactionDate).format("YYYY-MM-DD");
      endTransactionDate = dayjs(endTransactionDate).format("YYYY-MM-DD");
      // if (transactionStatus === 2) {
      //   requestURI = `${PARTNER_URI}/shipping/list/admin/exportAllTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      //   if (carrier === "ALL") {
      //     requestURI = `${PARTNER_URI}/shipping/list/admin/exportAllTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      //   } else {
      //     requestURI = `${PARTNER_URI}/shipping/list/admin/exportAllTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&account_base=${carrier}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      //   }
      // } else {
      //   requestURI = `${PARTNER_URI}/shipping/list/admin/exportAllTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&tranStatus=${transactionStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      //   if (carrier === "ALL") {
      //     requestURI = `${PARTNER_URI}/shipping/list/admin/exportAllTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&tranStatus=${transactionStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      //   } else {
      //     requestURI = `${PARTNER_URI}/shipping/list/admin/exportAllTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&account_base=${carrier}&tranStatus=${transactionStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      //   }
      // }
      const { data } = await axios.get(requestURI, {
        params: {
          crmInvoices,
          startTransactionDate,
          endTransactionDate,
          tranStatus: transactionStatus
        }
      });

      const result = data.result;
      const resultArray = [
        newExcelRowHeader
      ];
      for (const column of result) {
        const userType = +column.owner_role === 1 ? "End User" : "Partner";
        const isCrossBorder = column?.send_from_country?.toLowerCase() === column?.send_to_country?.toLowerCase();
        const freightType = isCrossBorder ? "International" : "Domestic";
        let serviceFeeRate = 0;
        if (+column.owner_role === 1) {
          serviceFeeRate = column.end_user_service_fee_rate;
        } else {
          const accountBase = column.account_base;
          switch (accountBase?.toLowerCase()) {
            case "fedex":
              serviceFeeRate = column.fedex_service_fee_rate;
              break;
            case "canpar":
              serviceFeeRate = column.canpar_service_fee_rate;
              break;
            case "ups":
              serviceFeeRate = column.ups_service_fee_rate;
              break;
            case "canadapost":
              serviceFeeRate = column.canadapost_service_fee_rate;
              break;
            default:
              serviceFeeRate = column.partner_service_fee_rate;
              break;
          }
          if (+serviceFeeRate <= 0) {
            serviceFeeRate = column.partner_service_fee_rate;
          }
        }
        let clientName = "";
        if (+column.owner_role === 1) {
          clientName = `${column.firstname} ${column.lastname}`;
        } else {
          clientName = `${column.business_name}`;
        }

        const columnData = [];
        columnData.push(column.order_id);
        columnData.push(column.tran_id);
        columnData.push(userType);
        columnData.push(column.partner_id);
        columnData.push(serviceFeeRate);
        columnData.push(clientName);
        columnData.push(freightType);
        columnData.push(column.account_base);
        columnData.push(column.number_of_package);

        columnData.push(+column.shipping_fee_include_tax + +column.service_fee_include_gst);

        columnData.push(+column.shipping_fee_exclude_tax);
        columnData.push(+column.shipping_fee_include_tax);

        columnData.push(+column.service_fee_exclude_tax);
        columnData.push(+column.service_fee_include_gst);

        columnData.push(+column.total_brokerage_charge);
        columnData.push(+column.total_duties_charge);
        columnData.push(+column.total_duties_taxes_charge);

        columnData.push(+column.shipping_total_tax);

        columnData.push(+column.freight_charge);

        columnData.push(column.surcharge_details);

        columnData.push(column.created_at);

        columnData.push(column.tran_tracking_nos);

        // Send From Info
        // columnData.push(column.send_from_name);
        // columnData.push(column.send_from_addr);
        columnData.push(column.send_from_city);
        columnData.push(column.send_from_province);
        columnData.push(column.send_from_country);
        columnData.push(column.send_from_postal_code);

        // Send To Info
        // columnData.push(column.send_to_name);
        // columnData.push(column.send_to_tel);
        // columnData.push(column.send_to_email);
        // columnData.push(column.send_to_addr);
        columnData.push(column.send_to_city);
        columnData.push(column.send_to_province);
        columnData.push(column.send_to_country);
        columnData.push(column.send_to_postal_code);

        columnData.push(column.crmInvoiceRefNumber);
        columnData.push(column.vendor_invoice_no);

        columnData.push(column.vendor_invoice_shipping_fee_exclude_tax);
        columnData.push(column.vendor_invoice_shipping_fee_include_tax);

        columnData.push(column.adjustmentAmountExcludeTax);
        columnData.push(column.adjustmentAmountIncludeTax);


        resultArray.push(columnData);
      }
      setTransactions(resultArray);
      console.log(resultArray);

      if (crmInvoices) {
        setCSVFileName(`Trans-${dayjs().format("YYYY-MM-DD")}-${crmInvoices}.csv`);
      } else {
        setCSVFileName(`Trans-${dayjs().format("YYYY-MM-DD")}.csv`);
      }

      csvLink.current.link.click();
    } catch (e) {
      console.log(e);
    }
  };

  const getData = async ({
                           startOrderId,
                           endOrderId,
                           refOrderNumber,
                           refProductNumber,
                           currentPage,
                           tranStatus,
                           startTransactionDate,
                           endTransactionDate,
                           crmInvoices
                         }) => {
    setIsLoading(true);
    let requestURI;
    startTransactionDate = dayjs(startTransactionDate).format("YYYY-MM-DD");
    endTransactionDate = dayjs(endTransactionDate).format("YYYY-MM-DD");
    if (tranStatus === 2) {
      requestURI = `${PARTNER_URI}/shipping/list/admin/exportTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&page=${currentPage - 1}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      if (carrier === "ALL") {
        requestURI = `${PARTNER_URI}/shipping/list/admin/exportTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&page=${currentPage - 1}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      } else {
        requestURI = `${PARTNER_URI}/shipping/list/admin/exportTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&page=${currentPage - 1}&account_base=${carrier}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      }
    } else {
      requestURI = `${PARTNER_URI}/shipping/list/admin/exportTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&page=${currentPage - 1}&tranStatus=${tranStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      if (carrier === "ALL") {
        requestURI = `${PARTNER_URI}/shipping/list/admin/exportTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&page=${currentPage - 1}&tranStatus=${tranStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      } else {
        requestURI = `${PARTNER_URI}/shipping/list/admin/exportTransactionsByPartnerId?startOrderId=${startOrderId}&endOrderId=${endOrderId}&refOrderNumber=${refOrderNumber}&refProductNumber=${refProductNumber}&page=${currentPage - 1}&account_base=${carrier}&tranStatus=${tranStatus}&startTransactionDate=${startTransactionDate}&endTransactionDate=${endTransactionDate}`;
      }
    }
    const { data } = await axios.get(requestURI, {
      params: {
        crmInvoices
      }
    });
    const result = data?.result;
    const resultTotalNumber = result?.total || 0;
    setTotalNumber(resultTotalNumber);
    setShippingListData(result?.results || []);
    try {
    } catch (e) {
      setTotalNumber(0);
      console.log(e);
      setShippingListData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = data => {
    // console.log(data);
    const { startOrderId, endOrderId, refOrderNumber, refProductNumber, crmInvoices } = data;
    const startTransactionDate = dayjs(startDate)?.isBefore(dayjs(endDate)) ? startDate : endDate;
    const endTransactionDate = dayjs(endDate)?.isAfter(dayjs(startDate)) ? endDate : startDate;
    const cacheData = {
      ...data,
      transactionStatus,
      startTransactionDate,
      endTransactionDate
    };
    cacheInput.current = cacheData;
    setPage(1);
    getData({
      startOrderId,
      currentPage: 1,
      endOrderId,
      refOrderNumber,
      refProductNumber,
      startTransactionDate,
      endTransactionDate,
      tranStatus: transactionStatus, crmInvoices
    });
  };
  const handlePageChange = (e, value) => {
    if (value !== page) {
      console.log(value);
      setPage(value);
      const {
        startOrderId,
        endOrderId,
        refOrderNumber,
        refProductNumber,
        transactionStatus,
        startTransactionDate,
        endTransactionDate, crmInvoices
      } = cacheInput.current;
      getData({
        currentPage: value,
        startOrderId,
        endOrderId,
        refOrderNumber,
        refProductNumber,
        tranStatus: transactionStatus,
        endTransactionDate,
        startTransactionDate, crmInvoices
      });
    }
  };

  return (
    <>
      <Box>
        <Box component="form" width={"1000px"} margin="0 auto" sx={{
          display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem",
          alignItems: "center", alignContent: "center"
        }}>
          <h1>Exports Transactions</h1>
          <Box display="flex" flexWrap="wrap" sx={{ gap: "2rem" }}>
            <Box flex="45%" width="100%">
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(value) => {
                      setStartDate(value);
                    }}
                    renderInput={(params) => <TextField {...params} id="outlined-basic"
                                                        label="Start Date" variant="outlined"
                                                        fullWidth />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Box flex="45%" width="100%">
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(value) => {
                      setEndDate(value);
                    }}
                    renderInput={(params) => <TextField {...params} id="outlined-basic"
                                                        label="End Date"
                                                        variant="outlined" fullWidth />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Box>
            <Box flex="45%" width="100%">
              {/*<TextField id="outlined-basic" label="Transaction Status" variant="outlined" {...register("send_to_addr")} fullWidth />*/}
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-filled-label">Transaction Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={transactionStatus}
                  label="Age"
                  onChange={(e) => {
                    setTransactionStatus(e.target.value);
                  }}
                >
                  <MenuItem value={1}>VALID</MenuItem>
                  <MenuItem value={-1}>CANCELLED</MenuItem>
                  <MenuItem value={2}>ALL</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box flex="45%" width="100%">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-filled-label">Carrier</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={carrier}
                  label="Age"
                  onChange={(e) => {
                    setCarrier(e.target.value);
                  }}
                >
                  <MenuItem value={"ALL"}>ALL</MenuItem>
                  <MenuItem value={"UPS"}>UPS</MenuItem>
                  <MenuItem value={"CANPAR"}>CANPAR</MenuItem>
                  <MenuItem value={"FEDEX"}>FEDEX</MenuItem>
                  <MenuItem value={"CanadaPost"}>CANADA POST</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box width="100%">
              <TextField id="outlined-basic" label="Invoice Numbers"
                         helperText="Split with comma, such as FA1234-1111, FA1234-1112"
                         variant="outlined" {...register("crmInvoices")} fullWidth />
            </Box>
          </Box>
          <Box>
            <LoadingButton
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem"
              }}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              loading={isLoading}
            >
              Search
            </LoadingButton>
          </Box>
        </Box>
        <br />
        {
          isLoading ? (
            <>
              <Box>
                <h1>Loading...</h1>
              </Box>
            </>
          ) : (
            <>
              <Box>
                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center"
                     sx={{ gap: "2rem" }}>
                  <h2>Found {totalNumber} results</h2>
                  <Box display={totalNumber === 0 ? "none" : "flex"} justifyContent="center"
                       alignContent="center" flexBasis={0}>
                    <Button
                      onClick={getTransactionData}
                      variant="contained"
                      color="secondary"
                      style={{
                        textTransform: "unset",
                        fontSize: "1rem",
                        display: "flex",
                        padding: "0.5rem",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center"
                      }}>
                      Download
                    </Button>
                    <CSVLink
                      data={transactions}
                      filename={csvFileName}
                      className="hidden"
                      ref={csvLink}
                      target="_blank"
                    />
                    {/*<button onClick={getTransactionDataExcel}>Download</button>*/}
                  </Box>
                </Box>
                <hr width="100%" />
                <Box>
                  <Box mt={2} display="flex" justifyContent="center">
                    <table className={classes.table}>
                      <thead>
                      <tr className={classes.listItemWrapper}>
                        <th className={classes.idTH}>Transaction ID</th>
                        <th className={classes.idTH}>Order ID</th>
                        <th className={classes.idTH}>Invoice #</th>
                        <th className={classes.nameTH}>Ref #</th>
                        <th className={classes.nameTH}>Send To</th>
                        <th className={classes.nameTH}>Price</th>
                        <th className={classes.nameTH}>Carrier / Tracking #</th>
                        <th className={classes.nameTH}>Date</th>
                        <th className={classes.nameTH}>Status</th>
                      </tr>
                      </thead>
                      <tbody className={classes.tbody}>
                      {shippingListData.map((v, index) => {
                        return <ExportTransactionTable key={index}
                                                       account_base={v.account_base}
                                                       date={v.tranacted_at}
                                                       gross_price={v?.amount_gross}
                                                       net_price={v?.amount}
                                                       shipping_fee_exclude_tax={v?.shipping_fee_exclude_tax}
                                                       service_fee_exclude_tax={v?.service_fee_exclude_tax}
                                                       shipping_fee_include_tax={v?.shipping_fee_include_tax}
                                                       service_fee_include_gst={v?.service_fee_include_gst}
                                                       order_id={v?.order_id}
                                                       ref_product_no={v.ref_product_no}
                                                       ref_other_no={v?.ref_other_no}
                                                       ref_order_no={v?.ref_order_no}
                                                       tran_id={v.tran_id}
                                                       label_pdf_uri={v?.label_pdf_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_pdf_uri}` : ""}
                                                       zip_label_uri={v?.label_zip_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_zip_uri}` : ""}
                                                       send_to_addr={v.send_to_addr}
                                                       send_to_city={v.send_to_city}
                                                       send_to_province={v.send_to_province}
                                                       send_to_country={v.send_to_country}
                                                       send_to_postal_code={v.send_to_postal_code}
                                                       send_to_name={v.send_to_name}
                                                       send_to_tel={v.send_to_tel}
                                                       send_to_email={v.send_to_email}
                                                       currency_code={v?.currency_code || "CAD"}
                                                       transactionNumbers={v.tran_tracking_nos}
                                                       tran_status={v?.tran_status}
                                                       invoiceNumber={v?.invoice?.invoiceRefNumber || "N/A"}
                        />;
                      })}
                      </tbody>
                    </table>
                  </Box>
                </Box>
                <br />
                <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                  <Pagination count={Math.floor(totalNumber / pageSize) + 1}
                              page={page}
                              onChange={handlePageChange}
                              className={classes.pagination}
                              variant="outlined"
                              shape="rounded" />
                </Box>
              </Box>
            </>
          )
        }
      </Box>
    </>
  );
};

export default ShippingExportsTransaction;
