import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { Link } from "react-router-dom";

const BackArrow = ({ link }) => {
  return (
    <Box sx={{position: "absolute", top: "20px", left: "20px", textAlign:"start"}}>
      <Link to={"/v2" + link} >
          <ArrowBackIcon
            sx={{
              color:"black",
              fontSize: 40
            }} />
      </Link>
    </Box>
  )
}

export default BackArrow