import {Button, Container, Grid, Snackbar, Typography} from "@mui/material";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useHistory} from "react-router-dom";
import {useState} from "react";
import Box from "@mui/material/Box";
import {LTLOrderListSelectPartner} from "../LTLOrderList/LTLOrderListSelectPartner";
import {LTLOrderListSelectCarrier} from "../LTLOrderList/LTLOrderListSelectCarrier";
import {LTLQuoteSelectService} from "./LTLQuoteSelectService";
import LoadingButton from "@mui/lab/LoadingButton";
import {LTLQuoteNumber} from "./LTLQuoteNumber";
import {LTLQuoteExpiredDate} from "./LTLQuoteExpiredDate";
import {LTLQuoteShipper} from "./LTLQuoteShipper";
import {LTLQuoteConsignee} from "./LTLQuoteConsignee";
import {LTLQuotePallet} from "./LTLQuotePallet";
import {LTLQuoteCost} from "./LTLQuoteCost";
import {
    getMissingKeyPickupDateAndTime,
    getMissingKeysConsigneeInfo, getMissingKeysShipFromInfo,
    getMissingKeysShipperInfo, getMissingKeysShipToInfo
} from "../../utils/Helper";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../utils/constInfo";
import axios from "axios";
import {LTLQuoteRemarkNotes} from "./LTLQuoteRemarkNotes";
import {LTLQuoteTransitDays} from "./LTLQuoteTransitDays";
import {LTLQuoteTransitMode} from "./LTLQuoteTransitMode";
import {LTLQuoteTransitCargoType} from "./LTLQuoteTransitCargoType";
import {LTLQuoteCarrierName} from "./LTLQuoteCarrierName";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight", "quantity"];

export const LTLQuoteAdd = ({handleChangeType}) => {

    const history = useHistory();

    const [transitCargoType, setTransitCargoType] = useState();
    const [transitMode, setTransitMode] = useState();
    const [transitDays, setTransitDays] = useState();
    const [FTL, setFTL] = useState();
    const [shipperInfo, setShipperInfo] = useState();
    const [consigneeInfo, setConsigneeInfo] = useState();
    const [palletList, setPalletList] = useState();
    const [selectService, setSelectService] = useState();
    const [carrierName, setCarrierName] = useState();
    const [quoteNumber, setQuoteNumber] = useState();
    const [expiredDate, setExpiredDate] = useState();
    const [shippingCost, setShippingCost] = useState();
    const [remarkNotes, setRemarkNotes] = useState();
    const [loading, setLoading] = useState(false);

    const [isConfirm, setIsConfirm] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [validation, setValidation] = useState(null);
    const [triggerAction, setTriggerAction] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleEdit = () => {
        setIsConfirm(false);
    }

    const handleButtonClick = () => {
        // Set the state to trigger the action in the child component
        setTriggerAction(true);
    };

    const createQuote = async () => {
        setLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/shippingLTLRatingHistory/addRatingHistory`;

        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 90);
        const defaultExpiredDate = currentDate.toISOString().split('T')[0];

        let data = {
            packagesData: palletList,
            shipFromCity: shipperInfo?.shipperCity,
            shipFromProvince: shipperInfo?.shipperProvince,
            shipFromCountry: shipperInfo?.shipperCountry,
            shipToCity: consigneeInfo?.consigneeCity,
            shipToProvince: consigneeInfo?.consigneeProvince,
            shipToCountry: consigneeInfo?.consigneeCountry,
            accountBase: selectService,
            carrierName: carrierName,
            quoteNumber: quoteNumber,
            fuelCharge: shippingCost?.fuelCharge || 0,
            tariffCharge: shippingCost?.tariffCharge || 0,
            currencyCode: shippingCost?.currencyCode,
            taxes: shippingCost?.taxCharge || 0,
            crossBorderFee: shippingCost?.crossBorderCharge || 0,
            expiredTime: expiredDate || defaultExpiredDate,
            remarkNotes: remarkNotes,
            ftlCount: FTL || 0,
            transitDays: transitDays ? `${transitDays} day` : '',
            transitMode: transitMode || '',
            transitCargoType: transitCargoType ? 'Bonded' : 'N/A'
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('[LTLQuoteAdd] createQuote api - result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Create Quote');
            handleSuccessMessage();
            setTimeout(() => {
                // history.push("/v2/ltl-quote-list?type=list");
                handleChangeType('quoteList')
            }, 2000);

        } catch (e) {
            console.log('[LTLQuoteAdd] createQuote api - error', e?.response);
            setErrorMessage('Fail Create Quote');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }

    }

    const handleDataFromChildComponent = (data) => {
        const key = Object.keys(data);
        switch (key[0]) {
            case 'shipperInfo':
                setShipperInfo(data?.shipperInfo);
                break
            case 'consigneeInfo':
                setConsigneeInfo(data?.consigneeInfo);
                break
            case 'palletList':
                setPalletList(data?.palletList);
                break
            case 'selectService':
                setSelectService(data?.selectService);
                break
            case 'carrierName':
                setCarrierName(data?.carrierName);
                break
            case 'quoteNumber':
                setQuoteNumber(data?.quoteNumber);
                break
            case 'expiredDate':
                setExpiredDate(data?.expiredDate);
                break
            case 'shippingCost':
                setShippingCost(data?.shippingCost);
                break
            case 'remarkNotes':
                setRemarkNotes(data?.remarkNotes);
                break
            case 'FTL':
                setFTL(data?.FTL);
                break
            case 'transitDays':
                setTransitDays(data?.transitDays);
                break
            case 'transitMode':
                setTransitMode(data?.transitMode);
                break
            case 'transitCargoType':
                setTransitCargoType(data?.transitCargoType);
                break
            default:
        }
    }

    const getDataFromChildComponent = (data) => {
        console.log('child data', data);
        handleDataFromChildComponent(data);
        setTriggerAction(false);
        setIsConfirm(true);
    }

    const updateService = (carrier) => {
        setSelectService(carrier);
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysShipFromInfo(shipperInfo);
        const shipToMissing = getMissingKeysShipToInfo(consigneeInfo);
        const hasMissingValues = palletList?.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = palletList?.some(object => validateRequiredProperties.some((property) => object[property] <= 0));

        const validation = {
            shipFromMissing,
            shipToMissing,
            hasMissingValues,
            hasValidateValues,
            carrierMissing: selectService === 'default' || !selectService,
            palletListMissing: palletList?.length === 0,
            tariffChargeError: shippingCost?.tariffCharge <= 0
        }

        console.log('validation', validation);
        setValidation(validation);

        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Ship From information is missing one or more required fields.');
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Ship To information is missing one or more required fields.');
        (selectService === 'default' || !selectService) && setErrorMessage(prevState => prevState + 'Carrier is missing.');
        hasMissingValues && setErrorMessage(prevState => prevState + 'You pallet list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + 'You pallet list has one or more invalid fields.');
        palletList?.length === 0 && setErrorMessage(prevState => prevState + 'Pallet list is empty.');
        shippingCost?.tariffCharge <= 0 && setErrorMessage(prevState => prevState + 'Tariff Charge is smaller or equal to 0.')

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            selectService !== 'default' &&
            selectService &&
            palletList.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            shippingCost?.tariffCharge > 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreateOrder = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            createQuote();
        }
    }

    console.log('[LTLQuoteAdd] selectService', selectService);
    console.log('[LTLQuoteAdd] carrierName', carrierName);
    console.log('[LTLQuoteAdd] quoteNumber', quoteNumber);
    console.log('[LTLQuoteAdd] expiredDate', expiredDate);
    console.log('[LTLQuoteAdd] shipperInfo', shipperInfo);
    console.log('[LTLQuoteAdd] consigneeInfo', consigneeInfo);
    console.log('[LTLQuoteAdd] palletList', palletList);
    console.log('[LTLQuoteAdd] shippingCost', shippingCost);
    console.log('[LTLQuoteAdd] remarkNotes', remarkNotes);
    console.log('[LTLQuoteAdd] transitDays', transitDays);
    console.log('[LTLQuoteAdd] transitMode', transitMode);
    console.log('[LTLQuoteAdd] transitCargoType', transitCargoType);

    return (
        <Box sx={styles.ShippingOrderRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                Create LTL Quote
            </Typography>
            <Box sx={styles.ShippingOrderContent}>
                <Box sx={styles.ShippingOrderList}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <LTLQuoteSelectService
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                updateService={updateService}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LTLQuoteCarrierName
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LTLQuoteNumber
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <LTLQuoteExpiredDate
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={styles.ShippingOrderList}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LTLQuoteShipper
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={styles.ShippingOrderList}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LTLQuoteConsignee
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={styles.ShippingOrderList}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <LTLQuoteTransitDays
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <LTLQuoteTransitMode
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                selectService={selectService}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <LTLQuoteTransitCargoType
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={styles.ShippingOrderList}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LTLQuotePallet
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={styles.ShippingOrderList}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <LTLQuoteCost
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={styles.ShippingOrderList}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LTLQuoteRemarkNotes
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'flex-end'
                }}>
                    {/*<Button*/}
                    {/*    variant='contained'*/}
                    {/*    onClick={() => history.push('/v2/ltl-quote-list?type=list')}*/}
                    {/*>*/}
                    {/*    <Typography style={{*/}
                    {/*        textTransform: 'none'*/}
                    {/*    }}>*/}
                    {/*        Back To Quote List*/}
                    {/*    </Typography>*/}
                    {/*</Button>*/}
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'center'
                    }}>
                        <Button
                            variant='contained'
                            sx={{
                                backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                "&:hover": {
                                    backgroundColor: isConfirm ? '#F2BE22' : '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={isConfirm ? handleEdit : handleButtonClick}
                        >
                            <Typography style={{
                                textTransform: 'none'
                            }}>
                                {isConfirm ? 'Edit' : 'Confirm'}
                            </Typography>
                        </Button>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                }
                            }}
                            onClick={handleCreateOrder}
                            disabled={!isConfirm}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none'
                            }}>
                                Create
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}



