import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function ShippingListTransactionTable({
                                                       tran_id,
                                                       product_id,
                                                       label_uri,
                                                       tracking_no,
                                                       tn_status,
                                                       onCancelOrder,
                                                       order_id,
                                                       ref_order_number,
                                                       cargo_control_no,
                                                       account_base
}) {
  const classes = useStyles();

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link
              to={`#${order_id}`}>
            {order_id}
          </Link>
        </td>
        <td>{ref_order_number}</td>
        <td>{cargo_control_no}</td>
        <td>
          <Link
            to={`/v2/shippingtransaction/${tran_id}`}>
            {tran_id}
          </Link>
        </td>
        <td>{product_id}</td>
        <td>{tracking_no}</td>
        <td>
          <a href={label_uri} target="_blank" rel="noopener noreferrer">{label_uri ? "Download" : ""}</a>
        </td>
        <td>
          {
            tn_status === 0 ? (
                <button onClick={() => {onCancelOrder(tracking_no)}}>Cancel</button>
                ) : "Cancelled"
          }
        </td>
      </tr>
    </>
  );
}
