import {actionType} from "../../utils/redux"


const savePackageListSearchParams = data => ({
    type: actionType.SAVE_PACKAGE_LIST_SEARCH_PARAMS,
    payload: data
})

export default {
    savePackageListSearchParams
}