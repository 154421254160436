import React, { useState} from "react";
import { Box, Button, Collapse, makeStyles } from "@material-ui/core";
import axios from "axios";
import EditIcon from '@material-ui/icons/Edit';
import CurrencyFormat from "react-currency-format";
import EditQuote from "./editQuote";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function QuoteTable({ quoteId,
                                     businessName,
                                     standardAmount,
                                     serviceFee,
                                     additionalFee,
                                     bonus,
                                     tax,
                                     finalAmount,
                                     totalAmount,
                                     discount,
                                     currencyCode,
                                     quotesURI,
                                     expiredDate,
                                     remarkNote,
                                     statusName,
                                     status,
                                     requestId,
                                     refreshList,
                                     items,
                                     companyList,
                                     deliverAppointment,
                                     deliverLoadingDock,
                                     insurance,
                                     pickupLoadingDock
}) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);


  return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="start" width="100%" sx={{ gap: "2rem" }}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" fontSize="15px" width="100%">
          <Box flex={1}>{quoteId}</Box>
          <Box flex={1}>{businessName}</Box>
          <Box flex={1}>
            <CurrencyFormat value={(standardAmount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </Box>
          <Box flex={1}>
            <CurrencyFormat value={(serviceFee || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </Box>
          <Box flex={1}>
            <CurrencyFormat value={(additionalFee || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </Box>
          <Box flex={1}>
            <CurrencyFormat value={(bonus || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </Box>
          <Box flex={1}>
            <CurrencyFormat value={(tax || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </Box>
          <Box flex={1}>
            <CurrencyFormat value={(discount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </Box>
          <Box flex={1}>
            Total: <CurrencyFormat value={(totalAmount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
            <br />
            Final: <CurrencyFormat value={(finalAmount || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
          </Box>
          <Box flex={1}>{expiredDate}</Box>
          <Box flex={1}>{statusName}</Box>
          <Box flex={1}>
            <a href={quotesURI} target="_blank" rel="noopener noreferrer">
              {quotesURI ? "Download" : ""}
            </a>
          </Box>
        </Box>
        <Box fontSize="18px" fontWeight="700" textAlign="left" display="flex" width="100%" alignItems="center" sx={{ gap: "2rem" }}>
          <Box display="flex" fontSize="15px" fontWeight="400" flexDirection="column" sx={{ gap: "0.5rem" }}>
            <span>
              Pickup Loading Dock: <CurrencyFormat value={(pickupLoadingDock || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
            </span>
            <span>
              Deliver Loading Dock: <CurrencyFormat value={(deliverLoadingDock || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
            </span>
            <span>
              Deliver Appointment: <CurrencyFormat value={(deliverAppointment || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
            </span>
            <span>
              Insurance: <CurrencyFormat value={(insurance || 0)} displayType={'text'} thousandSeparator={true} prefix={`${currencyCode || "CAD"} $`} />
            </span>
          </Box>
          <Button onClick={() => {
            setEdit(v => !v);
          }} variant="contained" endIcon={<EditIcon />} disabled={status === 1} >
            {
              !edit ? "Edit" : "Cancel"
            }
              </Button>
          <Box>
            Notes:
          </Box>
          <Box sx={{ whiteSpace: "pre-wrap" }}>
            {remarkNote}
          </Box>
        </Box>
        {
          edit && (
            <Collapse in={edit}>
              <Box sx={{
                borderRadius: "20px",
                boxShadow: "0 10px 20px rgba(255, 255, 255, 0.1)",
                border: "1px solid rgba(0,0,0,0.3)",
              }}>
                <EditQuote requestId={requestId}
                           refreshList={refreshList}
                           companyList={companyList}
                           setEdit={setEdit}
                           quoteId={quoteId}
                           originalCompanyName={businessName}
                           originalFinalAmount={finalAmount}
                           originalStandardAmount={standardAmount}
                           originalServiceFee={serviceFee}
                           originalAdditionalFee={additionalFee}
                           originalCurrencyCode={currencyCode}
                           originalExpiredDate={expiredDate}
                           originalRemarkNotes={remarkNote}
                           originalTax={tax}
                           originalDiscount={discount}
                           originalTotalAmount={totalAmount}
                           originBonus={bonus}
                           originDeliverAppointment={deliverAppointment}
                           originDeliverLoadingDock={deliverLoadingDock}
                           originInsurance={insurance}
                           originPickupLoadingDock={pickupLoadingDock}
                           items={items}
                />
              </Box>
            </Collapse>
          )
        }
      </Box>
  );
}
