import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function TransactionAdjustmentTable({
  tran_id,
  order_id,
  partner_id,
  ref_order_no,
  cargo_control_no,
  ref_product_no,
  ref_other_no,
  account_base,
  gross_price,
  net_price,
  zip_label_uri,
  label_pdf_uri,
  date,
  send_to_name,
  send_to_email,
  send_to_tel,
  send_to_addr,
  send_to_city,
  send_to_postal_code,
  send_to_province,
  send_to_country,
  currency_code
}) {
  const classes = useStyles();

  const getLabelURL = (pdf, zip) => {
    if (pdf) {
      return pdf;
    }
    if (zip) {
      return zip;
    }
    return "";
  }

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link
            to={`/loose-item/3rd-party/shipping/list/product/${tran_id}`}>
            {tran_id}
          </Link>
        </td>
        <td>{partner_id}</td>
        <td>{order_id}</td>
        <td>{ref_order_no}</td>
        <td>{ref_product_no}</td>
        <td>{ref_other_no}</td>
        <td>{`${send_to_name ? send_to_name : "N/A"} / ${send_to_email ? send_to_email : "N/A"} / ${send_to_tel ? send_to_tel : "N/A"}`}</td>
        <td>{`${send_to_addr ? send_to_addr : "N/A"} / ${send_to_city ? send_to_city : "N/A"} / ${send_to_province ? send_to_province : "N/A"} / ${send_to_country ? send_to_country : "N/A"} / ${send_to_postal_code ? send_to_postal_code : "N/A"}`}</td>
        <td>{account_base}</td>
        <td>
          <CurrencyFormat value={gross_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />
        </td>
        <td>
          <CurrencyFormat value={net_price} displayType={'text'} thousandSeparator={true} prefix={`${currency_code} $`} />
        </td>
        <td>
          <a href={getLabelURL(label_pdf_uri, zip_label_uri)} target="_blank" rel="noopener noreferrer">
            {getLabelURL(label_pdf_uri, zip_label_uri) ? "Download" : ""}
          </a>
        </td>
        {/*<td>*/}
        {/*  <a href={zip_label_uri} target="_blank" rel="noopener noreferrer">*/}
        {/*    {zip_label_uri ? "Download" : ""}*/}
        {/*  </a>*/}
        {/*</td>*/}
        {/*<td>*/}
        {/*  <a href={label_pdf_uri} target="_blank" rel="noopener noreferrer">*/}
        {/*    {label_pdf_uri ? "Download" : ""}*/}
        {/*  </a>*/}
        {/*</td>*/}
        <td>{date}</td>
      </tr>
    </>
  );
}
