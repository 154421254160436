import {Fragment, useEffect, useState} from "react";
import {FormLabel, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldNumber} from "../../utils/customComponent";

export const LTLOrderListInsurance = ({
                                          getDataFromChildComponent,
                                          triggerAction,
                                          isConfirm,
                                      }) => {

    const [description, setDescription] = useState('');
    const [insuranceCost, setInsuranceCost] = useState(0);

    const onChange = (field, value) => {
        switch (field) {
            case 'description':
                setDescription(value);
                break;
            case 'insuranceCost':
                setInsuranceCost(+value);
                break;
            default:
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                insurance: {
                    description,
                    insuranceCost
                }
            });
        }
    }, [triggerAction]);

    console.log('[LTLOrderListInsurance] description', description);
    console.log('[LTLOrderListInsurance] insurance cost', insuranceCost);

    return (
        <Fragment>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Description
                        </FormLabel>
                        <CustomTextFieldNormal
                            defaultValue={description}
                            field='description'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%'
                }}>
                    <FormControl fullWidth>
                        <FormLabel sx={{textAlign: 'left'}}>
                            Cost
                        </FormLabel>
                        <CustomTextFieldNumber
                            field='insuranceCost'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </FormControl>
                </Box>
            </Grid>
        </Fragment>
    )
}