import React, {useRef, useState} from 'react';
import { Box, Button, TextField, useMediaQuery, FormControl, FormLabel, RadioGroup,FormControlLabel, Radio } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import ShippingListTrackingTable from "./ShippingListTrackingTable";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import axios from "axios";
import { NODE_API_TEST, LABEL_DOWNLOAD_URL_PREFIX } from "../../utils/apiUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const defaultPartnerInfo = {
  "state":"authorized",
  "login_user_type":"partner",
  "partner":"Alice",
  "partner_id":245,
  "partner_email":"info@jlinkcargo.ca",
  "major":"9sNMaHtixmSdWdfS1komoMyzgUCHGLlfCHeHl8xd",
  "minor":"Bs0bAnQGF6KcrN5HLlP7F16sZmj1gIS8fKlE8TDp"
}

const getPartnerInfo = () => {
  return defaultPartnerInfo;
}

const ShippingListTracking = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");
  const pageSize = 100;

  const partnerInfo = getPartnerInfo();

  const { register, handleSubmit, control } = useForm();
  const [shippingListData, setShippingListData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const cacheInput = useRef({});

  const getData = async ({ order_no, product_id, ref_other_no, account_base, currentPage }) => {
      const { data } = await axios.get(`${NODE_API_TEST}/shipping/list?account_base=${account_base}&order_no=${order_no}&product_id=${product_id}&ref_other_no=${ref_other_no}&partner_id=${partnerInfo.partner_id}&page=${currentPage - 1}`);
      const result = data.result;
      const resultTotalNumber = data.totalNumber;
      setTotalNumber(resultTotalNumber);
      setShippingListData(result);
      try {
      } catch (e) {
        setTotalNumber(0);
        console.log(e);
        setShippingListData([]);
      } finally {
        setIsLoading(false);
      }
  }

  const onSubmit = data => {
    // console.log(data);
    const { order_no, product_id , ref_other_no, account_base } = data;
    cacheInput.current = data;
    setPage(1);
    getData({ order_no, product_id, ref_other_no, account_base, currentPage: 1 });
  }
  const handlePageChange = (e, value) => {
    if (value !== page) {
      console.log(value);
      setPage(value);
      const { order_no, product_id , ref_other_no, account_base } = cacheInput.current;
      getData({ order_no, product_id, ref_other_no, account_base, currentPage: value });
    }
  }

  return (
      <>
        <Box>
          <Box component="form" width={matches ? "100%" : "600px"} margin="0 auto" sx={{
            display: "flex", flexDirection: "column", justifyContent: "space-evenly", gap: "2rem"
          }}>
            <h1>Tracking Orders</h1>
            <TextField id="outlined-basic" label="Order Number" variant="outlined" {...register("order_no")} />
            <TextField id="outlined-basic" label="Product ID" variant="outlined" {...register("product_id")} />
            <TextField id="outlined-basic" label="Reference Other Number" variant="outlined" {...register("ref_other_no")} />

            <Box mt={2} mb={4}>
              <FormControl>
                <FormLabel required color="primary">Account Base</FormLabel>
                <Controller control={control} defaultValue="FedEx" name="account_base" rules={{ required: true }} render={({ field }) => (
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        defaultValue="FedEx"
                        {...field}
                    >
                      <FormControlLabel value="FedEx" control={<Radio />} label="FedEx" />
                      <FormControlLabel value="UPS" control={<Radio />} label="UPS" />
                    </RadioGroup>
                )}/>

              </FormControl>
            </Box>
            <Box>
              <Button
                  variant="contained"
                  component="span"
                  color="primary"
                  style={{
                    textTransform: "unset",
                    fontSize: "1rem",
                    width: "7rem",
                  }}
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Box>
          </Box>

          <br />
          {
            isLoading ? (
                <>
                <Box>
                  <h1>Loading...</h1>
                </Box>
                </>
            ) : (
                <>
                  <Box>
                    <h2>Found {totalNumber} results</h2>
                    <hr width={matches ? "100%" : "rem"} />
                    <Box maxWidth="70%" margin="0 auto">
                      <Box mt={2}>
                        <table className={classes.table}>
                          <thead>
                          <tr>
                            <th className={classes.idTH}>Transaction ID</th>
                            <th className={classes.nameTH}>Order Number</th>
                            <th className={classes.nameTH}>Product ID</th>
                            <th className={classes.nameTH}>Reference Other Number</th>
                            <th className={classes.nameTH}>Account Base</th>
                            <th className={classes.nameTH}>Gross Price</th>
                            <th className={classes.nameTH}>Net Price</th>
                            <th className={classes.nameTH}>ZIP Label URI</th>
                            <th className={classes.nameTH}>Date</th>
                          </tr>
                          </thead>
                          <tbody className={classes.tbody}>
                          {shippingListData.map((v, index) => {
                            return <ShippingListTrackingTable key={index}
                                                              account_base={v.account_base}
                                                              date={v.tranacted_at}
                                                              gross_price={v?.amount_gross}
                                                              net_price={v?.amount}
                                                              order_no={v?.ref_order_no}
                                                              product_id={v?.ref_product_no}
                                                              ref_other_no={v?.ref_other_no}
                                                              tran_id={v.tran_id}
                                                              zip_label_uri={v?.label_zip_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${v.label_zip_uri}` : ""}
                            />;
                          })}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                    <br />
                    <Box margin="auto" sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                      <Pagination count={Math.floor(totalNumber / pageSize) + 1}
                                  page={page}
                                  onChange={handlePageChange}
                                  className={classes.pagination}
                                  variant="outlined"
                                  shape="rounded" />
                    </Box>
                  </Box>
                </>
            )
          }
        </Box>
      </>
  );
};

export default ShippingListTracking;
