import {Button, Container, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import {useEffect, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useHistory} from "react-router-dom";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

const FLOOR_LEVEL_OPTIONS = [
    '1',
    '2',
    '3',
];

export const UploadAreaPricePolicyFile = () => {

    const inputFile = useRef(null);
    const history = useHistory();
    const classes = useStyles();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [validation, setValidation] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileObj, setFileObj] = useState(null);
    const [contactFileObj, setContactFileObj] = useState(null);
    const [uploadFileLoading, setUploadFileLoading] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);
    const [theInputKey, setTheInputKey] = useState(null);
    const [areaList, setAreaList] = useState([]);
    const [areaId, setAreaId] = useState(null);
    const [areaGroupId, setAreaGroupId] = useState(null);
    const [floorLevel, setFloorLevel] = useState("1");
    const [isSteepDriveway, setIsSteepDriveway] = useState(false);
    const [lift, setLift] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleMovingToFloorLevel = (event, value) => {
        setFloorLevel(value);
    }

    const handleIsSteepDriveway = (event, value) => {
        setIsSteepDriveway(value)
    }

    const handleLift = (event) => {
        setLift(event?.target?.value);
    }

    const searchAreaAndAreaGroup = async (input) => {
        let requestURL = `${NODE_ROUTE_URI}/backendAreaAndAreaGroup/searchBackendAreaAndAreaGroup`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: input,
                    areaType: 2,
                    page: 0,
                    pageSize: 20
                }
            })

            const {data: {data}} = result
            console.log('[searchAreaAndAreaGroup] data', data);
            return data;

        } catch (e) {
            console.log(e.response)
        }

    }

    const handleInputArea = async (event, value) => {

        const areaData = await searchAreaAndAreaGroup(value);
        // console.log('[handleInputShipFromCity] predictions', predictions);
        setAreaList(areaData);
    }

    const handleChangeArea = async (event, value) => {
        console.log('[handleChangeArea] value', value);
        setAreaId(value?.area_id);
        if (+value?.belongto_group_id !== 0) {
            setAreaGroupId(value?.belongto_group_id);
        } else {
            setAreaGroupId(value?.group_id)
        }
    }

    const onDrop = (_type) => (e) => {
        if (!(e.target.files && e.target.files[0])) return;
        if (_type === "bol") {
            setFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
        } else {
            setContactFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
            setFileName(e.target.files[0].name);
        }
    };

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        (!fileObj && !contactFileObj) && setErrorMessage(prevState => prevState + 'Box Rate file is missing.');
        !floorLevel && setErrorMessage(prevState => prevState + 'Floor level is missing.');
        !areaGroupId && setErrorMessage(prevState => prevState + 'Area group is missing.')
        const validation = {
            areaPricePolicyFileMissing: !fileObj && !contactFileObj,
            floorLevelMissing: !floorLevel,
            areaGroupIdMissing: !areaGroupId
        }

        setValidation(validation);

        if ((fileObj || contactFileObj) &&
            floorLevel &&
            areaGroupId
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const uploadAreaPricePolicyFile = async () => {
        setUploadFileLoading(true);
        const bodyFormData = new FormData();
        const tmpFileObj = fileObj || contactFileObj;
        const requestURL = `${NODE_ROUTE_URI}/movingCategory/uploadMovingCategoryFile`;

        try {
            bodyFormData.append("file", tmpFileObj.selectedFile);
            bodyFormData.append("areaGroupId", areaGroupId);
            bodyFormData.append("lift", lift);
            bodyFormData.append("floorLevel", floorLevel);
            bodyFormData.append("drivewaySteepLevel", isSteepDriveway ? 1 : 0);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: bodyFormData
            })
            setErrorMessage('');
            setSuccessMessage('Successfully upload the area price policy file.');
            handleSuccessMessage();
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to upload the area price policy file.');
            handleErrorMessage();
        } finally {
            setUploadFileLoading(false);
        }
    }

    const handleConfirmUploadAreaPricePolicyFile = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            uploadAreaPricePolicyFile();
        }
    }

    useEffect(() => {
        // Set a timeout to show a message if loading takes more than 5 seconds
        const maxLoadingTime = 5000; // 5 seconds in milliseconds
        setTimeout(() => {
            uploadFileLoading ? setMessageLoading(true) : setMessageLoading(false);
        }, maxLoadingTime);
    }, [uploadFileLoading])

    console.log('[UploadAreaPricePolicyFile] area / area group', areaId, areaGroupId);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Upload Area Price Policy File
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Area Group
                                </FormLabel>
                                <Autocomplete
                                    options={areaList}
                                    getOptionLabel={(option) => (option?.area_name
                                        ? `${option.area_name}, ${option.province_shortname}`
                                        : (option?.belongto_group_name
                                                ? `${option.belongto_group_name}, ${option.province_shortname}`
                                                : (option?.group_name
                                                        ? `${option.group_name}, ${option.province_shortname}`
                                                        : `${option.province_shortname}`
                                                )
                                        ))}
                                    filterOptions={(options, state) => options}
                                    PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                    fullWidth
                                    renderInput={(params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        error={validation?.areaGroupIdMissing}
                                        helperText={validation?.areaGroupIdMissing && 'Required field'}
                                    />)}
                                    onInputChange={handleInputArea}
                                    onChange={handleChangeArea}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '14px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option?.area_name || option?.belongto_group_name || option?.group_name}, {option?.province_shortname}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Floor Level
                                </FormLabel>
                                <Autocomplete
                                    value={floorLevel}
                                    fullWidth
                                    options={FLOOR_LEVEL_OPTIONS}
                                    onChange={handleMovingToFloorLevel}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                        error={validation?.floorLevelMissing}
                                        helperText={validation?.floorLevelMissing && 'Required field'}
                                    />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Has Lift
                                </FormLabel>
                                <Select
                                    value={lift}
                                    size='small'
                                    // placeholder='Select Partner'
                                    onChange={handleLift}
                                    style={{textAlign: 'left'}}>
                                    <MenuItem value={0}>
                                        No
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        Yes
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isSteepDriveway}
                                            onChange={handleIsSteepDriveway}
                                            name="steep driveway"
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <>
                                            Steep Driveway:&nbsp;
                                            <span style={{fontSize: "12px"}}>Trucks & Movers may struggle with slope.
                                        </span>
                                        </>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Area Price Policy File
                                </FormLabel>
                                <TextField
                                    value={fileName}
                                    fullWidth
                                    disabled={true}
                                    size='small'
                                    error={validation && validation?.areaPricePolicyFileMissing}
                                    helperText={(validation && validation?.areaPricePolicyFileMissing) && 'Required field'}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                                height: '100%'
                            }}>
                                <input
                                    accept=".xlsx, .xls"
                                    style={{display: "none"}}
                                    ref={inputFile}
                                    multiple
                                    type="file"
                                    // onChange={()={}}
                                    onChange={onDrop("contacts")}
                                    key={theInputKey || ""}
                                />
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#454545',
                                        "&:hover": {
                                            backgroundColor: '#454545',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => {
                                        inputFile.current.click();
                                    }}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Upload
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleConfirmUploadAreaPricePolicyFile}
                                    loading={uploadFileLoading}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Confirm
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        {
                            messageLoading &&
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CircularProgress/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '16px'}}>
                                        Your file is currently being processed. You can wait here to receive the results
                                        or
                                        click the following button to go to the home page.
                                    </Typography>
                                    <Grid item xs={12}>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={() => history.push('/v2')}
                                        >
                                            <Typography style={{textTransform: 'none'}}>
                                                Home Page
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}