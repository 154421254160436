import Grid from "@material-ui/core/Grid";
import {Container, Divider, FormLabel, Link, Snackbar, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import axios from "axios";
import {useEffect, useRef, useState, Fragment} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {CSVLink} from "react-csv";
import LoadingButton from "@mui/lab/LoadingButton";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {PARTNER_URI} from "../../utils/apiUrl";
import {AdjustPackageOrderDetailsEach} from "./AdjustPackageOrderDetailsEach";
import {AdjustPackageOrderDetailsCanpar} from "./AdjustPackageOrderDetailsCanpar";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AdjustPackageOrderDetails = () => {

    const isMobile = useMediaQuery('(max-width: 480px)');
    const {orderId} = useParams();
    const csvLink = useRef();

    const [loading, setLoading] = useState(false);
    const [tracking, setTracking] = useState([]);
    const [adjustAmount, setAdjustMount] = useState(0);
    const [carrier, setCarrier] = useState('');
    const [invoiceData, setInvoiceData] = useState([]);
    const [invoiceLoading, setInvoiceLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [trackingLength, setTrackingLength] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getAdjustOrderDetails = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/invoices/admin/getPartnerShippingAdjustOrderDetailsByShippingOrderId`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    orderId: orderId,
                }
            })
            console.log('result', result);
            setAdjustMount(result?.data?.total_amount_include_tax);
            setCarrier(result?.data?.account_base);
            setTracking(result?.data?.trackings);
            setLoading(false);

        } catch (e) {
            console.log('error', e.response);
            setLoading(false);
        }
    }

    const getInvoiceData = async (tackingNumber, invoiceNumber) => {
        let requestURL = `${PARTNER_URI}/invoices/admin/exportInvoiceDataForTracking`;
        let data = {
            trackingNumber: tackingNumber,
            invoiceNumber: invoiceNumber
        }
        console.log('data', data);
        const invoiceResult = [];
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            console.log('result get invoice data', result);
            if (result?.data?.headData?.length === 0) {
                // setErrorMessage('There is no invoice data available for download.');
                // handleErrorMessage();
            } else {
                // setErrorMessage('');
                // setSuccessMessage('Invoice data downloaded successfully.');
                // handleSuccessMessage();
                setInvoiceData(prevState => {
                    if (prevState?.length === 0) {
                        return [result?.data?.headData, ...result?.data?.lineData]
                    } else {
                        return [...prevState, ...result?.data?.lineData]
                    }
                })
                // setInvoiceData([result?.data?.headData, ...result?.data?.lineData]);
                // csvLink.current.link.click();
            }
        } catch (e) {
            // setErrorMessage('There is an issue with downloading invoice data.');
            // handleErrorMessage();
            console.log('error', e.response);
        } finally {
            setTrackingLength(prevState => prevState + 1);
        }
    }

    const handleDownloadInvoice = () => {
        setInvoiceLoading(true);
        setTrackingLength(0);
        setInvoiceData([]);
        for (const adjustment of tracking) {
            getInvoiceData(adjustment?.tracking_no, adjustment?.invoice_no);
        }
    }

    useEffect(() => {
        (async () => {
            await getAdjustOrderDetails();
        })()
    }, []);

    useEffect(() => {
        if (trackingLength > 0 && trackingLength === tracking?.length) {
            if (invoiceData?.length > 1) {
                // console.log('download csv');
                setErrorMessage('');
                setSuccessMessage('Invoice data downloaded successfully.');
                handleSuccessMessage();
                csvLink.current.link.click();
            } else {
                setErrorMessage('There is no invoice data available for download.');
                handleErrorMessage();
            }
            setInvoiceLoading(false);
        }
    }, [trackingLength]);

    if (loading) {
        return (
            <Container maxwidth='xl'>
                <Box>
                    <CircularProgress/>
                </Box>
            </Container>
        )
    }

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                border: '1px solid #B2B2B2',
                borderRadius: '10px',
                padding: '10px 0',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
                background: '#FFFFFF'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{padding: '10px 20px'}}>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                                        <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                                            Adjustment Order #:
                                        </Typography>
                                        <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                                            {orderId}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={5} xs={12}>
                                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                                        <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                                            Total Adjustment Amount:
                                        </Typography>
                                        <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                                            $ {adjustAmount?.toFixed(2)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={1} xs={12}>
                                    <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                                        <Typography style={{fontSize: '18px', fontWeight: '600'}}>
                                            {carrier}
                                        </Typography>
                                    </Box>
                                </Grid>
                                {(carrier?.toUpperCase() === 'CANPAR' || carrier?.toUpperCase() === 'UPS') &&
                                    <Grid item md={2} xs={12}>
                                        <Box sx={{display: 'flex', gap: '20px', alignItems: 'center', height: '100%'}}>
                                            <LoadingButton
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #1D8B45',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={handleDownloadInvoice}
                                                loading={invoiceLoading}
                                            >
                                                <Typography style={{
                                                    textTransform: 'none',
                                                    fontSize: '12px',
                                                    color: '#1D8B45',
                                                }}>
                                                    Download CSV
                                                </Typography>
                                            </LoadingButton>
                                            <CSVLink
                                                data={invoiceData}
                                                filename={`Adjust-Invoice-${orderId}.csv`}
                                                className='hidden'
                                                ref={csvLink}
                                                target='_blank'
                                            />
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{padding: '0px 20px'}}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Tracking
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Adjust For Order
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Sender
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Recipient
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography style={{fontWeight: '600'}}>
                                        Adjustment
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{padding: '0px 20px'}}>
                            {
                                tracking?.length > 0 && tracking?.map((adjustment, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <AdjustPackageOrderDetailsEach adjustment={adjustment}/>
                                        </Fragment>
                                    )
                                })
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{padding: '0px 20px'}}>
                            {
                                carrier?.toUpperCase() === 'CANPAR' && <AdjustPackageOrderDetailsCanpar/>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}