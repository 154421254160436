import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    Snackbar,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import {useForm, Controller} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {Alert, Pagination} from "@material-ui/lab";
import axios from "axios";
import {useSelector} from "react-redux";
import PartnerListTable from "./PartnerListTable";
import {SERVICE_DAO_URL, NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import {Link} from "react-router-dom";
import BackArrow from "../BackArrow";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    },
    buttonFilesWrapper: {
        border: "2px dashed #d3d3d3",
        borderRadius: "2px",
        background: "#fdf5e8",
        padding: "5px",
        wordBreak: "break-word",
    },
    hscodeListWrapper: {
        border: "2px solid lightgray",
        borderRadius: "5px",
        width: "98%",
        maxHeight: "45vh",
        height: "fit-content",
        margin: "10px auto",
        overflowY: "auto",
        background: "#fff",
    },
    table: {
        width: "100%",
    },
    unTrackingTable: {
        "border-spacing": "2rem 1rem",
    },
    tbody: {
        "& tr:nth-child(odd)": {
            backgroundColor: "rgba(222, 222, 222, 0.4)",
        },
    },
    nameTH: {
        width: "100px",
    },
    idTH: {
        width: "100px",
        "white-space": "nowrap",
    },
    pagination: {
        "& > *": {
            marginTop: theme.spacing(2),
        },
        display: "flex",
        justifyContent: "center",
    },
    link: {
        textDecoration: "none",
    },
    customButton: {
        backgroundColor: '#FD841F',
        color: 'white',
        fontSize: '12px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#FD841F',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'

        }
    },
    saveButton: {
        backgroundColor: '#54B435',
        color: 'white',
        fontSize: '12px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#54B435',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'
        },
        marginRight: '10px'
    },
    cancelButton: {
        backgroundColor: '#FF0032',
        color: 'white',
        fontSize: '15px',
        padding: '5px 20px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#FF0032',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)'

        }
    }
}));

const PartnerList = () => {
    const classes = useStyles();
    const matches = useMediaQuery("(max-width:600px)");

    // const partnerInfo = getPartnerInfo();
    const partnerInfo = useSelector((state) => state.user);

    const {register, handleSubmit, control} = useForm();
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [partnerList, setPartnerList] = useState([]);

    const [companyList, setCompanyList] = useState([]);
    const [isSetCompany, setIsSetCompany] = useState(false);
    const [companyId, setCompanyId] = useState("");
    const [companyInfo, setCompanyInfo] = useState()

    useEffect(() => {
        const getPartners = async () => {
            try {
                const result = await axios.get(
                    PARTNER_URI + "/companies/getAllPartnerList"
                );
                if (result.status === 200) {
                    const newPartnerList = await result.data;
                    setPartnerList(newPartnerList)
                } else {
                    throw new Error({error: "Could not retrieve Partner List"})
                }
            } catch (e) {
                return
            }
        };
        getPartners();
    }, []);

    // Search for specific partner
    const onSubmit = async (data) => {
        // Empty keys replaced with undefined
        Object.keys(data).map((key) => {
            if (data[key] === "") {
                data[key] = undefined;
            }
        });
        try {
            const result = await axios.get(
                PARTNER_URI + "/companies/searchPartnerList",
                {params: {...data}}
            );
            if (result.status === 200) {
                const newPartnerList = await result.data;
                setPartnerList(newPartnerList)
            } else {
                throw new Error({error: "Could not retrieve Partner List"})
            }
        } catch (e) {
            return
        }
    };

    const [toastOpen, setToastOpen] = useState(false);
    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const getCompanyResult = async (companySearch) => {
        let requestURI = `${PARTNER_URI}/companies/searchCompanyList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                params: {
                    query_string: companySearch
                }
            })
            const companyInfo = [];
            const {data} = result;
            console.log(data)
            for (const value of data) {
                const companyData = {
                    // label: `${value?.id}`,
                    label: `${value?.id || "N/A"} | ${value?.business_name || "N/A"}  | ${value?.email || "N/A"} | ${value?.phone || "N/A"} `,
                    value: value?.id
                }
                companyInfo.push(companyData)
            }

            // console.log(companyInfo)
            return companyInfo;

        } catch (e) {
            console.log(e);
        }
    }

    const getCompanyInfo = async () => {
        console.log('company id', companyId)

        try {
            const result = await axios.get(
                PARTNER_URI + "/companies/getCompanyByIdOrName",
                {params: {companyId: companyId}}
            );

            setCompanyInfo(result.data)
        } catch (e) {
            console.log(e)
        }
    }

    const registerPartner = async () => {
        let requestURI = `${PARTNER_URI}/companies/registerPartner`;
        let data = {companyId: companyId, adminUserId: companyInfo?.admin_id, canCreditOrder: 1}
        console.log('body', data)

        try {
            await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(data)
            })
            setIsSetCompany(false)

        } catch (e) {
            console.log(e.response)
        }
    }

    useEffect(() => {
        companyId && getCompanyInfo()
    }, [companyId])

    const registerCompany = () => {
        registerPartner()
    }

    return (
        <Box paddingBottom="3rem">
            <BackArrow link={"/"}/>
            {/*Pop-up Toast*/}
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={3000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box>
                <Box
                    component="form"
                    width={"1000px"}
                    margin="0 auto"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        gap: "2rem",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <h1>Partner List</h1>
                    <Box flex="45%" width="100%">
                        <TextField
                            id="outlined-basic"
                            label="Search Partner By Company Name"
                            variant="outlined"
                            {...register("companyName")}
                            fullWidth
                        />
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField
                            id="outlined-basic"
                            label="Search Partner By Email"
                            variant="outlined"
                            type="email"
                            {...register("email")}
                            fullWidth
                        />
                    </Box>
                    <Box flex="45%" width="100%">
                        <TextField
                            id="outlined-basic"
                            label="Search Partner By Phone"
                            variant="outlined"
                            type="phone"
                            {...register("phone")}
                            fullWidth
                        />
                    </Box>
                    <Box mt={2} mb={4}>
                        {errorMessage !== "" && (
                            <Alert severity="error">{errorMessage}</Alert>
                        )}
                    </Box>
                    <Box flex="45%" width="100%" mt={2} sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Autocomplete
                            sx={{width: 8 / 10}}
                            disablePortal
                            options={companyList}
                            disabled={!isSetCompany}
                            fullWidth
                            filterOptions={(options, state) => options}
                            getOptionLabel={(option) => option.label}
                            {...register("companyId",)}
                            onChange={(e, value) => {
                                setCompanyId(value.value);
                            }}
                            onInputChange={async (e, value) => {
                                setCompanyId("");
                                const result = await getCompanyResult(value);
                                // console.log(result);
                                setCompanyList(result);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    id="outlined-basic"
                                    label="Search Company"
                                    variant="outlined"
                                    {...register("companyId")}
                                    required
                                    fullWidth
                                    {...params}
                                />}
                        />
                        {isSetCompany ? (
                            <Box sx={{display: 'flex'}}>
                                <Button
                                    className={classes.saveButton}
                                    onClick={registerCompany}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => setIsSetCompany(false)}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        ) : (
                            <Button
                                className={classes.customButton}
                                onClick={() => setIsSetCompany(true)}
                            >
                                Register
                            </Button>
                        )}
                    </Box>
                    <Box display="flex" sx={{gap: "3rem"}}>
                        <LoadingButton
                            variant="contained"
                            component="span"
                            color="primary"
                            style={{
                                textTransform: "unset",
                                fontSize: "1rem",
                                width: "7rem",
                            }}
                            loading={isLoading}
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Search
                        </LoadingButton>
                    </Box>
                </Box>
                <hr style={{marginBlock: "3rem"}}/>
                {isLoading ? (
                    <>
                        <Box>
                            <h1>Loading...</h1>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box>
                            <Box>
                                <Box mt={2}>
                                    <PartnerListTable partnerList={partnerList}/>
                                </Box>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
        ;
};
export default PartnerList;
