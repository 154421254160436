import {Divider, Grid, Typography} from "@mui/material";
import {Fragment} from "react";
import {MovingOrderListEach} from "./MovingOrderListEach";

export const MovingOrderListTable = ({order}) => {
    return (
        <Grid container spacing={1}>
            <Grid item md={2}>
                <Typography style={{fontWeight: '600'}}>
                    Order Number
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    Sender
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    Recipient
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    User
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    Operator
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    Order Status
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{fontWeight: '600'}}>
                    Shipping Status
                </Typography>
            </Grid>
            <Grid item md={1} xs={4}>
                <Typography style={{fontWeight: '600'}}>
                    Charge
                </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                order?.map((orderEach, index) =>
                    <Fragment key={index}>
                        <MovingOrderListEach order_info={orderEach}/>
                    </Fragment>
                )
            }
        </Grid>
    )
}