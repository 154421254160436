import React, { useState } from "react";
import { makeStyles, IconButton, Input } from "@material-ui/core";
import { Edit, Check, Cancel } from "@material-ui/icons";
import {
  selectHSCode,
  addHSCodeList,
  removeHSCodeList,
} from "../../redux/HSCode/HSCodeListSlice";
import { useDispatch } from "react-redux";
import {
  GET_HSCODE_DETAIL,
  UPDATE_HSCODE_SECTION,
  UPDATE_HSCODE_DETAIL,
} from "../../utils/apiUrl";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
  tdEdit: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
}));

export default function HSCodeSectionListItem({
  data,
  type,
  listIndex,
  tableRowClick,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState({
    name_cn_gb: false,
    name_cn_big: false,
    desc_cn_gb: false,
    desc_cn_big: false,
    tags: false,
  });

  const [updatedAttr, setUpdatedAttr] = useState({
    name_cn_gb: false,
    name_cn_big: false,
    desc_cn_gb: false,
    desc_cn_big: false,
    tags: false,
  });

  const [originValue, setOriginValue] = useState({
    name_cn_gb: "",
    name_cn_big: "",
    desc_cn_gb: "",
    desc_cn_big: "",
    tags: "",
  });

  const [editValue, setEditValue] = useState({
    name_cn_gb: "",
    name_cn_big: "",
    desc_cn_gb: "",
    desc_cn_big: "",
    tags: "",
  });

  const changeEditMode = (attr, value) => {
    setEditMode({
      ...editMode,
      [attr]: value,
    });
  };

  const fetchChildHSCode = async (_section, _parent) => {
    //API call to get hs code detail
    try {
      const result = await axios.get(GET_HSCODE_DETAIL, {
        params: {
          section: _section,
          ...(_parent ? { parent: _parent } : { parent: "NULL" }),
        },
      });

      if (result.status === 200) {
        if (result.data.length > 0) {
          dispatch(
            addHSCodeList({
              type: "detail",
              data: result.data,
              indexToAdd: listIndex + 1,
            })
          );
        } else {
          //empty result
        }
      }
    } catch (error) {}
  };

  const handleItemClick = (_section, _parent, _listIndex, event) => {
    const id = event.target.id;

    tableRowClick(id, _listIndex);

    dispatch(
      removeHSCodeList({
        indexToRemove: _listIndex + 1,
      })
    );
    dispatch(
      selectHSCode({
        section: _section,
        parent: _parent,
      })
    );

    fetchChildHSCode(_section, _parent);
  };

  const createId = () => {
    if (type === "section") {
      return `tr_${listIndex}_${data.section}`;
    }

    if (type === "detail") {
      return `tr_${listIndex}_${data.hscode}`;
    }
  };

  const handleValueChange = (attr, e) => {
    setEditValue({
      ...editValue,
      [attr]: e.target.value,
    });
  };

  const updateAttr = (attr, targetGroup) => {
    //compare if value is changed
    const match = editValue[attr].localeCompare(originValue[attr]);

    if (match === 0) {
      //value didnt change
      changeEditMode(attr, false);
    } else {
      //make API to update value
      let newTags = "";
      if (attr === "tags") {
        const val = editValue[attr].split(/,| |;|[.]/);
        val.forEach((v) => {
          if (v.length > 0) {
            newTags += v + " ";
          }
        });
        setEditValue({ ...editValue, [attr]: newTags });
      }

      const url =
        type === "section" ? UPDATE_HSCODE_SECTION : UPDATE_HSCODE_DETAIL;
      const body =
        type === "section"
          ? {
              section: targetGroup,
              [attr]: editValue[attr],
            }
          : {
              hscode: targetGroup,
              ...(attr === "tags"
                ? { [attr]: newTags }
                : { [attr]: editValue[attr] }),
            };

      axios({
        method: "put",
        url: url + `/${targetGroup}`,
        data: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            changeEditMode(attr, false);
            setUpdatedAttr({
              ...updatedAttr,
              [attr]: true,
            });
          }
        })
        .catch((error) => {});
    }
  };

  const renderSimpleEditView = (attr, targetGroup) => {
    return (
      <div>
        <Input
          type="text"
          value={editValue[attr]}
          onChange={(e) => handleValueChange(attr, e)}
        />
        <IconButton onClick={() => changeEditMode(attr, false)}>
          <Cancel />
        </IconButton>
        <IconButton onClick={() => updateAttr(attr, targetGroup)}>
          <Check />
        </IconButton>
      </div>
    );
  };

  const renderView = (attr) => {
    let value = updatedAttr[attr] ? editValue[attr] : data[attr];
    if (value?.trim().length === 0) {
      value = null;
    }
    return (
      <div
        onClick={() => {
          setEditValue({ ...editValue, [attr]: value });
          setOriginValue({ ...originValue, [attr]: value });
          changeEditMode(attr, true);
        }}
      >
        {value || (
          <IconButton
            onClick={() => {
              changeEditMode(attr, true);
            }}
          >
            <Edit />
          </IconButton>
        )}
      </div>
    );
  };

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>{type === "section" ? data.section : data.hscode}</td>
        <td
          onClick={(e) =>
            handleItemClick(
              data.section,
              type === "section" ? data.parent : data.hscode,
              listIndex,
              e
            )
          }
          id={createId()}
        >
          {type === "section" ? data.name : data.desc}
        </td>
        <td>
          <div className={classes.tdEdit}>
            {(() => {
              const targetField =
                type === "section" ? "name_cn_gb" : "desc_cn_gb";
              const targetGroup =
                type === "section" ? data.section : data.hscode;
              return editMode[targetField]
                ? renderSimpleEditView(targetField, targetGroup)
                : renderView(targetField);
            })()}
          </div>
        </td>
        <td>
          <div className={classes.tdEdit}>
            {(() => {
              const targetField =
                type === "section" ? "name_cn_big" : "desc_cn_big";
              const targetGroup =
                type === "section" ? data.section : data.hscode;
              return editMode[targetField]
                ? renderSimpleEditView(targetField, targetGroup)
                : renderView(targetField);
            })()}
          </div>
        </td>
        {type === "detail" && (
          <td>
            <div className={classes.tdEdit}>
              {(() => {
                const targetField = "tags";
                const targetGroup = data.hscode;
                return editMode[targetField]
                  ? renderSimpleEditView(targetField, targetGroup)
                  : renderView(targetField);
              })()}
            </div>
          </td>
        )}
      </tr>
    </>
  );
}
