import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";

const style = {
    PackageOrderDashboardCostRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        background: '#FFFFFF'
    }
}

export const PackageOrderTransactionCost = ({orderInfo}) => {

    return (
        <Box sx={style.PackageOrderDashboardCostRoot}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_DOCUMENTS')}*/}
                            Cost Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Shipping Fee
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'left'
                    }}>
                        $ {(+orderInfo?.total_amount_exclude_tax)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Service Fee
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'left'
                    }}>
                        $ {(+orderInfo?.service_fee_exclude_tax)?.toFixed(2)}
                    </Typography>
                </Grid>
                {
                    orderInfo?.discount > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Discount
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'left'
                            }}>
                                ($ {(+orderInfo?.discount)?.toFixed(2)})
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Taxes
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'left'
                    }}>
                        $ {(+orderInfo?.total_tax)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Total
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textAlign: 'left'
                    }}>
                        $ {(+orderInfo?.final_total)?.toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}
