import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {
    Container,
    Divider,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Select,
    Snackbar,
    Typography
} from "@mui/material";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {extractFullAddress, provinces_states} from "../../utils/Helper";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";

const styles = {
    ShippingOrderRoot: {
        // gap: '15px',
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        borderRadius: '10px',
    },
    ShippingOrderHeading: {
        padding: '20px 20px 0',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        padding: '20px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    smallInput: {
        "& .MuiInputBase-input": {
            height: "4px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const AddressLookUp = () => {

    const classes = useStyles();

    const [addressLookup, setAddressLookup] = useState(null);
    const [loading, setLoading] = useState(false);
    const [addressPredictions, setAddressPredictions] = useState([]);
    const [address, setAddress] = useState({
        streetAddress: null,
        apt: null,
        city: null,
        province: null,
        postalCode: null,
        country: null,
        displayAddress: {
            description: null,
            placeId: null,
            text: null
        }
    });
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleLookupAddress = async () => {
        setLoading(true);
        // let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/lookupAddress`;
        let data = {
            address: address?.streetAddress,
            city: address?.city,
            province: address?.province?.code,
            postalCode: address?.postalCode,
            country: address?.country
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            console.log('[handleLookupAddress] data', result?.data);
            setAddressLookup(result?.data);
        } catch (e) {
            console.log('error', e.response);
        } finally {
            setLoading(false);
        }
    }

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setAddress(prevState => ({
            ...prevState,
            streetAddress: value,
            displayAddress: {
                description: '',
                placeId: '',
                text: value
            }
        }));
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setAddress(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                city: address?.city,
                province: address?.state,
                country: address?.country?.code,
                displayAddress: value
            }))
        } else {
            setAddress(prevState => ({
                ...prevState,
                streetAddress: '',
                displayAddress: {
                    description: '',
                    placeId: '',
                    text: ''
                },
            }))
        }
    }

    const handleAddress2 = (event) => {
        setAddress(prevState => ({
            ...prevState, apt: event.target.value
        }))
    }

    const handleCity = (event) => {
        setAddress(prevState => ({
            ...prevState, city: event.target.value
        }))
    }

    const handleProvince = (event, value) => {
        console.log(value);
        if (value) {
            setAddress(prevState => ({
                ...prevState,
                province: {
                    ...prevState.province,
                    code: value?.code,
                    name: value?.name
                },
                country: value?.countryCode
            }))
        } else {
            setAddress(prevState => ({
                ...prevState,
                province: null
            }))
        }
    }

    const handleCountry = event => {
        setAddress(prevState => ({
            ...prevState,
            country: event?.target?.value
        }))
    }

    const handlePostalCode = (event) => {
        setAddress(prevState => ({
            ...prevState, postalCode: event.target.value
        }))
    }

    console.log('[AddressLookUp] addressLookup', addressLookup);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Box sx={styles.ShippingOrderHeading}>
                    <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                        Address Lookup
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Address
                                </FormLabel>
                                <Autocomplete
                                    freeSolo
                                    value={address?.displayAddress}
                                    fullWidth
                                    options={addressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.text}
                                    onInputChange={handleInputChangeAddress}
                                    onChange={handleChangeAddress}
                                    PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        className={classes.smallInput}
                                        variant="outlined"
                                    />}
                                    renderOption={option => (
                                        <Typography style={{fontSize: '12px'}}>
                                            {option.description}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel sx={{textAlign: 'left'}}>
                                    Apt / Suite
                                </FormLabel>
                                <TextField
                                    value={address.apt}
                                    onInput={handleAddress2}
                                    fullWidth
                                    variant="outlined"
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    City
                                </FormLabel>
                                <TextField
                                    value={address.city}
                                    onInput={handleCity}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Province
                                </FormLabel>
                                <Autocomplete
                                    value={address.province}
                                    groupBy={(option) => option.country}
                                    fullWidth
                                    options={provinces_states}
                                    getOptionLabel={(option) => option.code}
                                    onChange={handleProvince}
                                    renderInput={(params) => <TextField
                                        variant="outlined"
                                        className={classes.smallInput}
                                        {...params}
                                    />}
                                    renderOption={(option) => (
                                        <div>
                                            {option.name}
                                        </div>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Country
                                </FormLabel>
                                <FormControl fullWidth>
                                    <Select
                                        value={address?.country}
                                        size='small'
                                        onChange={handleCountry}
                                        style={{textAlign: 'left'}}
                                    >
                                        <MenuItem value='CA'>Canada</MenuItem>
                                        <MenuItem value='US'>Unites States</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    Postal Code
                                </FormLabel>
                                <TextField
                                    value={address.postalCode}
                                    onInput={handlePostalCode}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        style: {padding: '2px 0'},
                                    }}
                                    size='small'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    loading={loading}
                                    onClick={handleLookupAddress}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Lookup
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        {
                            addressLookup &&
                            <>
                                <Grid item xs={6}>
                                    <Typography>
                                        Customer Messages:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {addressLookup?.customerMessages?.[0]?.message || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Classifications: {addressLookup?.classification}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {addressLookup?.classification}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Rural Route Highway Contract:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {addressLookup?.ruralRouteHighwayContract ? 'True' : 'False'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        General Delivery:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {addressLookup?.generalDelivery ? 'True' : 'False'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Normalized Status Name DPV:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {addressLookup?.normalizedStatusNameDPV ? 'True' : 'False'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Standardized Status Name Match Source:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {addressLookup?.standardizedStatusNameMatchSource || 'N/A'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        Resolution Method Name:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>
                                        {addressLookup?.resolutionMethodName || 'N/A'}
                                    </Typography>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}