import {FormLabel, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingOrderDeposit = ({cbHandleGetMovingDeposit}) => {

    const [deposit, setDeposit] = useState({
        isDeposit: false,
        amount: 0
    })

    const handleIsDeposit = (event) => {
        setDeposit(prevState => ({
            ...prevState,
            isDeposit: event?.target?.checked,
            amount: 0
        }))
    }

    const handleAmount = (event) => {
        const value = event?.target?.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setDeposit(prevState => ({
                ...prevState,
                amount: event?.target?.value
            }))
        }
    }

    useEffect(() => {
        cbHandleGetMovingDeposit(deposit);
    }, [deposit])

    return (
        <Grid item xs={12}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Deposit
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={deposit.isDeposit}
                                        onChange={handleIsDeposit}
                                        name="narrow street"
                                        sx={{
                                            color: 'green',
                                            '&.Mui-checked': {
                                                color: 'green',
                                            },
                                        }}
                                        style={{
                                            color: 'green',
                                            '&.Mui-checked': {
                                                color: 'green',
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <>
                                        Requires Deposit
                                    </>
                                }
                            />
                        </FormControl>
                    </Grid>
                    {/*{*/}
                    {/*    deposit?.isDeposit &&*/}
                    {/*    <Grid item xs={4}>*/}
                    {/*        <FormControl fullWidth>*/}
                    {/*            <FormLabel required sx={{textAlign: 'left'}}>*/}
                    {/*                Amount*/}
                    {/*            </FormLabel>*/}
                    {/*            <TextField*/}
                    {/*                value={deposit.amount}*/}
                    {/*                onInput={handleAmount}*/}
                    {/*                fullWidth*/}
                    {/*                variant="outlined"*/}
                    {/*                InputProps={{*/}
                    {/*                    style: {padding: '2px 0'},*/}
                    {/*                }}*/}
                    {/*                size='small'*/}
                    {/*                // error={validationResult?.validateMovingToMissing?.includes('postalCode') || validationResult?.movingToPostalCodeError}*/}
                    {/*                // helperText={validationResult?.validateMovingToMissing?.includes('postalCode') ? 'Required' : validationResult?.movingToPostalCodeError && "Invalid"}*/}
                    {/*            />*/}
                    {/*        </FormControl>*/}
                    {/*    </Grid>*/}
                    {/*}*/}
                </Grid>
            </Box>
        </Grid>
    )
}