import {FormLabel, Grid, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldPallet} from "../../utils/customComponent";
import {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {extractFullAddress} from "../../utils/Helper";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        }
    }
});

export const LTLOrderListConsigneeAddress = ({
                                                 getDataFromChildComponent,
                                                 triggerAction,
                                                 isConfirm,
                                                 validation,
                                                 getConsigneeResidential
                                             }) => {

    const [residential, setResidential] = useState(false);
    const [consigneeName, setConsigneeName] = useState();
    const [consigneePhone, setConsigneePhone] = useState();
    const [consigneeCompanyName, setConsigneeCompanyName] = useState();
    const [consigneeEmail, setConsigneeEmail] = useState();
    const [consigneeAddress, setConsigneeAddress] = useState();
    const [consigneeAddressTwo, setConsigneeAddressTwo] = useState();
    const [consigneeCity, setConsigneeCity] = useState();
    const [consigneeProvince, setConsigneeProvince] = useState();
    const [consigneeCountry, setConsigneeCountry] = useState();
    const [consigneePostalCode, setConsigneePostalCode] = useState();

    const [shipToAddressPredictions, setShipToAddressPredictions] = useState([]);
    const [displayAddress, setDisplayAddress] = useState({
        description: null,
        placeId: null,
        text: null
    });

    const classes = useStyles();

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            console.log('prediction', predictions);
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            // console.log('updated prediction', updatedPredictions);

            return updatedPredictions;

        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputChangeAddress = async (event, value) => {
        setDisplayAddress(prevState => ({
            ...prevState,
            description: '',
            placeId: '',
            text: value
        }));
        setConsigneeAddress(value);
        const predictionsAddress = await googleMapsPlaceAutocomplete(value, 'address');
        setShipToAddressPredictions(predictionsAddress);
    }

    const handleChangeAddress = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            setDisplayAddress(value);
            setConsigneeCity(address?.city);
            setConsigneeProvince(address?.state?.code);
            setConsigneeCountry(address?.country?.code);
            setConsigneePostalCode(address?.zip);
        } else {
            setDisplayAddress('');
        }
    }

    const verifyAddressIsResidential = async () => {
        let requestURI = `${NODE_ROUTE_URI}/verifyAddress/isResidential`;
        let data = {
            address: consigneeAddress,
            city: consigneeCity,
            province: consigneeProvince,
            postalCode: consigneePostalCode,
            country: consigneeCountry
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                data: data
            })
            // console.log('result', result);
            setResidential(result?.data?.result);
            getConsigneeResidential(result?.data?.result);
        } catch (e) {
            console.log('error', e.response);
        }
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'consigneeName':
                setConsigneeName(value);
                break;
            case 'consigneePhone':
                setConsigneePhone(value);
                break;
            case 'consigneeCompanyName':
                setConsigneeCompanyName(value);
                break;
            case 'consigneeEmail':
                setConsigneeEmail(value);
                break;
            case 'consigneeAddress':
                setConsigneeAddress(value);
                break;
            case 'consigneeAddressTwo':
                setConsigneeAddressTwo(value);
                break;
            case 'consigneeCity':
                setConsigneeCity(value);
                break;
            case 'consigneeProvince':
                setConsigneeProvince(value);
                break;
            case 'consigneeCountry':
                setConsigneeCountry(value);
                break;
            case 'consigneePostalCode':
                setConsigneePostalCode(value);
                break;
            default:
        }
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                consigneeInfo: {
                    consigneeName,
                    consigneePhone,
                    consigneeEmail,
                    consigneeCompanyName,
                    consigneeAddress,
                    consigneeAddressTwo,
                    consigneeCity,
                    consigneeProvince,
                    consigneeCountry,
                    consigneePostalCode
                }
            });
        }
    }, [triggerAction])

    useEffect(() => {
        if (consigneeAddress && consigneeCity && consigneeProvince && consigneeCountry && consigneePostalCode) {
            getConsigneeResidential(false);
            verifyAddressIsResidential();
        }
    }, [consigneeAddress, consigneeCity, consigneeProvince, consigneeCountry, consigneePostalCode])

    return (
        <Fragment>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Name
                    </FormLabel>
                    <CustomTextFieldNormal
                        field='consigneeName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeName')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Phone
                    </FormLabel>
                    <CustomTextFieldNormal
                        field='consigneePhone'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneePhone')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Company
                    </FormLabel>
                    <CustomTextFieldNormal
                        field='consigneeCompanyName'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Email
                    </FormLabel>
                    <CustomTextFieldNormal
                        field='consigneeEmail'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address
                    </FormLabel>
                    <Autocomplete
                        freeSolo
                        value={displayAddress}
                        fullWidth
                        disabled={isConfirm}
                        options={shipToAddressPredictions}
                        filterOptions={(options, state) => options}
                        getOptionLabel={option => option?.text}
                        onInputChange={handleInputChangeAddress}
                        onChange={handleChangeAddress}
                        PaperComponent={props => <Paper {...props} sx={{width: 400}}/>}
                        renderInput={params => <TextField
                            {...params}
                            className={classes.smallInput}
                            variant="outlined"
                            error={validation?.shipToMissing?.includes('consigneeAddress')}
                            helperText={
                                (validation?.shipToMissing?.includes('consigneeAddress')) && 'Required Field'
                            }
                        />}
                        renderOption={option => (
                            <Typography>
                                {option?.description}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Address Two
                    </FormLabel>
                    <CustomTextFieldNormal
                        field='consigneeAddressTwo'
                        onChange={onChange}
                        isConfirm={isConfirm}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        City
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeCity}
                        field='consigneeCity'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeCity')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Province
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeProvince}
                        field='consigneeProvince'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeProvince')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Country
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneeCountry}
                        field='consigneeCountry'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneeCountry')}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Postal Code
                    </FormLabel>
                    <CustomTextFieldPallet
                        textValue={consigneePostalCode}
                        field='consigneePostalCode'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.shipToMissing?.includes('consigneePostalCode')}
                    />
                </FormControl>
            </Grid>
            {
                residential &&
                <Grid item xs={12}>
                    <Typography sx={{fontSize: '14px', textAlign: 'left'}}>
                        Attention: This is a residential address according to Canada Post.
                    </Typography>
                </Grid>
            }
        </Fragment>

    )

}