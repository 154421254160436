

exports.PAGE_SIZE = 50;


// export const SHIPPING_LTL_GENERAL_APPOINTMENT_DELIVERY_CODE = "APTFGT";
// export const SHIPPING_LTL_GENERAL_DANGEROUS_GOODS_CODE = "DANGEROUS";
// export const SHIPPING_LTL_GENERAL_INSIDE_DELIVERY_CODE = "INSDLY";
exports.SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE = "PRESDL";
exports.SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE = "PRESPU";
// export const SHIPPING_LTL_GENERAL_PROTECTED_SERVICE_CODE = "PROTEC";
// export const SHIPPING_LTL_GENERAL_TAILGATE_DELIVERY_CODE = "TLGDEL";
// export const SHIPPING_LTL_GENERAL_TAILGATE_PICKUP_CODE = "TLGGP";
// export const SHIPPING_LTL_GENERAL_INSURANCE_CLAIM_CODE = "INSFEE";