import {FormLabel, Grid, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {carManufactures, shippingMode, shippingModeList} from "../../utils/Helper";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});


export const CarQuoteShippingMode = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const classes = useStyles();

    const [shippingMode, setShippingMode] = useState('Open Truck & Train');

    const handleChangeShippingMode = async (event, value) => {
        setShippingMode(value);
    }

    const handleInputChangeShippingMode = async (event, value) => {
        setShippingMode(value);
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({shippingMode});
        }
    }, [triggerAction]);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Shipping Mode
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            value={shippingMode}
                            freeSolo
                            options={shippingModeList}
                            // getOptionLabel={option}
                            onInputChange={handleInputChangeShippingMode}
                            onChange={handleChangeShippingMode}
                            fullWidth
                            disabled={isConfirm}
                            PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                            renderInput={params => <TextField
                                {...params}
                                variant='outlined'
                                className={classes.smallInput}
                                error={validation?.shippingModeMissing}
                                helperText={
                                    (validation?.shippingModeMissing) && 'Required Field'
                                }
                            />}
                            renderOption={(option) => (
                                <Typography
                                    style={{fontSize: '14px'}}
                                >
                                    {/*<Typography>*/}
                                    {option}
                                    {/*</Typography>*/}
                                </Typography>
                            )}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Fragment>
    )
}