import { createSlice } from "@reduxjs/toolkit";
import {ADDRESS_TYPE_RESIDENTIAL} from "../shared/constInfo";

export const defaultPickUpDetails = {
  selectedDate: new Date(),
  // dropOff: {
  //   address: "",
  //   zipCode: "",
  //   city: "",
  //   province: "",
  //   country: "CA"
  // },
  accountBaseCombinedName: "UPS-Richmond-BC",
  refOrderNumber: "",
  cargoControlNumber: "",
  deliver: {
    name: "",
    address: "",
    aptInfo: "",
    zipCode: "",
    city: "",
    province: null,
    country: "CA",
    phone: "",
    placeId: "",
    service: ADDRESS_TYPE_RESIDENTIAL
  },
  pickUp: {
    name: "",
    address: "",
    aptInfo: "",
    zipCode: "",
    city: "",
    province: null,
    country: "CA",
    phone: "",
    placeId: "",
    service: ADDRESS_TYPE_RESIDENTIAL
  },
  locationType: "commercial",
  schedulePickupDate: null,
  requestId: "",
  status: 0
};

export const defaultShippingItem = {
  description: "",
  height: null,
  weight: null,
  quantity: null,
  length: null,
  width: null,
  packageID: "",
  color: "",
  hazardous: false,
  tempSensitive: false,
  looseUnit: 1,
  weightUnit: "kg",
  lengthUnit: "cm"
};

export const defaultShippingPallets = {
  description: "",
  height: null,
  weight: null,
  quantity: null,
  length: null,
  width: null,
  packageID: "",
  color: "",
  hazardous: false,
  tempSensitive: false,
  looseUnit: 1,
  weightUnit: "lb",
  lengthUnit: "in",
  packagingType: "1"
};

const initialState = {
  // featureSwitches: null,
  // isFeatureLoaded: false,

  shippingItems: [],
  pickUpDetails: defaultPickUpDetails,
};

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    addShippingItem: (state, { payload }) => {
      state.shippingItems.push(payload.newItem);
    },

    updateShippingItem: (state, { payload }) => {
      const index = state.shippingItems.findIndex(
        (item) => item.packageID === payload.newItem.packageID
      );
      if (index !== -1) state.shippingItems[index] = payload.newItem;
    },

    deleteShippingItem: (state, { payload }) => {
      state.shippingItems = state.shippingItems.filter(
        (_pallet) => _pallet.packageID !== payload.packageID
      );
    },

    addPickupDetails: (state, { payload }) => {
      state.pickUpDetails = payload.newPickUpInfo;
    },

    loadShippingItems: (state, { payload }) => {
      state.shippingItems = payload.items;
    },

    clearShippingInfo: (state) => {
      state.shippingItems = [];
      state.pickUpDetails = defaultPickUpDetails;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = shippingSlice;

export const {
  addShippingItem,
  deleteShippingItem,
  addPickupDetails,
  updateShippingItem,
  loadShippingItems,
  clearShippingInfo,
} = actions;

export const shippingReducer = reducer;

// Export selector
