import {Fragment, useEffect, useRef, useState} from "react";
import {Button, Divider, Grid, Link, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import {LABEL_DOWNLOAD_URL_PREFIX, PARTNER_URI} from "../../utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {ReactComponent as InvoiceLogo} from "../../images/svg/Invoice.svg";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardCrossBorder = ({orderData, getOrderDetails}) => {

    console.log('order data', orderData);

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loadingRemove, setLoadingRemove] = useState(false);

    const inputPDFFile = useRef(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const uploadPDFToServer = async ({fileObj}) => {
        // console.log('file object', fileObj);
        setLoading(true);
        if (!fileObj) {
            return;
        }
        const formData = new FormData();
        formData.append('invoices', fileObj);
        formData.append('orderId', orderData?.id);
        const requestURL = `${PARTNER_URI}/shippingLTL/admin/uploadLTLCommercialInvoice`;

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: formData,
            })

            console.log('result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully upload invoice');
            handleSuccessMessage();
            // setTimeout(() => {
            // getOrderData();
            // window.location.reload();
            getOrderDetails();
            // }, 2000);
        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to upload invoice');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const onPDFDrop = async (e) => {
        if (!(e.target.files && e.target.files[0])) {
            return;
        }
        await uploadPDFToServer({fileObj: e.target.files[0]});
    };

    const deleteCommercialInvoice = async (docId) => {
        setLoadingRemove(true);
        const requestURL = `${PARTNER_URI}/shippingLTL/admin/removeCommercialInvoice`;
        try {
            const result = await axios({
                method: 'delete',
                url: requestURL,
                params: {
                    docId: docId
                }
            })

            setErrorMessage('');
            setSuccessMessage('Successfully remove invoice');
            handleSuccessMessage();
            // setTimeout(() => {
            getOrderDetails();
            // }, 2000);

        } catch (e) {
            console.log('error', e?.response);
            setErrorMessage('Fail to remove invoice');
            handleErrorMessage();
        } finally {
            setLoadingRemove(false);
        }
    }

    const handleRemoveInvoice = (docId) => {
        deleteCommercialInvoice(docId);
    }

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={6}>
                <Typography style={{fontSize: '16px', fontWeight: '600', textAlign: 'left'}}>
                    Commercial-Invoices and Packing-List
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <input type='file' style={{display: 'none'}} ref={inputPDFFile} onChange={onPDFDrop}
                           accept=".pdf" name='file'/>
                    <LoadingButton
                        variant='contained'
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => {
                            inputPDFFile.current.click()
                        }}
                        loading={loading}
                    >
                        <Typography style={{
                            textTransform: 'none',
                        }}>
                            Upload Invoice
                        </Typography>
                    </LoadingButton>
                </Box>
            </Grid>
            {
                orderData?.commecialInvoices?.length > 0 ?
                    <>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                File Name
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography style={{fontSize: '14px', fontWeight: '600', textAlign: 'center'}}>
                                Uploaded Date
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={3}>
                        </Grid>
                        {
                            orderData?.commecialInvoices?.map((element, index) => (
                                <Fragment key={index}>
                                    <Grid item md={3}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%'
                                        }}>
                                            <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                                {element?.file_name}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={3}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%'
                                        }}>
                                            <Typography style={{fontSize: '14px', textAlign: 'center'}}>
                                                {dayjs(element?.created_at).format('YYYY-MM-DD')}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <Link href={`${LABEL_DOWNLOAD_URL_PREFIX}${element?.file_uri}`}
                                                  target="_blank" rel="noopener noreferrer"
                                            >
                                                <Button
                                                    variant='outlined'
                                                    sx={{
                                                        border: '2px solid #1D8B45',
                                                        "&:hover": {
                                                            border: '2px solid #1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        }
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontSize: '14px',
                                                            textTransform: 'none',
                                                            color: '#1D8B45',
                                                        }}
                                                    >
                                                        Download
                                                    </Typography>
                                                </Button>
                                            </Link>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            <LoadingButton
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #424242',
                                                    "&:hover": {
                                                        border: '2px solid #424242',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={() => handleRemoveInvoice(element?.doc_id)}
                                                loading={loadingRemove}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textTransform: 'none',
                                                        color: '#424242',
                                                    }}
                                                >
                                                    Delete
                                                </Typography>
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                </Fragment>
                            ))
                        }
                    </>
                    :
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            minHeight: '200px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            flexDirection: 'column'
                        }}>
                            <InvoiceLogo width="150px" height="150px"/>
                            <Typography>
                                Your Commercial Invoice list is empty now
                            </Typography>
                        </Box>
                    </Grid>
            }
        </Fragment>
    )
}