import React from "react";
import { Box, Button } from "@material-ui/core";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// import { ordinal_suffix_of } from "../../utils/addOrdinalSuffix";
// import {shipmentTypesDetails} from "../shared";

// const findUnit = (labelId, fieldName) => {
//   const itemUnit = shipmentTypesDetails[labelId];
//   return itemUnit ? itemUnit[fieldName] : "";
// };

const ReviewOrderHeader = ({
                             orderNumber,
                             pickupName,
                             pickupPhone,
                             pickupAddress,
                             pickupAddressTwo,
                             pickupCity,
                             pickupProvince,
                             pickupPostalCode,
                             deliverName,
                             deliverPhone,
                             deliverAddress,
                             deliverAddressTwo,
                             deliverCity,
                             deliverProvince,
                             deliverPostalCode,
                             pallets,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className="subtitle">
        <Box display="flex" justifyContent="space-between" mb={2} >
          <Box className={classes.summaryHeader}>Order # {orderNumber}</Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader}>Pickup</Box>
        </Box>

        <Box className={classes.summaryBodyText} mb={2}>
          Name: {pickupName}
          <br />
          Phone number: {pickupPhone}
          <br />
          {`${pickupAddress}`}, {pickupAddressTwo} <br />
          {`${pickupCity}, ${pickupProvince} ${pickupPostalCode}`}
          <br />
        </Box>
      </div>
      <div className="subtitle">
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader}>Delivery</Box>
        </Box>
        <Box className={classes.summaryBodyText} mb={2}>
          Name: {deliverName}
          <br />
          Phone number: {deliverPhone}
          <br />
          {`${deliverAddress}`}, {deliverAddressTwo} <br />
          {`${deliverCity}, ${deliverProvince} ${deliverPostalCode}`}
        </Box>
      </div>
      <div className="bodytext"></div>
      {/* <div className="subtitle">
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader}>Items</Box>
        </Box>
        <Box className={classes.summaryBodyText} mb={1}>
          {pallets.map((item, key) => {
            return (
              <div key={key}>
                <p>
                  <Box component="span" fontWeight="700">
                    {ordinal_suffix_of(key + 1)} Package:
                  </Box>{" "}
                  <Box component="span" fontWeight="400">
                    {item.description} - {item.weight}{item.weightUnit} &times; {item.looseUnit}
                  </Box>
                </p>
              </div>
            );
          })}
        </Box>
      </div> */}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  summaryBodyText: {
    fontSize: "1.1rem",
    color: "rgba(0,0,0,0.8)",
    fontFamily: "Montserrat",
    fontWeight: "400",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
}));

// ReviewOrderHeader.propTypes = {
//   pickupEmpty: PropTypes.bool.isRequired,
//   toFirst: PropTypes.func.isRequired,
//   pickUpDetails: PropTypes.object.isRequired,
//   toFourth: PropTypes.func.isRequired,
//   pallets: PropTypes.array.isRequired,
// };

export default ReviewOrderHeader;
