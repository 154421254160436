import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { TextField, Box, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { green, red, blue } from "@mui/material/colors";
import BackArrow from "../BackArrow";

import axios from "axios";
import { SERVICE_DAO_URL, NODE_ROUTE_URI, PARTNER_URI } from "../../utils/apiUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));
const filter = createFilterOptions();

const CompanyDetails = () => {
  const classes = useStyles;

  const [companyId] = useState(useParams().id);
  const [companyDetails, setCompanyDetails] = useState({});
  const [initialCompanyDetails, setInitialCompanyDetails] = useState({});
  const [canEdit, setCanEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [basicInfoInitalValues, setBasicInfoInitialValues] = useState({});
  const [statusMessage, setStatusMessage] = useState("");
  const [partnerStatus, setPartnerStatus] = useState(false);
  const [partnerStatusMessage, setPartnerStatusMessage] = useState("");
  const [admin, setAdmin] = useState(undefined);
  const [newAdmin, setNewAdmin] = useState(undefined);
  const [adminId, setAdminId] = useState(undefined);
  const [hasAdmin, setHasAdmin] = useState(false);

  const [adminList, setAdminList] = useState([]);

  const [canEditAdmin, setCanEditAdmin] = useState(false);

  const options = [
    {value: 'LTL TRUCKING', label: 'LTL TRUCKING'},
    {value: 'WAREHOUSE', label: 'WAREHOUSE'},
    {value: '', label: 'NONE'},
  ];

  const [companyType, setCompanyType] = useState('LTL TRUCKING');

  useEffect(() => {
    const getCompanyDetails = async () => {
      try {
        // Get company details from api
        const result = await axios.get(
          PARTNER_URI + "/companies/getCompanyByIdOrName",
          { params: { companyId: companyId } }
        );
        if (result.status === 200) {
          const newCompanyDetails = result.data;
          console.log(newCompanyDetails)
          setCompanyDetails(newCompanyDetails);
          setInitialCompanyDetails(newCompanyDetails);

          setCompanyType(companyDetails?.company_type)

          // If assigned admin, setAdmin
          if (newCompanyDetails.admin_id) {
            setAdmin({
              id: newCompanyDetails.admin_id || undefined,
              firstname: newCompanyDetails.firstname,
              lastname: newCompanyDetails.lastname,
              administrator: newCompanyDetails.administrator,
            });
          }

          const newPartnerStatus =
            newCompanyDetails.partner_id === null ||
            !(newCompanyDetails.partner_id > 0)
              ? false
              : true;
          setPartnerStatus(newPartnerStatus);

          const adminId = newCompanyDetails.admin_id;
          if (adminId !== null) {
            setHasAdmin(true);
          } else {
            setHasAdmin(false);
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        return;
      }
    };
    getCompanyDetails();
  }, [companyId]);

  const queryAdmins = async (queryString) => {
    try {
      // Get company details from api
      const result = await axios.get(
        PARTNER_URI + "/companies/searchActiveUserProfile",
        { params: { query_string: queryString } }
      );
      if (result.status === 200) {
        const adminList = result.data;
        setAdminList(adminList);
      } else {
        throw new Error();
      }
    } catch (e) {
      return;
    }
  };

  const setAdminForCompany = async () => {
    const body = {
      adminUserId: newAdmin.id,
      companyId: companyDetails.id,
    };
    try {
      // Get company details from api
      const result = await axios.post(
        PARTNER_URI + "/companies/setAdminForCompany",
        body
      );
      if (result.status === 200) {
        setAdmin(newAdmin);
        setAdminList([newAdmin]);
        setNewAdmin(undefined);
        setCanEditAdmin(false);
        setPartnerStatusMessage(<Box sx={{color:green[500]}}>Successfully Set as Admin</Box>);
      } else {
        setPartnerStatusMessage(<Box sx={{color:red[500]}}>Failed to set as Admin</Box>);
        throw new Error();
      }
    } catch (e) {
      return;
    }
    return;
  };

  // Reverts all Textfields/inputs to intial, turns canEdit off
  const cancelChanges = () => {
    setCanEdit(false);
    setCompanyDetails(initialCompanyDetails);
  };

  // Reverts all Textfields/inputs to intial, turns canEditAdmin off
  const cancelChangesAdmin = () => {
    setCanEditAdmin(false);
    setCompanyDetails(initialCompanyDetails);
  };

  const saveNewCompanyInfo = async () => {
    setCanEdit(false);
    if (companyDetails === initialCompanyDetails) {
      alert("No changes were made.");
      return;
    }

    // Format POST body
    const body = {
      companyId: companyDetails.id,
      businessName: companyDetails.business_name,
      normalizedBusinessName: companyDetails.normalized_business_name,
      email: companyDetails.email,
      phone: companyDetails.phone,
      address: companyDetails.address,
      city: companyDetails.city,
      province: companyDetails.province,
      postalCode: companyDetails.postal_code,
      companyType: companyDetails.company_type,
      gstNumber: companyDetails.gst_number,
    };

    try {
      // Get company details from api
      const result = await axios.post(
        PARTNER_URI + "/companies/setBasicInfoForCompany",
        body
      );
      if (result.status === 200) {
        setStatusMessage(<Box sx={{color:green[500]}}>Successfully saved company info</Box>);
      } else {
        setStatusMessage(<Box sx={{color:red[500]}}>Failed to save new company info</Box>);
        throw new Error();
      }
    } catch (e) {
      return;
    }
  };

  const setCompanyAsPartner = async () => {
    try {
      if (!admin) {
        alert("Select an Admin before setting as Partner");
        return;
      }
      // Format POST body
      const body = {
        companyId: companyDetails.id,
        canCreditOrder: true,
        adminUserId: admin.id,
      };
      // Get company details from api
      const result = await axios.post(
        PARTNER_URI + "/companies/registerPartner",
        body
      );
      if (result.status === 200) {
        setPartnerStatus(true);
        setPartnerStatusMessage(<Box sx={{color:green[500]}}>Successfully Set as Partner</Box>);
      } else {
        setPartnerStatusMessage(<Box sx={{color:red[500]}}>Failed to set as Partner</Box>);
        throw new Error();
      }
    } catch (e) {
      return;
    }
  };

  return (
    <div>
      <BackArrow link={"/companyList"} />
      <Box maxWidth="70%" margin="0 auto" paddingBottom={"200px"}>
        <Box mt={2}>
          <h1>
            {initialCompanyDetails.business_name
              ? initialCompanyDetails.business_name
              : "Loading Details"}
          </h1>
          {statusMessage}
          {canEdit ? (
            <>
              <Button
                style={{
                  backgroundColor: green[500],
                  color: "white",
                }}
                variant="contained"
                onClick={saveNewCompanyInfo}
              >
                Save
              </Button>
              <Button
                style={{
                  backgroundColor: red[500],
                  marginLeft: "20px",
                  color: "white",
                }}
                variant="contained"
                onClick={() => cancelChanges()}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              style={{
                backgroundColor: blue[500],
                color: "white",
              }}
              variant="contained"
              color="primary"
              onClick={() => setCanEdit(true)}
            >
              Edit Basic Info
            </Button>
          )}

          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.idTH} width="20%"></th>
                <th className={classes.idTH} width="100%"></th>
                {/* <th className={classes.idTH} width="10%"></th> */}
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              <tr>
                <td>
                  <strong>Business Name</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    <TextField
                      id="outlined-basic"
                      value={
                        companyDetails.business_name
                          ? companyDetails.business_name
                          : ""
                      }
                      onChange={(e) => {
                        setCompanyDetails((prev) => ({
                          ...prev,
                          business_name: e.target.value,
                        }));
                      }}
                      // variant={canEdit ? "outlined" : "filled"}
                      variant="outlined"
                      // {...register("businessName")}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    <TextField
                      id="outlined-basic"
                      value={companyDetails.email ? companyDetails.email : ""}
                      variant="outlined"
                      // {...register("email")}
                      onChange={(e) => {
                        setCompanyDetails((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }));
                      }}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Company Type</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    { canEdit ?
                      <FormControl fullWidth>
                        <Select
                            sx={{textAlign: 'left'}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant="outlined"
                            disabled={!canEdit}
                            value={companyDetails?.company_type}
                            onChange={(e) => {
                              setCompanyDetails((prev) => ({
                                ...prev,
                                company_type: e.target.value,
                              }));
                            }}
                        >
                          {options.map((item) => {
                            return (
                                <MenuItem value={item.value} key={item.value}>
                                  {item.label}
                                </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                        :
                        <TextField
                            id="outlined-basic"
                            value={
                              companyDetails.company_type
                                  ? companyDetails.company_type
                                  : ""
                            }
                            variant="outlined"
                            // {...register("companyType")}
                            onChange={(e) => {
                              setCompanyDetails((prev) => ({
                                ...prev,
                                company_type: e.target.value,
                              }));
                            }}
                            fullWidth
                            disabled={!canEdit}
                        />
                    }
                  </Box>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Phone</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    <TextField
                      id="outlined-basic"
                      value={companyDetails.phone ? companyDetails.phone : ""}
                      variant="outlined"
                      // {...register("phone")}
                      onChange={(e) => {
                        setCompanyDetails((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }));
                      }}
                      fullWidth
                      disabled={!canEdit}
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Address</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    <TextField
                      id="outlined-basic"
                      value={
                        companyDetails.address ? companyDetails.address : ""
                      }
                      variant="outlined"
                      // {...register("address")}
                      onChange={(e) => {
                        setCompanyDetails((prev) => ({
                          ...prev,
                          address: e.target.value,
                        }));
                      }}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>City</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    <TextField
                      id="outlined-basic"
                      value={companyDetails.city ? companyDetails.city : ""}
                      variant="outlined"
                      // {...register("city")}
                      onChange={(e) => {
                        setCompanyDetails((prev) => ({
                          ...prev,
                          city: e.target.value,
                        }));
                      }}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Province</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    <TextField
                      id="outlined-basic"
                      value={
                        companyDetails.province ? companyDetails.province : ""
                      }
                      variant="outlined"
                      // {...register("province")}
                      onChange={(e) => {
                        setCompanyDetails((prev) => ({
                          ...prev,
                          province: e.target.value,
                        }));
                      }}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Postal Code</strong>
                </td>
                <td>
                  <Box flex="45%" width="100%">
                    <TextField
                      id="outlined-basic"
                      value={
                        companyDetails.postal_code
                          ? companyDetails.postal_code
                          : ""
                      }
                      variant="outlined"
                      // {...register("postalCode")}
                      onChange={(e) => {
                        setCompanyDetails((prev) => ({
                          ...prev,
                          postal_code: e.target.value,
                        }));
                      }}
                      fullWidth
                      disabled={true}
                    />
                  </Box>
                </td>
              </tr>

            </tbody>
          </table>
          <h1>Important Info</h1>

          {partnerStatusMessage}
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.idTH} width="20%"></th>
                <th className={classes.idTH} width="100%"></th>
                <th className={classes.idTH} width="10%"></th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              <tr>
                <td>
                  <Box sx={{ alignItems: "center" }}>
                    <strong>Admin</strong>
                  </Box>
                </td>
                <td>
                  <Box
                    style={{
                      paddingBottom: "40px",
                      paddingTop: "20px",
                    }}
                    flex="45%"
                    width="100%"
                  >
                    <Autocomplete
                      disablePortal
                      variant="outlined"
                      fullWidth
                      options={adminList}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(option) =>
                        `#${option.id || ""} ${option.administrator ? option.administrator + "," : ""} ${
                          option.firstname ? option.firstname + "," : ""
                        } ${option.lastname ? option.lastname + "," : ""} ${option.email ? option.email : ""}` || ""
                      }
                      onChange={(event, newValue) => setNewAdmin(newValue)}
                      onInputChange={async (e) => {
                        queryAdmins(e.target.value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          key={params.id}
                          {...params}
                          label={
                            admin
                              ? `Current: #${admin.id ? admin.id + "," : ""} ${
                                  admin.administrator
                                    ? admin.administrator + ","
                                    : ""
                                }
                              ${admin.firstname ? admin.firstname + "," : ""} ${
                                  admin.lastname ? admin.lastname + "," : ""
                                } ${admin.email ? admin.email + "," : ""} `
                              : "No Admin Found"
                          }
                        />
                      )}
                      filterOptions={(options, state) => options}
                      disabled={!canEditAdmin}
                    />
                  </Box>
                </td>
                <td>
                  {canEditAdmin ? (
                    <Box display="inline-flex">
                      <Button
                        style={{
                          backgroundColor: green[500],
                          marginLeft: "20px",
                          fontSize: "10px",
                          width: "100px",
                          color: "white",
                        }}
                        variant="contained"
                        onClick={() => setAdminForCompany()}
                      >
                        Save New Admin
                      </Button>
                      <Button
                        style={{
                          backgroundColor: red[500],
                          marginLeft: "20px",
                          fontSize: "10px",
                          width: "100px",
                          color: "white",
                        }}
                        variant="contained"
                        onClick={() => {
                          setCanEditAdmin(false);
                          cancelChangesAdmin();
                        }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      style={{
                        backgroundColor: blue[500],
                        marginLeft: "20px",
                        fontSize: "10px",
                        width: "100px",
                        color: "white",
                      }}
                      variant="contained"
                      onClick={() => setCanEditAdmin(true)}
                    >
                      Set Admin
                    </Button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </div>
  );
};

export default CompanyDetails;
