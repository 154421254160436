import {Grid, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        },
    }
});

export const ContainerShippingOrderDetailUser = ({orderInfo}) => {
    const classes = useStyles();

    const [ownerRole, setOwnerRole] = useState(1);
    const [userList, setUserList] = useState([]);
    const [displayUser, setDisplayUser] = useState(null);

    const handleOwnerRole = (event) => {
        setOwnerRole(event?.target?.value);
        setDisplayUser(null);
    }

    const handleChangeUser = (event, value) => {
        if (value) {
            setDisplayUser(value);
        } else {
            setDisplayUser(null);
        }
    };

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const getAllEndUserList = async () => {
        const requestURL = `${PARTNER_URI}/partner/getAllUserProfileList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    useEffect(() => {
        (async () => {
            if (orderInfo?.ownerRole) {
                setOwnerRole(orderInfo?.ownerRole);
                if (orderInfo?.ownerRole === 1) {
                    const userList = await getAllEndUserList();
                    const filterDisplayUser = userList?.filter((userInfo) => userInfo?.id === orderInfo?.userId);
                    console.log('[ContainerShippingOrderDetailUser] filterDisplayUser', filterDisplayUser);
                    setDisplayUser(filterDisplayUser[0]);
                } else {
                    const userList = await getAllPartnerList();
                    const filterDisplayUser = userList?.filter((userInfo) => userInfo?.id === orderInfo?.userId);
                    console.log('[ContainerShippingOrderDetailUser] filterDisplayUser', filterDisplayUser);
                    setDisplayUser(filterDisplayUser[0]);
                }
            }
        })();
    }, [orderInfo])

    // useEffect(() => {
    //     (async () => {
    //         if (ownerRole === 1) {
    //             await getAllEndUserList();
    //         } else {
    //             await getAllPartnerList();
    //         }
    //     })();
    // }, [ownerRole]);
    //
    // useEffect(() => {
    //     const filterDisplayUser = userList?.filter((userInfo) => userInfo?.id === orderInfo?.userId);
    //     console.log('[ContainerShippingOrderDetailUser] filterDisplayUser', filterDisplayUser);
    //     setDisplayUser(filterDisplayUser[0]);
    // }, [userList])

    return (
        <Grid item xs={8}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    User Information
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Owner Role
                            </FormLabel>
                            <Select
                                value={ownerRole}
                                size='small'
                                // placeholder='Select Partner'
                                onChange={handleOwnerRole}
                                style={{textAlign: 'left'}}
                                disabled={true}
                                // error={validation && validation?.carrierMissing}
                            >
                                <MenuItem value={1}>
                                    EndUser
                                </MenuItem>
                                <MenuItem value={3}>
                                    Partner
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                {ownerRole === 1 ? 'EndUser' : 'Partner'}
                            </FormLabel>
                            <Autocomplete
                                value={displayUser}
                                options={userList}
                                getOptionLabel={
                                    option => ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                        `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                }
                                // filterOptions={(options, state) => options}
                                fullWidth
                                renderInput={(params) => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                />}
                                // onInputChange={handleInputChangeUser}
                                onChange={handleChangeUser}
                                disabled={true} i
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '14px'}}
                                    >
                                        {
                                            ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                                `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                        }
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}