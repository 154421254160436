import React, { useState } from "react";
import { Box, makeStyles, Snackbar } from "@material-ui/core";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import axios from "axios";
import { SERVICE_SERVICE_URL } from "../../utils/apiUrl";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function TransactionInvoicesTable({ invoiceId,
                                                   accountBase,
                                                   accountNumber,
                                                   invoiceNumber,
                                                   invoiceFileURI,
                                                   grossAmount,
                                                   amount,
                                                   taxRate,
                                                   status,
                                                   invoiceDate,
                                                   pdfFileURI,
                                                   refreshList
}) {
  const classes = useStyles();

  const [toastOpen, setToastOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const getStatusText = (statusCode) => {
    if (statusCode === 0) {
      return "Initial"
    }
    return "Processed";
  }

  const processInvoiceLineData = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      const { data } = await axios.post(`${SERVICE_SERVICE_URL}/transactionAdjustments/processInvoiceLineData`, {
        invoiceId: invoiceId
      });
      console.log(data);
      handleToastClick();
      setSuccessMessage("Success!");
      await refreshList();
    } catch (e) {
      console.log(e);
      setErrorMessage(e?.response?.data?.error || "Error!");
      handleToastClick();
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Submit Transaction">
        {(() => {
          if (errorMessage !== "") {
            return (
              <Alert onClose={handleToastClose} severity="error" sx={{ width: '100%' }}>
                Error!
                <hr />
                {errorMessage}
              </Alert>
            )
          }
          return (
            <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>
              {successMessage}
            </Alert>
          )
        })()}

      </Snackbar>
      <tr className={classes.listItemWrapper}>
        <td>{invoiceId}</td>
        <td>{invoiceNumber}</td>
        <td>{accountBase}</td>
        <td>{accountNumber}</td>
        <td>
          <a href={invoiceFileURI} target="_blank" rel="noopener noreferrer">
            {invoiceFileURI ? "Download" : ""}
          </a>
        </td>
        <td>
          <a href={pdfFileURI} target="_blank" rel="noopener noreferrer">
            {pdfFileURI !== "" ? "View" : ""}
          </a>
        </td>
        <td>
          <CurrencyFormat value={grossAmount} displayType={'text'} thousandSeparator={true} prefix={`$`} />
          <br />
          <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={`$`} />
        </td>
        {/*<td>*/}
        {/*  <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={`$`} />*/}
        {/*</td>*/}
        <td>{taxRate * 100}%</td>
        <td>{getStatusText(status)}</td>
        <td>{invoiceDate}</td>
        <td>
          <Box display="flex" sx={{ gap: "3rem" }}>
            <LoadingButton
              variant="contained"
              component="span"
              color="secondary"
              loading={isLoading}
              disabled={status !== 0}
              onClick={() => {
                processInvoiceLineData()
              }}
            >
              Process Data
            </LoadingButton>
        </Box>
        </td>
      </tr>
    </>
  );
}
