import {Container, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {LTLQuoteList} from "../LTLQuoteList/LTLQuoteList";
import {LTLQuickQuote} from "../LTLQuoteList/LTLQuickQuote";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {UploadLTLFile} from "./UploadLTLFile";
import {UploadBoxFile} from "./UploadBoxFile";
import {UploadAreaPricePolicyFile} from "./UploadAreaPricePolicyFile";

const styles = {
    ShippingLTLQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    ShippingLTLQuickQuoteSection2nd: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

export const UploadFile = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isMobile = useMediaQuery('(max-width: 600px)');
    const [type, setType] = useState('ltl');

    const handleChangeType = (value) => {
        setType(value);
    }

    useEffect(() => {
        const queryObject = {};
        for (let [key, value] of searchParams.entries()) {
            queryObject[key] = value;
        }
        setType(queryObject?.type || 'ltl');
    }, [])

    console.log('[LTLQuote] searchParams', searchParams);

    return (
        <Container maxWidth="xl">
            <Box sx={styles.ShippingLTLQuickQuoteSection}>
                <Box
                    sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'ltl'}
                            onClick={() => handleChangeType('ltl')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Upload LTL Rate File
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'box'}
                            onClick={() => handleChangeType('box')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Upload Box Rate File
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'areaPricePolicy'}
                            onClick={() => handleChangeType('areaPricePolicy')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Area Price Policy File
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {
                type === 'ltl' ? <UploadLTLFile/> :
                    type === 'box' ? <UploadBoxFile/> :
                        <UploadAreaPricePolicyFile/>
            }
        </Container>
    )
}