import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import React, {Fragment, useEffect, useState} from 'react';
import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {MovingOrderRoomPlaceItemEach} from "./MovingOrderRoomPlaceItemEach";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        // height: '80vh',
        // backgroundColor: theme.palette.background.default,
    },
    menu: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        // padding: theme.spacing(1),
        padding: theme.spacing(2),
    },
    itemBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[2],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    itemText: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    itemCounter: {
        display: 'flex',
        alignItems: 'center',
    },
    MovingServiceItemRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceItemHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    selectedMenuItem: {
        display: "flex",
        justifyContent: "space-between",
        gap: "1px",
        borderLeft: `4px solid green`,
        backgroundColor: theme.palette.action.hover,
        '& .MuiTypography-root': {
            color: 'green',
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    menuItem: {
        display: "flex",
        justifyContent: "space-between",
        gap: "1px"
    },
}));

export const MovingOrderRoomPlaceItemList = ({cbHandleGetMovingFurnitureList, isContinuePlaceOrder}) => {

    const classes = useStyles();

    const [roomPlaces, setRoomPlaces] = useState([]);
    const [furnitureItemsList, setFurnitureItemsList] = useState([]);
    const [selectRoomPlaceIndex, setSelectRoomPlaceIndex] = useState(0);
    const [selectFurnitureItems, setSelectFurnitureItems] = useState([]);
    const [roomPlaceCount, setRoomPlaceCount] = useState([]);
    const [movingFurnitureList, setMovingFurnitureList] = useState([]);

    const cbAddFurnitureList = (data) => {
        setMovingFurnitureList(prevState => [
            ...prevState, data
        ])
    }

    const cbUpdateFurnitureList = (data) => {
        const updateFurnitureItem = data;
        const updateFurnitureItemList = movingFurnitureList?.map(each => {
            if (each?.cate_id === updateFurnitureItem?.cate_id &&
                each?.roomPlace === updateFurnitureItem?.roomPlace) {
                return {...updateFurnitureItem}
            }
            return each
        })
        setMovingFurnitureList(updateFurnitureItemList);
    }

    const cbRemoveFurnitureList = (data) => {
        const furnitureItemIdToFindCateId = data.cate_id;
        const furnitureItemIdToFindRoomPlace = data.roomPlace
        const filteredFurnitureItems = movingFurnitureList.filter(item => item.cate_id !== furnitureItemIdToFindCateId || item.roomPlace !== furnitureItemIdToFindRoomPlace);
        setMovingFurnitureList(filteredFurnitureItems);
    }

    useEffect(() => {
        (async () => {
            try {
                const requestURL = `${NODE_ROUTE_URI}/movingService/getMovingItemRoomPlaces`;
                const {data: roomPlacesList} = await axios.get(requestURL);
                const newRoomPlaces = [];
                const newFurnitureItemsList = [];
                // const newRoomPlaceCount = [];
                for (const roomPlaces of roomPlacesList) {
                    const {
                        roomPlace: roomPlaceName,
                        categories: categoriesList,
                        icon_uri: roomPlaceIcon
                    } = roomPlaces;
                    newRoomPlaces.push({
                        roomPlaceName,
                        iconURI: roomPlaceIcon,
                        count: 0
                    });
                    newFurnitureItemsList.push(categoriesList);
                    // newRoomPlaceCount.push(0);
                }
                setRoomPlaces(newRoomPlaces);
                setFurnitureItemsList(newFurnitureItemsList);
                // setRoomPlaceCount(newRoomPlaceCount);
            } catch (e) {
                console.log(e);
            }
        })()
    }, []);

    useEffect(() => {
        setSelectFurnitureItems(furnitureItemsList?.[selectRoomPlaceIndex] || []);

    }, [selectRoomPlaceIndex, furnitureItemsList]);

    useEffect(() => {
        if (roomPlaces?.length > 0) {
            const updatedRoomPlaces = [...roomPlaces];
            for (const existingFurniture of movingFurnitureList) {
                const {roomPlace: furnitureRoomPlaceString = "", quantity} = existingFurniture;
                if (furnitureRoomPlaceString) {
                    updatedRoomPlaces.forEach((roomEach, index) => {
                        if (roomEach?.roomPlaceName?.toLowerCase() === furnitureRoomPlaceString?.toLowerCase()) {
                            updatedRoomPlaces[index] = {
                                ...roomEach,
                                count: (roomEach.count || 0) + quantity,
                            };
                        }
                    });
                }
            }
            // Set the updated state
            setRoomPlaceCount(updatedRoomPlaces);
        }
    }, [roomPlaces, movingFurnitureList]);

    useEffect(() => {
        cbHandleGetMovingFurnitureList(movingFurnitureList);
    }, [movingFurnitureList])

    // console.log('[MovingOrderRoomPlaceItemList] selectFurnitureItems', selectFurnitureItems)
    console.log('[MovingOrderRoomPlaceItemList] movingFurnitureList', movingFurnitureList);

    return (
        <Grid item xs={12}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Furniture List
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Grid container spacing={0}>
                            {
                                roomPlaces.map((roomPlace, index) => (
                                    <Grid item xs={12} key={index}>
                                        <ListItemButton key={index}
                                                        selected={selectRoomPlaceIndex === index}
                                                        className={selectRoomPlaceIndex === index ? classes.selectedMenuItem : classes.menuItem}
                                                        sx={{
                                                            borderLeft: selectRoomPlaceIndex === index ? `4px solid green` : "",
                                                        }}
                                                        onClick={() => {
                                                            console.log(index);
                                                            setSelectRoomPlaceIndex(index);
                                                        }}
                                                        disabled={isContinuePlaceOrder}
                                        >
                                            <>
                                                <ListItemIcon>
                                                    <img src={`https://storage.googleapis.com/${roomPlace.iconURI}`}
                                                         width={25} height={25} alt="Room Places"
                                                         fill={selectRoomPlaceIndex === index ? `green` : ""}/>
                                                </ListItemIcon>
                                                <ListItemText primary={roomPlace.roomPlaceName}/>
                                            </>
                                            <Fragment key={index}>
                                                {
                                                    (() => {
                                                        if (roomPlaceCount?.[index]?.count > 0) {
                                                            return (
                                                                <div style={{
                                                                    width: 25,
                                                                    height: 25,
                                                                    borderRadius: '50%',
                                                                    backgroundColor: 'green',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            color: "white",
                                                                            fontSize: '12px'
                                                                        }}>
                                                                        {roomPlaceCount?.[index].count}
                                                                    </Typography>
                                                                </div>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </Fragment>
                                        </ListItemButton>
                                        <Divider/>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid container spacing={2}>
                            {
                                selectFurnitureItems.map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <MovingOrderRoomPlaceItemEach
                                            categoryName={item.category_name}
                                            cateId={item.cate_id}
                                            categoryDescription={item.category_desc}
                                            heightInch={item.standard_height_ins}
                                            lengthInch={item.standard_length_ins}
                                            widthInch={item.standard_width_ins}
                                            weightLB={item.standard_weight_lbs}
                                            volumeCubicInch={item.standard_volume_cubic_in}
                                            roomPlaceName={item?.room_place}
                                            roomPlace={item?.roomPlace}
                                            packageSpecialServices={item?.packageSpecialServices}
                                            movingFurnitureList={movingFurnitureList}
                                            cbAddFurnitureList={cbAddFurnitureList}
                                            cbUpdateFurnitureList={cbUpdateFurnitureList}
                                            cbRemoveFurnitureList={cbRemoveFurnitureList}
                                            isContinuePlaceOrder={isContinuePlaceOrder}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}