import React, { useState } from "react";
import {Check, Cancel} from "@material-ui/icons";
import {
  Box,
  MenuItem,
  Select,
  IconButton, 
  Input,
} from "@material-ui/core";

const statusCondition = "STATUS";
const bolNumberColumn = "BOL_NUMBER"

export default function BolListPageTableCells ({
  bolRefNumber,
  bolStatus,
  bolNumber,
  axiosCallBack,
  columnAttribute
}) {
  // Current Status Value
  const [bolRefRecordStatus, setBolRefRecordStatus] = useState(0);
  // individual status state
  const [renderMode, setRenderMode] = useState({});

  const [currentBolNumber, setCurrentBolNumber] = useState("");

  const ObtainNewStatusValue = (e) => {
    let newStatus = e.target.value;
    setBolRefRecordStatus(newStatus);
  };

  const changeCurrentBolNumber = (e) => {
    let newBolNumber = e.target.value;
    setCurrentBolNumber(newBolNumber)
    // Empty string checker
    // if (!!newBolNumber) {
    //   setCurrentBolNumber(newBolNumber);
    // }
  };

  const determineStatusValue = (status) => {
    switch(status) {
      case 1:
        return "Accepted";
      case -1:
        return "Rejected";
      default:
        return "Created"
    }
  };

  const changeIntoNewRenderMode = (bolRefNo, secondAttr, newValue) => {
    setRenderMode({...renderMode,
      [`${bolRefNo}-${secondAttr}`] : newValue
    })
  };

  const updateBofRefStatus = (bolRefNo, newStatus, currentStatus) => {
    if (axiosCallBack(bolRefNo, newStatus, currentStatus)) {
      changeIntoNewRenderMode(bolRefNo, statusCondition, false)
    }
  };
  
  const updateNewBolNumber = (bolRefNo, bolNumber) => {
    if (axiosCallBack(bolRefNo, bolNumber)) {
      changeIntoNewRenderMode(bolRefNo, bolNumberColumn, false)
    }
  };

  const renderStatusView = (currentBolRefNo, currentStatus) => {
    const status = currentStatus;
    const currentRenderMode = renderMode[`${currentBolRefNo}-${statusCondition}`];
    return (
      <div>
        {currentRenderMode ? (
        <div>
        <Box>
          <Select id="bol_ref_update_status" value={bolRefRecordStatus} onChange={e => ObtainNewStatusValue(e)}>
            <MenuItem value={0}>Created</MenuItem>
            <MenuItem value={1}>Accepted</MenuItem>
            <MenuItem value={-1}>Rejected</MenuItem>
          </Select> 
          <IconButton onClick={() => changeIntoNewRenderMode(currentBolRefNo, statusCondition, false)}><Cancel/></IconButton>
          <IconButton onClick={() => updateBofRefStatus(currentBolRefNo, bolRefRecordStatus, status)}><Check/></IconButton>
        </Box>
        </div>
        ) 
        : 
        (
          <div>
            <Box onClick={() => changeIntoNewRenderMode(currentBolRefNo, statusCondition, true)}>
              {determineStatusValue(status)}
            </Box>
          </div>
        )}
      </div>
    )
  };

  const renderBolNumberView = (currentBolRefNo, bolNumber) => {
    const currentRenderMode = renderMode[`${currentBolRefNo}-${bolNumberColumn}`];
    const bolNo = currentBolNumber[`${currentBolRefNo}-${bolNumber}`];
    return (
      <div>
        {currentRenderMode ? (
          <Box>
            <Input
              type="text"
              value={bolNo}
              onChange={(e) => changeCurrentBolNumber(e)}
            />
            <IconButton onClick={() => changeIntoNewRenderMode(currentBolRefNo, bolNumberColumn, false)}><Cancel/></IconButton>
            <IconButton onClick={() => updateNewBolNumber(currentBolRefNo, currentBolNumber)}><Check/></IconButton>
          </Box>
        ) : (
          <Box onClick={() => changeIntoNewRenderMode(currentBolRefNo, bolNumberColumn, true)}>
            {bolNumber === null ? "Not Set " : bolNumber}
          </Box>
        )}
      
      </div>
    );
  };

  return (
    <div>
      {columnAttribute ===  statusCondition ? 
        renderStatusView(bolRefNumber, bolStatus) : renderBolNumberView(bolRefNumber, bolNumber)}
    </div>
  )
};