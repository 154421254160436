import {
    Button,
    Container,
    FormHelperText,
    FormLabel,
    Grid,
    Link,
    MenuItem,
    Select,
    Snackbar,
    Typography
} from "@mui/material";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import {extractFullAddress, getMissingKeysShipFromInfo, quotePerUnit} from "../../utils/Helper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        },
    }
});

export const UploadBoxFile = () => {

    const classes = useStyles();

    const inputFile = useRef(null);
    const history = useHistory();

    const [availableService, setAvailableService] = useState([{
        account_base: 'SWYFT'
    }]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [selectCarrier, setSelectCarrier] = useState('default');
    const [validation, setValidation] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [fileObj, setFileObj] = useState(null);
    const [contactFileObj, setContactFileObj] = useState(null);
    const [theInputKey, setTheInputKey] = useState(null);
    const [uploadFileLoading, setUploadFileLoading] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleChangeCarrier = (event) => {
        console.log(event.target.value);
        setSelectCarrier(event.target.value);
    };

    const onDrop = (_type) => (e) => {
        if (!(e.target.files && e.target.files[0])) return;
        if (_type === "bol") {
            setFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
        } else {
            setContactFileObj({
                selectedFile: e.target.files[0],
                //**** added line below ****//
                selectedFileName: e.target.files[0].name,
            });
            setFileName(e.target.files[0].name);
        }
    };

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        (selectCarrier === 'default' || !selectCarrier) && setErrorMessage(prevState => prevState + 'Carrier is missing.');
        (!fileObj && !contactFileObj) && setErrorMessage(prevState => prevState + 'Box Rate file is missing.');

        const validation = {
            carrierMissing: selectCarrier === 'default' || !selectCarrier,
            ltlRateFileMissing: !fileObj && !contactFileObj
        }

        setValidation(validation);

        if (
            selectCarrier !== 'default' &&
            selectCarrier &&
            (fileObj || contactFileObj)
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const uploadLTLFile = async () => {
        setUploadFileLoading(true);
        const bodyFormData = new FormData();
        const tmpFileObj = fileObj || contactFileObj;
        const requestURL = `${NODE_ROUTE_URI}/shippingBoxForAdmin/uploadBoxRateFile`;

        try {
            bodyFormData.append("file", tmpFileObj.selectedFile);
            bodyFormData.append("accountBase", selectCarrier);

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: bodyFormData
            })
            setErrorMessage('');
            setSuccessMessage('Successfully upload the box rate file.');
            handleSuccessMessage();
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('Fail to upload the box rate file.');
            handleErrorMessage();
        } finally {
            setUploadFileLoading(false);
        }
    }


    const handleConfirmUploadRateFile = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            uploadLTLFile();
        }
    }

    useEffect(() => {
        // Set a timeout to show a message if loading takes more than 5 seconds
        const maxLoadingTime = 5000; // 5 seconds in milliseconds
        setTimeout(() => {
            uploadFileLoading ? setMessageLoading(true) : setMessageLoading(false);
        }, maxLoadingTime);
    }, [uploadFileLoading])

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Upload Box Rate File
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Select Carrier
                                    </FormLabel>
                                    <Select
                                        value={selectCarrier}
                                        size='small'
                                        // placeholder='Select Partner'
                                        onChange={handleChangeCarrier}
                                        // disabled={isConfirm}
                                        error={validation && validation?.carrierMissing}
                                        style={{textAlign: 'left'}}
                                    >
                                        <MenuItem disabled value='default'>
                                            <em>Select Carrier</em>
                                        </MenuItem>
                                        {
                                            availableService?.map((carrier, index) => (
                                                <MenuItem key={index} value={carrier?.account_base}>
                                                    {carrier?.account_base}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                    <FormHelperText
                                        sx={{
                                            color: "error.main",
                                        }}
                                    >
                                        {(validation && validation?.carrierMissing) && 'Required field'}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start'
                            }}>
                                <FormControl fullWidth>
                                    <FormLabel required sx={{textAlign: 'left'}}>
                                        Rate File
                                    </FormLabel>
                                    <TextField
                                        value={fileName}
                                        fullWidth
                                        disabled={true}
                                        size='small'
                                        error={validation && validation?.ltlRateFileMissing}
                                        helperText={(validation && validation?.ltlRateFileMissing) && 'Required field'}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                                height: '100%'
                            }}>
                                <input
                                    accept=".xlsx, .xls"
                                    style={{display: "none"}}
                                    ref={inputFile}
                                    multiple
                                    type="file"
                                    // onChange={()={}}
                                    onChange={onDrop("contacts")}
                                    key={theInputKey || ""}
                                />
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#454545',
                                        "&:hover": {
                                            backgroundColor: '#454545',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={() => {
                                        inputFile.current.click();
                                    }}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Upload
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-end',
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleConfirmUploadRateFile}
                                    loading={uploadFileLoading}
                                >
                                    <Typography style={{textTransform: 'none'}}>
                                        Confirm
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        {
                            messageLoading &&
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CircularProgress/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{fontSize: '16px'}}>
                                        Your file is currently being processed. You can wait here to receive the results or
                                        click the following button to go to the home page.
                                    </Typography>
                                    <Grid item xs={12}>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={() => history.push('/v2')}
                                        >
                                            <Typography style={{textTransform: 'none'}}>
                                                Home Page
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}