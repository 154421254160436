import {Button, Container, Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {LTLOrderDashboardSelectPartner} from "./LTLOrderDashboardSelectPartner";
import {LTLOrderDashboardSelectCarrier} from "./LTLOrderDashboardSelectCarrier";
import {LTLOrderDashboardBOLAndPOD} from "./LTLOrderDashboardBOLAndPOD";
import {LTLOrderDashboardOrderStatus} from "./LTLOrderDashboardOrderStatus";
import {LTLOrderDashboardInvoice} from "./LTLOrderDashboardInvoice";
import {LTLOrderDashboardShipper} from "./LTLOrderDashboardShipper";
import {LTLOrderDashboardConsignee} from "./LTLOrderDashboardConsignee";
import {LTLOrderDashboardPallet} from "./LTLOrderDashboardPallet";
import {LTLOrderDashboardSpecialService} from "./LTLOrderDashboardSpecialService";
import {LTLOrderDashboardShippingCost} from "./LTLOrderDashboardShippingCost";
import {LTLOrderListPickupDateAndTime} from "../LTLOrderList/LTLOrderListPickupDateAndTime";
import {LTLOrderDashboardPickupDateAndTime} from "./LTLOrderDashboardPickupDateAndTime";
import LoadingButton from "@mui/lab/LoadingButton";
import {
    getMissingKeyPickupDateAndTime,
    getMissingKeysConsigneeInfo,
    getMissingKeysShipperInfo
} from "../../utils/Helper";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../utils/constInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import {LTLOrderDashboardPOD} from "./LTLOrderDashboardPOD";
import {LTLOrderDashboardCrossBorder} from "./LTLOrderDashboardCrossBorder";
import {LTLOrderDashboardReferenceOrderNumber} from "./LTLOrderDashboardReferenceOrderNumber";
import {LTLOrderDashboardPurchaseOrderNumber} from "./LTLOrderDashboardPurchaseOrderNumber";
import {LTLOrderDashboardClone} from "./LTLOrderDashboardClone";
import {LTLOrderDashboardInsurance} from "./LTLOrderDashboardInsurance";
import {LTLOrderDashboardBrokerage} from "./LTLOrderDashboardBrokerage";
import TextField from "@mui/material/TextField";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const requiredProperties = ["description", "length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight", "quantity"];

export const LTLOrderDashboard = () => {

    const history = useHistory();

    const {orderId} = useParams();

    const [orderData, setOrderData] = useState();

    const [shipperInfo, setShipperInfo] = useState();
    const [consigneeInfo, setConsigneeInfo] = useState();
    const [palletList, setPalletList] = useState();
    const [shippingCost, setShippingCost] = useState();
    const [selectCarrier, setSelectCarrier] = useState();
    const [shippingDateAndTime, setShippingDateAndTime] = useState();
    const [specialService, setSpecialService] = useState();
    const [partner, setPartner] = useState();
    const [crmInvoiceNumber, setCrmInvoiceNumber] = useState();
    const [crmInvoiceId, setCrmInvoiceId] = useState();
    const [orderStatus, setOrderStatus] = useState();
    const [BOLAndPODInfo, setBOLAndPODInfo] = useState();
    const [deleteList, setDeleteList] = useState();
    const [isGenerateInvoice, setIsGenerateInvoice] = useState();
    const [loading, setLoading] = useState(false);

    const [isConfirm, setIsConfirm] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [validation, setValidation] = useState(null);

    const [triggerAction, setTriggerAction] = useState(false);
    const [selectPartner, setSelectedPartner] = useState(null);
    const [BOLNumber, setBOLNumber] = useState('');
    const [shipperResidential, setShipperResidential] = useState(null);
    const [consigneeResidential, setConsigneeResidential] = useState(null);

    const [shippingCostDetail, setShippingCostDetail] = useState(null);
    const [shippingRequest, setShippingRequest] = useState(false);
    const [isClone, setIsClone] = useState(false);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getPartnerInfo = (data) => {
        console.log('partner info', data);
        setSelectedPartner(data);
    }

    const getBOLNumber = (data) => {
        setBOLNumber(data);
    }

    const getCrmInvoiceNumber = (data) => {
        setCrmInvoiceNumber(data);
    }

    const getShipperResidential = (data) => {
        console.log('shipper residential', data?.shipperInfo);
        setShipperResidential(data?.shipperInfo);
    }

    const getConsigneeResidential = (data) => {
        console.log('consignee residential', data?.consigneeInfo);
        setConsigneeResidential(data?.consigneeInfo);
    }

    const getShippingCostRequest = (data) => {
        setShippingRequest(data);
    }

    const getShippingCostData = (data) => {
        setShippingCostDetail(data);
    }

    const handleEdit = () => {
        setIsConfirm(false);
    }

    const getOrderDetails = async () => {
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/getLTLOrderDetailsByOrderId`;

            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    orderId: orderId
                }
            })
            console.log('place order', result);
            setOrderData(result?.data);

        } catch (e) {
            console.log('error', e.response);
        }
    }

    const handleButtonClick = () => {
        // Set the state to trigger the action in the child component
        setTriggerAction(true);
    };
    const getDataFromChildComponent = (data) => {
        console.log('child data', data);
        handleDataFromChildComponent(data);
        setTriggerAction(false);
        setIsConfirm(true);
    }

    const validateInvoiceNumber = async () => {
        if (crmInvoiceNumber) {
            await getInvoiceInfoByInvoiceId();
        }
    }
    const getInvoiceInfoByInvoiceId = async () => {
        let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/getInvoiceInfoByRefNumber`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    invoiceRefNumber: crmInvoiceNumber
                }
            })
            setCrmInvoiceNumber(result?.data?.id);
            return false;
        } catch (e) {
            console.log('error', e.response);
            return true;
        }
    }

    const handleDataFromChildComponent = (data) => {
        const key = Object.keys(data);
        switch (key[0]) {
            case 'shipperInfo':
                setShipperInfo(data?.shipperInfo);
                break
            case 'consigneeInfo':
                setConsigneeInfo(data?.consigneeInfo);
                break
            case 'palletList':
                setPalletList(data?.palletList);
                break
            case 'selectCarrier':
                setSelectCarrier(data?.selectCarrier);
                break
            case 'shippingCost':
                setShippingCost(data?.shippingCost);
                break
            case 'specialService':
                setSpecialService(data?.specialService);
                break
            case 'shipDateAndTime':
                setShippingDateAndTime(data?.shipDateAndTime);
                break
            case 'partner':
                setPartner(data?.partner);
                break
            case 'crmInvoiceNumber':
                setCrmInvoiceNumber(data?.crmInvoiceNumber);
                break
            case 'orderStatus':
                setOrderStatus(data?.orderStatus);
                break
            case 'BOLAndPODInfo':
                setBOLAndPODInfo(data?.BOLAndPODInfo);
                break
            case 'deleteList':
                setDeleteList(data?.deleteList);
                break
            case 'crmInvoiceId':
                setCrmInvoiceId(data?.crmInvoiceId);
                break
            case 'isGenerateInvoice':
                setIsGenerateInvoice(data?.isGenerateInvoice);
            default:
        }
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysShipperInfo(shipperInfo);
        const shipToMissing = getMissingKeysConsigneeInfo(consigneeInfo);
        const hasMissingValues = palletList?.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = palletList?.some(object => validateRequiredProperties.some((property) => object[property] <= 0));
        const shipDateAndTimeMissing = getMissingKeyPickupDateAndTime(shippingDateAndTime);

        const validation = {
            shipFromMissing,
            shipToMissing,
            hasMissingValues,
            hasValidateValues,
            partnerMissing: partner === 'default' || !partner,
            carrierMissing: selectCarrier === 'default' || !selectCarrier,
            palletListMissing: palletList?.length === 0,
            shipDateAndTimeMissing,
        }
        console.log('validation', validation);
        setValidation(validation);
        // dispatch(updateCreateLabelValidation(validation));

        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Shipper information is missing one or more required fields.');
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Consignee information is missing one or more required fields.');
        (partner === 'default' || !partner) && setErrorMessage(prevState => prevState + 'Partner is missing.');
        (selectCarrier === 'default' || !selectCarrier) && setErrorMessage(prevState => prevState + 'Carrier is missing.');
        hasMissingValues && setErrorMessage(prevState => prevState + 'You pallet list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + 'You pallet list has one or more invalid fields.');
        palletList?.length === 0 && setErrorMessage(prevState => prevState + 'Pallet list is empty.');
        shipDateAndTimeMissing?.length !== 0 && setErrorMessage(prevState => prevState + 'Pickup date or time is missing one or more required fields.');

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            partner !== 'default' &&
            partner &&
            selectCarrier !== 'default' &&
            selectCarrier &&
            palletList.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            shipDateAndTimeMissing.length === 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderId,
                    orderStatus: orderStatus,
                    crmInvoiceNumber: crmInvoiceId || "",
                    partnerId: partner,
                    packageData: palletList,
                    deletePackageData: deleteList,
                    shipperName: shipperInfo?.shipperName,
                    shipperPhone: shipperInfo?.shipperPhone,
                    shipperCompanyName: shipperInfo?.shipperCompanyName,
                    shipperEmail: shipperInfo?.shipperEmail,
                    shipperAddress: shipperInfo?.shipperAddress,
                    shipperAddressType: specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
                    shipperCity: shipperInfo?.shipperCity,
                    shipperProvince: shipperInfo?.shipperProvince,
                    shipperPostalCode: shipperInfo?.shipperPostalCode,
                    shipperCountry: shipperInfo?.shipperCountry,
                    consigneeName: consigneeInfo?.consigneeName,
                    consigneePhone: consigneeInfo?.consigneePhone,
                    consigneeCompanyName: consigneeInfo?.consigneeCompanyName,
                    consigneeEmail: consigneeInfo?.consigneeEmail,
                    consigneeAddress: consigneeInfo?.consigneeAddress,
                    consigneeAddressType: specialService?.some(service => service.serviceCode === SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE) ? "RESIDENTIAL" : "WAREHOUSE",
                    consigneeCity: consigneeInfo?.consigneeCity,
                    consigneeProvince: consigneeInfo?.consigneeProvince,
                    consigneePostalCode: consigneeInfo?.consigneePostalCode,
                    consigneeCountry: consigneeInfo?.consigneeCountry,
                    serviceName: selectCarrier,
                    shipDate: shippingDateAndTime?.shipDate,
                    pickupLocationCloseTime: `${shippingDateAndTime?.shipDate} ${shippingDateAndTime?.latestPickupTime}`,
                    pickupLocationOpenTime: `${shippingDateAndTime?.shipDate} ${shippingDateAndTime?.earliestPickupTime}`,
                    pickupAvailableTime: `${shippingDateAndTime?.shipDate} ${shippingDateAndTime?.availablePickupTime}`,
                    pickupDate: shippingDateAndTime?.shipDate,
                    transMode: "INT_TRUCK",
                    shipmentType: "COMMERCIAL",
                    specialServices: specialService,
                    refNumber: "REF NUMBER",
                    shippingFee: (+shippingCost?.shippingFee),
                    serviceFee: (+shippingCost?.serviceFee),
                    taxCharge: (+shippingCost?.taxCharge),
                    totalCharge: (+shippingCost?.totalCharge),
                    discount: (+shippingCost?.discount),
                    finalTotal: (+shippingCost?.finalTotal),
                    bolNumber: BOLAndPODInfo?.bolNumber || "",
                    bolDocURI: BOLAndPODInfo?.bolDocURI || "",
                    podDocURI: BOLAndPODInfo?.podDocURI || "",
                    generateInvoice: isGenerateInvoice
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            setTimeout(() => {
                history.push("/v2/ltl-order-list");
            }, 2000);

        } catch (e) {
            console.log(e.response);
            setSuccessMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        const validationResult = validate();
        if (validationResult) {
            console.log('call api');
            updateBackendOrder();
        }
    }

    const handleBack = () => {
        history.push('/v2/ltl-order-list')
    }

    const handleClone = () => {
        setIsClone(true);
    }

    const cancelClone = () => {
        setIsClone(false);
    }

    useEffect(() => {
        getOrderDetails();
    }, []);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Edit LTL Order
                </Typography>
                {/*{*/}
                {/*    orderData ?*/}
                <Box sx={styles.ShippingOrderContent}>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Order Id
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={orderId}
                                            variant='outlined'
                                            fullWidth
                                            size='small'
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Partner
                                        </Typography>
                                    </Grid>
                                    <LTLOrderDashboardSelectPartner
                                        orderData={orderData}
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                        validation={validation}
                                        getPartnerInfo={getPartnerInfo}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Carrier
                                        </Typography>
                                    </Grid>
                                    <LTLOrderDashboardSelectCarrier
                                        orderData={orderData}
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                        validation={validation}
                                        getOrderDetails={getOrderDetails}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Order Status
                                        </Typography>
                                    </Grid>
                                    <LTLOrderDashboardOrderStatus
                                        orderData={orderData}
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                        validation={validation}
                                        BOLNumber={BOLNumber}
                                        getOrderDetails={getOrderDetails}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    BOL
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardBOLAndPOD
                                orderData={orderData}
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                getBOLNumber={getBOLNumber}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    POD
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardPOD
                                orderData={orderData}
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                getBOLNumber={getBOLNumber}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Shipper Information
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardShipper
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                orderData={orderData}
                                selectPartner={selectPartner}
                                getShipperResidential={getShipperResidential}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Consignee Information
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardConsignee
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                orderData={orderData}
                                getConsigneeResidential={getConsigneeResidential}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Pallet List
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardPallet
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                orderData={orderData}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Special Service
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardSpecialService
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                orderData={orderData}
                                shipperResidential={shipperResidential}
                                consigneeResidential={consigneeResidential}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Insurance
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardInsurance
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                orderData={orderData}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Custom Brokerage and Duty Tax
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardBrokerage
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                orderData={orderData}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Shipping Cost
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardShippingCost
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                orderData={orderData}
                                crmInvoiceNumber={crmInvoiceNumber}
                                getOrderDetails={getOrderDetails}
                                shippingRequest={shippingRequest}
                                getShippingCostData={getShippingCostData}
                            />
                        </Grid>
                    </Box>
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                    Pickup Date and Time
                                </Typography>
                            </Grid>
                            <LTLOrderDashboardPickupDateAndTime
                                getDataFromChildComponent={getDataFromChildComponent}
                                triggerAction={triggerAction}
                                isConfirm={isConfirm}
                                validation={validation}
                                orderData={orderData}
                                getOrderDetails={getOrderDetails}
                            />
                        </Grid>
                    </Box>
                    {
                        orderData?.bolInfo?.consignee_country !== orderData?.bolInfo?.shipper_country &&
                        <Box sx={styles.ShippingOrderList}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                        Cross Border
                                    </Typography>
                                </Grid>
                                <LTLOrderDashboardCrossBorder orderData={orderData} getOrderDetails={getOrderDetails}
                                />
                            </Grid>
                        </Box>
                    }
                    <Box sx={styles.ShippingOrderList}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Purchase Order Number
                                        </Typography>
                                    </Grid>
                                    <LTLOrderDashboardPurchaseOrderNumber
                                        orderData={orderData}
                                        getOrderDetails={getOrderDetails}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            Reference Order Number
                                        </Typography>
                                    </Grid>
                                    <LTLOrderDashboardReferenceOrderNumber
                                        orderData={orderData}
                                        getOrderDetails={getOrderDetails}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                                            CRM Invoice Number
                                        </Typography>
                                    </Grid>
                                    <LTLOrderDashboardInvoice
                                        orderData={orderData}
                                        getDataFromChildComponent={getDataFromChildComponent}
                                        triggerAction={triggerAction}
                                        isConfirm={isConfirm}
                                        validation={validation}
                                        getCrmInvoiceNumber={getCrmInvoiceNumber}
                                        getOrderDetails={getOrderDetails}
                                        getShippingCostRequest={getShippingCostRequest}
                                        shippingCostDetail={shippingCostDetail}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleBack}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Back To LTL Order List
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleClone}
                                    disabled={isClone}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Clone LTL Order
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {
                        isClone && <LTLOrderDashboardClone orderData={orderData} cancelClone={cancelClone}/>
                    }
                </Box>
            </Box>
        </Container>
    )
}