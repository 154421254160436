import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import OrderPaymentListItem from "./OrderPaymentListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const OrderPaymentTable = ({ orderList }) => {
  const classes = useStyles();
  return (
    <div>
      <Box maxWidth="70%" margin="0 auto">
        <Box mt={2}>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.idTH}>ID</th>
                <th className={classes.idTH}>referenceOrderNo</th>
                <th className={classes.idTH}>uucOrderId</th>
                <th className={classes.idTH}>accountBase</th>
                <th className={classes.idTH}>accountName</th>
                <th className={classes.idTH}>orderStatus</th>
                <th className={classes.nameTH}></th>
              </tr>
            </thead>
            <tbody className={classes.tbody}>
              {orderList.map((v, index) => {
                return (
                  <OrderPaymentListItem
                  key={index}
                  account_base={v.account_base}
                  send_from_email={v.send_from_email}
                  send_from_name={v.send_from_name}
                  send_from_postal_code={v.send_from_postal_code}
                  order_id={v.order_id}
                  cargo_control_no={v.cargo_control_no}
                  send_from_tel={v.send_from_tel}
                  ref_order_no={v.ref_order_no}
                  send_from_addr={v.send_from_addr}
                  send_from_city={v.send_from_city}
                  send_from_province={v.send_from_province}
                  total_amount={v.total_amount || 0}
                  origi_shipping_item_file={v.origi_shipping_item_file}
                  currency_code={v.currency_code}
                  date={v.created_at}
                  />
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </div>
  );
};

export default OrderPaymentTable;