import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const style = {
    PackageOrderTransactionAccountRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        background: '#FFFFFF'
    }
}

export const PackageOrderTransactionAccount = ({orderData, orderInfo}) => {

    return (
        <Box sx={style.PackageOrderTransactionAccountRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            User Information
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            Owner Role:
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {+orderInfo?.owner_role === 1 ? 'Enduser' : 'Partner'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {+orderInfo?.owner_role === 1 ? 'User Id:' : 'Partner Id:'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {orderInfo?.partner_id}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography sx={{textAlign: 'left', wordBreak: "break-word"}}>
                            {+orderInfo?.owner_role === 1 ? 'User Name:' : 'Company Name:'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography sx={{textAlign: 'left', wordBreak: "break-word"}}>
                            {+orderInfo?.owner_role === 1 ? `${orderInfo?.firstname} ${orderInfo?.lastname}` : orderInfo?.business_name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {+orderInfo?.owner_role === 1 ? 'User Email:' : 'Company Email:'}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography sx={{textAlign: 'left', wordBreak: "break-word"}}>
                            {+orderInfo?.owner_role === 1 ? `${orderInfo?.user_email}` : orderInfo?.company_email}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}