import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../utils/apiUrl";
import {Fragment, useEffect, useState} from "react";
import {getAccessToken} from "../../utils/doToken";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Button, Divider} from "@mui/material";
import {saveAs} from 'file-saver';

const style = {
    PackageOrderCrossBorderRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        background: '#FFFFFF'
    }
}

export const PackageOrderCrossBorder = ({orderInfo}) => {

    const accessToken = getAccessToken("access_token");

    const [fileList, setFileList] = useState([]);
    const [trackingList, setTrackingList] = useState([]);
    const [commercialInvoiceStatus, setCommercialInvoiceStatus] = useState(false);

    const getTrackingList = async () => {
        let requestURI = `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/admin/getTrackingViewByShippingOrderId/${orderInfo?.order_id}`;
        try {
            const results = await axios({
                method: 'get',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                url: requestURI,
            })

            const {data} = results;

            console.log('[getTrackingList] data', data);

            const invoiceStatus = data.every(element => element?.doc_status === 2);

            setCommercialInvoiceStatus(invoiceStatus);

            const updatedTrackingList = [];

            const updatedFileList = []

            data.forEach(element => {
                let object = {
                    trackingNumber: element?.tracking_no,
                    tranId: element?.tran_id,
                    shippingIdNumber: element?.shipping_id_no,
                    description: element?.item_name,
                    unitPrice: element?.unit_price,
                    quantity: 1,
                    UOM: element?.uom,
                    weight: element?.weight,
                    hsCode: element?.hs_code,
                    originalCountry: element?.origi_country,
                    currency: element?.currency_code,
                    weightUnit: element?.weight_unit,
                    length: element?.length,
                    height: element?.height,
                    width: element?.width,
                    dimensionUnit: element?.dimension_unit,
                    documentStatus: element?.doc_status,
                }
                updatedTrackingList.push(object);
            });

            data.forEach(element => {
                let object = {
                    fileURL: element?.file_uri,
                    fileName: element?.file_name
                }
                updatedFileList.push(object);
            });

            const distinctFileURLs = [
                ...new Map(updatedFileList.filter(item => item.fileURL !== null).map(item => [item.fileURL, item])).values()
            ];

            setTrackingList(updatedTrackingList);
            setFileList(distinctFileURLs);

        } catch (e) {
            console.log(e)
        }
    }

    const handleDownUploadedFile = (file) => {
        const fileURL = `${LABEL_DOWNLOAD_URL_PREFIX}${file.fileURL}`;
        // const fileURL = 'https://storage.googleapis.com/uucargo-resource/tmp/upload/shipping/1714-shipping-package-list-sample_13_.xlsx';
        saveAs(fileURL, file.fileName);
    }

    useEffect(() => {
        if (orderInfo) {
            getTrackingList();
        }
    }, [orderInfo])

    // console.log('[PackageOrderCrossBorder] order info', orderInfo);
    // console.log('[PackageOrderCrossBorder] trackingList', trackingList);
    // console.log('[PackageOrderCrossBorder] commercialInvoiceStatus', commercialInvoiceStatus);
    console.log('[PackageOrderCrossBorder] fileList', fileList);

    return (
        <Box sx={style.PackageOrderCrossBorderRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Cross Border Information
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        {
                            commercialInvoiceStatus ?
                                <Typography>
                                    All commercial invoices submitted successfully.
                                </Typography>
                                :
                                <Typography>
                                    Not all commercial invoices have been submitted.
                                </Typography>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Typography sx={{fontWeight: '600'}}>
                            Submitted Commercial Invoices
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '0 10px',
                    }}>
                        <Grid container spacing={4}>
                            {
                                fileList?.map((element, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={7}>
                                            <Typography sx={{fontSize: '14px', wordBreak: 'break-word'}}>
                                                {element?.fileName}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Button
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #1D8B45',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={() => handleDownUploadedFile(element)}
                                            >
                                                <Typography style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    color: '#1D8B45',
                                                }}>
                                                    View
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Fragment>
                                ))
                            }
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )

}