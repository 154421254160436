import {convertCMToIN, convertINToCM, convertKGToLB, convertLBToKG} from "../../utils/Helper";
import {FormLabel, Grid, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormalWithUnit, CustomTextFieldPallet} from "../../utils/customComponent";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEffect, useState} from "react";

const palletTypeDefault = [
    {name: '48"x40"', length: '48', width: '40', type: 1},
    {name: '48"x48"', length: '48', width: '48', type: 2},
    {name: 'Custom', length: null, width: null, type: 3}
]

const palletTypeMetric = [
    {name: '122cmx102cm', length: '122', width: '102', type: 1},
    {name: '122cmx122cm', length: '122', width: '122', type: 2},
    {name: 'Custom', length: null, width: null, type: 3}
]


export const LTLQuickQuotePalletEach = ({pallet, handleUpdatePallet, handleDeletePallet, metric}) => {

    const [palletInfo, setPalletInfo] = useState();
    const [palletTypeList, setPalletTypeList] = useState([]);
    const [palletType, setPalletType] = useState({name: '48"x40"', length: '48', width: '40', type: 1});

    const onChange = (field, value) => {
        switch (field) {
            case 'description':
                setPalletInfo(prevState => ({
                    ...prevState,
                    description: value
                }))
                break;
            case 'length':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        length: value
                    }))
                }
                break;
            case 'width':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        width: value
                    }))
                }
                break;
            case 'height':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        height: value
                    }))
                }
                break;
            case 'weight':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        weight: value
                    }))
                }
                break;
            case 'quantity':
                if (/^\d*\.?\d*$/.test(value)) {
                    setPalletInfo(prevState => ({
                        ...prevState,
                        quantity: value
                    }))
                }
                break;
            default:
        }
    }

    const handleChangePalletType = (event) => {
        const pallet = event?.target?.value
        setPalletType(pallet);
        if (pallet?.name !== 'Custom') {
            setPalletInfo(prevState => ({
                ...prevState,
                length: pallet?.length,
                width: pallet?.width
            }))
        }
    }

    useEffect(() => {
        setPalletInfo(pallet);
    }, [pallet?.palletId]);

    useEffect(() => {
        handleUpdatePallet(palletInfo);
    }, [palletInfo]);

    useEffect(() => {
        if (metric) {
            setPalletInfo(prevState => ({
                ...prevState,
                lengthUnit: 'cm',
                weightUnit: 'kg',
                weight: convertLBToKG(pallet?.weight),
                length: convertINToCM(pallet?.length),
                width: convertINToCM(pallet?.width),
                height: convertINToCM(pallet?.height),
            }))
            setPalletType(palletTypeMetric[palletType?.type - 1]);
            setPalletTypeList(palletTypeMetric);
        } else {
            setPalletInfo(prevState => ({
                ...prevState,
                lengthUnit: 'in',
                weightUnit: 'lb',
                weight: convertKGToLB(pallet?.weight),
                length: convertCMToIN(pallet?.length),
                width: convertCMToIN(pallet?.width),
                height: convertCMToIN(pallet?.height),
            }))
            setPalletType(palletTypeDefault[palletType?.type - 1]);
            setPalletTypeList(palletTypeDefault)
        }
    }, [metric])

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Pallet Type
                    </FormLabel>
                    <Select
                        value={palletType}
                        size='small'
                        onChange={handleChangePalletType}
                    >
                        {
                            palletTypeList?.map((each, index) => (
                                <MenuItem key={index} value={each}>
                                    {each?.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Unit Length
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={pallet?.length}
                        field='length'
                        onChange={onChange}
                        unit={pallet?.lengthUnit}
                        isConfirm={palletType?.type !== 3}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Unit Width
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={pallet?.width}
                        field='width'
                        onChange={onChange}
                        unit={pallet?.lengthUnit}
                        isConfirm={palletType?.type !== 3}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Unit Height
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={pallet?.height}
                        field='height'
                        onChange={onChange}
                        unit={pallet?.lengthUnit}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Unit Weight
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={pallet?.weight}
                        field='weight'
                        onChange={onChange}
                        unit={pallet?.weightUnit}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Quantity
                    </FormLabel>
                    <CustomTextFieldNormalWithUnit
                        textValue={pallet?.quantity}
                        field='quantity'
                        onChange={onChange}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <IconButton
                        onClick={() => handleDeletePallet(pallet)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}