import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Snackbar,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { useSelector } from "react-redux";
import CompanyListTable from "./CompanyListTable";
import { SERVICE_DAO_URL, NODE_ROUTE_URI, PARTNER_URI } from "../../utils/apiUrl";
import { Link } from "react-router-dom";
import BackArrow from "../BackArrow";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  buttonFilesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
    wordBreak: "break-word",
  },
  hscodeListWrapper: {
    border: "2px solid lightgray",
    borderRadius: "5px",
    width: "98%",
    maxHeight: "45vh",
    height: "fit-content",
    margin: "10px auto",
    overflowY: "auto",
    background: "#fff",
  },
  table: {
    width: "100%",
  },
  unTrackingTable: {
    "border-spacing": "2rem 1rem",
  },
  tbody: {
    "& tr:nth-child(odd)": {
      backgroundColor: "rgba(222, 222, 222, 0.4)",
    },
  },
  nameTH: {
    width: "100px",
  },
  idTH: {
    width: "100px",
    "white-space": "nowrap",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
  },
}));

const CompanyList = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:600px)");

  // const partnerInfo = getPartnerInfo();
  const partnerInfo = useSelector((state) => state.user);

  const { register, handleSubmit, control } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [companyList, setCompanyList] = useState([]);

  useEffect(() => {
    const getCompanies = async () => {
      const companies = await fetch(
        PARTNER_URI + "/companies/getAllCompanyList"
      );
      const result = await companies.json();
      setCompanyList(result);
    };
    getCompanies();
  }, []);

  const onSubmit = async (data) => {
    // Empty keys replaced with undefined
    Object.keys(data).map((key) => {
      if (data[key] === "") {
        data[key] = undefined;
      }
    });
    try {
      const result = await axios.get(
        PARTNER_URI + "/companies/searchCompanyList",
        { params: {...data} }
      );
      if (result.status === 200) {
        const newCompanyList = await result.data;
        setCompanyList(newCompanyList)
      } else {
        throw new Error("Could not retrieve Company List")
      }
    } catch (e) {
      return
    }
  };

  const [toastOpen, setToastOpen] = useState(false);
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  return (
    <Box paddingBottom="3rem">
      <BackArrow link={"/"} />
      {/*Pop-up Toast*/}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Submit Transaction"
      >
        {(() => {
          if (errorMessage !== "") {
            return (
              <Alert
                onClose={handleToastClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Error!
                <hr />
                {errorMessage}
              </Alert>
            );
          }
          return (
            <Alert
              onClose={handleToastClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          );
        })()}
      </Snackbar>
      <Box>
        <Box
          component="form"
          width={"1000px"}
          margin="0 auto"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            gap: "2rem",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <h1>Company List</h1>
          <Link className={classes.link} to={`/v2/companyadd`}>
            <Button color="secondary" variant="outlined">
              Add Company
            </Button>
          </Link>
          <Box flex="45%" width="100%">
            <TextField
              id="outlined-basic"
              label="Search Company"
              variant="outlined"
              {...register("companyName")}
              fullWidth
            />
          </Box>
          <Box flex="45%" width="100%">
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              type="email"
              {...register("email")}
              fullWidth
            />
          </Box>
          <Box flex="45%" width="100%">
            <TextField
              id="outlined-basic"
              label="Phone"
              variant="outlined"
              type="phone"
              {...register("phone")}
              fullWidth
            />
          </Box>

          <Box mt={2} mb={4}>
            {errorMessage !== "" && (
              <Alert severity="error">{errorMessage}</Alert>
            )}
          </Box>
          <Box display="flex" sx={{ gap: "3rem" }}>
            <LoadingButton
              variant="contained"
              component="span"
              color="primary"
              style={{
                textTransform: "unset",
                fontSize: "1rem",
                width: "7rem",
              }}
              loading={isLoading}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              Search
            </LoadingButton>
          </Box>
        </Box>
        <hr style={{ marginBlock: "3rem" }} />
        {isLoading ? (
          <>
            <Box>
              <h1>Loading...</h1>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box>
                <Box mt={2}>
                  <CompanyListTable companyList={companyList} />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default CompanyList;
