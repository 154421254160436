import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {useEffect, useState} from "react";
import UploadIcon from "@mui/icons-material/Upload";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    MovingServicePhotosAndDetailsSection: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    MovingServiceAddressHeading: {
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: '20px'
    },
    MovingServiceImageRoot: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
    },

    MovingServiceImageHeading: {
        fontSize: '20px',
        fontWeight: '600'
    },
    MovingServiceImageModalBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        backgroundColor: '#FFFFFF',
    },
    MovingServiceImageBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'auto',
    },
    MovingServiceImageItem: {
        maxWidth: '100%',
        maxHeight: '400px'
    },
    MovingServiceImageUploadSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '10px'
    },
    MovingServiceImageWarning: {
        color: 'red',
        fontSize: '12px'
    },
    MovingServiceImageEach: {
        objectFit: 'contain',
        width: '200px',
        height: '200px',
    },
    MovingServiceImageList: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: '15px',
        width: '100%'
    },
    MovingServiceImageCard: {
        borderRadius: '10px',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)',
        padding: '10px',
        "&:hover": {
            cursor: 'pointer',
            transform: 'scale(1.05)',
            transition: '0.5s ease-in-out'
        },
    },
    MovingServiceImageSection: {
        display: 'flex',
        flexDirection: 'column',
        width: '220px',
        position: 'relative'
    },
    MovingServiceImageDeleteImage: {
        position: 'absolute',
        right: '0',
        top: '0'
    }
}

export const MovingOrderPhotosAndDetails = ({cbHandleGetImageList, cbHandleGetMovingDetails}) => {

    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [exceedLimit, setExceedLimit] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [previewImageURL, setPreviewImageURL] = useState('');
    const [movingDetails, setMovingDetails] = useState('');

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleMovingDetails = (event) => {
        setMovingDetails(event.target.value);
    }

    const handleRemoveImage = (itemToRemove) => {
        const updatedItems = selectedFiles.filter((item) => item !== itemToRemove);
        setSelectedFiles(updatedItems);
    }

    const uploadImageToServer = async (uploadImages) => {
        if (!uploadImages) {
            setLoading(false);
            return;
        }

        let requestURL = `${NODE_ROUTE_URI}/movingService/uploadImage`;
        const data = new FormData();

        uploadImages.forEach(image => {
            data.append('images', image);
        })

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    "Content-Type": "multipart/form-data;",
                },
                data: data
            })

            setSelectedFiles((prevFiles) => [...prevFiles, ...result.data.urls]);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setLoading(false);
        }
    }

    const handleFileChange = (event) => {
        console.log('[MovingOrderPhotosAndDetails] handleFileChange');

        setLoading(true);
        const files = Array.from(event.target.files);
        const allowedFormats = ['image/jpeg', 'image/png'];

        // Filter out files that are not in JPG or PNG format
        const selectedImages = files.filter((file) =>
            allowedFormats.includes(file.type)
        );

        const UploadImageNum = selectedImages.length + selectedFiles.length;

        if (UploadImageNum > 5) {
            setExceedLimit(true);
            setLoading(false);
        } else {
            setExceedLimit(false);
            uploadImageToServer(selectedImages);
        }
    };

    useEffect(() => {
        cbHandleGetImageList(selectedFiles);
    }, [selectedFiles]);

    useEffect(() => {
        cbHandleGetMovingDetails(movingDetails);
    }, [movingDetails])

    console.log('[MovingOrderPhotosAndDetails] selectedFiles', selectedFiles);

    return (

        <Grid item xs={12}>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Tell us about moving
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            Do you have photos?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.MovingServiceImageUploadSection}>
                            <input
                                accept="image/jpeg, image/png"
                                style={{display: 'none'}}
                                id="moving-image-upload"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                                // disabled={selectedFiles.length < 5 ? false : true}
                            />
                            <label htmlFor="moving-image-upload" style={{margin: 0}}>
                                <LoadingButton
                                    variant="contained"
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    component="span"
                                    endIcon={<UploadIcon/>}
                                    // disabled={selectedFiles.length < 5 ? false : true}
                                    loading={loading}
                                    size='small'
                                >
                                    <Typography style={{textTransform: 'none', fontSize: '14px'}}>
                                        Upload Images
                                    </Typography>
                                </LoadingButton>
                            </label>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={{fontSize: '12px', color: 'B5B5B5'}}>
                                The images should be in JPG, JPEG, or png format
                            </Typography>
                            <Typography sx={{fontSize: '12px', color: 'B5B5B5'}}>
                                Maximum size: 5MB
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={styles.MovingServiceImageList}>
                            {
                                selectedFiles.length > 0 && selectedFiles.map((image, index) => {
                                    return (
                                        <Box sx={styles.MovingServiceImageSection}>
                                            <Box sx={styles.MovingServiceImageCard}>
                                                <img src={`https://storage.googleapis.com/${image}`}
                                                     style={styles.MovingServiceImageEach}
                                                     onClick={() => {
                                                         handleOpenModal();
                                                         setPreviewImageURL(image);
                                                     }}
                                                />
                                            </Box>
                                            <Typography style={{fontWeight: '600'}}>
                                                {image.split('_')[1]}
                                            </Typography>
                                            <IconButton
                                                sx={styles.MovingServiceImageDeleteImage}
                                                onClick={() => {
                                                    handleRemoveImage(image);
                                                }}
                                            >
                                                <ClearIcon/>
                                            </IconButton>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                        >
                            <Box sx={styles.MovingServiceImageModalBox}>
                                <Box sx={styles.MovingServiceImageBox}>
                                    <img
                                        src={`https://storage.googleapis.com/${previewImageURL}`}
                                        style={styles.MovingServiceImageItem}
                                    />
                                </Box>
                            </Box>
                        </Modal>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: '600', textAlign: 'left'}}>
                            Provide us with more details about your item.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={movingDetails}
                                onInput={handleMovingDetails}
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                size='small'
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}