import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const styles = {
    MovingOrderRoomPlaceItemEachRoot: {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        borderRadius: '5px',
        padding: '20px'
    }
}

export const MovingOrderRoomPlaceItemEach = ({
                                                 categoryName,
                                                 categoryDescription,
                                                 cateId,
                                                 roomPlaceName,
                                                 lengthInch,
                                                 widthInch,
                                                 heightInch,
                                                 weightLB,
                                                 volumeCubicInch,
                                                 roomPlace,
                                                 movingFurnitureList,
                                                 cbAddFurnitureList,
                                                 cbUpdateFurnitureList,
                                                 cbRemoveFurnitureList,
                                                 isContinuePlaceOrder
                                             }) => {

    const [quantity, setQuantity] = useState(0);
    const [wrappingService, setWrappingService] = useState(false);
    const [assemblyService, setAssemblyService] = useState(false);
    const [disassemblyService, setDisassemblyService] = useState(false);

    const handleAddItem = () => {
        const existingItem = movingFurnitureList.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            const updatedFurniture = {
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity + 1,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: wrappingService,
                    packingService: false,
                    assemblyService: assemblyService,
                    disassemblyService: disassemblyService,
                    roomPlace: roomPlace
                }
            };
            cbUpdateFurnitureList(updatedFurniture);
        } else {
            const newFurniture = {
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: 1,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: wrappingService,
                    packingService: false,
                    assemblyService: assemblyService,
                    disassemblyService: disassemblyService,
                    roomPlace: roomPlace
                }
            };
            cbAddFurnitureList(newFurniture);
        }
        setQuantity(+quantity + 1);
    }

    const handleRemoveItem = () => {
        const existingItem = movingFurnitureList.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (+quantity <= 0) {
            return;
        }
        if (+quantity === 1) {
            const removedFurniture = {
                cate_id: cateId,
                roomPlace: roomPlace
            };
            cbRemoveFurnitureList(removedFurniture);
        } else {
            const updatedFurniture = {
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: +quantity - 1,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: existingItem?.packageSpecialServices
            };
            cbUpdateFurnitureList(updatedFurniture);
        }
        setQuantity(+quantity - 1);
    }

    const handleWrappingService = (event) => {
        setWrappingService(event?.target?.checked);
        const existingItem = movingFurnitureList.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            const updatedFurniture = {
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: event?.target?.checked,
                    packingService: false,
                    assemblyService: existingItem?.packageSpecialServices?.assemblyService,
                    disassemblyService: existingItem?.packageSpecialServices?.disassemblyService,
                    roomPlace: existingItem?.packageSpecialServices?.roomPlace
                }
            }
            cbUpdateFurnitureList(updatedFurniture);
        }
    }

    const handleAssemblyService = (event) => {
        setAssemblyService(event?.target?.checked);
        const existingItem = movingFurnitureList.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            const updatedFurniture = {
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: existingItem?.packageSpecialServices?.wrappingService,
                    packingService: false,
                    assemblyService: event?.target?.checked,
                    disassemblyService: existingItem?.packageSpecialServices?.disassemblyService,
                    roomPlace: existingItem?.packageSpecialServices?.roomPlace
                }
            }
            cbUpdateFurnitureList(updatedFurniture);
        }
    }

    const handleDisassemblyService = (event) => {
        setDisassemblyService(event?.target?.checked);
        const existingItem = movingFurnitureList.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            const updatedFurniture = {
                description: categoryDescription,
                category: categoryName,
                length: lengthInch,
                width: widthInch,
                height: heightInch,
                weight: weightLB,
                volume: volumeCubicInch,
                lengthUnit: "in",
                weightUnit: "lb",
                quantity: originQuantity,
                cate_id: cateId,
                room_place: roomPlaceName,
                roomPlace: roomPlace,
                packageSpecialServices: {
                    wrappingService: existingItem?.packageSpecialServices?.wrappingService,
                    packingService: false,
                    assemblyService: existingItem?.packageSpecialServices?.assemblyService,
                    disassemblyService: event?.target?.checked,
                    roomPlace: existingItem?.packageSpecialServices?.roomPlace
                }
            }
            cbUpdateFurnitureList(updatedFurniture);
        }
    }

    useEffect(() => {
        // Get Existing Furniture From Furniture List
        console.log("movingFurnitureList: ", movingFurnitureList);
        console.log("cateId: ", cateId);
        const existingItem = movingFurnitureList.find(item => item.cate_id === cateId && item?.roomPlace === roomPlace);
        console.log("existingItem: ", existingItem);
        if (existingItem) {
            const {quantity: originQuantity} = existingItem;
            setQuantity(originQuantity);
            setWrappingService(existingItem?.packageSpecialServices?.wrappingService);
            setAssemblyService(existingItem?.packageSpecialServices?.assemblyService);
            setDisassemblyService(existingItem?.packageSpecialServices?.disassemblyService);
        } else {
            setQuantity(0);
            setWrappingService(false);
            setAssemblyService(false);
            setDisassemblyService(false);
        }
        return () => {
            setQuantity(0);
            setWrappingService(false);
            setAssemblyService(false);
            setDisassemblyService(false);
        }
    }, [cateId])

    console.log('[MovingOrderRoomPlaceItemEach] movingFurnitureList', movingFurnitureList);


    return (
        <Box sx={styles.MovingOrderRoomPlaceItemEachRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>{categoryName}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={9} xl={10}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start'
                            }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={wrappingService}
                                            onChange={handleWrappingService}
                                            disabled={isContinuePlaceOrder}
                                            sx={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                },
                                            }}
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography sx={{fontSize: '14px'}}>
                                            Wrapping
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={assemblyService}
                                            onChange={handleAssemblyService}
                                            disabled={isContinuePlaceOrder}
                                            sx={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                },
                                            }}
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography sx={{fontSize: '14px'}}>
                                            Assembly
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={disassemblyService}
                                            onChange={handleDisassemblyService}
                                            disabled={isContinuePlaceOrder}
                                            sx={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                },
                                            }}
                                            style={{
                                                color: 'green',
                                                '&.Mui-checked': {
                                                    color: 'green',
                                                }
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography sx={{fontSize: '14px'}}>
                                            Disassembly
                                        </Typography>
                                    }
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={3} xl={2}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                border: '1px solid #000000',
                                borderRadius: '5px',
                                width: '100%',
                                height: '100%'
                            }}>
                                <IconButton
                                    size="small"
                                    disabled={isContinuePlaceOrder}
                                    onClick={handleAddItem}
                                >
                                    <AddCircleOutlineIcon/>
                                </IconButton>
                                <Typography
                                    sx={{fontSize: '14px', color: '#1D8B45'}}>
                                    {quantity}
                                </Typography>
                                <IconButton
                                    size="small"
                                    disabled={isContinuePlaceOrder}
                                    onClick={handleRemoveItem}
                                >
                                    <RemoveCircleOutlineIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}