import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Snackbar,
} from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import { Alert, Pagination } from "@material-ui/lab";
import axios from "axios";
import { useSelector } from "react-redux";
import OrderPaymentTable from "./OrderPaymentTable";
import { SERVICE_DAO_URL, NODE_ROUTE_URI } from "../../../../utils/apiUrl";
import { Link } from "react-router-dom";
import BackArrow from "../../../BackArrow";

const BackendOrderPaymentList = () => {
  const { register, handleSubmit, control } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [orderList, setOrderList] = useState([]);

  useEffect(() => {
    const getNotPaid3PLBackendOrderListForEndUser = async () => {
      try {
        const result = await axios.get(
          NODE_ROUTE_URI +
            "/orderPaymentManagementRouter/getNotPaid3PLBackendOrderListForOperator"
        );
        if (result.status === 200) {
          console.log(result.data);
          const newOrderList = await result.data;
          setOrderList(newOrderList);
        } else {
          setErrorMessage("Failed to retrieve Backend Order List");
          setToastOpen(true);
        }
      } catch (e) {
        setErrorMessage("Failed to retrieve Backend Order List");
        setToastOpen(true);
      }
    };
    getNotPaid3PLBackendOrderListForEndUser();
  }, []);

  const [toastOpen, setToastOpen] = useState(false);
  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false);
  };

  return (
    <Box paddingBottom="3rem">
      <BackArrow link={"/"} />
      {/*Pop-up Toast*/}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={3000}
        message="Submit Transaction"
      >
        {(() => {
          if (errorMessage !== "") {
            return (
              <Alert
                onClose={handleToastClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                Error!
                <hr />
                {errorMessage}
              </Alert>
            );
          }
          return (
            <Alert
              onClose={handleToastClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          );
        })()}
      </Snackbar>
      <Box>
        <Box
          component="form"
          // width={"1000px"}
          margin="0 auto"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            gap: "2rem",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <h1>Not Paid Backend Orders for Operators</h1>
        </Box>
        {isLoading ? (
          <>
            <Box>
              <h1>Loading...</h1>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Box>
                <Box mt={2}>
                  <OrderPaymentTable orderList={orderList} />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BackendOrderPaymentList;
