import React from "react";
import PartnerServiceFeeSettingTable from "../PartnerServiceFeeSettingTable";

const ServiceFeeSettingTableForPackage = () => {
  return (
    <>
      <PartnerServiceFeeSettingTable partnerId={0} isGlobal shipmentTypeGroupId={5} />
    </>
  );
};

export default ServiceFeeSettingTableForPackage;
