import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "16px", // Adjust the font size as per your preference
        },
    }
});

export const MovingOrderEditPartner = ({orderInfo, getMovingOrderDetails}) => {

    const classes = useStyles();

    const accessToken = getAccessToken("access_token");

    const [partnerId, setPartnerId] = useState(null);
    const [originalPartnerId, setOriginalPartnerId] = useState(null);
    const [partnerList, setPartnerList] = useState([]);
    const [displayPartner, setDisplayPartner] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleChangePartner = (event, value) => {
        if (value) {
            setDisplayPartner(value);
            setPartnerId(value?.id);
        } else {
            setDisplayPartner(null);
            setPartnerId(null);
        }
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setPartnerList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleUpdateOrderClaimMovingOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingService/admin/partnerClaimMovingOrder`;

            let data = {
                backendOrderId: orderInfo?.order_id,
                partnerId: partnerId,
                sendEmail: true
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Moving Order');
            handleSuccessMessage();
            setTimeout(() => {
                getMovingOrderDetails();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Moving Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrderUnclaimMovingOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingService/admin/unclaimMovingOrder`;

            let data = {
                backendOrderId: orderInfo?.order_id,
            }

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Moving Order');
            handleSuccessMessage();
            setTimeout(() => {
                getMovingOrderDetails();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Moving Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrderPartner = async () => {
        if (partnerId) {
            await handleUpdateOrderClaimMovingOrder();
        } else {
            await handleUpdateOrderUnclaimMovingOrder();
        }
    }

    useEffect(async () => {
        if (orderInfo) {
            const partnerList = await getAllPartnerList();
            if (orderInfo?.task_status_id !== -1 && orderInfo?.task_status_id) {
                setPartnerId(orderInfo?.task_partner_id);
                setOriginalPartnerId(orderInfo?.task_partner_id);
                const filterPartner = partnerList?.filter(item => (
                    item?.id === orderInfo?.task_partner_id
                ));
                setDisplayPartner(filterPartner[0]);
            }
        }
    }, [orderInfo]);

    return (
        <Grid item xs={6}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Operator
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Partner
                            </FormLabel>
                            <Autocomplete
                                value={displayPartner}
                                options={partnerList}
                                getOptionLabel={
                                    option => `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                }
                                // filterOptions={(options, state) => options}
                                fullWidth
                                renderInput={(params) => <TextField
                                    {...params}
                                    className={classes.smallInput}
                                    // error={validationResultPlaceOrder?.selectUserError}
                                    // helperText={validationResultPlaceOrder?.selectUserError && 'Required'}
                                />}
                                // onInputChange={handleInputChangeUser}
                                onChange={handleChangePartner}
                                renderOption={(option) => (
                                    <Typography
                                        style={{fontSize: '16px'}}
                                    >
                                        {
                                            `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                        }
                                    </Typography>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    {
                        (+partnerId !== +originalPartnerId) &&
                        <Grid item md={12} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    size='small'
                                    onClick={handleUpdateOrderPartner}
                                    loading={loading}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                    }}>
                                        Update
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}