import {Divider, Grid, Typography} from "@mui/material";
import {PickupListEach} from "./PickupListEach";
import Box from "@mui/material/Box";

export const PickupListTable = ({pickupList, getPickupList}) => {

    return (
        <Grid container spacing={2}>
            {
                pickupList?.map((pickupData, index) =>
                    (
                        <Grid item xs={12}>
                            <Box sx={{
                                border: '1px solid #EEEEEE',
                                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
                                borderRadius: '10px',
                                padding: '20px'
                            }}>
                                <PickupListEach pickupData={pickupData} xs={12} key={index} getPickupList={getPickupList}/>
                            </Box>
                        </Grid>
                    )
                )
            }
        </Grid>
    )
}