import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";
const initialState = {
  fetchedHSCodeList: [],
  selectedHSCode: [],
};

export const HSCodeListSlice = createSlice({
  name: "selectHSCode",
  initialState,
  reducers: {
    addHSCodeList: (state, action) => {
      const index = action.payload.indexToAdd;
      const itemToAdd = {
        data: action.payload.data,
        type: action.payload.type,
      };
      //remove elements after the index
      state.fetchedHSCodeList.splice(index + 1);
      //update the element in the target index
      state.fetchedHSCodeList[index] = { ...itemToAdd };
    },
    selectHSCode: (state, action) => {
      const level = action.payload.level;
      const selected = {
        level: level,
        section: action.payload.section,
        parent: action.payload.parent,
      };
      if (!level) {
        state.selectedHSCode = [];
        state.selectedHSCode.push(selected);
      } else {
        produce(state, (draft) => {
          draft.selectedHSCode[level / 2] = selected;
          draft.selectedHSCode.splice(level / 2);
        });
      }
    },
    removeHSCodeList: (state, action) => {
      const indexToRemove = action.payload.indexToRemove;
      //remove elements after the index
      state.fetchedHSCodeList.splice(indexToRemove);
    },
  },
});

const { actions } = HSCodeListSlice;
export const { selectHSCode, addHSCodeList, removeHSCodeList } = actions;
export default HSCodeListSlice.reducer;
