import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Divider} from "@mui/material";
import dayjs from "dayjs";

export const MovingOrderDetailTransaction = ({orderInfo}) => {

    console.log('[MovingOrderDetailTransaction] order info', orderInfo)

    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Transaction
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    {
                        orderInfo?.backendPayments?.length > 0 ?
                            orderInfo?.backendPayments?.map((payment, index) => (
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={7}>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {dayjs(payment?.created_at).format('YYYY-MM-DD')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography style={{
                                                textAlign: 'left',
                                                color: payment?.payment_status === 'CAPTURED_SUCCEEDED' ? '#1D8B45' :
                                                    payment?.payment_status === 'DECLINED' ? '#FF0303' :
                                                        payment?.payment_status === 'UNDER_REVIEW' && '#F2BE22'
                                            }}>
                                                {payment?.payment_status === 'CAPTURED_SUCCEEDED' ? 'Succeed' :
                                                    payment?.payment_status === 'DECLINED' ? 'Declined' :
                                                        payment?.payment_status === 'UNDER_REVIEW' && 'Pending'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography sx={{textAlign: 'left'}}>
                                                {payment?.remark_notes === "Down_Payment" ? "Prepaid Amount" : "Payment"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <Typography style={{textAlign: 'left'}}>
                                                $ {payment?.amount.toFixed(2)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                            :
                            <Grid item xs={12}>
                                <Typography>
                                    No Transactions
                                </Typography>
                            </Grid>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}