import {Button, Container, Grid, Snackbar, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import {MovingOrderLocation} from "./MovingOrderLocation";
import {MovingOrderRoomPlaceItemList} from "./MovingOrderRoomPlaceItemList";
import {MovingOrderCustomizedItemList} from "./MovingOrderCustomizedItemList";
import {MovingOrderPhotosAndDetails} from "./MovingOrderPhotosAndDetails";
import {MovingOrderContact} from "./MovingOrderContact";
import {MovingOrderDate} from "./MovingOrderDate";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {
    getMissingKeysForMovingAddressFromItem,
    getMissingKeysForMovingContact, isValidateEmail,
    isValidPostalCode,
    isValidZipCode
} from "../../utils/Helper";
import {isValid} from "date-fns";
import {MovingOrderRating} from "./MovingOrderRating";
import {MovingOrderSelectUser} from "./MovingOrderSelectUser";
import {MovingOrderDeposit} from "./MovingOrderDeposit";
import dayjs from "dayjs";
import {getAccessToken} from "../../utils/doToken";
import {useHistory} from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const requiredItemProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit", "type"];
const validateRequiredItemProperties = ["length", "width", "height", "weight", "quantity"];

export const MovingOrder = () => {

    const history = useHistory();

    const [additionalService, setAdditionalService] = useState({
        scheduleDelivery: false,
        whiteGlove: false,
        packingService: false,
        wrappingService: false,
        assemblyService: false,
        disassemblyService: false,
        storageService: {
            cubicMeterSpace: 0,
            storageDays: 0
        }
    })
    const [loading, setLoading] = useState(false);
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [isContinuePlaceOrder, setIsContinuePlaceOrder] = useState(false);
    const [movingFrom, setMovingFrom] = useState();
    const [movingTo, setMovingTo] = useState();
    const [movingFurnitureList, setMovingFurnitureList] = useState([]);
    const [customizedItemList, setCustomizedItemLIst] = useState([]);
    const [selectUser, setSelectUser] = useState(null);
    const [imageList, setImageList] = useState(null);
    const [movingDetails, setMovingDetails] = useState('');
    const [movingContact, setMovingContact] = useState(null);
    const [movingDate, setMovingDate] = useState();
    const [movingTime, setMovingTime] = useState();
    const [deliverDate, setDeliverDate] = useState();
    const [movingFromWareHouse, setMovingFromWareHouse] = useState(null);
    const [movingToWareHouse, setMovingToWareHouse] = useState(null);
    const [movingDeposit, setMovingDeposit] = useState(null);

    const [contactValidate, setContactValidate] = useState(null);
    const [rating, setRating] = useState(null);
    const [validationResult, setValidationResult] = useState(null);
    const [validationResultPlaceOrder, setValidationResultPlaceOrder] = useState(null);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const cbHandleGetMovingFrom = (data) => {
        setMovingFrom(data);
    }

    const cbHandleGetMovingTo = (data) => {
        setMovingTo(data);
    }

    const cbHandleGetMovingFurnitureList = (data) => {
        setMovingFurnitureList(data);
    }

    const cbHandleGetCustomizedItemList = (data) => {
        setCustomizedItemLIst(data);
    }

    const cbHandleGetImageList = (data) => {
        setImageList(data);
    }

    const cbHandleGetMovingDetails = (data) => {
        setMovingDetails(data);
    }

    const cbHandleGetUser = (data) => {
        setSelectUser(data);
    }

    const cbHandleGetMovingContact = (data) => {
        setMovingContact(data);
    }

    const cbHandleGetMovingDate = (data) => {
        setMovingDate(data);
    }

    const cbHandleGetMovingTime = (data) => {
        setMovingTime(data);
    }

    const cbHandleGetDeliverDate = (data) => {
        setDeliverDate(data);
    }

    const cbHandleGetMovingFromWarehouse = (data) => {
        setMovingFromWareHouse(data);
    }

    const cbHandleGetMovingToWarehouse = (data) => {
        setMovingToWareHouse(data);
    }

    const cbHandleGetMovingDeposit = (data) => {
        setMovingDeposit(data);
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const handleGetMovingRating = async () => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/movingService/admin/movingItemGetRatingForLocalMoving`;

        const updatedItemList = customizedItemList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            volume: item.volume,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const updatedFurnitureList = movingFurnitureList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            volume: item.volume,
            lengthUnit: item?.lengthUnit,
            weightUnit: item?.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const items = [...updatedItemList, ...updatedFurnitureList];

        const specialServices = Object.keys(additionalService)
            .filter(key => typeof additionalService[key] === 'boolean' && additionalService[key])
            .map(serviceName => ({serviceName}));

        const data = {
            items: items,
            pickupWarehouseId: movingFromWareHouse?.warehouse?.id || 0,
            selfDropOff: movingFromWareHouse?.option === 'self',
            pickupAddress: movingFrom?.streetAddress,
            pickupCity: movingFrom?.city,
            pickupProvince: movingFrom?.province?.code,
            pickupCountry: movingFrom?.country,
            pickupPostalCode: movingFrom?.postalCode,
            deliverWarehouseId: movingToWareHouse?.warehouse?.id || 0,
            selfPickup: movingToWareHouse?.option === 'self',
            deliverAddress: movingTo?.streetAddress,
            deliverCity: movingTo?.city,
            deliverProvince: movingTo?.province?.code,
            deliverCountry: movingTo?.country,
            deliverPostalCode: movingTo?.postalCode,
            specialServices: specialServices,
            pickupHouseFeatures: {
                houseType: movingFrom?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFrom?.propertyType?.houseType,
                floorLevel: movingFrom?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingFrom?.isNarrowStreet,
                drivewaySteepLevel: movingFrom?.isSteepDriveway ? 1 : 0,
                hasLift: !!movingFrom?.lift
            },
            deliverHouseFeatures: {
                houseType: movingTo?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingTo?.propertyType?.houseType,
                floorLevel: movingTo?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingTo?.isNarrowStreet,
                drivewaySteepLevel: movingTo?.isSteepDriveway ? 1 : 0,
                hasLift: !!movingTo?.lift
            }
        }

        console.log('[handleGetRating] data', data);

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            // console.log(result.data);
            // setCategoryList(result.data);
            console.log('[handleGetRating] rating result', result);
            if (result?.data?.message) {
                setErrorMessage('Fail to get moving rating');
                handleErrorMessage();
            } else {
                setSuccessMessage('Successfully get moving rating');
                setErrorMessage('');
                handleSuccessMessage();
            }
            setRating(result?.data);
        } catch (e) {
            console.log(e.response);
            setRating(null);
            setErrorMessage('Fail to get moving rating');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const handlePlaceMovingOrder = async () => {
        setLoadingPlaceOrder(true);
        let requestURL = `${NODE_ROUTE_URI}/movingService/admin/initMovingOrder`;
        const accessToken = getAccessToken("access_token");

        const updatedItemList = customizedItemList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            volume: item.volume,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const updatedFurnitureList = movingFurnitureList?.map(item => ({
            description: item.category,
            cateId: item.cate_id,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            volume: item.volume,
            lengthUnit: item?.lengthUnit,
            weightUnit: item?.weightUnit,
            quantity: item.quantity,
            packageSpecialServices: item.packageSpecialServices
        }));

        const items = [...updatedItemList, ...updatedFurnitureList];

        const specialServices = Object.keys(additionalService)
            .filter(key => typeof additionalService[key] === 'boolean' && additionalService[key])
            .map(serviceName => ({serviceName}));

        const messages = {
            additionalService,
            movingFromAddress: {
                // property: movingFromAddress?.propertyType,
                // lift: movingFromAddress?.lift,
                // storey: movingFromAddress?.storey,

                ...movingFrom

            },
            movingToAddress: {
                // property: movingToAddress?.propertyType,
                // lift: movingToAddress?.lift,
                // storey: movingTçoAddress?.storey
                ...movingTo
            }
        }

        const data = {
            userId: selectUser?.id,
            ownerRole: selectUser?.ownerRole,
            items: items,
            senderName: movingContact?.pickupName,
            senderEmail: movingContact?.pickupEmail,
            senderPhone: movingContact?.pickupPhone.replace(/\D/g, ""),
            deliverName: movingContact?.deliverName,
            deliverEmail: movingContact?.deliverEmail,
            deliverPhone: movingContact?.deliverPhone.replace(/\D/g, ""),
            pickupWarehouseId: movingFromWareHouse?.warehouse?.id || 0,
            selfDropOff: movingFromWareHouse?.option === 'self',
            pickupAddress: movingFrom?.streetAddress,
            pickupCity: movingFrom?.city,
            pickupProvince: movingFrom?.province?.code,
            pickupCountry: movingFrom?.country,
            pickupPostalCode: movingFrom?.postalCode,
            deliverWarehouseId: movingToWareHouse?.warehouse?.id || 0,
            selfPickup: movingToWareHouse?.option === 'self',
            deliverAddress: movingTo?.streetAddress,
            deliverCity: movingTo?.city,
            deliverProvince: movingTo?.province?.code,
            deliverCountry: movingTo?.country,
            deliverPostalCode: movingTo?.postalCode,
            specialServices: specialServices,
            pickupHouseFeatures: {
                houseType: movingFrom?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingFrom?.propertyType?.houseType,
                floorLevel: movingFrom?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingFrom?.isNarrowStreet,
                drivewaySteepLevel: movingFrom?.isSteepDriveway ? 1 : 0,
                hasLift: !!movingFrom?.lift
            },
            deliverHouseFeatures: {
                houseType: movingTo?.propertyType?.houseType === 'Apartment' ? 'Condo' : movingTo?.propertyType?.houseType,
                floorLevel: movingTo?.floorLevel,
                staircaseNumber: "0",
                isNarrowStreet: movingTo?.isNarrowStreet,
                drivewaySteepLevel: movingTo?.isSteepDriveway ? 1 : 0,
                hasLift: !!movingTo?.lift
            },
            imageUrls: imageList,
            scheduledPickupDate: `${dayjs(movingDate).format('YYYY-MM-DD')} ${dayjs(movingTime?.movingTimeFrom).format('hh:mm')}`,
            messages: JSON.stringify(messages),
            accountBase: "",
            activateOrder: !movingDeposit?.isDeposit
        }

        try {
            const result = await axios({
                method: 'post',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                url: requestURL,
                data: data,
            });

            console.log('[handlePlaceMovingOrder] place order result', result);
            setErrorMessage('');
            setSuccessMessage('Successfully place moving order');
            handleSuccessMessage();
            setTimeout(() => {
                history.push("/v2/moving-order-list");
            }, 2000);
            setLoadingPlaceOrder(false);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail to place moving order');
            handleErrorMessage();
            setLoadingPlaceOrder(false);
        }
    }

    const validateMovingFromPostalCode = () => {
        if (movingFrom?.country === 'CA') {
            const result = isValidPostalCode(movingFrom?.postalCode);
            return result;
        } else {
            const result = isValidZipCode(movingFrom?.postalCode);
            console.log(result);
            return result;
        }
    }

    const validateMovingToPostalCode = () => {
        if (movingTo?.country === 'CA') {
            const result = isValidPostalCode(movingTo?.postalCode);
            console.log(result);
            return result;
        } else {
            const result = isValidZipCode(movingTo?.postalCode);
            return result;
        }
    }

    const validatePostalCode = () => {
        const movingFromPostalCode = validateMovingFromPostalCode();
        const movingToPostalCode = validateMovingToPostalCode();
        return (movingFromPostalCode && movingToPostalCode);
    }

    const validateItemList = () => {
        if (customizedItemList.length === 0 && movingFurnitureList?.length === 0) {
            return true
        } else {
            return false
        }
    }

    const validateContactInfoMissing = () => {
        const missingKeys = getMissingKeysForMovingContact(movingContact);
        // console.log('missing keys - move to', missingKeys);
        if (missingKeys.length === 0) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoPickupPhone = () => {

        const checkPhoneNumber = movingContact.pickupPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfoDeliverPhone = () => {
        const checkPhoneNumber = movingContact.deliverPhone.replace(/\D/g, "");
        if (!isNaN(checkPhoneNumber) && checkPhoneNumber.length < 20 && checkPhoneNumber.length > 9) {
            return false
        } else {
            return true
        }
    }

    const validateContactInfo = async () => {
        const contactMissing = validateContactInfoMissing();
        const pickupPhoneError = validateContactInfoPickupPhone();
        const pickupEmailError = await validateEmailAddress(movingContact?.pickupEmail);
        const deliverPhoneError = validateContactInfoDeliverPhone();
        const deliverEmailError = await validateEmailAddress(movingContact?.deliverEmail);
        setContactValidate(prevState => ({
            ...prevState,
            pickupPhoneError,
            pickupEmailError: !pickupEmailError,
            deliverPhoneError,
            deliverEmailError: !deliverEmailError
        }))
        console.log('missing', contactMissing);
        // console.log('phone', phoneError);
        // console.log('email', emailError);
        if (!contactMissing && !pickupPhoneError && pickupEmailError && !deliverPhoneError && deliverEmailError) {
            return false
        } else {
            return true
        }
    }

    const validateMoveDataAndTime = () => {
        if (isValid(movingDate?.$d) && isValid(movingTime?.movingTimeFrom?.$d)) {
            return false
        } else {
            return true
        }
    }

    const validateWareHouse = (option, warehouse) => {
        if (option === 'schedule') {
            return false
        } else {
            if (warehouse) {
                return false
            } else {
                return true
            }
        }
    }

    const validationGetRating = () => {
        setErrorMessage('');
        setToastOpen(false);

        const validateMovingFromMissing = getMissingKeysForMovingAddressFromItem(movingFrom);
        const validateMovingToMissing = getMissingKeysForMovingAddressFromItem(movingTo);
        const hasMissingValues = customizedItemList.some(object => requiredItemProperties.some((property) => !object[property]));
        const hasValidateValues = customizedItemList.some(object => validateRequiredItemProperties.some((property) => (object[property]) <= 0));
        const hasMissingDescription = customizedItemList.some(item => {
            // Check if cate_id is 0 and description is missing or empty
            return item.cate_id === 0 && (item.description === undefined || item?.description?.trim() === "");
        });

        setValidationResult(prevState => ({
            ...prevState,
            validateMovingFromMissing: validateMovingFromMissing,
            validateMovingToMissing: validateMovingToMissing,
            hasMissingValuesItem: hasMissingValues,
            hasValidateValuesError: hasValidateValues,
            hasMissingDescriptionError: hasMissingDescription,
            validateItemListError: validateItemList(),
            movingFromPostalCodeError: !validateMovingFromPostalCode(),
            movingToPostalCodeError: !validateMovingToPostalCode(),
        }))

        !validatePostalCode() && setErrorMessage(prevState => prevState + ' Postal code is invalid.');
        validateMovingFromMissing.length > 0 && setErrorMessage(prevState => prevState + ' Move from is missing one or more required fields.');
        validateMovingToMissing.length > 0 && setErrorMessage(prevState => prevState + ' Move to is missing one or more required fields.');
        validateItemList() && setErrorMessage(prevState => prevState + 'Your item list is empty.');
        (hasMissingValues || hasMissingDescription) && setErrorMessage(prevState => prevState + ' Your item list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + ' Your item list has one or more invalid fields.');
        // validateContactInfo() && setErrorMessage(prevState => prevState + 'Contact Information is missing some required fields or containing invalid fields.');
        // validateMoveDataAndTime() && setErrorMessage(prevState => prevState + 'Moving date or time is missing or invalid.');
        // validateWareHouse(movingFromWareHouse?.option, movingFromWareHouse?.warehouse) && setErrorMessage(prevState => prevState + 'Moving from warehouse is missing.');
        // validateWareHouse(movingToWareHouse?.option, movingToWareHouse?.warehouse) && setErrorMessage(prevState => prevState + 'Moving to warehouse is missing.');

        if (!validatePostalCode() ||
            validateMovingFromMissing.length > 0 ||
            validateMovingToMissing.length > 0 ||
            validateItemList() ||
            hasMissingValues ||
            hasValidateValues ||
            hasMissingDescription
            // validateContactInfo() ||
            // validateMoveDataAndTime() ||
            // validateWareHouse(movingFromWareHouse?.option, movingFromWareHouse?.warehouse) ||
            // validateWareHouse(movingToWareHouse?.option, movingToWareHouse?.warehouse)
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const validationPlaceOrder = async () => {
        setErrorMessage('');
        setToastOpen(false);

        const validateContactError = await validateContactInfo();
        const validateMoveDataAndTimeError = validateMoveDataAndTime();
        const validateWareHouseFromError = validateWareHouse(movingFromWareHouse?.option, movingFromWareHouse?.warehouse);
        const validateWareHouseToError = validateWareHouse(movingToWareHouse?.option, movingToWareHouse?.warehouse);
        const missingKeys = getMissingKeysForMovingContact(movingContact);

        !selectUser && setErrorMessage(prevState => prevState + 'User information is missing.');
        validateContactError && setErrorMessage(prevState => prevState + 'Contact Information is missing some required fields or containing invalid fields.');
        validateMoveDataAndTimeError && setErrorMessage(prevState => prevState + 'Moving date or time is missing or invalid.');
        validateWareHouseFromError && setErrorMessage(prevState => prevState + 'Moving from warehouse is missing.');
        validateWareHouseToError && setErrorMessage(prevState => prevState + 'Moving to warehouse is missing.');

        setValidationResultPlaceOrder(prevState => ({
            ...prevState,
            selectUserError: !selectUser,
            contactMissing: missingKeys,
            validateMoveDataAndTimeError,
            validateWareHouseFromError,
            validateWareHouseToError
        }))

        if (!selectUser ||
            validateContactError ||
            validateMoveDataAndTime() ||
            validateWareHouse(movingFromWareHouse?.option, movingFromWareHouse?.warehouse) ||
            validateWareHouse(movingToWareHouse?.option, movingToWareHouse?.warehouse)
        ) {
            handleErrorMessage();
            return false
        } else {
            return true
        }
    }

    const handleGetRating = async () => {
        setIsContinuePlaceOrder(false);
        if (validationGetRating()) {
            setRating(null);
            await handleGetMovingRating();
        }
    }

    const handleContinuePlaceOrder = () => {
        setIsContinuePlaceOrder(true);
    }

    const handleBack = () => {
        setIsContinuePlaceOrder(false);
        setValidationResultPlaceOrder(null);
        setContactValidate(null);
    }

    const handlePlaceOrder = async () => {
        const validationResult = await validationPlaceOrder();
        if (validationResult) {
            await handlePlaceMovingOrder();
        }
    }

    console.log('[MovingOrder] moving from', movingFrom);
    console.log('[MovingOrder] movingFurnitureList', movingFurnitureList);
    console.log('[MovingOrder] customizedItemList', customizedItemList);
    console.log('[MovingOrder] imageList', imageList);
    console.log('[MovingOrder] movingDetails', movingDetails);
    console.log('[MovingOrder] movingContact', movingContact);
    console.log('[MovingOrder] movingFromWareHouse', movingFromWareHouse);
    console.log('[MovingOrder] validationResult', validationResult);
    console.log('[MovingOrder] selectUser', selectUser);
    console.log('[MovingOrder] contactValidate', contactValidate);
    console.log('[MovingOrder] movingTime', movingTime);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderRoot}>
                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'left'}}>
                    Moving Order
                </Typography>
                <Box sx={styles.ShippingOrderContent}>
                    <Grid container spacing={2}>
                        <MovingOrderLocation cbHandleGetMovingFrom={cbHandleGetMovingFrom}
                                             cbHandleGetMovingTo={cbHandleGetMovingTo}
                                             validationResult={validationResult}
                                             isContinuePlaceOrder={isContinuePlaceOrder}/>
                        <MovingOrderRoomPlaceItemList cbHandleGetMovingFurnitureList={cbHandleGetMovingFurnitureList}
                                                      isContinuePlaceOrder={isContinuePlaceOrder}/>
                        {/*<MovingOrderCustomizedItemList cbHandleGetCustomizedItemList={cbHandleGetCustomizedItemList}*/}
                        {/*                               isContinuePlaceOrder={isContinuePlaceOrder}/>*/}
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        height: '100%',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    onClick={handleGetRating}
                                    loading={loading}
                                    disabled={isContinuePlaceOrder}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        Get Rating
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                        {
                            rating &&
                            <>
                                <MovingOrderRating rating={rating}/>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                height: '100%',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                },
                                            }}
                                            onClick={handleContinuePlaceOrder}
                                            disabled={isContinuePlaceOrder}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Continue Place Order
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </>
                        }
                        {
                            isContinuePlaceOrder &&
                            <>
                                <MovingOrderSelectUser cbHandleGetUser={cbHandleGetUser}
                                                       validationResultPlaceOrder={validationResultPlaceOrder}/>
                                <MovingOrderContact cbHandleGetMovingContact={cbHandleGetMovingContact}
                                                    selectUser={selectUser}
                                                    validationResultPlaceOrder={validationResultPlaceOrder}
                                                    contactValidate={contactValidate}/>
                                <MovingOrderDate cbHandleGetMovingDate={cbHandleGetMovingDate}
                                                 cbHandleGetMovingTime={cbHandleGetMovingTime}
                                                 cbHandleGetDeliverDate={cbHandleGetDeliverDate}
                                                 cbHandleGetMovingFromWarehouse={cbHandleGetMovingFromWarehouse}
                                                 cbHandleGetMovingToWarehouse={cbHandleGetMovingToWarehouse}
                                                 validationResultPlaceOrder={validationResultPlaceOrder}/>
                                <MovingOrderDeposit cbHandleGetMovingDeposit={cbHandleGetMovingDeposit}
                                                    validationResultPlaceOrder={validationResultPlaceOrder}/>
                                <MovingOrderPhotosAndDetails cbHandleGetImageList={cbHandleGetImageList}
                                                             cbHandleGetMovingDetails={cbHandleGetMovingDetails}/>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                height: '100%',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                },
                                            }}
                                            onClick={handleBack}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Back To Get Rating
                                            </Typography>
                                        </Button>
                                        <LoadingButton
                                            variant='contained'
                                            sx={{
                                                backgroundColor: '#1D8B45',
                                                height: '100%',
                                                "&:hover": {
                                                    backgroundColor: '#1D8B45',
                                                    filter: 'brightness(0.9)'
                                                },
                                            }}
                                            onClick={handlePlaceOrder}
                                            loading={loadingPlaceOrder}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Place Order
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}