import {Divider, Grid, Typography} from "@mui/material";
import {ClaimItem} from "./ClaimItem";
import Box from "@mui/material/Box";

export const ClaimTable = ({claimList}) => {

    return (
        <Grid container spacing={2}>
            {
                claimList?.map((claimData, index) => (
                        <Grid item xs={12} key={index}>
                            <Box sx={{
                                border: '1px solid #EEEEEE',
                                boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
                                borderRadius: '10px',
                                padding: '20px'
                            }}>
                                <ClaimItem claimData={claimData} key={index}/>
                            </Box>
                        </Grid>
                    )
                )
            }
        </Grid>
    )
}