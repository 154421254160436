import {FormHelperText, Grid, MenuItem, Select, Typography} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import {carSizeList} from "../../utils/Helper";

export const CarQuoteCategory = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [carSize, setCarSize] = useState([]);


    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        setCarSize(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({carSize});
        }
    }, [triggerAction])

    console.log('[CarQuoteCategory] validation', validation);

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Car Size
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}>
                        <FormControl fullWidth>
                        <Select
                            value={carSize}
                            size='small'
                            multiple
                            onChange={handleChange}
                            disabled={isConfirm}
                            style={{textAlign: 'left'}} // font size of input text
                            error={validation && validation?.carSize}
                        >
                            {
                                carSizeList?.map((size, index) => (
                                    <MenuItem key={index} value={size}>
                                        {size}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                }}
                            >
                                {(validation && validation?.carSize) && 'Required field'}
                            </FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Fragment>
    )
}