import React from "react";
import { string, func, oneOfType, number, bool } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, Select, MenuItem } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const CustomInput = React.memo(
  ({
    disabled,
    value,
    onChange,
    label,
    placeholderValue,
    // error = false,
    // defaultValue,
    type,
    options = [],
    // multiline,
    // rows,
  }) => {
    const classes = useStyles();

    let inputBody = (
      <TextField
        className={classes.textfield}
        value={value || ""}
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholderValue || ""}
        InputProps={{ disableUnderline: true }}
        variant="outlined"
        classes={{ root: classes.root }}
      />
    );

    if (type === "date") {
      inputBody = (
        <TextField
          className={classes.textfield}
          type="date"
          value={value || ""}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholderValue || ""}
          InputProps={{ disableUnderline: true }}
          variant="outlined"
          classes={{ root: classes.root }}
        />
      );
    }

    if (type === "select") {
      inputBody = (
        <Select
          value={value || ""}
          onChange={onChange}
          className={classes.textfield}
          variant="outlined"
          disableUnderline={true}
          classes={{ root: classes.selectRoot }}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );
    }

    return (
      <Box>
        <Box textAlign="start" fontSize="0.8rem" pb={1} pl={1}>
          {label}
        </Box>
        {inputBody}
      </Box>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  textfield: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    // paddingTop: "0.4vw",
    // paddingBottom: "0.4vw",
  },
  root: {
    "& input": {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  selectRoot: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  disabled: {
    backgroundColor: grey[300],
    border: `1px solid ${grey[300]}`,
  },
  errorText: {
    border: "1px solid red",
  },
}));

CustomInput.propTypes = {
  onChange: func.isRequired,
  value: oneOfType([string, number]),
  //   error: object.isRequired,
  //   multiline: bool,
  //   type: string,
  label: string,
  disabled: bool,
  //   rows: number,
  placeholderValue: string,
  //   defaultValue: string,
};

export default CustomInput;
