import {Button, Divider, Grid, Menu, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import {Link} from "@material-ui/core";
import {mapCountryIcon} from "../../utils/Helper";
import {Fragment, useEffect, useState} from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import {PAGE_SIZE} from "../../utils/constInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useHistory} from "react-router-dom";

export const MovingCategoryListEach = ({order_info, partner, searchString}) => {

    console.log('[MovingCategoryListEach] order info', order_info);

    const history = useHistory();
    const [toggleViewMore, setToggleViewMore] = useState(false);
    const [subCategoryList, setSubCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const getMovingSubCategoryList = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingService/searchCategories`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    page: 0,
                    pageSize: PAGE_SIZE,
                    parentCateId: order_info?.cate_id
                }
            });
            console.log('[Moving Category List] - result', result);
            setSubCategoryList(result?.data?.data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
        }
    }

    const handleViewMore = () => {
        setToggleViewMore(!toggleViewMore);
    }

    useEffect(() => {
        if (toggleViewMore) {
            getMovingSubCategoryList();
        }
    }, [toggleViewMore])

    return (
        <Fragment>
            <Grid item md={1.25}>
                <Typography style={{textAlign: 'left'}}>
                    {order_info?.cate_id}
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{textAlign: 'left'}}>
                    {+order_info?.parent_cate_id === 0 ? 'N/A' : order_info?.parent_cate_id}
                </Typography>
            </Grid>
            <Grid item md={1.5}>
                <Typography style={{textAlign: 'left'}}>
                    {order_info?.category_name}
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{textAlign: 'left'}}>
                    {(+order_info?.standard_length_ins === 0 || !order_info?.standard_length_ins) ? 'N/A' : order_info?.standard_length_ins}
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{textAlign: 'left'}}>
                    {(+order_info?.standard_width_ins === 0 || !order_info?.standard_width_ins) ? 'N/A' : order_info?.standard_width_ins}
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{textAlign: 'left'}}>
                    {(+order_info?.standard_height_ins === 0 || !order_info?.standard_height_ins) ? 'N/A' : order_info?.standard_height_ins}
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{textAlign: 'left'}}>
                    {(+order_info?.standard_volume_cubic_in === 0 || !order_info?.standard_volume_cubic_in) ? 'N/A' : order_info?.standard_volume_cubic_in}
                </Typography>
            </Grid>
            <Grid item md={1.25}>
                <Typography style={{textAlign: 'left'}}>
                    {(+order_info?.standard_weight_lbs === 0 || !order_info?.standard_weight_lbs) ? 'N/A' : order_info?.standard_weight_lbs}
                </Typography>
            </Grid>
            <Grid item xs={1.5}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: (!searchString && (partner === 'default' || partner === 'all')) ? 'flex-end' : 'center',
                    height: '100%'
                }}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => history.push(`/v2/moving-category-list/${order_info?.cate_id}`)}
                    >
                        <Typography style={{
                            fontSize: '14px',
                            textTransform: 'none',
                            color: '#1D8B45'
                        }}>
                            Details
                        </Typography>
                    </Button>
                    {
                        (!searchString && (partner === 'default' || partner === 'all')) &&
                        <IconButton
                            onClick={handleViewMore}
                        >
                            {
                                toggleViewMore ? <ExpandLessIcon/> : <ExpandMoreIcon/>
                            }
                        </IconButton>
                    }
                </Box>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
            {
                toggleViewMore && (
                    loading ?
                        <Grid item md={12} xs={12}>
                            <CircularProgress/>
                        </Grid>
                        :
                        error ?
                            <Grid item md={12} xs={12}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    Sorry it currently encounter some issues, please try again later
                                </Typography>
                            </Grid>
                            :
                            subCategoryList?.length === 0 ?
                                <Grid item md={12} xs={12}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        No result
                                    </Typography>
                                </Grid>
                                :
                                subCategoryList?.map((category, index) => (
                                    <Fragment key={index}>
                                        <Grid item xs={12} sx={{backgroundColor: '#FFFFFF'}}>
                                            <Grid container spacing={2}>
                                                <Grid item md={1.25}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {category?.cate_id}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1.5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {+category?.parent_cate_id === 0 ? 'N/A' : category?.parent_cate_id}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1.5}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {category?.category_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1.25}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {(+category?.standard_length_ins === 0 || !category?.standard_length_ins) ? 'N/A' : category?.standard_length_ins}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1.25}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {(+category?.standard_width_ins === 0 || !category?.standard_width_ins) ? 'N/A' : category?.standard_width_ins}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1.25}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {(+category?.standard_height_ins === 0 || !category?.standard_height_ins) ? 'N/A' : category?.standard_height_ins}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1.25}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {(+category?.standard_volume_cubic_in === 0 || !category?.standard_volume_cubic_in) ? 'N/A' : category?.standard_volume_cubic_in}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={1.25}>
                                                    <Typography style={{textAlign: 'left'}}>
                                                        {(+category?.standard_weight_lbs === 0 || !category?.standard_weight_lbs) ? 'N/A' : category?.standard_weight_lbs}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1.5}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'center',
                                                        height: '100%'
                                                    }}>
                                                        <Button
                                                            variant='outlined'
                                                            sx={{
                                                                border: '2px solid #1D8B45',
                                                                "&:hover": {
                                                                    border: '2px solid #1D8B45',
                                                                    filter: 'brightness(0.9)'
                                                                }
                                                            }}
                                                            onClick={() => history.push(`/v2/moving-category-list/${category?.cate_id}`)}
                                                        >
                                                            <Typography style={{
                                                                fontSize: '14px',
                                                                textTransform: 'none',
                                                                color: '#1D8B45'
                                                            }}>
                                                                Details
                                                            </Typography>
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item md={12} xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                ))
                )
            }
        </Fragment>
    )
}