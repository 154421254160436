import React from "react";
import {makeStyles, Button} from "@material-ui/core";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    listItemWrapper: {
        width: "100%",
        margin: "3px auto",
        "&:hover": {
            cursor: "pointer",
            background: "aliceblue",
        },
        lineHeight: "2rem",
    },
    link: {
        textDecoration: "none",
    },
}));

const EndUserListItem = ({id, name, email, phone, status, userType}) => {
    const classes = useStyles();
    return (
        <>
            <tr className={classes.listItemWrapper}>
                <td>
                    {id}
                </td>
                <td>{name}</td>
                <td>{email}</td>
                <td>{phone}</td>
                <td>{status}</td>
                <td>{userType}</td>
                <td>
                    <Link className={classes.link} to={`/v2/end-user/${id}`}>
                        <Button
                            className={classes.detailBtn}
                            color="secondary"
                            variant="outlined"
                        >
                            Details
                        </Button>
                    </Link>
                </td>
            </tr>
        </>
    );
};

export default EndUserListItem;