import React, { useState } from "react";
import {Check, Cancel} from "@material-ui/icons";
import {
  TableBody,
  TableRow,
  TableCell,
  Button
} from "@material-ui/core";
import CurrencyFormat from "react-currency-format";

const LooseItemServices = ({
  setCompanyName,
  companyName,
  shippingService,
  chargeAmount,
  toSeventh,
  setServiceName,
  setPriceText
}) => {

  const setAndLoadNext = (currentCompanyName, currentServiceName) => {
    setCompanyName(currentCompanyName);
    setServiceName(currentServiceName);
    setPriceText(chargeAmount);
    toSeventh()
  };

  return (
    <TableBody>
    <TableRow>
      <TableCell align="left">
        <Button onClick={() => setAndLoadNext(companyName, shippingService)}>Select</Button>
      </TableCell>
      <TableCell align="center">{companyName}</TableCell>
      <TableCell align="center">{shippingService}</TableCell>
      <TableCell align="right">
        <CurrencyFormat value={(chargeAmount)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
      </TableCell>
    </TableRow>
  </TableBody>
  )
}

export default LooseItemServices;
