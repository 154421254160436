import {Fragment} from "react";
import {Button, Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "@material-ui/core";
import dayjs from "dayjs";
import {mapCountryIcon} from "../../utils/Helper";
import {useHistory} from "react-router-dom";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../utils/apiUrl";

export const LTLQuoteListEach = ({quoteInfo}) => {

    const history = useHistory();

    console.log('[LTLQuoteListEach] quoteInfo', quoteInfo);

    return (
        <Fragment>
            <Grid item md={1.875} xs={1.875}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                        {quoteInfo?.ship_from_city}, {quoteInfo?.ship_from_province}, {quoteInfo?.ship_from_country}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1.875} xs={1.875}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                        {quoteInfo?.ship_to_city}, {quoteInfo?.ship_to_province}, {quoteInfo?.ship_to_country}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={0.75} xs={0.75}>
                {
                    quoteInfo?.icon_uri ?
                        <img src={`${LABEL_DOWNLOAD_URL_PREFIX}${quoteInfo?.icon_uri}`} alt="logo"
                             width='35px'/>
                        :
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Typography style={{fontSize: '14px'}}>
                                {quoteInfo?.account_base}
                            </Typography>
                        </Box>
                }
            </Grid>
            <Grid item md={1} xs={1}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {((quoteInfo?.account_base !== quoteInfo?.carrier_name) && quoteInfo?.carrier_name) ? quoteInfo?.carrier_name : 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1.5} xs={1.5}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        ${(+quoteInfo?.final_amount)?.toFixed(2)} {quoteInfo?.currency_code}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1} xs={1}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {quoteInfo?.total_weight_lb}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1} xs={1}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {quoteInfo?.quote_no || 'N/A'}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1} xs={1}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{fontSize: '14px'}}>
                        {dayjs(quoteInfo?.expired_time).format("YYYY-MM-DD")}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={0.75} xs={0.75}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Typography style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        textAlign: "left",
                        color: (+quoteInfo?.status) === 1 ? "#1D8B45" : "#454545"
                    }}>
                        {(+quoteInfo?.status) === 1 ? "Active" : "Inactive"}
                    </Typography>
                </Box>
            </Grid>
            <Grid item md={1.25} xs={1.25}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={() => history.push(`/v2/ltl-quote-list/${quoteInfo?.id}`)}
                    >
                        <Typography style={{
                            fontSize: '14px',
                            textTransform: 'none',
                            color: '#1D8B45'
                        }}>
                            Details
                        </Typography>
                    </Button>
                </Box>
            </Grid>
            <Grid item md={12} xs={12}>
                <Divider/>
            </Grid>
        </Fragment>
    )
}