import React, { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { Box, TextField } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import { NODE_ROUTE_URI } from "../../utils/apiUrl";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const getShipmentTypeByShipmentTypeGroupId = ({ shipmentTypeGroupId }) => {
  switch (+shipmentTypeGroupId) {
    case 1:
      return "LTL";
    case 5:
      return "Package";
    case 7:
      return "Moving Box";
    case 8:
      return "Moving Item"
    default:
      return "";
  }
};

const PartnerServiceFeeSettingItem = ({
                                        id,
                                        shipmentTypeGroupId,
                                        accountBase,
                                        serviceFeeRate: initialServiceFeeRate,
                                        serviceFeeTaxRate: initialServiceFeeTaxRate,
                                        minServiceFee: initialMinServiceFee,
                                        maxServiceFee: initialMaxServiceFee,
                                        refreshList,
                                        ownerRole,
                                        isGlobal=false
                                      }) => {
  let shipmentType = getShipmentTypeByShipmentTypeGroupId({
    shipmentTypeGroupId
  });

  const [isEdit, setIsEdit] = useState(false);
  const [serviceFeeRate, setServiceFeeRate] = useState(initialServiceFeeRate);
  const [serviceFeeTaxRate, setServiceFeeTaxRate] = useState(initialServiceFeeTaxRate);
  const [minServiceFee, setMinServiceFee] = useState(initialMinServiceFee);
  const [maxServiceFee, setMaxServiceFee] = useState(initialMaxServiceFee);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleSave = async () => {
    try {
      const requestBody = {
        id: id,
        maxServiceFeeCharge: maxServiceFee,
        minServiceFeeCharge: minServiceFee,
        serviceFeeRate: serviceFeeRate,
        serviceFeeTaxRate: serviceFeeTaxRate
      };
      const response = await axios.patch(`${NODE_ROUTE_URI}/serviceFeeSetting/editServiceFeeSetting`, requestBody);
      console.log("Data updated:", response.data);
      setIsEdit(false); // Disable edit mode after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleCancel = () => {
    setIsEdit(false); // Exit edit mode
    // Revert to initial values if canceled
    setServiceFeeRate(initialServiceFeeRate);
    setServiceFeeTaxRate(initialServiceFeeTaxRate);
    setMinServiceFee(initialMinServiceFee);
    setMaxServiceFee(initialMaxServiceFee);
  };

  // Handle actual delete API call
  const handleDelete = async () => {
    try {
      await axios.delete(`${NODE_ROUTE_URI}/serviceFeeSetting/deleteServiceFeeSetting/${id}`);
      console.log('Data deleted');
      setOpenDeleteDialog(false);
      refreshList();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  // Handle delete confirmation dialog open/close
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  return (
    <>
      {/* Confirmation dialog for deletion */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <TableRow
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>{shipmentType}</TableCell>
        <TableCell>{accountBase}</TableCell>
        {
          isGlobal ?
            (
              <>
                <TableCell>{+ownerRole === 3 ? "Partner" : "End User"}</TableCell>
              </>
            )
            : (
              <>
              </>
            )
        }
        <TableCell>
          {isEdit ? (
            <TextField
              value={serviceFeeRate}
              onChange={(e) => setServiceFeeRate(e.target.value)}
              type="number"
            />
          ) : (
            serviceFeeRate
          )}
        </TableCell>
        <TableCell>
          {isEdit ? (
            <TextField
              value={serviceFeeTaxRate}
              onChange={(e) => setServiceFeeTaxRate(e.target.value)}
              type="number"
            />
          ) : (
            serviceFeeTaxRate
          )}
        </TableCell>
        <TableCell>
          <TableCell>
            {isEdit ? (
              <TextField
                value={minServiceFee}
                onChange={(e) => setMinServiceFee(e.target.value)}
                type="number"
              />
            ) : (
              minServiceFee
            )}
          </TableCell>
        </TableCell>
        <TableCell>
          <TableCell>
            {isEdit ? (
              <TextField
                value={maxServiceFee}
                onChange={(e) => setMaxServiceFee(e.target.value)}
                type="number"
              />
            ) : (
              maxServiceFee
            )}
          </TableCell>
        </TableCell>
        <TableCell>
          {
            isEdit ? (
              <Box sx={{ display: "flex", gap: "1rem" }}>
                <IconButton aria-label="delete" size="small" onClick={handleSave}>
                  <CheckCircleOutlineIcon />
                </IconButton>
                <IconButton aria-label="delete" size="small" onClick={handleCancel}>
                  <CancelIcon />
                </IconButton>
              </Box>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Button>
              </>
            )
          }

        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleOpenDeleteDialog()}
          >
            Delete
          </Button>
        </TableCell>

      </TableRow>
    </>

  );
};

export default PartnerServiceFeeSettingItem;
