import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue",
    },
    lineHeight: "2rem",
  },
  link: {
    textDecoration: "none",
  },
}));

const PartnerListItem = ({
  id,
  businessName,
  companyEmail,
  phone,
  address,
  adminId,
  admin,
  partnerId,
  partnerStatus,
  PartnerType,
  crmNumber,
}) => {
  const classes = useStyles();
  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link
              to={`/v2/partner/${id}`}>
          {id}
          </Link>
        </td>
        <td>{businessName}</td>
        <td>{companyEmail}</td>
        <td>{phone}</td>
        <td>{address}</td>
        <td>{adminId}</td>
        {/* <td>{partnerStatus ? <>Partner</> : <>Not Partner</>}</td> */}
        {/* <td>{PartnerType}</td> */}
        {/* <td>{crmNumber ? crmNumber : <>N/A</>}</td> */}
        <td>
          <Link className={classes.link} to={`/v2/partner/${id}`}>
            <Button
              className={classes.detailBtn}
              color="secondary"
              variant="outlined"
            >
              Details
            </Button>
          </Link>
        </td>
      </tr>
    </>
  );
};

export default PartnerListItem;
