import {Button, Container, FormLabel, Grid, InputAdornment, MenuItem, Select, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@material-ui/icons/Search";
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PackageOrderList} from "./PackageOrderList";
import {Pagination} from "@mui/lab";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import {separateTaxes} from "../../utils/calculateTax";
import dayjs from "dayjs";
import {CSVLink} from "react-csv";
import {useDispatch, useSelector} from "react-redux";
import action from "../Action";
import {AdjustPackageOrderDetails} from "./AdjustPackageOrderDetails";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useHistory, useLocation} from "react-router-dom";
import ServiceFeeSettingTableForPackage from "../ServiceFeeSetting/Package/ServiceFeeSettingTableForPackage";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

const carrierList = [
    {carrier: 'All'},
    {carrier: 'FedEx'},
    {carrier: 'CanadaPost'},
    {carrier: 'UPS'},
    {carrier: 'Canpar'}
]

export const PackageOrders = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const searchParams = useSelector(state => state?.packageOrderReducer?.searchParams);
    const location = useLocation();
    const statueValue = location?.state;

    const [serviceFeeSetting, setServiceFeeSetting] = useState(false);
    const [csvFileName, setCSVFileName] = useState('');
    const [searchType, setSearchType] = useState('text');
    const [searchString, setSearchString] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [carrier, setCarrier] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [ownerRole, setOwnerRole] = useState(0);
    const [userList, setUserList] = useState([]);
    const [displayUser, setDisplayUser] = useState(null);
    const [shippingOrderIdList, setShippingOrderList] = useState([]);
    const [transactions, setTransactions] = useState([
        [
            "Order ID",
            "Cargo Control Number",
            "Ref Order No",
            "Tran ID",
            "Carrier",
            "Client Number",
            "Product ID",
            "Exclude Tax",
            "Include Tax",
            "GST",
            "HST",
            "QST",
            "Total Brokerage Charge",
            "Total Duties Charge",
            "Total Duties Tax Charge",
            "Service Fee Exclude Tax",
            "Service Fee Include Tax",
            "Shipping Total Tax",

            "Freight Charge",
            "SurCharge",
            "Fuel Charge",
            "Collect Charge",
            "Residential Charge",
            "Insurance Charge",
            "Rural Charge",
            "Extended Area Charge",
            "Extra Care Charge",
            "Handling Charge",
            "Over Size Charge",
            "Over Weight Charge",
            "COD Charge",
            "COS Charge",
            "Dangerous Charge",
            "Surcharge Details",
            "Date",
            "Tracking #",
            "Labels",

            "Send From Name",
            // "Send From Company",
            "Send From Address",
            "Send From City",
            "Send From Province",
            "Send From Country",
            "Send From Postal Code",

            "Send To Name",
            // "Send To Company",
            "Send To Phone",
            "Send To Email",
            "Send To Address",
            "Send To City",
            "Send To Province",
            "Send To Country",
            "Send To Postal Code"
        ],
    ]);
    const csvLink = useRef();

    const getAllPartnerList = async () => {
        const requestURL = `${PARTNER_URI}/companies/getAllPartnerList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const getAllEndUserList = async () => {
        const requestURL = `${PARTNER_URI}/partner/getAllUserProfileList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
            });
            console.log('[partner list] - result', result);
            setUserList(result?.data);
            return result?.data
        } catch (e) {
            console.log(e.response);
        }
    }

    const getOrderList = async (data = null) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/shippingTransactions/searchShippingTransactions`;

        let params;

        if (data) {
            params = data
        } else {
            params = {
                queryString: searchType === 'text' ? (searchString || "") : null,
                page: 0,
                pageSize: 20,
                createDateAfter: startDate || null,
                createDateBefore: endDate || null,
                carrier: carrier?.carrier === 'All' ? null : carrier?.carrier,
                ownerRole: ownerRole === 0 ? null : ownerRole,
                partnerId: displayUser?.partner_id,
                crmInvoiceNumber: searchType === 'invoice' ? searchString : null,
                displayUser: displayUser
            }
        }

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                // headers: {
                //     Authorization: `Bearer ${storedToken}`,
                // },
                params: params
            });
            // const orderList = [];
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);

            if (searchType === 'invoice') {
                const orderList = result?.data?.result?.map(item => item.order_id);
                setShippingOrderList(orderList);
            }
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
            dispatch(action.packageOrderAction.savePackageListSearchParams(params));
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/shippingTransactions/searchShippingTransactions`;
        let params = {
            queryString: searchType === 'text' ? (searchString || "") : null,
            page: page,
            pageSize: 20,
            createDateAfter: startDate || null,
            createDateBefore: endDate || null,
            carrier: carrier?.carrier === 'All' ? null : carrier?.carrier,
            ownerRole: ownerRole === 0 ? null : ownerRole,
            partnerId: displayUser?.partner_id,
            crmInvoiceNumber: searchType === 'invoice' ? searchString : null,
            displayUser: displayUser
        }
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: params
            });
            console.log('[PackageOrders] - result', result);
            if (searchType === 'invoice') {
                const orderList = result?.data?.result?.map(item => item.order_id);
                setShippingOrderList(orderList);
            }
            setOrderData(result?.data?.result);
            setError(false);
            setLoading(false);
            dispatch(action.packageOrderAction.savePackageListSearchParams(params));
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const handleSearchShippingOrder = () => {
        // call api search orders
        if (searchType === 'text' || searchString) {
            getOrderList();
        } else {
            setShippingOrderList([]);
            setOrderData([]);
        }
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            getOrderList();
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value - 1;
        getOrderListByPage(page);
    };

    const handleChangeSearchType = (event) => {
        setSearchType(event?.target?.value);
        setShippingOrderList([]);
        setOrderData([]);
    }

    const handleChangeCarrier = (event, value) => {
        setCarrier(value);
    }

    const handleStartDate = (event) => {
        const inputDate = event.target.value;
        if (endDate) {
            if (inputDate <= endDate) {
                setStartDate(inputDate);
            } else {
                setStartDate(endDate);
            }
        } else {
            setStartDate(inputDate);
        }
    }

    const handleEndDate = (event) => {
        const inputDate = event.target.value;
        if (startDate) {

            if (inputDate >= startDate) {
                setEndDate(inputDate);
            } else {
                setEndDate(startDate);
            }
        } else {
            setEndDate(inputDate);
        }
    }

    const handleOwnerRole = (event) => {
        setOwnerRole(event?.target?.value);
        setDisplayUser(null);
    }

    const handleChangeUser = (event, value) => {
        if (value) {
            setDisplayUser(value);
        } else {
            setDisplayUser(null);
        }
    };

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const handleDownloadInvoice = async () => {
        const requestURL = `${PARTNER_URI}/shipping/list/exportTransactionsByShippingOrderIdList`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    shippingOrderIdList: shippingOrderIdList
                }
            });
            console.log('[handleDownloadInvoice] - result', result);
            const resultArray = [
                [
                    "Order ID",
                    "Cargo Control Number",
                    "Ref Order No",
                    "Tran ID",
                    "Carrier",
                    "Client Number",
                    "Product ID",
                    "Exclude Tax",
                    "Include Tax",
                    "GST",
                    "HST",
                    "QST",
                    "Total Brokerage Charge",
                    "Total Duties Charge",
                    "Total Duties Tax Charge",
                    "Service Fee Exclude Tax",
                    "Service Fee Include Tax",
                    "Shipping Total Tax",

                    "Freight Charge",
                    "SurCharge",
                    "Fuel Charge",
                    "Collect Charge",
                    "Residential Charge",
                    "Insurance Charge",
                    "Rural Charge",
                    "Extended Area Charge",
                    "Extra Care Charge",
                    "Handling Charge",
                    "Over Size Charge",
                    "Over Weight Charge",
                    "COD Charge",
                    "COS Charge",
                    "Dangerous Charge",
                    "Surcharge Details",
                    "Date",
                    "Tracking #",
                    "Labels",

                    "Send From Name",
                    // "Send From Company",
                    "Send From Address",
                    "Send From City",
                    "Send From Province",
                    "Send From Country",
                    "Send From Postal Code",

                    "Send To Name",
                    // "Send To Company",
                    "Send To Phone",
                    "Send To Email",
                    "Send To Address",
                    "Send To City",
                    "Send To Province",
                    "Send To Country",
                    "Send To Postal Code"
                ],
            ];
            for (const column of result?.data) {
                const columnData = [];
                columnData.push(column.order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(column.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(column.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                // // GST
                // columnData.push(taxesObject.gstTax);
                // // PST
                // columnData.push(taxesObject.pstTax);
                // columnData.push(taxesObject["GST/HST"]);
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.total_brokerage_charge);
                columnData.push(column.total_duties_charge);
                columnData.push(column.total_duties_taxes_charge);
                columnData.push(column.service_fee_exclude_tax);
                columnData.push(column.service_fee_include_gst);
                columnData.push(column.shipping_total_tax);

                columnData.push(column.freight_charge);
                columnData.push(column.sur_charge);
                columnData.push(column.fuel_surcharge);
                columnData.push(column.collect_charge);
                columnData.push(column.residential_charge);
                columnData.push(column.insurance_charge);
                columnData.push(column.rural_charge);
                columnData.push(column.extended_area_charge);
                columnData.push(column.extra_care_charge);
                columnData.push(column.handling_charge);
                columnData.push(column.over_size_charge);
                columnData.push(column.over_weight_charge);
                columnData.push(column.cod_charge);
                columnData.push(column.cos_charge);
                columnData.push(column.dangerous_charge);
                columnData.push(column?.surcharge_details || "");

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");

                // Send From Info
                columnData.push(column.send_from_name);
                columnData.push(column.send_from_addr);
                columnData.push(column.send_from_city);
                columnData.push(column.send_from_province);
                columnData.push(column.send_from_country);
                columnData.push(column.send_from_postal_code);

                // Send To Info
                columnData.push(column.send_to_name);
                columnData.push(column.send_to_tel);
                columnData.push(column.send_to_email);
                columnData.push(column.send_to_addr);
                columnData.push(column.send_to_city);
                columnData.push(column.send_to_province);
                columnData.push(column.send_to_country);
                columnData.push(column.send_to_postal_code);

                resultArray.push(columnData);
            }

            setTransactions(resultArray);
            setCSVFileName(`Invoice-${searchString}-${dayjs().format("YYYY-MM-DD")}.csv`)
            csvLink.current.link.click()

        } catch (e) {
            console.log(e);
        }
    }

    const handlePickupList = () => {
        history.push('/v2/pickup-list')
    }

    const handleServiceFeeSetting = () => {
        setServiceFeeSetting(true);
    }

    const handleServicePackageOrders = () => {
        setServiceFeeSetting(false);
    }

    useEffect(async () => {
        const partnerList = await getAllPartnerList();
        if (searchParams && partnerList?.length > 0) {
            setSearchString(searchParams?.queryString);
            setOwnerRole(searchParams?.ownerRole);
            setCarrier({carrier: searchParams?.carrier});
            setStartDate(searchParams?.createDateAfter);
            setEndDate(searchParams?.createDateBefore);
            setCurrentPage(searchParams?.page);
            setDisplayUser(searchParams?.displayUser);
            await getOrderList(searchParams);
        } else {
            await getOrderList();
        }
    }, []);

    useEffect(async () => {
        if (ownerRole === 1) {
            await getAllEndUserList();
        } else if (ownerRole === 3) {
            await getAllPartnerList();
        } else {
            setUserList([]);
        }
    }, [ownerRole]);

    useEffect(() => {
        if (statueValue) {
            setServiceFeeSetting(statueValue);
        }
    }, [statueValue])

    // console.log('[PackageOrders] order data', orderData);
    console.log('[PackageOrders] carrier', carrier);
    // console.log('[PackageOrders] startDate', startDate);
    // console.log('[PackageOrders] role', role);
    // console.log('[PackageOrders] partner', partner);
    // console.log('[PackageOrders] CSVFileName', csvFileName);
    // console.log('[PackageOrders] shippingOrderIdList', shippingOrderIdList);
    console.log('[PackageOrders] searchParams', searchParams);
    console.log('[PackageOrders] displayUser', displayUser);

    return (
        <Container maxWidth='xl'>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            flexWrap: 'wrap',
                            gap: '10px',
                            backgroundColor: '#FFFFFF',
                            padding: '20px',
                            borderRadius: '10px',
                            border: '1px solid #D1D1D1',
                        }}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked={!serviceFeeSetting}
                                onClick={handleServicePackageOrders}
                            />
                            <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                                Package Orders
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked={false}
                                onClick={handlePickupList}
                            />
                            <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                                Pickup List
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                icon={<RadioButtonUncheckedIcon/>}
                                checkedIcon={<CheckCircleIcon/>}
                                color="success"
                                checked={serviceFeeSetting}
                                onClick={handleServiceFeeSetting}
                            />
                            <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                                Service Fee Setting
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                {
                    serviceFeeSetting ?
                        <Grid item xs={12}>
                            <ServiceFeeSettingTableForPackage/>
                        </Grid>
                        :
                        <>
                            <Grid item xs={12}>
                                <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'center'}}>
                                    Package Orders
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={styles.ShippingOrderContent}>
                                    <Grid container spacing={2}>
                                        {
                                            searchType === 'text' &&
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography style={{textAlign: 'left', fontWeight: '600'}}>
                                                        Filter By
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        width: '100%'
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <FormLabel>
                                                                <Typography style={{textAlign: 'left'}}>
                                                                    Role
                                                                </Typography>
                                                            </FormLabel>
                                                            <Select
                                                                value={ownerRole}
                                                                size='small'
                                                                style={{textAlign: 'left', fontSize: '14px'}}
                                                                onChange={handleOwnerRole}
                                                            >
                                                                <MenuItem value={0}>
                                                                    All
                                                                </MenuItem>
                                                                <MenuItem value={3}>
                                                                    Partner
                                                                </MenuItem>
                                                                <MenuItem value={1}>
                                                                    EndUser
                                                                </MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        width: '100%'
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <FormLabel>
                                                                <Typography style={{textAlign: 'left'}}>
                                                                    {ownerRole === 1 ? 'EndUser' : ownerRole === 3 ? 'Partner' : 'All'}
                                                                </Typography>
                                                            </FormLabel>
                                                            <Autocomplete
                                                                value={displayUser}
                                                                options={userList}
                                                                getOptionLabel={
                                                                    option => ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                                                        `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                                                }
                                                                // filterOptions={(options, state) => options}
                                                                disabled={ownerRole === 0}
                                                                fullWidth
                                                                renderInput={(params) => <TextField
                                                                    {...params}
                                                                    className={classes.smallInput}
                                                                />}
                                                                // onInputChange={handleInputChangeUser}
                                                                onChange={handleChangeUser}
                                                                renderOption={(option) => (
                                                                    <Typography
                                                                        style={{fontSize: '14px'}}
                                                                    >
                                                                        {
                                                                            ownerRole === 1 ? `${option?.id} | ${option?.firstname} ${option?.lastname} | ${option?.email}` :
                                                                                `${option?.admin_user_id} | ${option?.business_name} | ${option?.email}`
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        width: '100%'
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <FormLabel>
                                                                <Typography style={{textAlign: 'left'}}>
                                                                    Carrier
                                                                </Typography>
                                                            </FormLabel>
                                                            <Autocomplete
                                                                value={carrier}
                                                                options={carrierList}
                                                                getOptionLabel={option => option?.carrier}
                                                                onChange={handleChangeCarrier}
                                                                fullWidth
                                                                PaperComponent={props => <Paper {...props}
                                                                                                sx={{width: '100%'}}/>}
                                                                renderInput={params => <TextField
                                                                    {...params}
                                                                    variant='outlined'
                                                                    className={classes.smallInput}
                                                                    // placeholder={'Moving To City'}
                                                                />}
                                                                renderOption={(option) => (
                                                                    <Typography
                                                                        style={{fontSize: '14px'}}
                                                                    >
                                                                        {/*<Typography>*/}
                                                                        {option.carrier}
                                                                        {/*</Typography>*/}
                                                                    </Typography>
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        width: '100%'
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <FormLabel>
                                                                <Typography style={{textAlign: 'left'}}>
                                                                    Start Date
                                                                </Typography>
                                                                <TextField
                                                                    value={startDate}
                                                                    fullWidth
                                                                    type='date'
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                    InputProps={{
                                                                        style: {fontSize: '14px'}, // Adjust the font size here
                                                                    }}
                                                                    onChange={handleStartDate}
                                                                    size='small'
                                                                />
                                                            </FormLabel>
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: '100%',
                                                        width: '100%'
                                                    }}>
                                                        <FormControl fullWidth>
                                                            <FormLabel>
                                                                <Typography style={{textAlign: 'left'}}>
                                                                    End Date
                                                                </Typography>
                                                            </FormLabel>
                                                            <TextField
                                                                value={endDate}
                                                                fullWidth
                                                                type='date'
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    style: {fontSize: '14px'}, // Adjust the font size here
                                                                }}
                                                                onChange={handleEndDate}
                                                                size='small'
                                                            />
                                                        </FormControl>
                                                    </Box>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item md={3}>
                                            <FormControl fullWidth>
                                                <Select
                                                    value={searchType}
                                                    size='small'
                                                    onChange={handleChangeSearchType}
                                                    style={{textAlign: 'left', fontSize: '14px'}}
                                                >
                                                    <MenuItem value='text'>
                                                        Search By Text
                                                    </MenuItem>
                                                    <MenuItem value='invoice'>
                                                        Search By Invoice Number
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={8} xs={12}>
                                            <TextField
                                                value={searchString}
                                                fullWidth
                                                size='small'
                                                onInput={handleSearchString}
                                                onKeyDown={handleSearchShippingOrderByKeyDown}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                placeholder="Order# / REF# / Tracking# / Name / Email / Address / Phone"
                                            />
                                        </Grid>
                                        <Grid item md={1} xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: '100%',
                                            }}>
                                                <Button
                                                    variant='contained'
                                                    sx={{
                                                        backgroundColor: '#1D8B45',
                                                        height: '100%',
                                                        "&:hover": {
                                                            backgroundColor: '#1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        },
                                                    }}
                                                    // size='small'
                                                    onClick={handleSearchShippingOrder}
                                                >
                                                    <Typography
                                                        style={{textTransform: 'none', fontSize: '16px'}}
                                                    >
                                                        Search
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </Grid>
                                        {
                                            (shippingOrderIdList?.length > 0) &&
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    gap: '5px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Typography>
                                                        Found {shippingOrderIdList?.length} results
                                                    </Typography>
                                                    <LoadingButton
                                                        onClick={handleDownloadInvoice}
                                                    >
                                                        <Typography style={{
                                                            textTransform: 'none',
                                                            color: '#1D8B45',
                                                        }}>
                                                            Download
                                                        </Typography>
                                                    </LoadingButton>
                                                    <CSVLink
                                                        data={transactions}
                                                        filename={csvFileName}
                                                        className='hidden'
                                                        ref={csvLink}
                                                        target='_blank'
                                                    />
                                                </Box>
                                            </Grid>
                                        }
                                        {
                                            loading ?
                                                <Grid item xs={12}>
                                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                                        <CircularProgress/>
                                                    </Box>
                                                </Grid>
                                                :
                                                error ?
                                                    <Grid item xs={12}>
                                                        <Box sx={styles.ShippingOrderContent}>
                                                            <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                                Sorry We currently encounter some issues, please try
                                                                again later.
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    :
                                                    orderData?.length === 0 ?
                                                        <Grid item xs={12}>
                                                            <Box sx={styles.ShippingOrderContent}>
                                                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                                                    No Shipping Order Found
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        :
                                                        <>
                                                            <Grid item xs={12}>
                                                                <PackageOrderList order={orderData}/>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Pagination
                                                                    count={lastPage}
                                                                    page={+currentPage}
                                                                    size='small'
                                                                    // defaultPage={6}
                                                                    onChange={handlePageChange}
                                                                    // boundaryCount={2}
                                                                />
                                                            </Grid>
                                                        </>
                                        }
                                    </Grid>
                                </Box>
                            </Grid>
                        </>
                }
            </Grid>
        </Container>
    )
}