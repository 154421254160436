import {Divider, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export const MovingOrderDetailConsignee = ({orderInfo}) => {
    return (
        <Grid item xs={12}>
            <Box sx={{
                border: '1px solid #B5B5B5',
                borderRadius: '5px',
                padding: '10px'
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: 600, textAlign: 'left', fontSize: '18px'}}>
                            Consignee Information
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Consignee Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.deliver_name}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Consignee Phone:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.deliver_phone}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            Consignee Email:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{textAlign: 'left'}}>
                            {orderInfo?.deliver_email}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}