import {getAccessToken} from "../../utils/doToken";
import {useEffect, useState} from "react";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

export const MovingOrderEditOrderStatus = ({orderInfo, getMovingOrderDetails}) => {

    const accessToken = getAccessToken("access_token");

    const [orderStatus, setOrderStatus] = useState(0);
    const [originalOrderStatus, setOriginalOrderStatus] = useState(0);
    const [shippingStatus, setShippingStatus] = useState(0);
    const [originalShippingStatus, setOriginalShippingStatus] = useState(0);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleOrderStatus = (event) => {
        setOrderStatus(event?.target?.value);
    }

    const handleShippingStatus = (event) => {
        const value = event?.target?.value;
        if (value === 1) {
            setErrorMessage("Please set the shipping status to 'initial' by deselecting the partner under the operator section.");
            handleErrorMessage();
        } else {
            setShippingStatus(value);
        }
    }

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleUpdateOrderStatus = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/movingService/admin/editMovingOrderDetails`;

            let data = {
                backendOrderId: orderInfo?.order_id,
                orderStatus: orderStatus,
                shippingStatus: shippingStatus,
            }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            setErrorMessage('');
            setSuccessMessage('Successful Update Moving Order');
            handleSuccessMessage();
            setTimeout(() => {
                getMovingOrderDetails();
            }, 1000);
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Moving Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (orderInfo) {
            setOrderStatus(orderInfo?.status_id);
            setOriginalOrderStatus(orderInfo?.status_id);
            setShippingStatus(orderInfo?.shipping_status_id);
            setOriginalShippingStatus(orderInfo?.shipping_status_id);
        }
    }, [orderInfo]);

    // console.log('[MovingOrderEditOrderStatus] order status', orderStatus);
    // console.log('[MovingOrderEditOrderStatus] orderInfo', orderInfo);

    return (
        <Grid item xs={6}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={styles.ShippingOrderList}>
                <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                    Status
                </Typography>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Order Status
                            </FormLabel>
                            <Select
                                value={orderStatus}
                                size='small'
                                // placeholder='Select Partner'
                                onChange={handleOrderStatus}
                                style={{textAlign: 'left'}}
                                // error={validation && validation?.carrierMissing}
                                // disabled={movingFrom?.propertyType?.houseType !== 'Condo' || isContinuePlaceOrder}
                            >
                                <MenuItem value={0}>
                                    Initial
                                </MenuItem>
                                <MenuItem value={100}>
                                    Paid Deposit
                                </MenuItem>
                                <MenuItem value={1}>
                                    Payment Received
                                </MenuItem>
                                {/*<MenuItem value={2}>*/}
                                {/*    Paused*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem value={3}>*/}
                                {/*    Refunded*/}
                                {/*</MenuItem>*/}
                                {/*<MenuItem value={-1}>*/}
                                {/*    Cancelled*/}
                                {/*</MenuItem>*/}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl fullWidth>
                            <FormLabel required sx={{textAlign: 'left'}}>
                                Shipping Status
                            </FormLabel>
                            <Select
                                value={shippingStatus}
                                size='small'
                                // placeholder='Select Partner'
                                onChange={handleShippingStatus}
                                style={{textAlign: 'left'}}
                                // error={validation && validation?.carrierMissing}
                                disabled={originalShippingStatus === 1}
                            >
                                <MenuItem value={1}>
                                    Initial
                                </MenuItem>
                                <MenuItem value={2}>
                                    Processing
                                </MenuItem>
                                <MenuItem value={20}>
                                    Waiting Approved
                                </MenuItem>
                                <MenuItem value={25}>
                                    Confirmed
                                </MenuItem>
                                <MenuItem value={30}>
                                    Pickup Unconfirmed
                                </MenuItem>
                                <MenuItem value={3}>
                                    Pickup Confirmed
                                </MenuItem>
                                <MenuItem value={40}>
                                    Deliver Unconfirmed
                                </MenuItem>
                                <MenuItem value={4}>
                                    Deliver Confirmed
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        ((+orderStatus !== +originalOrderStatus) || (+shippingStatus !== +originalShippingStatus)) &&
                        <Grid item md={12} xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        },
                                    }}
                                    size='small'
                                    onClick={handleUpdateOrderStatus}
                                    loading={loading}
                                >
                                    <Typography style={{
                                        textTransform: 'none',
                                    }}>
                                        Update
                                    </Typography>
                                </LoadingButton>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        </Grid>
    )
}