import React, { useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { CSVLink } from "react-csv";
import axios from "axios";
import { PARTNER_URI } from "../../utils/apiUrl";
import dayjs from "dayjs";

const ExportLtlOrdersButton = () => {

  const newExcelRowHeader = [
    "Order ID",
    "BOL #",
    "Partner Id",
    "Partner Company Name",
    "Carrier",
    "Package Details",
    "Shipping Fee",
    "Service Fee",
    "Cross Border Fee",
    "Duty Tax",
    "Total Charge",
    "Tax",
    "Final Total",
    "Date",
    "Send From City",
    "Send From Province",
    "Send From Country",
    "Send From Postal Code",
    "Send To City",
    "Send To Province",
    "Send To Country",
    "Send To Postal Code",
    "Special Services",
    "Status",
    "CRM Invoice Ref"
  ];

  const [orders, setOrders] = useState([
    newExcelRowHeader
  ]);

  const csvLink = useRef();

  const getLTLOrdersData = async () => {
    let requestURI = `${PARTNER_URI}/shippingLTL/getAllShippingLTLOrderListWithDetails`;
    const { data } = await axios.get(requestURI, {
      params: {

      }
    });

    const resultArray = [
      newExcelRowHeader
    ];
    for (const column of data) {
      const {
        order_id: orderId,
        bol_no: bolNumber,
        sender_id: senderId,
        business_name: partnerCompanyName,
        account_base_combine_name: accountBase,
        packages=[],
        shipping_fee: shippingFee,
        service_fee: serviceFee,
        total,
        taxes,
        final_total: finalTotal,
        cross_border_fee: crossBorderFee,
        duty_tax: dutyTax,
        status_id: statusId,
        pickup_area: pickupArea,
        pickup_postal_code: pickupPostalCode,
        send_from_country: pickupCountry,
        deliver_area: deliverArea,
        deliver_postal_code: deliverPostalCode,
        send_to_country: deliverCountry,
        crmInvoiceRefNumber,
        created_at: createdDate,
        specialServiceContent
      } = column;

      const [pickupCity, pickupProvince] = pickupArea.split(", ");
      const [deliverCity, deliverProvince] = deliverArea.split(", ");

      let status = "Initial";
      switch (+statusId) {
        case 0:
          status = "Initial";
          break;
        case 1:
          status = "Confirm";
          break;
        case -1:
          status = "Cancel";
          break;
        default:
          status = "Refund"
      }

      let packagesContent = "";
      if (packages) {
        for (let index = 0; index < packages?.length; index++) {
          const onePackage = packages[index];
          const packageContent = `Item ${index + 1}:\nDimensions(${onePackage?.dimension_unit}): ${onePackage?.length} (L) * ${onePackage.width} (W) * ${onePackage?.height} (H)\nWeight(${onePackage?.weight_unit}): ${onePackage?.weight}\nQuantity: ${onePackage?.number}\n\n`;
          packagesContent += packageContent;
        }
      }


      const columnData = [];
      columnData.push(orderId);
      columnData.push(bolNumber);
      columnData.push(senderId);
      columnData.push(partnerCompanyName);
      columnData.push(accountBase);

      columnData.push(packagesContent);

      columnData.push(shippingFee);
      columnData.push(serviceFee);
      columnData.push(crossBorderFee);
      columnData.push(dutyTax);
      columnData.push(total);
      columnData.push(taxes);
      columnData.push(finalTotal);
      columnData.push(dayjs(createdDate).format("YYYY-MM-DD"));

      columnData.push(pickupCity);
      columnData.push(pickupProvince);
      columnData.push(pickupCountry);
      columnData.push(pickupPostalCode);

      columnData.push(deliverCity);
      columnData.push(deliverProvince);
      columnData.push(deliverCountry);
      columnData.push(deliverPostalCode);

      columnData.push(specialServiceContent);
      columnData.push(status);
      columnData.push(crmInvoiceRefNumber);
      resultArray.push(columnData);
    }
    setOrders(resultArray);
    csvLink.current.link.click();
  }

  return (
    <>
      <Button
        onClick={getLTLOrdersData}
        variant="contained"
        color="secondary"
        style={{
          textTransform: "unset",
          fontSize: "1rem",
          display: "flex",
          padding: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center"
        }}>
        Export Data
      </Button>
      <CSVLink
        data={orders}
        filename={"LTL Orders"}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>

  );
};

export default ExportLtlOrdersButton;
