import { createSlice } from "@reduxjs/toolkit";

export const searchShipmentSlice = createSlice({
  name: "searchShipmentPreference",
  initialState: {
    page: 1,
    perPage: 10,
    shipmentNo: "",
    orderNo: "",
    senderName: "",
    shippingStatus: "",

    pickupCity: "",
    deliverCity: "",
    pickupProvince: "",
    deliverProvince: "",

    departureArea: "",
    arrivalArea: "",
    createdDateFrom: "",
    createdDateTo: "",

    deliveredDateFrom: "",
    deliveredDateTo: "",
    driverName: "",
  },
  reducers: {
    setSearchField: (state, action) => {
      state[action.payload.fieldName] = action.payload.value;
    },
  },
});

export const { setSearchField } = searchShipmentSlice.actions;

export default searchShipmentSlice.reducer;
