import { configureStore } from "@reduxjs/toolkit";
import searchShipmentReducer from "./shipment/searchShipmentSlice";
import updateShipmentInfo from "./shipment/updateShipmentSlice";
import HSCodeList from "./HSCode/HSCodeListSlice";
import { userReducer } from "../slices/userSlice";
import { ordersReducer } from "../slices/ordersSlice";
import {menuBarReducer} from "../component/Reducer/menuBarReducer";
import {adminReducer} from "../component/Reducer/adminReducer";
import {packageOrderReducer} from "../component/Reducer/packageOrderReducer";

export default configureStore({
  reducer: {
    shipmentSearchPreference: searchShipmentReducer,
    updateShipmentInfo: updateShipmentInfo,
    HSCodeList: HSCodeList,
    user: userReducer,
    orders: ordersReducer,
    menuBarReducer: menuBarReducer,
    adminReducer: adminReducer,
    packageOrderReducer: packageOrderReducer
  },
});
