import {Divider, Grid, Typography} from "@mui/material";
import {Fragment, useState} from "react";
import {MovingCategoryAreaListEach} from "./MovingCategoryAreaListEach";

export const MovingCategoryAreaListTable = ({areaList, getAreaCategoryPricePolicyListByCategoryId}) => {

    console.log('[MovingCategoryAreaListTable] areaList', areaList);

    const [policyId, setPolicyId] = useState(null);

    const cbGetPolicyId = (value) => {
        setPolicyId(value);
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={2}>
                <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                    Cover Area Group
                </Typography>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Pickup / Deliver
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Labours
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Working Hour
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Unit Cost
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                            Address Features
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Typography style={{fontWeight: '600'}}>
                    Action
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Divider/>
            </Grid>
            {
                areaList?.map((areaEach, index) =>
                    <Fragment key={index}>
                        <MovingCategoryAreaListEach area_info={areaEach} cbGetPolicyId={cbGetPolicyId}
                                                    policyId={policyId}
                                                    getAreaCategoryPricePolicyListByCategoryId={getAreaCategoryPricePolicyListByCategoryId}/>
                    </Fragment>
                )
            }
        </Grid>
    )
}