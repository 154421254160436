import {FormLabel, Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {PARTNER_URI} from "../../utils/apiUrl";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {extractFullAddress} from "../../utils/Helper";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

export const LTLQuickQuoteLocation = ({cbHandleGetShipFrom, cbHandleGetShipTo, validationList}) => {

    const classes = useStyles();

    const [movingFromAddressPredictions, setMovingFromAddressPredictions] = useState([]);
    const [movingToAddressPredictions, setMovingToAddressPredictions] = useState([]);
    const [movingFromPostalCodePredictions, setMovingFromPostalCodePredictions] = useState([]);
    const [movingToPostalCodePredictions, setMovingToPostalCodePredictions] = useState([]);
    const [shipFrom, setShipFrom] = useState({
        city: null,
        parentCity: null,
        province: null,
        region: null,
        postalCode: null,
        country: null
    });
    const [shipTo, setShipTo] = useState({
        city: null,
        parentCity: null,
        province: null,
        region: null,
        postalCode: null,
        country: null
    });

    const googleMapsPlaceAutocomplete = async (inputValue, type) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeAutocomplete`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    input: inputValue,
                    types: type
                }
            })
            const {data: {data: {predictions}}} = result;
            const updatedPredictions = predictions?.map(each => {
                return {
                    description: each?.description,
                    text: each?.structured_formatting?.main_text,
                    placeId: each?.place_id
                }
            })
            return updatedPredictions;
        } catch (e) {
            console.log(e.response)
        }
    }

    const googleMapsPlaceDetails = async (placeId) => {
        let requestURL = `${PARTNER_URI}/googleMaps/placeDetails`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    placeId: placeId
                }
            });
            const place = result?.data?.data?.result;
            return place;

        } catch (e) {
            console.log(e.response)
        }
    }

    const handleInputShipFromCity = async (event, value) => {
        setShipFrom(prevState => ({
            ...prevState,
            city: value,
            parentCity: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality|neighborhood|postal_code|administrative_area_level_2');
        console.log('[handleInputShipFromCity] predictions', predictions);
        setMovingFromAddressPredictions(predictions);
    }

    const handleInputShipToCity = async (event, value) => {
        setShipTo(prevState => ({
            ...prevState,
            city: value,
            parentCity: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'locality|sublocality|neighborhood|postal_code|administrative_area_level_2');
        setMovingToAddressPredictions(predictions);
    }

    const handleChangeShipFromCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship from', address);
            setShipFrom(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                parentCity: address?.city || address?.administrative_area_level_3 || address?.region,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,
                },
                displayCity: {
                    description: null,
                    placeId: null,
                    text: address?.sublocality || address?.city || address?.administrative_area_level_3,
                },
                postalCode: address?.zip,
                isPostalCode: !!address?.zip,
                region: address?.region
            }))
        } else {
            setShipFrom(prevState => ({
                ...prevState,
                city: null,
                parentCity: null,
                province: null,
                postalCode: null,
                country: null,
                displayCity: {
                    description: null,
                    placeId: null,
                    text: null
                },
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: null
                },
                isPostalCode: false,
                region: null
            }))
        }
    }

    const handleChangeShipToCity = async (event, value) => {
        console.log('select', value);
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from city ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                city: address?.sublocality || address?.city || address?.administrative_area_level_3,
                parentCity: address?.city || address?.administrative_area_level_3 || address?.region,
                province: {
                    name: address?.state?.name,
                    code: address?.state?.code,
                },
                country: {
                    code: address?.country?.code,
                    name: address?.country?.name,
                },
                displayCity: {
                    description: null,
                    placeId: null,
                    text: address?.sublocality || address?.city || address?.administrative_area_level_3,
                },
                postalCode: address?.zip,
                isPostalCode: !!address?.zip,
                region: address?.region
            }))
        } else {
            setShipTo(prevState => ({
                ...prevState,
                city: null,
                parentCity: null,
                province: null,
                postalCode: null,
                country: null,
                displayCity: {
                    description: null,
                    placeId: null,
                    text: null
                },
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: null
                },
                isPostalCode: false,
                region: null
            }))
        }
    }

    const handleInputShipFromPostalCode = async (event, value) => {
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipFromPostalCode - value', value);
        setShipFrom(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipFromPostalCode - predictions', predictions);
        // const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(shipFrom?.city));
        setMovingFromPostalCodePredictions(predictions);
    }

    const handleInputShipToPostalCode = async (event, value) => {
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipToPostalCode - value', value);
        setShipTo(prevState => ({
            ...prevState,
            postalCode: value
        }))
        const predictions = await googleMapsPlaceAutocomplete(value, 'postal_code');
        console.log('[ShippingLTLQuickQuoteAddress] handleInputShipToPostalCode - predictions', predictions);
        // const filterPredictions = predictions.filter(address => address?.structured_formatting?.secondary_text.includes(shipTo?.city));
        setMovingToPostalCodePredictions(predictions);
    }

    const handleChangeShipFromPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from postal code ship to', address);
            setShipFrom(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: address?.zip,
                }
            }))
        }
    }

    const handleChangeShipToPostalCode = async (event, value) => {
        const place = await googleMapsPlaceDetails(value?.placeId);
        if (place) {
            const address = extractFullAddress(place);
            console.log('address from postal code ship to', address);
            setShipTo(prevState => ({
                ...prevState,
                postalCode: address?.zip,
                displayPostalCode: {
                    description: null,
                    placeId: null,
                    text: address?.zip,
                }
            }))
        }
    }

    useEffect(() => {
        cbHandleGetShipFrom(shipFrom);
    }, [shipFrom]);

    useEffect(() => {
        cbHandleGetShipTo(shipTo);
    }, [shipTo]);

    // useEffect(() => {
    //     if (shipFrom?.country?.code?.toLowerCase() !== 'us' && shipTo?.country?.code?.toLowerCase() !== 'us') {
    //         console.log('change postal code');
    //         setShipFrom(prevState => ({
    //             ...prevState,
    //             postalCode: ''
    //         }))
    //         setShipTo(prevState => ({
    //             ...prevState,
    //             postalCode: ''
    //         }))
    //     }
    // }, [shipFrom?.country?.code, shipTo?.country?.code])

    console.log('[LTLQuickQuoteLocation] ship from', shipFrom);
    console.log('[LTLQuickQuoteLocation] ship to', shipTo);
    console.log('[LTLQuickQuoteLocation] movingFromAddressPredictions', movingFromAddressPredictions);

    return (
        <>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Ship From
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    City or Postal Code
                                </FormLabel>
                                <Autocomplete
                                    value={shipFrom?.displayCity}
                                    freeSolo
                                    options={movingFromAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    onInputChange={handleInputShipFromCity}
                                    onChange={handleChangeShipFromCity}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                        error={validationList?.moveFromMissing?.includes('city')}
                                        helperText={validationList?.moveFromMissing?.includes('city') && 'required field'}
                                        // placeholder={'Moving To City'}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '14px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {
                            ((shipFrom?.country?.code?.toLowerCase() === 'us' || shipTo?.country?.code?.toLowerCase() === 'us') && !shipFrom?.isPostalCode) &&
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel
                                        required={(shipFrom?.country?.code?.toLowerCase() === 'us' || shipTo?.country?.code?.toLowerCase() === 'us')}
                                        sx={{textAlign: 'left'}}>
                                        Postal Code
                                    </FormLabel>
                                    <Autocomplete
                                        value={shipFrom?.displayPostalCode}
                                        freeSolo
                                        filterOptions={(options, state) => options}
                                        options={movingFromPostalCodePredictions}
                                        getOptionLabel={option => option?.text}
                                        onInputChange={handleInputShipFromPostalCode}
                                        onChange={handleChangeShipFromPostalCode}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            error={validationList && (validationList?.shipFromPostalCodeMissing || !validationList?.shipFromPostalCodeValid)}
                                            helperText={validationList && (validationList?.shipFromPostalCodeMissing ? 'required field' : !validationList?.shipFromPostalCodeValid && 'invalid postal code')}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Ship To
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <FormLabel required sx={{textAlign: 'left'}}>
                                    City or Postal Code
                                </FormLabel>
                                <Autocomplete
                                    value={shipTo?.displayCity}
                                    freeSolo
                                    options={movingToAddressPredictions}
                                    filterOptions={(options, state) => options}
                                    getOptionLabel={option => option?.description}
                                    onInputChange={handleInputShipToCity}
                                    onChange={handleChangeShipToCity}
                                    fullWidth
                                    PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                    renderInput={params => <TextField
                                        {...params}
                                        variant='outlined'
                                        className={classes.smallInput}
                                        error={validationList?.moveToMissing?.includes('city')}
                                        helperText={validationList?.moveToMissing?.includes('city') && 'required field'}
                                    />}
                                    renderOption={(option) => (
                                        <Typography
                                            style={{fontSize: '14px'}}
                                        >
                                            {/*<Typography>*/}
                                            {option.description}
                                            {/*</Typography>*/}
                                        </Typography>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        {
                            ((shipFrom?.country?.code?.toLowerCase() === 'us' || shipTo?.country?.code?.toLowerCase() === 'us') && !shipTo?.isPostalCode) &&
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <FormLabel
                                        required={(shipFrom?.country?.code?.toLowerCase() === 'us' || shipTo?.country?.code?.toLowerCase() === 'us')}
                                        sx={{textAlign: 'left'}}>
                                        Postal Code
                                    </FormLabel>
                                    <Autocomplete
                                        value={shipTo?.displayPostalCode}
                                        freeSolo
                                        filterOptions={(options, state) => options}
                                        options={movingToPostalCodePredictions}
                                        getOptionLabel={option => option?.text}
                                        onInputChange={handleInputShipToPostalCode}
                                        onChange={handleChangeShipToPostalCode}
                                        fullWidth
                                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                                        renderInput={params => <TextField
                                            {...params}
                                            variant='outlined'
                                            className={classes.smallInput}
                                            error={validationList && (validationList?.shipToPostalCodeMissing || !validationList?.shipToPostalCodeValid)}
                                            helperText={validationList && (validationList?.shipToPostalCodeMissing ? 'required field' : !validationList?.shipToPostalCodeValid && 'invalid postal code')}
                                        />}
                                        renderOption={(option) => (
                                            <Typography
                                                style={{fontSize: '12px'}}
                                            >
                                                {/*<Typography>*/}
                                                {option.description}
                                                {/*</Typography>*/}
                                            </Typography>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}