import {Container, useMediaQuery} from "@mui/material";
import {LTLQuoteList} from "./LTLQuoteList";
import {LTLQuickQuote} from "./LTLQuickQuote";
import Box from "@mui/material/Box";
import {FormLabel, Grid, Typography} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Checkbox from "@mui/material/Checkbox";
import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {LTLQuoteAdd} from "./LTLQuoteAdd";
import ServiceFeeSettingTableForLTL from "../ServiceFeeSetting/LTL/ServiceFeeSettingTableForLTL";

const styles = {
    ShippingLTLQuickQuoteSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    ShippingLTLQuickQuoteSection2nd: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    ShippingPackageQuickQuoteHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
}

export const LTLQuote = () => {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const statueValue = location?.state;

    const isMobile = useMediaQuery('(max-width: 600px)');
    const [type, setType] = useState('quickQuote');

    const handleChangeType = (value) => {
        setType(value);
    }

    const directToLTLOrder = () => {
        if (type === 'orderList') {
            history.push('/v2/ltl-order-list');
        } else {
            history.push('/v2/ltl-order-list/add');
        }
    }

    useEffect(() => {
        const queryObject = {};
        for (let [key, value] of searchParams.entries()) {
            queryObject[key] = value;
        }
        setType(queryObject?.type || 'quickQuote');
    }, [])

    useEffect(() => {
        if (statueValue) {
            setType(statueValue);
        }
    }, [statueValue])

    console.log('[LTLQuote] searchParams', searchParams);
    console.log('[LTLQuote] state', statueValue);

    return (
        <Container maxWidth="xl">
            <Box sx={styles.ShippingLTLQuickQuoteSection}>
                <Box
                    sx={{
                        width: isMobile ? '300px' : '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        gap: '10px'
                    }}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'quickQuote'}
                            onClick={() => handleChangeType('quickQuote')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quick Quote
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'quoteList'}
                            onClick={() => handleChangeType('quoteList')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Quote List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'addQuote'}
                            onClick={() => handleChangeType('addQuote')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Add Quote
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'orderList'}
                            onClick={() => handleChangeType('orderList')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            LTL Order List
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'addOrder'}
                            onClick={() => handleChangeType('addOrder')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Add Order
                        </Typography>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Checkbox
                            icon={<RadioButtonUncheckedIcon/>}
                            checkedIcon={<CheckCircleIcon/>}
                            color="success"
                            checked={type === 'setting'}
                            onClick={() => handleChangeType('setting')}
                        />
                        <Typography style={{color: '#545454', padding: '0 5px', fontSize: '20px'}}>
                            Service Fee Setting
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {
                type === 'quoteList' ? <LTLQuoteList/> :
                    type === 'quickQuote' ? <LTLQuickQuote/> :
                        type === 'addQuote' ? <LTLQuoteAdd handleChangeType={handleChangeType}/> :
                            type === 'setting' ? <ServiceFeeSettingTableForLTL/> :
                                directToLTLOrder()
            }
        </Container>
    )
}