import {Fragment, useEffect, useState} from "react";
import YearPicker from '@mui/lab/YearPicker';
import {Grid, Typography} from "@mui/material";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";

export const CarQuoteYear = ({getDataFromChildComponent, triggerAction, isConfirm, validation}) => {

    const [carYear, setCarYear] = useState(null);

    const handleYearChange = (year) => {
        console.log('year', year);
        setCarYear(year);
    };

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({carYear});
        }
    }, [triggerAction])

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography style={{textAlign: 'left', fontSize: '18px', fontWeight: '600'}}>
                        Year
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            views={['year']}
                            value={carYear}
                            disabled={isConfirm}
                            onChange={(newValue) => {
                                handleYearChange(newValue);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params} fullWidth
                                    size='small'
                                    error={validation?.carYearMissing}
                                    helperText={
                                        (validation?.carYearMissing) && 'Required Field'
                                    }
                                />}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
        </Fragment>

    )
}