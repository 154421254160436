import {Fragment, useEffect, useState} from "react";
import {FormLabel, Grid, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldNumber} from "../../utils/customComponent";
import TextField from "@mui/material/TextField";
import {calculateTaxes} from "../../utils/Helper";

export const LTLOrderListShippingCost = ({getDataFromChildComponent, triggerAction, isConfirm}) => {

    const [shippingFee, setShippingFee] = useState(0);
    const [serviceFee, setServiceFee] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [subtotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [total, setTotal] = useState(0);
    const [crossBorderFee, setCrossBorderFee] = useState(0);
    const [taxList, setTaxList] = useState([]);
    const [selectTax, setSelectTax] = useState(null);

    const onChange = (field, value) => {
        switch (field) {
            case 'shippingFee':
                setShippingFee(+value);
                break;
            case 'serviceFee':
                setServiceFee(+value);
                break;
            case 'taxes':
                setTaxes(+value);
                break;
            case 'discount':
                setDiscount(+value);
                break;
            case 'crossBorderFee':
                setCrossBorderFee(+value);
                break;
            default:
        }
    }

    const handleChange = (event) => {
        console.log(event.target.value);
        setTaxes(+event.target.value.amount);
        setSelectTax(event.target.value);
    };

    useEffect(() => {
        setTaxes(0);
        setSelectTax(0);
    },[taxList])

    useEffect(() => {
        const subtotalCost = +shippingFee + +serviceFee - +discount;
        setSubTotal(subtotalCost.toFixed(2));
    }, [shippingFee, serviceFee, discount]);

    useEffect(() => {
        const taxes = calculateTaxes(subtotal);
        setTaxList(taxes);
    }, [subtotal]);

    useEffect(() => {
        const totalCost = +subtotal + +taxes + +crossBorderFee;
        setTotal(totalCost.toFixed(2));
    }, [taxes, subtotal, crossBorderFee]);

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({
                shippingCost: {
                    shippingFee: +shippingFee + +crossBorderFee,
                    serviceFee,
                    taxCharge: taxes,
                    totalCharge: subtotal,
                    discount,
                    crossBorderFee,
                    finalTotal: total,

                }
            });
        }
    }, [triggerAction])

    // console.log('shipping fee', shippingFee);
    // console.log('service fee', serviceFee);
    console.log('taxes', taxes);
    // console.log('subtotal', subtotal);
    // console.log('discount', discount);
    // console.log('total', total);

    return (
        <Fragment>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Shipping Fee <span style={{fontSize: '12px'}}>(include special service fee)</span>
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextFieldNumber
                            field='shippingFee'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Service Fee
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextFieldNumber
                            field='serviceFee'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Discount
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <CustomTextFieldNumber
                            field='discount'
                            onChange={onChange}
                            isConfirm={isConfirm}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Subtotal
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            value={subtotal}
                            size='small'
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Taxes
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Select
                                value={taxes}
                                size='small'
                                onChange={handleChange}
                                renderValue={() => selectTax?.amount || 0}
                                disabled={isConfirm}
                                style={{textAlign: 'left'}}
                            >
                                {
                                    taxList?.map((tax, index) => (
                                        <MenuItem key={index} value={tax}>
                                            {tax?.percent} | {tax?.amount}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {/*<CustomTextFieldNumber*/}
                            {/*    field='taxCharge'*/}
                            {/*    onChange={onChange}*/}
                            {/*    isConfirm={isConfirm}*/}
                            {/*/>*/}
                        </FormControl>
                    </Grid>

                    {/*<Grid item xs={3}>*/}
                    {/*    <CustomTextFieldNumber*/}
                    {/*        field='taxes'*/}
                    {/*        onChange={onChange}*/}
                    {/*        isConfirm={isConfirm}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Cross Border Fee
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <CustomTextFieldNumber
                                field='crossBorderFee'
                                onChange={onChange}
                                isConfirm={isConfirm}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel sx={{textAlign: 'left'}}>
                                Final Total
                            </FormLabel>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            value={total}
                            size='small'
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )

}