import {Grid, Link, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
    ShippingOrderList: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}


export const LTLQuickQuoteOptions = ({cbHandleGetOptions}) => {

    const [isBattery, setIsBattery] = useState(false);
    const [batteryDetails, setBatteryDetails] = useState({
        material: null,
        packing: null,
        quantity: 0
    })

    const handleIsBattery = event => {
        setIsBattery(event?.target?.checked);
        if (event?.target?.checked) {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: 'LITHIUM_ION',
                packing: 'CONTAINED_IN_EQUIPMENT',
                quantity: 1
            }))
        } else {
            setBatteryDetails(prevState => ({
                ...prevState,
                material: null,
                packing: null,
                quantity: 0
            }))
        }
    }

    const handleBatteryType = (type) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            material: type
        }))
    }

    const handleBatteryPackaged = (packaged) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            packing: packaged
        }))
    }

    const handleBatteryQuantity = (event) => {
        setBatteryDetails(prevState => ({
            ...prevState,
            quantity: event.target.value < 1 ? '1' : event.target.value
        }))
    }

    useEffect(() => {
        cbHandleGetOptions(batteryDetails);
    },[batteryDetails])

    console.log('[LTLQuickQuoteOptions] batteryDetails', batteryDetails);

    return (
        <>
            <Grid item xs={12}>
                <Box sx={styles.ShippingOrderList}>
                    <Typography sx={{fontSize: '18px', fontWeight: '600', textAlign: 'left'}}>
                        Shipping Options
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                            }}>
                                <FormControlLabel
                                    value="start"
                                    control={<Checkbox
                                        checked={isBattery}
                                        onChange={handleIsBattery}
                                        color="success"
                                    />}
                                    label={
                                        <Typography style={{textAlign: 'left'}}>
                                            Include lithium batteries (hazardous material)
                                        </Typography>
                                    }
                                    labelPlacement="end"
                                />
                                <Box sx={{display: 'flex', gap: '5px'}}>
                                    <Typography style={{fontSize: '14px', textAlign: 'left'}}>
                                        This shipment contains dangerous goods or hazardous materials
                                    </Typography>
                                    <Typography style={{
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        fontWeight: '600',
                                    }}>
                                        <Link
                                            href='https://uucargo.ca/support/shipment-of-items-containing-lithium-batteries-with-uucargo/'
                                            underline='none'
                                            target='_blank'
                                            color='#1D8B45'
                                        >
                                            Learn More
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        {
                            isBattery &&
                            <>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                    }}>
                                        <Typography>
                                            Battery Type
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            gap: '10px',
                                            // flexDirection: isMobile && 'column',
                                            alignItems: 'flex-start'
                                        }}>
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    checked={batteryDetails?.material === 'LITHIUM_ION'}
                                                    onChange={() => handleBatteryType('LITHIUM_ION')}
                                                    color="success"
                                                    icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                    checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                                />}
                                                label={
                                                    <Typography>
                                                        Lithium Ion
                                                    </Typography>
                                                }
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    checked={batteryDetails?.material === 'LITHIUM_METAL'}
                                                    onChange={() => handleBatteryType('LITHIUM_METAL')}
                                                    color="success"
                                                    icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                    checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                                />}
                                                label={
                                                    <Typography>
                                                        Lithium Metal
                                                    </Typography>
                                                }
                                                labelPlacement="end"
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                    }}>
                                        <Typography>
                                            How is it packaged?
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            gap: '10px',
                                            // flexDirection: isMobile && 'column',
                                            alignItems: 'flex-start'
                                        }}>
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    checked={batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT'}
                                                    onChange={() => handleBatteryPackaged('CONTAINED_IN_EQUIPMENT')}
                                                    color="success"
                                                    icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                    checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                                />}
                                                label={
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Contained or installed in equipment
                                                    </Typography>
                                                }
                                                labelPlacement="end"
                                            />
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    checked={batteryDetails?.packing === 'PACKED_WITH_EQUIPMENT'}
                                                    onChange={() => handleBatteryPackaged('PACKED_WITH_EQUIPMENT')}
                                                    color="success"
                                                    icon={<RadioButtonUncheckedIcon sx={{width: '20px'}}/>}
                                                    checkedIcon={<CheckCircleIcon sx={{width: '20px'}}/>}
                                                />}
                                                label={
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Packaged separately with other equipment
                                                    </Typography>
                                                }
                                                labelPlacement="end"
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                    }}>
                                        <Typography>
                                            Battery Quantity
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            gap: '10px',
                                            // flexDirection: isMobile && 'column',
                                            alignItems: 'flex-start'
                                        }}>
                                            <TextField
                                                value={batteryDetails?.quantity}
                                                size='small'
                                                onInput={handleBatteryQuantity}
                                                type='number'
                                                InputProps={{
                                                    inputProps: {
                                                        min: 1
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}