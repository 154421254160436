import {Button, FormHelperText, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldNormal, CustomTextFieldNumberForCost} from "../../utils/customComponent";
import {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../utils/apiUrl";
import {useParams} from "react-router-dom";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import {makeStyles} from "@material-ui/core/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    },
    smallInput: {
        "& .MuiInputBase-input": {
            height: "2px", // Adjust the height as per your preference
            padding: "2px", // Adjust the padding as per your preference
            fontSize: "14px", // Adjust the font size as per your preference
        },
    }
});

export const MovingCategoryAreaAdd = ({handleCloseNewArea, getAreaCategoryPricePolicyListByCategoryId}) => {

    const {cateId} = useParams();
    const classes = useStyles();

    const [isConfirm, setIsConfirm] = useState(false);
    const [validation, setValidation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const [areaList, setAreaList] = useState([]);
    const [areaId, setAreaId] = useState(null);
    const [areaGroupId, setAreaGroupId] = useState(null);
    const [selfPickupCharge, setSelfPickupCharge] = useState(0);
    const [pickupMinCharge, setPickupMinCharge] = useState(0);
    const [deliverMinCharge, setDeliverMinCharge] = useState(0);
    const [labourHourCost, setLabourHourCost] = useState(0);
    const [wrappingUnitCost, setWrappingUnitCost] = useState(0);
    const [packingUnitCost, setPackingUnitCost] = useState(0);
    const [storageDailyUnitCost, setStorageDailyUnitCost] = useState(0);
    const [storageWeeklyUnitCost, setStorageWeeklyUnitCost] = useState(0);
    const [storageMonthlyUnitCost, setStorageMonthlyUnitCost] = useState(0);
    const [minLabours, setMinLabours] = useState(0);
    const [minMovingHours, setMinMovingHours] = useState(0);
    const [minWrappingHours, setMinWrappingHours] = useState(0);
    const [minPackingHours, setMinPackingHours] = useState(0);
    const [minAssemblyHours, setMinAssemblyHours] = useState(0);
    const [minDisassemblyHours, setMinDisassemblyHours] = useState(0);
    const [floorLevel, setFloorLevel] = useState(1);
    const [houseType, setHouseType] = useState('House');
    const [drivewaySteepLevel, setDrivewaySteepLevel] = useState(0);
    const [hasLift, setHasLift] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const searchAreaAndAreaGroup = async (input) => {
        let requestURL = `${NODE_ROUTE_URI}/backendAreaAndAreaGroup/searchBackendAreaAndAreaGroup`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                params: {
                    queryString: input,
                    areaType: 2,
                    page: 0,
                    pageSize: 20
                }
            })

            const {data: {data}} = result
            console.log('[searchAreaAndAreaGroup] data', data);
            return data;

        } catch (e) {
            console.log(e.response)
        }

    }

    const handleInputArea = async (event, value) => {

        const areaData = await searchAreaAndAreaGroup(value);
        // console.log('[handleInputShipFromCity] predictions', predictions);
        setAreaList(areaData);
    }

    const handleChangeArea = async (event, value) => {
        console.log('[handleChangeArea] value', value);
        setAreaId(value?.area_id);
        if (+value?.belongto_group_id !== 0) {
            setAreaGroupId(value?.belongto_group_id);
        } else {
            setAreaGroupId(value?.group_id)
        }
    }

    const onChange = (field, value) => {
        switch (field) {
            case 'selfPickupCharge':
                setSelfPickupCharge(value);
                break;
            case 'pickupMinCharge':
                setPickupMinCharge(value);
                break;
            case  'deliverMinCharge':
                setDeliverMinCharge(value);
                break;
            case  'labourHourCost':
                setLabourHourCost(value);
                break;
            case  'wrappingUnitCost':
                setWrappingUnitCost(value);
                break;
            case  'packingUnitCost':
                setPackingUnitCost(value);
                break;
            case  'storageDailyUnitCost':
                setStorageDailyUnitCost(value);
                break;
            case  'storageWeeklyUnitCost':
                setStorageWeeklyUnitCost(value);
                break;
            case  'storageMonthlyUnitCost':
                setStorageMonthlyUnitCost(value);
                break;
            case  'minLabours':
                setMinLabours(value);
                break;
            case  'minMovingHours':
                setMinMovingHours(value);
                break;
            case  'minWrappingHours':
                setMinWrappingHours(value);
                break;
            case  'minPackingHours':
                setMinPackingHours(value);
                break;
            case  'minAssemblyHours':
                setMinAssemblyHours(value);
                break;
            case  'minDisassemblyHours':
                setMinDisassemblyHours(value);
                break;
            default:
        }
    }

    const handleChangeHouseType = (event) => {
        const type = event.target.value;
        if (type === 'House' || type === 'Townhouse') {
            setHasLift(0);
        }
        setHouseType(event.target.value);
    }

    const handleChangeFloorLevel = (event) => {
        setFloorLevel(event.target.value);
    }

    const handleChangeDrivewaySteepLevel = (event) => {
        setDrivewaySteepLevel(event.target.value);
    }

    const handleChangeHasLift = (event) => {
        setHasLift(event.target.value);
    }

    const addAreaCategoryPricePolicy = async () => {
        setLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/movingCategory/addAreaCategoryPricePolicy`;

        let data = {
            cateId,
            areaId,
            areaGroupId,
            selfPickupCharge,
            pickupMinCharge,
            deliverMinCharge,
            labourHourCost,
            wrappingUnitCost,
            packingUnitCost,
            storageDailyUnitCost,
            storageWeeklyUnitCost,
            storageMonthlyUnitCost,
            minLabours,
            minMovingHours,
            minWrappingHours,
            minPackingHours,
            minAssemblyHours,
            minDisassemblyHours,
            floorLevel,
            houseType,
            drivewaySteepLevel,
            hasLift
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            console.log('[MovingCategoryAreaAdd] addAreaCategoryPricePolicy api - result', result);
            setErrorMessage('');
            setSuccessMessage('Successful Add a New Area Price Policy');
            handleSuccessMessage();
            setIsConfirm(false);
            handleCloseNewArea();
            getAreaCategoryPricePolicyListByCategoryId();
        } catch (e) {
            console.log('[MovingCategoryAreaAdd] addAreaCategoryPricePolicy api - error', e?.response);
            setErrorMessage('Fail To Add a New Area Price Policy');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const handleConfirm = () => {
        const validationResult = validate();
        if (validationResult) {
            setIsConfirm(true);
        }
    }

    const handleEdit = () => {
        setIsConfirm(false);
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const validation = {
            areaIdMissing: !areaId,
            selfPickupChargeError: selfPickupCharge < 0,
            pickupMinChargeError: pickupMinCharge < 0,
            deliverMinChargeError: deliverMinCharge < 0,
            labourHourCostError: labourHourCost < 0,
            wrappingUnitCostError: wrappingUnitCost < 0,
            packingUnitCostError: packingUnitCost < 0,
            storageDailyUnitCostError: storageDailyUnitCost < 0,
            storageWeeklyUnitCostError: storageWeeklyUnitCost < 0,
            storageMonthlyUnitCostError: storageMonthlyUnitCost < 0,
            minLaboursError: minLabours <= 0,
            minMovingHoursError: minMovingHours <= 0,
            minWrappingHoursError: minWrappingHours <= 0,
            minPackingHoursError: minPackingHours < 0,
            minAssemblyHoursError: minAssemblyHours < 0,
            minDisassemblyHoursError: minDisassemblyHours < 0
        }
        console.log('validation', validation);
        setValidation(validation);
        (!areaId) && setErrorMessage(prevState => prevState + 'Select Area is missing.');
        selfPickupCharge < 0 && setErrorMessage(prevState => prevState + 'Self Pickup Charge is smaller than 0.');
        pickupMinCharge < 0 && setErrorMessage(prevState => prevState + 'Minimum Pickup Charge is smaller than 0.');
        deliverMinCharge < 0 && setErrorMessage(prevState => prevState + 'Minimum Deliver Charge is smaller than 0.');
        labourHourCost < 0 && setErrorMessage(prevState => prevState + 'Labour Hour Cost is smaller than 0.');
        wrappingUnitCost < 0 && setErrorMessage(prevState => prevState + 'Wrapping Unit Cost is smaller than 0.');
        packingUnitCost < 0 && setErrorMessage(prevState => prevState + 'Packing Unit Cost is smaller than 0.');
        storageDailyUnitCost < 0 && setErrorMessage(prevState => prevState + 'Storage Daily Unit Cost is smaller than 0.');
        storageWeeklyUnitCost < 0 && setErrorMessage(prevState => prevState + 'Storage Weekly Unit Cost is smaller than 0.');
        storageMonthlyUnitCost < 0 && setErrorMessage(prevState => prevState + 'Storage Monthly UnitCost is smaller than 0.');
        minLabours <= 0 && setErrorMessage(prevState => prevState + 'Minimum Labours is smaller or equal to 0.');
        minMovingHours <= 0 && setErrorMessage(prevState => prevState + 'Minimum Moving Hours is smaller or equal to 0.');
        minWrappingHours <= 0 && setErrorMessage(prevState => prevState + 'Minimum Wrapping Hours is smaller or equal to 0.');
        minPackingHours < 0 && setErrorMessage(prevState => prevState + 'Minimum Packing Hours is smaller than 0.');
        minAssemblyHours < 0 && setErrorMessage(prevState => prevState + 'Minimum Assembly Hours is smaller than 0.');
        minDisassemblyHours < 0 && setErrorMessage(prevState => prevState + 'Minimum Disassembly Hours is smaller than 0.');

        if (areaId &&
            selfPickupCharge >= 0 &&
            pickupMinCharge >= 0 &&
            deliverMinCharge >= 0 &&
            labourHourCost >= 0 &&
            wrappingUnitCost >= 0 &&
            packingUnitCost >= 0 &&
            storageDailyUnitCost >= 0 &&
            storageWeeklyUnitCost >= 0 &&
            storageMonthlyUnitCost >= 0 &&
            minLabours > 0 &&
            minMovingHours > 0 &&
            minWrappingHours > 0 &&
            minPackingHours >= 0 &&
            minAssemblyHours >= 0 &&
            minDisassemblyHours >= 0
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleCreate = () => {
        // const validationResult = validate();
        // if (validationResult) {
        //     console.log('call api');
        addAreaCategoryPricePolicy();
        // }
    }

    // console.log('[MovingCategoryAreaAdd] cateId', cateId);
    // console.log('[MovingCategoryAreaAdd] area Id', areaId);
    // console.log('[MovingCategoryAreaAdd] area group Id', areaGroupId);
    // console.log('[MovingCategoryAreaAdd] selfPickupCharge', selfPickupCharge);
    // console.log('[MovingCategoryAreaAdd] pickupMinCharge', pickupMinCharge);
    // console.log('[MovingCategoryAreaAdd] deliverMinCharge', deliverMinCharge);
    // console.log('[MovingCategoryAreaAdd] labourHourCost', labourHourCost);
    // console.log('[MovingCategoryAreaAdd] wrappingUnitCost', wrappingUnitCost);
    // console.log('[MovingCategoryAreaAdd] packingUnitCost', packingUnitCost);
    // console.log('[MovingCategoryAreaAdd] storageDailyUnitCost', storageDailyUnitCost);
    // console.log('[MovingCategoryAreaAdd] storageWeeklyUnitCost', storageWeeklyUnitCost);
    // console.log('[MovingCategoryAreaAdd] storageMonthlyUnitCost', storageMonthlyUnitCost);
    console.log('[MovingCategoryAreaAdd] house type', houseType);
    console.log('[MovingCategoryAreaAdd] validation', validation);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Typography style={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    New Area Price Policy
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Select Area Group
                    </FormLabel>
                    <Autocomplete
                        options={areaList}
                        getOptionLabel={(option) => (option?.area_name
                            ? `${option.area_name}, ${option.province_shortname}`
                            : (option?.belongto_group_name
                                    ? `${option.belongto_group_name}, ${option.province_shortname}`
                                    : (option?.group_name
                                            ? `${option.group_name}, ${option.province_shortname}`
                                            : `${option.province_shortname}`
                                    )
                            ))}
                        filterOptions={(options, state) => options}
                        PaperComponent={props => <Paper {...props} sx={{width: '100%'}}/>}
                        fullWidth
                        disabled={isConfirm}
                        renderInput={(params => <TextField
                            {...params}
                            className={classes.smallInput}
                            error={validation?.areaIdMissing}
                            helperText={validation?.areaIdMissing && 'Required'}
                        />)}
                        onInputChange={handleInputArea}
                        onChange={handleChangeArea}
                        renderOption={(option) => (
                            <Typography
                                style={{fontSize: '14px'}}
                            >
                                {/*<Typography>*/}
                                {option?.area_name || option?.belongto_group_name || option?.group_name}, {option?.province_shortname}
                                {/*</Typography>*/}
                            </Typography>
                        )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Self Pickup Charge
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='selfPickupCharge'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.selfPickupChargeError}
                        placeHolder={10}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Pickup Charge
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='pickupMinCharge'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.pickupMinChargeError}
                        placeHolder={30}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Deliver Charge
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='deliverMinCharge'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.deliverMinChargeError}
                        placeHolder={30}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Labour Hour Cost
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='labourHourCost'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.labourHourCostError}
                        placeHolder={25}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Storage Daily Unit Cost
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='storageDailyUnitCost'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.storageDailyUnitCostError}
                        placeHolder={10}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Storage Weekly Unit Cost
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='storageWeeklyUnitCost'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.storageWeeklyUnitCostError}
                        placeHolder={50}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Storage Monthly Unit Cost
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='storageMonthlyUnitCost'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.storageMonthlyUnitCostError}
                        placeHolder={150}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Picking Unit Cost
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='packingUnitCost'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.packingUnitCostError}
                        placeHolder={5}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Wrapping Unit Cost
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='wrappingUnitCost'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.wrappingUnitCostError}
                        placeHolder={5}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Labours
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='minLabours'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.minLaboursError}
                        placeHolder={2}
                        errorMessage={'Greater than zero'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Moving Hour
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='minMovingHours'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.minMovingHoursError}
                        placeHolder={0.5}
                        errorMessage={'Greater than zero'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Wrapping Hour
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='minWrappingHours'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.minWrappingHoursError}
                        placeHolder={0.5}
                        errorMessage={'Greater than zero'}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Packing Hour
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='minPackingHours'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.minPackingHoursError}
                        placeHolder={0.5}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Assembly Hour
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='minAssemblyHours'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.minAssemblyHoursError}
                        placeHolder={0.5}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Minimum Disassembly Hour
                    </FormLabel>
                    <CustomTextFieldNumberForCost
                        field='minDisassemblyHours'
                        onChange={onChange}
                        isConfirm={isConfirm}
                        error={validation?.minDisassemblyHoursError}
                        placeHolder={0.5}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        House Type
                    </FormLabel>
                    <Select
                        value={houseType}
                        size='small'
                        // placeholder='Select Partner'
                        onChange={handleChangeHouseType}
                        disabled={isConfirm}
                        style={{textAlign: 'left'}}
                        // error={validation && validation?.carrierMissing}
                    >
                        <MenuItem disabled value='default'>
                            <em>Select House Type</em>
                        </MenuItem>
                        <MenuItem value='House'>
                            House
                        </MenuItem>
                        <MenuItem value='Townhouse'>
                            Townhouse
                        </MenuItem>
                        <MenuItem value='Condo'>
                            Condo
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Floor Level
                    </FormLabel>
                    <Select
                        value={floorLevel}
                        size='small'
                        // placeholder='Select Partner'
                        onChange={handleChangeFloorLevel}
                        disabled={isConfirm}
                        style={{textAlign: 'left'}}
                        // error={validation && validation?.carrierMissing}
                    >
                        <MenuItem disabled value='default'>
                            <em>Select Floor Level</em>
                        </MenuItem>
                        <MenuItem value={1}>
                            1
                        </MenuItem>
                        <MenuItem value={2}>
                            2
                        </MenuItem>
                        <MenuItem value={3}>
                            3
                        </MenuItem>
                    </Select>
                    {/*<FormHelperText*/}
                    {/*    sx={{*/}
                    {/*        color: "error.main",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {(validation && validation?.carrierMissing) && 'Required field'}*/}
                    {/*</FormHelperText>*/}
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Driveway Steep Level
                    </FormLabel>
                    <Select
                        value={drivewaySteepLevel}
                        size='small'
                        // placeholder='Select Partner'
                        onChange={handleChangeDrivewaySteepLevel}
                        disabled={isConfirm}
                        style={{textAlign: 'left'}}
                        // error={validation && validation?.carrierMissing}
                    >
                        <MenuItem disabled value='default'>
                            <em>Select Driveway Steep Level</em>
                        </MenuItem>
                        <MenuItem value={0}>
                            Flat
                        </MenuItem>
                        <MenuItem value={1}>
                            Moderate
                        </MenuItem>
                        <MenuItem value={2}>
                            Steep
                        </MenuItem>
                    </Select>
                    {/*<FormHelperText*/}
                    {/*    sx={{*/}
                    {/*        color: "error.main",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {(validation && validation?.carrierMissing) && 'Required field'}*/}
                    {/*</FormHelperText>*/}
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <FormLabel sx={{textAlign: 'left'}}>
                        Has Lift
                    </FormLabel>
                    <Select
                        value={hasLift}
                        size='small'
                        // placeholder='Select Partner'
                        onChange={handleChangeHasLift}
                        disabled={isConfirm || houseType !== 'Condo'}
                        style={{textAlign: 'left'}}
                        // error={validation && validation?.carrierMissing}
                    >
                        <MenuItem value={0}>
                            No
                        </MenuItem>
                        <MenuItem value={1}>
                            Yes
                        </MenuItem>
                    </Select>
                    {/*<FormHelperText*/}
                    {/*    sx={{*/}
                    {/*        color: "error.main",*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {(validation && validation?.carrierMissing) && 'Required field'}*/}
                    {/*</FormHelperText>*/}
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px'
                }}>
                    {
                        isConfirm ?
                            <>
                                <Button
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#F2BE22',
                                        "&:hover": {
                                            backgroundColor: '#F2BE22',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleEdit}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Cancel
                                    </Typography>
                                </Button>
                                <LoadingButton
                                    variant='contained'
                                    sx={{
                                        backgroundColor: '#1D8B45',
                                        "&:hover": {
                                            backgroundColor: '#1D8B45',
                                            filter: 'brightness(0.9)'
                                        }
                                    }}
                                    onClick={handleCreate}
                                    loading={loading}
                                >
                                    <Typography style={{
                                        textTransform: 'none'
                                    }}>
                                        Confirm
                                    </Typography>
                                </LoadingButton>
                            </>
                            :
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleConfirm}
                            >
                                <Typography style={{
                                    textTransform: 'none'
                                }}>
                                    Create
                                </Typography>
                            </Button>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}