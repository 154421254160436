import React, { useEffect, useRef, useState } from "react";
import { FormLabel, Grid, MenuItem, Select, Typography } from "@mui/material";
import { Box, Button } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { NODE_ROUTE_URI } from "../../utils/apiUrl";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";

const styles = {
  ShippingOrderRoot: {
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
  },
  ShippingOrderContent: {
    backgroundColor: '#FFFFFF',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '10px',
    // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    border: '1px solid #D1D1D1',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
}

const useStyles = makeStyles({
  customTextField: {
    "& input::placeholder": {
      fontSize: "14px"
    }
  },
  smallInput: {
    "& .MuiInputBase-input": {
      height: "2px", // Adjust the height as per your preference
      padding: "2px", // Adjust the padding as per your preference
      fontSize: "14px", // Adjust the font size as per your preference
    },
  }
});

const invoiceTypes = [
  { value: 1, label: "Not Exists In UUCARGO" },
  { value: 2, label: "Billed But Cancelled in UUCARGO" }
];

const ExportVendorInvoiceTable = () => {
  const classes = useStyles();

  const excelRowHeader = [
    "Line Id",
    "Carrier",
    "Account Number",
    "Invoice Id",
    "Invoice Number",
    "Partner Id",
    "Transaction Id",
    "Tracking Number",
    "PO Number",
    "Shipping Fee Exclude Tax",
    "Shipping Fee Include Tax",
    "Invoice Date"
  ]

  const csvLink = useRef();

  const [accountBase, setAccountBase] = useState("All");
  const [distinctVendorInvoiceList, setDistinctVendorInvoiceList] = useState([]);
  const [lineSeparateList, setLineSeparateList] = useState([]);
  const [lineSeparateCount, setLineSeparateCount] = useState(0);

  const [invoiceType, setInvoiceType] = useState(null);
  const [selectVendorInvoice, setSelectVendorInvoice] = useState(0);


  const handleSearchInvoice = async () => {
    try {
      const requestParams = {
        accountBase: accountBase === "All" ? "" : accountBase,
        invoiceNumber: selectVendorInvoice?.invoice_no || ""
      }
      let requestURL = `${NODE_ROUTE_URI}/transactionAdjustments/getNotExistInvoiceLineSeparateList`;

      if (invoiceType === 1) {
        requestURL = `${NODE_ROUTE_URI}/transactionAdjustments/getNotExistInvoiceLineSeparateList`;
      } else {
        requestURL = `${NODE_ROUTE_URI}/transactionAdjustments/getBilledButCancelInvoiceLineSeparateList`;
      }

      const { data } = await axios.get(requestURL, {
        params: requestParams
      });

      const resultArray = [
        excelRowHeader
      ];

      for (const column of data) {
        const columnData = [];
        columnData.push(column.id);
        columnData.push(column.account_base);
        columnData.push(column.account_number);

        columnData.push(column.invoice_id);
        columnData.push(column.invoice_no);
        columnData.push(column.partner_id);

        columnData.push(column.tran_id);
        columnData.push(column.tracking_no);
        columnData.push(column.po_number);

        columnData.push(column.shipping_fee_exclude_tax);
        columnData.push(column.shipping_fee_include_tax);
        columnData.push(column.invoice_date);

        resultArray.push(columnData);
      }

      setLineSeparateList(resultArray);
      csvLink.current.link.click();
    } catch (e) {
      console.log(e);
    }
  }

  const updateLineSeparateCount = async () => {
    const requestParams = {
      accountBase: accountBase === "All" ? "" : accountBase,
      invoiceNumber: selectVendorInvoice?.invoice_no || ""
    }
    let requestURL = `${NODE_ROUTE_URI}/transactionAdjustments/getNotExistInvoiceLineSeparateList`;

    if (invoiceType === 1) {
      requestURL = `${NODE_ROUTE_URI}/transactionAdjustments/getNotExistInvoiceLineSeparateListCount`;
    } else {
      requestURL = `${NODE_ROUTE_URI}/transactionAdjustments/getBilledButCancelInvoiceLineSeparateListCount`;
    }

    const { data } = await axios.get(requestURL, {
      params: requestParams
    });
    const { count } = data;
    setLineSeparateCount(count);
  }

  const handleSetAccountBase = (event) => {
    event.preventDefault();
    setAccountBase(event?.target?.value);
  }

  const handleSetInvoiceType = (event) => {
    event.preventDefault();
    setInvoiceType(event?.target?.value);
  }

  const handleChangeVendorInvoice = (event, value) => {
    if (value) {
      setSelectVendorInvoice(value);
    } else {
      setSelectVendorInvoice(null);
    }
  };

  useEffect(() => {
    (async () => {

      const requestParams = {
        accountBase: accountBase === "All" ? "" : accountBase
      }

      const { data } = await axios.get(`${NODE_ROUTE_URI}/transactionAdjustments/getDistinctInvoiceLineSeparateList`, {
        params: requestParams
      });
      console.log("distintVendorInvoiceList: ", data);
      setDistinctVendorInvoiceList(data);
      setSelectVendorInvoice(null);
    })()
  }, [accountBase]);

  useEffect(() => {
    if (invoiceType) {
      (async () => {
        await updateLineSeparateCount();
      })();
    }

  }, [accountBase, invoiceType, selectVendorInvoice])


  return (
    <>
      <Grid item xs={12}>
        <Typography style={{fontSize: '24px', fontWeight: 600, textAlign: 'center'}}>
          Export Wrongly Billed Tracking# and Invoices
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box sx={styles.ShippingOrderContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{
                textAlign: 'left',
                fontWeight: '600'
              }}>
                Filter By
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Typography style={{textAlign: 'left'}}>
                      Carrier
                    </Typography>
                  </FormLabel>
                  <Select
                    value={accountBase}
                    size='small'
                    style={{textAlign: 'left', fontSize: '14px'}}
                    onChange={handleSetAccountBase}
                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"UPS"}>UPS</MenuItem>
                    <MenuItem value={"FedEx"}>FedEx</MenuItem>
                    <MenuItem value={"Canpar"}>Canpar</MenuItem>
                    <MenuItem value={"CanadaPost"}>Canada Post</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                      <Typography style={{ textAlign: 'left' }}>Vendor Invoice</Typography>
                      <Typography style={{ fontSize: '12px', color: '#888' }}>
                        Total Invoices: {distinctVendorInvoiceList.length}
                      </Typography>
                    </Box>
                  </FormLabel>
                  <Autocomplete
                    value={selectVendorInvoice}
                    options={distinctVendorInvoiceList}
                    getOptionLabel={(option) => `${option.account_base} | ${option.invoice_no} | ${dayjs(option.invoice_date).format("YYYY-MM-DD HH:mm")}`}
                    fullWidth
                    renderInput={(params) => <TextField
                      {...params}
                      className={classes.smallInput}
                    />}
                    // onInputChange={handleInputChangeUser}
                    onChange={handleChangeVendorInvoice}
                    renderOption={(option) => (
                      <Typography
                        style={{fontSize: '14px'}}
                      >
                        {option.account_base} | {option.invoice_no} | {dayjs(option.invoice_date).format("YYYY-MM-DD HH:mm")}
                      </Typography>
                    )}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                width: '100%'
              }}>
                <FormControl fullWidth>
                  <FormLabel>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                      <Typography style={{ textAlign: 'left' }}>Invoice Type</Typography>
                      <Typography style={{ fontSize: '12px', color: '#888' }}>
                        Total Count: {lineSeparateCount}
                      </Typography>
                    </Box>
                  </FormLabel>
                  <Select
                    value={invoiceType}
                    size="small"
                    style={{ textAlign: 'left', fontSize: '14px' }}
                    onChange={handleSetInvoiceType}
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Invoice Type
                    </MenuItem>
                    {invoiceTypes.map((type) => (
                      <MenuItem key={type.value} value={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <>
                  <Button
                    onClick={handleSearchInvoice}
                    variant="contained"
                    color="secondary"
                    style={{
                      textTransform: "unset",
                      fontSize: "1rem",
                      display: "flex",
                      padding: "0.5rem",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center"
                    }}>
                    Download
                  </Button>
                  <CSVLink
                    data={lineSeparateList}
                    filename={"Invoice Line Separate"}
                    className="hidden"
                    ref={csvLink}
                    target="_blank"
                  />
                </>

              </Box>

            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ExportVendorInvoiceTable;
