import React from "react";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  listItemWrapper: {
    width: "100%",
    margin: "3px auto",
    "&:hover": {
      cursor: "pointer",
      background: "aliceblue"
    },
    lineHeight: "2rem"
  }
}));

export default function ShippingListTrackingTable({
  tran_id,
  order_no,
  product_id,
  ref_other_no,
  account_base,
  gross_price,
  net_price,
  zip_label_uri,
  date
}) {
  const classes = useStyles();

  return (
    <>
      <tr className={classes.listItemWrapper}>
        <td>
          <Link
            to={`/v2/shipping/list/product/${tran_id}`}
            target="_blank"
            rel="noopener noreferrer">
            {tran_id}
          </Link>
        </td>
        <td>{order_no}</td>
        <td>{product_id}</td>
        <td>{ref_other_no}</td>
        <td>{account_base}</td>
        <td>{gross_price}</td>
        <td>{net_price}</td>
        <td>
          <a href={zip_label_uri} target="_blank" rel="noopener noreferrer">
            {zip_label_uri ? "Download" : ""}
          </a>
        </td>
        <td>{date}</td>
      </tr>
    </>
  );
}
