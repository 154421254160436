import {Fragment, useEffect, useRef, useState} from "react";
import {FormHelperText, FormLabel, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {CustomTextFieldPallet} from "../../utils/customComponent";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import {
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_DELIVERY_CODE,
    SHIPPING_LTL_GENERAL_PRIVATE_RESIDENT_PICKUP_CODE
} from "../../utils/constInfo";
import axios from "axios";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardOrderStatus = ({
                                                 orderData,
                                                 isConfirm,
                                                 triggerAction,
                                                 validation,
                                                 getDataFromChildComponent,
                                                 BOLNumber,
                                                 getOrderDetails
                                             }) => {

    const [orderStatus, setOrderStatus] = useState(0);
    const [originalValue, setOriginalValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isGenerateInvoice, setIsGenerateInvoice] = useState(false);

    const handleChange = (event) => {
        console.log(event.target.value);
        setOrderStatus(event.target.value);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    orderStatus: orderStatus,
                    generateInvoice: isGenerateInvoice
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalValue(orderStatus);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({orderStatus});
        }
    }, [triggerAction])

    useEffect(() => {
        setOrderStatus(orderData?.status_id);
        setOriginalValue(orderData?.status_id);
    }, [orderData])

    useEffect(() => {
        if (orderData?.status_id === 0) {
            if (BOLNumber.replace(/\s/g, '')) {
                setOrderStatus(1);
            } else {
                setOrderStatus(0);
            }
        }
    }, [BOLNumber])

    useEffect(() => {
        if (orderStatus === 1 && !orderData?.crm_invoice_no) {
            setIsGenerateInvoice(true);
        } else {
            setIsGenerateInvoice(false);
        }
    } ,[orderStatus])
    // console.log('[LTLOrderDashboardOrderStatus] order data', orderData);
    console.log('[LTLOrderDashboardOrderStatus] isGenerateInvoice', isGenerateInvoice);

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormControl fullWidth>
                        <Select
                            value={orderStatus}
                            size='small'
                            // placeholder='Select Partner'
                            onChange={handleChange}
                            disabled={isConfirm}
                            error={validation && validation?.carrierMissing}
                        >
                            <MenuItem disabled value='default'>
                                <em>Select Status</em>
                            </MenuItem>
                            <MenuItem value={0}>
                                Initial
                            </MenuItem>
                            <MenuItem value={1}>
                                Confirm
                            </MenuItem>
                            <MenuItem value={-1}>
                                Cancel
                            </MenuItem>
                            <MenuItem value={3}>
                                Refund
                            </MenuItem>
                        </Select>
                        <FormHelperText
                            sx={{
                                color: "error.main",
                            }}
                        >
                            {(validation && validation?.carrierMissing) && 'Required field'}
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Grid>
            {
                orderStatus !== originalValue &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>
    )
}