import {Fragment, useEffect, useState} from "react";
import {FormLabel, Grid, Snackbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {NODE_ROUTE_URI} from "../../utils/apiUrl";
import axios from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const LTLOrderDashboardPickupDateAndTime = ({
                                                       getDataFromChildComponent,
                                                       triggerAction,
                                                       isConfirm,
                                                       validation,
                                                       orderData,
                                                       getOrderDetails
                                                   }) => {

    const [shipDateAndTime, setShipDateAndTime] = useState({
        shipDate: null,
        pickupDate: null,
        availablePickupTime: '12:00',
        earliestPickupTime: '',
        latestPickupTime: '',
    });

    const [orignialShipDateAndTime, setOriginalShipDateAndTime] = useState({
        shipDate: null,
        pickupDate: null,
        availablePickupTime: '12:00',
        earliestPickupTime: '',
        latestPickupTime: '',
    });

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handlePickupDate = (event) => {
        const inputDate = event.target.value;
        setShipDateAndTime(prevState => ({
            ...prevState,
            pickupDate: inputDate,
            shipDate: inputDate
        }))
    }

    const handlePickupEarliestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            earliestPickupTime: inputTime
        }))
    }

    const handlePickupLatestTime = (event) => {
        const inputTime = event.target.value;
        console.log('time', inputTime);
        setShipDateAndTime(prevState => ({
            ...prevState,
            latestPickupTime: inputTime
        }))
    }

    const updateBackendOrder = async () => {
        setLoading(true);
        try {
            let requestURL = `${NODE_ROUTE_URI}/shippingLTLForAdmin/editLTLOrderDetailsByOrderId`;

            let data =
                {
                    orderId: orderData?.id,
                    shipDate: shipDateAndTime?.shipDate,
                    pickupLocationCloseTime: `${shipDateAndTime?.shipDate} ${shipDateAndTime?.latestPickupTime}`,
                    pickupLocationOpenTime: `${shipDateAndTime?.shipDate} ${shipDateAndTime?.earliestPickupTime}`,
                    pickupAvailableTime: `${shipDateAndTime?.shipDate} ${shipDateAndTime?.availablePickupTime}`,
                    pickupDate: shipDateAndTime?.shipDate,
                }

            const result = await axios({
                method: 'patch',
                url: requestURL,
                data: data
            })
            console.log('place order', result);
            setErrorMessage('');
            setSuccessMessage('Successful Update Order');
            handleSuccessMessage();
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000);
            // setOriginalShipDateAndTime(shipDateAndTime);
            getOrderDetails();
        } catch (e) {
            console.log(e.response);
            setErrorMessage('Fail Update Order');
            handleErrorMessage();

        } finally {
            setLoading(false);
        }
    }

    const handleUpdateOrder = () => {
        updateBackendOrder();
    }

    useEffect(() => {
        if (triggerAction) {
            getDataFromChildComponent({shipDateAndTime});
        }
    }, [triggerAction]);

    useEffect(() => {
        setShipDateAndTime(prevState => ({
            ...prevState,
            pickupDate: orderData?.pickup_date,
            shipDate: orderData?.pickup_date,
            availablePickupTime: orderData?.pickup_available_time || '12:00',
            earliestPickupTime: orderData?.pickup_location_open_time,
            latestPickupTime: orderData?.pickup_location_close_time,
        }))
        setOriginalShipDateAndTime(prevState => ({
            ...prevState,
            pickupDate: orderData?.pickup_date,
            shipDate: orderData?.pickup_date,
            availablePickupTime: orderData?.pickup_available_time || '12:00',
            earliestPickupTime: orderData?.pickup_location_open_time,
            latestPickupTime: orderData?.pickup_location_close_time,
        }))
    }, [orderData])

    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        Shipping Date
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='date'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDateAndTime?.pickupDate}
                        onChange={handlePickupDate}
                        size='small'
                        disabled={isConfirm}
                        error={validation?.shipDateAndTimeMissing.includes('pickupDate')}
                        helperText={validation?.shipDateAndTimeMissing.includes('pickupDate') && "Required field"}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        Pickup Earliest Time
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDateAndTime?.earliestPickupTime}
                        onChange={handlePickupEarliestTime}
                        size='small'
                        disabled={isConfirm}
                        error={validation?.shipDateAndTimeMissing.includes('earliestPickupTime')}
                        helperText={validation?.shipDateAndTimeMissing.includes('earliestPickupTime') && "Required field"}
                    />
                </Box>
            </Grid>
            <Grid item md={4} xs={12}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <FormLabel>
                        Pickup Latest Time
                    </FormLabel>
                    <TextField
                        fullWidth
                        type='time'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={shipDateAndTime?.latestPickupTime}
                        onChange={handlePickupLatestTime}
                        size='small'
                        disabled={isConfirm}
                        error={validation?.shipDateAndTimeMissing.includes('latestPickupTime')}
                        helperText={validation?.shipDateAndTimeMissing.includes('latestPickupTime') && "Required field"}
                    />
                </Box>
            </Grid>
            {
                (
                    JSON.stringify(shipDateAndTime) !== JSON.stringify(orignialShipDateAndTime)
                ) &&
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}>
                        <LoadingButton
                            variant='contained'
                            sx={{
                                backgroundColor: '#1D8B45',
                                "&:hover": {
                                    backgroundColor: '#1D8B45',
                                    filter: 'brightness(0.9)'
                                },
                            }}
                            size='small'
                            onClick={handleUpdateOrder}
                            loading={loading}
                        >
                            <Typography style={{
                                textTransform: 'none',
                            }}>
                                Update
                            </Typography>
                        </LoadingButton>
                    </Box>
                </Grid>
            }
        </Fragment>

    )
}