import {Grid, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

export const ClaimDashboardPackageData = ({claimData, transactionData}) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={{fontSize: '18px', fontWeight: 600, textAlign: 'left'}}>
                    Package Details
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontWeight: 600, textAlign: 'left'}}>
                    Shipper
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_from_name}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_from_addr_line2 ? `${transactionData?.send_from_addr_line2}-${transactionData?.send_from_addr}` : `${transactionData?.send_from_addr}`}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_from_city}, {transactionData?.send_from_province}, {transactionData?.send_from_postal_code}, {transactionData?.send_from_country}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_from_email}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_from_tel}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={{fontWeight: 600, textAlign: 'left'}}>
                    Recipient
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_to_name}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_to_addr_line2 ? `${transactionData?.send_to_addr_line2}-${transactionData?.send_to_addr}` : `${transactionData?.send_to_addr}`}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_to_city}, {transactionData?.send_to_province}, {transactionData?.send_to_postal_code}, {transactionData?.send_to_country}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_to_email}
                </Typography>
                <Typography sx={{textAlign: 'left'}}>
                    {transactionData?.send_to_tel}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Box sx={{
                    display: 'flex',
                    gap: '15px',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}>
                    <Typography sx={{textAlign: 'left'}}>
                        <span style={{fontWeight: '600'}}>Carrier:</span> {claimData?.account_base}
                    </Typography>
                    <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: '600'}}>Total Price:</span> ${transactionData?.amount} {transactionData?.currency_code}
                    </Typography>
                    <Typography sx={{textAlign: 'left'}}>
                                    <span
                                        style={{fontWeight: '600'}}>Shipment Date:</span> {dayjs(claimData?.shipped_date).format('MMM D, YYYY')}
                    </Typography>
                    <Typography sx={{textAlign: 'left'}}>
                        <span style={{fontWeight: '600'}}>Tracking #:</span> {claimData?.tracking_nos}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}